import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Checkbox } from "antd";
import { ToastsStore } from "react-toasts";

import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";
import LinkButton from "elements/Button/Link";
import BasicInput from "elements/Input/Basic";
import BasicSwitch from "elements/Switch/Basic";
import ModalSelect from "elements/Select/Modal";
import PartnersCustomInfoList from "../Lists/PartnersCustomInfoList";
import Error from "elements/Text/Error";

import useProperties from "../../store/actions/propertiesActions";
import { useToggle, useArray } from "../../utils/hooks";
import { isNullOrUndefined } from "utils";
import { isAlphaNumeric, sortArray } from "utils/data";
import constants from "../../constants";



const PartnersModal = ({ visible, type, channels = [], onCancel = null, onSubmit = null }) => {
    const state = useSelector((state) => state.mainReducer);
    const { listItemInView, propertyInView } = state;
    const { updatePartnerMappings } = useProperties();

    const partners = sortArray(channels, "name").map((item) => { return { name: item.name, value: item._id } });

    const [partner, setPartner] = useState("");
    const [propertyCode, setPropertyCode] = useState("");
    const [outAuthUser, setOutAuthUser] = useState("");
    const [outAuthPass, setOutAuthPass] = useState("");
    const [isPartnerEnabled, togglePartner, resetPartner] = useToggle(false);
    const [isMessageSwitchEnabled, toggleMessageSwitch, resetMessageSwitch] = useToggle(false);
    const [isRoomTypesEnabled, toggleRoomTypes, resetRoomTypes] = useToggle(false);
    const [isReservationsEnabled, toggleReservations, resetReservations] = useToggle(false);
    const [isAvailabilityEnabled, toggleAvailability, resetAvailability] = useToggle(false);
    const [isRatesEnabled, toggleRates, resetRates] = useToggle(false);
    const [isBAREnabled, toggleBAR, resetBAR] = useToggle(false);
    const [bookingSources, setBookingSources] = useState([]);

    const [customInfoLabel, setCustomInfoLabel] = useState("");
    const [customInfoValue, setCustomInfoValue] = useState("");
    const [isCustomInfoFormVisible, toggleCustomInfoForm] = useToggle(false);
    const customInfoList = useArray([]);

    const [modalErrors, setModalErrors] = useState([]);
    const [customInfoErrors, setCustomInfoErrors] = useState([]);

    const isViewModal = type === "view";
    const isAddModal = type === "add";
    const isEditModal = type === "edit";
    const isDeleteModal = type === "delete";


    // Dynamic Header Copy & Element
    let headerCopy = "";
    if (isAddModal) headerCopy = "Map to Channel";
    if (isViewModal) headerCopy = "Mapping to Channel";
    if (isEditModal) headerCopy = "Edit Mapping to Channel";
    if (isDeleteModal) headerCopy = "Delete Channel Mapping";



    // Happens in Edit Mode
    // Pre-Fill Modal with Generic listItemInView State Data if a Partner Item is Selected
    useEffect(() => {
        resetModal();
        if (isEditModal && !isNullOrUndefined(listItemInView)) {
            setPartner(listItemInView.partner_code);
            setPropertyCode(listItemInView.property_code);
            setOutAuthUser(listItemInView.authentication?.outbound?.username ?? "");
            setOutAuthPass(listItemInView.authentication?.outbound?.password ?? "");
            setBookingSources(listItemInView.booking_sources ?? []);
            if (listItemInView.enabled) togglePartner();
            if (listItemInView.room_types_enabled) toggleRoomTypes();

            const messageSwitches = listItemInView.message_switches;
            if (!isNullOrUndefined(messageSwitches) && !isNullOrUndefined(messageSwitches.options)) {
                if (Object.values(messageSwitches.options).includes(true)) toggleMessageSwitch();

                if (messageSwitches.options.reservations) toggleReservations();
                if (messageSwitches.options.availability) toggleAvailability();
                if (messageSwitches.options.rates) toggleRates();
                if (messageSwitches.options.bar) toggleBAR();
            }

            const customInfo = listItemInView.custom_info;
            if (!isNullOrUndefined(customInfo) && Array.isArray(customInfo) && customInfo.length > 0) {
                customInfo.forEach((item) => {
                    customInfoList.add({ label: item.label, value: item.value });
                })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);


    // Dynamically Update the Messaging Switches Toggle All Enabled Status for Every Update on Options
    useEffect(() => {
        let messageSwitches = [isReservationsEnabled, isAvailabilityEnabled, isRatesEnabled, isBAREnabled];
        if ((Object.values(messageSwitches).includes(true)) && !isMessageSwitchEnabled) toggleMessageSwitch();
        if ((!Object.values(messageSwitches).includes(true)) && isMessageSwitchEnabled) resetMessageSwitch();
    }, [isReservationsEnabled, isAvailabilityEnabled, isRatesEnabled, isBAREnabled]);



    // Custom Info Actions
    const handleCustomInfoErrors = (data) => {
        let errors = [];
        if (customInfoLabel === "") errors.push({ id: "label", message: "Please provide a label" });
        if (customInfoValue === "") errors.push({ id: "value", message: "Please provide a value" });

        return errors;
    }

    const resetCustomInfo = () => {
        setCustomInfoLabel("");
        setCustomInfoValue("");
        setCustomInfoErrors([]);
    }

    const handleSubmitCustomInfo = () => {
        let errors = handleCustomInfoErrors();
        let hasErrors = errors.length > 0;

        if (hasErrors) {
            setCustomInfoErrors(errors);
        } else {
            customInfoList.add({ label: customInfoLabel, value: customInfoValue });
            resetCustomInfo();
            document.getElementById("customInfoLabel").focus();
        }
        return hasErrors;
    }

    const handleKeyDownCustomInfo = () => {
        let hasErrors = handleSubmitCustomInfo();
        if (!hasErrors) resetCustomInfo();
    }



    // Modal Actions
    const handleToggleMessagingSwitches = () => {
        toggleMessageSwitch();

        if (!isMessageSwitchEnabled !== isReservationsEnabled) toggleReservations();
        if (!isMessageSwitchEnabled !== isAvailabilityEnabled) toggleAvailability();
        if (!isMessageSwitchEnabled !== isRatesEnabled) toggleRates();
        if (!isMessageSwitchEnabled !== isBAREnabled) toggleBAR();
    }

    const handleModalErrors = (data) => {
        let errors = [];
        if (data.partner_code === "") errors.push({ id: "partner", message: "Please select a channel" });
        if (data.property_code === "") errors.push({ id: "code", message: "Please provide a property code" });
        if (data.property_code !== "" && !isAlphaNumeric(data.property_code, false)) {
            errors.push({ id: "code", message: "Code contains non-alphanumeric characters" });
        }

        let isExistingPartner = (propertyInView.mappings.filter((item) => {
            return (item.partner_code === data.partner_code && item.property_code === data.property_code);
        })).length > 0;
        if (isAddModal && isExistingPartner) errors.push({
            id: "exists", message: "Channel with matching property code exists"
        });

        return errors;
    }

    const resetModal = () => {
        setPartner("");
        resetPartner();
        setPropertyCode("");
        setOutAuthUser("");
        setOutAuthPass("");
        setBookingSources([]);
        resetMessageSwitch();
        resetRoomTypes();
        resetReservations();
        resetAvailability();
        resetRates();
        resetBAR();
        resetCustomInfo();
        setModalErrors([]);
        setCustomInfoErrors([]);
        customInfoList.clear();
    }

    const handleModalCancel = () => {
        onCancel();
        resetModal();
    }

    const handleModalDelete = () => {
        updatePartnerMappings(propertyInView._id, {
            op: "delete",
            filter: {
                partner_code: listItemInView.partner_code,
                property_code: listItemInView.property_code,
            }
        }).then((res) => {
            if (res.type === constants.UPDATE_PROPERTY_PARTNER_MAPPINGS_SUCCESS) {
                onSubmit();
                resetModal();
                ToastsStore.success(`Mapping to channel successfully removed`);
            } else {
                ToastsStore.error(`Sorry, we're unable to remove the mapping to channel at this time`);
            }
        });
    }

    const handleModalSubmit = () => {
        let data = {
            enabled: isPartnerEnabled,
            partner_code: partner,
            property_code: propertyCode,
            authentication: { outbound: { username: outAuthUser, password: outAuthPass } },
            message_switches: {
                enabled: isMessageSwitchEnabled,
                options: {
                    reservations: isReservationsEnabled,
                    availability: isAvailabilityEnabled,
                    rates: isRatesEnabled,
                    bar: isBAREnabled
                }
            },
            room_types_enabled: isRoomTypesEnabled,
            custom_info: customInfoList.value,
            booking_sources: bookingSources
        };

        // In Case Existing Data Has More Fields Than Existing Data Model
        // Copy Those Fields Into The Data Too
        if (isEditModal && (Object.keys(listItemInView ?? {}).length > Object.keys(data).length)) {
            data = { ...listItemInView, ...data };
        }

        let errors = handleModalErrors(data);
        if (errors.length === 0) {
            updatePartnerMappings(propertyInView._id, {
                op: isEditModal ? "edit" : "add",
                filter: isEditModal ? { before: listItemInView, after: data } : data
            }).then((res) => {
                if (res.type === constants.UPDATE_PROPERTY_PARTNER_MAPPINGS_SUCCESS) {
                    onSubmit();
                    resetModal();
                    ToastsStore.success(`Mapping to channel successfully ${isEditModal ? "updated" : "added"}`);
                } else {
                    ToastsStore.error(`Sorry, we're unable to ${isEditModal ? "update" : "add"} mapping to channgel at this time`);
                }
            });
        } else {
            let isExistingError = errors.filter(item => item.id === "exists");
            isExistingError.length > 0
                ? ToastsStore.error(isExistingError[0].message)
                : setModalErrors(errors);
        }
    }



    return (
        <Modal open={visible} onCancel={() => handleModalCancel()} footer={null} closeIcon={
            <IconButton type="delete" hasAccess={true} width="25px" />
        }>
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>

                <form onSubmit={(e) => null} className="light-form">
                    {!isDeleteModal && <>

                        {/* Select Channel Dropdown */}
                        <ModalSelect label="Select Channel" value={partner} options={partners}
                            disabled={isEditModal} action={(value) => setPartner(value)} />
                        {modalErrors.filter(err => err.id === "partner").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        {/* Channel Status Switch */}
                        <div style={{ maxWidth: "72%" }}>
                            <BasicSwitch label="Channel Status" checked={isPartnerEnabled}
                                action={() => togglePartner()} />
                        </div>

                        {/* Property Code Input */}
                        <BasicInput id="propertyCode" label="Property Code" value={propertyCode}
                            disabled={isEditModal} onBlur={(e) => setPropertyCode(e.target.value)}
                            onChange={(e) => setPropertyCode(e.target.value)} />
                        {modalErrors.filter(err => err.id === "code").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        {/* API Outbound Authentication Username Input */}
                        <BasicInput id="apiOutAuthUser" label="API Outbound Auth Username" value={outAuthUser}
                            onBlur={(e) => setOutAuthUser(e.target.value.replace(/\s/g, ""))}
                            onChange={(e) => setOutAuthUser(e.target.value.replace(/\s/g, ""))} />

                        {/* API Outbound Authentication Password Input */}
                        <BasicInput id="apiOutAuthPass" label="API Outbound Auth Password" value={outAuthPass}
                            onBlur={(e) => setOutAuthPass(e.target.value.replace(/\s/g, ""))}
                            onChange={(e) => setOutAuthPass(e.target.value.replace(/\s/g, ""))} />


                        <div style={{ display: "grid", gridTemplateColumns: "60% 40%" }}>
                            <div>
                                {/* Messaging Switches */}
                                <BasicSwitch label="Messaging Switches" checked={isMessageSwitchEnabled}
                                    action={() => handleToggleMessagingSwitches()} type="60% 40%" />

                                {/* Messaging Checkboxes */}
                                <div style={{ display: "grid", gridTemplateColumns: "50% 50%", padding: "0 8px" }}>
                                    <Checkbox checked={isReservationsEnabled} onChange={toggleReservations}>
                                        Reservations
                                    </Checkbox>
                                    <Checkbox checked={isAvailabilityEnabled} onChange={toggleAvailability}>
                                        Availability
                                    </Checkbox>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "50% 50%", padding: "0 8px" }}>
                                    <Checkbox checked={isRatesEnabled} onChange={toggleRates}>
                                        Rates
                                    </Checkbox>
                                    <Checkbox checked={isBAREnabled} onChange={toggleBAR}>
                                        BAR
                                    </Checkbox>
                                </div>
                            </div>

                            {/* Room Types Switch */}
                            <BasicSwitch label="Room Types" checked={isRoomTypesEnabled} action={toggleRoomTypes} />
                        </div>


                        {/* Add Custom Info Button */}
                        {!isCustomInfoFormVisible && <div style={{ marginTop: 20 }}>
                            <LinkButton action={toggleCustomInfoForm}
                                text={`Add ${customInfoList.value.length > 0 ? "More" : ""} Custom Information`} />
                        </div>}


                        {/* Add Custom Info Form */}
                        {isCustomInfoFormVisible && <>
                            <div style={{
                                display: "grid", gridTemplateColumns: "50% 50%",
                                gap: 8, padding: "20px 8px 0 8px"
                            }}>
                                <div>
                                    <BasicInput id="customInfoLabel" label="Data Label" value={customInfoLabel}
                                        onBlur={(e) => setCustomInfoLabel(e.target.value)}
                                        onChange={(e) => setCustomInfoLabel(e.target.value)} />
                                    {customInfoErrors.filter(err => err.id === "label").map((item, index) => {
                                        return <Error key={index} errorKey={index} error={item.message} />
                                    })}
                                </div>
                                <div>
                                    <BasicInput id="customInfoValue" label="Value" value={customInfoValue}
                                        onBlur={(e) => setCustomInfoValue(e.target.value)}
                                        onChange={(e) => setCustomInfoValue(e.target.value)}
                                        onKeyDown={() => handleKeyDownCustomInfo()} />
                                    {customInfoErrors.filter(err => err.id === "value").map((item, index) => {
                                        return <Error key={index} errorKey={index} error={item.message} />
                                    })}
                                </div>
                            </div>
                            <div className="light-form-buttons">
                                <ActionButton type="cancel" text="Cancel" action={toggleCustomInfoForm} />
                                <span style={{ width: 20 }} />
                                <ActionButton type="submit" text="Add" action={() => handleSubmitCustomInfo()} />
                            </div>
                            <hr style={{ marginTop: 20 }} />
                        </>}

                        {/* Custom Information List */}
                        {customInfoList.value.length > 0 &&
                            <PartnersCustomInfoList customInfo={customInfoList.value} />}

                    </>}


                    {/* Delete Channel Copy */}
                    {isDeleteModal && <div style={{ padding: 20 }}>
                        <p>Deleting this channel will delete all room mappings associated with this channel.</p>
                        <p>
                            <strong>Channel: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.partner_code}</span>
                            <br />
                            <strong>Property Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.property_code}</span>
                        </p>
                        <p>Are you sure you want to delete this channel?</p>
                    </div>}


                    {/* Cancel, Submit, Delete Buttons */}
                    <div className="light-form-buttons" style={{ marginTop: isDeleteModal ? 20 : 40 }}>
                        <ActionButton type={isCustomInfoFormVisible ? "disabled" : "cancel"}
                            action={() => handleModalCancel()} text="Cancel" />
                        <span style={{ width: 20 }} />
                        {isDeleteModal
                            ? <ActionButton type="submit" action={() => handleModalDelete()} text="Delete" />
                            : <ActionButton type={isCustomInfoFormVisible ? "disabled" : "submit"}
                                action={() => handleModalSubmit()} text="Submit" />
                        }
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default PartnersModal;
