import React from "react";
import { useExport } from "./useExport";
import Export from "../../Icons/Export";

const ExportReservationsButton = ({ data }) => {
    const { exportReservationsData } = useExport();
    
    return <Export className="pointer" noBackground={true} strokeColor={"#51b68a"} onClick={() => exportReservationsData(data)} />
}

export default ExportReservationsButton;