import React from 'react';

export default ({ name, inputClass, value, min, disabled, numberOnly, onChange, onBlur }) => {

    return (
    <div className="form-field-input-div">
        <input key={name} className={`form-field-input form-field-textbox ${inputClass}`} name={name} 
            type={numberOnly === true? "number": "text"} 
            min={min}
            value={value || ''} 
            disabled={disabled}
            onChange={(e)=> { typeof onChange == "function" && onChange(name, e.target.value) }}
            onBlur={(e) => { typeof onBlur == "function" && onBlur(name, e.target.value) }}/>
    </div>);
}