import React from 'react';
import useForm from "../../../Forms/useForm";
import validateForm from "../../../Forms/validateForm";
import Field from "../../../Field";
import './ForecastWeights.scss'
import { pickup_fields, pace_fields, forecast_fields, price_fields, finals_fields, withGroupError } from "./forecastWeightsHelper";

const ForecastWeights = (props) => {

    const { errors, values } = props;
    const { onChange } = props;

    return (
        <div className="forecast-weights">
            <div className="fcw-row">
                <div className="fcw-column">
                    <div className="forms-section">
                        <h3>Pickup</h3>
                        <strong>
                            <span className="field-error">
                                {withGroupError(
                                    errors,
                                    pickup_fields.map(i => i.key)
                                )}
                            </span>
                        </strong>
                        {pickup_fields.map((field) => {
                            const { key, label, type } = field;
                            return (
                                <Field
                                    key={key}
                                    fieldKey={key}
                                    label={label}
                                    type={type}
                                    value={values[key]}
                                    placeholder=""
                                    onChange={onChange}
                                    errors={withGroupError(
                                        errors,
                                        pickup_fields.map((i) => i.key)
                                    )}
                                    withErrorMessage={false}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="fcw-column">
                    <div className="forms-section">
                        <h3>Pace</h3>
                        <strong>
                            <span className="field-error">
                                {withGroupError(
                                    errors,
                                    pace_fields.map(i => i.key)
                                )}
                            </span>
                        </strong>
                        {pace_fields.map((field) => {
                            const { key, label, type } = field;
                            return (
                                <Field
                                    key={key}
                                    fieldKey={key}
                                    label={label}
                                    type={type}
                                    value={values[key]}
                                    placeholder=""
                                    onChange={onChange}
                                    errors={withGroupError(
                                        errors,
                                        pace_fields.map((i) => i.key)
                                    )}
                                    withErrorMessage={false}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="fcw-column">
                    <div className="forms-section">
                        <h3>Forecast</h3>
                        <strong>
                            <span className="field-error">
                                {withGroupError(
                                    errors,
                                    forecast_fields.map(i => i.key)
                                )}
                            </span>
                        </strong>

                        {forecast_fields.map((field) => {
                            const { key, label, type } = field;
                            return (
                                <Field
                                    key={key}
                                    fieldKey={key}
                                    label={label}
                                    type={type}
                                    value={values[key]}
                                    placeholder=""
                                    onChange={onChange}
                                    errors={withGroupError(
                                        errors,
                                        forecast_fields.map((i) => i.key)
                                    )}
                                    withErrorMessage={false}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="fcw-column">
                    <div className="forms-section">
                        <h3>Price</h3>
                        <strong>
                            <span className="field-error">
                                {withGroupError(
                                    errors,
                                    price_fields.map(i => i.key)
                                )}
                            </span>
                        </strong>

                        {price_fields.map((field) => {
                            const { key, label, type } = field;
                            return (
                                <Field
                                    key={key}
                                    fieldKey={key}
                                    label={label}
                                    type={type}
                                    value={values[key]}
                                    placeholder=""
                                    onChange={onChange}
                                    errors={withGroupError(
                                        errors,
                                        price_fields.map((i) => i.key)
                                    )}
                                    withErrorMessage={false}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="fcw-column">
                    <div className="forms-section">
                        <h3>Finals</h3>
                        <strong>
                            <span className="field-error">
                                {withGroupError(
                                    errors,
                                    finals_fields.map(i => i.key)
                                )}
                            </span>
                        </strong>

                        {finals_fields.map((field) => {
                            const { key, label, type } = field;
                            return (
                                <Field
                                    key={key}
                                    fieldKey={key}
                                    label={label}
                                    type={type}
                                    value={values[key]}
                                    placeholder=""
                                    onChange={onChange}
                                    errors={withGroupError(
                                        errors,
                                        finals_fields.map((i) => i.key)
                                    )}
                                    withErrorMessage={false}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForecastWeights;