import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastsStore } from "react-toasts";

import ListHeader from "elements/Text/ListHeader";
import IconButton from "elements/Button/Icon";

import useProperties from "../../store/actions/propertiesActions";
import constants from "../../constants";
import { sortArray } from "utils/data";


/**
 * Segment mappings list component.
 *
 * @param {[Object]} segments List of segments data object.
 * @param {Object} user Rev&You user data object.
 * @param {Function} onAdd Event action after clicking the Add Segment Mapping button.
 * @param {Function} onView Event action after clicking the View Segment Mapping button.
 * @param {Function} onEdit Event action after clicking the Edit Segment Mapping button.
 * @param {Function} onDelete Event action after clicking the Delete Segment Mapping button.
 * @return {Component} Segment mappings list component.
*/
const SegmentMappingsList = ({ segments, user, onAdd, onView, onEdit, onDelete }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const { propertyInView } = state;

    let hasSegments = segments && Array.isArray(segments) && segments.length > 0;
    let isAdmin = user.type === "ope_admin";
    let isHotelUser = user.type === "hotel_user";
    let hasUpdateAccess = isHotelUser || isAdmin;


    const updateListItemInView = (mapping, s) => {
        dispatch({
            type: constants.UPDATE_LIST_ITEM_IN_VIEW,
            value: {
                mapping: mapping,
                segment: s
            }
        });
    }

    const handleEditSegmentMapping = (mapping, s) => {
        updateListItemInView(mapping, s);
        onEdit();
    }

    const handleDeleteSegmentMapping = (mapping, s) => {
        updateListItemInView(mapping, s);
        onDelete();
    }



    return (
        <div>
            <ListHeader headerCopy="Add Segment Mapping" component={
                <IconButton type="add" hasAccess={hasUpdateAccess} action={onAdd} width="25px" />
            } />

            <table className="forms-table">
                <thead>
                    <tr style={{ marginBottom: 8 }}>
                        <th>Segment Code</th>
                        <th>Segment Name</th>
                        <th>Channel</th>
                        <th style={{ marginLeft: 100 }}>Source Code</th>
                        <th style={{ marginLeft: 100 }}>Source Name</th>
                        <th style={{ textAlign: "right" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasSegments && sortArray(segments, "segment").map((s, index) => {
                        return s.local && <div key={`${s.local.code}-${s.local.name}-${index}`}>
                            <tr key={`${s.local.code}-${s.local.name}-${index}`} style={{ marginTop: 8 }}>
                                <td>{s.local.code}</td>
                                <td style={{ marginLeft: -50, maxWidth: "16ch", wordWrap: "break-word" }}>
                                    {s.local.name}
                                </td>
                                <td style={{ display: "grid", gridTemplateColumns: "95% 5%", marginLeft: 10 }}>
                                    {(s.local.code !== "UNS") && <>
                                        <strong style={{ width: "max-content" }}>
                                            Add another segment mapping
                                        </strong>
                                        <div style={{ marginTop: 2 }}>
                                            <IconButton type="plus" hasAccess={hasUpdateAccess} width="18px"
                                                action={() => handleEditSegmentMapping(null, s)} />
                                        </div>
                                    </>}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {sortArray(s.sources, "partner_code").map((mapping, index) => {
                                return (mapping.partner_code !== "REVANDYOU") && (
                                    <tr key={`${s.local.code}-${mapping.partner_code}-${mapping.source_code}-${index}`}>
                                        <td></td>
                                        <td></td>
                                        <td>{mapping.partner_code}</td>
                                        <td style={{ marginLeft: 100 }}>{mapping.source_code}</td>
                                        <td style={{ marginLeft: 100 }}>{mapping.source}</td>
                                        <td style={{
                                            display: "grid", gridTemplateColumns: "20% 20% 20% 20% 20%",
                                            justifyItems: "end"
                                        }}>
                                            <span></span>
                                            <span></span>
                                            {isAdmin
                                                ? <IconButton type="edit" hasAccess={isAdmin}
                                                    action={() => handleEditSegmentMapping(mapping, s)} />
                                                : <IconButton type="view" hasAccess={isHotelUser} action={onView} />
                                            }
                                            <IconButton type="delete" hasAccess={isAdmin}
                                                action={() => handleDeleteSegmentMapping(mapping, s)} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </div>
                    }
                    )}

                    {!hasSegments && <tr>
                        <td>No segments mappings found</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
};

export default SegmentMappingsList;