import React, { useState, useEffect } from "react";
// import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { DatePicker, Modal } from "antd";

import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";
import BasicInput from "elements/Input/Basic";
import Error from "elements/Text/Error";
import "./RoomInventoriesModal.scss";

const RoomInventoriesModal = ({ selectedRoomInventory, roomInventoryList, visible, type, onCancel = null, onSubmit = null }) => {
    const property = useSelector(state => state.mainReducer?.propertyInView);

    const [id, setId] = useState();
    const [maxCapacity, setMaxCapacity] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [modalErrors, setModalErrors] = useState([]);
    // Used state for type, just for consistency of modal.
    const [currentType, setCurrentType] = useState(null);

    const isAddModal = type === "add";
    const isEditModal = type === "edit";
    const isDeleteModal = type === "delete";

    // Dynamic Header Copy & Element
    let headerCopy = "";
    if (isAddModal) headerCopy = "Add Room Availability";
    if (isEditModal) headerCopy = "Edit Room Availability";
    if (isDeleteModal) headerCopy = "Delete Room Availability";

    useEffect(() => {
        if (visible !== true) return;
        setCurrentType(type);
        resetModal();
        
        if (!isAddModal) {
            setId(selectedRoomInventory?._id);
            setMaxCapacity(selectedRoomInventory?.max_capacity);
            setStartDate(dayjs(selectedRoomInventory?.start_date));
            setEndDate(dayjs(selectedRoomInventory?.end_date));
        }

    }, [visible]);

    // Modal Actions
    const handleModalErrors = () => {
        const errors = [];
        const addError = (id, message) => errors.push({ id, message });

        const conflicts = roomInventoryList.filter(item => {
            if (isEditModal && item._id === id) return false;

            if (dayjs(item.start_date).isBetween(startDate, endDate, undefined, "[]")) 
                return true;
            if (dayjs(item.end_date).isBetween(startDate, endDate, undefined, "[]")) 
                return true;
            if (dayjs(item.start_date).isBefore(startDate) &&  dayjs(item.end_date).isAfter(endDate)) 
                return true;

            return false;
        });

        if (maxCapacity == null) 
            addError("max_capacity", "Please provide max capacity");
        
        if (isNaN(parseInt(maxCapacity))) 
            addError("max_capacity", "Please enter an integer in max capacity");
        
        if (maxCapacity >= property.total_inventory)
            addError("max_capacity", "Maximum Capacity set is more than or equal to Total Room Inventory.");
        
        if (conflicts.length > 0)
            conflicts.forEach(conflict => {
                const msg = `Conflict with ${dayjs(conflict.start_date).format("MMMM DD YYYY")}` + 
                    ` - ${dayjs(conflict.end_date).format("MMMM DD YYYY")}` + 
                    ` (${conflict.max_capacity})`;
                addError("date_range", msg);
            });

        if (startDate == null)
            addError("date_range", "Please provide start date"); 

        if (endDate == null)
            addError("date_range", "Please provide end date");

        return errors;
    }

    const resetModal = () => {
        setMaxCapacity(null);
        setStartDate(null);
        setEndDate(null);
        setModalErrors([]);
    }


    const handleModalSubmit = () => {
        const errors = handleModalErrors();
        setModalErrors(errors);
        if (!isDeleteModal && errors.length > 0) return;

        onSubmit({
            type: currentType, 
            data: {
                ...selectedRoomInventory,
                _id: isAddModal? `NEW[${roomInventoryList.length}]`: selectedRoomInventory._id,
                max_capacity: maxCapacity,
                start_date: startDate.format(),
                end_date: endDate.format()
            } 
        });
    }

    return (
        <Modal className="room-inventory-modal" open={visible} width={485} onCancel={() => onCancel()} destroyOnClose={true} 
            footer={null} closeIcon={<IconButton type="delete" hasAccess={true} width="25px" />}
        >
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>

                <form onSubmit={(e) => null} className="light-form">
                    {/* Content: Add or Edit */}
                    {!isDeleteModal && <>
                        {/* Start Date */}
                        <div className="light-input-container">
                            <div className="light-label">Date Range</div>
                            <DatePicker.RangePicker 
                                popupClassName="room-inventories-date-ranges-picker"
                                style={{ marginBottom: "15px", width: "100%" }}
                                value={[startDate, endDate]}
                                size="default" format="MMMM DD YYYY"
                                onChange={(value) => {
                                    setStartDate(dayjs(value[0]).utc(true).startOf("day"));
                                    setEndDate(dayjs(value[1]).utc(true).startOf("day"));
                                }}
                            />
                            {modalErrors.filter(err => err.id === "date_range").map((item, index) => {
                                return <Error key={index} errorKey={index} error={item.message} />
                            })}
                        </div>
                        
                        {/* Max Capacity */}
                        <BasicInput id="max_capacity" label="Max Capacity" value={maxCapacity?? ""}
                            disabled={false} onBlur={(e) => setMaxCapacity(e.target.value)}
                            onChange={(e) => setMaxCapacity(e.target.value)} type={"number"} />
                        {modalErrors.filter(err => err.id === "max_capacity").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}
                    </>}

                    {/* Content: Delete */}
                    {isDeleteModal && <div style={{ padding: 20, textAlign: "center" }}>
                        <p>Are you sure you want to delete the max capacity for</p>
                        <p><strong>{dayjs(startDate).format("MMMM DD, YYYY")}</strong> - <strong>{dayjs(endDate).format("MMMM DD, YYYY")}</strong>?</p>
                    </div>}

                    {/* Cancel, Submit, Delete Buttons */}
                    <div className="light-form-buttons" style={{ marginTop: isDeleteModal ? 20 : 40 }}>
                        <ActionButton type="cancel" action={() => onCancel()} text="Cancel" />
                        <span style={{ width: 20 }} />
                        <ActionButton type="submit" action={() => handleModalSubmit()}
                            text={isDeleteModal ? "Delete" : "Submit"} />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default RoomInventoriesModal;
