import React from 'react';
import Select from 'elements/Select';
import constants from 'constants.js';

const RoomTypeFilter = ({ value, roomTypes = [], handleChange }) => {
    const options = [...roomTypes].map(option => {
        return { 
            ...option, 
            label: `${option.name} (${option.code})`,
            display: option.code
        }
    })
    // Add ALL ROOM TYPES option at the beginning of options array.
    options.unshift({
        name: constants.ALLROOMTYPES,
        value: constants.ALLROOMTYPES,
        label: constants.ALLROOMTYPES,
        display: 'ALL'
    });

    return <div className='rms-select-filter'>
        <Select
            value={value}
            onChange={handleChange}
            suffixIcon={null}
            optionLabelProp='display'
            options={options.map(item => ({ ...item, title: null }))}
        />
    </div>
}

export default RoomTypeFilter;