import axios from "axios";
import { useDispatch } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";

import constants from "../../constants";



const useDashboardActions = () => {
    const dispatch = useDispatch();

    const { getTokenSilently } = useAuth0();

    const getDailyData = async filter => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_DAILY_DATA_REQUEST,
                constants.GET_DAILY_DATA_SUCCESS,
                constants.GET_DAILY_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    };

    const getWeeklyData = async (filter, additional) => {
        const token = await getTokenSilently();
        const data = { op: "weekly", filter };
        const result = dispatch({
            types: [
                constants.GET_WEEKLY_DATA_REQUEST,
                constants.GET_WEEKLY_DATA_SUCCESS,
                constants.GET_WEEKLY_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }),
            payload: {
                additional
            }
        });
        return result;
    };

    const getMonthlyData = async (filter, additional) => {
        const token = await getTokenSilently();
        const data = { op: "monthly", filter };
        const result = dispatch({
            types: [
                constants.GET_MONTHLY_DATA_REQUEST,
                constants.GET_MONTHLY_DATA_SUCCESS,
                constants.GET_MONTHLY_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }),
            payload: {
                additional
            }
        });
        return result;
    };

    const getCustomDailyData = async filter => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_CUSTOM_DAILY_DATA_REQUEST,
                constants.GET_CUSTOM_DAILY_DATA_SUCCESS,
                constants.GET_CUSTOM_DAILY_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/custom`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    };

    const getVisualSegmentsData = async filter => {
        const token = await getTokenSilently();
        const data = { op: "segments", filter };
        const result = dispatch({
            types: [
                constants.GET_CHART_SEGMENTS_DATA_REQUEST,
                constants.GET_CHART_SEGMENTS_DATA_SUCCESS,
                constants.GET_CHART_SEGMENTS_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/segments`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    };

    const getStatisticsSegmentsData = async filter => {
        const token = await getTokenSilently();
        const data = { op: "segments", filter };
        const result = dispatch({
            types: [
                constants.GET_STATISTICS_SEGMENTS_DATA_REQUEST,
                constants.GET_STATISTICS_SEGMENTS_DATA_SUCCESS,
                constants.GET_STATISTICS_SEGMENTS_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/statistics/segments`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    };

    const getVisualComPriceData = async filter => {
        const token = await getTokenSilently();
        const data = { op: "comprice_by_do", filter };
        const result = dispatch({
            types: [
                constants.GET_CHART_COMP_PRICE_BY_DAYS_OUT_REQUEST,
                constants.GET_CHART_COMP_PRICE_BY_DAYS_OUT_SUCCESS,
                constants.GET_CHART_COMP_PRICE_BY_DAYS_OUT_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/compricing`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    };

    const getReservationsList = async filter => {
        const token = await getTokenSilently();
        const data = { op: "reservations", filter };
        const result = dispatch({
            useCache: true,
            resetCache: true,
            types: [
                constants.GET_RESERVATION_LIST_REQUEST,
                constants.GET_RESERVATION_LIST_SUCCESS,
                constants.GET_RESERVATION_LIST_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/reservations`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    }

    const getDaily = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_DAILY_REQUEST,
                constants.GET_DAILY_SUCCESS,
                constants.GET_DAILY_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/daily`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getCurrentAvgs = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        console.log("filter: ", filter)
        const result = dispatch({
            types: [
                constants.GET_CURRENT_AVGS_DATA_REQUEST,
                constants.GET_CURRENT_AVGS_DATA_SUCCESS,
                constants.GET_CURRENT_AVGS_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/avgsc`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getForecastAvgs = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_FORECAST_AVGS_DATA_REQUEST,
                constants.GET_FORECAST_AVGS_DATA_SUCCESS,
                constants.GET_FORECAST_AVGS_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/avgsf`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getVarianceAvgs = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_INSTANCES_AVGS_DATA_REQUEST,
                constants.GET_INSTANCES_AVGS_DATA_SUCCESS,
                constants.GET_INSTANCES_AVGS_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/avgsv`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getVariancesByDo = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_VARIANCES_BY_DAYSOUT_DATA_REQUEST,
                constants.GET_VARIANCES_BY_DAYSOUT_DATA_SUCCESS,
                constants.GET_VARIANCES_BY_DAYSOUT_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/variances`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getVariants = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_VARIANTS_DATA_REQUEST,
                constants.GET_VARIANTS_DATA_SUCCESS,
                constants.GET_VARIANTS_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/variants`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getSDLY = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_SDLY_DATA_REQUEST,
                constants.GET_SDLY_DATA_SUCCESS,
                constants.GET_SDLY_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/sdly`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getInstances = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.GET_INSTANCES_DATA_REQUEST,
                constants.GET_INSTANCES_DATA_SUCCESS,
                constants.GET_INSTANCES_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/instances`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const computeForecasts = async (filter) => {
        const token = await getTokenSilently();
        const data = { op: "daily", filter };
        const result = dispatch({
            types: [
                constants.COMPUTE_FORECASTS_REQUEST,
                constants.COMPUTE_FORECASTS_SUCCESS,
                constants.COMPUTE_FORECASTS_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/forecast`, data, {
                    headers: { Authorization: `Bearer ${token}` },
                    cancelToken: filter.cancelToken
                })
        });
        return result;
    };

    const getBookingChart = async (view, filter) => {
        const token = await getTokenSilently();
        const data = { op: view, filter };
        const result = dispatch({
            types: [
                constants.GET_BOOKINGCHART_DATA_REQUEST,
                constants.GET_BOOKINGCHART_DATA_SUCCESS,
                constants.GET_BOOKINGCHART_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/bookingchart`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    };

    const getComparison = async (view, filter) => {
        const token = await getTokenSilently();
        const data = { op: view, filter };
        const result = dispatch({
            types: [
                constants.GET_COMPARISON_DATA_REQUEST,
                constants.GET_COMPARISON_DATA_SUCCESS,
                constants.GET_COMPARISON_DATA_FAILURE
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/comparison`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        });
        return result;
    };



    return {
        getDailyData,
        getMonthlyData,
        getWeeklyData,
        getCustomDailyData,
        getVisualSegmentsData,
        getVisualComPriceData,
        getStatisticsSegmentsData,
        getReservationsList,
        getDaily,
        getSDLY,
        getCurrentAvgs,
        getForecastAvgs,
        getInstances,
        getVariants,
        getVarianceAvgs,
        getVariancesByDo,
        computeForecasts,
        getBookingChart,
        getComparison
    };
};

export default useDashboardActions;
