import React from "react";

export default function Switch({ checked, onChange, disabled = false }) {
    const handleChange = (e) => {
        onChange(e.target.checked);
    };

    return (
        <div data-html2canvas-ignore className="switch-wrapper">
            <label className="switch">
                <input type="checkbox" disabled={disabled} checked={checked} onChange={handleChange} />
                <span className="switch__slider round"></span>
            </label>
        </div>
    );
}
