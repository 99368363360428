import React from "react";
import PropTypes from "prop-types";
import constants from "constants.js";
import { withCurrency } from "utils/index";
import { Chart } from "react-google-charts";
import "./PieChart.scss";


/**
 * Pie chart component.
 *
 * @function PieChart
 * @param {String} chartName Identifier for component customizations.
 * @param {[Object]} data Array of pie chart data, defaults to an empty array.
 * @param {String} title Pie chart title, defaults to an empty string.
 * @param {Number} radius Pie chart radius, defaults to "500px".
 * @return {Component} Pie chart component.
*/
const PieChart = ({ chartName = "", data = [], title = "", radius = "500px" }) => {
    let chartData = transformData(data, chartName);

    return (
        <div className="rev-pie-chart">
            <span className="title">{title}</span>
            <Chart
                width={radius}
                height={radius}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={chartData.data}
                options={{
                    legend: "none",
                    tooltip: chartData.isEmptyData? { trigger: "none" }: { isHtml: true },
                    backgroundColor: 'transparent',
                    // title: title,
                    titleTextStyle: {
                        fontName: "Titillium Web",
                        fontSize: 16,
                        bold: true,
                        color: "#282F5B"
                    },
                    enableInteractivity: !chartData.isEmptyData,
                    pieSliceText: "label",
                    pieSliceTextStyle: {
                        fontName: "Lato",
                        fontSize: 10,
                        color: "#282F5B"
                    },
                    pieHole: chartData.isEmptyData? .97: 1,
                    slices: chartData.colors,
                }}
                rootProps={{ "data-testid": "1" }}
            />
        </div>
    );
}


PieChart.propType = {
    chartName: PropTypes.string,
    radius: PropTypes.string,
    data: PropTypes.array,
};

/**
 * Creates basic tooltip content. Composing multiple label and value pair.
 * @param {[Object]} data label and value pair
 * @returns {String}
 */
const generateTooltip = (data = []) => {
    let tooltip = '';
    data.forEach((item) => {
        tooltip += `<p>${item.label}: <b>${item.value}</b></p>`
    });
    return tooltip;
}
/**
 * Reconstruct data to match requirements for adding custom tooltip.
 * @param {[Object]} data Array of pie chart data, defaults to an empty array.
 * @param {[String]} chartName Name of pie chart. Used for tooltip customization.
 * @returns {Object} { data: [], colors: [] }
 */
const transformData = (data = [], chartName = '') => {
    const chartHeader = [];
    const chartData = [];
    const colors = [];
    const chartsWithCustomTooltip = {
        dashboard_otb_occ: { label: 'Rooms', percentLabel: '% Rooms', addCurrency: false },
        dashboard_otb_rev: { label: 'Revenue', percentLabel: '% Revenue', addCurrency: true }
    }

    let isZeroValue = true;
    if (data.length > 0) {
        // This is for occupancy and revenue data transformation.
        if (Object.keys(chartsWithCustomTooltip).includes(chartName)) {
            const chartInfo = chartsWithCustomTooltip[chartName];
            chartHeader.push('Category');
            chartHeader.push('Value');
            chartHeader.push({ role: 'tooltip', p: { html: true } });
            data.forEach((item, i) => {
                if (item.label !== constants.ALLSEGMENTS) {
                    if (item.value < 0) item.value = 0;
                    if (item.value > 0) isZeroValue = false;
                    let percent = Math.round((item.value / item.total) * 100);
                    chartData.push([`${item.label}, ${percent}%`, item.value, generateTooltip([
                        { label: 'Segment', value: item.label },
                        { label: chartInfo.label, value: chartInfo.addCurrency ? withCurrency(item.value) : item.value },
                        { label: chartInfo.percentLabel, value: `${percent}%` }
                    ])]);
                    colors.push({ color: item.color });
                }
            });
        }

        if (isZeroValue) {
            return { data: [["Empty", "Value"], ['', 1]], colors: [{ color: "#dadada" }], isEmptyData: isZeroValue };
        }
    }

    return { data: [chartHeader, ...chartData], colors: colors, isEmptyData: isZeroValue };
}

export default PieChart;

