// import moment from "moment";
import dayjs from "dayjs";

import constants from "../constants";
import { isNullOrUndefined } from "./index";
import { getSegmentGroupOptionText, getSegmentOptionText } from "./segments";

const ALLSEGMENTS = constants.ALLSEGMENTS;
const SEGMENTGROUPS = constants.SEGMENTGROUPS;
const INDIVSEGMENTS = constants.INDIVSEGMENTS;
const ALLROOMTYPES = constants.ALLROOMTYPES;



/**
 * Default View Tabs data
 *
 * @param {type} type View tab type. Either daily or aggregate. Defaults to daily.
 * @return {[Object]} Default view tabs data
*/
export function defaultViewTabs(type = "daily") {
    return [
        {
            key: "cells",
            label: type === "daily" ? "Calendar" : "Cards",
        },
        {
            key: "table",
            label: "Table",
        },
    ]
}


/**
 * Default Daily Tabs data
 *
 * @param {type} paceType Pace type
 * @return {[Object]} Default daily tabs data
*/
export function defaultDailyTabs(paceType = "") {
    let pace = "pace";
    if (paceType === "averageroomsold") pace = "average_occ";
    if (paceType === "averagedowroomsold") pace = "average_occ_dow";

    return [
        {
            key: "overview",
            label: "Overview",
            visible: true
        },
        {
            key: "pickup",
            label: "Pickup",
            visible: true
        },
        {
            key: pace,
            label: "Pace",
            visible: true
        },
        {
            key: "rate",
            label: "Price",
            visible: true
        }
    ]
}


/**
 * Generates a Filter data object
 *
 * @param {String} id Property ID
 * @param {Date} asof As-Of date
 * @param {[Date]} checkin Check-In Date Range
 * @param {String} pickup User-selected pickup
 * @param {Date} pace Pace
 * @param {[String]} segments List of user-selected segments
 * @param {String} roomtype User-selected room type
 * @param {Object} userPrefs User account preferences
 * @return {[Object]} Default daily tabs data
*/
export function generateFilter(id, asof, checkin, pickup, pace, segments, roomtype, userPrefs, group, paceType, includeReservations) {
    const isGroup = Array.isArray(id);
    // When requesting to API, dates should be converted to UTC to avoid confusions.
    // In database (MONGODB), dates are stored in UTC format. 
    // Just think that the dates written in DB are the properties' actual time or date (regardless of timezone)
    // Booking Chart does not use this filter... We can refactor it later.
    const utcToLocal = (date) => dayjs(date).isUTC()? dayjs.utc(date).local(true): dayjs(date);
    return {
        group: isGroup,
        property: isGroup ? group : id,
        properties: isGroup ? id : [id],
        asof: dayjs.utc(asof),
        checkin: [
            dayjs.utc(checkin[0]), 
            dayjs.utc(checkin[1])
        ],
        pace: dayjs.utc(pace),
        pickup: pickup,
        pace_type: paceType,
        segments: segments,
        roomtype: roomtype,
        forecast: isGroup
            ? (!(userPrefs?.forecastGroupDisabled?? false) )
            : (!(userPrefs?.forecastPropertyDisabled?? false) ),
        version: userPrefs?.appVersion ?? "v2",
        colorscore: userPrefs?.colorScoreEnabled ?? false,
        include_reservations: includeReservations
    }
}


/**
 * Default Pickup Filter options data
 *
 * @return {[Object]} Default pickup filter options data
*/
export const defaultPickupFilterOptions = [
    {
        name: "1 DAY",
        value: "-1"
    },
    {
        name: "3 DAYS",
        value: "-3"
    },
    {
        name: "7 DAYS",
        value: "-7"
    },
    {
        name: "14 DAYS",
        value: "-14"
    },
    {
        name: "28 DAYS",
        value: "-28"
    }
]


/**
 * Default Pace Filter options data
 *
 * @return {[Object]} Default pace filter options data
*/
export const defaultPaceFilterOptions = [
    {
        code: "SDLY",
        name: "Same Day Last Year",
        label: "SDLY",
        value: "samedaylastyear"
    },
    {
        code: "LY",
        name: "Last Year",
        label: "Last Year",
        value: "lastyear"
    },
    // {
    //     code: "SPLY",
    //     name: "Same Period Last Year",
    //     value: "sameperiodlastyear"
    // },
    // {
    //     code: "SDLY",
    //     name: "Same Date Last Year",
    //     value: "samedatelastyear"
    // },
    {
        code: "AVG",
        name: "Average",
        label: "AVG",
        value: "averageroomsold"
    },
    {
        code: "AVG DOW",
        name: "Average Day-of-Week",
        label: "AVG DOW",
        value: "averagedowroomsold"
    }
]


/**
 * Default Segment Filter options data.
 *
 * @function defaultSegmentFilterOptions
 * @return {[Object]} Default segment filter options data
*/
export function defaultSegmentFilterOptions(propertyInfo, groupSegmentCodes, individualSegmentCodes, isGroup) {
    const moveUnsegmentedToLastPosition = (a, b) => {
        if (b.code == "UNS") return -1;
        if (a.code == "UNS") return 1;
        return 0;
    }

    return [{
        display: SEGMENTGROUPS,
        value: SEGMENTGROUPS,
        // key: SEGMENTGROUPS,
        children: [...groupSegmentCodes].sort(moveUnsegmentedToLastPosition).map((s, index) => {
            const segments = isGroup ? groupSegmentCodes : propertyInfo.segment_groups;
            return {
                display: getSegmentGroupOptionText(segments, s.code, isGroup),
                value: `GROUP-${s.code}`,
                // key: `group_${index}`
            }
        })
    },
    {
        display: INDIVSEGMENTS,
        value: INDIVSEGMENTS,
        // key: INDIVSEGMENTS,
        children: [...individualSegmentCodes].sort(moveUnsegmentedToLastPosition).map((s, index) => {
            const segments = isGroup ? individualSegmentCodes : propertyInfo.segments;
            return {
                display: getSegmentOptionText(segments, s.code, isGroup),
                value: s.code,
                // key: `indiv_${index}`
            }
        })
    }];
}


/**
 * Handles display of selected filter option.
 *
 * @function displaySelectedOption
 * @param {String} type Filter type
 * @param {String} value Filter value
 * @return {String} Text to display based on the selected filter value
*/
export function displaySelectedOption(type, value) {
    switch (type) {
        case "pace":
            if (value === "lastyear") return "LAST YEAR";
            if (value === "averageroomsold") return "AVG";
            if (value === "averagedowroomsold") return "AVG DOW";
            return "SDLY";
        case "segment":
            if (value === ALLSEGMENTS) return "ALL";
            return value;
        case "roomtype":
            if (value === ALLROOMTYPES) return "ALL";
            return value;
        default:
            return value;
    }
}


/**
 * Handles retrieval of user custom filters from local storage.
 *
 * @function getUserCustomFilters
 * @return {[Object]} User custom filters
*/
export function getUserCustomFilters() {
    return JSON.parse(localStorage.getItem("userCustomFilters"));
}


/**
 * Handles saving of complete user custom filters to local storage.
 * Updates both the 'userCustomFilters' and 'authUser' local storage values.
 *
 * @function saveUserCustomFiltersToLocalStorage
 * @return {[Object]} User custom filters
*/
export function saveUserCustomFiltersToLocalStorage(filters) {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (!isNullOrUndefined(authUser)) {
        authUser.cf = filters;
        localStorage.setItem("authUser", JSON.stringify(authUser));
    }
    localStorage.setItem("userCustomFilters", JSON.stringify(filters));
}


/**
 * Handles filtering of user custom filters by property.
 *
 * @function getUserCustomFiltersByProperty
 * @param {[Object]} filters User custom filters data object
 * @param {String} hotelId Property ID
 * @return {[Object]} User custom filters for the property
*/
export function getUserCustomFiltersByProperty(filters, hotelId) {
    if (hotelId && filters && Array.isArray(filters) && filters.length > 1) {
        let userCustomFiltersByProperty = filters.filter((filter) => {
            return filter.property === hotelId;
        })
        return userCustomFiltersByProperty;
    }
    return [];
}