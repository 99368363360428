import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastsStore } from "react-toasts";

import ListHeader from "elements/Text/ListHeader";
import IconButton from "elements/Button/Icon";

import useProperties from "../../store/actions/propertiesActions";
import constants from "../../constants";
import { sortArray } from "utils/data";


/**
 * Room mappings list component.
 *
 * @param {[Object]} roomtypes List of room types data object.
 * @param {Object} user Rev&You user data object.
 * @param {Function} onAdd Event action after clicking the Add Room Mapping button.
 * @param {Function} onView Event action after clicking the View Room Mapping button.
 * @param {Function} onEdit Event action after clicking the Edit Room Mapping button.
 * @param {Function} onDelete Event action after clicking the Delete Room Mapping button.
 * @param {Function} onUpdateBarStatus Event action after clicking the Update Room Mapping Bar Status button.
 * @return {Component} Room mappings list component.
*/
const RoomMappingsList = ({ roomtypes, user, onAdd, onView, onEdit, onDelete, onUpdateBarStatus }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const { propertyInView } = state;
    const { updateRoomMappings } = useProperties();

    let hasRoomTypes = roomtypes && Array.isArray(roomtypes) && roomtypes.length > 0;
    let isAdmin = user.type === "ope_admin";
    let isHotelUser = user.type === "hotel_user";
    let hasUpdateAccess = isHotelUser || isAdmin;


    const updateListItemInView = (mapping, rt) => {
        dispatch({
            type: constants.UPDATE_LIST_ITEM_IN_VIEW,
            value: {
                mapping: mapping,
                roomtype: rt
            }
        });
    }

    const handleEditRoomMapping = (mapping, rt) => {
        updateListItemInView(mapping, rt);
        onEdit();
    }

    const handleDeleteRoomMapping = (mapping, rt) => {
        updateListItemInView(mapping, rt);
        onDelete();
    }

    const handleUpdateRoomMappingBarStatus = (mapping, rt) => {
        let mappings = rt.mappings.map((m) => {
            let isPartnerMatch = m.partner_code === mapping.partner_code;
            let isRoomTypeCodeMatch = m.room_type_code === mapping.room_type_code;
            let isRoomTypeNameMatch = m.room_type_name === mapping.room_type_name;

            m.bar = isPartnerMatch && isRoomTypeCodeMatch && isRoomTypeNameMatch;
            return m;
        });

        let data = { ...rt, mappings: mappings };

        updateRoomMappings(propertyInView._id, {
            op: "edit",
            type: "roommapping",
            filter: data
        }).then((res) => {
            if (res.type === constants.UPDATE_PROPERTY_ROOM_MAPPINGS_SUCCESS) {
                onUpdateBarStatus();
                ToastsStore.success("Room mapping bar status successfully updated");
            } else {
                ToastsStore.error("Sorry, we're unable to update the room mapping at this time");
            }
        });
    }



    return (
        <div>
            <ListHeader headerCopy="Add Room Mapping" component={
                <IconButton type="add" hasAccess={hasUpdateAccess} action={onAdd} width="25px" />
            } />

            <table className="forms-table">
                <thead>
                    <tr style={{ marginBottom: 8 }}>
                        <th>Room Code</th>
                        <th>Room Name</th>
                        <th>Channel</th>
                        <th style={{ marginLeft: 100 }}>Channel Room Code</th>
                        <th style={{ marginLeft: 100 }}>Channel Room Name</th>
                        <th style={{ textAlign: "right" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasRoomTypes && sortArray(roomtypes, "room_type").map((rt, index) => {
                        return <div key={`${rt.room_type_code}-${rt.room_type}-${index}`}>
                            <tr key={`${rt.room_type_code}-${rt.room_type}-${index}`} style={{ marginTop: 8 }}>
                                <td>{rt.room_type_code}</td>
                                <td style={{ marginLeft: -50, maxWidth: "16ch", wordWrap: "break-word" }}>
                                    {rt.room_type}
                                </td>
                                <td style={{ display: "grid", gridTemplateColumns: "95% 5%", marginLeft: 10 }}>
                                    {(rt.room_type_code !== "UMP") && <>
                                        <strong style={{ width: "max-content" }}>
                                            Add another room mapping
                                        </strong>
                                        <div style={{ marginTop: 2 }}>
                                            <IconButton type="plus" hasAccess={hasUpdateAccess} width="18px"
                                                action={() => handleEditRoomMapping(null, rt)} />
                                        </div>
                                    </>}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {sortArray(rt.mappings, "partner_code").map((mapping, index) => {
                                const isBar = mapping?.bar ?? false;
                                return (mapping.partner_code !== "REVANDYOU") && (
                                    <tr key={`${rt.room_type_code}-${mapping.partner_code}-${mapping.room_type_code}-${index}`}>
                                        <td></td>
                                        <td></td>
                                        <td>{mapping.partner_code}</td>
                                        <td style={{ marginLeft: 100 }}>{mapping.room_type_code}</td>
                                        <td style={{ marginLeft: 100 }}>{mapping.room_type_name}</td>
                                        <td style={{
                                            display: "grid", gridTemplateColumns: "20% 20% 20% 20% 20%",
                                            justifyItems: "end"
                                        }}>
                                            <span></span>
                                            <span></span>
                                            <IconButton type="star" hasAccess={isAdmin} data={{ isBar }}
                                                action={() => handleUpdateRoomMappingBarStatus(mapping, rt)} />
                                            {isAdmin
                                                ? <IconButton type="edit" hasAccess={isAdmin}
                                                    action={() => handleEditRoomMapping(mapping, rt)} />
                                                : <IconButton type="view" hasAccess={isHotelUser} action={onView} />
                                            }
                                            <IconButton type="delete" hasAccess={isAdmin}
                                                action={() => handleDeleteRoomMapping(mapping, rt)} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </div>
                    }
                    )}

                    {!hasRoomTypes && <tr>
                        <td>No room types found</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
};

export default RoomMappingsList;
