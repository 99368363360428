export const _operation = {
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete'
}

export const _config = {
    [_operation.ADD]: {
        title: 'Add Room Type',
        submitLabel: 'Add'
    },
    [_operation.EDIT]: {
        title: 'Edit Room Type',
        submitLabel: 'Edit'
    },
    [_operation.DELETE]: {
        title: 'Delete Room Type',
        submitLabel: 'Delete'
    }
}