import React from 'react';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';

const getPageRange = (page, limit, totalCount) => {
    const multiplier = (page - 1) * limit;
    const pageStart = (multiplier) + 1;
    const pageEnd = (multiplier) + limit;

    return { 
        start: pageStart, 
        end: pageEnd > totalCount? totalCount: pageEnd 
    };
}

const BasicPagination = ({ isLoading = false, limit, page = 0, totalCount, eventHandler}) => {

    const navigatorStyle = { fontSize: '12px', color: '#fff', cursor: "pointer" };
    const disabledStyle = { ...navigatorStyle, color: '#868686', cursor: "unset" };
    
    const { start: pageStart, end: pageEnd } = getPageRange(page, limit, totalCount);

    const disablePrev = isLoading || pageStart <= 1;
    const disableNext = isLoading || pageEnd >= totalCount || totalCount <= 0;

    const handleOnClick = (direction = 'next') => {
        if (direction === 'next' && disableNext) return;
        if (direction === 'prev' && disablePrev) return;
        if (eventHandler) {
            // Recompute page range for next/previous page
            const { start, end } = getPageRange(page + (direction === 'next'? 1: -1), limit, totalCount);
            eventHandler({ action: `paginate_${direction}`, start, end });
        }
    }

    const renderLoading = () => {
        return <span>
            <OutlinedIcon type='loading' style={{ fontSize: '14px', color: '#808285'}}/>
        </span>
    }

    const renderDataRange = () => {
        if (totalCount <= 0) return <span> 0 </span>
        return <span>{pageStart} - {pageEnd}</span>
    }

    return <div style={{ display: 'flex', gap: '5px' }}>
        <span>Viewing</span>
        {/* Previous Button */}
        <OutlinedIcon type='left' style={disablePrev? disabledStyle: navigatorStyle} 
            onClick={() => handleOnClick('prev')}
        />
        {/* Loading */}
        {isLoading? renderLoading(): renderDataRange()}
        {/* Next Button */}
        <OutlinedIcon type='right' style={disableNext? disabledStyle: navigatorStyle}
            onClick={() => handleOnClick('next')}
        />
        {/* Display Total Count */}
        <span>of</span>
        <span>{totalCount}</span>
    </div>
}

export default BasicPagination;