import React from 'react';

import Switch from './FieldTypes/Switch';
import Textbox from './FieldTypes/Textbox';
import Dropdown from './FieldTypes/Dropdown';
import RadioGroup from './FieldTypes/RadioGroup';

const FormField = ({ label, name, value, type, min, error, disabled = false, inputClass = "", className = "",
    items = [], config = {}, eventHandler = () => {}
}) => {

    const inputErrorClassName = "form-field-input-error";
    const labelErrorClassName = "form-field-label-error";

    const Error = ({ error }) => <>
        <div className="form-field-error">
            {error}
        </div>
    </>;

    switch(type.toLowerCase()) {
        case "textbox":
        case "numberbox": 
            return (
            <div className={`form-field ${className}`}>
                <div className={`form-field-label ${error? labelErrorClassName: ''}`}>
                    <span/>{label?? ""}
                </div>
                <Textbox 
                    name={name} 
                    inputClass={`${inputClass} ${error? inputErrorClassName: ''}`}
                    value={value} 
                    min={min}
                    disabled={disabled} 
                    numberOnly={type == "numberbox"}
                    onChange={(name, value) => {eventHandler({ action: "onChange", name, value })}}
                    onBlur={(name, value) => {eventHandler({ action: "onBlur", name, value })}}
                />
                <Error error={error}></Error>
            </div>)
        case "dropdown":
            return (
            <div className={`form-field ${className}`}>
                <div className={`form-field-label ${error? labelErrorClassName: ''}`}>
                    <span/>{label?? ""}
                </div>
                <Dropdown
                    name={name} 
                    inputClass={`${inputClass} ${error? inputErrorClassName: ''}`} 
                    value={value} 
                    disabled={disabled} 
                    items={items} 
                    config={config} 
                    onChange={(name, value) => {eventHandler({ action: "onChange", name, value })}} 
                />
                <Error error={error}></Error>
            </div>);
        case "switch":
        case "switchonly":
            return (
            <div className={`form-field ${className}`}>
                <div className={`form-field-label ${error? labelErrorClassName: ''}`}>
                    <span/>{label?? ""}
                </div>
                <Switch
                    name={name} 
                    inputClass={`${inputClass} ${error? inputErrorClassName: ''}`} 
                    value={value} 
                    disabled={disabled}
                    switchOnly={type == "switchonly"}
                    onChange={(name, value) => {eventHandler({ action: "onChange", name, value })}} 
                />
                <Error error={error}></Error>
            </div>);
        case "radiogroup": 
            return (
            <div className={`form-field ${className}`}>
                <div className={`form-field-label ${error? labelErrorClassName: ''}`}>
                    <span/>{label?? ""}
                </div>
                <RadioGroup
                    name={name} 
                    inputClass={`${inputClass} ${error? inputErrorClassName: ''}`} 
                    value={value} 
                    disabled={disabled} 
                    items={items}  
                    onChange={(name, value) => {eventHandler({ action: "onChange", name, value })}} 
                />
                <Error error={error}></Error>
            </div>);
        default: 
            return <></>
    }
    
}

export default FormField;