import React from "react";
import { Select } from "antd";

import { isNullOrUndefined } from "utils";

const { Option } = Select;


/**
 * A basic 'Select' element.
 *
 * @param {String} id Element ID. Defaults to ''.
 * @param {Object} actualValue The actual value of the 'Select' element. Defaults to ''.
 * @param {Function} handleChange Function that triggers when value of the 'Select' element changes.
 * @param {[Object]} options An array of objects to be displayed as Options inside the 'Select' element. Defaults to [].
 * @param {Boolean} withComma Displays a comma after the 'Select' element if true. Defaults to false.
 * @param {Boolean} hidden Hide the select element; useful in some cases. Defaults to false.
 * @return {Object} A basic 'Select' element.
*/
const BasicSelect = ({ id = '', actualValue = '', handleChange = null, options = [], withComma = false, hidden = false, size = 'default' }) => {

    return (
        <div className="rms-select-filter">
            <Select id={id}
                size={size}
                value={actualValue}
                onChange={handleChange}
                popupMatchSelectWidth={false}
                style={hidden
                    ? {
                        overflow: 'hidden',
                        position: 'absolute',
                        clip: 'rect(0 0 0 0)',
                        height: '1px',
                        width: '1px',
                        padding: 0,
                        border: 0,
                        zIndex: 10,
                    }
                    : null
                }
            >
                {(options.length > 0) && options.map((option, index) => {
                    return !isNullOrUndefined(option) && <Option key={index} value={option.value}>
                        <span>{option.name}</span>
                    </Option>
                })}
            </Select>
            {withComma && <span style={{ marginLeft: -20, marginRight: 8 }}>,</span>}
        </div>
    );
};

export default BasicSelect;
