import _ from "lodash";

export default function validate(values, fields) {
    let errors = {};

    Object.keys(values).map(key => {
        const field = _.find(fields, ["key", key]);

        if (field) {
            const requirements = field.validation;
            requirements.map(requirement => {
                switch (requirement) {
                    case "required":
                        if (!values[key]) {
                            errors[key] = field.label + " is required";
                        }
                        break;
                    case "totalOf100":
                        let total = parseInt(values[key]);
                        field.totalOf100with.length > 0 &&
                            field.totalOf100with.forEach(i => {
                                total += parseInt(values[i]);
                            });
                        if (total !== 100) { errors[key] = "Weights should sum up to 100%"; }
                        break;
                    case "email":
                        if (!validateEmail(values[key])) {
                            errors[key] = "Field should be in an email format";
                        }
                        break;
                    case "confirmPassword":
                        if (values[key] !== values.confirmPassword) {
                            errors[key] = "Password did not match";
                        }
                        break;
                    case "newPassword":
                        if (values[key] !== values.newPassword) {
                            errors[key] = "Password did not match";
                        }
                        break;
                    case "3-5char":
                        if (values[key].length < 3 || values[key].length > 5) {
                            errors[key] = "Should contain 3-5 characters";
                        }
                        break;
                    case "greaterThan30":
                        if (values[key] < 30) {
                            errors[key] = "Minimum of 30 days out";
                        }
                        break;
                    case "greaterThanField":
                        if (!(values[key] > values[field.greaterThanField])) {
                            errors[key] = `Should be greater than ${values[field.greaterThanField]}`;
                        }
                        break;
                    case "lessThanField":
                        if (!(values[key] > values[field.lessThanField])) {
                            errors[key] = `Should be less than ${values[field.lessThanField]}`;
                        }
                        break;
                    case "greaterThanEqualToField":
                        if (values[key] < values[field.greaterThanEqualToField]) {
                            errors[key] = `Should be greater than or equal to ${values[field.greaterThanEqualToField]}`;
                        }
                        break;
                    case "lessThanEqualToField":
                        if (values[key] > values[field.lessThanEqualToField]) {
                            errors[key] = `Should be less than or equal to ${values[field.lessThanEqualToField]}`;
                        }
                        break;
                    case "positive":
                        if (values[key] <= 0) {
                            errors[key] = "Should be a number greater than 0";
                        }
                        break;
                    case "max730":
                        if (values[key] > 730) {
                            errors[key] = "Maximum of 730";
                        }
                        break;
                    case "alphanumeric":
                        if (!/^[a-zA-Z0-9]*$/.test(values[key])) {
                            errors[key] = "Should contain alphanumeric characters";
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    });

    return errors;
}

function validateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
    }
    return false;
}
