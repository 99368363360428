import * as React from "react";

function SvgSearch(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <g data-name="Layer 1">
        <circle cx={49.96} cy={49.84} r={47.5} fill="#67b489" />
        <circle
          cx={43.81}
          cy={43.7}
          r={14.44}
          strokeWidth={8.4}
          fill="none"
          stroke="#fff"
          strokeMiterlimit={10}
        />
        <path
          strokeWidth={8}
          fill="none"
          stroke="#fff"
          strokeMiterlimit={10}
          d="M53.54 53.42l16.99 17"
        />
      </g>
    </svg>
  );
}

export default SvgSearch;
