import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Modal, Layout } from "antd";

import { Exit } from "Icons";
import Button from "../../components/Button/Button";

import useUsers from "../../store/actions/usersActions";

const { Content } = Layout;



const PropertyMappingModal = ({ user, visible, handleModal, loading }) => {
    const propertiesState = useSelector((state) => state.properties);
    const { updateUserProperty } = useUsers();
    const [selectedRows, setSelectedRows] = useState([]);
    useEffect(() => {
        setSelectedRows(user.properties);
    }, [user]);

    const clearModal = () => {
        handleModal({
            user: {
                name: "",
                properties: [],
            },
            visible: false,
        });
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRows(selectedRowKeys);
    };

    const handleSaveMapping = () => {
        updateUserProperty(user._id, {
            properties: selectedRows,
        }).then(() => clearModal());
    };

    const handleCancel = () => {
        clearModal();
    };

    const columns = [
        {
            title: "Property ID",
            dataIndex: "_id",
        },
        {
            title: "PropertyName",
            dataIndex: "name",
        },
    ];

    const rowSelection = {
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            name: record.name,
        }),
        selectedRowKeys: selectedRows,
    };

    return (
        <div className="property-mapping-modal-container">
            <Modal
                open={visible}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                onOk={handleSaveMapping}
                onCancel={handleCancel}
                width={700}
                footer={false}
            >
                <Layout>
                    <Content style={{ background: "#fff", padding: 0, minHeight: 150 }}>
                        <div className="rms-modal-content">
                            <div className="rms-modal-header">
                                <h3>{`Map Properties to ${user.name}`}</h3>
                            </div>
                            <div className="property-mapping-modal">
                                <Table
                                    loading={propertiesState.loading}
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    rowKey={(row) => row._id}
                                    dataSource={
                                        propertiesState.loading ? [] : propertiesState.properties
                                    }
                                    pagination={false}
                                />
                            </div>
                            <div className="rms-modal-footer">
                                <Button type="bg-light" onClick={handleCancel}>
                                    Cancel
                </Button>
                                <Button
                                    type="primary"
                                    loading={loading}
                                    onClick={handleSaveMapping}
                                >
                                    Save
                </Button>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </Modal>
        </div>
    );
};

export default PropertyMappingModal;
