/**
 * @author Jarbee Bejar
 */
import React, { useState, useEffect } from 'react';
import { isNullOrUndefined } from '../../utils';


const DataGridController = () => {
    const [internalState, setInternalState] = useState({
        hasError: false
    });
    const [loading, setLoading] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [rawData, setRawData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [cells, setCells] = useState([]);
    const [columns, setColumns] = useState([]);
    const [errors, setErrors] = useState({});
    const [sortInfo, setSortInfo] = useState({ columnId: null, value: 'ascend'});
    const [paginationInfo, setPaginationInfo] = useState({ enabled: false, number: 1, totalCount: 0, limit: null, view: { from: 0, to: 0 } });

    const loadData = (data, resetData = false, totalCount = 0) => {
        if (!Array.isArray(data)) { console.error('Data should be an array'); return; }

        setRawData(data);

        if (paginationInfo.enabled === true) {
            const pageViewInfo = getPageNumbers(resetData? 1: paginationInfo.number, paginationInfo.limit, data.length, totalCount);
            setPaginationInfo({ ...paginationInfo, totalCount: totalCount, view: pageViewInfo });
        }
    }

    const addNewData = (data) => {
        let tmpGridData = [...getGridData(), data];
        setGridData(alignDataWithColumns(tmpGridData));
    }

    const reload = () => {
        setGridData(alignDataWithColumns(rawData));
    }

    const getGridData = (rowIndex) => {
        const data = [];
        gridData.forEach((row, rowIdx) => {
            let cols = {};
            columns.forEach((column, colIdx) => {
                if ([
                    'empty', 
                    'action', 
                    'custom_actions', 
                    'action_edit', 
                    'action_delete'
                    ].includes(column.type)
                ) return;
                cols[column.id] = row[colIdx];
            });
            data.push(cols);
        });
        if (!isNullOrUndefined(rowIndex)) return data[rowIndex];
        return data;
    }

    const getRawData = (rowIndex) => {
        return rowIndex >= 0? rawData[rowIndex]: rawData;
    }
    const getData = (columnId, rowIdx = 0) => {
        let colIdx = columns.findIndex(col => col.id === columnId);
        return colIdx > -1 ? gridData[rowIdx][colIdx] : undefined;
    }

    const saveValueToGridData = (rowIdx, colIdx, value) => {
        if (gridData[rowIdx][colIdx] === value) return;
        let newGridData = [...gridData];
        newGridData[rowIdx][colIdx] = value;
        setGridData(newGridData);
    }

    const initializePagination = (enabled, limit) => {
        setPaginationInfo({ ...paginationInfo, enabled: enabled, limit: limit });
    }

    const selectSort = (columnId) => {
        let value = sortInfo.value;

        if (sortInfo.columnId != columnId) value = null;

        switch(value) {
            case 'descend': 
                value = null; break;
            case 'ascend': 
                value = 'descend'; break;
            default: 
                value = 'ascend';
        }

        // value = value === 'ascend'? 'descend': 'ascend';
        const result = { 
            columnId: value? columnId: null, 
            value: value 
        };
        setSortInfo(result);
        return result;
    }

    const getPageNumbers = (pageNumber, pageLimit, dataLength, totalCount) => {
        // Show all
        if (!(pageLimit > 0)) {
            return { from: 1, to: totalCount }
        }
        // No data stored
        if (!(dataLength> 0)) return { from: 0, to: 0 }

        let multiplier = pageNumber * pageLimit;
        let firstRowIndex = multiplier - pageLimit;

        let fromNumber = firstRowIndex + 1;
        let toNumber = firstRowIndex + dataLength;

        return { from: fromNumber, to: toNumber };
    }

    const paginate = (page) => {
        // No pagination if ALL data is already shown.
        // The numbers of data should depend in the gridData.
        if (paginationInfo.limit === null) { return; }

        let number = paginationInfo.number;
        switch(page) {
            case 'next': number++; break;
            case 'prev': number--; break;
        }
        
        const result = { ...paginationInfo, number: number };
        setPaginationInfo(result);
        
        return result;  
    }

    const triggerEvent = (event) => {
        let newInfo = null;
        switch(event.action) {
            case 'sort':
                let sortInfo = selectSort(event.columnId);
                newInfo = { sortId: sortInfo.columnId, sortVal: sortInfo.value };
            break;
            case 'paginate':    
                let pageInfo = paginate(event.value);
                newInfo = { pageNo: pageInfo.number, pageLimit: pageInfo.limit };
            break;
        }

        return getGridInfo(newInfo);
    }

    const getGridInfo = (newInfo) => {
        let info = {  
            sortId: sortInfo.columnId, 
            sortVal: sortInfo.value, 
            pageNo: paginationInfo.number, 
            pageLimit: paginationInfo.limit,
            pageView: paginationInfo.view
        };

        return typeof newInfo === "object"? { ...info, ...newInfo }: info; 
    }

    useEffect(() => {
        const defaultSort = columns.find(col => col.sortDefault === true);
        defaultSort?.enableSort === true && selectSort(defaultSort.id);
    }, [columns]);

    useEffect(() => {

    }, [paginationInfo])

    useEffect(() => {
        // console.log("GRIDCONTROLLER", internalState);
    }, [internalState]);

    useEffect(() => {
        if (rawData.length > 0 && columns.filter(column => !(column.hidden === true)).length === 0) {
            console.error(rawData, 'Unable to load data of grid: No columns found');
            return;
        }
        setGridData(alignDataWithColumns(rawData));
    }, [rawData]);

    useEffect(() => {
        // console.log(sortInfo)
    }, [sortInfo]);

    const alignDataWithColumns = (data) => {
        const columnData = data.map((datum, rowIdx) => columns.map(column => {
            if (['empty', 'action'].includes(column.type)) return;
            if (!isNullOrUndefined(column.dataField) && !isNullOrUndefined(datum[column.dataField]))
                return datum[column.dataField];
            if (!isNullOrUndefined(column.defaultValue) && isNullOrUndefined(datum[column.id]))
                return column.defaultValue;
            else if (isNullOrUndefined(datum[column.id]))
                return null;

            return datum[column.id];
        }));
        return columnData;
    }

    const generateCells = (cellConfigs) => {
        if (!(Array.isArray(gridData) && gridData.length > 0)) return [];
        return gridData.map((row, rowIdx) => {
            if (!Array.isArray(row)) return;
            let rowData = [];
            row.forEach((val, colIdx) => {
                const column = { ...columns[colIdx] };
                let value = val;
                if (column.hidden === true) return;
                if (column.copyFieldValue) {
                    let targetColumnIdx = columns.findIndex(col => col.id === column.copyFieldValue);
                    if (targetColumnIdx > -1) {
                        value = row[targetColumnIdx];
                    }
                }
                const cellConfig = cellConfigs.find(config => config.positionX === colIdx && config.positionY === rowIdx);
                // Override cell config
                if (cellConfig !== undefined) {
                    Object.keys(cellConfig).forEach(key => {
                        if (cellConfig[key] !== undefined) {
                            column[key] = cellConfig[key];
                        }
                    });
                }
                //Available configs here.
                let applyConfig = {
                    type: column.type,
                    items: column.items,
                    customActions: column.actions,
                    actionFn: column.actionFn,
                    linkFn: column.linkFn,
                    disabled: column.disabled === true ? true : false,
                    formatValue: column.formatValue,
                    render: column.render,
                    useDisplayField: column.useDisplayField,
                    validations: column.validations
                };
                // Default function for action columns
                if (column.type === "action" || column.type === "action_delete" || column.type === "action_edit") {
                    if (typeof applyConfig.actionFn !== "object") {
                        applyConfig.actionFn = {}
                        console.warn(`Column: ${column.id} - There is no configured actionFn found. Note: React states are private in functional component. Accessing the state from this function is useless.`);
                    }
                    if ((column.type === "action" || column.type === "action_edit") && typeof applyConfig.actionFn.onEdit !== "function") {
                        applyConfig.actionFn.onEdit = () => { };
                        console.warn(`Column: ${column.id} - There is no configured onEdit function found in actionFn`);
                    }
                    if ((column.type === "action" || column.type === "action_delete") && typeof applyConfig.actionFn.onDelete !== "function") {
                        applyConfig.actionFn.onDelete = (rowIdx) => {
                            let tmpGridData = [...gridData];
                            tmpGridData.splice(rowIdx);
                            setGridData(tmpGridData);
                        };
                        console.warn(`Column: ${column.id} - There is no configured onDelete function found in actionFn`);
                    }
                }

                rowData.push({
                    column: column.id,
                    type: applyConfig.type,
                    items: applyConfig.items,
                    customActions: applyConfig.customActions,
                    actionFn: applyConfig.actionFn,
                    linkFn: applyConfig.linkFn,
                    validations: applyConfig.validations,
                    disabled: applyConfig.disabled,
                    formatValue: applyConfig.formatValue,
                    render: applyConfig.render,
                    useDisplayField: applyConfig.useDisplayField,
                    value: value,
                    coordinates: { x: colIdx, y: rowIdx },
                    error: null
                });
            });
            return rowData;
        });
    }

    const validateCells = (generatedCells = cells) => {
        let errorsFound = [];
        generatedCells.forEach((row, rowIdx) => {
            if (!Array.isArray(row)) return;
            row.forEach((cell, colIdx) => {
                if (cell.type === 'empty' || !(cell.validations && Array.isArray(cell.validations))) return;
                cell.validations.forEach(validation => {
                    let error = null;
                    switch (validation.type) {
                        case 'required':
                            if (!!validation.value === true && (isNullOrUndefined(cell.value) || cell.value === '')) {
                                error = 'This field is required';
                            }
                            break;
                        case 'limit': {
                            if (cell.type === 'number') {
                                if (!isNullOrUndefined(validation.min) && Number(cell.value) < validation.min) {
                                    error = 'This field has a minimum value: ' + validation.min;
                                }
                                if (!isNullOrUndefined(validation.max) && Number(cell.value) > validation.max) {
                                    error = 'This field has a maximum value: ' + validation.max;
                                }
                            }
                            break;
                        }
                        case 'compare':
                            let compareColumnIdx = row.findIndex(targetCell => targetCell.column === validation.field);
                            if (compareColumnIdx < 0) break;
                            if (cell.type === 'number') {
                                if (validation.operator === 'equal_less_than_field') {
                                    if (!(parseFloat(cell.value) <= parseFloat(row[compareColumnIdx].value))) {
                                        error = validation.error;
                                    }
                                } else if (validation.operator === 'equal_greater_than_field') {
                                    if (!(parseFloat(cell.value) >= parseFloat(row[compareColumnIdx].value))) {
                                        error = validation.error;
                                    }
                                } else if (validation.operator === 'less_than_field') {
                                    if (!(parseFloat(cell.value) < parseFloat(row[compareColumnIdx].value))) {
                                        error = validation.error;
                                    }
                                } else if (validation.operator === 'greater_than_field') {
                                    if (!(parseFloat(cell.value) > parseFloat(row[compareColumnIdx].value))) {
                                        error = validation.error;
                                    }
                                }
                            }
                            break;
                        case 'alphanumeric':
                            if (!isNullOrUndefined(cell.value) && !/^[a-zA-Z0-9]*$/.test(cell.value))
                                error = 'Alphanumeric characters only';
                            break;
                        case 'alphanumeric_with_space':
                            if (!isNullOrUndefined(cell.value) && !/^[a-zA-Z0-9\s]*$/.test(cell.value))
                                error = 'Alphanumeric characters and space only';
                            break;
                        case 'no_whitespace':
                            if (!isNullOrUndefined(cell.value) && /\s/.test(cell.value))
                                error = 'Alphanumeric and special characters only';
                            break;
                        case 'url':
                            if (!isNullOrUndefined(cell.value) && cell.value.includes("availability/[PROPERTY_CODE]")) break;
                            if (!isNullOrUndefined(cell.value) && !/^((http|https):\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.?)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/.test(cell.value))
                                error = 'Value is not a valid URL';
                            break;
                    }
                    if (error) {
                        cell.error = error;
                        errorsFound.push({ row: rowIdx + 1, column: colIdx + 1, message: error });
                    }
                });
            });
        });

        return errorsFound;
    }

    return {
        internalState, setInternalState,
        errors, setErrors,
        cells, setCells,
        gridData, setGridData,
        columns, setColumns,
        loading, setLoading,
        initializing, setInitializing,
        addNewData,
        loadData, reload, 
        getData,
        getGridData, getRawData,
        alignDataWithColumns,
        sortInfo, selectSort, 
        paginationInfo, initializePagination, paginate,
        validateCells,
        generateCells,
        saveValueToGridData,
        getGridInfo,
        triggerEvent
    }
}

export default DataGridController;