import React from "react";

const Button = ({ key, onClick, disabled, type, className, children, style, btnType = 'button' }) => {
    return (
        <button
            key={key}
            type={btnType}
            disabled={disabled}
            onClick={onClick}
            style={style}
            className={`rms-button ${type ? type : "bg-light"} ${className ? className : ""
                }`}
        >
            <span>{children}</span>
        </button>
    );
};

export default Button;
