import React from "react";
import { useSelector } from "react-redux";
import 'chart.js/auto';
import { Bar } from "react-chartjs-2";
// import moment from "moment";
import dayjs from "dayjs";
import _ from "lodash";



const EnlargedSimpleChart = ({ data, filter, type }) => {
    const state = useSelector((state) => state.mainReducer);

    const filteredData = {
        ...data,
        datasets: data.datasets.filter((item) => {
            if (item.dataType === "Occupancy") {
                return item.type !== "unplotted" && filter;
            }

            return item.type !== "unplotted";
        }),
    };
    const arrangedData = {
        ...filteredData,
        datasets: _.orderBy(
            filteredData.datasets,
            ["type", "label", "segment"],
            ["desc", "asc", "asc"]
        ),
    };

    return (
        <div className="rms-chart-container">
            <Bar
                data={arrangedData}
                height={100}
                options={{
                    tension: 0.8, // line chart
                    spanGaps: true, // line chart
                    responsive: true,
                    maintainAspectRatio: true,
                    categoryPercentage: 0.8,
                    barPercentage: 1.0,
                    plugins: {
                        legend: {
                            labels: {
                                fontColor: "#2e2e2e",
                            },
                            display: false,
                            position: "bottom",
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const dataset = tooltipItem.dataset;
                                    const range = dataset.label.split(": ")[0];
                                    const isOccupancyRange = range === 'Range 1';
                                    const isAvgOccRange = range === 'Range 2';
                                    const segmentCode = dataset.segment;
                                    const dataType = dataset.dataType;
                                    let label = "";
                                    let prefix = "";
                                    let valueSuffix = "";
                                    let tooltipValue = "";
    
                                    if (dataset.formatAsCurrency === true) {
                                        prefix = dataset.currencyCode;
                                        tooltipValue = tooltipItem.formattedValue.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                        });
                                        if (dataType === "Revenue") {
                                            if (segmentCode === "ALLSEGMENTS") {
                                                valueSuffix = "total revenue";
                                            } else {
                                                valueSuffix = "segment revenue";
                                            }
                                        }
                                    } else {
                                        tooltipValue = tooltipItem.formattedValue;
                                        if (segmentCode === "ALLSEGMENTS") {
                                            if (dataType === "Pick-up") {
                                                valueSuffix = "rooms picked-up";
                                            } else {
    
                                                if (state.activeCalendarView === 'daily') {
                                                    if ((['samedaylastyear', 'lastyear'].includes(state.calendar_data.pace_string))
                                                        || isOccupancyRange) {
                                                        valueSuffix = "total rooms sold";
                                                    } else {
                                                        valueSuffix = (state.calendar_data.pace_string === 'averageroomsold')
                                                            ? "average rooms sold"
                                                            : "average DOW rooms sold";
                                                    }
                                                } else {
                                                    if ((state.calendar_data.pace_string === 'averagedowroomsold')
                                                        && isAvgOccRange) {
                                                        valueSuffix = "average DOW rooms sold";
                                                    } else {
                                                        valueSuffix = "average rooms sold";
                                                    }
                                                }
                                            }
                                        } else {
                                            valueSuffix = "rooms sold";
                                        }
                                    }
    
                                    label += ` ${prefix} ${tooltipValue} ${valueSuffix}`;
    
                                    return label;
                                },
                                beforeTitle: function (tooltipItems) {
                                    const dataset = tooltipItems[0].dataset;
                                    const range = dataset.label.split(": ")[0];
                                    const isOccupancyRange = range === 'Range 1';
                                    const isAvgOccRange = range === 'Range 2';
                                    const rangeStart = dayjs(dataset.rangeStart);
                                    const rangeEnd = dayjs(dataset.rangeEnd);
    
                                    if (isAvgOccRange && state.calendar_data.pace_string === 'averageroomsold') return 'Average Rooms Sold'
                                    if (isAvgOccRange && state.calendar_data.pace_string === 'averagedowroomsold') return 'Average DOW Rooms Sold'

                                    if (isOccupancyRange && type === "monthly") {
                                        return rangeStart.format("MMM YYYY");
                                    }
                                    if (isOccupancyRange && type === "daily") {
                                        return rangeStart.format("MMM DD, YYYY");
                                    } else if (isOccupancyRange && (type === "weekly" || type === "monthly")) {
                                        return rangeStart.format("MMM DD, YYYY") + " to " + rangeEnd.format("MMM DD, YYYY");
                                    } else if (isAvgOccRange && type === "weekly") {
                                        return `Same week last year (${rangeStart.format("MMM DD, YYYY") + " to " + rangeEnd.format("MMM DD, YYYY")})`;
                                    } else if (isAvgOccRange && type === "monthly") {
                                        return `Same month last year (${rangeStart.format("MMM YYYY")})`;
                                    } else if (isAvgOccRange && type === "daily") {
                                        switch (state.calendar_data.pace_string) {
                                            case "samedaylastweek":
                                                return `Same day last week (${rangeStart.format("MMM DD, YYYY")})`;
                                            case "samedaylastmonth":
                                                return `Same day last month (${rangeStart.format("MMM DD, YYYY")})`;
                                            case "lastyear":
                                                return `Last Year (${rangeStart.format("MMM DD, YYYY")})`;
                                            default:
                                                return `Same day last year (${rangeStart.format("MMM DD, YYYY")})`;
                                        }
                                    }
                                },
                                title: function (tooltipItems) {
                                    const dataset = tooltipItems[0].dataset;
                                    const segmentType = dataset.segment;
    
                                    const segmentTitle =
                                        segmentType === "ALLSEGMENTS"
                                            ? "All Segments"
                                            : `${segmentType} Segment`;
    
                                    return segmentTitle;
                                },
                                afterTitle: function (tooltipItems) {
                                    const tooltipItemLabel = tooltipItems[0].label;
                                    let toolTipTitle = tooltipItemLabel;
                                    switch(parseInt(tooltipItemLabel)) {
                                        case -1: toolTipTitle = "Actuals"; break;
                                        case 0: toolTipTitle = "On the day"; break;
                                        case 1: toolTipTitle += " day out"; break;
                                        default: toolTipTitle += " days out";
                                    }
                                    return toolTipTitle;
                                },
                            },
                        }
                    },
                    scales: {
                        y: {
                            grid: { display: false },
                            position: "right",
                            ticks: {
                                beginAtZero: true,
                                color: "#2e2e2e",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 13,
                                    weight: "900",
                                }
                            },
                            title: {
                                display: true,
                                text: "Rooms Sold",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 16,
                                    weight: "900"
                                }
                            }
                        },
                        y1: {
                            position: "left",
                            gridLines: { display: false },
                            ticks: {
                                callback: (value) => {
                                    return `${data.datasets[0]?.currencyCode ?? ''} ${value}`;
                                },
                                beginAtZero: true,
                                color: "#2e2e2e",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 13,
                                    weight: "900",
                                }
                            },
                            title: {
                                display: true,
                                text: "Price",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 16,
                                    weight: "900"
                                }
                            }
                        },
                        x: {
                            categoryPercentage: 0.8,
                            barPercentage: 1.0,
                            ticks: {
                                display: true,
                                color: "#2e2e2e",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 13,
                                    weight: "900",
                                }
                            },
                            title: {
                                display: true,
                                text: "Days Out",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 16,
                                    weight: "900"
                                }
                            }
                        },
                    }
                }}
            />

            <div className="legends-wrapper">
                {arrangedData.datasets.map((item, index) => {
                    const { backgroundColor, dataType, rangeStart, rangeEnd, type } = item;

                    const label =
                        state.calendar_data.pace_string === "averageroomsold"
                            ? "Avg room sold"
                            : "Avg DOW room sold";

                    return (
                        <div key={index} className="legend-item">
                            <div className="label">
                                <span
                                    className={type}
                                    style={{
                                        backgroundColor,
                                    }}
                                />
                                {dataType}
                            </div>
                            {state.calendar_data.pace_string === "samedaylastyear" ||
                                index === 0 ||
                                index === 2 ? (
                                <p>{`${state.activeCalendarView !== "monthly"
                                    ? rangeEnd !== rangeStart
                                        ? dayjs(rangeStart).format("MMM DD") +
                                        " to " +
                                        dayjs(rangeEnd).format("MMM DD, YYYY")
                                        : dayjs(rangeStart).format("MMM DD, YYYY")
                                    : dayjs(rangeStart).format("MMM YYYY")
                                    }`}</p>
                            ) : (
                                <p>{label}</p>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default EnlargedSimpleChart;
