import React, { useEffect, useState, useMemo } from 'react';
import { TreeSelect } from 'antd';
import constants from 'constants.js';
import { defaultSegmentFilterOptions } from 'utils/calendar';
const { TreeNode } = TreeSelect;

const SegmentFilter = ({ value, handleChange, property, segmentGroups, segments, isPropertyGroup }) => {

    const treeData = useMemo(() => {
        const data = defaultSegmentFilterOptions(property, segmentGroups, segments, isPropertyGroup);
        // Insert ALL SEGMENTS item at the beginning of array.
        data.unshift({
            display: constants.ALLSEGMENTS,
            value: constants.ALLSEGMENTS,
            key: constants.ALLSEGMENTS
        });
        // Finalize tree data content. This includes removal of title (tooltip) per item.
        data.forEach(item => {
            item.display = item.display.replace('-', ' ');
            item.title = null;
            item.children && item.children.forEach(child => child.title = null)
        });

        return data;
    }, [segmentGroups, segments, isPropertyGroup]);

    const getSelectedSegments = () => {
        const selectedTreeNode = treeData.find(td => td.value === selectedParent);
        if (selectedTreeNode?.children) {
            const values = selectedTreeNode.children.filter(item => {
                return selectedNodes.includes(item.value)
            }).map(item => item.value);
            // If there are selected items from selected parent.
            if (values.length > 0) {
                // Just save the selected items.
                return values;
            } else {
                // If there is no selected item then it will automatically select ALL SEGMENTS.
                return [treeData[0].value];
            }
        } else {
            // ALL SEGMENTS selected
            return [selectedTreeNode.value];
        }
    }

    const getDisplayValue = (items) => {
        if (items.length === 1) {
            if (items[0] === constants.ALLSEGMENTS) return 'ALL';
            return items[0].replace('GROUP-', '');
        }
        return 'Combined';
    }

    const [selectedParent, setSelectedParent] = useState(treeData[1].value);
    const [selectedNodes, setSelectedNodes] = useState(value);

    // Only 1 TreeNode (group) is allowed to select
    // TreeSelect can select multiple child from different parent that's why this is implemented.
    useEffect(() => {
        // State selectedParent and selectedNodes is actually updated here.
        const selectedSegments = getSelectedSegments();

        handleChange(selectedSegments);
    }, [selectedNodes]);

    const expandedKey = useMemo(() => {
        if (selectedParent === treeData[0].value) {
            if ((treeData[1]?.children ?? []).length > 0) {
                return treeData[1].value;
            } else if ((treeData[2]?.children ?? []).length > 0) {
                return treeData[2].value;
            }
        }
        return selectedParent;
    }, [selectedParent])

    return <div className='rms-select-filter'>
        <TreeSelect
            className='filter-tree-select'
            popupClassName='filter-tree-select-dropdown'
            showSearch={false}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            value={value}
            treeData={treeData}
            treeExpandAction={'click'}
            treeExpandedKeys={[expandedKey]}
            popupMatchSelectWidth={false}
            treeNodeLabelProp='display'
            suffixIcon={null}
            treeTitleRender={(node) => {
                if (!node) return;
                return treeData.find(item => item.value === node.value)
                    ? <span style={{ fontWeight: 600 }}>{node.display}</span>
                    : <span>{node.display}</span>
            }}
            tagRender={(props) => {
                if (value[0] === props.value) {
                    return <span className='ant-tree-select-selection-item'>
                        {getDisplayValue(value)}
                    </span>
                }
                return <></>
            }}
            onChange={(value, labelList, extra) => {
                const selectedValue = extra.triggerValue; // Clicked/Selected item
                const selectedValueParent = treeData.find(item => (item?.children ?? []).find(child => child.value === selectedValue));

                if (selectedValue === expandedKey) {
                    // The selected parent is the expanded item. Select ALL of its children.
                    setSelectedNodes(value);
                } else if (selectedValueParent) {
                    // Child selected. Align selectedParent to parent of selected value.
                    setSelectedParent(selectedValueParent.value);
                    setSelectedNodes(value);
                } else if (selectedValue === treeData[0].value) {
                    // ALL SEGMENTS selected
                    setSelectedNodes(value);
                } else if (selectedParent === selectedValue) {
                    // Parent Selected.
                    setSelectedNodes(value);
                } else if (expandedKey !== selectedValue && value.length === 0) {
                    // Emply value means parent was deselected. 
                    // The deselected parent is not the expanded item.
                    setSelectedNodes(value);
                }
            }}
            onSelect={(value, node) => {
                // Only save parent node's value.
                if (treeData.find(item => item.value === value)) setSelectedParent(value);
            }}
        />
    </div>
}

export default SegmentFilter;