import React from "react";
import { Select } from "antd";

import { isNullOrUndefined } from "utils";

const { Option } = Select;


/**
 * A basic select element for modals.
 *
 * @param {String} label Select label.
 * @param {String} value Select value.
 * @param {Boolean} disabled Disable select field, or not. Defaults to false.
 * @param {Function} action Action to happen after select.
 * @param {[Object]} options Select list of options data object.
 * @return {Component} A basic select element for modals.
*/
const ModalSelect = ({ label = "", value = null, disabled = false, action = null, options = [], showSearch = false }) => {

    return (
        <div className="light-input-container">
            <div className="light-label">{label}</div>

            <Select className="light-select" value={value} optionFilterProp="name" onChange={action} disabled={disabled} showSearch={showSearch}>
                {(options.length > 0) && options.map((option, index) => {
                    return !isNullOrUndefined(option) && <Option key={index} value={option.value} name={option.name}>
                        <span>{option.name}</span>
                    </Option>
                })}
            </Select>
        </div>
    )
};

export default ModalSelect;
