import React from "react";
import { Modal } from "antd";

import { Exit } from "Icons";
import Button from "../Button/Button";



const UnsavedChangesModal = ({ visible, onCancel, onConfirm }) => {
    return (
        <Modal
            open={visible}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={onCancel}
            width={500}
            footer={null}
        >
            <div className="rms-modal-content">
                <p>
                    You have unsaved changes. Are you sure you want to leave this page?
        </p>
                <div className="modal-buttons">
                    <Button key="back" type="bg-light" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button key="submit" type="primary" onClick={onConfirm}>
                        Yes
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default UnsavedChangesModal;
