import React from "react";
import { useSelector } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";

import TableChart from "elements/Chart/TableChart";
import PriceRecoIcon from "elements/Icon/PriceRecoIcon";

import { noTimezone } from "utils";
import { chunkArray } from "../../../utils/data";


/**
 * Aggregate View (Weekly/Monthly) of the Algorithm Modal.
 *
 * @return {Component}
*/
const AlgorithmModalAggregate = () => {
    const { sidebarData, calendar_data, activeCalendarView } = useSelector(state => state.mainReducer);
    const isWeekly = activeCalendarView.includes("week");
    const isMonthly = activeCalendarView.includes("month");

    let aggregateInfo = sidebarData.algorithm_aggregate;
    let forecast = aggregateInfo.forecast;
    let breakdown = aggregateInfo.breakdown;
    let asof = dayjs(calendar_data.as_of);


    const displayDate = (data) => {
        if (data !== null) {
            switch (data.type) {
                case "weekly":
                    return `${dayjs(data.week_range[0]).format("MMM DD")}
                        - ${dayjs(data.week_range[1]).format("MMM DD")}, ${data.year}`;
                case "monthly":
                    return `${data.month} ${data.year}`;
                default:
                    return `${dayjs(noTimezone(data.check_in)).format("MMM DD, YYYY")}`;
            }
        }
        return null;
    };


    // Overview Table Data
    let overview_headers = [
        "Forecast",
        "Pickup",
        "Pace",
        "Price",
        "Demand",
        "Price Recommendation",
    ];
    let overview_data = {
        score: {
            name: "Average Score",
            data: [
                forecast.averages.pickup,
                forecast.averages.pace,
                forecast.averages.price,
                forecast.averages.demand,
                `${forecast.averages.price_recommendation_percent}%`
            ],
            color: null
        },
        color: {
            name: "Color",
            data: [
                forecast.colors.pickup,
                forecast.colors.pace,
                forecast.colors.price,
                forecast.colors.demand,
                <PriceRecoIcon quantity={forecast.averages.price_recommendation} hasCopy={true} round={true} />
            ],
            color: null
        },
    };


    // Weekly Aggregate Table Data
    let weekly_headers = [
        "",
        ...aggregateInfo.detail
            .map(item => noTimezone(item.check_in))
            .filter(item => dayjs(item) >= asof)
    ];
    let weekly_data = {
        pickup: {
            name: "Pickup Scores",
            data: breakdown.scores.pickup,
            color: null
        },
        pace: {
            name: "Pace Scores",
            data: breakdown.scores.pace,
            color: null
        },
        price: {
            name: "Price Scores",
            data: breakdown.scores.price,
            color: null
        },
        demand: {
            name: "Demand Scores",
            data: breakdown.scores.demand,
            color: null
        },
        price_reco_percent: {
            name: "Price Recommendation Percents",
            data: breakdown.price_recommendation.percents.map(item => `${item}%`),
            color: null
        },
        price_reco: {
            name: "Price Recommendation Arrow Quantity",
            data: breakdown.price_recommendation.arrow_qty,
            color: null
        },
    };


    // Monthly Table
    const renderMonthTable = () => {
        let monthTable = [];
        let monthDayDates = aggregateInfo.detail.map(item => noTimezone(item.check_in))
            .filter(item => dayjs(item) >= asof);

        for (let i = 0; i < Math.ceil(monthDayDates.length / 10); i++) {
            monthTable.push(
                <TableChart
                    allowString={true}
                    headers={["", ...chunkArray(monthDayDates, 10)[i]]}
                    data={{
                        pickup: {
                            name: "Pickup Scores",
                            data: chunkArray(breakdown.scores.pickup, 10)[i],
                            color: null
                        },
                        pace: {
                            name: "Pace Scores",
                            data: chunkArray(breakdown.scores.pace, 10)[i],
                            color: null
                        },
                        price: {
                            name: "Price Scores",
                            data: chunkArray(breakdown.scores.price, 10)[i],
                            color: null
                        },
                        demand: {
                            name: "Demand Scores",
                            data: chunkArray(breakdown.scores.demand, 10)[i],
                            color: null
                        },
                        price_reco_percent: {
                            name: "Price Recommendation Percents",
                            data: (chunkArray(breakdown.price_recommendation.percents, 10)[i])?.map(item => `${item}%`) ?? [],
                            color: null
                        },
                        price_reco: {
                            name: "Price Recommendation Arrow Quantity",
                            data: chunkArray(breakdown.price_recommendation.arrow_qty, 10)[i],
                            color: null
                        },
                    }}
                    rowIndecesWithColor={[0, 1, 2, 3]}
                    rowNameWidth={150}
                    rowDataWidth={115}
                />
            )
        }

        return monthTable;
    }





    return (
        <div>
            {/* Overview Summary and Table */}
            <div style={{
                display: "grid", gridAutoFlow: "column", justifyItems: "center", width: "100%",
                marginBottom: 20
            }}>
                <p>
                    <strong>OVERVIEW</strong>
                    <br />
                    <strong>{displayDate(sidebarData)}</strong>
                </p>
                <TableChart
                    allowString={true}
                    headers={overview_headers}
                    data={overview_data}
                    rowIndecesWithColor={[0, 1, 2, 3]}
                    rowNameWidth={150}
                    rowDataWidth={115}
                />
            </div>
            <hr />


            {/* Weekly/Monthly Breakdown Table */}
            <p style={{ marginTop: 20 }}>
                <strong>BREAKDOWN</strong>
            </p>

            {isWeekly && <TableChart
                allowString={true}
                headers={weekly_headers}
                data={weekly_data}
                rowIndecesWithColor={[0, 1, 2, 3]}
                rowNameWidth={150}
                rowDataWidth={115}
            />}

            {isMonthly && <div>
                {renderMonthTable()}
            </div>}
        </div>
    )
}

export default AlgorithmModalAggregate;
