import React from "react";
import { useSelector } from "react-redux";

import Button from "../Button/Button";
import { TutorialSpot } from "../Tutorial/index";
import useHelperFunctions from '../../store/actions/actions';



const Tabs = ({ tabs, activeTab, selectTab, callback, inputClassName }) => {
    const tutorial = useSelector((state) => state.mainReducer.tutorial);
    const { toggleChartModal } = useHelperFunctions();
    let tutorialId = "";

    if (inputClassName.includes("daily-tabs"))
        tutorialId = "dataTypeViewTutorial";
    if (inputClassName.includes("calendar-tabs")) tutorialId = "filter1Tutorial";
    if (inputClassName.includes("view-tabs")) tutorialId = "filter2Tutorial";

    const isActive = (activeTab, tabKey) => {
        const paceTypes = ["pace", "average_occ", "average_occ_dow"];
        let tabIsOfTypePace = paceTypes.indexOf(activeTab) > -1;
        let userIsInPaceCalendar = paceTypes.indexOf(tabKey) > -1;
        if (tabIsOfTypePace && userIsInPaceCalendar) {
            return true;
        }
        return activeTab === tabKey;
    };

    return (
        <div
            className={`rms-tabs ${inputClassName} ${tutorialId}`}
            style={{ position: "relative" }}
        >
            {tutorial.dataTypeView.showSpot &&
                inputClassName.includes("daily-tabs") && (
                    <TutorialSpot
                        section="overviewCalendar"
                        type="dataTypeView"
                        left="-42px"
                        bottom="4px"
                    />
                )}
            <ul>
                {tabs.map((tab) => {
                    if (tab.visible !== true) {
                        return null;
                    }

                    return (
                        <li
                            key={tab.key}
                            className={isActive(activeTab, tab.key) ? "is-active" : ""}
                            onClick={() => {
                                selectTab(tab.key);
                                typeof callback == "function" && callback(tab.key);
                                toggleChartModal({ show: false, tab: 'booking-chart' })
                            }}
                        >
                            {inputClassName.includes("view-tabs") ||
                                inputClassName.includes("daily-tabs") ? (
                                    <Button type="secondary tab-button">{tab.label}</Button>
                                ) : (
                                    <span>{tab.label}</span>
                                )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Tabs;
