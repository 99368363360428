import React, { useEffect, useState } from 'react';
import './CustomTabs.scss';

const CustomTabs = ({ selectedTab, tabs, name = '', children, className = ''}) => {

    const getActiveTab = (selectedTab) => {
        const localStorageValue = localStorage.getItem(`customTab-${name}`);
        const defaultTab = tabs.find(tab => !(tab.hide === true))?.key;

        if (selectedTab) return selectedTab;
        if (name && localStorageValue) return localStorageValue;
        if (defaultTab) return defaultTab;
    }

    const [activeTab, setActiveTab] = useState(getActiveTab(selectedTab));
    const activeTabComponent = children.find(child => child.type?.displayName === "CustomTab" && child.key === activeTab);

    useEffect(() => {
        !name && localStorage.setItem(`customTab-${name}`, activeTab);
    }, [activeTab]);
    
    return (
        <div className={`custom-tabs ${className}`} >
            <div className="custom-tabs-buttons">{
                tabs.map(tab => {
                    let arrClass = [];
                    if (tab.hide === true) arrClass.push('hidden');
                    if (tab.key === activeTab) arrClass.push('active');
                    if (tab.disabled === true) arrClass.push('disabled');
                    return (
                        !(tab.hide === true) &&
                        <button
                            type="button"
                            className={`custom-tab-button ${arrClass.join(' ')}`}
                            key={tab.key}
                            onClick={(e) => { if (tab.key !== activeTab) setActiveTab(tab.key) }}
                            disabled={tab.disabled === true}
                        >{tab.label}</button>
                    )
                })}
            </div>
            {activeTabComponent}
        </div>
    );
}

const CustomTab = ({ children }) => {
    return (
        <div className="custom-tab-container">
            {children}
        </div>
    )
};
// child.type.name is not working properly in production build because of "minify/uglify".
CustomTab.displayName = 'CustomTab';

CustomTabs.Tab = CustomTab;

export default CustomTabs;