import React from 'react';
import Select from 'elements/Select';
import { defaultPaceFilterOptions } from 'utils/calendar';

const PaceFilter = ({ value, handleChange }) => {
    return <div className='rms-select-filter'>
        <Select
            value={value}
            onChange={handleChange}
            popupMatchSelectWidth={false}
            suffixIcon={null}
            optionLabelProp="label"
            options={defaultPaceFilterOptions.map(item => ({ ...item, title: null }))}
            optionRender={({ data }) => <span>{`${data.name} (${data.code})`}</span>}
        >
        </Select>
    </div>
}

export default PaceFilter;