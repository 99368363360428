/** @file Helper functions for data */



/**
 * Checks if a text is alphanumeric or not.
 * Has feature to allow spaces, dashes, or underscores.
 *
 * @function isAlphaNumeric
 * @param {String} text A text
 * @param {Boolean} allowSpaces Allow spaces
 */
export function isAlphaNumeric(text, allowSpaces = true, allowPeriod = false) {
    if (!allowSpaces && !allowPeriod) return text.trim().match( /^[a-z0-9]+$/i);

    if (!allowSpaces && allowPeriod) return text.trim().match(/^[a-z\d\-_.]+$/i);
    if (allowSpaces && !allowPeriod) return text.trim().match(/^[a-z\d\-_\s]+$/i);
    if (allowSpaces && allowPeriod) return text.trim().match(/^[a-z\d\-_\s.]+$/i);
};



/**
 * Moves an array item from one index to another.
 *
 * @function moveArrayItem
 * @param {[Object]} arr An array
 * @param {Number} fromIndex Current index of the array item to be moved
 * @param {Number} toIndex Index where the array item will be moved to
 */
export function moveArrayItem(arr, fromIndex, toIndex) {
    let arrayItem = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, arrayItem);

    return arr;
};



/**
 * Sorts an array.
 *
 * @function sortArray
 * @param {[Object]} arr An array
 * @param {String} key An array.
 */
export function sortArray(arr, key, sortType = 'string') {
    if (arr.length < 2) return arr;
    if (sortType === 'string') {
        return arr.sort((a, b) => {
            let fa = a[key]?.toLowerCase(), fb = b[key]?.toLowerCase();

            if (fa < fb) { return -1; }
            if (fa > fb) { return 1; }
            return 0;
        })
    }
    return arr;
};



/**
 * Chunks an array into multiple array of specified size.
 *
 * @function chunkArray
 * @param {[Object]} arr An array to be chunked
 * @param {Number} size Size of each array after chunking
 */
export function chunkArray(arr, size) {
    let arrayChunk = [];

    for (let i = 0; i < arr.length; i += size) {
        let chunk = arr.slice(i, i + size);
        arrayChunk.push(chunk);
    }

    return arrayChunk;
}



/**
 * Converts an array of objects into a single object of objects.
 * Requires a specific objKey to be the key of each object in the array when converted to an object.
 * The provided objKey, defaults to "id", will not be included in the resulting object.
 *
 * @function arrayToObject
 * @param {[Object]} arr An array of objects
 * @param {String} objKey Determining key for each object
 */
export function arrayToObject(arr, objKey = "id") {
    const res = {};
    for (let i = 0; i < arr.length; i++) {
        const key = arr[i][objKey];
        res[key] = arr[i];
        delete res[key][objKey];
    };
    return res;
};






/**
 * Checks if a value is either null or undefined.
 *
 * @exports isNullish
 * @param {Number} value Value to be checked.
 * @return {Boolean} Value is null or undefined.
*/
export function isNullish(value) {
    return (value === null || value === undefined);
}



/**
 * Sums an array of values.
 * Returns a null if at least one of the values in the array is null, unless a sum is forced.
 *
 * @exports arraySum
 * @param {[number]} data An array of values
 * @return {number} Sum, or null
*/
export function arraySum(data, forceSum = false) {
    function add(a, b) { return a + b; }

    const isNullish = (value) => {
        return (value === null || value === undefined);
    }

    let hasNullData = false;
    for (let i = 0; i < data.length; i++) {
        if (isNullish(data[i])) {
            hasNullData = true;
            break;
        }
    }
    return hasNullData && !forceSum ? null : data.reduce(add, 0);
}



/**
 * Handles division of two items.
 * Returns a null if either item is null. Also returns a null if the divisor is zero.
 *
 * @exports divide
 * @param {Number} dividend Dividend
 * @param {Number} divisor Divisor
 * @return {Number} Quotient or null
*/
export function divide(dividend, divisor) {
    if (dividend === null || divisor === null) return null;
    if (divisor === 0) return null;
    return dividend / divisor;
}



/**
 * Rounds a value using Math.round. Returns a null if value does not exist of is null.
 *
 * @exports round
 * @param {number} value Value to be rounded
 * @return {number} Rounded value
*/
export function round(value, float = false) {
    if (value) {
        if (value === null) return null;
        return float ? Math.round(value.toFixed(2) * 100) / 100 : parseFloat(value.toFixed(2));
    } else {
        if (value === 0) return value;
        return null;
    }
}



/**
 * Find array data with matching days-out.
 *
 * @exports byDo
 * @param {[Object]} data An array of data.
 * @param {Number} dout Days-out.
 * @return {Object} Array data with matching days-out.
*/
export function byDo(data, dout) {
    return Array.isArray(data) ? data?.find(a => a._id === dout) : data;
}



/**
 * Find array data with matching days-out and day-of-week.
 *
 * @exports byDoDow
 * @param {[Object]} data An array of data.
 * @param {Number} dow Day-of-week.
 * @param {Number} dout Days-out.
 * @return {Object} Array data with matching days-out.
*/
export function byDoDow(data, dow, dout) {
    return data[dow]?.find(a => a.do === dout);
}

