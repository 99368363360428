import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastsStore } from "react-toasts";
// import moment from "moment";
import dayjs from "dayjs";
import * as antd from "antd";

import Button from "../Button/Button";
import { Exit } from "Icons";
import SimpleChart from "../SimpleChart/SimpleChart";
import EnlargedSimpleChart from "../SimpleChart/EnlargedSimpleChart";
import RecommendationIcon from "../Recommendation/RecommendationIcon";

import constants from "../../constants";
import usePropertyActions from "store/actions/propertiesActions";
import useDashboardActions from "../../store/actions/dashboardActions";
import useHelperFunctions from "../../store/actions/actions";
import { noTimezone, getFirstdayOfWeek, getLastdayOfWeek } from "../../utils";
import { generateFilter } from '../../utils/calendar';
import { getBookingChartDataToDisplay } from '../../utils/visuals';
import { isNullish } from "utils/data";


const DashboardSidebar = ({ info, data }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const users = useSelector((state) => state.users);
    const properties = useSelector((state) => state.properties);
    const { algoModalEnabled, varianceAvgs, variances, source } = useSelector((state) => state.dashboard);
    const {
        hotelId,
        sidebarChartDataIsLoading,
        sidebarChartData,
        sidebarData,
        segmentsTotal,
        activeCalendarView,
        calendar_data,
        activeHotel,
        visualsBookingChartSelectedDaysOut,
    } = state;
    const { as_of, check_in_start, check_in_end, pick_up, pace, pace_string,
        segments, roomtype, comparison, comparison_data } = calendar_data;
    const isGroup = state.isGroup;

    const userPrefs = users.authUser.prefs;
    const usePercentsForOccs = state.isGroup && !isNullish(userPrefs) && (userPrefs.usePercentsForOccs ?? true);
    const hideComparisons = (!isNullish(userPrefs) && (userPrefs.hideComparisons ?? false)) || comparison === "NONE";
    const forecastEnabled = isGroup
        ? (!(userPrefs?.forecastGroupDisabled) ?? true)
        : (!(userPrefs?.forecastPropertyDisabled) ?? true)

    const { getComparison, getVariancesByDo } = useDashboardActions();
    const { toggleSidePanel, setNotesModalState, toggleChartModal } = useHelperFunctions();
    const [visible, setVisible] = useState(false);
    const [notesLoaded, setNotesLoaded] = useState(false);
    const [notes, setNotes] = useState("");
    const { addNote, getNotesSummary } = usePropertyActions();
    
    useEffect(() => {
        return () => {
            setNotesModalState({ visible: false, startDate: null, endDate: null });
        }
    }, []);

    useEffect(() => {
        if (data !== null && state.showSidebar && varianceAvgs) {
            // const daysOut = (cin, aof) => parseInt((new Date(cin) - new Date(aof)) / (1000 * 3600 * 24));
            const daysOut = (cin, aof) => dayjs(cin).diff(dayjs(aof), "days");
            const isDaily = data.type === "daily";
            const checkInStart = isDaily? dayjs(data.cin): dayjs(data.dateRange[0]);
            const checkInEnd = isDaily? dayjs(data.cin): dayjs(data.dateRange[1]);
            // NOTE: The check in dates came from back-end that's why it is in UTC date. 
            // The generateFilter can handle the conversion of dates so we can leave this issue to that function.
            const checkin = [checkInStart, checkInEnd];
            
            const group = state.propertyInView.id;
            let filter = generateFilter(hotelId, as_of, checkin, pick_up, pace, segments, roomtype, userPrefs, group, pace_string);

            filter.cancelToken = source.token;
            filter.type = data.type;
            filter.percent = usePercentsForOccs;

            // Comparison Data Request (For Sidebar)
            if (!hideComparisons) {
                filter.comparison = {
                    name: comparison_data.name,
                    group: comparison_data.isGroup,
                    property: comparison_data.property,
                    properties: comparison_data.properties,
                }

                getComparison(data.type, filter).then((res) => {
                    if (res && res.type === "GET_COMPARISON_DATA_SUCCESS") {
                        let comparison = res.response.data.data;
                        dispatch({ type: constants.UPDATE_COMPARISON_CONTENT, value: comparison });
                    }
                });
            }
            
            const pickupDays = Math.abs(parseInt(filter.pickup));
            // There is inconsistency in dates(aof and cin) format.
            // To lessen the confusion, look at stayDate and originalAsOf to see the correct format of checkin/stay and as of date.
            // The accuracy of dates is important since days out depends in as of and cin.
            const stayDate = dayjs(checkInEnd);
            const originalAsOf = dayjs(as_of);
            const asOf = daysOut(stayDate, originalAsOf) < 0
                ? dayjs(stayDate).add(1, "day"): originalAsOf;
            
            const firstDout = daysOut(stayDate, asOf);
            const douts = [...new Array(9).keys()].map(key => {
                return firstDout + (key * pickupDays);
            });

            const avgsByDo = varianceAvgs.avgsByDo;
            const avgsByDoDow = varianceAvgs.avgsByDoDow;
            let avgs = { byDo: [], byDoDow: [] };
            if (isDaily) {
                for (let i = 0; i < avgsByDo.length; i++) {
                    const dout = douts[i];
                    avgs.byDo.push(avgsByDo[i].find(_ => _.dout === dout));
                    avgs.byDoDow.push(avgsByDoDow[i][data.dow - 1].find(_ => _.dout === dout));
                }
            } else {
                for (let i = 0; i < avgsByDo.length; i++) {
                    const dout = douts[i];
                    const addtlDays = daysOut(checkInEnd, checkInStart);

                    let aggregateByDo = [];
                    let aggregateByDoDow = [];
                    for (let j = addtlDays; j >= 0; j--) {
                        const iCheckIn = dayjs(checkInStart).add(j, "days").format("YYYY-MM-DD");
                        const iAsOf = asOf.clone().subtract(Math.abs(parseInt(filter.pickup)) * i, "days").format("YYYY-MM-DD");

                        let dow = dayjs(iCheckIn).isoWeekday();
                        let dout = daysOut(iCheckIn, iAsOf);

                        if (dout < -1) {
                            const finalAvgByDo = avgsByDo[i].find(_ => _._id === -1);
                            const finalAvgByDoDow = avgsByDoDow[i][dow - 1].find(_ => _.do === -1);
                            const pickupAvgByDo = avgsByDo[i].find(_ => _._id === (dout + pickupDays));
                            const pickupAvgByDoDow = avgsByDoDow[i][dow - 1].find(_ => _.do === (dout + pickupDays));

                            aggregateByDo.push({
                                ...finalAvgByDo,
                                _id: dout,
                                pickup: finalAvgByDo?.avg_occ - (pickupAvgByDo?.avg_occ?? finalAvgByDo?.avg_occ)
                            });
                            aggregateByDoDow.push({
                                ...finalAvgByDoDow,
                                do: dout,
                                days_out: { do: dout, dow: dow },
                                pickup: finalAvgByDoDow?.avg_occ - (pickupAvgByDoDow?.avg_occ ?? finalAvgByDoDow?.avg_occ)
                            });

                        } else {
                            aggregateByDo.push(avgsByDo[i].find(_ => _.dout === dout));
                            aggregateByDoDow.push(avgsByDoDow[i][dow - 1].find(_ => _.dout === dout));
                        }
                    }
                    
                    // console.log(
                    //     aggregateByDo.map(_ => { return { do: _._id, pickup: _.pickup }}), 
                    //     aggregateByDoDow.map(_ => { return { do: _._id, pickup: _.pickup }})
                    // );

                    // console.log(dout,aggregateByDo.map(_ => Math.round(_?.pickup ?? 0)), aggregateByDo.reduce((s, _) => s + Math.round(_?.pickup ?? 0), 0));

                    avgs.byDo.push({
                        _id: dout,
                        dout: dout,
                        avg_occ: aggregateByDo.reduce((s, _) => s + Math.round((_?.avg_occ ?? 0) * 100) / 100, 0),
                        avg_rev: aggregateByDo.reduce((s, _) => s + Math.round(_?.avg_rev ?? 0), 0),
                        pickup: aggregateByDo.reduce((s, _) => s + Math.round(_?.pickup ?? 0), 0),
                        avg_rate: aggregateByDo.reduce((s, _) => s + Math.round(_?.avg_rate ?? 0), 0)
                            / aggregateByDo.length,
                    });
                    avgs.byDoDow.push({
                        _id: { do: dout, dow: 1 },
                        avg_occ: Math.round(aggregateByDoDow.reduce((s, _) => s + _?.avg_occ ?? 0, 0) * 100) / 100,
                        avg_rev: aggregateByDoDow.reduce((s, _) => s + Math.round(_?.avg_rev ?? 0), 0),
                        pickup: aggregateByDoDow.reduce((s, _) => s + Math.round(_?.pickup ?? 0), 0),
                        avg_rate: aggregateByDoDow.reduce((s, _) => s + Math.round(_?.avg_rate ?? 0), 0)
                            / aggregateByDoDow.length,
                        dout: dout
                    });
                }
            }
            filter.varianceAvgs = avgs;
            // Variance Data Request (for Dashboard Modal Linechart and Table)
            getVariancesByDo(filter);
        };
    }, [sidebarData, varianceAvgs]);



    const getBookingDateRequest = (data) => {
        switch (state.activeCalendarView) {
            case "daily": case "custom":
                data.booking_date_start = new Date(sidebarData.check_in);
                data.booking_date_end = new Date(sidebarData.check_in);
                return data;
            case "weekly":
                data.booking_date_start = new Date(sidebarData.dateRange[0]);
                data.booking_date_end = new Date(sidebarData.dateRange[1]);
                return data;
            case "monthly":
                data.booking_date_start = new Date(sidebarData.dateRange[0]);
                data.booking_date_end = new Date(sidebarData.dateRange[1]);
                return data;
            default:
                ToastsStore.error("Something went wrong");
        }
    };

    const handleAddNote = () => {
        if (notes !== "") {
            let newData = {
                content: notes,
                property_id: state.hotelId,
                note_type: state.activeCalendarView,
            };

            

            newData = getBookingDateRequest(newData);

            addNote(newData).then((res) => {
                if (res.type === "ADD_NOTE_SUCCESS") {
                    setNotes("");

                    // let request = {
                    //     property_id: state.hotelId,
                    // };

                    // request = getBookingDateRequest(request);

                    // getNotesSummary(request);
                    ToastsStore.success("Successfully added.");
                } else {
                    ToastsStore.error("Something went wrong.");
                }
            });
        }
    };

    useEffect(() => {
        toggleSidePanel(false);
        setNotesModalState({ visible: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeHotel]);

    useEffect(() => {
        if (!isGroup && state.showSidebar) {
            if (sidebarData) {
                let request = { property_id: state.hotelId };

                // request = getBookingDateRequest(request);
                // getNotesSummary(request).then((res) => {
                //     if (res && res.type === "GET_NOTES_SUCCESS") setNotesLoaded(true);
                // });
            }
        } else {
            setNotesLoaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarData?.check_in, sidebarChartData]);

    useEffect(() => {
        if (state.showSidebar === false) {
            setNotesModalState({ visible: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showSidebar]);

    let range1 = "";
    let range2 = "";
    sidebarChartData.datasets.forEach((item) => {
        let dateRange = ` ${dayjs(item.rangeStart).format("MMM DD, YYYY")} ${item.rangeStart !== item.rangeEnd
            ? "to " + dayjs(item.rangeEnd).format("MMM DD, YYYY")
            : ""
            }`;
        if (item.label.indexOf("Range 1") !== -1 && !range1) {
            range1 = dateRange;
        }
        if (state.activeCalendarView === "weekly") {
            dateRange = ` ${dayjs(item.rangeStart).format("MMM DD")} ${item.rangeStart !== item.rangeEnd
                ? "to " + dayjs(item.rangeEnd).format("MMM DD, YYYY") + " "
                : ""
                }`;
        }
        if (state.activeCalendarView === "monthly") {
            range1 = dayjs(sidebarChartData.datasets[0].rangeStart).format("MMMM YYYY");
            dateRange = ` ${dayjs(item.rangeEnd).format("MMMM YYYY")} `;
        }
        if (item.label.indexOf("Range 2") !== -1 && !range2) {

            switch (calendar_data.pace_string) {
                case "samedaylastweek":
                    range2 = "Same day last week ";
                    range2 += `( ${dateRange})`;
                    break;
                case "samedaylastmonth":
                    range2 = "Same day last month ";
                    range2 += `( ${dateRange})`;
                    break;
                case "averageroomsold":
                    range2 = "Average rooms sold ";
                    break;
                case "averagedowroomsold":
                    range2 = "Average DOW rooms sold ";
                    break;
                default:
                    range2 = "SDLY ";
                    range2 += `(${dateRange})`;
                    break;
            }
        }
    });

    const getTitle = (data) => {
        if (data !== null) {
            switch (data.type) {
                case "weekly":
                    return `${dayjs(data.week_range[0]).format("MMM DD")}
                        - ${dayjs(data.week_range[1]).format("MMM DD")}, ${data.year}`;
                case "monthly":
                    return `${data.month} ${data.year}`;
                default:
                    return `${dayjs(noTimezone(data.check_in)).format("MMM DD, YYYY")}`;
            }
        }
        return null;
    };

    return (
        <>
            <div className="dashboard-sidebar__content">
                <div className={`date-label ${data ? data.color : ""}`}>
                    <span className="date-label-span" onDoubleClick={() => {
                        if (algoModalEnabled && forecastEnabled) {
                            dispatch({ type: constants.SHOW_ALGORITHM, value: !state.algorithmIsDisplayed })
                        }
                    }} style={{ userSelect: "none", WebkitUserSelect: "none" }}>
                        {getTitle(data)}
                    </span>
                    {data && data.type === "weekly" || !calendar_data.segments.includes("ALL SEGMENTS") ? null : (
                        <RecommendationIcon data={data} spanClassName="recommendation-wrapper" />
                    )}
                    <Exit
                        onClick={() => {
                            toggleSidePanel(false);
                            toggleChartModal({ show: false, tab: 'booking-chart' })
                            dispatch({ type: constants.SHOW_VISUALS, value: false })
                            dispatch({ type: constants.SHOW_ALGORITHM, value: false })
                        }}
                        width="25px"
                        height="25px"
                        className="pointer"
                    />
                </div>
                {data && data.type === "weekly" ? (
                    <div className={`date-label gray`}>
                        <span>{`Week ${data.week}`}</span>
                        <RecommendationIcon data={data} spanClassName="recommendation-wrapper" />
                    </div>
                ) : null}
                <SimpleChart
                    isLoading={sidebarChartDataIsLoading}
                    data={getBookingChartDataToDisplay(sidebarChartData, visualsBookingChartSelectedDaysOut, sidebarData?.days_out)}
                    filter={segmentsTotal}
                    onClick={setVisible}
                    showModal={visible}
                />
                <div className="wrapper">{info}</div>

                <div
                    style={{
                        marginBottom: "100px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <Button type="primary" onClick={() => setNotesModalState({ 
                        visible: true,
                        startDate: ['daily', 'custom'].includes(state.activeCalendarView)
                            ? new Date(sidebarData?.check_in)
                            : new Date(sidebarData?.dateRange?.[0]),
                        endDate: ['daily', 'custom'].includes(state.activeCalendarView)
                            ? new Date(sidebarData?.check_in)
                            : new Date(sidebarData?.dateRange?.[1])
                    })}>
                        Notes
                    </Button>
                </div>

                <antd.Modal
                    open={false}
                    title={null}
                    closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                    onCancel={() => { setVisible(false); }}
                    width={900}
                    footer={null}
                >
                    <div className="rms-modal-content">
                        <div className="rms-modal-header">
                            <h3>{`${data?.week_range && state.activeCalendarView === "weekly"
                                ? `${(data.week_range &&
                                    `${dayjs(data.week_range[0]).format(
                                        "MMM DD"
                                    )} to ${dayjs(data.week_range[1]).format(
                                        "MMM DD"
                                    )}`) ||
                                data.month
                                } ${data.year}`
                                : range1
                                } vs ${range2}`}</h3>
                        </div>
                        <EnlargedSimpleChart
                            data={sidebarChartData}
                            filter={segmentsTotal}
                            type={activeCalendarView}
                        />
                    </div>
                </antd.Modal>
            </div>
        </>
    );
};

export default DashboardSidebar;
