import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
import dayjs from 'dayjs';

import Calendar from "../Calendar/Calendar";
import DashboardSidebar from "./DashboardSidebar";
import { TutorialSpot } from "../Tutorial/index";
import Tools from "../Tools/Tools";

import constants from "../../constants";
import { formatData, formatDataWithPlusSign, withCurrency, percent, getPaceTitle, formatNegativeOne } from "../../utils";
import { isNullish } from "../../utils/data";
import useHelperFunctions from '../../store/actions/actions';



export default function CalendarView() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const users = useSelector((state) => state.users);
    const { dashModalEnabled } = useSelector((state) => state.dashboard);
    const { toggleChartModal } = useHelperFunctions();
    const { sidebarData, comparisonData, calendar_data, currency, ADRState, isSegmentFeaturesEnabled } = state;
    const [sidebarList, setSidebarList] = useState("");
    const isGroup = state.isGroup;
    const comparison = calendar_data.comparison;

    const userPrefs = users.authUser.prefs;
    const usePercentsForOccs = isGroup && !isNullish(userPrefs) && (userPrefs.usePercentsForOccs ?? true);
    const hideComparisons = (!isNullish(userPrefs) && (userPrefs.hideComparisons ?? false)) || comparison === "NONE";
    const cmpString = hideComparisons ? "" : (comparison.length > 8 ? `${comparison.slice(0, 8)}..` : comparison);
    const hasCompetitorPricing = (state.propertyInView.competitors_info?? []).length > 0;

    useEffect(() => {
        setSidebarList("");

        if (sidebarData) {
            const {
                pickup,
                pickup_adr,
                average_rate,
                average_rate_dow,
                average_pickup,
                average_pickup_dow,
                pace,
                pace_adr,
                average_adr,
                average_occ,
                average_occ_dow,
                average_sell_rate,
                rate,
                pickup_rate,
                pace_rate,
                adr,
                total_occ,
                total_inventory,
                total_rev,
                individualColor,
                average_adr_dow,
                noOfItemsAggregated,
                pct_occ,
                pct_pace,
                pct_pickup,
                pct_avgdo,
                pct_avgdow,
                pct_avgdo_pickup,
                pct_avgdow_pickup,
                competitors_price
            } = sidebarData;

            const c = isGroup ? sidebarData.currency : currency;
            const isDaily = sidebarData.type === "daily";

            const getPaceSection = (value) => {
                const pace_sdly = usePercentsForOccs
                    ? percent(Math.round(pct_pace))
                    : formatDataWithPlusSign(pace);
                const pace_avgDo = usePercentsForOccs
                    ? percent(Math.round(pct_avgdo))
                    : formatDataWithPlusSign(average_occ);
                const pace_avgDow = usePercentsForOccs
                    ? percent(Math.round(pct_avgdow))
                    : formatDataWithPlusSign(average_occ_dow);


                switch (value) {
                    case "averageroomsold":
                        return [
                            { label: "AVG Room Sold", value: pace_avgDo, },
                            { label: "AVG DOW Room Sold", value: pace_avgDow, },
                            { label: 'SDLY', value: pace_sdly, },
                        ];
                    case "averagedowroomsold":
                        return [
                            { label: "AVG DOW Room Sold", value: pace_avgDow, },
                            { label: 'SDLY', value: pace_sdly, },
                            { label: "AVG Room Sold", value: pace_avgDo, },
                        ];
                    default:
                        return [
                            { label: getPaceTitle(calendar_data.pace_string), value: pace_sdly, },
                            { label: "AVG Room Sold", value: pace_avgDo, },
                            { label: "AVG DOW Room Sold", value: pace_avgDow, },
                        ];
                }
            };

            const getPaceRate = (value) => {
                switch (value) {
                    case "averageroomsold":
                        return withCurrency(formatData(isNullish(average_rate) ? null : Math.round(average_rate)), c);
                    case "averagedowroomsold":
                        return withCurrency(formatData(isNullish(average_rate_dow) ? null : Math.round(average_rate_dow)), c);
                    default:
                        return withCurrency(formatData(isNullish(pace_rate) ? null : Math.round(pace_rate)), c);
                }
            };

            const setClassColor = (color) => { return (color === "grey") ? "gray" : color; }


            setSidebarList(
                <div className="key-value-list">

                    {/* Pickup */}
                    <div
                        className={
                            `pickupTutorial pickup2Tutorial pickup3Tutorial set --` +
                            setClassColor(individualColor.pick_up)
                        }
                        onClick={() => {
                            if (dashModalEnabled) {
                                dispatch({ type: constants.SHOW_VISUALS, value: true })
                                toggleChartModal({ show: true, tab: 'pickup' })
                            }
                        }}
                    >
                        {state.tutorial.pickup.showSpot && (
                            <TutorialSpot
                                type="pickup"
                                section="sidepanel"
                                left="-43px"
                                bottom="76px"
                            />
                        )}
                        <div className="set__content--main">
                            <div className="set-label">
                                <span>Pickup</span>
                                <span>From {calendar_data.pick_up} days</span>
                            </div>
                            <div className="set-label">
                                <span className="value enlarged">
                                    {usePercentsForOccs
                                        ? percent(Math.round(pct_pickup))
                                        : formatDataWithPlusSign(pickup)
                                    }
                                </span>
                                {ADRState && (
                                    <span className="value normal">
                                        {withCurrency(formatData(pickup_adr), c)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="set__content">
                            <span className="label">AVG Pick-up </span>
                            <span className="value">
                                {usePercentsForOccs
                                    ? percent(Math.round(pct_avgdo_pickup))
                                    : formatDataWithPlusSign(average_pickup)
                                }
                            </span>
                        </div>

                        <div className="set__content">
                            <span className="label">AVG DOW Pick-up </span>
                            <span className="value">
                                {usePercentsForOccs
                                    ? percent(Math.round(pct_avgdow_pickup))
                                    : formatDataWithPlusSign(average_pickup_dow)
                                }
                            </span>
                        </div>

                        {!hideComparisons && comparisonData && <div className="set__content">
                            <span className="label">{cmpString} Pick-up</span>
                            <span className="value">
                                {usePercentsForOccs
                                    ? percent(Math.round(comparisonData.pct_pickup))
                                    : formatDataWithPlusSign(comparisonData.pickup)
                                }
                            </span>
                        </div>}
                    </div>


                    {/* Pace */}
                    <div
                        className={
                            `paceTutorial pace2Tutorial pace3Tutorial set --` +
                            setClassColor(individualColor.pace)
                        }
                        onClick={() => {
                            if (dashModalEnabled) {
                                dispatch({ type: constants.SHOW_VISUALS, value: true })
                                toggleChartModal({ show: true, tab: 'pace' })
                            }
                        }}
                    >
                        {state.tutorial.pace.showSpot && (
                            <TutorialSpot
                                type="pace"
                                section="sidepanel"
                                left="-43px"
                                bottom="76px"
                            />
                        )}
                        <div className="set__content--main">
                            <div className="set-label">
                                <span>Pace</span>
                                <span>
                                    {getPaceSection(calendar_data.pace_string)[0].label}
                                </span>
                            </div>

                            <div className="set-label">
                                <span className="value enlarged">
                                    {getPaceSection(calendar_data.pace_string)[0].value}
                                </span>
                                {ADRState && (
                                    <span className="value normal">
                                        {calendar_data.pace_string === "averageroomsold"
                                            ? withCurrency(formatData(average_adr), c)
                                            : calendar_data.pace_string ===
                                                "averagedowroomsold"
                                                ? withCurrency(formatData(average_adr_dow), c)
                                                : withCurrency(formatData(pace_adr), c)
                                        }
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="set__content">
                            <span className="label">
                                {getPaceSection(calendar_data.pace_string)[1].label}
                            </span>
                            <span className="value">
                                {getPaceSection(calendar_data.pace_string)[1].value}
                            </span>
                        </div>

                        <div className="set__content">
                            <span className="label">
                                {getPaceSection(calendar_data.pace_string)[2].label}
                            </span>
                            <span className="value">
                                {getPaceSection(calendar_data.pace_string)[2].value}
                            </span>
                        </div>

                        {!hideComparisons && comparisonData && <div className="set__content">
                            <span className="label">{cmpString} Room Sold</span>
                            <span className="value">
                                {usePercentsForOccs
                                    ? percent(Math.round(comparisonData.pct_pace))
                                    : formatDataWithPlusSign(comparisonData.pace)
                                }
                            </span>
                        </div>}
                    </div>


                    {/* Price */}
                    <div
                        className={
                            `sell_rateTutorial sell_rate2Tutorial sell_rate3Tutorial set --` +
                            setClassColor(individualColor.sell_rate)
                        }
                        onClick={() => {
                            if (dashModalEnabled) {
                                dispatch({ type: constants.SHOW_VISUALS, value: true })
                                toggleChartModal({ show: true, tab: 'price' })
                            }
                        }}
                    >
                        {state.tutorial.sell_rate.showSpot && (
                            <TutorialSpot
                                type="sell_rate"
                                section="sidepanel"
                                left="-43px"
                                bottom="58px"
                            />
                        )}
                        <div className="set__content--main">
                            <div className="set-label">
                                <span>Price</span>
                                <span>
                                    As of {dayjs(calendar_data.as_of).format("MMM DD, YYYY")}
                                </span>
                            </div>
                            <span className="value enlarged">
                                {withCurrency(formatData(average_sell_rate || (isNullish(rate) ? null : Math.round(rate))), c)}
                            </span>
                        </div>

                        {isDaily && hasCompetitorPricing && <>
                            <div className="set__content">
                                <span className="label">
                                    Price from{" "}
                                    <span>{getPaceTitle(calendar_data.pace_string)}</span>
                                </span>
                                <span className="value">{getPaceRate(calendar_data.pace_string)}</span>
                            </div>
                            <div className="set__content">
                                <span className="label">
                                    {userPrefs.enableLowestPriceCompetitor
                                        ? "Lowest Competitor Price"
                                        : "Average Competitor Price"
                                    }
                                </span>
                                <span className="value">
                                    {withCurrency(formatData((isNullish(competitors_price) ? null : Math.round(competitors_price))), c)}
                                </span>
                            </div>
                        </>}

                        {!(isDaily && hasCompetitorPricing) && <>
                            <div className="set__content">
                                <span className="label">
                                    Price from {calendar_data.pick_up.substring(1)} days
                                </span>
                                <span className="value">
                                    {withCurrency(formatData((isNullish(pickup_rate) ? null : Math.round(pickup_rate))), c)}
                                </span>
                            </div>
                            <div className="set__content">
                                <span className="label">
                                    Price from{" "}
                                    <span>{getPaceTitle(calendar_data.pace_string)}</span>
                                </span>
                                <span className="value">{getPaceRate(calendar_data.pace_string)}</span>
                            </div>
                        </>}
                        

                        {!hideComparisons && comparisonData && <div className="set__content">
                            <span className="label">Price from {cmpString}</span>
                            <span className="value">{withCurrency(formatData(
                                isNullish(comparisonData.rate) ? null : Math.round(comparisonData.rate)
                            ), c)}</span>
                        </div>}
                    </div>


                    {/* On The Books */}
                    <div id="otbTutorial" className="set --" onClick={() => {
                        if ((isGroup || (!isGroup && isSegmentFeaturesEnabled)) && dashModalEnabled) {
                            dispatch({ type: constants.SHOW_VISUALS, value: true })
                            toggleChartModal({ show: true, tab: 'on-the-books' })
                        }
                    }}>
                        <div className="set__content--main">
                            <div className="set-label">
                                <span>On The Books</span>
                                <span>Rooms Sold</span>
                            </div>
                            <span className="value enlarged">
                                {usePercentsForOccs ? percent(Math.round(pct_occ)) : formatData(total_occ, 10000, true)}
                            </span>
                        </div>

                        {ADRState && (
                            <div className="set__content">
                                <span className="label">ADR</span>
                                <span className="value">
                                    {withCurrency(formatData(adr), c)}
                                </span>
                            </div>
                        )}

                        <div className="set__content">
                            <span className="label">{usePercentsForOccs ? 'Total Occupancy' : 'OCC%'}</span>
                            <span className="value">{usePercentsForOccs ? total_occ : percent(Math.round(pct_occ))}</span>
                        </div>

                        <div className="set__content">
                            <span className="label">Total Inventory</span>
                            <span className="value">{total_inventory}</span>
                        </div>

                        <div className="set__content">
                            <span className="label">Total Rev</span>
                            <span className="value">
                                {withCurrency(formatData(total_rev, 10000, true), c)}
                            </span>
                        </div>

                        {!hideComparisons && comparisonData && <div className="set__content">
                            <span className="label">{cmpString} OCC%</span>
                            <span className="value">{percent(Math.round(comparisonData.pct_occ))}</span>
                        </div>}
                        {!hideComparisons && comparisonData && <div className="set__content">
                            <span className="label">{cmpString} ADR</span>
                            <span className="value">{
                                withCurrency(formatData(comparisonData.adr), c)
                            }</span>
                        </div>}

                    </div>
                </div>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sidebarData,
        comparisonData,
        ADRState,
        state.tutorial.pickup.showSpot,
        state.tutorial.pace.showSpot,
        state.tutorial.sell_rate.showSpot,
        state.tutorial.otb.showSpot,
    ]);

    const containerStyles = {
        width: state.showSidebar ? "calc(100vw - 300px)" : "100%",
    };
    const sidebarStyles = {
        transform: `translateX(${state.showSidebar ? "0" : "300px"})`,
        width: "300px",
    };


    return (
        <div className="dashboard">
            <div className="dashboard-content" style={containerStyles}>
                <Calendar />
            </div>

            <div className={`dashboard-sidebar-container`} style={sidebarStyles}>
                <div id="sidepanelTutorial" className="dashboard-sidebar">
                    <DashboardSidebar info={sidebarList} data={state.sidebarData} />
                </div>
            </div>

            {/* Developer Tools UI */}
            {/* Only used for easily viewing several state data via the app */}
            {/* Instead of having to open the browser's dev-tools all the time */}
            <Tools />
        </div >
    );
}
