import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { ToastsStore } from "react-toasts";

import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";
import BasicInput from "elements/Input/Basic";
import ModalSelect from "elements/Select/Modal";
import Error from "elements/Text/Error";

import useProperties from "../../store/actions/propertiesActions";
import { isNullOrUndefined } from "utils";
import { isAlphaNumeric, sortArray } from "utils/data";
import constants from "../../constants";



const RoomTypesModal = ({ visible, type, rts = [], onCancel = null, onSubmit = null }) => {
    const state = useSelector((state) => state.mainReducer);
    const { listItemInView, propertyInView } = state;
    const { updateRoomMappings } = useProperties();

    const roomtypes = sortArray(rts, "name")
        .map((item) => { return { name: `${item.name} (${item._id})`, value: item._id } });

    const [roomname, setRoomName] = useState("");
    const [roomcode, setRoomCode] = useState("");
    const [roomtype, setRoomType] = useState("");
    const [roominventory, setRoomInventory] = useState("");

    const [hasAtLeastOneChannelMapping, setHasAtLeastOneChannelMapping] = useState(false);
    const [modalErrors, setModalErrors] = useState([]);

    const isViewModal = type === "view";
    const isAddModal = type === "add";
    const isEditModal = type === "edit";
    const isDeleteModal = type === "delete";


    // Dynamic Header Copy & Element
    let headerCopy = "";
    if (isAddModal) headerCopy = "Add Room Type";
    if (isViewModal) headerCopy = "Room Type";
    if (isEditModal) headerCopy = "Edit Room Type";
    if (isDeleteModal) headerCopy = "Delete Room Type";

    // Dynamic Success/Fail Copy
    let successCopy = "";
    if (isAddModal) successCopy = "Room type successfully added";
    if (isEditModal) successCopy = "Room type successfully updated";
    if (isDeleteModal) successCopy = "Room type successfully removed";

    let failureCopy = "";
    if (isAddModal) failureCopy = "Sorry, we're unable to add the room type at this time";
    if (isEditModal) failureCopy = "Sorry, we're unable to update the room type at this time";
    if (isDeleteModal) failureCopy = "Sorry, we're unable to remove the room type at this time";


    // Happens in Edit/Delete Mode
    // Pre-Fill Modal with Generic listItemInView State Data if a Partner Item is Selected
    useEffect(() => {
        resetModal();
        if (isEditModal && !isNullOrUndefined(listItemInView)) {
            setRoomName(listItemInView.room_type);
            setRoomCode(listItemInView.room_type_code);
            setRoomType(listItemInView.mappings.filter(m => m.partner_code === "REVANDYOU")[0].room_type_code);
            setRoomInventory(listItemInView.total_inventory);
        }

        if (isDeleteModal) {
            let roomtypeMatch = propertyInView.room_types.filter((rt) => {
                return rt.room_type === listItemInView.room_type && rt.room_type_code === listItemInView.room_type_code;
            })[0];
            setHasAtLeastOneChannelMapping(roomtypeMatch.mappings.length > 1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);



    // Modal Actions
    const handleModalErrors = (data) => {
        let errors = [];
        if (data.room_type === "") errors.push({ id: "name", message: "Please provide a room name" });
        if (data.room_type_code === "") errors.push({ id: "code", message: "Please provide a room code" });
        if (data.mappings[0].room_type_code === "") errors.push({ id: "type", message: "Please select a room type" });
        if (data.total_inventory === "") errors.push({ id: "inventory", message: "Please provide a room inventory" });

        if (data.room_type !== "" && !isAlphaNumeric(data.room_type)) {
            errors.push({ id: "name", message: "Name contains non-alphanumeric characters" });
        }
        if (data.room_type_code !== "" && !isAlphaNumeric(data.room_type_code, false)) {
            errors.push({ id: "code", message: "Code contains non-alphanumeric characters" });
        }

        return errors;
    }

    const resetModal = () => {
        setRoomName("");
        setRoomCode("");
        setRoomType("");
        setRoomInventory("");
        setModalErrors([]);
    }

    const handleModalCancel = () => {
        onCancel();
        resetModal();
    }

    const handleModalDelete = () => {
        updateRoomMappings(propertyInView._id, {
            op: "delete",
            type: "roomtype",
            filter: {
                room_type: listItemInView.room_type,
                room_type_code: listItemInView.room_type_code,
                mapping_code: listItemInView.mappings.filter(m => m.partner_code === "REVANDYOU")[0].room_type_code
            }
        }).then((res) => {
            if (res.type === constants.UPDATE_PROPERTY_ROOM_MAPPINGS_SUCCESS) {
                onSubmit();
                resetModal();
                ToastsStore.success(successCopy);
            } else {
                ToastsStore.error(failureCopy);
            }
        });
    }

    const handleModalSubmit = () => {
        const roomtypeName = (roomtype !== "") ? rts.find(rt => rt._id === roomtype)?.name : "";

        let nonRevMappings = isNullOrUndefined(listItemInView?.mappings)
            ? []
            : listItemInView?.mappings.filter(m => m.partner_code !== "REVANDYOU");
        let data = {
            room_type: roomname,
            room_type_code: roomcode,
            mappings: [
                { partner_code: "REVANDYOU", room_type_code: roomtype, room_type_name: roomtypeName },
                ...nonRevMappings
            ],
            total_inventory: roominventory,
        };

        let errors = handleModalErrors(data);
        if (errors.length === 0) {
            let alreadyExists = isAddModal
                && propertyInView.room_types.filter(rt => rt.room_type_code === roomcode).length > 0;

            if (alreadyExists) {
                ToastsStore.error(`A room type with the same code already exists`);
            } else {
                updateRoomMappings(propertyInView._id, {
                    op: isEditModal ? "edit" : "add",
                    type: "roomtype",
                    filter: isEditModal ? { before: listItemInView, after: data } : data
                }).then((res) => {
                    if (res.type === constants.UPDATE_PROPERTY_ROOM_MAPPINGS_SUCCESS) {
                        onSubmit();
                        resetModal();
                        ToastsStore.success(successCopy);
                    } else {
                        ToastsStore.error(failureCopy);
                    }
                });
            }
        } else {
            setModalErrors(errors);
        }
    }



    return (
        <Modal open={visible} onCancel={() => handleModalCancel()} footer={null} closeIcon={
            <IconButton type="delete" hasAccess={true} width="25px" />
        }>
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>

                <form onSubmit={(e) => null} className="light-form">
                    {!isDeleteModal && <>

                        <div style={{ display: "grid", gridTemplateColumns: "48% 4% 48%" }}>
                            <div>
                                {/* Room Name */}
                                <BasicInput id="roomName" label="Room Name" value={roomname}
                                    disabled={false} onBlur={(e) => setRoomName(e.target.value)}
                                    onChange={(e) => setRoomName(e.target.value)} />
                                {modalErrors.filter(err => err.id === "name").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>

                            <span />

                            <div>
                                {/* Room Code */}
                                <BasicInput id="roomCode" label="Room Code" value={roomcode}
                                    disabled={isEditModal} onBlur={(e) => setRoomCode(e.target.value)}
                                    onChange={(e) => setRoomCode(e.target.value)} />
                                {modalErrors.filter(err => err.id === "code").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>
                        </div>

                        {/* Map to Rev&You Room Type Dropdown */}
                        <ModalSelect label="Map to Rev&You Room Type" value={roomtype} options={roomtypes}
                            disabled={false} action={(value) => setRoomType(value)} />
                        {modalErrors.filter(err => err.id === "type").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        {/* Room Code */}
                        <BasicInput id="roomInventory" label="Total Inventory" value={roominventory}
                            disabled={false} onBlur={(e) => setRoomInventory(e.target.value)}
                            onChange={(e) => setRoomInventory(e.target.value)} />
                        {modalErrors.filter(err => err.id === "inventory").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                    </>}


                    {/* Delete Room Type Copy */}
                    {isDeleteModal && !hasAtLeastOneChannelMapping && <div style={{ padding: 20 }}>
                        <p>
                            <strong>Room Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.room_type_code}</span>
                            <br />
                            <strong>Room Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.room_type}</span>
                            <br />
                            <strong>Rev&You Room Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mappings[0].room_type_code}</span>
                            <br />
                            <strong>Rev&You Room Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mappings[0].room_type_name}</span>
                        </p>
                        <p>Are you sure you want to delete this room type?</p>
                    </div>}
                    {isDeleteModal && hasAtLeastOneChannelMapping && <div style={{
                        padding: 20, display: "grid", alignItems: "center"
                    }}>
                        <span>This room type is mapped to at least one channel.</span>
                        <span>You must remove all mappings to proceed.</span>
                    </div>}


                    {/* Cancel, Submit, Delete Buttons */}
                    <div className="light-form-buttons" style={{ marginTop: isDeleteModal ? 20 : 40 }}>
                        {!(isDeleteModal && hasAtLeastOneChannelMapping) && <>
                            <ActionButton type="cancel" action={() => handleModalCancel()} text="Cancel" />
                            <span style={{ width: 20 }} />
                        </>}
                        {isDeleteModal
                            ? hasAtLeastOneChannelMapping
                                ? <ActionButton type="submit" action={() => handleModalCancel()} text="OK" />
                                : <ActionButton type="submit" action={() => handleModalDelete()} text="Delete" />
                            : <ActionButton type="submit" action={() => handleModalSubmit()} text="Submit" />
                        }
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default RoomTypesModal;
