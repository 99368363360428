// import moment from "moment";
import dayjs from "dayjs";
import axios from "axios";

import constants from "../../constants";
import { createError } from "../../utils/index";
import { vs, withForecast, avgPriceByRemainingDo } from "../../utils/summary/forecast";
import { isNullish, round, arraySum, byDo, byDoDow } from "../../utils/data";

const CancelToken = axios.CancelToken;


function dashboard(
    state = {
        source: CancelToken.source(),
        loading: false,
        bgLoading: false,
        dailyFilter: {},
        daily: [],
        dailyData: [],
        dailyErrors: [],
        monthlyData: [],
        weeklyData: [],
        customDailyData: [],
        chartData: [],
        dashSidebarEnabled: false,
        dashModalEnabled: false,
        algoModalEnabled: false,
        varianceAvgs: null,
        variances: null,
    },
    action
) {
    switch (action.type) {
        case constants.SET_SOURCE:
            return {
                ...state,
                source: CancelToken.source()
            };
        case constants.GET_DAILY_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                bgLoading: true,
                dashSidebarEnabled: true,
                algoModalEnabled: true,
                dashModalEnabled: true,
                dailyData: [],
                currentSearchTerm: action.searchTerm,
            };
        case constants.GET_DAILY_DATA_SUCCESS:
            const response = action.response.data.data;
            const arr = response.map((item) => {
                return {
                    ...item,
                    selected: false,
                };
            });
            return {
                ...state,
                loading: false,
                bgLoading: false,
                dailyData: arr,
            };
        case constants.GET_DAILY_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
            };
        case constants.GET_CUSTOM_DAILY_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                bgLoading: true,
                dashSidebarEnabled: true,
                algoModalEnabled: true,
                dashModalEnabled: true,
                customDailyData: [],
                currentSearchTerm: action.searchTerm,
            };
        case constants.GET_CUSTOM_DAILY_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                customDailyData: action.response.data.data.map((item) => {
                    return {
                        ...item,
                        selected: false,
                    };
                }),
            };
        case constants.GET_CUSTOM_DAILY_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
            };
        case constants.GET_DAILY_REQUEST:
            return {
                ...state,
                loading: true,
                bgLoading: true,
                dashSidebarEnabled: false,
                algoModalEnabled: false,
                dashModalEnabled: false,
                dailyData: [],
                currentSearchTerm: action.searchTerm,
            };
        case constants.GET_DAILY_SUCCESS:
            const daily = action.response.data;
            const dailyData = daily.data.map((item) => {
                return {
                    ...item,
                    selected: false,
                };
            });

            return {
                ...state,
                loading: false,
                bgLoading: false,
                dailyData: dailyData,
                daily: daily.data,
                dailyFilter: daily.filter
            };
        case constants.GET_DAILY_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.GET_CURRENT_AVGS_DATA_SUCCESS:
            const avgs = action.response.data.data;

            const dailyWithCurrentAvgs = state.daily.map((item) => {
                const occ = item.o;
                const rev = item.r;
                const dout = item.dout < 0 ? -1 : item.dout;
                const dow = item.dow;
                const dowIndex = dow - 1;
                const avgAsOfByDo = byDo(avgs?.avgsByDo[0] ?? [], dout);
                const avgAsOfByDoDow = byDoDow(avgs?.avgsByDoDow ?? [], dowIndex, dout);

                const computedAvgs = {
                    byDo: {
                        avg_occ: avgAsOfByDo ? round(occ - avgAsOfByDo.avg_occ) : null,
                        avg_rev: avgAsOfByDo ? round(rev - avgAsOfByDo.avg_rev) : null,
                        avg_adr: avgAsOfByDo ? round((rev / occ) - (avgAsOfByDo.sum_rev / avgAsOfByDo.sum_occ)) : null,
                        avg_rate: avgAsOfByDo?.avg_rate ?? null,
                        pickup: avgAsOfByDo ? round(avgAsOfByDo.pickup) : null,
                        sum_occ: avgAsOfByDo?.sum_occ ?? null,
                        sum_rev: avgAsOfByDo?.sum_rev ?? null,
                    },
                    byDoDow: {
                        avg_occ: avgAsOfByDoDow ? round(occ - avgAsOfByDoDow.avg_occ) : null,
                        avg_rev: avgAsOfByDoDow ? round(rev - avgAsOfByDoDow.avg_rev) : null,
                        avg_adr: avgAsOfByDoDow ? round((rev / occ) - (avgAsOfByDoDow.sum_rev / avgAsOfByDoDow.sum_occ)) : null,
                        avg_rate: avgAsOfByDoDow?.avg_rate ?? null,
                        pickup: avgAsOfByDoDow ? round(avgAsOfByDoDow.pickup) : null,
                        sum_occ: avgAsOfByDoDow?.sum_occ ?? null,
                        sum_rev: avgAsOfByDoDow?.sum_rev ?? null,
                    }
                }

                item._computed.avgs = computedAvgs;
                item._computed._breakdown = {
                    ...item._computed._breakdown,
                    avgAsOfByDo: avgs?.avgsByDo ?? [],
                    avgAsOfByDoDow: avgs?.avgsByDoDow ?? [],
                };

                return {
                    ...item,
                    average_total_rev: computedAvgs.byDo.sum_rev,
                    average_total_occ: computedAvgs.byDo.sum_occ,
                    average_occ: computedAvgs.byDo.avg_occ,
                    average_rev: computedAvgs.byDo.avg_rev,
                    average_adr: computedAvgs.byDo.avg_adr,
                    average_rate: computedAvgs.byDo.avg_rate,
                    average_pickup: computedAvgs.byDo.pickup,
                    average_total_rev_dow: computedAvgs.byDoDow.sum_rev,
                    average_total_occ_dow: computedAvgs.byDoDow.sum_occ,
                    average_occ_dow: computedAvgs.byDoDow.avg_occ,
                    average_rev_dow: computedAvgs.byDoDow.avg_rev,
                    average_adr_dow: computedAvgs.byDoDow.avg_adr,
                    average_rate_dow: computedAvgs.byDoDow.avg_rate,
                    average_pickup_dow: computedAvgs.byDoDow.pickup,
                };
            });

            return {
                ...state,
                daily: dailyWithCurrentAvgs,
                dailyData: dailyWithCurrentAvgs,
                dashSidebarEnabled: true,
            }
        case constants.GET_CURRENT_AVGS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.GET_FORECAST_AVGS_DATA_SUCCESS:
            const forecastAvgs = action.response.data.data;

            const dailyWithForecastAvgs = state.daily.map((item) => {
                const dout = item.dout < 0 ? -1 : item.dout;
                const dow = item.dow;
                const dowIndex = dow - 1;

                item._computed.avgs = {
                    ...item._computed.avgs,
                    pickupByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed.avgs.byDo
                        : byDo((forecastAvgs?.avgPickupByDo ?? [])[0] ?? [], dout),
                    pickupByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed.avgs.byDoDow
                        : byDoDow(forecastAvgs?.avgPickupByDoDow ?? [], dowIndex, dout),
                    paceByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed.avgs.byDo
                        : byDo((forecastAvgs?.avgPaceByDo ?? [])[0] ?? [], dout),
                    paceByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed.avgs.byDoDow
                        : byDoDow(forecastAvgs?.avgPaceByDoDow ?? [], dowIndex, dout),
                    priceByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed.avgs.byDo
                        : byDo((forecastAvgs?.avgPriceByDo ?? [])[0] ?? [], dout),
                    priceByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed.avgs.byDoDow
                        : byDoDow(forecastAvgs?.avgPriceByDoDow ?? [], dowIndex, dout),
                    finalsByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed.avgs.byDo
                        : byDo((forecastAvgs?.avgFinalsByDo ?? [])[0] ?? [], -1),
                    finalsByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed.avgs.byDoDow
                        : byDoDow(forecastAvgs?.avgFinalsByDoDow ?? [], dowIndex, -1),
                }

                item._computed._breakdown = {
                    ...item._computed._breakdown,
                    avgPickupByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed._breakdown.avgAsOfByDo
                        : (forecastAvgs?.avgPickupByDo ?? [])[0],
                    avgPickupByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed._breakdown.avgAsOfByDoDow
                        : forecastAvgs?.avgPickupByDoDow ?? [],
                    avgPaceByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed._breakdown.avgAsOfByDo
                        : (forecastAvgs?.avgPaceByDo ?? [])[0],
                    avgPaceByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed._breakdown.avgAsOfByDoDow
                        : forecastAvgs?.avgPaceByDoDow ?? [],
                    avgPriceByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed._breakdown.avgAsOfByDo
                        : (forecastAvgs?.avgPriceByDo ?? [])[0],
                    avgPriceByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed._breakdown.avgAsOfByDoDow
                        : forecastAvgs?.avgPriceByDoDow ?? [],
                    avgPriceRemainByDo: forecastAvgs?.avgPriceRemainByDo ?? [],
                    avgPriceRemainByDoDow: forecastAvgs?.avgPriceRemainByDoDow ?? [],
                    avgFinalsByDo: forecastAvgs.useAvgAsOfByDo
                        ? item._computed._breakdown.avgAsOfByDo
                        : (forecastAvgs?.avgFinalsByDo ?? [])[0],
                    avgFinalsByDoDow: forecastAvgs.useAvgAsOfByDoDow
                        ? item._computed._breakdown.avgAsOfByDoDow
                        : forecastAvgs?.avgFinalsByDoDow ?? [],
                };

                return item;
            });

            return {
                ...state,
                daily: dailyWithForecastAvgs,
                dailyData: dailyWithForecastAvgs,
            }
        case constants.GET_FORECAST_AVGS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.GET_INSTANCES_AVGS_DATA_REQUEST:
            return {
                ...state,
                variances: null,
            }
        case constants.GET_INSTANCES_AVGS_DATA_SUCCESS:
            return {
                ...state,
                varianceAvgs: action.response.data.data
            }
        case constants.GET_INSTANCES_AVGS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.GET_VARIANTS_DATA_SUCCESS:
            const variants = action.response.data.data;

            const dailyWithVariants = state.daily.map((item) => {
                item._computed.variants = variants;
                return item;
            });

            return {
                ...state,
                daily: dailyWithVariants,
                dailyData: dailyWithVariants,
            };
        case constants.GET_VARIANTS_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.GET_SDLY_DATA_SUCCESS:
            const sdlys = action.response.data.data;
            const filter = action.response.data.filter;

            const dailyWithSDLY = state.daily.map((item, index) => {
                const cin = item.cin;
                const occ = item.o;
                const dout = item.dout < 0 ? -1 : item.dout;
                const dow = item.dow;
                const dowIndex = dow - 1;

                const scin = new Date(dayjs(cin).subtract(52, "weeks"));
                const scinNextDay = new Date(dayjs(scin).add(1, "days"));
                const sdly = sdlys.find((p) => {
                    let cin = new Date(p.cin);
                    return p.dout === dout && cin >= scin && cin < scinNextDay;
                });
                const sdlyPick = sdlys.find((p) => {
                    let cin = new Date(p.cin);
                    let pickup = Math.abs(parseInt(filter.forecast.variantPickup));
                    return p.dout === (dout + pickup) && cin >= scin && cin < scinNextDay;
                });
                const sdlyFinals = sdlys.find((p) => {
                    let cin = new Date(p.cin);
                    return p.dout === -1 && cin >= scin && cin < scinNextDay;
                });

                item._computed.sdly = {
                    occ: sdly?.o ?? null,
                    occPick: sdlyPick?.o ?? null,
                    occFinals: sdlyFinals?.o ?? null,
                    pickup: round(arraySum([(sdly?.o ?? null), ((sdlyPick?.o ?? null) === null ? null : (sdlyPick.o * (-1)))]))
                }


                const rsdly = sdlys.filter((p) => {
                    let cin = new Date(p.cin);
                    return cin >= scin && cin < scinNextDay;
                });

                const breakdown = item._computed._breakdown;
                item._computed.remaindays = {
                    price: {
                        sdly: avgPriceByRemainingDo("sdly", rsdly, dout),
                        avg: avgPriceByRemainingDo("ado", (breakdown?.avgPriceRemainByDo ?? [])[0] ?? [], dout),
                        avg_dow: avgPriceByRemainingDo("adow", breakdown?.avgPriceRemainByDoDow[dowIndex] ?? [], dout),
                    }
                }

                item._computed.vs = vs(filter, {
                    pickups: breakdown.pickups,
                    variants: item._computed.variants,
                    avgs: breakdown,
                    pace: item._computed.pace,
                    sdly: item._computed.sdly,
                    cin: cin,
                    dout: dout,
                    dow: dow,
                    occ: occ,
                })

                return item;
            });

            return {
                ...state,
                daily: dailyWithSDLY,
                dailyData: dailyWithSDLY,
            }
        case constants.GET_SDLY_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.GET_INSTANCES_DATA_SUCCESS:
            const instances = action.response.data.data;

            const dailyWithInstances = state.daily.map((item) => {
                const match = instances.find(i => i.cin === item.cin);
                item._computed.icolors = match.icolors;

                return item;
            });

            return {
                ...state,
                daily: dailyWithInstances,
                dailyData: dailyWithInstances,
            }
        case constants.GET_INSTANCES_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.COMPUTE_FORECASTS_SUCCESS:
            const dailyWithForecasts = withForecast(state.dailyFilter, state.daily);

            return {
                ...state,
                daily: dailyWithForecasts,
                dailyData: dailyWithForecasts,
                algoModalEnabled: true,
                dashModalEnabled: true
            };
        case constants.COMPUTE_FORECASTS_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
                dailyErrors: state.dailyErrors.splice(0, 1, action.error)
            };
        case constants.GET_VARIANCES_BY_DAYSOUT_DATA_REQUEST:
            return {
                ...state,
                variances: null,
            };
        case constants.GET_VARIANCES_BY_DAYSOUT_DATA_SUCCESS:
            return {
                ...state,
                variances: action.response.data.data.variances
            };
        // case constants.GET_CHART_COMP_PRICE_BY_DAYS_OUT_REQUEST:
        //     return {
        //         ...state,
        //         comPricing: {
        //             byDo: null
        //         },
        //     };
        // case constants.GET_CHART_COMP_PRICE_BY_DAYS_OUT_SUCCESS:
        //     return {
        //         ...state,
        //         comPricing: {
        //             byDo: action.response.data.data
        //         }
        //     };
        case constants.GET_WEEKLY_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                bgLoading: true,
            };
        case constants.GET_WEEKLY_DATA_SUCCESS:
            const weeklyResponse = action.response.data.data;
            let weeklyArr = weeklyResponse.map((item) => {
                return {
                    ...item,
                    selected: false,
                };
            });

            if (action.additional === "end") {
                weeklyArr = [...state.weeklyData, ...weeklyArr];
            }
            if (action.additional === "beginning") {
                weeklyArr = [...weeklyArr, ...state.weeklyData];
            }

            return {
                ...state,
                loading: false,
                bgLoading: false,
                weeklyData: weeklyArr,
            };
        case constants.GET_WEEKLY_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
            };
        case constants.GET_MONTHLY_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                bgLoading: true,
            };
        case constants.GET_MONTHLY_DATA_SUCCESS:
            const monthlyResponse = action.response.data.data;
            let monthlyArr = monthlyResponse.map((item) => {
                return {
                    ...item,
                    selected: false,
                };
            });
            if (action.additional === "end") {
                monthlyArr = [...state.monthlyData, ...monthlyArr];
            }
            if (action.additional === "beginning") {
                monthlyArr = [...monthlyArr, ...state.monthlyData];
            }
            return {
                ...state,
                loading: false,
                bgLoading: false,
                monthlyData: monthlyArr,
            };
        case constants.GET_MONTHLY_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
            };
        default:
            return state;
    }
}

export default dashboard;
