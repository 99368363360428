/* eslint no-eval: 0 */
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
import dayjs from 'dayjs';

import useChartView from "../ChartView/useChartView";
import useHelperFunctions from "../../store/actions/actions";
import useDashboardActions from "../../store/actions/dashboardActions";

import constants from "../../constants";
import {
    getPace, getPaceForAggregatedWeekly, getISOStandardDOW
} from "../../utils";
import { generateFilter } from "../../utils/calendar";

const useCalendar = () => {
    const state = useSelector((state) => state.mainReducer);
    const users = useSelector((state) => state.users);
    const isGroup = state.isGroup;
    const ALLSEGMENTS = constants.ALLSEGMENTS;
    const UNSEGMENTED = constants.UNSEGMENTED;

    const dispatch = useDispatch();
    const { getStatisticsSegmentsData } = useDashboardActions();
    const { handleSubmitForm } = useChartView();
    const { emptyDataStore, toggleSidePanel } = useHelperFunctions();

    const handleChangeCheckinStart = (value) => {
        emptyDataStore();

        dispatch({
            type: constants.UPDATE_CALENDAR_SETTINGS,
            value: {
                ...state.calendar_data,
                check_in_start: dayjs.utc(value).startOf("month"),
                check_in_end: dayjs.utc(value).endOf("month").startOf("day"),
            },
        });
    };

    const changeMonth = (direction) => {
        toggleSidePanel(false);
        emptyDataStore();
        handleChangeCheckinStart(
            dayjs(state.calendar_data.check_in_start)
                .add(direction, "months")
        );
    };

    const changeYear = (direction) => {
        toggleSidePanel(false);
        emptyDataStore();
        handleChangeCheckinStart(
            dayjs(state.calendar_data.check_in_start)
                .add(direction, "years")
        );
    };

    const toggleCell = (item, type) => {
        let clickedCell;
        let dateRanges;
        let dow = Object.keys(state.dowObject).filter((d) => state.dowObject[d]);
        switch (type) {
            case "daily":
                item.type = "daily";
                clickedCell = item.check_in;

                if (state.calendar_data.pace_string === "averagedowroomsold") {
                    dow = getISOStandardDOW(dayjs(clickedCell));
                }

                const paceDaily = getPace(state.calendar_data.pace_string, clickedCell);
                let paceDailyStart;
                let paceDailyEnd;
                if (Array.isArray(paceDaily)) {
                    paceDailyStart = paceDaily[0];
                    paceDailyEnd = paceDaily[1];
                } else {
                    paceDailyStart = paceDaily;
                    paceDailyEnd = paceDaily;
                }
                if (state.calendar_data.pace_string === "averagedowroomsold" || state.calendar_data.pace_string === "averageroomsold") {
                    paceDailyStart = state.calendar_data.pace
                    paceDailyEnd = state.calendar_data.as_of
                }
                dateRanges = [
                    { startDate: clickedCell, endDate: clickedCell },
                    { startDate: paceDailyStart, endDate: paceDailyEnd },
                ];
                break;

            case "weekly":
                item.type = "weekly";
                item.key = `${item.week}_${item.year}`;
                clickedCell = item.key;
                const firstDay = dayjs(item.week_range[0]).format("MM-DD-YYYY");
                const paceWeekly = getPaceForAggregatedWeekly(
                    state.calendar_data.pace_string,
                    firstDay
                );
                dateRanges = [
                    { startDate: item.dateRange[0], endDate: item.dateRange[1] },
                    { startDate: paceWeekly.start, endDate: paceWeekly.end },
                ];
                break;

            case "monthly":
                item.type = "monthly";
                item.key = `${item.month}_${item.year}`;
                clickedCell = item.key;
                const paceMonthlyStart = dayjs(item.dateRange[0])
                    .subtract(1, "year");
                const paceMonthlyEnd = dayjs(item.dateRange[1])
                    .subtract(1, "year");
                dateRanges = [
                    { startDate: item.dateRange[0], endDate: item.dateRange[1] },
                    { startDate: paceMonthlyStart, endDate: paceMonthlyEnd },
                ];
                break;
            default:
                break;
        }

        if (state.activeCell === clickedCell) {
            dispatch({
                type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                value: null
            });
            toggleSidePanel(false);
        } else {
            dispatch({
                type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                value: clickedCell
            });
            toggleSidePanel(true);
            dispatch({  
                type: constants.UPDATE_SIDEBAR_CONTENT,
                value: item
            });
            getBookingChart(item, dateRanges, dow);
        }
    };

    const getBookingChart = (item, dateRanges, dow = []) => {
        dispatch({
            type: constants.SET_ISLOADING,
            value: true,
            loading: "sidebarChartDataIsLoading",
        });

        const filter = {
            property_id: state.hotelId,
            check_in: dateRanges.map((d) => {
                return { range: [d.startDate, d.endDate] };
            }),
            days_out: constants.DAYS_OUT_OPTIONS[constants.DAYS_OUT_OPTIONS.length - 1].days_out, // Max days out available
            dow: dow,
            pace: state.calendar_data.pace
        };
        handleSubmitForm(filter, dateRanges, constants.SET_SIDEBAR_CHART_DATA);
    };

    const getFilterSegments = () => {
        const filterSegments = state.segmentsInView;
        const userSelectedSegments = state.calendar_data.segments;
        const hotelSegmentCodes = [{ id: ALLSEGMENTS, name: ALLSEGMENTS }];

        const useSegmentGroup = userSelectedSegments.includes(ALLSEGMENTS) || userSelectedSegments[0].includes('GROUP-');

        filterSegments[
            useSegmentGroup? "segment_groups": "segments"
        ].forEach(segment => {
            hotelSegmentCodes.push({
                id: `${useSegmentGroup? "GROUP-": ""}${segment.code}`,
                name: segment.name
            });
        });

        return hotelSegmentCodes;
    }

    const getStatisticsSegments = async (viewReservationsEnabled, hotelSegmentCodes, visualDate) => {
        const { calendar_data, propertyInView, sidebarData } = state;
        const startDate = sidebarData.type === 'daily'? sidebarData?.check_in: sidebarData.week_range[0];
        const endDate = sidebarData.type === 'daily'? sidebarData?.check_in: sidebarData.week_range[1];
        // Send Visual Segments Request
        // propertyInView's id is an ID of property or property group
        // Convert date ranges to UTC because its data came from DB.
        return await getStatisticsSegmentsData(generateFilter(
            isGroup ? (propertyInView?.properties ?? []).map(_ => _.id) : propertyInView._id, 
            calendar_data.as_of,
            [dayjs.utc(startDate), dayjs.utc(endDate)],
            calendar_data.pick_up,
            calendar_data.pace,
            hotelSegmentCodes.filter(segment => segment != ALLSEGMENTS),
            calendar_data.roomtype,
            users.authUser.prefs,
            propertyInView._id,
            calendar_data.pace_string,
            viewReservationsEnabled
        )).then((res) => {
            // Then Process the Response Data if Successful
            if (res?.type !== constants.GET_STATISTICS_SEGMENTS_DATA_SUCCESS) return;
            const segmentsData = res?.response?.data?.data;
            if (segmentsData && Array.isArray(segmentsData.data)) {
                const data = segmentsData.data.sort((a, b) => {
                    var segmentA = a.segment.toString().toUpperCase();
                    var segmentB = b.segment.toString().toUpperCase();
                    return (segmentA < segmentB) ? -1 : (segmentA > segmentB) ? 1 : 0;
                });

                dispatch({
                    type: constants.UPDATE_STATISTICS_SEGMENTS_DATA,
                    value: {
                        current: data,
                        cache: [{ id: visualDate, value: data }, ...state.statisticsSegmentsData.cache]
                    }
                });

                return data;
            }
            return segmentsData.data;
        });
    }

    return {
        changeMonth,
        changeYear,
        handleChangeCheckinStart,
        toggleCell,
        getFilterSegments,
        getStatisticsSegments
    };
};

export default useCalendar;
