import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

import Form from "components/Forms/Form";
import useForm from "components/Forms/useForm";
import validateForm from "components/Forms/validateForm";
import Accordion from "elements/Accordion/Accordion";
import Button from "components/Button/Button";

import useUsersActions from "store/actions/usersActions";
import { account_information, reset_password, preferences } from "./AccountFields";
import { isNullOrUndefined } from "utils";



const Account = () => {

    const users = useSelector(state => state.users);
    const { updateUserProfile } = useUsersActions();


    const [internalState, setState] = useState({
        isEdit: false,
        showModal: false,
        showUnsavedWarning: false
    });


    const accountInformationInitialState = {
        email: users.authUser.email,
        name: users.authUser.name
    };

    const accountInformationSubmit = () => {
        updateUserProfile(accountInformationForm.values);
    }

    const accountInformationForm = useForm(
        accountInformationSubmit,
        validateForm,
        account_information,
        accountInformationInitialState,
        internalState.isEdit
    );


    const resetPasswordInitialState = {};

    const resetPasswordSubmit = () => {
        updateUserProfile({ password: resetPasswordForm.values.newPassword });
    }

    const resetPasswordForm = useForm(
        resetPasswordSubmit,
        validateForm,
        reset_password,
        resetPasswordInitialState,
        internalState.isEdit
    );


    const userPreferences = users.authUser.prefs;
    const preferencesInitialState = {
        appVersion: userPreferences && !isNullOrUndefined(userPreferences.appVersion)
            ? userPreferences.appVersion
            : "v2",
        logoRedirectUrl: userPreferences && !isNullOrUndefined(userPreferences.logoRedirectUrl)
            ? userPreferences.logoRedirectUrl
            : "",
        showAllProperties: userPreferences && !isNullOrUndefined(userPreferences.showAllProperties)
            ? userPreferences.showAllProperties
            : false,
        hideLatestAsOfModal: userPreferences && !isNullOrUndefined(userPreferences.hideLatestAsOfModal)
            ? userPreferences.hideLatestAsOfModal
            : false,
        cacheCalendar: userPreferences && !isNullOrUndefined(userPreferences.cacheCalendar)
            ? userPreferences.cacheCalendar
            : true,
        cacheDashboardModal: userPreferences && !isNullOrUndefined(userPreferences.cacheDashboardModal)
            ? userPreferences.cacheDashboardModal
            : true,
        singleAsOfAvgsForVariances: userPreferences && !isNullOrUndefined(userPreferences.singleAsOfAvgsForVariances)
            ? userPreferences.singleAsOfAvgsForVariances
            : true,
        colorScoreEnabled: userPreferences && !isNullOrUndefined(userPreferences.colorScoreEnabled)
            ? userPreferences.colorScoreEnabled
            : false,
        forecastPropertyDisabled: userPreferences && !isNullOrUndefined(userPreferences.forecastPropertyDisabled)
            ? userPreferences.forecastPropertyDisabled
            : false,
        forecastGroupDisabled: userPreferences && !isNullOrUndefined(userPreferences.forecastGroupDisabled)
            ? userPreferences.forecastGroupDisabled
            : false,
        propertyGroupsEnabled: userPreferences && !isNullOrUndefined(userPreferences.propertyGroupsEnabled)
            ? userPreferences.propertyGroupsEnabled
            : false,
        usePercentsForOccs: userPreferences && !isNullOrUndefined(userPreferences.usePercentsForOccs)
            ? userPreferences.usePercentsForOccs
            : true,
        hideComparisons: userPreferences && !isNullOrUndefined(userPreferences.hideComparisons)
            ? userPreferences.hideComparisons
            : true,
        showDownloadables: userPreferences && !isNullOrUndefined(userPreferences.showDownloadables)
            ? userPreferences.showDownloadables
            : true,
        sendEmailAfterFileUpload: userPreferences && !isNullOrUndefined(userPreferences.sendEmailAfterFileUpload)
            ? userPreferences.sendEmailAfterFileUpload
            : false,
        enableLowestPriceCompetitor: userPreferences && !isNullOrUndefined(userPreferences.enableLowestPriceCompetitor)
            ? userPreferences.enableLowestPriceCompetitor
            : false,
    };

    const preferencesSubmit = () => {
        updateUserProfile({ prefs: preferencesForm.values });
    }

    const preferencesForm = useForm(
        preferencesSubmit,
        validateForm,
        preferences,
        preferencesInitialState,
        internalState.isEdit
    );



    let accordionItems = [
        {
            key: "accountInformation",
            label: "Account Information",
            options: {
                arrow: false
            },
            content: () => (
                <div>
                    <Form
                        fields={account_information}
                        onChange={accountInformationForm.handleChange}
                        onBlur={accountInformationForm.handleBlur}
                        formValues={accountInformationForm.values}
                        fieldErrors={accountInformationForm.errors}
                    />
                    <div className="action-wrapper">
                        <Button
                            type="primary"
                            onClick={accountInformationForm.handleSubmit}
                        >
                            {" "}
                            Save{" "}
                        </Button>
                    </div>
                </div>
            )
        },
        {
            key: "resetPassword",
            label: "Reset Password",
            options: {
                arrow: false
            },
            content: () => (
                <div>
                    <Form
                        fields={reset_password}
                        onChange={resetPasswordForm.handleChange}
                        onBlur={resetPasswordForm.handleBlur}
                        formValues={resetPasswordForm.values}
                        fieldErrors={resetPasswordForm.errors}
                    />
                    <div className="action-wrapper">
                        <Button type="primary" onClick={resetPasswordForm.handleSubmit}>
                            {" "}
                            Save{" "}
                        </Button>
                    </div>
                </div>
            )
        }
    ];

    const isPreferencesDisplayed = users.authUser.type === "ope_admin";
    if (isPreferencesDisplayed) {
        accordionItems.push({
            key: "preferences",
            label: "Preferences",
            options: {
                arrow: true
            },
            content: () => (
                <div>
                    <Form
                        fields={preferences}
                        onChange={preferencesForm.handleChange}
                        onBlur={preferencesForm.handleBlur}
                        formValues={preferencesForm.values}
                        fieldErrors={preferencesForm.errors}
                    />
                    <div className="action-wrapper">
                        <Button
                            type="primary"
                            onClick={preferencesForm.handleSubmit}
                        >
                            {" "}
                            Save{" "}
                        </Button>
                    </div>
                </div>
            )
        })
    }



    return (
        <div className="forms page">
            <Helmet><title>Rev&You | Account Details | {users.authUser.name}</title></Helmet>
            <div className="divider"></div>
            <div className="forms-container container centered">
                <div className="row">
                    <div className="forms-header">
                        <h2>Account Settings</h2>
                    </div>
                    <div className="forms-row" style={{ width: "100%" }}>
                        <Accordion
                            accordion={true}
                            items={accordionItems}
                            defaultActiveKey={isPreferencesDisplayed ? "preferences" : "accountInformation"}
                            collapse={isPreferencesDisplayed}
                            onChange={() => { return }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
