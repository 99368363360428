import React from "react";
// import moment from "moment";
import dayjs from 'dayjs';
import DatePicker from "elements/DatePicker/DatePicker";
import OutlinedIcon from "elements/Icon/OutlinedIcon";


const MonthYearNav = ({
    changeMonth,
    isLoading,
    monthValue,
    changeYear,
    yearValue,
    handleChange,
}) => {
    const { MonthPicker } = DatePicker;

    return (
        <div className="month-year-navigation-wrapper">
            <div className="date-picker">
                <span className="month-indicator">
                    <span className="calendar-navigation">
                        <span className="icon change-month-button" onClick={() => !isLoading && changeMonth(-1)}>
                            <OutlinedIcon type='left' style={{ fontSize: "20px" }} />
                        </span>
                    </span>
                    <span className="calendar-label month">
                        <DatePicker
                            picker={'month'}
                            value={monthValue}
                            onChange={(e) => handleChange(dayjs(e))}
                            format={"MMMM"}
                            allowClear={false}
                            suffixIcon={""}
                            cellRender={(date, info) => {
                                return info.type === 'year'
                                    ? date.format("YYYY")
                                    : date.format("MMMM")
                            }}
                        />
                    </span>
                    <span className="calendar-navigation">
                        <span className="icon change-month-button" onClick={() => !isLoading && changeMonth(1)}>
                            <OutlinedIcon type='right' style={{ fontSize: "20px" }} />
                        </span>
                    </span>
                </span>
            </div>

            <div>
                <span className="month-indicator">
                    <span className="calendar-navigation">
                        <span className="icon change-month-button" onClick={() => !isLoading && changeYear(-1)}>
                            <OutlinedIcon type='left' style={{ fontSize: "20px" }} />
                        </span>
                    </span>
                    <span className="calendar-label year">{yearValue}</span>
                    <span className="calendar-navigation">
                        <span className="icon change-month-button" onClick={() => !isLoading && changeYear(1)}>
                            <OutlinedIcon type='right' style={{ fontSize: "20px" }} />
                        </span>
                    </span>
                </span>
            </div>
        </div>
    );
};

export default MonthYearNav;
