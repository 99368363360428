// src/components/PrivateRoute.js

import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import { useSelector } from "react-redux";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import constants from "constants.js";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const authUser = useSelector(state => state.users.authUser);
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
        connection: constants.AUTH0_DB_CONNECTION
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props =>
    isAuthenticated === true && authUser ? (
      <Component {...props} />
    ) : (
      <LoadingSpinner size={"40px"} />
    );

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
