/**
 * @author Jarbee Bejar
 */
import React, { useEffect } from 'react';
import OutlinedIcon from 'elements/Icon/OutlinedIcon.js';
import Cell from './Cell.js';
import DataGridController from './DataGridController';
import './DataGrid.scss'

const DataGrid = ({
    columns: gridColumns, data, controller = {},
    cellConfigs = [],
    emptyRowContent,
    headerHeight = '50px',
    dividerHeight = '10px',
    footerHeight = '10px',
    defaultCellHeight = '35px',
    width = 'auto',
    height = 'auto',
    style = {}
}) => {
    const {
        internalState, setInternalState,
        loading, setLoading,
        initializing, setInitializing,
        columns, setColumns,
        cells, setCells,
        gridData, setGridData,
        loadData,
        validateCells,
        generateCells,
        saveValueToGridData
    } = controller;

    useEffect(() => {
        if (gridColumns) setColumns(gridColumns);
        if (data) loadData(data);
        setInitializing(false);
    }, []);

    useEffect(() => {
        if (data) loadData(data);
    }, [data]);

    useEffect(() => {
    }, [internalState]);

    useEffect(() => {
        const generatedCells = generateCells(cellConfigs);
        const errors = validateCells(generatedCells);
        setCells(generatedCells);
        setInternalState({ ...internalState, hasError: errors.length > 0 });
    }, [gridData]);

    const getColumnsWidth = () => {
        return columns.filter(column => !(column.hidden === true)).map(column => column.width || 'minmax(0, 1fr)');
    }

    const getGridTemplateRows = () => {
        if (loading) return `${headerHeight} ${dividerHeight} auto ${footerHeight}`;
        if (!loading) {
            // No Record found
            if (emptyRowContent === undefined && cells.length === 0) return `${headerHeight} ${dividerHeight} auto ${footerHeight}`;
            if (emptyRowContent !== undefined && cells.length === 0) return `${headerHeight} ${dividerHeight} ${footerHeight}`;
        }
        return `${headerHeight} ${dividerHeight} repeat(${cells.length}, ${defaultCellHeight}) ${footerHeight}`;
    }
    // Safari issue in inline style. Creating this seperate component will help the DataGrid update the inline style.
    const Content = ({ children }) => {
        return <div className="data-grid" style={{ gridTemplateRows: getGridTemplateRows(), gridTemplateColumns: getColumnsWidth().join(" "), ...style }}>
            {children}
        </div>
    }

    return (
        <div className="form-section" style={{ position: "relative", width: width, height: height, overflow: 'auto' }}>
            <div className="data-grid" style={{ gridTemplateRows: getGridTemplateRows(), gridTemplateColumns: getColumnsWidth().join(" "), ...style }}>
                {columns.filter(column => column.hidden !== true).map((col, colIdx) =>
                    <div key={`header_${colIdx}`} className="data-grid-header">{col.header}</div>
                )}
                <span className="data-grid-divider" style={{ gridColumn: '1/-1' }}></span>
                {loading && <div className="data-grid-loader" style={{ gridColumn: '1 / -1' }}>
                    <OutlinedIcon type='loading' style={{ fontSize: "20px", color: "#808285", margin: "auto" }} />
                </div>}
                {!loading && cells.length === 0 && emptyRowContent === undefined && <div style={{ gridColumn: '1 / -1', color: "#808285", justifyContent: 'center' }}>No record found</div>}
                {!loading && cells.length === 0 && emptyRowContent !== undefined && emptyRowContent}
                {!loading && cells.map((row, rowIdx) => {
                    console.log("cell-row: ", row);
                    return row.map((cell, colIdx) => {
                        return <Cell
                            key={`cell_${rowIdx}_${colIdx}`}
                            type={cell.type}
                            items={cell.items}
                            actionFn={cell.actionFn}
                            linkFn={cell.linkFn}
                            disabled={cell.disabled}
                            formatValue={cell.formatValue}
                            initialValue={cell.value}
                            coordinates={cell.coordinates}
                            useDisplayField={cell.useDisplayField}
                            render={cell.render}
                            error={cell.error}
                            saveValueToGridData={saveValueToGridData}
                        />
                    })
                })}
                <div className="data-grid-footer" style={{ gridColumn: '1/-1' }}></div>
            </div>
        </div>
    )
};
// Experimental
DataGrid.newController = DataGridController;

export default DataGrid;