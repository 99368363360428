import React from "react";
import { useDispatch } from "react-redux";

import ListHeader from "elements/Text/ListHeader";
import IconButton from "elements/Button/Icon";

import constants from "../../constants";
import { sortArray } from "utils/data";


/**
 * Integration partners list component.
 *
 * @param {[Object]} partners List of integration partners data object.
 * @param {Object} user Rev&You user data object.
 * @param {Function} onAdd Event action after clicking the Add Integration Partner button.
 * @param {Function} onView Event action after clicking the View Integration Partner button.
 * @param {Function} onEdit Event action after clicking the Edit Integration Partner button.
 * @param {Function} onDelete Event action after clicking the Delete Integration Partner button.
 * @return {Component} Integration partners list component.
*/
const PartnersList = ({ partners, user, onAdd, onView, onEdit, onDelete }) => {
    const dispatch = useDispatch();

    let hasPartners = partners && Array.isArray(partners) && partners.length > 0;
    let isAdmin = user.type === "ope_admin";
    let isHotelUser = user.type === "hotel_user";
    let hasUpdateAccess = isHotelUser || isAdmin;


    const updateListItemInView = (partner) => {
        dispatch({
            type: constants.UPDATE_LIST_ITEM_IN_VIEW,
            value: partner
        });
    }

    const handleEditBookingSources = (partner) => {
        updateListItemInView(partner);
        onEdit("bookingsource");
    }

    const handleEditPartner = (partner) => {
        updateListItemInView(partner);
        onEdit("partner");
    }

    const handleDeletePartner = (partner) => {
        updateListItemInView(partner);
        onDelete();
    }


    return (
        <div>
            <ListHeader headerCopy="Add Integration Partner" component={
                <IconButton type="add" hasAccess={hasUpdateAccess} action={onAdd} width="25px" />
            } />

            <table className="forms-table">
                <thead>
                    <tr style={{ marginBottom: "8px" }}>
                        <th>Channel</th>
                        <th>Room Status</th>
                        <th>Booking Sources</th>
                        <th style={{ textAlign: "right" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasPartners && sortArray(partners, "partner_code").map((partner, index) =>
                        <tr key={`${partner.partner_code}-${partner.property_code}-${index}`}>
                            <td>{partner.partner_code}</td>
                            <td>{partner.room_types_enabled ? "Active" : "Inactive"}</td>
                            <td><a style={{textDecoration: "underline", color: "unset", }} 
                                    onClick={() => handleEditBookingSources(partner)}>View</a>
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <IconButton type="view" hasAccess={isHotelUser} action={onView} />
                                <IconButton type="edit" hasAccess={isAdmin}
                                    action={() => handleEditPartner(partner)} />
                                <IconButton type="delete" hasAccess={isAdmin}
                                    action={() => handleDeletePartner(partner)} />
                            </td>
                        </tr>
                    )}

                    {!hasPartners && <tr>
                        <td>No integration partners found</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
};

export default PartnersList;
