export const pickup_fields = [
    {
        key: "pickup_avg_dow",
        label: "Average day-of-week",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 25,
    },
    {
        key: "pickup_avg",
        label: "Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_sdly",
        label: "Same day last year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_avg", "pickup_trend_avg_dow", "pickup_trend_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_trend_avg_dow",
        label: "Average day-of-week trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_avg", "pickup_sdly", "pickup_trend_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_trend_avg",
        label: "Average trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_trend_sdly",
        label: "Same day last year trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_avg"],
        type: "number",
        initialValue: 15
    },
];

export const pace_fields = [
    {
        key: "pace_avg_dow",
        label: "Average day-of-week",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 25,
    },
    {
        key: "pace_avg",
        label: "Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_sdly", "pace_trend_avg_dow", "pace_trend_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_sdly",
        label: "Same day last year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_avg", "pace_trend_avg_dow", "pace_trend_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_trend_avg_dow",
        label: "Average day-of-week trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_avg", "pace_sdly", "pace_trend_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_trend_avg",
        label: "Average trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_trend_sdly",
        label: "Same day last year trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_avg"],
        type: "number",
        initialValue: 15
    },
];

export const forecast_fields = [
    {
        key: "forecast_pickup",
        label: "Pickup",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["forecast_pace"],
        type: "number",
        initialValue: 50,
    },
    {
        key: "forecast_pace",
        label: "Pace",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["forecast_pickup"],
        type: "number",
        initialValue: 50,
    }
];

export const price_fields = [
    {
        key: "price_avg_dow",
        label: "Average day-of-week",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_avg", "price_remaindays_sdly"],
        type: "number",
        initialValue: 15,
    },
    {
        key: "price_avg",
        label: "Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_avg", "price_remaindays_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "price_sdly",
        label: "Same day last year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_avg", "price_remaindays_avg_dow", "price_remaindays_avg", "price_remaindays_sdly"],
        type: "number",
        initialValue: 20
    },
    {
        key: "price_remaindays_avg_dow",
        label: "Remaining days AVG DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_avg", "price_sdly", "price_remaindays_avg", "price_remaindays_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "price_remaindays_avg",
        label: "Remaining days AVG",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "price_remaindays_sdly",
        label: "Remaining days SDLY",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_avg"],
        type: "number",
        initialValue: 20
    }
];

export const finals_fields = [
    {
        key: "finals_sdly",
        label: "Same day last year occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_avg_dow", "finals_avg"],
        type: "number",
        initialValue: 70,
    },
    {
        key: "finals_avg_dow",
        label: "Average day-of-week occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_sdly", "finals_avg"],
        type: "number",
        initialValue: 20,
    },
    {
        key: "finals_avg",
        label: "Average occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_sdly", "finals_avg_dow"],
        type: "number",
        initialValue: 10,
    }
]