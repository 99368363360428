import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Settings, Map, Reset, AddFilled } from "Icons";
import SearchInput from "elements/Input/Search";
import ResetPwdModal from "../../components/Modal/ResetPwdModal";
import Table from "../../components/Table/";
import PropertyMappingModal from "./PropertyMappingModal";

import useUsers from "../../store/actions/usersActions";
import { composeSortParams } from "../../utils";
import { withAuthorization } from "../../session";



const Users = ({ history }) => {
    const state = useSelector((state) => state.users);
    const { getUsers, sendResetPasswordEmail } = useUsers();

    const [mapPropertiesModal, setMapPropertiesModal] = useState({
        user: {
            name: "",
            properties: [],
        },
        visible: false,
    });
    const [confirmModal, setConfirmModal] = useState({
        user: null,
        visible: false,
    });

    const [sorterState, setSorterState] = useState({
        field: "name",
        value: "descend",
    });


    const handleSearch = (value) => {
        const sort = composeSortParams(sorterState.value, sorterState.field);
        getUsers(value, sort);
    };

    const handleMapPropertiesModal = (user) => {
        setMapPropertiesModal({
            user,
            visible: true,
        });
    };

    const handleResetUserPassword = () => {
        const email = confirmModal.user.email;
        sendResetPasswordEmail(email).then((res) => {
            setConfirmModal({ user: null, visible: false });
        });
    };

    const heads = [
        {
            label: "NAME OF USER",
            key: "name",
            sort: { default: "ascend" },
            width: "270px",
        },
        {
            label: "USER LEVEL",
            key: "type",
            sort: { default: "ascend" },
            conditions: {
                hotel_admin: "Property Admin",
                ope_admin: "Rev&You Admin",
                hotel_user: "Property User",
                internal_user: "Internal User",
            },
        },
        {
            label: "LAST LOGIN",
            key: "last_login",
            sort: { default: "ascend" },
            date: true,
        },
        {
            label: "OTHER ACTIONS",
            key: "action",
            actions: [
                {
                    element: (
                        <Settings
                            width="20px"
                            height="20px"
                            className="pointer rms-table-icon"
                        />
                    ),
                    function: (item) => history.push(`/users/${item._id}/edit`),
                },
                {
                    element: (
                        <Map
                            width="20px"
                            height="20px"
                            className="pointer rms-table-icon"
                        />
                    ),
                    function: (item) => handleMapPropertiesModal(item),
                },
                {
                    element: (
                        <Reset
                            width="20px"
                            height="20px"
                            className="pointer rms-table-icon"
                        />
                    ),
                    function: (item) => setConfirmModal({ user: item, visible: true }),
                },
            ],
        },
    ];


    const handleFilter = async (page, sorter) => {
        const sort = composeSortParams(sorter[sorter.clicked], sorter.clicked);
        await getUsers(state.currentSearchTerm, sort, page);
    };

    return (
        <div className="users page">
            <Helmet>
                <title>Rev&You | Users List</title>
            </Helmet>
            <div className="divider"></div>
            <div className="container centered">
                <div className="body row">
                    <div className="header">
                        <div className="title">
                            <h2>User Management</h2>

                            {state.permissions.canCreateUsers && (
                                <Link to="/users/add">
                                    <AddFilled width="32px" height="32px" className="pointer" />
                                </Link>
                            )}
                        </div>

                        <div className="search-field">
                            <SearchInput
                                handleSearch={handleSearch}
                                isLoading={state.loading}
                                placeholder="Search for a user"
                            />
                        </div>
                    </div>

                    <div className="page-navigation" />

                    <div className="table-wrapper">
                        <Table
                            data={state.users}
                            heads={heads}
                            layout="fixed"
                            dataId="_id"
                            pagination={{
                                length: state.users.length,
                                totalCount: state.totalCount,
                            }}
                            isLoading={state.loading}
                            filterFunction={handleFilter}
                            initialSortKey="last_login"
                        />
                    </div>
                </div>
            </div>

            <PropertyMappingModal
                user={mapPropertiesModal.user}
                visible={mapPropertiesModal.visible}
                loading={state.loading}
                handleModal={setMapPropertiesModal}
            />

            <ResetPwdModal
                visible={confirmModal.visible}
                onCancel={() => setConfirmModal({ user: null, visible: false })}
                onConfirm={handleResetUserPassword}
                content={`Send ${confirmModal.user ? confirmModal.user.name : ""
                    } a reset password link?`}
            />
        </div>
    );
};

export default withAuthorization("canManageUsers")(Users);
