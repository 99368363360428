import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastsStore } from "react-toasts";
import { Modal } from "antd";

import { AddFilled, Exit, Edit, Settings } from "Icons";
import Button from "../Button/Button";
import BasicSelect from "elements/Select/Basic";
import Form from "./Form";
import UserMapping from "../UserMapping/UserMapping";
import UnsavedChangesModal from "./UnsavedChangesModal";
import AddBrandModal from "components/Properties/AddBrandModal";
import ErrorPage from "../ErrorPage/ErrorPage";
import WithDataRequiredContainer from "../WithDataRequiredContainer/WithDataRequiredContainer";
import SegmentModal from "../Modal/SegmentModal";
import RoomTypeMappingModal, { RoomTypeOperation } from "../RoomTypeMappingModal";
import RmsTabs from "../Tabs/Tabs";
import CustomTabs from "../CustomTabs/CustomTabs";
import VariableSettings from "../ForecastSettings/VariableSettings/VariableSettings";
import ForecastWeights from "../ForecastSettings/ForecastWeights";
import ComparisonsList from 'components/Lists/ComparisonsList';
import PartnersList from "../Lists/PartnersList";
import PartnersModal from "../Modal/PartnersModal";
import BookingSourceModal from "../Modal/BookingSourcesModal";
import SegmentMappingsList from "../Lists/SegmentMappingsList";
import SegmentMappingsModal from "../Modal/SegmentMappingsModal";
import RoomInventoriesList from "../Lists/RoomInventoriesList";
import RoomTypesList from "../Lists/RoomTypesList";
import RoomTypesModal from "../Modal/RoomTypesModal";
import RoomMappingsList from "../Lists/RoomMappingsList";
import RoomMappingsModal from "../Modal/RoomMappingsModal";
import CompetitorsList from "../Lists/CompetitorsList";
import CustomDashboardViewList from "components/Lists/CustomDashboardViewList";
import CompetitorsModal from "../Modal/CompetitorsModal";
import ConfirmationModal from "../ConfirmationModal";
import TabButton from "elements/Button/Tab";
import Tools from "../Tools/Tools";

import useForm from "./useForm";
import validateForm from "./validateForm";
import useNav from "components/Nav/useNav";
import usePropertiesActions from "../../store/actions/propertiesActions";
import usePropertyActions from '../../store/actions/propertiesActions';
import useAdminConsoleActions from "../../store/actions/adminConsoleActions";
import useHelperFunctions from "../../store/actions/actions";
import { isNullOrUndefined } from "../../utils/index";
import { defaultUnsegmentedSegment, defaultUnsegmentedSegmentGroup } from "../../utils/segments";
import { getTimezones } from "../../utils/timezones";
import { sortArray, isNullish } from "../../utils/data";
import constants from "../../constants";
// import moment from "moment";
import dayjs from "dayjs";

import {
    property_information,
    pickup_treshold,
    pickup_rates,
    pace_treshold,
    pace_rates,
    sell_rate_rates,
    forecast_rates,
    sell_rate_treshold,
    admin_controls
} from "./PropertiesFields";
import RoomInventoriesModal from "components/Modal/RoomInventoriesModal";
import Select from "elements/Select";

const PropertiesForms = ({ history, match }) => {
    const {
        addProperty,
        getProperty,
        updateProperty,
        updatePropertyStatus,
        getHotelCurrencies,
        getHotelBrands,
        getHotelCountries,
        getHotelProvinces,
        getHotelCities,
        clearProperty,
        getParsers,
        getIntegrationPartners,
        retrievePartnerMappings,
        retrieveRoomMappings,
        retrieveSegmentMappings,
        getInternalRoomTypes,
        updateBarRoomType,
        addCompetitor,
        updateCompetitor,
        deleteCompetitor
    } = usePropertiesActions();
    const { getAllPropertiesAndGroups } = usePropertyActions();
    const { getBookingSources, getCompetitors } = useAdminConsoleActions();
    const { setShowUploadModal } = useHelperFunctions();
    const dispatch = useDispatch();
    const { changeActiveHotel } = useNav();

    const state = useSelector((state) => state);
    const [internalState, setState] = useState({
        isEdit: false,
        showModal: false,
        showUnsavedWarning: false,
        showStatusChangeWarning: false,
        propertyStatus: true,
        showBrandModal: false,
        loading: true
    });
    const [segments, setSegments] = useState({
        direct: { value: false, label: "Direct" },
        ota: { value: false, label: "Online travel agencies" },
        groups: { value: false, label: "Groups" },
        walkin: { value: false, label: "Walk in" },
    });
    let allTimezones = getTimezones().map((item) => {
        item._id = item.tz;
        item.name = `${item.tz} (${item.offset})`;
        return item;
    })
    const partnersMappingEnabled = true;
    const roomsMappingEnabled = true;


    // States for data.
    const [propertyInView, setPropertyInView] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [brands, setBrands] = useState([]);
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [timezones, setTimezones] = useState(allTimezones);
    const [hotelname, setHotelname] = useState('');
    const [parser, setParser] = useState(null);
    const [parsers, setParsers] = useState([]);
    const [integrationPartners, setIntegrationPartners] = useState([]);
    const [partnersView, setPartnersView] = useState([]);
    const [partnersList, setPartnersList] = useState([]);
    const [bookingSourcesList, setBookingSourcesList] = useState([]);
    const [segmentMappingView, setSegmentMappingView] = useState("segments");
    const [segmentsList, setSegmentsList] = useState([])
    const [segmentTypesView, setSegmentTypesView] = useState(["list"]);
    const [segmentMapsView, setSegmentMapsView] = useState(["list"]);
    const [roomMappingView, setRoomMappingView] = useState("room-types");
    const [roomTypesList, setRoomTypesList] = useState([])
    const [roomTypesView, setRoomTypesView] = useState(["list"]);
    const [roomMapsView, setRoomMapsView] = useState(["list"]);
    const [roomInventoriesView, setRoomInventoriesView] = useState(["list"]);
    const [selectedRoomInventory, setSelectedRoomInventory] = useState(null);
    const [competitorMapsView, setCompetitorMapsView] = useState(["list"]);
    const [internalRoomTypes, setInternalRoomTypes] = useState([]);
    const [barRoomTypesList, setBarRoomTypesList] = useState([])
    const [barRoomType, setBarRoomType] = useState('ALL');
    const [comparisons, setComparisons] = useState([]);
    const [comparisonsEnabled, setComparisonsEnabled] = useState(false);
    const [competitorsList, setCompetitorsList] = useState([]);
    const [customDashboardDefaultView, setCustomDashboardDefaultView] = useState({});
    const [customDashboardCustomDates, setCustomDashboardCustomDates] = useState([]);
    const [selectedCompetitor, setSelectedCompetitor] = useState(null);
    const [adminCompetitorsList, setAdminCompetitorsList] = useState([]);
    const [roomInventories, setRoomInventories] = useState([]);
    // States for UI
    const [segmentModal, setSegmentModal] = useState(false);
    const [segmentModalState, setSegmentModalState] = useState('add');
    const [segmentInput, setSegmentInput] = useState({});
    const [segmentDeleteModal, setSegmentDeleteModal] = useState(false);
    const [roomTypeModalState, setRoomTypeModalState] = useState({
        show: false,
        operation: 'add',
        values: {}
    });
    const [propertyStatusModalState, setPropertyStatusModalState] = useState({ show: false });
    const [showDeleteComparisonModal, setShowDeleteComparisonModal] = useState(false);
    const [deleteComparisonId, setDeleteComparisonId] = useState(null);

    const users = state.users;
    const userPrefs = users.authUser.prefs;
    const hideComparisons = !isNullish(userPrefs) && (userPrefs.hideComparisons ?? false);

    
    const combinedFields = [
        ...property_information,
        ...pickup_rates,
        ...pace_rates,
        ...sell_rate_rates,
        ...forecast_rates,
        pickup_treshold,
        pace_treshold,
        sell_rate_treshold,
        ...admin_controls
    ];

    let initialState = {
        users: [],
    };

    const dropDownItems = {
        brand: brands,
        country: countries,
        province: provinces,
        city: cities,
        timezone: timezones,
        currency: currencies,
        parser: parsers
    }

    combinedFields.forEach((field) => {
        initialState[field.key] = field.initialValue === 0 ? 0 : field.initialValue;
        if (field.key in dropDownItems) field.dropDownList = dropDownItems[field.key];
    });
    
    const competitorsToTable = (property) => {
        return (property.competitors?? []).map(competitor => {
            const gcompetitor = property.competitors_info.find(gc => gc._id == competitor.competitor_id)
            return { ...competitor, name: gcompetitor?.name?? "N/A" }
        })
    }
    
    const barRoomTypeListToTabel = (property) => {
        return [
            { name: "Lowest Rate from All Room Types  ", value: 'ALL' },
            ...sortArray(property.room_types.map((rt) => {
                return {
                    name: `${rt.room_type} (${rt.room_type_code})  `,
                    value: rt.room_type_code
                }
            }), "name")
        ]
    }

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        editedValues,
        errors,
        prePopulateForm,
    } = useForm(
        submit,
        validateForm,
        combinedFields,
        initialState,
        internalState.isEdit
    );

    useEffect(() => {
        getParsers().then(res => {
            setParsers(res.data.data)
        });

        getIntegrationPartners().then(res => {
            if (!isNullOrUndefined(res.data) && res.data.data.length > 0) setIntegrationPartners(res.data.data);
        });

        getBookingSources().then((res) => {
            if (Array.isArray(res.data?.data)) setBookingSourcesList(res.data.data);
        });

        getCompetitors().then((res) => {
            if (Array.isArray(res.data?.data)) setAdminCompetitorsList(res.data.data);
        });

        getHotelCurrencies().then((res) => {
            if (res.type === "GET_HOTEL_CURRENCIES_SUCCESS") {
                let data = res.response.data.data;
                // Filter-out GR Euro because UI displays two Euro currencies (one for EU and one for GR)
                setCurrencies(sortArray(
                    data.filter(_ => !(_.currency === "Euro" && _.country_code === "GR")), "currency"
                ));
            }
        });

        getHotelBrands().then((res) => {
            if (res.type === "GET_HOTEL_BRANDS_SUCCESS") {
                let data = res.response.data.data;
                setBrands(sortArray(data, "brand_name"));
            }
        });

        getHotelCountries().then((res) => {
            if (res.type === "GET_HOTEL_COUNTRIES_SUCCESS") {
                let data = res.response.data.data;
                let countries = sortArray(data, "country").map((item) => {
                    item.name = item.country;
                    return item;
                })
                setCountries(countries);
            }
        });

        getInternalRoomTypes().then(res => {
            setInternalRoomTypes(res.data.data);
        });

        if (match && match.params.id) {
            getProperty(match.params.id).then((res) => {
                if (res.type === constants.GET_PROPERTY_SUCCESS) {
                    const fetchedHotel = res.response.data.data;
                    const propertyStatus = fetchedHotel.status === "active";

                    setPropertyInView(fetchedHotel);
                    setParser(fetchedHotel.parser);
                    setPartnersList(fetchedHotel.mappings);
                    setComparisonsEnabled(fetchedHotel?.comparisons_enabled ?? false);
                    setComparisons(fetchedHotel?.comparisons ?? []);
                    setRoomTypesList(fetchedHotel.room_types);
                    setSegmentsList(fetchedHotel.segments);
                    setBarRoomTypesList(barRoomTypeListToTabel(fetchedHotel));
                    setBarRoomType(fetchedHotel.room_types_bar);
                    setCompetitorsList(competitorsToTable(fetchedHotel));
                    setRoomInventories(fetchedHotel.room_inventories);
                    setCustomDashboardDefaultView(fetchedHotel.custom_dashboard.default_view);
                    setCustomDashboardCustomDates(fetchedHotel.custom_dashboard.custom_dates);
                    prePopulateForm(fetchedHotel);
                    changeActiveHotel(fetchedHotel);
                    setHotelname(fetchedHotel?.name);
                    admin_controls[0].disabled = false;

                    let countryCode = fetchedHotel.country;
                    getHotelProvinces().then((res) => {
                        if (res.type === "GET_HOTEL_PROVINCES_SUCCESS") {
                            let provinces = res.response.data.data;
                            let provincesByCountry = provinces.filter(p => p.country_code === countryCode);

                            if (provincesByCountry.length > 0) {
                                setProvinces(provincesByCountry);
                            } else {
                                setProvinces([]);
                                setCities([]);
                            }
                        }
                    });
                    // Update state of property data to update the UI of Nav.
                    dispatch({
                        type: constants.UPDATE_PROPERTY_DATA,
                        value: fetchedHotel
                    });
                    setState({ ...internalState, propertyStatus, isEdit: true, loading: false });
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const tab = localStorage.getItem('managePropertyTab');

        if (tab) setActiveTab(tab);
        if (history && history.location.pathname.includes("add")) {
            setState({ ...internalState, loading: false });
            setActiveTab('propertyInfo');
            clearProperty();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAllPropertiesAndGroups(propertyInView?._id).then((res) => {
            if (res && res.type === "GET_PROPERTIES_AND_GROUPS_SUCCESS") {
                const data = res.response.data.data;
                dispatch({
                    type: constants.UPDATE_COMPARISONS_OPTIONS,
                    value: data
                })
            }
        });
    }, [propertyInView])

    let prevProvince = usePrevious(values.province);

    useEffect(() => {
        let provinceId = values.province;
        if ((provinceId !== "") && (provinceId !== undefined) && (provinceId !== null)) {
            getHotelCities(provinceId).then((res) => {
                let resData = res.response.data.data;
                if ((res.type === "GET_HOTEL_PROVINCE_SUCCESS") && (resData !== null)) {
                    setCities(resData["cities"].map((city, index) => ({ _id: `item_${index}`, name: city })));
                    if (editedValues.province && values.province !== prevProvince) {
                        values.city = "";
                        prePopulateForm(values);
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.province])


    useEffect(() => {
        if ((editedValues.currency !== null) && (editedValues.currency !== undefined)) {
            let currency = currencies.filter((item) => {
                if ((values.currency !== null) && (values.currency !== undefined)) {
                    return item._id === values.currency;
                }
                return null;
            })

            if (currency !== null && currency[0] !== null && currency[0] !== undefined) {
                values.currency = {
                    code: currency[0].currency_code,
                    symbol: currency[0].symbol,
                    html: currency[0].html
                };
                prePopulateForm(values);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedValues.currency])

    useEffect(() => {
        if (values.brand === "Add") {
            setState({ ...internalState, showBrandModal: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.brand]);

    const prevCurrencies = usePrevious(currencies);
    useEffect(() => {
        if (prevCurrencies?.length > 0) {
            if (prevCurrencies.length < currencies.length) {
                handleChange("currency", currencies[currencies.length - 1]._id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currencies]);

    const prevBrands = usePrevious(brands);
    useEffect(() => {
        if (prevBrands?.length > 0) {
            if (prevBrands.length < brands.length) {
                handleChange("brand", brands[brands.length - 1]._id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brands]);

    const prevCountries = usePrevious(countries);
    useEffect(() => {
        if (prevCountries?.length > 0) {
            if (prevCountries.length < countries.length) {
                handleChange("country", countries[countries.length - 1]._id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    const prevProvinces = usePrevious(provinces);
    useEffect(() => {
        if (prevProvinces && prevProvinces !== provinces) {
            if (values.province !== undefined && editedValues.country !== undefined) {
                handleChange("province", provinces[provinces.length - 1]._id);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provinces]);

    const prevTimezones = usePrevious(timezones);
    useEffect(() => {
        if (prevTimezones && prevTimezones !== timezones && values.country !== "") {
            let timezone = (timezones.length === 1) ? timezones[0]._id : "";
            handleChange("timezone", timezone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timezones]);


    let prevCountry = usePrevious(values.country);

    useEffect(() => {
        let countryTimezones = allTimezones.filter(item => item.country_code === values.country);
        if (countryTimezones.length === 0) {
            let country = countries.filter(item => item._id === values.country)[0];
            let countryCode = isNullOrUndefined(country) ? "" : country.country_code_2;
            countryTimezones = allTimezones.filter(item => item.country_code === countryCode);
        }
        setTimezones(countryTimezones);

        let selectedCountry = (editedValues.country === undefined) ? values.country : editedValues.country;
        if ((selectedCountry !== prevCountry)) {
            let country = (selectedCountry.length === 2)
                ? countries.filter(item => item.country_code_2 === selectedCountry)[0]
                : countries.filter(item => item._id === selectedCountry)[0];

            if (country !== undefined) {
                let countryCode = country.country_code_2;
                getHotelProvinces().then((res) => {
                    if (res.type === "GET_HOTEL_PROVINCES_SUCCESS") {
                        let provinces = res.response.data.data;
                        let provincesByCountry = provinces.filter(p => p.country_code === countryCode);

                        if (provincesByCountry.length > 0) {
                            setProvinces(provincesByCountry);
                        } else {
                            setProvinces([]);
                            setCities([]);
                        }
                    }
                });
            }

            if (editedValues.country !== undefined) {
                values.province = undefined;
                values.city = undefined;
            }
            prePopulateForm(values);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.country]);

    const setPartnersListViewStatus = (parser) => {
        let selectedParser = parsers.filter(item => item._id === parser)[0];
        let isIntegrationPartner = selectedParser.code === "integrationpartner";
        setPartnersView(isIntegrationPartner ? ["list"] : []);
    }

    useEffect(() => {
        if (!isNullOrUndefined(parser) && !isNullOrUndefined(parsers) && parsers.length > 0) {
            setPartnersListViewStatus(parser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parser, parsers])

    useEffect(() => {
        if (!isNullOrUndefined(editedValues.parser) && !isNullOrUndefined(parsers) && parsers.length > 0) {
            setPartnersListViewStatus(editedValues.parser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedValues.parser])

    useEffect(() => {
        if (internalState.loading === true) return;
        if (state.properties.property && (state.properties.property.status === "active") !== editedValues.status)
            setPropertyStatusModalState({ show: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedValues.status]);

    useEffect(() => {
        if (!internalState.isEdit || internalState.loading === true) return;
        if (state.properties.property &&
            !isNullOrUndefined(editedValues.segment_status) &&
            (state.properties.property.segment_status) !== editedValues.segment_status) {
            updatePropertyStatus(match.params.id, {
                segment_status: editedValues.segment_status === true,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedValues.segment_status]);

    useEffect(() => {
        setComparisons(state.mainReducer.comparisons);
    }, [state.mainReducer.comparisons])

    useEffect(() => {
        setComparisonsEnabled(state.mainReducer.comparisons_enabled);
    }, [state.mainReducer.comparisons_enabled])

    useEffect(() => {
        if (!internalState.isEdit) {
            dispatch({ type: constants.UPDATE_COMPARISONS_ENABLED, value: false });
            dispatch({ type: constants.UPDATE_COMPARISONS_LATEST, value: [] });
        }
    }, [internalState.isEdit])

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    function submit() {
        let data;
        const getCurrencyDetailsForProperty = (selectedCurrency) => {
            let currency = currencies.filter((item) => { return item._id === selectedCurrency; })[0];
            return {
                code: currency.currency_code,
                symbol: currency.symbol,
                html: currency.html
            };
        }

        if (internalState.isEdit) {
            if ("status" in editedValues) {
                delete editedValues.status;
            }
            if ("segment_status" in editedValues) {
                delete editedValues.segment_status;
            }
            if ("currency" in editedValues) {
                editedValues.currency = getCurrencyDetailsForProperty(editedValues.currency);
            }
            if ("country" in editedValues) {
                let country = (editedValues.country.length === 2)
                    ? countries.filter(item => item.country_code_2 === editedValues.country)[0]
                    : countries.filter(item => item._id === editedValues.country)[0];
                editedValues.country = country.country_code_2;
            }
            if ("city" in editedValues) {
                let index = Number(editedValues.city.replace(/\D/g, ''));
                editedValues.city = cities[index];
            }
            if ("reservation_cut_off_time" in editedValues) {
                editedValues.reservation_cut_off_time = dayjs(editedValues.reservation_cut_off_time).format("HH:mm:ss");
            }

            editedValues.room_inventories = roomInventories.map(item => {
                if (/^NEW\[\d*\]/.test(item._id)) {
                    delete item._id;
                }
                return item;
            });

            if (!hideComparisons) {
                editedValues.comparisons_enabled = comparisonsEnabled;
                editedValues.comparisons = comparisons.map((comparison) => {
                    return isNullish(comparison.isGroup)
                        ? {
                            id: comparison.id,
                            isGroup: !("property_code" in comparison),
                            property: comparison.id,
                            properties: comparison.properties ?? [comparison.id],
                            name: comparison.name,
                            code: comparison.property_code ?? comparison.code,
                        }
                        : isNullish(comparison.id)
                            ? { ...comparison, id: comparison.property }
                            : comparison
                })
            }

            updateProperty(match.params.id, editedValues).then(result => {
                const property = result.response.data.data;
                dispatch({
                    type: constants.UPDATE_ACTIVE_HOTEL,
                    value: property
                });
                // Update state of property data to update the UI of Nav.
                dispatch({
                    type: constants.UPDATE_PROPERTY_DATA,
                    value: property
                });

                setRoomInventories(property?.room_inventories);

                return property;
            });
        } else {
            let propertySegments = Object.keys(segments).filter(segment => segments[segment].value);

            data = {
                ...values,
                currency: getCurrencyDetailsForProperty(values.currency),
                segments: [...propertySegments, defaultUnsegmentedSegment],
                segment_groups: [defaultUnsegmentedSegmentGroup],
                room_inventories: roomInventories.map(item => { 
                    delete item._id;
                    return item;
                }),
                users: values.users
            };

            data.reservation_cut_off_time = dayjs(values.reservation_cut_off_time).format("HH:mm:ss");
            addProperty(data);
        }
        dispatch({
            type: constants.UPDATE_ACTIVE_HOTEL_CURRENCY,
            value: values.currency
        });
    }

    const transformPropertyRoomTypes = (integrationPartnerCode, propertyRoomTypes) => {
        let newPropertyRoomTypes = [];
        if (!Array.isArray(propertyRoomTypes) || !integrationPartnerCode) return [];
        propertyRoomTypes.forEach(propertyRoomType => {
            let internalRoomCode = null;
            propertyRoomType.mappings.forEach(mappedRoomType => {
                if (!internalRoomCode && mappedRoomType.partner_code === "REVANDYOU")
                    internalRoomCode = mappedRoomType.room_type_code;
                if (mappedRoomType.partner_code === integrationPartnerCode) {
                    newPropertyRoomTypes.push({
                        internal_room_code: internalRoomCode,
                        property_room_code: propertyRoomType.room_type_code,
                        property_room_name: propertyRoomType.room_type,
                        external_room_code: mappedRoomType.room_type_code,
                        external_room_name: mappedRoomType.room_type_name,
                        partner_code: mappedRoomType.partner_code,
                        total_inventory: propertyRoomType.total_inventory
                    });
                }

            });
        });

        return newPropertyRoomTypes.sort((a, b) => {
            if (a.property_room_code > b.property_room_code) return 1;
            if (a.property_room_code < b.property_room_code) return -1;
            if (a.external_room_code > b.external_room_code) return 1;
            if (a.external_room_code < b.external_room_code) return -1;
            if (a.internal_room_code > b.internal_room_code) return 1;
            if (a.internal_room_code < b.internal_room_code) return -1;
        });
    }

    const handleCancel = () => {
        const withEditedValues = Object.keys(editedValues).length > 0;
        if (internalState.isEdit && withEditedValues) {
            setState({ ...internalState, showUnsavedWarning: true });
        } else {
            history.goBack();
        }
    };

    const SegmentButton = () => {
        if (state.users.authUser.type === 'hotel_user') return <div></div>;

        return (
            <AddFilled
                onClick={() => { setSegmentModal(true); setSegmentModalState('add'); setSegmentInput({}) }}
                width="25px"
                height="25px"
                className="pointer"
            />
        );
    };

    const RoomTypeAddButton = () => {
        return (
            <AddFilled
                onClick={() => {
                    setRoomTypeModalState({
                        show: true, operation: RoomTypeOperation.ADD, data: {
                            partner_code: state.properties.property.mappings[0].partner_code,
                            property_id: match.params.id
                        }
                    })
                }}
                width="25px"
                height="25px"
                className="pointer"
            />
        )
    };

    const customHeader = (title, Component, spaceBetween) => () => {
        return (
            <div
                className="custom-header"
                style={{
                    display: "flex",
                    justifyContent: `${spaceBetween ? "space-between" : ""}`,
                }}
            >
                <h3>{title}</h3>

                {(Component !== null) ? <Component /> : null}
            </div>
        );
    };

    // TABS
    let viewTab = [{
        key: "propertyInfo",
        label: "Property Information",
        visible: true
    }, {
        key: "adminControls",
        label: "Admin Controls", 
        visible: state.users.authUser?.type === 'ope_admin'
    }, {
        key: "forecastSettings",
        label: "Forecast Settings",
        visible: state.users.authUser?.type === 'ope_admin'
    }, {
        key: "segments",
        label: "Segments",
        visible: state.properties.property?.segment_status === true
    }, { 
        key: "roomMapping", 
        label: "Room Mapping",
        visible: state.users.authUser?.type === 'ope_admin'
            // && state.mainReducer.property?.mappings?.some(_ => _.room_types_enabled === true)
    }, {
        key: "competitorMapping", 
        label: "Comp Mapping",
        visible: state.users.authUser?.type === 'ope_admin'
    }, {
        key: "customDashboard", 
        label: "Dashboard", 
        visible: state.users.authUser?.type === 'ope_admin'
    }];

    const [activeTab, setActiveTab] = useState('propertyInfo');

    const handleChangeTabs = (tab) => {
        if (!internalState.isEdit && tab !== "propertyInfo") return;

        localStorage.setItem(
            "managePropertyTab", 
            tab
        );
        setActiveTab(tab);
    }
    //END OF TABS

    const getPageTitle = (history) => {
        let title = "Rev & You";
        if (!isNullOrUndefined(history)) {
            let isInAddPropertyForm = history.location.pathname.includes("add");
            let suffix = isInAddPropertyForm ? " | Add Property" : ` | Edit Property | ${hotelname}`;
            title += suffix;
        }
        return title;
    }

    const getPropertySegmentGroupsForSegment = (segment) => {
        return state.properties.property.segment_groups.find((item) => {
            if (!isNullOrUndefined(item) && !isNullOrUndefined(item.gc)) {
                return item.gc === segment.local.gc;
            }
        });
    }

    let doneFetching = !(internalState.loading || state.loading || state.loadingProperty);

    return (
        <div className="forms page manage-property">
            <Helmet>{getPageTitle(history)}</Helmet>

            <div>
                <div className="container centered bg-white property-info-header" style={{ padding: '16px 80px 0' }}>
                    <h2>Manage Property</h2>
                    <RmsTabs
                        tabs={viewTab}
                        activeTab={activeTab}
                        selectTab={handleChangeTabs}
                        callback={(tab) => { return; }}
                        inputClassName={"property-info-tabs"}
                    />
                </div>
            </div>

            <WithDataRequiredContainer
                doneFetching={doneFetching}
                height={"300px"}
                style={{ paddingTop: '24px' }}
            >
                {state.properties.error ? (
                    <ErrorPage errorMessage={state.properties.error} />
                ) : (
                    <div className="container centered">


                        {/* Property Information Tab */}
                        {activeTab === 'propertyInfo' && (<div className="row">
                            <div className="column">
                                <Form
                                    fields={property_information}
                                    title={"Property Information"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    formValues={values}
                                    fieldErrors={errors}
                                />
                            </div>
                            <div className="column">
                                <UserMapping
                                    state={state.users}
                                    values={values}
                                    handleChange={handleChange}
                                    show={activeTab === 'propertyInfo'}
                                />
                                <div className="forms-section">
                                    <RoomInventoriesList 
                                        roomInventories={roomInventories}
                                        user={state.users.authUser}
                                        onAdd={() => setRoomInventoriesView(["list", "add"])}
                                        onEdit={(item) => {
                                            setSelectedRoomInventory(item);
                                            setRoomInventoriesView(["list", "edit"])
                                        }}
                                        onDelete={(item) => {
                                            setSelectedRoomInventory(item);
                                            setRoomInventoriesView(["list", "delete"])
                                        }}
                                    />
                                </div>
                            </div>
                        </div>)}


                        {/* Admin Controls Tab */}
                        {activeTab === 'adminControls' && (<div className="row">
                            <div className="column" style={{ display: 'flex', maxWidth: "40%" }}>
                                <div className="forms-section" style={{ marginBottom: -20 }}>
                                    <h3>Default Controls</h3>
                                </div>
                                <Form
                                    fields={admin_controls.filter(control => {
                                        if (state.users.authUser.type !== 'ope_admin' && control.key === 'reservations_translation_enabled') 
                                            return false;
                                        return control.group === 'main'
                                    })}
                                    title={"Admin Controls"}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    formValues={values}
                                    fieldErrors={errors}
                                />
                            </div>
                            <div className="column">
                                <div className="forms-section">

                                    {/* Comparisons */}
                                    {!hideComparisons && <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        <div style={{ width: "640px" }}>
                                            <ComparisonsList
                                                id={propertyInView._id}
                                                title="Comparisons"
                                                active={comparisonsEnabled}
                                                selected={comparisons}
                                                className=""
                                                hasTitleMargins={true}
                                                action={(event) => {
                                                    if (event.action == "delete") {
                                                        setShowDeleteComparisonModal(true);
                                                        setDeleteComparisonId(event.id);
                                                        return;
                                                    }

                                                    setComparisons(state.mainReducer.comparisons);
                                                }}
                                            />
                                        </div>
                                    </div>}


                                    {/* Data Settings */}
                                    <div>
                                        <h3 style={{ marginTop: hideComparisons ? null : "50px", marginBottom: -8 }}>
                                            Data Settings
                                        </h3>
                                        <Form
                                            fields={admin_controls.filter(control => control.group === 'integration_settings')}
                                            title={"Admin Controls"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            formValues={values}
                                            fieldErrors={errors}
                                        />
                                    </div>

                                    {/* Add Integration Partner */}
                                    {partnersMappingEnabled && partnersView.includes("list") &&
                                        <div style={{ marginTop: 20 }}>
                                            <PartnersList partners={partnersList}
                                                user={state.users.authUser}
                                                onAdd={() => setPartnersView(["list", "add"])}
                                                onView={() => setPartnersView(["list", "view"])}
                                                onEdit={(modal) => setPartnersView(["list", modal == "partner" ? "edit" : "editbooksrc"])}
                                                onDelete={() => setPartnersView(["list", "delete"])} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>)}


                        {/* Forecast Settings Tab */}
                        {activeTab === 'forecastSettings' && state.users.permissions.canEditPerformanceWeights && (
                            <CustomTabs name="forecastSettingsTab" tabs={[
                                { key: 'variable_settings', label: 'Variable Settings' },
                                { key: 'forecast_settings_new', label: 'Forecast Weights' },
                                { key: 'forecast_settings', label: 'Forecast Weights(v1)', hide: true }
                            ]}>
                                <CustomTabs.Tab key="variable_settings">
                                    <VariableSettings></VariableSettings>
                                </CustomTabs.Tab>
                                <CustomTabs.Tab key="forecast_settings_new">
                                    <ForecastWeights></ForecastWeights>
                                </CustomTabs.Tab>
                            </CustomTabs>
                        )}


                        <div className="row">
                            {/* Segments Tab */}
                            {activeTab === 'segments' && <>
                                <div className="column">
                                    <div className="forms-section">
                                        {/* Segments List */}
                                        {<div style={{ width: "100%", paddingBottom: 40 }}>
                                            <TabButton type="content" text="Segments"
                                                isSelected={segmentMappingView === "segments"}
                                                action={() => setSegmentMappingView("segments")} />
                                            <TabButton type="content" text="Integration Mappings"
                                                isSelected={segmentMappingView === "mappings"}
                                                action={() => setSegmentMappingView("mappings")} />
                                        </div>}

                                        {segmentMappingView === "segments" && <div>

                                            {/* Segments List */}
                                            {customHeader("Segments", SegmentButton, false)()}
                                            <table className="forms-table">
                                                <thead>
                                                    <tr style={{ marginBottom: '8px' }}>
                                                        <th>Segment Name</th>
                                                        <th>Segment Code</th>
                                                        <th>Segment Group</th>
                                                        <th>Segment Group Code</th>
                                                        <th style={{ textAlign: 'right' }}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {state.properties.property && state.properties.property.segments.length ? (
                                                        state.properties.property.segments.map((segment) => {
                                                            if (segment.local) {
                                                                return (
                                                                    <tr key={segment.local.code}>
                                                                        <td className="segment-name">{segment.local.name}<div className="rms-tooltip"><p>{segment.local.description}</p></div></td>
                                                                        <td>{segment.local.code}</td>
                                                                        <td>{(getPropertySegmentGroupsForSegment(segment))?.g}</td>
                                                                        <td>{segment.local.gc}</td>
                                                                        <td style={{ textAlign: 'right' }}>
                                                                            {segment.local?.code && segment.local?.code !== "UNS" && <>
                                                                                {state.users.authUser.type === 'hotel_user' ?
                                                                                    <Settings onClick={() => { setSegmentModal(true); setSegmentModalState('edit'); setSegmentInput(segment) }} width="20px" height="20px" className="pointer" style={{ marginRight: '10px' }} />
                                                                                    :
                                                                                    <Edit onClick={() => { setSegmentModal(true); setSegmentModalState('edit'); setSegmentInput(segment) }} width="20px" height="20px" className="pointer" style={{ marginRight: '10px' }} />
                                                                                }
                                                                                {state.users.authUser.type === 'ope_admin' &&
                                                                                    <Exit onClick={() => { setSegmentDeleteModal(true); setSegmentInput(segment); }} width="20px" height="20px" className="pointer" />
                                                                                }
                                                                            </>}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td>No segments found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>}


                                        {/* Segment Mappings List */}
                                        {segmentMappingView === "mappings" &&
                                            <SegmentMappingsList segments={segmentsList}
                                                user={state.users.authUser}
                                                onAdd={() => setSegmentMapsView(["list", "add"])}
                                                onView={() => setSegmentMapsView(["list", "view"])}
                                                onEdit={() => setSegmentMapsView(["list", "edit"])}
                                                onDelete={() => setSegmentMapsView(["list", "delete"])} />}

                                    </div>
                                </div>
                            </>}



                            {/* Room Types Tab */}
                            {activeTab === "roomMapping" && <>
                                <div className="column">
                                    <div className="forms-section">


                                        {/* New Room Types List Version */}
                                        {roomsMappingEnabled && <div style={{ width: "100%", paddingBottom: 40 }}>
                                            <TabButton type="content" text="Room Types"
                                                isSelected={roomMappingView === "room-types"}
                                                action={() => setRoomMappingView("room-types")} />
                                            <TabButton type="content" text="Integration Mappings"
                                                isSelected={roomMappingView === "mappings"}
                                                action={() => setRoomMappingView("mappings")} />
                                        </div>}

                                        {roomsMappingEnabled && roomTypesView.includes("list") && <div style={{ position: 'relative' }}>

                                            {/* Default Room Type for Bar Select */}
                                            <div style={{
                                                display: 'flex', position: 'absolute', right: 0, gap: '10px', top: -7
                                            }}>
                                                <span style={{ marginTop: 12 }}>
                                                    <strong>Default Room Type for BAR:</strong>
                                                </span>
                                                <div className='rms-select-filter' style={{ marginTop: 4 }}>
                                                    <Select 
                                                        value={barRoomType}
                                                        onChange={(value) => {
                                                            setBarRoomType(value);
                                                            updateBarRoomType(propertyInView._id, { room_type_code: value })
                                                                .then((res) => {
                                                                    if (res.type === constants.UPDATE_PROPERTY_ROOM_TYPES_BAR_FAILURE) {
                                                                        setBarRoomType(propertyInView.room_types_bar ?? 'ALL');
                                                                        ToastsStore.error("Unable to update Default Room Type for BAR at this time");
                                                                    }
                                                                    if (res.type === constants.UPDATE_PROPERTY_ROOM_TYPES_BAR_SUCCESS) {
                                                                        ToastsStore.success("Default Room Type for BAR successfully updated");
                                                                    }
                                                                });
                                                        }}
                                                        popupMatchSelectWidth={false}
                                                        suffixIcon={null}
                                                        optionLabelProp="name"
                                                        options={barRoomTypesList}
                                                        optionRender={({ data }) => <span>{`${data.name}`}</span>}
                                                    />
                                                </div>
                                                {/* <span onClick={() => document.querySelector(`#barRoomType`).click()}
                                                    style={{
                                                        marginTop: 12, marginLeft: '4px',
                                                        color: '#51b68a', cursor: 'pointer'
                                                    }}
                                                >
                                                    <strong>{
                                                        barRoomTypesList
                                                            .find(rt => rt.value === barRoomType)?.name?.toUpperCase()
                                                    }</strong>
                                                </span>
                                                <br style={{ height: 0 }} />
                                                <BasicSelect id="barRoomType" hidden={true}
                                                    actualValue={barRoomType}
                                                    handleChange={(value) => {
                                                        setBarRoomType(value);
                                                        updateBarRoomType(propertyInView._id, { room_type_code: value })
                                                            .then((res) => {
                                                                if (res.type === constants.UPDATE_PROPERTY_ROOM_TYPES_BAR_FAILURE) {
                                                                    setBarRoomType(propertyInView.room_types_bar ?? 'ALL');
                                                                    ToastsStore.error("Unable to update Default Room Type for BAR at this time");
                                                                }
                                                                if (res.type === constants.UPDATE_PROPERTY_ROOM_TYPES_BAR_SUCCESS) {
                                                                    ToastsStore.success("Default Room Type for BAR successfully updated");
                                                                }
                                                            });
                                                    }}
                                                    options={barRoomTypesList}
                                                /> */}
                                            </div>

                                            {roomMappingView === "room-types" &&
                                                <RoomTypesList roomtypes={roomTypesList}
                                                    partnerCode="REVANDYOU"
                                                    // partnerCode={partnersList[0] ? partnersList[0].partner_code : null}
                                                    user={state.users.authUser}
                                                    onAdd={() => setRoomTypesView(["list", "add"])}
                                                    onView={() => setRoomTypesView(["list", "view"])}
                                                    onEdit={() => setRoomTypesView(["list", "edit"])}
                                                    onDelete={() => setRoomTypesView(["list", "delete"])} 
                                                />
                                            }

                                            {roomMappingView === "mappings" &&
                                                <RoomMappingsList roomtypes={roomTypesList}
                                                    user={state.users.authUser}
                                                    onAdd={() => setRoomMapsView(["list", "add"])}
                                                    onView={() => setRoomMapsView(["list", "view"])}
                                                    onEdit={() => setRoomMapsView(["list", "edit"])}
                                                    onDelete={() => setRoomMapsView(["list", "delete"])}
                                                    onUpdateBarStatus={() => {
                                                        setRoomMapsView(["list"])
                                                        retrieveRoomMappings(propertyInView._id).then((res) => {
                                                            if (res.type === constants.GET_PROPERTY_ROOM_MAPPINGS_SUCCESS) {
                                                                let updatedRoomTypesData = res.response.data.data;
                                                                dispatch({
                                                                    type: constants.UPDATE_PROPERTY_IN_VIEW,
                                                                    value: { ...propertyInView, room_types: updatedRoomTypesData }
                                                                });
                                                                setRoomTypesList(updatedRoomTypesData);
                                                            }
                                                        });
                                                    }} 
                                                />
                                            }
                                        </div>}


                                        {/* Old Room Types List Version */}
                                        {!roomsMappingEnabled && customHeader("Room Types", RoomTypeAddButton, false)()}
                                        {!roomsMappingEnabled && <table className="forms-table">
                                            <thead>
                                                <tr style={{ marginBottom: '8px' }}>
                                                    <th>Property Room Code</th>
                                                    <th>Property Room Name</th>
                                                    <th>Rev&You Room Code</th>
                                                    <th>Rev&You Room Name</th>
                                                    <th style={{ textAlign: 'right' }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state.properties.property && state.properties.property.room_types.length ? (
                                                    transformPropertyRoomTypes(
                                                        state.properties.property.mappings[0]?.partner_code,
                                                        state.properties.property.room_types
                                                    ).map(mappedRoomType =>
                                                        <tr key={mappedRoomType.room_type_code}>
                                                            <td className="segment-name">{mappedRoomType.property_room_code}</td>
                                                            <td>{mappedRoomType.property_room_name}</td>
                                                            <td>{mappedRoomType.external_room_code}</td>
                                                            <td>{mappedRoomType.external_room_name}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {state.users.authUser.type === 'ope_admin' ?
                                                                    <Edit onClick={() => { setRoomTypeModalState({ ...roomTypeModalState, show: true, operation: RoomTypeOperation.EDIT, data: { ...mappedRoomType, property_id: match.params.id } }); }} width="20px" height="20px" className="pointer" style={{ marginRight: '10px' }} />
                                                                    :
                                                                    <Edit fill="#868686" width="20px" height="20px" className="pointer" style={{ marginRight: '10px' }} />
                                                                }
                                                                {state.users.authUser.type === 'ope_admin' ?
                                                                    <Exit onClick={() => { setRoomTypeModalState({ ...roomTypeModalState, show: true, operation: RoomTypeOperation.DELETE, data: { ...mappedRoomType, property_id: match.params.id } }); }} width="20px" height="20px" className="pointer" />
                                                                    :
                                                                    <Exit fill="#868686" width="20px" height="20px" className="pointer" style={{ marginRight: '10px' }} />
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                ) : (
                                                    <tr>
                                                        <td>No room types found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>}
                                    </div>
                                </div>
                            </>}

                            {/* Competitor Mapping Tab */}
                            {activeTab === "competitorMapping" && <>
                                <div className="column">
                                    <div className="forms-section">
                                        <CompetitorsList 
                                            competitors={competitorsList} 
                                            user={state.users.authUser}
                                            onAdd={() => setCompetitorMapsView(["list", "add"])}
                                            onEdit={(competitor) => {
                                                setSelectedCompetitor(competitor);
                                                setCompetitorMapsView(["list", "edit"])
                                            }}
                                            onDelete={(competitor) => {
                                                setSelectedCompetitor(competitor);
                                                setCompetitorMapsView(["list", "delete"])
                                            }}
                                        />
                                    </div>
                                </div>
                            </>}

                            {/* Competitor Mapping Tab */}
                            {activeTab === "customDashboard" && <>
                                <div className="column">
                                    <div className="forms-section">
                                        <CustomDashboardViewList 
                                            defaultViewOption={customDashboardDefaultView} 
                                            customDates={customDashboardCustomDates}
                                            user={state.users.authUser}
                                            onDefaultViewEdit={(code, value) => {
                                                const params = { custom_dashboard: { 
                                                    default_view: { code, value } 
                                                } };
                                                updateProperty(match.params.id, params).then(result => {
                                                    const property = result.response.data.data;
                                                    setCustomDashboardDefaultView(property.custom_dashboard.default_view);
                                                    dispatch({
                                                        type: constants.UPDATE_ACTIVE_HOTEL,
                                                        value: property
                                                    });
                                                    // Update state of property data to update the UI of Nav.
                                                    dispatch({
                                                        type: constants.UPDATE_PROPERTY_DATA,
                                                        value: property
                                                    });
                                                });
                                            }}
                                            onCustomDatesEdit={(dateRanges) => {
                                                const params = { custom_dashboard: { 
                                                    custom_dates: dateRanges
                                                } };
                                                return updateProperty(match.params.id, params).then(result => {
                                                    const property = result.response.data.data;
                                                    setCustomDashboardCustomDates(property.custom_dashboard.custom_dates);
                                                    dispatch({
                                                        type: constants.UPDATE_ACTIVE_HOTEL,
                                                        value: property
                                                    });
                                                    // Update state of property data to update the UI of Nav.
                                                    dispatch({
                                                        type: constants.UPDATE_PROPERTY_DATA,
                                                        value: property
                                                    });
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </>}

                            {/* Submit and Cancel Buttons */}
                            {(!['roomMapping', 'segments', 'forecastSettings', 'competitorMapping', 'customDashboard'].includes(activeTab)) && (
                                <div className="row" style={{ minHeight: '50px' }}>
                                    <div className="action-wrapper" style={{ display: activeTab === 'segments' ? 'none' : 'flex' }}>
                                        <Button
                                            className="submit"
                                            type="primary"
                                            onClick={() =>
                                                setState({ ...internalState, showModal: true })
                                            }
                                            disabled={Object.keys(errors).length}
                                        >
                                            {" "}
                                            Submit{" "}
                                        </Button>
                                        <Button type="bg-dark" onClick={handleCancel}>
                                            {" "}
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>


                        {/* Submit Confirmation Modal */}
                        <Modal
                            open={internalState.showModal}
                            closeIcon={
                                <Exit width="25px" height="25px" className="pointer" />
                            }
                            onCancel={() => setState({ ...internalState, showModal: false })}
                            width={500}
                            footer={null}
                        >
                            <div className="rms-modal-content">
                                <p>
                                    {internalState.isEdit
                                        ? "Are you sure you want to submit changes?"
                                        : "Create new property?"}
                                </p>
                                <div className="modal-buttons">
                                    <Button
                                        type="bg-light"
                                        onClick={() =>
                                            setState({ ...internalState, showModal: false })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            handleSubmit();
                                            setState({ ...internalState, showModal: false });

                                            // Check if there are errors on save
                                            // And check if user is in Add Property Form
                                            // If there aren't any, go back to Properties List
                                            if (history && history.location.pathname.includes("add")) {
                                                let errorObject = validateForm(values, combinedFields);
                                                let noErrors = Object.keys(errorObject).length === 0;
                                                if (noErrors) history.goBack();
                                            }
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Modal>


                        {/* Property Status Modal */}
                        <Modal
                            open={propertyStatusModalState.show}
                            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                            onCancel={() => {
                                setPropertyStatusModalState({ show: false });
                                handleChange("status", !editedValues.status);
                            }}
                            width={500}
                            footer={null}
                        >
                            <div className="rms-modal-content">
                                <p>{`You are about to change property status to ${editedValues.status === true ? "active" : "inactive"
                                    }`}</p>
                                <div className="modal-buttons">
                                    <Button
                                        type="bg-light"
                                        onClick={() => {
                                            setPropertyStatusModalState({ show: false });
                                            handleChange("status", !editedValues.status);
                                        }}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            const data = {
                                                status: editedValues.status === true ? "active" : "inactive",
                                            };
                                            updatePropertyStatus(match.params.id, data).then((res) => {
                                                setPropertyStatusModalState({ show: false });
                                            });
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </Modal>


                        {/* Add Brand Modal */}
                        <AddBrandModal
                            showModal={internalState.showBrandModal}
                            brands={brands}
                            onSetBrands={setBrands}
                            onCancel={() => handleChange("brand", "")}
                            closeModal={() =>
                                setState({ ...internalState, showBrandModal: false })
                            }
                        />


                        {/* Unsaved Changes Modal */}
                        <UnsavedChangesModal
                            visible={internalState.showUnsavedWarning}
                            onCancel={() =>
                                setState({ ...internalState, showUnsavedWarning: false })
                            }
                            onConfirm={() => {
                                history.goBack();
                            }}
                        />


                        {/* Integration Partners / Channel Mapping Modal */}
                        <PartnersModal visible={partnersView.filter(view => !["editbooksrc", "list"].includes(view)).length > 0}
                            type={partnersView.filter(view => view !== "list").toString()}
                            channels={integrationPartners}
                            onCancel={() => setPartnersView(["list"])}
                            onSubmit={() => {
                                setPartnersView(["list"])
                                retrievePartnerMappings(propertyInView._id).then((res) => {
                                    if (res.type === constants.GET_PROPERTY_PARTNER_MAPPINGS_SUCCESS) {
                                        setPartnersList(res.response.data.data);
                                    }
                                });
                            }} />

                        <BookingSourceModal visible={partnersView.includes("editbooksrc")}
                            bookingSourcesMasterList={bookingSourcesList}
                            onCancel={() => setPartnersView(["list"])}
                            onSubmit={() => {
                                setPartnersView(["list"]);
                                getProperty(propertyInView._id).then((res) => {
                                    dispatch({
                                        type: constants.UPDATE_PROPERTY_IN_VIEW,
                                        value: res.response.data.data
                                    });
                                });
                            }} />


                        {/* Segment Modal */}
                        <SegmentModal
                            segmentState={segmentModalState}
                            visible={segmentModal}
                            onClose={() => { setSegmentModal(false) }}
                            segmentInput={segmentInput}
                            deleteModal={segmentDeleteModal}
                            onDeleteModalClose={() => setSegmentDeleteModal(false)}
                        />


                        {/* New Room Types Modal Version */}
                        {roomsMappingEnabled &&
                            <RoomTypesModal visible={roomTypesView.filter(view => view !== "list").length > 0}
                                type={roomTypesView.filter(view => view !== "list").toString()}
                                rts={internalRoomTypes}
                                onCancel={() => setRoomTypesView(["list"])}
                                onSubmit={() => {
                                    setRoomTypesView(["list"])
                                    retrieveRoomMappings(propertyInView._id).then((res) => {
                                        if (res.type === constants.GET_PROPERTY_ROOM_MAPPINGS_SUCCESS) {
                                            let updatedRoomTypesData = res.response.data.data;
                                            dispatch({
                                                type: constants.UPDATE_PROPERTY_IN_VIEW,
                                                value: { ...propertyInView, room_types: updatedRoomTypesData }
                                            });
                                            setRoomTypesList(updatedRoomTypesData);
                                            setBarRoomTypesList([
                                                { name: "Lowest Rate from All Room Types  ", value: 'ALL' },
                                                ...sortArray(updatedRoomTypesData.map((rt) => {
                                                    return {
                                                        name: `${rt.room_type} (${rt.room_type_code})  `,
                                                        value: rt.room_type_code
                                                    }
                                                }), "name")
                                            ])
                                        }
                                    });
                                }} />
                        }

                        <CompetitorsModal visible={competitorMapsView.some(view => ["add", "edit", "delete"].includes(view))} 
                            type={competitorMapsView.find(view => ["add", "edit", "delete"].includes(view))} 
                            competitor={selectedCompetitor}
                            onCancel={() => {
                                setSelectedCompetitor(null);
                                setCompetitorMapsView(["list"]);
                            }}
                            adminCompetitors={adminCompetitorsList}
                            onSubmit={async ({ type = null, data = {} }) => {
                                const doneSubmit = (res) => {
                                    setSelectedCompetitor(null);
                                    setCompetitorMapsView(["list"])
                                    getProperty(propertyInView._id).then((res) => {
                                        const fetchedHotel = res.response.data.data;
                                        setCompetitorsList(fetchedHotel.competitors.map(competitor => {
                                            const gcompetitor = fetchedHotel.competitors_info.find(gc => gc._id == competitor.competitor_id)
                                            return { ...competitor, name: gcompetitor?.name?? "N/A" }
                                        }));
                                        dispatch({
                                            type: constants.UPDATE_PROPERTY_IN_VIEW,
                                            value: fetchedHotel
                                        });
                                    });
                                }

                                if (type === "add") {
                                    addCompetitor(propertyInView._id, {
                                        competitor_id: data.competitor_id,
                                        label: data.competitor_label
                                    }).then((res) => {
                                        if ((res.type ?? "").substr(res.type.lastIndexOf("_") + 1) == "SUCCESS") {
                                            ToastsStore.success(`Competitor was successfuly added!`);
                                            doneSubmit(res);
                                        } else
                                            ToastsStore.error(`${res.error.response.data.message}`);
                                    });
                                }
                                if (type === "edit") {
                                    updateCompetitor(propertyInView._id, data._id, {
                                        competitor_id: data.competitor_id,
                                        label: data.competitor_label
                                    }).then((res) => {
                                        if ((res.type ?? "").substr(res.type.lastIndexOf("_") + 1) == "SUCCESS") {
                                            ToastsStore.success(`Competitor was successfuly updated!`);
                                            doneSubmit(res);
                                        } else
                                            ToastsStore.error(`${res.error.response.data.message}`);
                                    });
                                }
                                if (type === "delete") {
                                    deleteCompetitor(propertyInView._id, data._id).then((res) => {
                                        if ((res.type ?? "").substr(res.type.lastIndexOf("_") + 1) == "SUCCESS") {
                                            ToastsStore.success(`Competitor was successfuly deleted!`);
                                            doneSubmit(res);
                                        } else
                                            ToastsStore.error(`${res.error.response.data.message}`);
                                    });
                                }
                            }}
                        />

                        {/* Old Room Types Modal Version */}
                        {!roomsMappingEnabled && <RoomTypeMappingModal
                            operation={roomTypeModalState.operation}
                            visible={roomTypeModalState.show}
                            data={roomTypeModalState.data}
                            onClose={() => {
                                setRoomTypeModalState({ ...roomTypeModalState, show: false })
                            }}
                        />}

                        {/* Room Inventory Modal */}
                        <RoomInventoriesModal visible={roomInventoriesView.some(view => ["add", "edit", "delete"].includes(view))}
                            type={roomInventoriesView.find(view => ["add", "edit", "delete"].includes(view))}
                            selectedRoomInventory={selectedRoomInventory}
                            roomInventoryList={roomInventories}
                            onCancel={() => {
                                setRoomInventoriesView(["list"]);
                                setSelectedRoomInventory(null);
                            }}
                            onSubmit={({ type, data }) => {
                                if (type === "add") {
                                    setRoomInventories([data, ...roomInventories]);
                                }
                                if (type === "edit") {
                                    setRoomInventories(roomInventories.map(item => {
                                        return item._id === data._id
                                            ? { ...item, ...data }
                                            : item;
                                    }));
                                }
                                if (type === "delete") {
                                    setRoomInventories(roomInventories.filter(item => {
                                        return item._id !== data._id;
                                    }));
                                }
                                setRoomInventoriesView(["list"]);
                                setSelectedRoomInventory(null);
                            }}
                        />
                        {/* Segment Mappings Modal */}
                        <SegmentMappingsModal visible={segmentMapsView.filter(view => view !== "list").length > 0}
                            type={segmentMapsView.filter(view => view !== "list").toString()}
                            channels={integrationPartners}
                            ss={internalRoomTypes}
                            onCancel={() => setSegmentMapsView(["list"])}
                            onSubmit={() => {
                                setSegmentMapsView(["list"])
                                retrieveSegmentMappings(propertyInView._id).then((res) => {
                                    if (res.type === constants.GET_PROPERTY_SEGMENT_MAPPINGS_SUCCESS) {
                                        let updatedSegmentsData = res.response.data.data;
                                        dispatch({
                                            type: constants.UPDATE_PROPERTY_IN_VIEW,
                                            value: { ...propertyInView, segments: updatedSegmentsData }
                                        });
                                        setSegmentsList(updatedSegmentsData);
                                    }
                                });
                            }} />


                        {/* Room Mappings Modal */}
                        {roomsMappingEnabled &&
                            <RoomMappingsModal visible={roomMapsView.filter(view => view !== "list").length > 0}
                                type={roomMapsView.filter(view => view !== "list").toString()}
                                channels={integrationPartners}
                                rts={internalRoomTypes}
                                onCancel={() => setRoomMapsView(["list"])}
                                onSubmit={() => {
                                    setRoomMapsView(["list"])
                                    retrieveRoomMappings(propertyInView._id).then((res) => {
                                        if (res.type === constants.GET_PROPERTY_ROOM_MAPPINGS_SUCCESS) {
                                            let updatedRoomTypesData = res.response.data.data;
                                            dispatch({
                                                type: constants.UPDATE_PROPERTY_IN_VIEW,
                                                value: { ...propertyInView, room_types: updatedRoomTypesData }
                                            });
                                            setRoomTypesList(updatedRoomTypesData);
                                        }
                                    });
                                }} />
                        }


                        {/* Delete Comparison Modal */}
                        <ConfirmationModal visible={showDeleteComparisonModal}
                            text={(<p><br />
                                Are you sure you want to remove <b>{
                                    state.mainReducer.comparisons_options.find(_ => _.id === deleteComparisonId)?.name
                                }</b> from the comparisons list?
                            </p>)}
                            width={580}
                            confirmText="Submit"
                            cancelText="Cancel"
                            eventHandler={({ action }) => {
                                switch (action) {
                                    case "submit":
                                        const updatedComparisons = comparisons.filter(_ => _.id !== deleteComparisonId);
                                        dispatch({
                                            type: constants.UPDATE_COMPARISONS_LATEST,
                                            value: updatedComparisons
                                        })
                                        setComparisons(updatedComparisons);
                                        setShowDeleteComparisonModal(false)
                                        break;
                                    default:
                                        setShowDeleteComparisonModal(false);
                                        break;
                                }
                            }}
                        />
                    </div>
                )}
            </WithDataRequiredContainer>


            {/* Developer Tools UI */}
            {/* Only used for easily viewing several state data via the app */}
            {/* Instead of having to open the browser's dev-tools all the time */}
            <Tools />
        </div>
    );
};

export default PropertiesForms;