import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../Button/Button';
import DataGrid from '../../../DataGrid';
import CustomAvgDatesModal from "../Modals/CustomAvgDatesModal";
import { gridDefaultsColumns, gridDefaultsCellConfig } from './gridConfig';
import usePropertiesActions from '../../../../store/actions/propertiesActions';

const DefaultsSection = (props) => {
    const isGroup = props?.isGroup ?? false;
    const group = props.values;
    const { property, loadingProperty } = useSelector(state => state.properties);
    const item = isGroup ? group : property;
    const forecastSettingsDefaults = item?.forecast_settings_v2.defaults;
    
    const gridDefaultsRef = useRef();
    const { updateForecastSettingsV2 } = usePropertiesActions();
    const [customModalState, setCustomModalState] = useState({ for: null, header: null, items: [], visible: false });

    useEffect(() => {
        if (loadingProperty) return;  
        gridDefaultsRef.current.loadData(getColumnData(forecastSettingsDefaults, false));
    }, []);

    const getColumnData = (value, loadDefaults = true) => {
        if (loadDefaults) {
            return [{
                'variable': 'Same day last year',
                'average_days': 'N/A'
            }, {
                'variable': 'Rolling Average DOW'
            }, {
                'variable': 'Custom Average DOW'
            }, {
                'variable': 'Rolling Average'
            }, {
                'variable': 'Custom Average'
            }]
        }
        return [{
            'variable': 'Same day last year',
            'average_days': 'N/A',
            'instances': value.instances.sdly
        }, {
            'variable': 'Rolling Average DOW',
            'average_days': value.avg_days.avg_dow,
            'instances': value.instances.avg_dow
        }, {
            'variable': 'Rolling Average',
            'average_days': value.avg_days.avg,
            'instances': value.instances.avg
        }, {
            'variable': 'Custom Average DOW',
            'average_days': value.avg_days.custom_avg_dow,
            'instances': value.instances.custom_avg_dow
        }, {
            'variable': 'Custom Average',
            'average_days': value.avg_days.custom_avg,
            'instances': value.instances.custom_avg
        }]
    }
    
    const cellConfigs = [...gridDefaultsCellConfig, { 
        positionX: 1, 
        positionY: 3, 
        defaultValue: null,
        type: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg_dow", 
                header: "Manage Custom Average DOW",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }, { 
        positionX: 1, 
        positionY: 4, 
        defaultValue: null,
        type: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg", 
                header: "Manage Custom Average",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }];
    
    const handleSubmit = () => {
        if (gridDefaultsRef.current.internalState.hasError) return;

        const newForecastDefaults = { ...forecastSettingsDefaults };
        ['sdly', 'avg_dow', 'avg', 'custom_avg_dow', 'custom_avg'].forEach((key, idx) => {
            newForecastDefaults.avg_days[key] = idx === 0 ? null : gridDefaultsRef.current.getData('average_days', idx);
            newForecastDefaults.instances[key] = gridDefaultsRef.current.getData('instances', idx);
        });

        if (gridDefaultsRef.current.errors.length > 0) return;
        updateForecastSettingsV2(isGroup ? group.id : property._id, newForecastDefaults, 'defaults', isGroup)
            .then(result => {
                if (result.type === "UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS") {
                    const updatedProperty = result.response.data.data;
                    gridDefaultsRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.defaults, false))
                }
            });
    }

    const handleCancel = () => {
        gridDefaultsRef.current.reload();
    }

    return (<div className="defaults-section">
        <DataGrid ref={gridDefaultsRef} columns={gridDefaultsColumns} cellConfigs={cellConfigs} defaultCellHeight="40px" footerHeight="0px" />
        <div style={{ display: "flex", flexDirection: 'row-reverse', paddingTop: "25px" }}>
            <Button type="primary" className="submit" onClick={handleSubmit} style={{ marginLeft: "35px" }}>Submit</Button>
            <Button type="bg-dark" onClick={handleCancel}>Cancel</Button>
        </div>

        <CustomAvgDatesModal width={484}
            headerCopy={ customModalState.header }
            isVisible={ customModalState.visible } 
            items={ customModalState.items }
            onCancel={() => setCustomModalState({ ...customModalState, visible: false })} 
            onSubmit={(items) => {
                const params = { avg_days: null };

                if (customModalState.for === 'avg_dow') {
                    params.avg_days = { custom_avg_dow: items };
                }

                if (customModalState.for === 'avg') {
                    params.avg_days = { custom_avg: items };
                }

                updateForecastSettingsV2(isGroup ? group.id : property._id, params, 'defaults', isGroup).then(result => {
                    setCustomModalState({ ...customModalState, visible: false });
                    const updatedProperty = result.response.data.data;
                    gridDefaultsRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.defaults, false)); 
                });
                
            }}
        />
    </div>)
}

export default DefaultsSection;