import React from "react";
import Field from "../Field";

const Form = ({
  fields,
  title,
  onChange,
  onBlur,
  formValues,
  fieldErrors,
  renderCustomHeader: Component,
}) => {
  return (
    <div className="forms-section">
      {Component && <Component /> }
      {/* : <h3>{title}</h3> */}

      {fields.map(field => {
        const { key, label, type, options, dropDownList, add, disabled = false} = field;
        return (
          <Field
            key={key}
            field={field}
            fieldKey={key}
            label={label}
            type={type}
            value={formValues[key]}
            placeholder=""
            onChange={onChange}
            onBlur={onBlur}
            errors={fieldErrors[key]}
            options={options}
            dropDownList={dropDownList}
            add={add}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default Form;
