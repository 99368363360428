import React from "react";


/**
 * An basic error element. Red in color.
 *
 * @param {String} errorKey Error key.
 * @param {String} error Error message.
 * @return {Component} A list header element.
*/
const Error = ({ errorKey = "", error = "" }) => {

    return (
        <div key={errorKey}>
            <span style={{ color: "red" }}>{error}</span>
        </div>
    );
};

export default Error;
