import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../Button/Button';
import Field from "../../../Field";
import ForecastWeightsSection from './ForecastWeightsSection';
import { transformData, validateValues, validateValuesWithFields, days_out_fields } from './forecastWeightsHelper';
import usePropertiesActions from '../../../../store/actions/propertiesActions';
// import { forecastWeights } from './mockData';

const ForecastWeights = (props) => {
    
    const { updateForecastSettingsV2 } = usePropertiesActions();
    const selectedProperty = useSelector(state => state.properties?.property);
    const { isGroup = false } = props;
    const group = props.values; // NULL if not group
    const property = isGroup? group: selectedProperty;

    const forecastWeights = property?.forecast_settings_v2?.weights;
    const hasForecastWeights = Array.isArray(forecastWeights);
    
    const fcWeightsMax = hasForecastWeights? forecastWeights.find(item => item.max_dout == null && item.min_dout != null): {};
    const fcWeightsMid = hasForecastWeights? forecastWeights.find(item => item.max_dout != null && item.min_dout != null): {};
    const fcWeightsMin = hasForecastWeights? forecastWeights.find(item => item.max_dout != null && item.min_dout == null): {};

    const [fcwMaxValues, setFcwMaxValues] = useState(transformData(fcWeightsMax));
    const [fcwMidValues, setFcwMidValues] = useState(transformData(fcWeightsMid));
    const [fcwMinValues, setFcwMinValues] = useState(transformData(fcWeightsMin));

    const [fcwMaxErrors, setFcwMaxErrors] = useState({});
    const [fcwMidErrors, setFcwMidErrors] = useState({});
    const [fcwMinErrors, setFcwMinErrors] = useState({});
    const [fcwDoutErrors, setFcwDoutErrors] = useState({});

    const [fcwMaxMinDaysOut, setFcwMaxMinDaysOut] = useState(fcWeightsMid?.max_dout?? 120);
    const [fcwMinMaxDaysOut, setFcwMinMaxDaysOut] = useState(fcWeightsMid?.min_dout?? 14);

    const fcwMaxValuesOnChange = (key, val) => setFcwMaxValues({ ...fcwMaxValues, [key]: val });
    const fcwMidValuesOnChange = (key, val) => setFcwMidValues({ ...fcwMidValues, [key]: val });
    const fcwMinValuesOnChange = (key, val) => setFcwMinValues({ ...fcwMinValues, [key]: val });

    useEffect(() => {
        setFcwMaxErrors(validateValues(fcwMaxValues));
    }, [fcwMaxValues]);
    useEffect(() => {
        setFcwMidErrors(validateValues(fcwMidValues));
    }, [fcwMidValues]);
    useEffect(() => {
        setFcwMinErrors(validateValues(fcwMinValues));
    }, [fcwMinValues]);
    useEffect(() => {
        setFcwDoutErrors(validateValuesWithFields({
            "max_min_days_out": fcwMaxMinDaysOut, 
            "min_max_days_out": fcwMinMaxDaysOut
        }, days_out_fields));
    }, [fcwMaxMinDaysOut, fcwMinMaxDaysOut])

    const handleSubmit = () => {
        // Save values
        if (Object.keys({...fcwMaxErrors, ...fcwMidErrors, ...fcwMinErrors, ...fcwDoutErrors}).length > 0) return;

        const allWeights = [];

        const transformValues = (weights) => {
            const newForecastSettingsWeights = {};
            const weightKeys = ['pickup', 'pace', 'forecast', 'price', 'finals'];
            // Each values should have the same format in their keys.
            weightKeys.forEach(key => {
                let editedKeys = Object.keys(weights).filter(weight => weight.indexOf(key) === 0);
                if (editedKeys.length > 0) {
                    editedKeys.forEach(editedKey => {
                        if (newForecastSettingsWeights[key] === undefined) newForecastSettingsWeights[key] = {};
                        newForecastSettingsWeights[key][editedKey.replace(`${key}_`, '')] = weights[editedKey];
                    });
                }
            });

            return newForecastSettingsWeights;
        }

        allWeights.push({ ...transformValues(fcwMaxValues), max_dout: null, min_dout: (fcwMaxMinDaysOut * 1) + 1 });
        allWeights.push({ ...transformValues(fcwMidValues), max_dout: fcwMaxMinDaysOut, min_dout: fcwMinMaxDaysOut });
        allWeights.push({ ...transformValues(fcwMinValues), max_dout: (fcwMinMaxDaysOut * 1) - 1, min_dout: null });

        updateForecastSettingsV2(isGroup ? group.id : property._id, allWeights, 'weights', isGroup)
            .then(result => {
                if (result.type === "UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS") {
                    // const updatedProperty = result.response.data.data;
                }
            });
    }

    const handleCancel = () => {
        // Reset values
        setFcwMaxValues(transformData(fcWeightsMax));
        setFcwMidValues(transformData(fcWeightsMid));
        setFcwMinValues(transformData(fcWeightsMin));
        setFcwMaxMinDaysOut(fcWeightsMid?.max_dout?? 120);
        setFcwMinMaxDaysOut(fcWeightsMid?.min_dout?? 14);
    
    }

    return (
        <div className="forecast-weights">
            <div className="fcw-section">
                <h3 className="fcw-section-header">Previous Year</h3>
                <div className="fcw-section-body">
                    <div className="fcw-days-out-input">
                        {fcwDoutErrors?.max_min_days_out && 
                            <strong><span className="field-error">{fcwDoutErrors?.max_min_days_out}</span></strong>
                        }
                        <Field 
                            key={"max_min_days_out"}
                            fieldKey={"max_min_days_out"}
                            label={"Days Out Greater Than"}
                            type={"number"}
                            value={fcwMaxMinDaysOut}
                            placeholder=""
                            onChange={(key, val) => setFcwMaxMinDaysOut(val)}
                            errors={fcwDoutErrors?.max_min_days_out}
                        />
                    </div>
                    <ForecastWeightsSection 
                        values={fcwMaxValues} errors={fcwMaxErrors}
                        onChange={fcwMaxValuesOnChange}
                    />
                </div>
            </div>
            <div className="fcw-section">
                <h3 className="fcw-section-header">Blended</h3>
                <div className="fcw-section-body">
                    <ForecastWeightsSection 
                        values={fcwMidValues} errors={fcwMidErrors}
                        onChange={fcwMidValuesOnChange}
                    />
                </div>
            </div>
            <div className="fcw-section">
                <h3 className="fcw-section-header">Recent</h3>
                <div className="fcw-section-body">
                    <div className="fcw-days-out-input">
                        {fcwDoutErrors?.min_max_days_out && 
                            <strong><span className="field-error">{fcwDoutErrors?.min_max_days_out}</span></strong>
                        }
                        <Field
                            key={"min_max_days_out"}
                            fieldKey={"min_max_days_out"}
                            label={"Days Out Less Than"}
                            type={"number"}
                            value={fcwMinMaxDaysOut}
                            placeholder=""
                            onChange={(key, val) => setFcwMinMaxDaysOut(val)}
                            errors={fcwDoutErrors?.min_max_days_out}
                        />
                    </div>
                    <ForecastWeightsSection 
                        values={fcwMinValues} errors={fcwMinErrors}
                        onChange={fcwMinValuesOnChange}
                    />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: 'row-reverse', padding: "35px 0 25px 0" }}>
                <Button type="primary" className="submit" onClick={handleSubmit} style={{ marginLeft: "35px" }}>Submit</Button>
                <Button type="bg-dark" onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    )
}

export default ForecastWeights;