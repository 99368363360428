import React from "react";

import ArrowIcon from "./ArrowIcon";

import { isNullOrUndefined } from "utils";


/**
 * A price recommendation icon.
 *
 * @param {Number} quantity Price recommendation value. Determines how many icons to display and what color.
 * @param {Boolean} round Round the quantity value to the nearest integer. Defaults to false.
 * @param {Boolean} hasCopy Displays a copy together with the icon. Defaults to false.
 * @return {Object} A basic 'Select' element.
*/
const PriceRecoIcon = ({ quantity, round = false, hasCopy = false }) => {

    if (isNullOrUndefined(quantity)) return null;
    if (round) quantity = (quantity > 0) ? Math.floor(quantity) : Math.ceil(quantity);


    return (
        <span>
            {quantity > 0 && <ArrowIcon direction="up" />}
            {quantity > 1 && <ArrowIcon direction="up" />}
            {quantity > 2 && <ArrowIcon direction="up" />}
            {quantity < 0 && <ArrowIcon direction="down" />}
            {quantity < -1 && <ArrowIcon direction="down" />}
            {quantity < -2 && <ArrowIcon direction="down" />}
            {hasCopy && <span>
                {quantity === 0 && <span>flat</span>}
                {isNullOrUndefined(quantity) && <span>null</span>}
                {!isNullOrUndefined(quantity) && quantity !== 0 && <span style={{ marginLeft: 8 }}>
                    {`(${Math.abs(quantity)} ${quantity > 0 ? "up" : "down"})`}
                </span>}
            </span>}
        </span>
    )
};

export default PriceRecoIcon;
