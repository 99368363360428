import React from 'react';
import DatePicker from 'elements/DatePicker';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';

const AsOfFilter = ({ value, onDateChange, isLoading, disabledDate, onClick }) => {
    return (
        <div className="rms-select-filter" onClick={(e) => onClick(e)}>
            {!isLoading
                ?<DatePicker
                    value={value}
                    onChange={(e) => onDateChange(e)}
                    format={"MMM DD YYYY"}
                    allowClear={false}
                    suffixIcon={null}
                    style={{ width: 95 }}
                    disabledDate={disabledDate}
                />
                :<OutlinedIcon style={{ fontSize: "20px", color: '#808285' }} type='loading' />
            }
        </div>
    )
}

export default AsOfFilter;