import * as React from "react";

function SvgAccount(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <defs>
        <style>
          {".Account_svg__cls-1{fill:#67b489}.Account_svg__cls-2{fill:#fff}"}
        </style>
      </defs>
      <g id="Account_svg__Layer_1" data-name="Layer 1">
        <circle className="Account_svg__cls-1" cx={50} cy={50.26} r={47.5} />
        <path
          className="Account_svg__cls-2"
          d="M69.85 71.92a3.72 3.72 0 003.63-4.44 23.9 23.9 0 00-47 0 3.72 3.72 0 003.63 4.44z"
        />
        <circle className="Account_svg__cls-1" cx={50.9} cy={40.48} r={11.87} />
        <path
          className="Account_svg__cls-2"
          d="M50.9 53.68a13.2 13.2 0 1113.19-13.2 13.2 13.2 0 01-13.19 13.2zm0-23.75a10.56 10.56 0 1010.56 10.55A10.57 10.57 0 0050.9 29.93z"
        />
      </g>
    </svg>
  );
}

export default SvgAccount;
