import * as React from "react";

function SvgExit(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <g data-name="Layer 1">
        <circle cx={50} cy={50} r={47.5} fill={props.fill? props.fill: "#67b489"} />
        <path
          d="M32.88 39.42l6.87-6.88 9.69 9.69 9.63-9.63 6.87 6.87-9.63 9.63 9.8 9.8-6.87 6.87-9.8-9.77-9.74 9.71-6.88-6.87 9.74-9.74z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgExit;
