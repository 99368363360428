import React from "react";
import { isNullOrUndefined } from "utils";


/**
 * A basic input element.
 *
 * @param {String} id Input ID.
 * @param {String} label Input label.
 * @param {String} value Input value.
 * @param {Boolean} disabled Disable input field, or not. Defaults to false.
 * @param {Function} onBlur Action to happen after blurring the input value.
 * @param {Function} onChange Action to happen after changing the input value.
 * @param {Function} onKeyDown Action to happen after a key down event.
 * @return {Component} A basic input element.
*/
const BasicInput = ({ id = "", label = "", value = null, type = "text", disabled = false,
    onBlur = null, onChange = null, onKeyDown = null }) => {

    const handleKeyDown = (e) => {
        let code;
        if (e.key !== undefined) {
            code = e.key;
        } else if (e.keyIdentifier !== undefined) {
            code = e.keyIdentifier;
        } else if (e.keyCode !== undefined) {
            code = e.keyCode;
        }

        if (!isNullOrUndefined(code) && !isNullOrUndefined(onKeyDown) && code === "Enter") onKeyDown();
    }


    return (
        <div className="light-input-container">
            <div className="light-label">{label}</div>

            <input id={id} className="light-input" name={label.toLowerCase()} type={type} disabled={disabled}
                style={disabled ? { cursor: "not-allowed", userSelect: "none", backgroundColor: "#f5f5f5" } : null}
                value={value} onChange={onChange} onBlur={onBlur} onKeyDown={(e) => handleKeyDown(e)} />
        </div>
    )
};

export default BasicInput;
