import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ToastsStore } from "react-toasts";
import Button from "components/Button/Button";

import usePropertiesActions from "store/actions/propertiesActions";
import FormModal from "components/FormModal/FormModal";


const AddBrandModal = ({ showModal, closeModal, brands, onSetBrands, onCancel }) => {

    const { addHotelBrands } = usePropertiesActions();

    const brandModalController = FormModal.newController();
    const { errors, inputValues, onChangeHandler, onBlurHandler, validateFields } = brandModalController;
    
    useEffect(() => {
        brandModalController.show(showModal);
    }, [showModal])

    function submit() {
        if (validateFields().length > 0) return;
        addHotelBrands(inputValues).then((res) => {
            if (res.type === "ADD_HOTEL_BRAND_SUCCESS") {
                onSetBrands([...brands, res.response.data.data]);
                closeModal();
                ToastsStore.success("Successfully added.");
            } else {
                ToastsStore.error("Something went wrong.");
            }
        });
    }

    return (
        <FormModal title={`Add Brand`} 
            maskClosable={false}
            controller={brandModalController}
            onSubmit={(e) => { 
                e.preventDefault();
                submit();
            }}
            onClose={() => closeModal()}
        >
            <div className="single-row-input">
                <div className="light-input-container" style={{ width: '100%' }}>
                    <FormModal.Field type="textbox" label="Brand Name"
                        name="brand_name" value={inputValues.brand_name} error={errors.brand_name}
                        validations={{ required: true, characters: 'alphanumeric_with_space' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
            </div>
            <div className="single-row-input">
                <div className="light-input-container" style={{ width: '100%' }}>
                    <FormModal.Field type="textbox" label="Brand Code"
                        name="brand_code" value={inputValues.brand_code} error={errors.brand_code}
                        validations={{ required: true, characters: 'alphanumeric' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
            </div>
            <div className="light-form-buttons" >
                <Button type="bg-light" style={{ marginRight: '8px' }} onClick={() => { closeModal(); onCancel(); }}>Cancel</Button>
                <Button btnType="submit" type="primary">Add</Button>
            </div>
        </FormModal>
    )
};

AddBrandModal.prototype = {
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    brands: PropTypes.array,
    onSetBrands: PropTypes.func,
    onCancel: PropTypes.func,
};

export default AddBrandModal;
