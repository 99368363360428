export const account_information = [
    {
        key: "name",
        label: "Name",
        placeholder: "Your name",
        validation: ["required"],
        type: "text",
        initialValue: ""
    },
    {
        key: "email",
        label: "Email Address",
        placeholder: "Your email",
        validation: ["required", 'email'],
        type: "text",
        initialValue: ""
    },

];

export const reset_password = [
    {
        key: "newPassword",
        label: "New Password",
        placeholder: "New Password",
        validation: ["required", 'confirmPassword'],
        type: "password",
        initialValue: ""
    },
    {
        key: "confirmPassword",
        label: "Confirm New Password",
        placeholder: "Confirm Password",
        validation: ["required", 'newPassword'],
        type: "password",
        initialValue: ""
    }
];

export const preferences = [
    {
        key: "appVersion",
        label: "App Version",
        placeholder: "v2",
        validation: [],
        type: "text",
        initialValue: "v2"
    },
    {
        key: "logoRedirectUrl",
        label: "Rev&You Logo On-Click Redirect Url",
        placeholder: "",
        validation: [],
        type: "text",
        initialValue: ""
    },
    {
        key: "showAllProperties",
        label: "Show All Properties in List",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
    {
        key: "hideLatestAsOfModal",
        label: "Hide Latest As-Of Modal",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
    {
        key: "cacheCalendar",
        label: "Cache Calendar Data Until Filter Change",
        type: "switch",
        initialValue: true,
        validation: [],
        disabled: false
    },
    {
        key: "cacheDashboardModal",
        label: "Cache Dashboard Modal Data Until Filter Change",
        type: "switch",
        initialValue: true,
        validation: [],
        disabled: false
    },
    {
        key: "singleAsOfAvgsForVariances",
        label: "Use Single As-Of Averages for Variances",
        type: "switch",
        initialValue: true,
        validation: [],
        disabled: false
    },
    {
        key: "colorScoreEnabled",
        label: "Compute Forecast Scores using Colors",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
    {
        key: "forecastPropertyDisabled",
        label: "Disable Property Forecasting",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
    {
        key: "forecastGroupDisabled",
        label: "Disable Property Group Forecasting",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
    {
        key: "propertyGroupsEnabled",
        label: "Enable Property Group",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
    {
        key: "usePercentsForOccs",
        label: "Use Percents For Group Occupancy Values",
        type: "switch",
        initialValue: true,
        validation: [],
        disabled: false
    },
    {
        key: "hideComparisons",
        label: "Hide Comparisons Feature",
        type: "switch",
        initialValue: true,
        validation: [],
        disabled: false
    },
    {
        key: "showDownloadables",
        label: "Show Download Features",
        type: "switch",
        initialValue: true,
        validation: [],
        disabled: false
    },
    {
        key: "sendEmailAfterFileUpload",
        label: "Send Email After File Upload",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
    {
        key: "enableLowestPriceCompetitor",
        label: "Enable Lowest Price Competitor",
        type: "switch",
        initialValue: false,
        validation: [],
        disabled: false
    },
];