import { isNullish, arraySum } from "../data";



/**
 * Retrieves the color, based on a given value and its comparison value.
 *
 * @exports color
 * @param {Number} value Value to be assigned a color.
 * @param {Object} comparison Comparison data object that determines what the resulting color is.
*/
export function color(value, comparison) {
    if (isNullish(value) || isNullish(comparison)) return null;
    if (value >= comparison.green) return "green";
    if (value >= comparison.grey) return "grey";
    if (value >= comparison.yellow) return "yellow";
    return "red";
}



/**
 * Computes the color score, based on the given color.
 *
 * @exports colorScore
 * @param {String} color Color.
*/
export function colorScore(color) {
    if (isNullish(color)) return null;
    if (color === "green") return 2;
    if (color === "grey") return 1;
    if (color === "yellow") return 0;
    if (color === "red") return -1;
    return null;
}



/**
 * Computes the weighted score, based on the weight percentage and the score.
 *
 * @exports weightScore
 * @param {Number} weight Weight percentage.
 * @param {Number} score Score value.
*/
export function weightScore(weight, score) {
    if (weight === 0) return 0;
    if (isNullish(weight) || isNullish(score)) return null;
    return Math.round((parseFloat((weight / 100) * score)) * 100) / 100;
}



/**
 * Counts how many colors are there in a given array.
 *
 * @exports colorCount
 * @param {[String]} colors An array of colors
 * @param {String} maxIndex Maximum array index value to count.
 * @param {String} matchColor Color match to count. Defaults to "green".
*/
export function colorCount(colors, maxIndex, matchColor = "green") {
    if (isNullish(colors) || isNullish(matchColor)) return null;

    let count = 0;
    for (let i = 0; i < maxIndex.length; i++) {
        if (colors[i] === matchColor) count++;
    }
    return count;
}



/**
 * Averages the equivalent color values in a given array of colors.
 *
 * @exports colorAvg
 * @param {[String]} colors An array of colors
 * @param {String} maxIndex Maximum array index value to count.
*/
export function colorAvg(colors, maxIndex) {
    if (isNullish(colors)) return null;

    const colorScore = (color) => {
        if (isNullish(color)) return null;
        if (color === "green") return 2;
        if (color === "grey") return 1;
        if (color === "yellow") return 0;
        if (color === "red") return -1;
        return null;
    }

    let colorValues = [];
    for (let i = 0; i < maxIndex; i++) {
        colorValues.push(colorScore(colors[i]));
    }

    let sum = arraySum(colorValues);
    let average = isNullish(sum) ? null : sum / maxIndex;
    return average;
}



/**
 * Computes the percentage a value is compared to the comparison value.
 *
 * @exports percentValue
 * @param {Number} value Value.
 * @param {Number} comparison Comparison to compare value with.
*/
export function percentValue(value, comparison) {
    if (isNullish(value) || isNullish(comparison)) return null;
    if (value === 0) return 0;
    return Math.round(((value / comparison) * 100) * 100) / 100;
}



/**
 * Computes the percent difference between before and after values for instances, compared to the before value.
 * Compared to getPercentDifference(), this uses an absolute value for the divisor.
 * Returns a null of either values are null.
 * Used by bookingDataController.
 *
 * @exports percentDiff
 * @param {number} value1 before value
 * @param {number} value2 after value
 * @return {number} Percent difference
*/
export function percentDiff(value1, value2) {
    if ((value1 === null) || (value2 === null)) return null;
    if (value2 === 0) return 100;
    return ((value1 - value2) / Math.abs(value2)) * 100;
};



/**
 * Retrieves the price recommendation definition, given demand forecast, price forecast, and finals forecast colors.
 *
 * @exports priceRecoDefn
 * @param {String} forecastColor Demand forecast color.
 * @param {String} priceColor Price forecast color.
 * @param {String} finalsColor Finals forecast color.
*/
export function priceRecoDefn(forecastColor, priceColor, finalsColor) {
    if (isNullish(forecastColor) || isNullish(priceColor) || isNullish(finalsColor)) return null;

    switch (forecastColor) {
        case "green":
            switch (priceColor) {
                case "green":
                    switch (finalsColor) {
                        case "green":
                            return "flat";
                        case "grey":
                            return "flat";
                        case "yellow":
                            return "decrease price to grey";
                        case "red":
                            return "decrease price to yellow";
                        default:
                            return null;
                    }
                case "grey":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to green";
                        case "grey":
                            return "increase price to green";
                        case "yellow":
                            return "flat";
                        case "red":
                            return "decrease price to yellow";
                        default:
                            return null;
                    }
                case "yellow":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to green";
                        case "grey":
                            return "increase price to green";
                        case "yellow":
                            return "increase price to grey";
                        case "red":
                            return "flat";
                        default:
                            return null;
                    }
                case "red":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to green";
                        case "grey":
                            return "increase price to green";
                        case "yellow":
                            return "increase price to grey";
                        case "red":
                            return "increase price to yellow";
                        default:
                            return null;
                    }
                default:
                    return null;
            }
        case "grey":
            switch (priceColor) {
                case "green":
                    switch (finalsColor) {
                        case "green":
                            return "flat";
                        case "grey":
                            return "decrease price to grey";
                        case "yellow":
                            return "decrease price to grey";
                        case "red":
                            return "decrease price to yellow";
                        default:
                            return null;
                    }
                case "grey":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to green";
                        case "grey":
                            return "flat";
                        case "yellow":
                            return "flat";
                        case "red":
                            return "decrease price to yellow";
                        default:
                            return null;
                    }
                case "yellow":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to green";
                        case "grey":
                            return "increase price to grey";
                        case "yellow":
                            return "increase price to grey";
                        case "red":
                            return "flat";
                        default:
                            return null;
                    }
                case "red":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to green";
                        case "grey":
                            return "increase price to grey";
                        case "yellow":
                            return "increase price to grey";
                        case "red":
                            return "increase price to yellow";
                        default:
                            return null;
                    }
                default:
                    return null;
            }
        case "yellow":
            switch (priceColor) {
                case "green":
                    switch (finalsColor) {
                        case "green":
                            return "decrease price to grey";
                        case "grey":
                            return "decrease price to yellow";
                        case "yellow":
                            return "decrease price to yellow";
                        case "red":
                            return "decrease price to red";
                        default:
                            return null;
                    }
                case "grey":
                    switch (finalsColor) {
                        case "green":
                            return "flat";
                        case "grey":
                            return "decrease price to yellow";
                        case "yellow":
                            return "decrease price to yellow";
                        case "red":
                            return "decrease price to red";
                        default:
                            return null;
                    }
                case "yellow":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to grey";
                        case "grey":
                            return "flat";
                        case "yellow":
                            return "flat";
                        case "red":
                            return "decrease price to red";
                        default:
                            return null;
                    }
                case "red":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to grey";
                        case "grey":
                            return "increase price to yellow";
                        case "yellow":
                            return "increase price to yellow";
                        case "red":
                            return "flat";
                        default:
                            return null;
                    }
                default:
                    return null;
            }
        case "red":
            switch (priceColor) {
                case "green":
                    switch (finalsColor) {
                        case "green":
                            return "decrease price to grey";
                        case "grey":
                            return "decrease price to yellow";
                        case "yellow":
                            return "decrease price to red";
                        case "red":
                            return "decrease price to red";
                        default:
                            return null;
                    }
                case "grey":
                    switch (finalsColor) {
                        case "green":
                            return "flat";
                        case "grey":
                            return "decrease price to yellow";
                        case "yellow":
                            return "decrease price to red";
                        case "red":
                            return "decrease price to red";
                        default:
                            return null;
                    }
                case "yellow":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to grey";
                        case "grey":
                            return "flat";
                        case "yellow":
                            return "decrease price to red";
                        case "red":
                            return "decrease price to red";
                        default:
                            return null;
                    }
                case "red":
                    switch (finalsColor) {
                        case "green":
                            return "increase price to grey";
                        case "grey":
                            return "increase price to yellow";
                        case "yellow":
                            return "flat";
                        case "red":
                            return "flat";
                        default:
                            return null;
                    }
                default:
                    return null;
            }
        default:
            return null;
    }
}



/**
 * Computes the percent adjustment value to target color, given the price recommendation definition.
 *
 * @exports percentAdjustment
 * @param {String} definition Price recommendation definition.
 * @param {Object} comparison Comparison data object.
*/
export function percentAdjustment(definition, comparison) {
    if (isNullish(definition) || isNullish(comparison)) return null;

    let def = definition.toString();
    let percent_adjustment = null;

    let weight_red = comparison.red;
    let weight_yellow = comparison.yellow;
    let weight_grey = comparison.grey;
    let weight_green = comparison.green;

    let avg_threshold_red = weight_red;
    let avg_threshold_yellow = (weight_yellow + (weight_grey - 0.01)) / 2;
    let avg_threshold_grey = (weight_grey + (weight_green - 0.01)) / 2;
    let avg_threshold_green = weight_green + avg_threshold_grey;

    if (def.includes("to red")) percent_adjustment = avg_threshold_red;
    if (def.includes("to yellow")) percent_adjustment = avg_threshold_yellow;
    if (def.includes("to grey")) percent_adjustment = avg_threshold_grey;
    if (def.includes("to green")) percent_adjustment = avg_threshold_green;

    return Math.round((percent_adjustment / 100) * 100) / 100;
}



/**
 * Computes the number of arrows for the price recommendation.
 *
 * @exports arrowQty
 * @param {Number} priceRecommendationPercent Price recommendation percentage value.
 * @param {Object} comparison Comparison data object
*/
export function arrowQty(priceRecommendationPercent, comparison, definition = null) {
    if (isNullish(priceRecommendationPercent) || isNullish(comparison)) return null;
    if ((definition !== null) && (definition.toString().toLowerCase() === "flat")) return 0;

    let arrow_amount = 0;
    if (priceRecommendationPercent >= comparison.good.level_1) arrow_amount = 1;
    if (priceRecommendationPercent >= comparison.good.level_2) arrow_amount = 2;
    if (priceRecommendationPercent >= comparison.good.level_3) arrow_amount = 3;
    if (priceRecommendationPercent <= comparison.bad.level_1) arrow_amount = -1;
    if (priceRecommendationPercent <= comparison.bad.level_2) arrow_amount = -2;
    if (priceRecommendationPercent <= comparison.bad.level_3) arrow_amount = -3;

    return arrow_amount;
}



/**
 * Computes the arrow direction for the price recommendation.
 *
 * @exports arrowDirection
 * @param {Number} priceRecommendationPercent Price recommendation percentage value.
 * @param {Object} comparison Comparison data object
*/
export function arrowDirection(priceRecommendationPercent, comparison, definition = null) {
    if (isNullish(priceRecommendationPercent) || isNullish(comparison)) return null;
    if ((definition !== null) && (definition.toString().toLowerCase() === "flat")) return "none";

    let arrow_direction = "none";
    if (priceRecommendationPercent >= comparison.good.level_1) arrow_direction = "up";
    if (priceRecommendationPercent <= comparison.bad.level_1) arrow_direction = "down";

    return arrow_direction;
}