import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Exit } from "Icons";
import AlgorithmModalDaily from "./Daily";
import AlgorithmModalAggregate from "./Aggregate";
import AlgorithmModalSettings from "./Settings";
import AlgorithmModalMatrix from "./Matrix";
import TabButton from "elements/Button/Tab";
import { NavWithIndicator } from "../../DashboardModal/components/StayDateNav";

import constants from "constants.js";
import { isNullOrUndefined } from 'utils';


/**
 * Algorithm Modal.
 *
 * @param {Boolean} visible Display or hide the algorithm modal.
 * @return {Component}
*/
const AlgorithmModal = ({ visible }) => {
    const dispatch = useDispatch();
    const { algorithmIsDisplayed, sidebarData, toolsSelectedTab, 
        activeCalendarView, sidebarChartDataIsLoading } = useSelector(state => state.mainReducer);
    const { loading:isDashboardLoading } = useSelector(state => state.dashboard);
    const isDaily = activeCalendarView === "daily" || activeCalendarView === "custom";

    const renderTabs = () => {
        if (isNullOrUndefined(sidebarData)) return null;
        const versionSource = isDaily? sidebarData.algorithm_breakdown: sidebarData.algorithm_aggregate
        const version = !isNullOrUndefined(versionSource) ? versionSource.version : null;
        // let toolTabs = ["algorithm", "settings", "matrix"];
        const toolTabs = ["algorithm", "matrix"];

        const getTabCopy = (item) => {
            if (item.includes('algorithm')) {
                item = `algorithm${isNullOrUndefined(version) ? "" : " (" + version + ")"}`;
            }
            return item.replace(/-/g, " ").toUpperCase();
        }


        return (<div style={{ marginBottom: 40 }}>
            {toolTabs.map((item, index) => {
                return (
                    <TabButton text={getTabCopy(item)} isSelected={toolsSelectedTab === item}
                        action={() => dispatch({ type: constants.UPDATE_TOOLS_SELECTED_TAB, value: item })} />
                )
            })}
            <NavWithIndicator disabled={isDashboardLoading || sidebarChartDataIsLoading} />
        </div >)
    }


    const renderAlgorithm = () => {
        switch (toolsSelectedTab) {
            case "algorithm":
                return isDaily? <AlgorithmModalDaily />: <AlgorithmModalAggregate />
            case "settings":
                return <AlgorithmModalSettings />
            case "matrix":
                return <AlgorithmModalMatrix />
            default:
                return null;
        }
    }



    return algorithmIsDisplayed && !isNullOrUndefined(sidebarData)
        ? (
            <div className={`rms-dashboard-modal ${visible ? 'active' : ''}`}>
                <div className="rms-dashboard-modal-container">
                    <div className="rms-dashboard-modal-close">
                        <Exit width="25px" height="25px" className="pointer"
                            onClick={() => {
                                dispatch({ type: constants.SHOW_ALGORITHM, value: false })
                            }} />
                    </div>
                    <div className="rms-dashboard-modal-container-body" style={{ padding: "30px 60px" }}>

                        {renderTabs()}
                        {renderAlgorithm()}

                    </div>
                </div>
            </div>
        )
        : null;
}


AlgorithmModal.propTypes = {
    visible: PropTypes.bool
}

export default AlgorithmModal;
