import React, { useState, useEffect, useRef } from 'react';
import { Select } from 'antd';
import { defaultPickupFilterOptions } from 'utils/calendar';
import './PickupFilter.scss';

const PickupFilter = ({ value, handleChange }) => {

    const [actualValue, setActualValue] = useState(value);

    const getValueInDefaultList = (val) => {
        return defaultPickupFilterOptions.find(opt => opt.value == val);
    }

    const isValueInDefaultList = (value) => {
        return !(getValueInDefaultList(value) === undefined);
    }

    useEffect(() => {
        setActualValue(value);
    }, [value]);

    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    const [customPickupVal, setCustomPickupVal] = useState(!isValueInDefaultList(value)? value * -1 : "");
   
    const setCustomVal = (val) => {
        const customPickupActualValue = val > 0 
            ? (val * -1).toString()
            : defaultPickupFilterOptions[0].value; 
        
        setActualValue(customPickupActualValue);
        setCustomPickupVal(val);
        handleChange(customPickupActualValue);
    }

    const selectOnChange = (val) => {
        setActualValue(val);
        handleChange(val);
    }

    return (
        <div className="rms-select-filter" style={{ zIndex: 1 }}>
            <Select
                value={actualValue}
                open={showDropdownMenu}
                popupMatchSelectWidth={false}
                onDropdownVisibleChange={(val) => { setShowDropdownMenu(val); }}
                onChange={selectOnChange}
                labelRender={({ label, value }) => label?? `${value * -1} Days`}
                suffixIcon={null}
                dropdownRender={menu => {
                    return (
                        <div className="dropdown-pickup-filter">
                            {menu}
                            <div className="dropdown-pickup-filter-custom-container">
                                <input 
                                    className='dropdown-pickup-filter-custom-field'
                                    type="number"
                                    value={customPickupVal} 
                                    min={1}
                                    onChange={(e) => setCustomVal(e.target.value)} 
                                    onBlur={(e) => setCustomPickupVal("")}
                                    onKeyDown={(e) => {
                                        if (e.code === 'Enter') setShowDropdownMenu(false);
                                        e.stopPropagation()
                                    } }
                                ></input>
                            </div>
                        </div>
                    )
                }}
            >
                {defaultPickupFilterOptions.map((opt, index) => <Select.Option title={null} key={index} value={opt.value}>{opt.name}</Select.Option>)}
            </Select>
        </div>
    )
}

export default PickupFilter;