import React from 'react';
import { Radio } from 'antd';

export default ({ name, inputClass, value, disabled, items = [], onChange }) => {
    const { Group } = Radio;

    return (
    <div className="form-field-input-div">
        <Group
            className={`form-field-input form-field-radio-group ${inputClass}`} disabled={disabled} value={value || ''}
            onChange={(e) =>  { typeof onChange == "function" && onChange(name, e.target.value)}}
        >
        {items.length > 0 && items.map((item) => {
            return (
                <Radio className={`form-field-radio ${item.class? item.class: ''}`} key={item.id} value={item.id}>
                    {item.displayText}
                </Radio>
            );
        })}
        </Group>
    </div>
    );
}