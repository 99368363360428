import validateForm from "../../../Forms/validateForm";

export const pickup_fields = [
    {
        key: "pickup_avg_dow",
        label: "Rolling Average DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_custom_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 25,
    },
    {
        key: "pickup_avg",
        label: "Rolling Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_custom_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_sdly",
        label: "Same day last year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_trend_avg_dow",
        label: "Rolling Average DOW Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_sdly", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_trend_avg",
        label: "Rolling Average Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_trend_sdly",
        label: "Same Day Last Year Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pickup_custom_avg_dow",
        label: "Custom Average DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 0,
    },
    {
        key: "pickup_custom_avg",
        label: "Custom Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 0
    },
    {
        key: "pickup_trend_custom_avg_dow",
        label: "Custom Average DOW Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_avg", "pickup_trend_custom_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 0
    },
    {
        key: "pickup_trend_custom_avg",
        label: "Custom Average Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pickup_avg_dow", "pickup_custom_avg_dow", "pickup_avg", "pickup_custom_avg", "pickup_sdly", "pickup_trend_avg_dow", "pickup_trend_custom_avg_dow", "pickup_trend_avg", "pickup_trend_sdly"],
        type: "number",
        initialValue: 0
    },
];

export const pace_fields = [
    {
        key: "pace_avg_dow",
        label: "Rolling Average DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_custom_avg_dow", "pace_avg", "pace_custom_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 25,
    },
    {
        key: "pace_avg",
        label: "Rolling Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_custom_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_sdly",
        label: "Same day last year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_avg", "pace_custom_avg", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_trend_avg_dow",
        label: "Rolling Average DOW Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_avg", "pace_custom_avg", "pace_sdly", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_trend_avg",
        label: "Rolling Average Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_avg", "pace_custom_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_trend_sdly",
        label: "Same day last year trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_avg", "pace_custom_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_custom_avg"],
        type: "number",
        initialValue: 15
    },
    {
        key: "pace_custom_avg_dow",
        label: "Custom Average DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_avg", "pace_custom_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 0,
    },
    {
        key: "pace_custom_avg",
        label: "Custom Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 0
    },
    {
        key: "pace_trend_custom_avg_dow",
        label: "Custom Average DOW Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_avg", "pace_custom_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_avg", "pace_trend_custom_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 0
    },
    {
        key: "pace_trend_custom_avg",
        label: "Custom Average Trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["pace_avg_dow", "pace_custom_avg_dow", "pace_avg", "pace_custom_avg", "pace_sdly", "pace_trend_avg_dow", "pace_trend_custom_avg_dow", "pace_trend_avg", "pace_trend_sdly"],
        type: "number",
        initialValue: 0
    },
];

export const forecast_fields = [
    {
        key: "forecast_pickup",
        label: "Pickup",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["forecast_pace", "forecast_finals"],
        type: "number",
        initialValue: 50,
    },
    {
        key: "forecast_pace",
        label: "Pace",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["forecast_pickup", "forecast_finals"],
        type: "number",
        initialValue: 50,
    },
    {
        key: "forecast_finals",
        label: "Finals",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["forecast_pickup", "forecast_pace"],
        type: "number",
        initialValue: 0,
    }
];

export const price_fields = [
    {
        key: "price_avg_dow",
        label: "Rolling Average DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_custom_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 15,
    },
    {
        key: "price_avg",
        label: "Rolling Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 15
    },
    {
        key: "price_sdly",
        label: "Same day last year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_custom_avg", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 20
    },
    {
        key: "price_remaindays_avg_dow",
        label: "Remaining Days Rolling AVG DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 15
    },
    {
        key: "price_remaindays_avg",
        label: "Remaining Days Rolling AVG",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 15
    },
    {
        key: "price_remaindays_sdly",
        label: "Remaining Days SDLY",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_competitor_pricing"],
        type: "number",
        initialValue: 20
    },
    {
        key: "price_competitor_pricing",
        label: "Competitor pricing",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly"],
        type: "number",
        initialValue: 0
    },
    {
        key: "price_custom_avg_dow",
        label: "Custom Average DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 0,
    },
    {
        key: "price_custom_avg",
        label: "Custom Average",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 0
    },
    {
        key: "price_remaindays_custom_avg_dow",
        label: "Remaining Days Custom AVG DOW",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_avg", "price_remaindays_custom_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 0
    },
    {
        key: "price_remaindays_custom_avg",
        label: "Remaining Days Custom AVG",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["price_avg_dow", "price_custom_avg_dow", "price_avg", "price_custom_avg", "price_sdly", "price_remaindays_avg_dow", "price_remaindays_custom_avg_dow", "price_remaindays_avg", "price_remaindays_sdly", "price_competitor_pricing"],
        type: "number",
        initialValue: 0
    },
];

export const finals_fields = [
    {
        key: "finals_avg_dow",
        label: "Rolling Average DOW Occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_sdly", "finals_custom_avg_dow", "finals_avg", "finals_custom_avg", "finals_otb"],
        type: "number",
        initialValue: 20,
    },
    {
        key: "finals_avg",
        label: "Rolling Average Occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_sdly", "finals_avg_dow", "finals_custom_avg_dow", "finals_custom_avg", "finals_otb"],
        type: "number",
        initialValue: 10,
    },
    {
        key: "finals_sdly",
        label: "Same Day Last Year Occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_avg_dow", "finals_custom_avg_dow", "finals_avg", "finals_custom_avg", "finals_otb"],
        type: "number",
        initialValue: 70,
    },
    {
        key: "finals_otb",
        label: "On the books",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_sdly", "finals_avg_dow", "finals_custom_avg_dow", "finals_avg", "finals_custom_avg"],
        type: "number",
        initialValue: 0,
    },
    {
        key: "finals_custom_avg_dow",
        label: "Custom Average DOW Occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_sdly", "finals_avg_dow", "finals_avg", "finals_custom_avg", "finals_otb"],
        type: "number",
        initialValue: 0,
    },
    {
        key: "finals_custom_avg",
        label: "Custom Average Occ",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["finals_sdly", "finals_avg_dow", "finals_custom_avg_dow", "finals_avg", "finals_otb"],
        type: "number",
        initialValue: 0,
    },
]

export const days_out_fields = [
    {
        key: "max_min_days_out",
        label: "Days Out Greater Than",
        placeholder: "",
        validation: ["greaterThanEqualToField"],
        greaterThanEqualToField: "min_max_days_out",
        type: "number",
        initialValue: 120,
    },
    {
        key: "min_max_days_out",
        label: "Days Out Less Than",
        placeholder: "",
        validation: ["lessThanEqualToField"],
        lessThanEqualToField: "max_min_days_out",
        type: "number",
        initialValue: 30,
    }
]

export const getAllForecastWeightsFields = () => {
    return [...pickup_fields, ...pace_fields, ...forecast_fields, ...price_fields, ...finals_fields];
}

export const transformData = (weights = null) => {
    const data = {};
    
    if (weights && Object.keys(weights).length > 0) {
        const { pickup = null, pace = null, forecast = null, price = null, finals = null } = weights;
        
        pickup_fields.forEach(field => {
            data[field.key] = pickup[field.key.replace('pickup_', '')];
        });
        pace_fields.forEach(field => {
            data[field.key] = pace[field.key.replace('pace_', '')];
        });
        forecast_fields.forEach(field => {
            data[field.key] = forecast[field.key.replace('forecast_', '')];
        });
        price_fields.forEach(field => {
            data[field.key] = price[field.key.replace('price_', '')];
        });
        finals_fields.forEach(field => {
            data[field.key] = finals[field.key.replace('finals_', '')];
        });

    } else {
        getAllForecastWeightsFields().forEach(field => {
            data[field.key] = field.initialValue === 0 ? 0 : field.initialValue;
        });
    }
    return data;
}

export const withGroupError = (errors, keys) => {
    let withError = [];

    keys.forEach((key) => {
        if (errors[key]) withError.push(errors[key]);
    });

    return withError[0];
};

export const validateValues = (values) => {
    return validateValuesWithFields(values, getAllForecastWeightsFields());
}

export const validateValuesWithFields = (values, fields) => {
    return validateForm(values, fields);
}