import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import "./StackedChart.scss";

const StackedChart = ({ 
    data = [], 
    width = "100%", 
    height = "100%", 
    chartWidth = null,
    chartHeight = null,
    mainTitle = "", 
    vTitle = "", 
    xTitle = "",
    customBarColors = [],
    colors = [],
    useViewWindow = true,
}) => {

    const getViewWindow = (numInterval) => {
        if (!useViewWindow) return {};
        const values = [];
        data.forEach((item, index) => {
            if (index === 0) return;
            values.push(item.reduce((total, val, index) => {
                return index % 2 === 0? total: total += val;
            }, 0));
        });
        
        const minVal = Math.min(...values);
        const maxVal = Math.max(...values);

        const getNearestValue = (type, val) => {
            if (val === 0) return 0;
            const powerOf10s = [];
            for(let powerOf10 = 1; powerOf10 <= Math.abs(val); powerOf10 *= 10) {
                powerOf10s.push(powerOf10);
            }
            const powerOf10 = powerOf10s[powerOf10s.length - 1];
            if (powerOf10s.includes(val)) return val;
            if (type === "lowest") {
                if (powerOf10 === 10) return Math.floor((val / powerOf10)) * powerOf10;
                return Math.floor((val / (powerOf10 / 10)) - 1) * powerOf10 / 10;
            }
            if (type === "highest") {
                if (powerOf10 === 10) return Math.floor((val / powerOf10) + 1) * powerOf10;
                return Math.floor((val / (powerOf10 / 10)) + 1) * powerOf10 / 10;
            }
        }

        const minRange = getNearestValue("lowest", minVal);
        const maxRange = getNearestValue("highest", maxVal);
        // Ticks
        // const valInterval = (maxRange - minRange) / numInterval;
        // return [...Array(5).keys()].map(key => minRange + (key * (valInterval)));
        // Window
        return {
            viewWindowMode: "explicit",
            viewWindow: { min: minRange, max: maxRange }
        }
    }

    return <div className="rms-stacked-chart">
        <span className="rms-stacked-chart-title">{mainTitle}</span>
        <span className="rms-stacked-chart-vtitle">{vTitle}</span>
        <Chart
            chartType="ColumnChart"
            width={width}
            height={height}
            data={data}
            options={{
                fontName: "Titillium Web",
                isStacked: "absolute",
                chartArea: { 
                    width: chartWidth?? width - 50,
                    height: chartHeight?? height - 50,
                    left: 50
                },
                legend: { position: "none" },
                colors: colors,
                bar: { groupWidth: "80%" },
                hAxis: { textStyle: { color: "#282F5B" } },
                vAxis: { textStyle: { color: "#282F5B" } },
                tooltip: { isHtml: true }
            }}
        />
        <span className="rms-stacked-chart-xtitle">{xTitle}</span>
    </div>
}

export default StackedChart;

// const customizeData = (data) => {
//     const newData = [];
//     const colCount = data[0].length;

//     for(let rowIdx = 0; rowIdx < data.length; rowIdx++) {
//         const newColumns = [...data[rowIdx]];
//         let barColorIdx = 0;
//         for(let colIdx = 1; colIdx < colCount ; colIdx++) {
            
//             if (rowIdx === 0) {
//                 if (customBarColors.length > 0) {
//                     newColumns.splice(colIdx * 2, 0, { role: "style" });
//                 }
//                 continue;
//             }
    
//             let style = "";
    
//             // Bar Colors - repetitive.
//             if (customBarColors.length > 0) {
//                 if (barColorIdx >= customBarColors.length) barColorIdx = 0;
//                 style += `color: ${customBarColors[barColorIdx]};stroke-width: 2;stroke-color:${customBarColors[barColorIdx]}`;
//                 newColumns.splice(colIdx * 2, 0, style);
//                 barColorIdx++;
//             }
    
//             // Other Bar Config here...
//         }
//         newData.push(newColumns);
//     }

//     return newData;
// }