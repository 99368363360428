import * as React from "react";

function SvgAddFilled(props) {
    return (
        <svg viewBox="0 0 100 100" {...props}>
            <g data-name="Layer 1">
                <circle cx={50} cy={50} r={47.5} fill="#67b489" />
                <path
                    d="M30.41 54.62V44.9h13.7V31.28h9.72V44.9h13.85v9.72H53.83V68.4h-9.72V54.62z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default SvgAddFilled;
