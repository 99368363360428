import React from "react";
import { useDispatch } from "react-redux";

import ListHeader from "elements/Text/ListHeader";
import IconButton from "elements/Button/Icon";

import constants from "../../constants";
import { isNullOrUndefined } from "utils";
import { sortArray } from "utils/data";


/**
 * Room types list component.
 *
 * @param {[Object]} roomtypes List of room types data object.
 * @param {String} partnerCode Partner code.
 * @param {Object} user Rev&You user data object.
 * @param {Function} onAdd Event action after clicking the Add Integration Partner button.
 * @param {Function} onView Event action after clicking the View Integration Partner button.
 * @param {Function} onEdit Event action after clicking the Edit Integration Partner button.
 * @param {Function} onDelete Event action after clicking the Delete Integration Partner button.
 * @return {Component} Room types list component.
*/
const RoomTypesList = ({ roomtypes, partnerCode, user, onAdd, onView, onEdit, onDelete }) => {
    const dispatch = useDispatch();

    let hasRoomTypes = roomtypes && Array.isArray(roomtypes) && roomtypes.length > 0;
    let isAdmin = user.type === "ope_admin";
    let isHotelUser = user.type === "hotel_user";
    let hasUpdateAccess = isHotelUser || isAdmin;


    const updateListItemInView = (roomtype) => {
        dispatch({
            type: constants.UPDATE_LIST_ITEM_IN_VIEW,
            value: roomtype
        });
    }

    const handleEditRoomType = (roomtype) => {
        updateListItemInView(roomtype);
        onEdit();
    }

    const handleDeleteRoomType = (roomtype) => {
        updateListItemInView(roomtype);
        onDelete();
    }



    return (
        <div>
            <ListHeader headerCopy="Room Types" component={
                <IconButton type="add" hasAccess={hasUpdateAccess} action={onAdd} width="25px" />
            } />

            <table className="forms-table">
                <thead>
                    <tr style={{ marginBottom: "8px" }}>
                        <th>Property Room Code</th>
                        <th>Property Room Name</th>
                        <th>Rev&You Room Code</th>
                        <th>Rev&You Room Name</th>
                        <th style={{ textAlign: "right" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasRoomTypes && sortArray(roomtypes, "room_type").map((rt, index) => {
                        let mapping = !isNullOrUndefined(partnerCode)
                            ? rt.mappings.filter(m => m.partner_code === partnerCode)[0]
                            : null;
                        let isUMP = rt.room_type_code === "UMP";

                        return !isNullOrUndefined(partnerCode) && (
                            <tr key={`${rt.room_type_code}-${rt.room_type}-${index}`}>
                                <td>{rt.room_type_code}</td>
                                <td>{rt.room_type}</td>
                                <td>{mapping.room_type_code}</td>
                                <td>{mapping.room_type_name}</td>
                                <td style={{ textAlign: "right" }}>
                                    {!isUMP && <IconButton type="view" hasAccess={isHotelUser} action={onView} />}
                                    {!isUMP && <IconButton type="edit" hasAccess={isAdmin}
                                        action={() => handleEditRoomType(rt)} />}
                                    {!isUMP && <IconButton type="delete" hasAccess={isAdmin}
                                        action={() => handleDeleteRoomType(rt)} />}
                                </td>
                            </tr>
                        )
                    })}

                    {!hasRoomTypes && <tr>
                        <td>No room types found</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
};

export default RoomTypesList;
