import React from 'react';
import dayjs from 'dayjs';
import { Dashboard, Add, Edit } from "Icons";

const useTutorial = () => {
    const getTutorialObject = () => {
        return  {
            avatar: {
                height: "40px",
                width: "50px",
                transform: "translate(-50%, -50%)",
                orientation: "left",
                boxWidth: 300,
                content: (
                    <p>
                    Navigating to other sections of Rev&You can be done from here. You can
                    update your account information, go to the hotel list, or go to the user
                    management section. You can logout from Rev&You via this section as
                    well.
                    </p>
                ),
            },
            search: {
                height: "50px",
                width: "270px",
                transform: "translate(-50%, -50%)",
                orientation: "left",
                boxWidth: 300,
                content: (
                    <p>
                    Use the search function to find your desired property. This is very
                    useful when you are managing a group of hotels.
                    </p>
                ),
            },
            other: {
                height: "50px",
                width: "88px",
                transform: "translate(-50%, -50.1%)",
                orientation: "left",
                boxWidth: 400,
                content: (
                    <div>
                    <p>These are the actions you can perform per hotel:</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex" }}>
                        <Dashboard width="20px" height="20px" className="pointer" />
                        </div>
                        <span style={{ margin: "0 10px" }}>-</span>
                        <span style={{ whiteSpace: "pre-wrap", lineHeight: "18px" }}>
                        This icon takes you to the dashboard of the hotel{" "}
                        </span>
                    </div>
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex" }}>
                        <Add width="20px" height="20px" className="pointer" />
                        </div>
                        <span style={{ margin: "0 10px" }}>-</span>
                        <span style={{ whiteSpace: "pre-wrap", lineHeight: "18px" }}>
                        This icon will display the upload feature where you can upload the
                        latest data for your hotel{" "}
                        </span>
                    </div>
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex" }}>
                        <Edit width="20px" height="20px" className="pointer" />
                        </div>
                        <span style={{ margin: "0 10px" }}>-</span>
                        <span style={{ whiteSpace: "pre-wrap", lineHeight: "18px" }}>
                        Clicking this icon will lead you to the corresponding hotel's
                        information section{" "}
                        </span>
                    </div>
                    </div>
                ),
            },
            filter: {
                height: "50px",
                width: "700px",
                transform: "translate(-50.0%, -50.0%)",
                orientation: "bottom",
                boxWidth: 900,
                next: "filter1",
                content: (
                    <div>
                    <p>
                        This section shows what filters Rev&You uses to display data on the
                        calendar. Just click the arrow buttons to change the filters according
                        to your preference.
                    </p>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/illustration01.png`}
                            alt="date"
                            style={{ height: "30px", transform: "translateY(6px)" }}
                        />
                        <span style={{ margin: "0 10px" }}>-</span>
                        <p style={{ margin: 0 }}>
                            “On the books as-of” date refers to when data was observed; this
                            defaults to today’s date
                        </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/illustration02.png`}
                            alt="date"
                            style={{ height: "30px", transform: "translateY(6px)" }}
                        />
                        <span style={{ margin: "0 10px" }}>-</span>
                        <p style={{ margin: 0 }}>
                            “Pick up from” refers to how many days of pick up is presented;
                            this defaults to 7 days (1 week)
                        </p>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/illustration03.png`}
                            alt="date"
                            style={{ height: "30px", transform: "translateY(6px)" }}
                        />
                        <span style={{ margin: "0 10px" }}>-</span>
                        <p style={{ margin: 0 }}>
                            “Pace from” refers to what day do you want to compare your pace
                            to; this defaults to Same Day Last Year (SDLY)
                        </p>
                        </div>
                    </div>
                    </div>
                ),
            },
            dataTypeView: {
                height: "50px",
                width: "350px",
                transform: "translate(-50%, -50.1%)",
                orientation: "bottom",
                boxWidth: 350,
                content: (
                    <p>
                    The default view on the Dashboard is the Overview calendar, which shows
                    the lowest available rate and on-the-books room sold (along with its
                    corresponding ADR) based on the as of date. From the Overview calendar,
                    you can switch to Pickup, Pace, or Price calendar.
                    </p>
                ),
            },
            filter2: {
                height: "50px",
                width: "190px",
                transform: "translate(-50%, -50.1%)",
                orientation: "left",
                boxWidth: 300,
                next: "filter3",
                content: (
                    <div>
                    <p>
                        It is entirely up to you how you want to view your revenue management
                        data. From the calendar view, you can switch to table view.
                    </p>
                    <p>
                        Select your preferred view to make revenue management analysis easier
                        for you.
                    </p>
                    </div>
                ),
            },
            filter1: {
                height: "50px",
                width: "270px",
                transform: "translate(-50%, -50%)",
                next: "filter2",
                orientation: "left",
                boxWidth: 300,
                content: (
                    <p>
                    You have the option to view aggregated data per day, per week, or per
                    month. Just click on these tab headers to switch to your desired views.
                    </p>
                ),
            },
            filter3: {
                height: "40px",
                width: "80px",
                transform: "translate(-50%, -50%)",
                orientation: "left",
                boxWidth: 300,
                content: (
                    <p>
                    There are times when you don't want to view ADR data to focus solely on
                    occupancy. Toggle the switch to show or hide ADR at will.
                    </p>
                ),
            },
            hotelMenu: {
                height: "40px",
                width: "165px",
                transform: "translate(-50%, -50%)",
                orientation: "left",
                boxWidth: 300,
                content: (
                    <p>
                    Click on the hotel name to quickly access other modules related to
                    managing the property you are viewing. You may also use this dropdown to
                    quickly switch to another hotel that you manage.
                    </p>
                ),
            },
            currentDate: {
                height: "130px",
                width: "180px",
                transform: "translate(-50%, -50.05%)",
                orientation: dayjs().isoWeekday() < 4 ? "right" : "left",
                boxWidth: 350,
                next: "currentDate2",
                calloutStyles: {
                    box: {
                    transform: "translateY(-50px)",
                    },
                    arrow: {
                    transform: "translateY(-80px)",
                    },
                },
                content: (
                    <div>
                    <p>
                        Notice that each of the check-in date displays a colored bar. This is
                        the demand forecast indicator.
                    </p>
                    <div style={{ height: "10px" }} />
                    <strong style={{ color: "#51b68a" }}>Green</strong>: Good
                    <br />
                    <strong style={{ color: "#808285" }}>Gray</strong>: Average
                    <br />
                    <strong style={{ color: "#f28f3c" }}>Yellow</strong>: Below Average
                    <br />
                    <strong style={{ color: "#ed474b" }}>Red</strong>: Bad
                    </div>
                ),
            },
            currentDate2: {
                height: "130px",
                width: "180px",
                transform: "translate(-50%, -50.05%)",
                orientation: dayjs().isoWeekday() < 4 ? "right" : "left",
                boxWidth: 350,
                next: "currentDate3",
                calloutStyles: {
                    box: {
                    transform: "translateY(-80px)",
                    },
                    arrow: {
                    transform: "translateY(-80px)",
                    },
                },
                content: (
                    <div>
                    <p>
                        More importantly, pay attention to the directional arrows displayed.
                        This is Rev&You's pricing recommendation.
                    </p>
                    <p>
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration04.png`}
                        alt="arrowup"
                        style={{ height: "15px", marginRight: "10px" }}
                        />
                        --> Increase
                    </p>
                    <p>
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration05.png`}
                        alt="arrowdown"
                        style={{ height: "15px", marginRight: "10px" }}
                        />
                        --> Decrease
                    </p>
            
                    <p>Single arrows --> conservatively adjust price</p>
                    <p>Double arrows --> moderately adjust price</p>
                    <p>Triple arrows --> aggressively adjust price</p>
                    </div>
                ),
            },
            currentDate3: {
                height: "130px",
                width: "180px",
                transform: "translate(-50%, -50.05%)",
                orientation: dayjs().isoWeekday() < 4 ? "right" : "left",
                boxWidth: 350,
                calloutStyles: {
                    box: {
                    transform: "translateY(-50px)",
                    },
                    arrow: {
                    transform: "translateY(-80px)",
                    },
                },
                content: (
                    <p>
                    All dates have their own demand forecast and recommended pricing action.
                    These indicators and recommendations are consistent across all data
                    types: pickup, pace, and price for the same date. Click on any date on
                    the calendar to understand how these were derived.
                    </p>
                ),
            },
            pickupDate: {
                height: "130px",
                width: "180px",
                transform: "translate(-50%, -50.05%)",
                orientation: dayjs().isoWeekday() < 4 ? "right" : "left",
                boxWidth: 350,
                content: (
                    <p>
                    Pickup is the number of room nights booked from the as of date and a
                    previous date (7 days).
                    <div style={{ height: "10px" }} />
                    <strong style={{ color: "#51b68a" }}>Green</strong> indicates good
                    pickup
                    <br />
                    <strong style={{ color: "#808285" }}>Gray</strong> indicates average
                    pickup
                    <br />
                    <strong style={{ color: "#f28f3c" }}>Yellow</strong> indicates below
                    average pickup
                    <br />
                    <strong style={{ color: "#ed474b" }}>Red</strong> indicates bad pickup
                    </p>
                ),
            },
            paceDate: {
                height: "130px",
                width: "180px",
                transform: "translate(-50%, -50.05%)",
                orientation: dayjs().isoWeekday() < 4 ? "right" : "left",
                boxWidth: 350,
                content: (
                    <p>
                    Pace is the difference in room nights booked for the check-in date
                    versus a comparison date (Same Day Last Year or SDLY).
                    <div style={{ height: "10px" }} />
                    <strong style={{ color: "#51b68a" }}>Green</strong> indicates good pace
                    <br />
                    <strong style={{ color: "#808285" }}>Gray</strong> indicates average
                    pace
                    <br />
                    <strong style={{ color: "#f28f3c" }}>Yellow</strong> indicates below
                    average pace
                    <br />
                    <strong style={{ color: "#ed474b" }}>Red</strong> indicates bad pace
                    </p>
                ),
            },
            rateDate: {
                height: "130px",
                width: "180px",
                transform: "translate(-50%, -50.05%)",
                orientation: dayjs().isoWeekday() < 4 ? "right" : "left",
                boxWidth: 350,
                content: (
                    <p>
                    Price is simply your lowest available sell price for a room for the
                    date.
                    <div style={{ height: "10px" }} />
                    <strong style={{ color: "#51b68a" }}>Green</strong> indicates price is
                    high
                    <br />
                    <strong style={{ color: "#808285" }}>Gray</strong> indicates price is
                    average
                    <br />
                    <strong style={{ color: "#f28f3c" }}>Yellow</strong> indicates price is
                    below average
                    <br />
                    <strong style={{ color: "#ed474b" }}>Red</strong> price indicates price
                    is low
                    </p>
                ),
            },
            weeklyCard: {
                height: "430px",
                width: "190px",
                transform: "translate(-50%, -50%)",
                orientation: "right",
                boxWidth: 350,
                content: (
                    <p>
                    Each card shows the weekly aggregate values for pickup, pace, price and
                    on-the-books data. Each week has it's own forecast and pricing
                    recommendation for the entire week.
                    </p>
                ),
            },
            monthlyCard: {
                height: "430px",
                width: "190px",
                transform: "translate(-50%, -50%)",
                orientation: "right",
                boxWidth: 350,
                content: (
                    <p>
                    Each card shows the monthly aggregate values for pickup, pace, price and
                    on-the-books data. Each week has it's own forecast and pricing
                    recommendation for the entire month.
                    </p>
                ),
            },
            table: {
                height: "50px",
                width: "1220px",
                transform: "translate(-50.0%, -50.0%)",
                orientation: "bottom",
                boxWidth: 500,
                content: (
                    <p>
                    Data displayed on the table views match exactly what is seen from the
                    daily calendar, weekly cards, and monthly cards. This provides an option
                    to users who are more comfortable viewing revenue management data in
                    tabular form.
                    </p>
                ),
            },
            uploadModal: {
                height: "301px",
                width: "470px",
                transform: "translate(-50%, -47.6%)",
                orientation: "left",
            },
            downloadTemplate: {
                height: "50px",
                width: "400px",
                transform: "translate(-50.8%, -50%)",
                orientation: "right",
            },
            sidepanel: {
                height: "111vh",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
            },
            chart: {
                height: "200px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 500,
                next: "chart2",
                content: (
                    <div>
                    <p>
                        The booking chart shows the cumulative number of rooms booked and the
                        price changes as the check-in date approaches.
                    </p>
                    <p>
                        You can see more details by clicking on this booking chart thumbnail.
                    </p>
                    </div>
                ),
            },
            chart2: {
                height: "200px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 700,
                next: "chart3",
                content: (
                    <div>
                    <p>
                        Two dates are displayed on the booking chart with two data points
                        each:
                    </p>
                    <div
                        style={{
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration11.png`}
                        alt="chart"
                        style={{ height: "300px" }}
                        />
                    </div>
                    <p>
                        The lines represents movement of price, while the bars represent rooms
                        booked. The left y-axis is the price range, while the right y-axis is
                        the occupancy level. The x-axis represents the number of days before
                        the check-in date.
                    </p>
                    </div>
                ),
            },
            chart3: {
                height: "200px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 700,
                next: "chart4",
                content: (
                    <div>
                    <div
                        style={{
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration11.png`}
                        alt="chart"
                        style={{ height: "300px" }}
                        />
                    </div>
                    <p>
                        The bar graph shows cumulative room nights booked as we approach
                        check-in date. Also we can see the timeline of when we are receiving
                        bookings. In this example, we can see we have more bookings compared
                        to Same Day Last Year (SDLY). We can also see that most of the
                        bookings last year happened closer to the check-in date.
                    </p>
                    </div>
                ),
            },
            chart4: {
                height: "200px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 700,
                content: (
                    <div>
                    <div
                        style={{
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration11.png`}
                        alt="chart"
                        style={{ height: "300px" }}
                        />
                    </div>
                    <p>
                        Meanwhile, the line graph shows movement of price as the check-in date
                        approaches. We can also see that last year's price was increased
                        closer to the date. Since we have more bookings now, we should now be
                        able to increase our price as well.
                    </p>
                    </div>
                ),
            },
            pickup: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                next: "pickup2",
                content: (
                    <p>
                    Pickup is the number of room nights booked from the as of date and a
                    previous date (7 days).
                    <div style={{ height: "10px" }} />
                    <strong style={{ color: "#51b68a" }}>Green</strong> indicates good
                    pickup
                    <br />
                    <strong style={{ color: "#808285" }}>Gray</strong> indicates average
                    pickup
                    <br />
                    <strong style={{ color: "#f28f3c" }}>Yellow</strong> indicates below
                    average pickup
                    <br />
                    <strong style={{ color: "#ed474b" }}>Red</strong> indicates bad pickup
                    </p>
                ),
            },
            pickup2: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                next: "pickup3",
                content: (
                    <div>
                    <p>
                        This box also shows the following information:
                        <div style={{ height: "10px" }} />
                        <ol style={{ marginLeft: "30px" }}>
                        <li>Average day-of-week (DOW) pickup</li>
                        <li>Overall average pickup</li>
                        </ol>
                        <p>
                        The higher the Pick Up is to the averages, the better we are doing.
                        </p>
                    </p>
                    </div>
                ),
            },
            pickup3: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                content: (
                    <div>
                    <div
                        style={{
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration06.png`}
                        alt="pickup"
                        style={{ height: "150px" }}
                        />
                    </div>
                    <p>
                        In this example, we can see that pick up is 60 rooms for the last 7
                        days, with ADR of P3,500. Average is only 30 rooms and Average DOW is
                        only 35 rooms. Therefore, we are picking up almost 2x better which
                        results in a <strong style={{ color: "#51b68a" }}>Green</strong>{" "}
                        indicator.
                    </p>
                    </div>
                ),
            },
            pace: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                next: "pace2",
                content: (
                    <p>
                    Pace is the difference in room nights booked for the check-in date
                    versus a comparison date (Same Day Last Year or SDLY).
                    <div style={{ height: "10px" }} />
                    <strong style={{ color: "#51b68a" }}>Green</strong> indicates good pace
                    <br />
                    <strong style={{ color: "#808285" }}>Gray</strong> indicates average
                    pace
                    <br />
                    <strong style={{ color: "#f28f3c" }}>Yellow</strong> indicates below
                    average pace
                    <br />
                    <strong style={{ color: "#ed474b" }}>Red</strong> indicates bad pace
                    </p>
                ),
            },
            pace2: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                next: "pace3",
                content: (
                    <div>
                    <p>
                        This box also shows the following information:
                        <div style={{ height: "10px" }} />
                        <ol style={{ marginLeft: "30px" }}>
                        <li>Pace to Average day-of-week (DOW) </li>
                        <li>Pace to Overall average</li>
                        </ol>
                        <p>
                        The higher we are paced to last year and averages, the better we are
                        doing.
                        </p>
                    </p>
                    </div>
                ),
            },
            pace3: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                content: (
                    <div>
                    <div
                        style={{
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration07.png`}
                        alt="pace"
                        style={{ height: "150px" }}
                        />
                    </div>
                    <p>
                        In this example, we can see that pace is +20 rooms compared to the
                        same day last year (SDLY), with ADR variance of -P700. Also we are +40
                        rooms to average rooms sold and +35 rooms to the corresponding average
                        rooms sold on the same day-of-week. Therefore, we are pacing ahead of
                        all comparables which results in a{" "}
                        <strong style={{ color: "#51b68a" }}>Green</strong> indicator.
                    </p>
                    </div>
                ),
            },
            sell_rate: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                next: "sell_rate2",
                content: (
                    <p>
                    Price is simply your lowest available sell price for a room for the
                    date.
                    <div style={{ height: "10px" }} />
                    <strong style={{ color: "#51b68a" }}>Green</strong> indicates price is
                    high
                    <br />
                    <strong style={{ color: "#808285" }}>Gray</strong> indicates price is
                    average
                    <br />
                    <strong style={{ color: "#f28f3c" }}>Yellow</strong> indicates price is
                    below average
                    <br />
                    <strong style={{ color: "#ed474b" }}>Red</strong> price indicates price
                    is low
                    </p>
                ),
            },
            sell_rate2: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                next: "sell_rate3",
                content: (
                    <div>
                    <p>
                        This box also shows the following information:
                        <div style={{ height: "10px" }} />
                        <ol style={{ marginLeft: "30px" }}>
                        <li>Price of previous date chosen</li>
                        <li>Price of comparison date</li>
                        </ol>
                        <p>
                        We compare to these metrics to see if our pricing for this date is
                        relatively higher or lower.
                        </p>
                    </p>
                    </div>
                ),
            },
            sell_rate3: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 350,
                calloutStyles: {
                    box: {
                    transform: "translateY(-90px)",
                    },
                    arrow: {
                    transform: "translateY(100px)",
                    },
                },
                content: (
                    <div>
                    <div
                        style={{
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        <img
                        src={`${process.env.PUBLIC_URL}/images/illustration08-v2.png`}
                        alt="pace"
                        style={{ height: "150px" }}
                        />
                    </div>
                    <p>
                        For example, we can see that our current price is 4000. Price from 7
                        days ago is 4500 and Price of Same Day Last Year (SDLY) is 5000.
                        Therefore, we are already priced 500-1000 pesos lower which results in
                        a <strong style={{ color: "#ed474b" }}>Red</strong> indicator.
                    </p>
                    </div>
                ),
            },
            otb: {
                height: "150px",
                width: "300px",
                transform: "translate(-50.0%, -50%)",
                orientation: "left",
                boxWidth: 500,
            },
        };
    }

    return {
        getTutorialObject
    }
}

export default useTutorial;