import constants from "../../constants";


export const property_information = [
    {
        key: "name",
        label: "Name of Property",
        placeholder: "Zeus Palace Hotel",
        validation: ["required"],
        type: "text",
        initialValue: "",
    },
    {
        key: "total_inventory",
        label: "Total Room Inventory",
        placeholder: "0",
        validation: ["required"],
        type: "number",
        initialValue: 0,
    },
    {
        key: "rating",
        label: "Rating",
        placeholder: "",
        validation: [],
        type: "rating",
        initialValue: 0,
    },
    {
        key: "brand",
        label: "Hotel Brand",
        placeholder: "",
        validation: ["required"],
        type: "dropdown",
        initialValue: "",
        dropDownList: [],
        add: true,
    },
    {
        key: "property_management_company",
        label: "Property Management",
        placeholder: "",
        validation: [""],
        type: "text",
        initialValue: "",
    },
    {
        key: "owner",
        label: "Owner",
        placeholder: "",
        validation: [],
        type: "text",
        initialValue: "",
    },
    {
        key: "preferred_location",
        label: "Marketing Location",
        placeholder: "",
        validation: ["required"],
        type: "text",
        initialValue: "",
    },
    {
        key: "country",
        label: "Country",
        placeholder: "",
        validation: ["required"],
        type: "dropdown",
        initialValue: "",
        dropDownList: [],
        add: false,
    },
    {
        key: "province",
        label: "Province",
        placeholder: "",
        validation: [],
        type: "dropdown",
        initialValue: "",
        dropDownList: [],
        add: false,
    },
    {
        key: "city",
        label: "City",
        placeholder: "",
        validation: [],
        type: "dropdown",
        initialValue: "",
        dropDownList: [],
        add: false,
    },
    {
        key: "address",
        label: "Address Line 1",
        placeholder: "",
        validation: ["required"],
        type: "text",
        initialValue: "",
    },
    {
        key: "address_additional",
        label: "Address Line 2",
        placeholder: "",
        validation: [""],
        type: "text",
        initialValue: "",
    },
    {
        key: "postal_code",
        label: "Postal Code",
        placeholder: "",
        validation: ["required"],
        type: "text",
        initialValue: "",
    },
    {
        key: "longitude",
        label: "Longitude",
        placeholder: "",
        validation: ["required"],
        type: "text",
        initialValue: "",
    },
    {
        key: "latitude",
        label: "Latitude",
        placeholder: "",
        validation: ["required"],
        type: "text",
        initialValue: "",
    },
    {
        key: "timezone",
        label: "Timezone",
        placeholder: "",
        validation: ["required"],
        type: "dropdown",
        initialValue: "",
        dropDownList: [],
        add: false,
    },
    {
        key: "currency",
        label: "Currency",
        placeholder: "",
        validation: ["required"],
        type: "dropdown",
        initialValue: "",
        dropDownList: [],
        add: false,
    },
];

export const admin_controls = [
    {
        key: "status",
        label: "Property Status",
        type: "switch",
        initialValue: false,
        validation: [],
        group: "main",
        disabled: true
    },
    {
        key: "segment_status",
        label: "Segment Status",
        type: "switch",
        initialValue: false,
        validation: [],
        group: "main"
    },
    {
        key: "reservations_translation_enabled",
        label: "Reservations View",
        type: "switch",
        initialValue: false,
        validation: [],
        group: "main"
    },
    {
        key: "reservations_fail_safe_upload",
        label: "Fail Safe Upload",
        type: "switch",
        initialValue: false,
        validation: [],
        group: "main"
    },
    {
        key: "chart_days_out",
        label: "Booking Chart Days Out",
        placeholder: "",
        validation: ["required"],
        type: "dropdown",
        initialValue: constants.DAYS_OUT_OPTIONS[0].days_out,
        dropDownList: constants.DAYS_OUT_OPTIONS.map(item => {
            return { _id: item.days_out, name: `${item.days_out} days` }
        }),
        add: false,
        group: "main"
    },
    {
        key: "default_pickup",
        label: "Default Pickup",
        placeholder: "",
        validation: ["required"],
        type: "custom_default_pickup",
        initialValue: "7",
        dropDownList: [
            { _id: 1, name: "1 day" },
            { _id: 3, name: "3 days" },
            { _id: 7, name: "7 days" },
            { _id: 14, name: "14 days" },
            { _id: 28, name: "28 days" }
        ],
        add: false,
        group: "main"
    },
    {
        key: "default_pace",
        label: "Default Pace",
        placeholder: "",
        validation: ["required"],
        type: "dropdown",
        initialValue: "samedaylastyear",
        dropDownList: [
            { _id: "lastyear", name: "Last Year" },
            { _id: "samedaylastyear", name: "Same day last year" },
            { _id: "averageroomsold", name: "Average rooms sold" },
            { _id: "averagedowroomsold", name: "Average DOW rooms sold" }
        ],
        add: false,
        group: "main"
    },
    {
        key: "default_view",
        label: "Default View",
        placeholder: "",
        validation: ["required"],
        type: "dropdown",
        initialValue: "daily",
        dropDownList: [
            { _id: "daily", name: "Daily" },
            { _id: "weekly", name: "Weekly" },
            { _id: "monthly", name: "Monthly" }
        ],
        add: false,
        group: "main"
    },
    {
        key: "average_days",
        label: "Average Days",
        placeholder: "60",
        validation: ["required", "positive", "max730"],
        type: "number",
        initialValue: 60,
        group: "main"
    },
    {
        key: "parser",
        label: "Data Parser",
        placeholder: "",
        validation: [],
        type: "dropdown",
        dropDownList: [],
        add: false,
        group: "integration_settings"
    },
    {
        key: "reservation_cut_off_time",
        label: "PU Cut-Off Time",
        placeholder: "",
        validation: ["required"],
        type: "timepicker",
        initialValue: "00:00:00",
        dropDownList: [],
        add: false,
        group: "main"
    },
    {
        key: "reservation_max_adr",
        label: "Maximum ADR",
        placeholder: "",
        validation: [],
        type: "number",
        initialValue: null,
        dropDownList: [],
        add: false,
        group: "main"
    },
    {
        key: "max_rate",
        label: "Maximum Rate",
        placeholder: "",
        validation: [],
        type: "number",
        initialValue: null,
        dropDownList: [],
        add: false,
        group: "main"
    }

]

export const pickup_treshold = {
    key: "pickup_treshold",
    label: "Pickup Treshold",
    placeholder: "",
    validation: [""],
    type: "number",
    initialValue: 50,
};
export const pickup_rates = [
    {
        key: "avg_dow_pickup",
        label: "Average DOW Pickup",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["avg_pickup", "pickup_trend"],
        type: "number",
        initialValue: 40,
        groupKey: "pickup_rates",
    },
    {
        key: "avg_pickup",
        label: "Average Pickup",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["avg_dow_pickup", "pickup_trend"],
        type: "number",
        initialValue: 25,
        groupKey: "pickup_rates",
    },
    {
        key: "pickup_trend",
        label: "4-Week pickup trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["avg_dow_pickup", "avg_pickup"],
        type: "number",
        initialValue: 35,
        groupKey: "pickup_rates",
    },
];

export const pace_treshold = {
    key: "pace_treshold",
    label: "Pace Treshold",
    placeholder: "",
    validation: [""],
    type: "number",
    initialValue: 20,
};
export const pace_rates = [
    {
        key: "same_day_last_year_pace",
        label: "Same Day Last Year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["overall_avg_dow_pace", "overall_avg_pace", "pace_trend"],
        type: "number",
        initialValue: 50,
    },
    {
        key: "overall_avg_dow_pace",
        label: "Average DOW Pace",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: [
            "same_day_last_year_pace",
            "overall_avg_pace",
            "pace_trend",
        ],
        type: "number",
        initialValue: 10,
    },
    {
        key: "overall_avg_pace",
        label: "Average Pace",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: [
            "same_day_last_year_pace",
            "overall_avg_dow_pace",
            "pace_trend",
        ],
        type: "number",
        initialValue: 10,
    },
    {
        key: "pace_trend",
        label: "4-Week pace trend",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: [
            "same_day_last_year_pace",
            "overall_avg_dow_pace",
            "overall_avg_pace",
        ],
        type: "number",
        initialValue: 30,
    },
];

export const sell_rate_treshold = {
    key: "sell_rate_treshold",
    label: "Price Treshold",
    placeholder: "",
    validation: [""],
    type: "number",
    initialValue: 20,
};
export const sell_rate_rates = [
    {
        key: "same_day_last_year_sell_rate",
        label: "Same Day Last Year",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["avg_rate", "avg_dow_rate"],
        type: "number",
        initialValue: 50,
        groupKey: "sell_rate_rates",
    },
    {
        key: "avg_dow_rate",
        label: "Average DOW Price",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["avg_rate", "same_day_last_year_sell_rate"],
        type: "number",
        initialValue: 25,
        groupKey: "sell_rate_rates",
    },
    {
        key: "avg_rate",
        label: "Average Price",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["avg_dow_rate", "same_day_last_year_sell_rate"],
        type: "number",
        initialValue: 25,
        groupKey: "sell_rate_rates",
    },
];

export const forecast_rates = [
    {
        key: "forecast_pickup",
        label: "Forecast Pickup",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["forecast_pace"],
        type: "number",
        initialValue: 30,
    },
    {
        key: "forecast_pace",
        label: "Forecast Pace",
        placeholder: "",
        validation: ["totalOf100"],
        totalOf100with: ["forecast_pickup"],
        type: "number",
        initialValue: 70,
    },
];

export const add_brand = [
    {
        key: "brand_name",
        label: "Brand Name",
        placeholder: "Enter brand name",
        validation: ["required"],
        type: "text",
        initialValue: "",
    },
    {
        key: "brand_code",
        label: "Brand Code",
        placeholder: "Enter brand code",
        validation: ["required", "3-5char"],
        type: "text",
        initialValue: "",
    },
];
