import * as React from "react";

function SvgReset(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path
        d="M84.91 44.27A35 35 0 0015 46.75v.16a3.66 3.66 0 003.68 3.62h1a3.66 3.66 0 002.58-1.06 3.79 3.79 0 001.09-2.72 26.71 26.71 0 018.9-19.86 26.41 26.41 0 0120.8-6.61 26.73 26.73 0 0123.43 23.43 26.66 26.66 0 01-23 29.44v-3.46a2 2 0 00-3.42-1.42l-7.87 7.87a2 2 0 00-.58 1.41 2 2 0 00.63 1.45l7.87 7.86a2 2 0 001.4.59 2.16 2.16 0 00.78-.15 2 2 0 001.24-1.85v-3.88a35.14 35.14 0 0022.06-10.94 34.73 34.73 0 009.32-26.36z"
        fill="#67b489"
        data-name="Layer 1"
      />
    </svg>
  );
}

export default SvgReset;
