import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const AuthUserContext = React.createContext(null);



const withAuthorization = condition => Component => {
    const WithAuthorizationComponent = props => {
        const permissions = useSelector(state => state.users.permissions);
        return <>{permissions[condition] ? <Component {...props} /> : null}</>;
    };
    return withRouter(WithAuthorizationComponent);
};

export { AuthUserContext, withAuthorization };
