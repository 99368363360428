import { isNullOrUndefined } from '../../utils';

export function getIdToEdit(match) {
    return match? match.params.id: null;
}


export function getSelectedItems(event, availableItems, currentSelectedItems) {
    const newSelectedItems = [];

    for (const currentSelectedItem of currentSelectedItems) {
        newSelectedItems.push(currentSelectedItem);
    }

    switch(event.action) {
        case "add": 
            const { value: selectedItemIds } = event;
            
            for (const selectedItemId of selectedItemIds) {
                const selectedItem = availableItems.find(item => item.id === selectedItemId);
                const currentSelectedItem = currentSelectedItems.find(item => item.id === selectedItem.id);
                // The ID does not exist in all items.
                if (!selectedItem) return;
                // The selected item does not exist in selected items.
                if (!currentSelectedItem) newSelectedItems.push(selectedItem)
            }
            
            break;
        case "delete": 
            const { id: selectedPropertyId } = event;
            newSelectedItems.splice(newSelectedItems.findIndex(item => item.id === selectedPropertyId), 1);           
            break;
    }

    return newSelectedItems;
}

export function getFormFieldDefaultValues(fieldConfigs) {
    const defaultValues = {}
    fieldConfigs.forEach(field => {
        if (field.defaultValue !== undefined) {
            defaultValues[field.name] = field.defaultValue;
        }
    });
    return defaultValues;
}

export function getFormFieldConfigs(name) {
    const fields = [{
        name: "name",
        validations: [{
            type: "required",
            value: true
        }, {
            type: "alphanumeric_with_space"
        }]
    }, {
        name: "email",
        validations: [{
            type: "required",
            value: true
        }]
    }, {
        name: "type",
        defaultValue: "hotel_user",
        validations: [{
            type: "required",
            value: true
        }]
    }];
    
    if (name) return fields.find(field => field.name == name);

    return fields;
}

export function validateAllFields(fieldConfigs, values) {
    const errors = [];
    
    fieldConfigs.forEach(field => {
        const fieldName = field.name;
        const value = values[fieldName];
        
        validate(field, value).forEach(error => {
            errors.push(error);
        });
    })

    return errors;
}

export function validate(fieldConfig, value) {
    if (fieldConfig === undefined) return [];

    const errors = [];
    const fieldName = fieldConfig.name;
    fieldConfig.validations.forEach(validation => {
        
        let error = null;
        switch (validation.type) {
            case 'required':
                if (!!validation.value === true && (isNullOrUndefined(value) || value === '' || (Array.isArray(value) && !(value.length > 0)))) {
                    error = 'This field is required';
                }
                break;
            case 'limit':
                if (!isNullOrUndefined(validation.min) && Number(value) < validation.min) {
                    error = 'This field has a minimum value: ' + validation.min;
                }
                if (!isNullOrUndefined(validation.max) && Number(value) > validation.max) {
                    error = 'This field has a maximum value: ' + validation.max;
                }
                break;
            case 'alphanumeric':
                if (!/^[a-zA-Z0-9]*$/.test(value))
                    error = 'Alphanumeric characters only';
                break;
            case 'alphanumeric_with_space':
                if (!/^[a-zA-Z0-9\s]*$/.test(value))
                    error = 'Alphanumeric characters and space only';
                break;
            case 'no_whitespace':
                if (/\s/.test(value))
                    error = 'Alphanumeric and special characters only';
                break;
            case 'url':
                if (value.includes("availability/[PROPERTY_CODE]")) break;
                if (!/^((http|https):\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.?)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/.test(value))
                    error = 'Value is not a valid URL';
                break;
        }

        if (error) errors.push({ field: fieldName, message: error })
    });

    return errors;
}

export function getUserTypes() {
    return [
        { id: "ope_admin", displayText: "Rev&You Admin" },
        { id: "internal_user", displayText: "Internal User" },
        { id: "hotel_admin", displayText: "Property Admin" },
        { id: "hotel_user", displayText: "Property User" },
    ]
}

export function mergeProperties(properties, propertyGroups) {
    const mergedProperties = []

    if (Array.isArray(properties)) {
        properties.forEach(property => mergedProperties.push({
            id: property.id,
            code: property.property_code,
            name: property.name,
            type: "PROPERTY"
        }));
    }

    if (Array.isArray(propertyGroups)) {
        propertyGroups.forEach(propertyGroup => mergedProperties.push({
            id: propertyGroup.id,
            code: propertyGroup.code,
            name: propertyGroup.name,
            type: "PROPERTY_GROUP"
        }));
    }
    
    return mergedProperties;
}

export function regroupProperties(mergedProperties, allProperties, allPropertyGroups) {
    const properties = [];
    const propertyGroups = [];

    mergedProperties.forEach(property => {
        switch(property.type) {
            case "PROPERTY": 
                if (Array.isArray(allProperties)) {
                    const selectedProperty = allProperties.find(p => p.id == property.id);
                    if (selectedProperty) properties.push(selectedProperty);
                }
                break;
            case "PROPERTY_GROUP":
                if (Array.isArray(allPropertyGroups)) {
                    const selectedPropertyGroup = allPropertyGroups.find(pg => pg.id == property.id);
                    if (selectedPropertyGroup) propertyGroups.push(selectedPropertyGroup);
                }
                break;
        }
    });

    return { properties, propertyGroups }
}