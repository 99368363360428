import React from 'react';
import { Flag } from 'Icons';

const NotesNotif = ({ type, style, onClick }) => {

    const color = {
        grey: '#000000a6',
        green: '#51b68a',
        red: '#ed474b',
        default: '#000000'
    }

    const getColor = (type) => {
        switch(type.toUpperCase()) {
            case 'BLOCKING': return color.green;
            case 'HOLIDAY': return color.grey;
            case 'ALERT': return color.red;
            default: return color.default;
        }
    }

    return (<Flag 
        style={{ height: '15px', width: '15px', ...style }} 
        stroke={getColor(type)}
        onClick={onClick}
    />)
}

export default NotesNotif;