import React from 'react';
import Accordion from 'elements/Accordion/Accordion';
import DefaultsSection from './Sections/DefaultsSection';
import PickupSection from './Sections/PickupSection';
import PaceSection from './Sections/PaceSection';
import PriceSection from './Sections/PriceSection';
import FinalsSection from './Sections/FinalsSection';
import PricingRecommendationSection from './Sections/PricingRecommendationSection';

const VariableSettings = (props) => {
    const isGroup = props?.isGroup ?? false;

    const accordionItems = [{
            key: "defaults",
            label: "Default Averages",
            options: {
                arrow: false
            },
            content: () => isGroup
                ? <DefaultsSection isGroup={props.isGroup} values={props.values} />
                : <DefaultsSection></DefaultsSection>
        },
        {
            key: "pickup",
            label: "Pickup",
            options: {
                arrow: false
            },
            content: () => isGroup
                ? <PickupSection isGroup={props.isGroup} values={props.values} />
                : <PickupSection/>
        },
        {
            key: "pace",
            label: "Pace",
            options: {
                arrow: false
            },
            content: () => isGroup
                ? <PaceSection isGroup={props.isGroup} values={props.values} />
                : <PaceSection/>
        },
        {
            key: "price",
            label: "Price",
            options: {
                arrow: false
            },
            content: () => isGroup
                ? <PriceSection isGroup={props.isGroup} values={props.values} />
                : <PriceSection/>
        },
        {
            key: "finals",
            label: "Finals",
            options: {
                arrow: false
            },
            content: () => isGroup
                ? <FinalsSection isGroup={props.isGroup} values={props.values} />
                : <FinalsSection/>
        },
        {
            key: "pricing_recommendation",
            label: "Pricing Recommendation",
            options: {
                arrow: false
            },
            content: () => isGroup
                ? <PricingRecommendationSection isGroup={props.isGroup} values={props.values} />
                : <PricingRecommendationSection/>
        }
    ];
    return (
        <div className="variable-settings-container" style={{ width: '1040px', borderTop: "1px solid white", margin: '30px auto 0 auto', paddingTop: '7px' }}>
            <Accordion
                headerClassname="variable-settings-accordion-header"
                accordion={true}
                items={accordionItems}
            ></Accordion>
        </div>
    )
}

export default VariableSettings;