import * as React from "react";

function SvgFunnel(props) {
    return (
        <svg x="0" y="0" enableBackground="new 0 0 463 463" viewBox="0 0 463 463" xmlSpace="preserve" { ...props }>
            <path d="M433 31.894H30c-16.568 0-30 13.432-30 30s13.432 30 30 30h12.862l142.814 161.112v171.9a6.2 6.2 0 009.005 5.531l75.724-38.395a12.627 12.627 0 006.917-11.263V253.005L420.138 91.894H433c16.568 0 30-13.432 30-30s-13.432-30-30-30z"></path>
        </svg>
    );
}

export default SvgFunnel;