import { useAuth0 } from "../../react-auth0-spa";
import { useDispatch } from "react-redux";
import axios from "axios";
import constants from "../../constants";

const usePropertyGroupActions = () => {
    const dispatch = useDispatch();
    const { getTokenSilently } = useAuth0();
    const EDIT_OP_GET = "GET";
    const EDIT_OP_UPDATE = "UPDATE";

    const getPropertyGroups = async (name = "", sort = "", page = 1, limit, status = "active") => {
        const token = await getTokenSilently();
        const params = { name, page, limit, sort, status };

        return axios.get(`${constants.BASE_URL_2}/properties/groups`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params,
        });
    };

    const getPropertyGroup = async (id) => {
        const token = await getTokenSilently();

        return axios.get(`${constants.BASE_URL_2}/properties/groups/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    const addPropertyGroup = async (data) => {
        const token = await getTokenSilently();
        return axios.post(`${constants.BASE_URL_2}/properties/groups`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const updatePropertyGroup = async (id, data) => {
        const token = await getTokenSilently();
        return axios.post(`${constants.BASE_URL_2}/properties/groups/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };
    
    const loadPropertyGroupsState = async (name = "", sort = "", page = 1, limit, status = "active") => {
        const result = dispatch({
            types: [
                constants.GET_PROPERTY_GROUPS_REQUEST,
                constants.GET_PROPERTY_GROUPS_SUCCESS,
                constants.GET_PROPERTY_GROUPS_FAILURE,
            ],
            callAPI: () => getPropertyGroups(name, sort, page, limit, status),
            payload: {
                searchTerm: name,
            },
        });
        return result;
    }

    const loadPropertyGroupState = (id) => {
        const result = dispatch({
            types: [
                constants.GET_PROPERTY_GROUP_REQUEST,
                constants.GET_PROPERTY_GROUP_SUCCESS,
                constants.GET_PROPERTY_GROUP_FAILURE,
            ],
            callAPI: () => getPropertyGroup(id),
            payload: {},
        })
        
        return result;
    }

    const setSelectedPropertyGroupState = (data) => {
        dispatch({ type: constants.SET_SELECTED_PROPERTY_GROUP, data: data });
    }

    const updatePropertyGroupState = (id, data) => {
        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_GROUP_REQUEST,
                constants.UPDATE_PROPERTY_GROUP_SUCCESS,
                constants.UPDATE_PROPERTY_GROUP_FAILURE,
            ],
            callAPI: () => updatePropertyGroup(id, data),
            payload: {},
        })
        
        return result;
    }

    const showLoadingPage = (value) => {
        dispatch({ type: constants.SET_BACKGROUND_LOADING, value: value === true });
    }

    const clearPropertyGroup = () => {
        dispatch({ type: constants.CLEAR_PROPERTY_GROUP });
    }

    const notifyUser = (type, message) => {
        dispatch({ type: constants.NOTIFY_USER, notifyType: type, message: message });
    }

    return {
        showLoadingPage,
        clearPropertyGroup,
        notifyUser,
        addPropertyGroup,
        updatePropertyGroup,
        getPropertyGroups,
        getPropertyGroup,
        loadPropertyGroupsState,
        loadPropertyGroupState,
        updatePropertyGroupState,
        setSelectedPropertyGroupState
    }
}

export default usePropertyGroupActions;