import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { isNullish } from "utils/data";


export const usePreferences = () => {

    const users = useSelector((state) => state.users);

    const [user, setUser] = useState(null);
    const [logoRedirectUrl, setLogoRedirectUrl] = useState("/");
    const [useCachedDailyData, setUseCachedDailyData] = useState(true);
    const [useCacheDashboardModal, setUseCachedDashboardModal] = useState(true);
    const [useSingleAsOfAvgsForVariances, setUseSingleAsOfAvgsForVariances] = useState(true);
    const [usePercentsForOccs, setUsePercentsForOccs] = useState(false);
    const [showComparisons, setShowComparisons] = useState(false);
    const [showDownloadables, setShowDownloadables] = useState(false);


    useEffect(() => {
        const user = users.authUser;
        const preferences = user?.prefs;

        // Set User and User Preferences
        if (!isNullish(user)) setUser(user);
        if (!isNullish(preferences)) {
            setUseCachedDailyData(preferences.cacheCalendar ?? true);
            setUseCachedDashboardModal(preferences.cacheDashboardModal ?? true);
            setUseSingleAsOfAvgsForVariances(preferences.singleAsOfAvgsForVariances ?? true);
            setUsePercentsForOccs(preferences.singleAsOfAvgsForVariances ?? true);
            setUsePercentsForOccs(preferences.usePercentsForOccs ?? false);
            setShowComparisons(!(preferences.hideComparisons ?? false));
            setShowDownloadables(preferences.showDownloadables ?? false);

            if (preferences.logoRedirectUrl !== "")
                setLogoRedirectUrl(preferences.logoRedirectUrl.split("/")[3]);
        }
    }, [users])


    return {
        user,
        logoRedirectUrl,
        useCachedDailyData,
        useCacheDashboardModal,
        useSingleAsOfAvgsForVariances,
        usePercentsForOccs,
        showComparisons,
        showDownloadables
    };
}