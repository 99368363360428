import React, { useEffect, useState, useRef } from "react";
// import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { composeSortParams } from "../../utils";
import { Dashboard, Add, Edit, AddFilled } from "Icons";
import { Link } from "react-router-dom";
import { CaretRightOutlined} from '@ant-design/icons';
import DataGrid from "components/DataGrid";
import SearchInput from "elements/Input/Search";
import useNav from "components/Nav/useNav";
import useProperties from "store/actions/propertiesActions";
import usePropertyGroups from "store/actions/propertyGroupActions";
import useHelperFunctions from "store/actions/actions";
import Accordion from "elements/Accordion/Accordion";

const Properties = ({ history }) => {
    const state = useSelector((state) => ({
        properties: state.properties,
        propertyGroups: state.propertyGroups,
        main: state.mainReducer,
        users: state.users ?? {},
        user: state.users?.authUser ?? {},
    }));

    const activePropertiesGridRef = useRef();
    const inactivePropertiesGridRef = useRef();
    const activeGroupGridRef = useRef();
    const inactiveGroupGridRef = useRef();
    const showAllProperties = state.user.type === "ope_admin" && state.user.prefs?.showAllProperties === true;
    const propertySearchLimit = showAllProperties ? 100 : 5;
    const { getProperties } = useProperties();
    const { getPropertyGroups } = usePropertyGroups();
    const { changeActiveHotel } = useNav();
    const { setShowUploadModal } = useHelperFunctions();

    const loadPropertyDataGrid = (value, sort, page = 1, limit, status = "active", resetData = true) => {
        const gridRef = (status == "active") ? activePropertiesGridRef : inactivePropertiesGridRef;
        if (!gridRef.current) return;
        gridRef.current.setLoading(true);
        getProperties(value, sort, page, limit, status).then((result) => {
            const totalCount = result.response.data[status == "active" ? 'activeTotalCount' : 'inactiveTotalCount'];
            if (gridRef.current) {
                gridRef.current.loadData(result.response.data.data, resetData, totalCount);
                gridRef.current.setLoading(false);
            }
        });
    }

    const loadPropertyGroupDataGrid = (value, sort, page = 1, limit, status = "active", resetData = true) => {
        const gridRef = (status == "active") ? activeGroupGridRef : inactiveGroupGridRef;
        if (!gridRef.current) return;
        gridRef.current.setLoading(true);
        getPropertyGroups(value, sort, page, limit, status).then((result) => {
            const totalCount = result.data[status == "active" ? 'activeTotalCount' : 'inactiveTotalCount'];
            if (gridRef.current) {
                gridRef.current.loadData(result.data.data, resetData, totalCount);
                gridRef.current.setLoading(false);
            }
        });
    }

    const handleSearch = (value) => {
        const gridInfo = activePropertiesGridRef.current?.getGridInfo();
        const sort = composeSortParams(gridInfo?.sortVal, gridInfo?.sortId);
        loadPropertyDataGrid(value, sort, 1, propertySearchLimit);
        loadPropertyGroupDataGrid(value, sort, 1, propertySearchLimit);

        if (state.user?.type === "ope_admin" || state.user?.type === "internal_user") {
            loadPropertyDataGrid(value, sort, 1, propertySearchLimit, "inactive");
            loadPropertyGroupDataGrid(state.properties.currentSearchTerm, sort, 1, propertySearchLimit, "inactive");
        }
    };

    const handleActivePropertyList = async (pageNo, sortVal, sortId) => {
        const sort = composeSortParams(sortVal, sortId);
        loadPropertyDataGrid(state.properties.currentSearchTerm, sort, pageNo, propertySearchLimit, "active", false);
    };

    const handleInactivePropertyList = async (pageNo, sortVal, sortId) => {
        const sort = composeSortParams(sortVal, sortId);
        loadPropertyDataGrid(state.properties.currentSearchTerm, sort, pageNo, propertySearchLimit, "inactive", false);
    };

    const handleActivePropertyGroupList = async (pageNo, sortVal, sortId) => {
        const sort = composeSortParams(sortVal, sortId);
        loadPropertyGroupDataGrid(state.properties.currentSearchTerm, sort, pageNo, propertySearchLimit, "active", false);
    };

    const handleInactivePropertyGroupList = async (pageNo, sortVal, sortId) => {
        const sort = composeSortParams(sortVal, sortId);
        loadPropertyGroupDataGrid(state.properties.currentSearchTerm, sort, pageNo, propertySearchLimit, "inactive", false);
    };

    const handleDashboardRedirect = (item) => {
        changeActiveHotel(item);
        history.push("/dashboard");
    };

    const handleShowUploadModal = (item) => {
        setShowUploadModal({ id: item._id, name: item.name, parser_code: item.parser_info?.code,visible: true, code: item.property_code });
    };

    useEffect(() => {
        document.title = "Rev & You | Property List";
        const sort = composeSortParams('ascend', 'name');
        loadPropertyDataGrid(state.properties.currentSearchTerm, sort, 1, propertySearchLimit);
        loadPropertyGroupDataGrid(state.properties.currentSearchTerm, sort, 1, propertySearchLimit);

        if (state.user?.type === "ope_admin" || state.user?.type === "internal_user") {
            loadPropertyDataGrid(state.properties.currentSearchTerm, sort, 1, propertySearchLimit, "inactive");
            loadPropertyGroupDataGrid(state.properties.currentSearchTerm, sort, 1, propertySearchLimit, "inactive");
        }
    }, []);


    const getPropertyDataGridColumns = (gridRef) => {
        const actionViewDashboard = {
            render: rowIdx => {
                const item = { ...gridRef.current.getRawData(rowIdx), group: false };
                return <Dashboard width="20px" height="20px" className="pointer" onClick={() => handleDashboardRedirect(item)} />
            }
        };
        const actionShowUploadModal = {
            render: rowIdx => {
                const item = gridRef.current.getRawData(rowIdx);
                const mappings = item?.mappings ?? [];
                return mappings.some(m => m.enabled === true) 
                    ? <Add width="20px" height="20px" className="disabled-action" />
                    : <Add width="20px" height="20px" className="pointer" onClick={() => handleShowUploadModal(item)} />
            }
        };
        const actionEditProperty = {
            render: rowIdx => {
                if (state.user.type == "hotel_user") return;
                const item = gridRef.current.getRawData(rowIdx);
                return <Edit width="20px" height="20px" className="pointer" onClick={() => history.push(`/properties/${item._id}/edit`)} />
            }
        };

        const actions = state.user.type == "hotel_user"
            ? [actionViewDashboard, actionShowUploadModal]
            : [actionViewDashboard, actionShowUploadModal, actionEditProperty];

        return [{
            id: 'property_code',
            header: 'PROPERTY CODE',
            enableSort: true,
            width: '170px',
        }, {
            id: 'name',
            header: 'PROPERTY NAME',
            dataField: 'name',
            enableSort: true,
            width: '380px',
            sortDefault: true
        }, {
            id: 'preferred_location',
            header: 'LOCATION',
            dataField: 'preferred_location',
            enableSort: true,
            width: '270px'
        }, {
            id: 'latest_as_of',
            header: 'LATEST DATA',
            render: val => val ? dayjs(val).format("DD MMM YYYY") : '',
            enableSort: true,
            width: '140px'
        }, {
            id: 'actions',
            header: 'OTHER ACTIONS',
            type: 'custom_actions',
            width: 'minmax(0, 1fr)',
            actions: actions
        }];
    }

    const getGroupDataGridColumns = (gridRef) => {
        const actionViewDashboard = {
            render: rowIdx => {
                const item = { ...gridRef.current.getRawData(rowIdx), group: true };
                return <Dashboard width="20px" height="20px" className="pointer" onClick={() => handleDashboardRedirect(item)} />
            }
        };
        const actionEditGroup = {
            render: rowIdx => {
                if (state.user.type == "hotel_user") return;
                const item = gridRef.current.getRawData(rowIdx);
                return <Edit width="20px" height="20px" className="pointer" onClick={() => {
                    history.push(`/properties/groups/${item._id}/edit`)
                }} />
            }
        };

        const actions = state.user.type == "hotel_user"
            ? [actionViewDashboard]
            : [actionViewDashboard, actionEditGroup];

        return [{
            id: 'code',
            header: 'GROUP CODE',
            dataField: "code",
            enableSort: true,
            width: "170px"
        }, {
            id: 'name',
            header: 'GROUP NAME',
            dataField: "name",
            enableSort: true,
            width: '380px',
            sortDefault: true
        }, {
            id: 'description',
            header: 'DESCRIPTION',
            dataField: 'description',
            enableSort: true,
            width: '410px'
        }, {
            id: 'actions',
            header: 'OTHER ACTIONS',
            type: 'custom_actions',
            width: 'minmax(0, 1fr)',
            actions: actions
        }];
    }

    const createHeader = (headerTitle, className = "", addButtonLink = "") => {
        return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: "flex", alignItems: 'center', gap: '10px' }}>
                <h3 className={className}>
                    {headerTitle}
                </h3>
                {addButtonLink && <Link to={addButtonLink} style={{ height: '22px' }}>
                    <AddFilled width="23px" height="23px" className="pointer" />
                </Link>}
            </div>
            <CaretRightOutlined />
        </div>
    }

    return (
        <div className="properties page">
            <div className="divider" />
            <div className="container centered">
                <div className="body row">

                    <div className="header">
                        <div className="title">
                            <h2>MY LIST</h2>
                        </div>
                        <div className="search-field">
                            <SearchInput
                                handleSearch={handleSearch}
                                isLoading={state.properties.loading}
                                placeholder="Search for a property or group"
                            />
                        </div>
                    </div>

                    <div className="table-wrapper properties-grid">
                        <div className="rms-accordion">
                            <Accordion 
                                collapse={true}
                                defaultActiveKey={[
                                    "active_properties", "active_groups", ...[showAllProperties && ["inactive_properties", "inactive_groups"]]
                                ]}
                                items={[{
                                    key: 'active_properties',
                                    header: createHeader("Properties", "", state.user.type == "ope_admin" ? "/properties/add" : null),
                                    content: () => <DataGrid
                                        style={{ minHeight: '231px', height: '100%', width: '100%', overflow: 'auto' }}
                                        gridEventHandler={(info) => { handleActivePropertyList(info.pageNo, info.sortVal, info.sortId) }}
                                        dividerHeight={'0px'}
                                        headerHeight={'57px'}
                                        defaultCellHeight={'58px'}
                                        ref={activePropertiesGridRef}
                                        columns={getPropertyDataGridColumns(activePropertiesGridRef)}
                                        pagination={{
                                            enabled: true,
                                            limit: propertySearchLimit
                                        }}
                                    />
                                }, {
                                    key: 'active_groups',
                                    header: createHeader("Groups", "", state.user.type == "ope_admin" ? "/properties/groups/add" : null),
                                    content: () => <DataGrid
                                        style={{ minHeight: '231px', height: '100%', width: '100%', overflow: 'auto' }}
                                        gridEventHandler={(info) => { handleActivePropertyGroupList(info.pageNo, info.sortVal, info.sortId) }}
                                        dividerHeight={'0px'}
                                        headerHeight={'57px'}
                                        defaultCellHeight={'58px'}
                                        ref={activeGroupGridRef}
                                        columns={getGroupDataGridColumns(activeGroupGridRef)}
                                        pagination={{
                                            enabled: true,
                                            limit: propertySearchLimit
                                        }}
                                    />
                                }, {
                                    key: 'inactive_properties',
                                    header: createHeader("Inactive Properties", "inactive-header"),
                                    visible: state.user.type == "ope_admin",
                                    content: () => <DataGrid
                                        style={{ minHeight: '231px', height: '100%', width: '100%', overflow: 'auto' }}
                                        gridEventHandler={(info) => { handleInactivePropertyList(info.pageNo, info.sortVal, info.sortId) }}
                                        dividerHeight={'0px'}
                                        headerHeight={'57px'}
                                        defaultCellHeight={'58px'}
                                        ref={inactivePropertiesGridRef}
                                        columns={getPropertyDataGridColumns(inactivePropertiesGridRef)}
                                        pagination={{
                                            enabled: true,
                                            limit: propertySearchLimit
                                        }}
                                    />
                                }, {
                                    key: 'inactive_groups',
                                    header: createHeader("Inactive Groups", "inactive-header"),
                                    visible: state.user.type == "ope_admin",
                                    content: () => <DataGrid
                                        style={{ minHeight: '231px', height: '100%', width: '100%', overflow: 'auto' }}
                                        gridEventHandler={(info) => { handleInactivePropertyGroupList(info.pageNo, info.sortVal, info.sortId) }}
                                        dividerHeight={'0px'}
                                        headerHeight={'57px'}
                                        defaultCellHeight={'58px'}
                                        ref={inactiveGroupGridRef}
                                        columns={getGroupDataGridColumns(inactiveGroupGridRef)}
                                        pagination={{
                                            enabled: true,
                                            limit: propertySearchLimit
                                        }}
                                    />
                                }]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Properties;
