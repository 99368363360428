import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import { Exit } from 'Icons';

import SearchBar from 'elements/Select/SearchBar';
import Switch from "../Switch/Switch";
import { isNullish } from "utils/data";
import constants from "../../constants";

const { Option } = Select;


/**
 * Comparison search and list component.
 *
 * @param {[Object]} selected List of selected comparisons.
 * @return {Component} Comparison search and list component.
*/
const ComparisonList = ({ id, title = "", active, selected, className, hasTitleMargins = false, action }) => {

    const unselected = (comparisons, selected) => {
        const selectedIds = selected.map((_) => !isNullish(_.id) ? _.id : _.property);
        let items = comparisons.filter(item => !selectedIds.includes(item.id));
        return items;
    };

    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const selectedRef = useRef(selected).current;

    const [searchOptions, setSearchOptions] = useState(state.comparisons_options);
    const [listItems, setListItems] = useState(selectedRef);
    const [enabled, setEnabled] = useState(active);
    const [selectedItems, setSelectedItems] = useState([]);
    const [addedItems, setAddedItems] = useState([]);


    useEffect(() => {
        const updatedListItems = [...listItems, ...addedItems];
        setListItems(updatedListItems);
        dispatch({
            type: constants.UPDATE_COMPARISONS_LATEST,
            value: updatedListItems
        })
    }, [addedItems])

    useEffect(() => {
        const options = unselected(state.comparisons_options, state.comparisons);
        setSearchOptions(options);
        setListItems(state.comparisons);
    }, [state.comparisons, state.comparisons_options])

    useEffect(() => {
        dispatch({
            type: constants.UPDATE_COMPARISONS_ENABLED,
            value: enabled
        })
    }, [enabled])

    return (
        <div className={`property-list ${className}`}>

            {/* Title */}
            <h3 style={hasTitleMargins ? { marginBottom: "16px" } : null}>{title}</h3>

            {/* Enable Comparisons */}
            <div className="form-container">
                <div className="form-field">
                    <div className="form-field-label">
                        <span />ENABLE COMPARISON
                    </div>
                    <div className="form-field-input-div">
                        <div className={`form-field-input form-field-switch`} style={
                            { display: "inline-flex", marginTop: "auto", marginBottom: "auto" }
                        }>
                            <span style={{ marginRight: 8, fontSize: 14 }}>
                                {enabled ? "Active" : "Inactive"}
                            </span>
                            <Switch checked={enabled} onChange={() => setEnabled(!enabled)}></Switch>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            {/* Search */}
            <SearchBar
                placeholder="Group or Property ID or Name"
                options={searchOptions.map(option => ({ 
                    label: option.name,
                    code: option.property_code ?? option.code,
                    value: option.id
                }))}
                optionRender={({ data }) => <>
                    <span>{data.label}</span>
                    <span>{data.code}</span>
                </>}
                value={selectedItems}
                notFoundContent="No available properties or groups to select"
                handleFilter={(input, option) => {
                    const inputLowerCase = input.toLowerCase();
                    const nameLowerCase = option.props.label.toLowerCase();
                    const codeLowerCase = option.props.code.toLowerCase();
                    return (
                        nameLowerCase.includes(inputLowerCase) ||
                        codeLowerCase.includes(inputLowerCase)
                    );
                }}
                onAdd={() => {
                    const event = { action: "add", ids: selectedItems };
                    typeof action == "function" && action(event);

                    setAddedItems(searchOptions.filter(_ => selectedItems.includes(_.id)));
                    setSearchOptions(searchOptions.filter(_ => !selectedItems.includes(_.id)));
                    setSelectedItems([]);
                }}
                onChange={(values) => {
                    setSelectedItems(values);
                }}
            />

            {/* Comparison List */}
            <table className="forms-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Type</th>
                        <th className="table-actions">Actions</th>
                    </tr>
                </thead>

                <tbody style={{ paddingTop: 20 }}>
                    {(Array.isArray(listItems) && listItems.length > 0) ? (
                        listItems.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.property_code ?? item.code}</td>
                                    <td>{!isNullish(item.isGroup)
                                        ? (item.isGroup === true ? "Group" : "Property")
                                        : (item.property_code ? "Property" : "Group")
                                    }</td>
                                    <td className="table-actions">
                                        <Exit
                                            onClick={() => {
                                                const event = { action: "delete", id: item.id };
                                                typeof action == "function" && action(event);
                                            }}
                                            width="16px"
                                            height="16px"
                                            className="pointer"
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td>No Comparisons Found</td>
                        </tr>
                    )}
                </tbody>
            </table>

        </div>
    );
}

export default ComparisonList;