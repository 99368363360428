import React from "react";

import PriceRecoIcon from "elements/Icon/PriceRecoIcon";
import ArrowIcon from "elements/Icon/ArrowIcon";

import { isNullOrUndefined } from "../../utils";



const RecommendationIcon = ({ data, spanClassName = null }) => {

    const isImprovedAlgorithmDaily = !isNullOrUndefined(data) && !isNullOrUndefined(data.algorithm_breakdown);
    const isImprovedAlgorithmAggregate = !isNullOrUndefined(data) && !isNullOrUndefined(data.algorithm_aggregate);


    if (isImprovedAlgorithmDaily) {
        let algorithmInfo = data.algorithm_breakdown;
        let arrowQuantity = algorithmInfo.price_recommendation.recommendation.arrow_quantity;

        return <PriceRecoIcon quantity={arrowQuantity} />

    } else if (isImprovedAlgorithmAggregate && data.algorithm_aggregate.version === "improved") {
        let algorithmInfo = data.algorithm_aggregate;
        let arrowQuantity = algorithmInfo.forecast?.averages?.price_recommendation;

        return <PriceRecoIcon quantity={arrowQuantity} round={true} />

    } else { // For Old Algorithm Version

        if (data && ("recommended_value" in data) && isNullOrUndefined(data["recommended_value"])) {
            return (<span></span>);
        }

        if (data && !("recommended_action" in data)) {
            let recommended_value = data["recommended_value"];
            return (
                <span className={spanClassName}>
                    {recommended_value > 0 && <ArrowIcon direction="up" />}
                    {recommended_value >= 2 && <ArrowIcon direction="up" />}
                    {recommended_value >= 3 && <ArrowIcon direction="up" />}
                    {recommended_value < 0 && <ArrowIcon direction="down" />}
                    {recommended_value <= -2 && <ArrowIcon direction="down" />}
                    {recommended_value <= -3 && <ArrowIcon direction="down" />}
                </span>
            )
        } else if (data && ("recommended_action" in data)) {
            let recommended_action = data["recommended_action"];
            let recommended_value = data["recommended_value"];
            return (
                <span className={spanClassName}>
                    {recommended_action === "Increase" && <ArrowIcon direction="up" />}
                    {recommended_value >= 2 && <ArrowIcon direction="up" />}
                    {recommended_value >= 3 && <ArrowIcon direction="up" />}
                    {recommended_action === "Decrease" && <ArrowIcon direction="down" />}
                    {recommended_value <= -2 && <ArrowIcon direction="down" />}
                    {recommended_value <= -3 && <ArrowIcon direction="down" />}
                </span>
            )
        } else {
            return (<span></span>);
        }
    }
}

export default RecommendationIcon;
