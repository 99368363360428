import { useDispatch } from "react-redux";

import constants from "../../constants";

const useNav = () => {
    const dispatch = useDispatch();

    const changeActiveHotel = value => {
        dispatch({
            type: constants.UPDATE_ACTIVE_HOTEL,
            value: value
        });
    };

    return {
        changeActiveHotel
    };
};

export default useNav;
