import { useDispatch } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";
import axios from "axios";
// import moment from "moment";
import dayjs from "dayjs";
import _ from "lodash";

import constants from "../../constants";



const usePropertiesActions = () => {
    const dispatch = useDispatch();

    const { getTokenSilently } = useAuth0();

    const getProperties = async (name = "", sort = "", page = 1, limit, status = "active") => {
        const token = await getTokenSilently();
        const params = { name, page, limit, sort, status };
        const result = dispatch({
            types: [
                constants.GET_PROPERTIES_REQUEST,
                constants.GET_PROPERTIES_SUCCESS,
                constants.GET_PROPERTIES_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/properties`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params,
                }),
            payload: {
                searchTerm: name,
            },
        });
        return result;
    };

    const addProperty = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.ADD_PROPERTIES_REQUEST,
                constants.ADD_PROPERTIES_SUCCESS,
                constants.ADD_PROPERTIES_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const getProperty = async (id) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_PROPERTY_REQUEST,
                constants.GET_PROPERTY_SUCCESS,
                constants.GET_PROPERTY_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/properties/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        })

        return result;
    };

    const updateProperty = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_REQUEST,
                constants.UPDATE_PROPERTY_SUCCESS,
                constants.UPDATE_PROPERTY_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });

        return result;
    };

    const updatePropertyStatus = async (id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_STATUS_REQUEST,
                constants.UPDATE_PROPERTY_STATUS_SUCCESS,
                constants.UPDATE_PROPERTY_STATUS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });

        return result;
    };


    const getHotelCurrencies = async () => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_HOTEL_CURRENCIES_REQUEST,
                constants.GET_HOTEL_CURRENCIES_SUCCESS,
                constants.GET_HOTEL_CURRENCIES_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/currencies`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const getHotelCurrency = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_HOTEL_CURRENCY_REQUEST,
                constants.GET_HOTEL_CURRENCY_SUCCESS,
                constants.GET_HOTEL_CURRENCY_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/currencies/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const getHotelBrands = async () => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_HOTEL_BRANDS_REQUEST,
                constants.GET_HOTEL_BRANDS_SUCCESS,
                constants.GET_HOTEL_BRANDS_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/brands`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const addHotelBrands = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_HOTEL_BRAND_REQUEST,
                constants.ADD_HOTEL_BRAND_SUCCESS,
                constants.ADD_HOTEL_BRAND_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/brands`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const getHotelCountries = async () => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_HOTEL_COUNTRIES_REQUEST,
                constants.GET_HOTEL_COUNTRIES_SUCCESS,
                constants.GET_HOTEL_COUNTRIES_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/countries`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const getHotelProvinces = async () => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_HOTEL_PROVINCES_REQUEST,
                constants.GET_HOTEL_PROVINCES_SUCCESS,
                constants.GET_HOTEL_PROVINCES_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/provinces`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const getHotelCities = async (id) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_HOTEL_PROVINCE_REQUEST,
                constants.GET_HOTEL_PROVINCE_SUCCESS,
                constants.GET_HOTEL_PROVINCE_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/provinces/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };


    const getNotes = async (propertyId, startDate, endDate, summaryOnly) => {
        const token = await getTokenSilently();
        const formatDate = (date) => dayjs.utc(date).format();
        return axios.get(
            `${constants.BASE_URL_2}/notes/${propertyId}/${formatDate(startDate)}/${formatDate(endDate)}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    };

    const getNotesSummary = async (propertyId, startDate, endDate, noteType, group = false) => {
        const token = await getTokenSilently();

        const formatDate = (date) => dayjs.utc(date).format();
        const url = `${constants.BASE_URL_2}/notes/summary/${propertyId}/${formatDate(startDate)}/${formatDate(endDate)}`;
        if (noteType) url.concat(`/${noteType}`);

        const result = dispatch({
            types: [
                constants.GET_NOTES_REQUEST,
                constants.GET_NOTES_SUCCESS,
                constants.GET_NOTES_FAILURE,
            ],
            callAPI: () => {
                return axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
            },
            payload: {},
        });
        return result;
    };

    const addNote = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_NOTE_REQUEST,
                constants.ADD_NOTE_SUCCESS,
                constants.ADD_NOTE_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/notes`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const updateNote = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_NOTE_REQUEST,
                constants.UPDATE_NOTE_SUCCESS,
                constants.UPDATE_NOTE_FAILURE,
            ],
            callAPI: () =>
                axios.patch(`${constants.BASE_URL_2}/notes/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const deleteNote = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_NOTE_REQUEST,
                constants.DELETE_NOTE_SUCCESS,
                constants.DELETE_NOTE_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/notes/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    };

    const clearProperty = () => {
        dispatch({
            type: "CLEAR_PROPERTY",
        });
    };

    const getValidAsOfs = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_VALID_ASOFS_REQUEST,
                constants.GET_VALID_ASOFS_SUCCESS,
                constants.GET_VALID_ASOFS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/valid-asofs`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return await result;
    };

    const updateRoomType = async (property_id, data, action = "add") => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_ROOM_TYPES_REQUEST,
                constants.UPDATE_PROPERTY_ROOM_TYPES_SUCCESS,
                constants.UPDATE_PROPERTY_ROOM_TYPES_FAILURE,
            ],
            callAPI: () => {
                const endpoint = `${constants.BASE_URL_2}/properties/${property_id}/room-types`;
                const headers = { headers: { Authorization: `Bearer ${token}` } }
                switch (action) {
                    case "add": return axios.post(endpoint, data, headers);
                    case "edit": return axios.patch(endpoint, data, headers);
                    case "delete": return axios.delete(endpoint, { data, ...headers });
                    default: break;
                }
            },
            payload: { request_action: action }
        });

        return result;
    }

    const updateForecastSettings = async (id, data, metric = "", isGroup) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_FORECAST_SETTINGS_REQUEST,
                constants.UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS,
                constants.UPDATE_PROPERTY_FORECAST_SETTINGS_FAILURE,
            ],
            callAPI: () => {
                const endpoint = isGroup
                    ? `${constants.BASE_URL_2}/properties/groups/${id}/forecast-settings`
                    : `${constants.BASE_URL_2}/properties/${id}/forecast-settings`;
                const headers = { headers: { Authorization: `Bearer ${token}` } }
                if (metric) {
                    return axios.patch(endpoint, { [metric]: data, isGroup }, headers);
                }
                return axios.patch(endpoint, data, headers);
            },
            payload: { metric: metric }
        });

        return result;
    }

    const updateForecastSettingsV2 = async (id, data, metric = "", isGroup) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_FORECAST_SETTINGS_REQUEST,
                constants.UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS,
                constants.UPDATE_PROPERTY_FORECAST_SETTINGS_FAILURE,
            ],
            callAPI: () => {
                const endpoint = isGroup
                    ? `${constants.BASE_URL_2}/properties/groups/${id}/forecast-settings-custom`
                    : `${constants.BASE_URL_2}/properties/${id}/forecast-settings-custom`;
                const headers = { headers: { Authorization: `Bearer ${token}` } }
                
                if (metric) return axios.patch(endpoint, { [metric]: data, isGroup }, headers);

                return axios.patch(endpoint, data, headers);
            },
            payload: { metric: metric }
        });

        return result;
    }

    const getInternalRoomTypes = async () => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/roomtypes`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const getSegments = async (property_id) => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/segments?property_id=${property_id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const addSegment = async (data) => {
        const token = await getTokenSilently();

        return axios.post(`${constants.BASE_URL_2}/segments`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const addSegmentGroup = async (data, property_id) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_SEGMENTS_REQUEST,
                constants.UPDATE_PROPERTY_SEGMENTS_SUCCESS,
                constants.UPDATE_PROPERTY_SEGMENTS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/segment_groups/${property_id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });

        return result;
    }

    const updatePropertySegments = async (data, id, oldInput = {}, segmentGroupIndex, segmentOldGroupIndex) => {
        const token = await getTokenSilently();
        if (!_.isEmpty(oldInput)) {
            data.oldInput = oldInput
        }
        data.segmentGroupIndex = segmentGroupIndex
        data.segmentOldGroupIndex = segmentOldGroupIndex
        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_SEGMENTS_REQUEST,
                constants.UPDATE_PROPERTY_SEGMENTS_SUCCESS,
                constants.UPDATE_PROPERTY_SEGMENTS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/segments/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });

        return result;
    }


    const deletePropertySegment = async (id, segmentCode) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_SEGMENTS_REQUEST,
                constants.UPDATE_PROPERTY_SEGMENTS_SUCCESS,
                constants.UPDATE_PROPERTY_SEGMENTS_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/properties/segments/${id}`, {
                    data: { code: segmentCode }, headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }),
            payload: {},
        });

        return result;
    }

    const getParsers = async () => {
        const token = await getTokenSilently();

        return axios.get(`${constants.BASE_URL_2}/parsers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
    }

    const getIntegrationPartners = async () => {
        const token = await getTokenSilently();

        return axios.get(`${constants.BASE_URL_2}/channels`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }


    // Function to retrieve property partner mappings
    const retrievePartnerMappings = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_PROPERTY_PARTNER_MAPPINGS_REQUEST,
                constants.GET_PROPERTY_PARTNER_MAPPINGS_SUCCESS,
                constants.GET_PROPERTY_PARTNER_MAPPINGS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${property_id}/getpartnermappings`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };


    // Function to add or remove property partner mappings
    const updatePartnerMappings = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_PARTNER_MAPPINGS_REQUEST,
                constants.UPDATE_PROPERTY_PARTNER_MAPPINGS_SUCCESS,
                constants.UPDATE_PROPERTY_PARTNER_MAPPINGS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${property_id}/updatepartnermappings`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };


    // Function to retrieve property segment mappings
    const retrieveSegmentMappings = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_PROPERTY_SEGMENT_MAPPINGS_REQUEST,
                constants.GET_PROPERTY_SEGMENT_MAPPINGS_SUCCESS,
                constants.GET_PROPERTY_SEGMENT_MAPPINGS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${property_id}/getsegmentmappings`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };


    // Function to retrieve property room mappings
    const retrieveRoomMappings = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.GET_PROPERTY_ROOM_MAPPINGS_REQUEST,
                constants.GET_PROPERTY_ROOM_MAPPINGS_SUCCESS,
                constants.GET_PROPERTY_ROOM_MAPPINGS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${property_id}/getroommappings`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };


    // Function to add, update, or remove property segment mappings
    const updateSegmentMappings = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_SEGMENT_MAPPINGS_REQUEST,
                constants.UPDATE_PROPERTY_SEGMENT_MAPPINGS_SUCCESS,
                constants.UPDATE_PROPERTY_SEGMENT_MAPPINGS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${property_id}/updatesegmentmappings`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };


    // Function to add, update, or remove property room mappings
    const updateRoomMappings = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_ROOM_MAPPINGS_REQUEST,
                constants.UPDATE_PROPERTY_ROOM_MAPPINGS_SUCCESS,
                constants.UPDATE_PROPERTY_ROOM_MAPPINGS_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${property_id}/updateroommappings`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };

    // Function to update property default room type for BAR
    const updateBarRoomType = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_ROOM_TYPES_BAR_REQUEST,
                constants.UPDATE_PROPERTY_ROOM_TYPES_BAR_SUCCESS,
                constants.UPDATE_PROPERTY_ROOM_TYPES_BAR_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/properties/${property_id}/updateroomtypebar`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };

    const addCompetitor = async (propertyId, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.ADD_PROPERTY_COMPETITOR_REQUEST,
                constants.ADD_PROPERTY_COMPETITOR_SUCCESS,
                constants.ADD_PROPERTY_COMPETITOR_FAILURE,
            ],
            callAPI: () => {
                const endpoint = `${constants.BASE_URL_2}/properties/${propertyId}/competitors`;
                const headers = { headers: { Authorization: `Bearer ${token}` } };
                return axios.post(endpoint, data, headers);
            }
        });

        return result;
    };

    const updateCompetitor = async (propertyId, competitorId, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_PROPERTY_COMPETITOR_REQUEST,
                constants.UPDATE_PROPERTY_COMPETITOR_SUCCESS,
                constants.UPDATE_PROPERTY_COMPETITOR_FAILURE,
            ],
            callAPI: () => {
                const endpoint = `${constants.BASE_URL_2}/properties/${propertyId}/competitors/${competitorId}`;
                const headers = { headers: { Authorization: `Bearer ${token}` } };
                return axios.patch(endpoint, data, headers);
            }
        });

        return result;
    };

    const deleteCompetitor = async (propertyId, competitorId) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.DELETE_PROPERTY_COMPETITOR_REQUEST,
                constants.DELETE_PROPERTY_COMPETITOR_SUCCESS,
                constants.DELETE_PROPERTY_COMPETITOR_FAILURE,
            ],
            callAPI: () => {
                const endpoint = `${constants.BASE_URL_2}/properties/${propertyId}/competitors/${competitorId}`;
                const headers = { headers: { Authorization: `Bearer ${token}` } };
                return axios.delete(endpoint, { data: null, ...headers });
            },
        });

        return result;
    }



    const updateSummary = async (property_id, data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.UPDATE_SUMMARY_REQUEST,
                constants.UPDATE_SUMMARY_SUCCESS,
                constants.UPDATE_SUMMARY_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/summary/${property_id}/copy`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
        });

        return result;
    };



    const getAllPropertiesAndGroups = async (exclude) => {
        const token = await getTokenSilently();
        const params = { exclude }
        const result = dispatch({
            types: [
                constants.GET_PROPERTIES_AND_GROUPS_REQUEST,
                constants.GET_PROPERTIES_AND_GROUPS_SUCCESS,
                constants.GET_PROPERTIES_AND_GROUPS_FAILURE,
            ],
            callAPI: () =>
                axios.get(`${constants.BASE_URL_2}/properties/all`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params
                }),
        });
        return result;
    };



    return {
        getProperties,
        addProperty,
        getProperty,
        updateProperty,
        updatePropertyStatus,
        getHotelCurrencies,
        getHotelCurrency,
        getHotelBrands,
        addHotelBrands,
        getHotelCountries,
        getHotelProvinces,
        getHotelCities,
        addNote,
        updateNote,
        deleteNote,
        getNotes,
        getNotesSummary,
        clearProperty,
        getValidAsOfs,
        getSegments,
        addSegment,
        updatePropertySegments,
        deletePropertySegment,
        addSegmentGroup,
        getParsers,
        getIntegrationPartners,
        updateRoomType,
        updateForecastSettings,
        updateForecastSettingsV2,
        getInternalRoomTypes,
        retrievePartnerMappings,
        updatePartnerMappings,
        retrieveSegmentMappings,
        retrieveRoomMappings,
        updateSegmentMappings,
        updateRoomMappings,
        updateBarRoomType,
        addCompetitor,
        updateCompetitor,
        deleteCompetitor,
        updateSummary,
        getAllPropertiesAndGroups
    };
};
export default usePropertiesActions;
