import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../Button/Button';
import DataGrid from '../../../DataGrid';
import CustomAvgDatesModal from "../Modals/CustomAvgDatesModal";
import { gridPaceColumns, gridPaceCellConfig } from './gridConfig';
import usePropertiesActions from '../../../../store/actions/propertiesActions';

const PaceSection = (props) => {
    const isGroup = props?.isGroup ?? false;
    const group = props.values;
    const { property, loadingProperty } = useSelector(state => state.properties);
    const item = isGroup ? group : property;
    const forecastSettingsPace = item?.forecast_settings_v2.pace;

    const gridPaceRef = useRef();
    const { updateForecastSettingsV2 } = usePropertiesActions();
    const [numberOfDays, setNumberOfDays] = useState(7);
    const [customModalState, setCustomModalState] = useState({ for: null, header: null, items: [], visible: false });

    useEffect(() => {
        if (loadingProperty) return;
        setNumberOfDays(forecastSettingsPace.days);
        gridPaceRef.current.loadData(getColumnData(forecastSettingsPace, false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getColumnData = (pace, loadDefaults) => {
        if (loadDefaults) {
            return [{
                'variable': 'Same day last year',
                'average_days': 'N/A'
            }, {
                'variable': 'Rolling Average DOW'
            }, {
                'variable': 'Rolling Average'
            }, {
                'variable': 'Custom Average DOW'
            }, {
                'variable': 'Custom Average'
            }];
        }
        return [{
            'variable': 'Same day last year',
            'average_days': 'N/A',
            'avg_days_threshold_good': pace.threshold.sdly.good,
            'avg_days_threshold_avg': pace.threshold.sdly.avg,
            'avg_days_threshold_below_avg': pace.threshold.sdly.below_avg,
            'avg_days_threshold_bad': pace.threshold.sdly.bad,
            'instances': pace.instances.sdly
        }, {
            'variable': 'Rolling Average DOW',
            'average_days': pace.avg_days.avg_dow,
            'avg_days_threshold_good': pace.threshold.avg_dow.good,
            'avg_days_threshold_avg': pace.threshold.avg_dow.avg,
            'avg_days_threshold_below_avg': pace.threshold.avg_dow.below_avg,
            'avg_days_threshold_bad': pace.threshold.avg_dow.bad,
            'instances': pace.instances.avg_dow
        }, {
            'variable': 'Rolling Average',
            'average_days': pace.avg_days.avg,
            'avg_days_threshold_good': pace.threshold.avg.good,
            'avg_days_threshold_avg': pace.threshold.avg.avg,
            'avg_days_threshold_below_avg': pace.threshold.avg.below_avg,
            'avg_days_threshold_bad': pace.threshold.avg.bad,
            'instances': pace.instances.avg
        }, {
            'variable': 'Custom Average DOW',
            'average_days': pace.avg_days.custom_avg_dow,
            'avg_days_threshold_good': pace.threshold.custom_avg_dow.good,
            'avg_days_threshold_avg': pace.threshold.custom_avg_dow.avg,
            'avg_days_threshold_below_avg': pace.threshold.custom_avg_dow.below_avg,
            'avg_days_threshold_bad': pace.threshold.custom_avg_dow.bad,
            'instances': pace.instances.custom_avg_dow
        }, {
            'variable': 'Custom Average',
            'average_days': pace.avg_days.custom_avg,
            'avg_days_threshold_good': pace.threshold.custom_avg.good,
            'avg_days_threshold_avg': pace.threshold.custom_avg.avg,
            'avg_days_threshold_below_avg': pace.threshold.custom_avg.below_avg,
            'avg_days_threshold_bad': pace.threshold.custom_avg.bad,
            'instances': pace.instances.custom_avg
        }];
    }

    const cellConfigs = [...gridPaceCellConfig, { 
        positionX: 1, 
        positionY: 3, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg_dow", 
                header: "Manage Custom Average DOW",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }, { 
        positionX: 1, 
        positionY: 4, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg", 
                header: "Manage Custom Average",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }];

    const handleSubmit = () => {
        if (gridPaceRef.current.internalState.hasError) return;

        const newForecastSettingsPace = { ...forecastSettingsPace };
        newForecastSettingsPace.days = numberOfDays;
        ['sdly', 'avg_dow', 'avg', 'custom_avg_dow', 'custom_avg'].forEach((key, idx) => {
            newForecastSettingsPace.avg_days[key] = idx === 0 ? null : gridPaceRef.current.getData('average_days', idx);
            newForecastSettingsPace.threshold[key].good = gridPaceRef.current.getData('avg_days_threshold_good', idx);
            newForecastSettingsPace.threshold[key].avg = gridPaceRef.current.getData('avg_days_threshold_avg', idx);
            newForecastSettingsPace.threshold[key].below_avg = gridPaceRef.current.getData('avg_days_threshold_below_avg', idx);
            newForecastSettingsPace.threshold[key].bad = gridPaceRef.current.getData('avg_days_threshold_bad', idx);
            newForecastSettingsPace.instances[key] = gridPaceRef.current.getData('instances', idx);
        });

        if (gridPaceRef.current.errors.length > 0) return;

        updateForecastSettingsV2(isGroup ? group.id : property._id, newForecastSettingsPace, 'pace', isGroup)
            .then(result => {
                if (result.type === "UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS") {
                    const updatedProperty = result.response.data.data;
                    gridPaceRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.pace, false));
                }
            });
    }

    const handleCancel = () => {
        setNumberOfDays(forecastSettingsPace.days);
        gridPaceRef.current.reload();
    }

    return (<div className='pace-section'>
        <div style={{ marginBottom: '15px' }}>
            <span style={{ display: 'inline-block', padding: '4px', width: '185px' }}>Number of Days</span>
            <span style={{ display: 'inline-block', padding: '4px', width: '140px', height: '40px' }}>
                <input style={{ minWidth: '50px', width: '100%', textAlign: 'center' }} type="number" min={0} value={numberOfDays} onChange={(e) => setNumberOfDays(e.target.value)}></input>
            </span>
        </div>
        <DataGrid ref={gridPaceRef} columns={gridPaceColumns} cellConfigs={cellConfigs} defaultCellHeight="40px" footerHeight="0px" />
        <div style={{ display: "flex", flexDirection: 'row-reverse', paddingTop: "25px" }}>
            <Button type="primary" className="submit" onClick={handleSubmit} style={{ marginLeft: "35px" }}>Submit</Button>
            <Button type="bg-dark" onClick={handleCancel}>Cancel</Button>
        </div>

        <CustomAvgDatesModal width={484}
            headerCopy={ customModalState.header }
            isVisible={ customModalState.visible } 
            items={ customModalState.items }
            onCancel={() => setCustomModalState({ ...customModalState, visible: false })} 
            onSubmit={(items) => {
                const params = { avg_days: null };

                if (customModalState.for === 'avg_dow') {
                    params.avg_days = { custom_avg_dow: items };
                }

                if (customModalState.for === 'avg') {
                    params.avg_days = { custom_avg: items };
                }

                updateForecastSettingsV2(isGroup ? group.id : property._id, params, 'pace', isGroup).then(result => {
                    setCustomModalState({ ...customModalState, visible: false });
                    const updatedProperty = result.response.data.data;
                    gridPaceRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.pace, false));
                });
            }}
        />
    </div>)
}

export default PaceSection;