import React, { useState } from 'react';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';

const DataGridSortHeader = ({ children, id, selectedColumnId, value, eventHandler }) => {

    const handleOnClick = () => {
        eventHandler({ action: 'sort', columnId: id, value: value });
    }

    return (
        <div className='clickable-head' onClick={handleOnClick}>
            <div>
                {children}
                {selectedColumnId === id && <div className='sort-icon'>
                    <OutlinedIcon
                        type="up"
                        style={{
                            fontSize: "12px",
                            color: value === "ascend"
                                ? "#51b68a"
                                : "#fff",
                            display: "block",
                            position: "relative",
                            top: "2.5px",
                            transition: '.5s'
                        }}
                    />
                    <OutlinedIcon
                        type="down"
                        style={{
                            fontSize: "12px",
                            color: value === "descend"
                                ? "#51b68a"
                                : "#fff",
                            display: "block",
                            position: "relative",
                            bottom: "2.5px",
                            transition: '.5s'
                        }}
                    />
                </div>}
            </div>
        </div>
    );
}

export default DataGridSortHeader;