import React, { useState, useEffect, useRef } from "react";
import OutlinedIcon from "elements/Icon/OutlinedIcon";
import { Exit } from "Icons";



export default function MultipleSelectSearch({
    handleSearch,
    searchResults = [],
    handleSubmit,
    callback,
}) {
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [searchResultsVisibilty, setSearchResultsVisibilty] = useState(false);
    const [available, setAvailable] = useState([]);
    const [state, setState] = React.useState({
        searchTerm: "",
        typingTimeout: 0,
        active: false,
        touched: false,
    });
    const node = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = (e) => {
        if (node.current.contains(e.target) && true) {
            return;
        }
        setSearchResultsVisibilty(false);
    };

    const handleFocus = (e) => {
        setState({ ...state, touched: true });
        setSearchResultsVisibilty(true);
    };

    const handleChange = (event) => {
        if (state.typingTimeout) {
            clearTimeout(state.typingTimeout);
        }

        setState({
            ...state,
            searchTerm: event.target.value,
            active: true,
        });
    };

    useEffect(() => {
        if (state.touched)
            setState({
                ...state,
                typingTimeout: setTimeout(function () {
                    handleSearch(state.searchTerm);
                }, 500),
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.searchTerm]);

    const handleRemove = (property) => {
        const newSelectedProperties = selectedProperties.filter((item) => {
            return property.property_code !== item.property_code;
        });
        setSelectedProperties(newSelectedProperties);
    };

    const handleAddToList = (property) => {
        const newSelectedProperties = [...selectedProperties, property];
        setSelectedProperties(newSelectedProperties);
    };

    const submit = () => {
        handleSubmit(selectedProperties);
        setSelectedProperties([]);
    };

    useEffect(() => {
        // remove selected properties to searchable
        callback(selectedProperties);

        // remove or add selected properties to list
        const selectedPropertiesIds = selectedProperties.map((item) => item._id);
        const availableForMapping = searchResults.filter(
            (item) => !selectedPropertiesIds.includes(item._id)
        );
        setAvailable(availableForMapping);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProperties, searchResults]);

    return (
        <div className="multiple-select-search">
            <div className="input-wrapper">
                <OutlinedIcon
                    style={{
                        fontSize: "16px",
                        height: "16px",
                        padding: "0 8px 0 8px",
                    }}
                    type="search"
                />
                {selectedProperties.length > 0 &&
                    selectedProperties.map((property) => (
                        <div key={property.property_code} className="selected">
                            <span>{property.name}</span>
                            <Exit
                                key={property.property_code}
                                onClick={() => handleRemove(property)}
                                width="16px"
                                height="16px"
                                className="pointer"
                            />
                        </div>
                    ))}
                <input
                    type="text"
                    name="searchTerm"
                    placeholder="Property ID or Name"
                    value={state.searchTerm}
                    onFocus={handleFocus}
                    onChange={handleChange}
                />
                <div className="search-results__wrapper" ref={node}>
                    {searchResultsVisibilty &&
                        available.length > 0 &&
                        available.map((item) => {
                            return (
                                <div
                                    key={item.property_code}
                                    className="item"
                                    id={item.property_code}
                                    onClick={() => {
                                        handleAddToList(item);
                                    }}
                                >
                                    <div>{item.name}</div>
                                    <div>{item.property_code}</div>
                                </div>
                            );
                        })}
                </div>
            </div>

            <button onClick={submit}>Add</button>
        </div>
    );
}
