import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";

import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";
import BasicInput from "elements/Input/Basic";
import ModalSelect from "elements/Select/Modal";
import Error from "elements/Text/Error";

import { isAlphaNumeric} from "utils/data";


const CompetitorsModal = ({ competitor, visible, type, adminCompetitors, onCancel = null, onSubmit = null }) => {
    const state = useSelector((state) => state.mainReducer);

    const [competitorsList, setCompetitorsList] = useState(adminCompetitors);
    const [adminCompetitorId, setAdminCompetitorId] = useState(competitor?.competitor_id);
    const [competitorId, setCompetitorId] = useState(competitor?._id);
    const [competitorLabel, setCompetitorLabel] = useState(competitor?.label);

    const [modalErrors, setModalErrors] = useState([]);
    // Used state for type, just for consistency of modal.
    const [currentType, setCurrentType] = useState(type);

    const isAddModal = currentType === "add";
    const isEditModal = currentType === "edit";
    const isDeleteModal = currentType === "delete";

    // Dynamic Header Copy & Element
    let headerCopy = "";
    if (isAddModal) headerCopy = "Add Competitor";
    if (isEditModal) headerCopy = "Edit Competitor";
    if (isDeleteModal) headerCopy = "Delete Competitor";



    useEffect(() => {

        
        if (visible !== true) return;
        setCurrentType(type);
        
        if (isAddModal) resetModal();

        setCompetitorId(competitor?._id);
        setAdminCompetitorId(competitor?.competitor_id)
        setCompetitorLabel(competitor?.label);

    }, [visible]);



    // Modal Actions
    const handleModalErrors = () => {
        let errors = [];

        if (adminCompetitorId == null) {
            errors.push({ id: "competitor_id", message: "Please select a competitor" });
        }
        
        if (competitorLabel == null || competitorLabel == "") {
            errors.push({ id: "competitor_label", message: "Please provide a competitor label"});
        }
        
        if (competitorLabel && !isAlphaNumeric(competitorLabel)) {
            errors.push({ id: "competitor_label", message: "Label contains non-alphanumeric characters" });
        }

        return errors;
    }

    const resetModal = () => {
        setAdminCompetitorId(null);
        setCompetitorId(null);
        setCompetitorLabel(null);
        setModalErrors([]);
    }


    const handleModalSubmit = () => {
        const errors = handleModalErrors();
        setModalErrors(errors);

        if (!isDeleteModal && errors.length > 0) return;
        
        const data = {
            _id: competitorId,
            competitor_id: adminCompetitorId,
            competitor_label: competitorLabel
        }

        onSubmit({ type: type, data: data });
    }

    const transformCompetitorsList = () => {
        return competitorsList.map(competitor => ({
            value: competitor._id,
            name: competitor.name
        }));
    }

    return (
        <Modal open={visible} onCancel={() => onCancel()} footer={null} closeIcon={
            <IconButton type="delete" hasAccess={true} width="25px" />
        }>
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>

                <form onSubmit={(e) => null} className="light-form">
                    {/* Content: Add or Edit */}
                    {!isDeleteModal && <>
                        {/* Select Competitor */}
                        <ModalSelect label="Select Competitor" value={adminCompetitorId} options={transformCompetitorsList()}
                            disabled={false} action={(value) => setAdminCompetitorId(value)} showSearch={true} />
                        {modalErrors.filter(err => err.id === "competitor_id").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        {/* Competitor Label */}
                        <BasicInput id="competitor_label" label="Competitor Label" value={competitorLabel?? ""}
                            disabled={false} onBlur={(e) => setCompetitorLabel(e.target.value)}
                            onChange={(e) => setCompetitorLabel(e.target.value)} />
                        {modalErrors.filter(err => err.id === "competitor_label").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}
                    </>}

                    {/* Content: Add or Edit */}
                    {isDeleteModal && <div style={{ padding: 20 }}>
                        <p>
                            <strong>Competitor Label: </strong>
                            <span style={{ marginLeft: 8 }}>{competitorLabel}</span>
                        </p>
                        <p>Are you sure you want to delete this competitor?</p>
                    </div>}


                    {/* Cancel, Submit, Delete Buttons */}
                    <div className="light-form-buttons" style={{ marginTop: isDeleteModal ? 20 : 40 }}>
                        <ActionButton type="cancel" action={() => onCancel()} text="Cancel" />
                        <span style={{ width: 20 }} />
                        <ActionButton type="submit" action={() => handleModalSubmit()}
                            text={isDeleteModal ? "Delete" : "Submit"} />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CompetitorsModal;
