import React, { useEffect, useRef } from "react";
import Button from "../Button/Button";
import useHelperFunctions from "../../store/actions/actions";

const TutorialBox = ({
    onPrev,
    onNext,
    type,
    section,
    orientation,
    content,
    width,
    next,
    calloutStyles,
}) => {
    const { setToggleTutorial } = useHelperFunctions();
    const node = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (node.current && !node.current.contains(event.target)) {
                if (next) {
                    if (!isNaN(next.substr(-1))) {
                        //checks if the type has a number in the end
                        setToggleTutorial({
                            type: next.substring(0, next.length - 1), //removes the number in the end to show the spot of the original type
                            payload: {
                                showSpot: true,
                                show: false,
                                section: section,
                            },
                        });
                        setToggleTutorial({
                            type: type,
                            payload: {
                                showSpot: false,
                                show: false,
                                section: section,
                            },
                        });
                    } else {
                        setToggleTutorial({
                            type: type,
                            payload: {
                                showSpot: true,
                                show: false,
                                section: section,
                            },
                        });
                    }
                }
                setToggleTutorial({
                    type: next
                        ? !isNaN(next.substr(-1))
                            ? next.substring(0, next.length - 1)
                            : next
                        : type,
                    payload: {
                        showSpot: true,
                        show: false,
                        section: section,
                    },
                });
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node, type, section]);


    const arrowPos = {
        left: {
            right: "-40px",
            top: "5%",
        },
        bottom: {
            right: "50%",
            top: "-30px",
            transform: "rotate(-90deg)",
        },
        right: {
            left: "-40px",
            top: "5%",
            transform: "rotate(180deg)",
        },
    };

    const rmsArrow = (
        <div style={{ position: "absolute", ...arrowPos[orientation] }}>
            <div
                style={{
                    display: "inline-block",
                    backgroundColor: "#fff",
                    height: "2px",
                    width: "40px",
                    position: "relative",
                    ...calloutStyles?.arrow,
                }}
            >
                <div
                    style={{
                        borderRadius: "100%",
                        width: "16px",
                        height: "16px",
                        backgroundColor: "#fff",
                        position: "absolute",
                        right: "-10px",
                        top: "-7px",
                    }}
                ></div>
            </div>
        </div>
    );

    return (
        <div
            className="rms-tutorial-box"
            ref={node}
            style={{ width: width + "px", ...calloutStyles?.box }}
        >
            {content}
            <div className="rms-tutorial-box-footer">
                {next ? (
                    <Button
                        type="primary"
                        onClick={(event) => {
                            setToggleTutorial({
                                type: next,
                                payload: {
                                    showSpot: false,
                                    show: true,
                                    section,
                                },
                            });
                            setToggleTutorial({
                                type: type,
                                payload: {
                                    showSpot: false,
                                    show: false,
                                    section,
                                },
                            });
                        }}
                    >
                        NEXT
                    </Button>
                ) : (
                        <Button
                            type="primary"
                            onClick={(event) => {
                                setToggleTutorial({
                                    type: type,
                                    payload: {
                                        showSpot: false,
                                        show: false,
                                        section,
                                    },
                                });
                            }}
                        >
                            GOT IT
                        </Button>
                    )}
            </div>

            {rmsArrow}
        </div>
    );
};

export default TutorialBox;
