import React from 'react';
import { Helmet } from "react-helmet";
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import Accordion from 'elements/Accordion/Accordion';
import { Segments, IntegrationPartners, RoomTypes, MessageLogs, BookingSources, Competitors } from '../../components/AdminConsole';
import './Admin.scss';

const Admin = () => {
    
    const tabs = [{ 
        key: 'predefined_lists', label: 'PREDEFINED LISTS' 
    }, { 
        key: 'message_logs', label: 'MESSAGE LOGS' 
    }];

    const accordionItems = [{
        key: "segments",
        label: "Segments",
        content: () => <Segments/>
    }, {
        key: "integration_partners",
        label: "Integration Partners",
        content: () => <IntegrationPartners/>
    }, {
        key: "room_types",
        label: "Room Types",
        content: () => <RoomTypes/>
    }, {
        key: "booking_sources",
        label: "Booking Sources",
        content: () => <BookingSources/>
    }, {
        key: "competitor_pricing",
        label: "Competitors",
        content: () => <Competitors/>
    }];

    return (
        <div className="admin page">
            <Helmet><title>Rev&You | Admin Console</title></Helmet>
            <div>
                <div className="container centered bg-white property-info-header" style={{ padding: '16px 80px 0' }}>
                    <h2>Admin Console</h2>
                </div>
            </div>
            <div className="page-content">
                <CustomTabs name="adminConsoleTabs" tabs={tabs}>
                    <CustomTabs.Tab key="predefined_lists">
                        <div style={{width: '1040px', borderTop: "1px solid white", margin: '40px auto 0 auto', paddingTop: '7px'}}>
                            <Accordion
                                headerClassname="variable-settings-accordion-header"
                                accordion={true}
                                items={accordionItems}
                            ></Accordion>
                        </div> 
                    </CustomTabs.Tab>
                    <CustomTabs.Tab key="message_logs">
                        <div style={{width: '1250px', margin: '20px auto 0 auto', paddingTop: '7px'}}>
                            <MessageLogs></MessageLogs>
                        </div> 
                    </CustomTabs.Tab>
                </CustomTabs>
            </div>
        </div>
    )
}

export default Admin;