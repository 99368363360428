import React from "react";
import { Modal } from "antd";
import { Exit } from "Icons";
import Button from "components/Button/Button";



const ResetPwdModal = ({
    visible,
    onCancel,
    onConfirm,
    content,
    customConfirmMessage = "Yes",
    customCancelMessage = "Cancel",
}) => {
    return (
        <Modal
            open={visible}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={onCancel}
            width={500}
            footer={null}
        >
            <div className="rms-modal-content">
                <p>{content}</p>

                <div className="modal-buttons">
                    <Button type="bg-light" key="back" onClick={onCancel}>
                        {customCancelMessage}
                    </Button>
                    <Button type="primary" key="submit" onClick={onConfirm}>
                        {customConfirmMessage}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ResetPwdModal;
