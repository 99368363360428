import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import moment from "moment";
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
import "./assets/sass/main.scss";

// import ChartView from "./components/ChartView";
// import TableView from "./components/TableView";
import Dashboard from "./components/Dashboard";
import Properties from "./containers/Properties";
import Users from "./containers/Users";
import Navigator from "./components/Nav/Nav";
import PropertiesForm from "./components/Forms/PropertiesForm";
import PropertyGroupForm from "./components/PropertyGroupForm";
import UsersForm from "./components/Forms/UsersForm";
import newUsersForm from "./components/UserForm";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import Account from "./containers/Account/Account";
import Admin from "./containers/Admin";
import UploadModal from "./components/Modal/UploadModal";
import { Tutorial } from "./components/Tutorial/index";
import PrivateRoute from "./components/PrivateRoute";

import useHelperFunction from "./store/actions/actions";
import useUsersActions from "./store/actions/usersActions";
import useProperties from "./store/actions/propertiesActions";
import Help from "./components/Help/Help";

import dayjs from "lib/dayjs";
// import { SocketContext, socket } from './context/socket';
const App = (props) => {
    const state = useSelector((state) => state);
    const { getUserProfile, updateUserTutorial } = useUsersActions();
    const { user, isAuthenticated } = useAuth0();
    const { toggleTutorialSection } = useHelperFunction();
    const { getProperties } = useProperties();
    // Change locale so week starts at Monday
    dayjs.updateLocale('en', { weekStart: 0 })

    useEffect(() => {
        if (!state.users.authUser && isAuthenticated) {
            getUserProfile();
            setPropertiesModal();
            async function setPropertiesModal() {
                const properties = await getProperties();
                let propertiesLatestAsOfModal = {};
                properties.response.data.data.forEach((item) => {
                    propertiesLatestAsOfModal[item.property_code] = true;
                });

                localStorage.setItem(
                    "propertiesLatestAsOfModal",
                    JSON.stringify(propertiesLatestAsOfModal)
                );
            }
        }

        if (state.users.authUser?.tutorial) {
            toggleTutorialSection({
                section: "overviewCalendar",
                payload: true,
            });
            toggleTutorialSection({
                section: "dashboard",
                payload: true,
            });
            toggleTutorialSection({
                section: "sidepanel",
                payload: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    // Once an authenticated user is found
    // Set a local storage value of 'userCustomFilters' that contains all the user custom filters for that user
    useEffect(() => {
        let userCustomFilters = (state.users.authUser && Array.isArray(state.users.authUser.cf))
            ? state.users.authUser.cf
            : []
        localStorage.setItem("userCustomFilters", JSON.stringify(userCustomFilters));
    }, [state.users.authUser])


    const loadingBackground =
        state.mainReducer.bgLoading ||
        state.users.bgLoading ||
        state.properties.bgLoading ||
        state.dashboard.bgLoading ||
        state.adminConsole.bgLoading ||
        state.propertyGroups.bgLoading;

    const isPropertyGroupsEnabled = state.users?.authUser?.prefs?.propertyGroupsEnabled ?? false;
    const UserForm = isPropertyGroupsEnabled ? newUsersForm : UsersForm;

    return (
        <div id="app" className="App">
            {/* <SocketContext.Provider value={socket}> */}
            <Navigator />
            <div className="main">
                {loadingBackground ? <LoadingSpinner size={"40px"} mask={true} /> : ""}
                <Route
                    render={({ location }) => (
                        <TransitionGroup>
                            <CSSTransition key={location.key} timeout={300} classNames="fade">
                                <Switch location={location}>
                                    {/* <PrivateRoute path="/chart" component={ChartView} /> */}
                                    {/* <PrivateRoute path="/table" component={TableView} /> */}
                                    <PrivateRoute path="/users/:id/edit" component={UserForm} />
                                    <PrivateRoute path="/users/add" component={UserForm} />
                                    <PrivateRoute exact path="/users" component={Users} />
                                    <PrivateRoute exact path="/account" component={Account} />
                                    <PrivateRoute exact path="/admin" component={Admin} />
                                    <PrivateRoute path="/properties/:id/edit" component={PropertiesForm} />
                                    <PrivateRoute path="/properties/add" component={PropertiesForm} />
                                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                    <PrivateRoute exact path="/" component={Properties} />
                                    <PrivateRoute path="/properties/groups/:id/edit" component={PropertyGroupForm} />
                                    <PrivateRoute path="/properties/groups/add" component={PropertyGroupForm} />
                                </Switch>
                            </CSSTransition>
                        </TransitionGroup>
                    )}
                />
                <UploadModal />
            </div>
            <ToastsContainer store={ToastsStore} />

            <Tutorial
                isNewUser={state.users.authUser?.tutorial}
                updateUserTutorial={updateUserTutorial}
            />
            <Help />
            {/* </SocketContext.Provider> */}
        </div>
    );
};

export default App;
