import React from "react";


/**
 * A basic tab button element.
 *
 * @param {String} type Tab button type. Defaults to modal.
 * @param {String} text Tab button text.
 * @param {Boolean} isSelected Button is selected, or not. Defaults to false.
 * @param {Function} action Action to happen after clicking the button.
 * @return {Component} A basic action button element.
*/
const TabButton = ({ type = "modal", text = "", isSelected = false, action = null }) => {

    let defaultStyle, unselectedStyle, selectedStyle;

    switch (type) {
        case "modal":
            defaultStyle = {
                width: "max-content", fontFamily: "Titillium Web", backgroundColor: "#fff",
                cursor: "pointer", margin: "4px 24px 4px 0"
            }
            unselectedStyle = { ...defaultStyle, border: "none" }
            selectedStyle = {
                ...defaultStyle, color: "#00c28d", padding: "0 0 4px 0",
                borderRadius: 0, borderLeft: 0, borderRight: 0, borderTop: 0, borderBottom: "2px solid #00c28d"
            }

            return (
                <button onClick={action} style={isSelected ? selectedStyle : unselectedStyle}>
                    {text.toUpperCase()}
                </button>
            )
        case "content":
            unselectedStyle = "custom-tab-button";
            selectedStyle = `${unselectedStyle} active`;

            return (
                <span className="custom-tabs-room">
                    <button onClick={action} className={isSelected ? selectedStyle : unselectedStyle}>
                        {text}
                    </button>
                </span>
            )
        default:
            return null;
    }
};

export default TabButton;
