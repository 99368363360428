import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { Button, Modal } from "antd";
import { Exit } from "Icons";

import TableChart from "elements/Chart/TableChart";
import TabButton from "elements/Button/Tab";

import useProperties from "../../store/actions/propertiesActions";
import { chunkArray } from "../../utils/data";
import { isNullOrUndefined, getFirstdayOfWeek, getLastdayOfWeek } from "utils";
import constants from "../../constants";



export default function Tools() {
    const state = useSelector((state) => state.mainReducer);
    const users = useSelector((state) => state.users);
    const { updateSummary } = useProperties();
    const user = users.authUser;
    const userCustomFilters = users.authUser.cf;

    const { showDevLogsUI, sidebarData, calendar_data, propertyInView } = state;
    // const { visualsSegmentsData, visualsAsofsData } = state;
    const roomTypes = propertyInView ? propertyInView.room_types : null;
    const mappings = propertyInView ? propertyInView.mappings : null;
    const [showDevLogs, setShowDevLogs] = useState(false);
    const [selectedTab, setSelectedTab] = useState("user");



    const renderTabs = () => {
        let toolTabs = ["user"];
        if (!isNullOrUndefined(propertyInView)) toolTabs.push("property");
        if (!isNullOrUndefined(roomTypes)) toolTabs.push("room types");
        if (!isNullOrUndefined(calendar_data)) toolTabs.push("calendar filter");
        if (!isNullOrUndefined(sidebarData)) toolTabs.push("sidebar");
        if (!isNullOrUndefined(userCustomFilters)) toolTabs.push("custom filters");
        // ADD: visualsSegmentsData, visualsAsofsData


        return (<div style={{ marginLeft: 20, marginBottom: 20, fontSize: 14 }}>
            {toolTabs.map((item, index) => {
                return (
                    <TabButton key={index} text={item} isSelected={selectedTab === item}
                        action={() => setSelectedTab(item)} />
                )
            })}
        </div >)
    }


    const renderDataTable = (name, data, tableSize = 6, centered = true, keysToHide = []) => {

        let dataToDisplay = { ...data };
        delete dataToDisplay["vs"];
        delete dataToDisplay["users"];
        delete dataToDisplay["as_of_dates"];
        delete dataToDisplay["algorithm_breakdown"];
        delete dataToDisplay["forecast_settings"];
        delete dataToDisplay["room_types"];
        delete dataToDisplay["mappings"];

        const keys = Object.keys(dataToDisplay);
        const values = (Object.values(dataToDisplay).map((item) => {
            return (typeof item === 'object' && item !== null)
                ? JSON.stringify(item)
                : item
        }));


        let dataTable = [];
        for (let i = 0; i < Math.ceil(keys.length / tableSize); i++) {
            dataTable.push(<div key={i}>
                <TableChart allowString={true} rowNameWidth={150} rowDataWidth={150}
                    showInitialColumn={false}
                    headers={[]}
                    data={{
                        key: {
                            name: "Key",
                            data: chunkArray(keys, tableSize)[i],
                            color: "#e0e0e0"
                        },
                        value: {
                            name: "Value",
                            data: chunkArray(values, tableSize)[i],
                            color: null
                        },
                    }}
                />
                <br />
            </div>)
        }

        return (
            <div style={{ padding: "20px 0", display: "grid", placeItems: centered ? "center" : null }}>
                <p style={{ color: "green" }}>
                    <strong>{name.toUpperCase()}</strong>
                </p>
                {dataTable}
            </div>
        )
    }


    const renderData = () => {
        switch (selectedTab) {
            case "user":
                const userInView = { ...user };
                delete userInView["prefs"];
                return renderDataTable("users.authUser", userInView, 3);
            case "property":
                return renderDataTable("state.propertyInView",
                    propertyInView, 5, ["forecast_settings", "room_types"]);
            case "room types":
                return <>
                    {roomTypes.length > 0 && renderDataTable("state.propertyInView.room_types", roomTypes, 1)}
                    {mappings.length > 0 && renderDataTable("state.propertyInView.mappings", mappings, 1)}
                </>;
            case "calendar filter":
                return renderDataTable("state.calendar_data", calendar_data, 6);
            case "sidebar":
                return renderDataTable("state.sidebarData",
                    sidebarData, 5, ["vs", "algorithm_breakdown"]);
            case "custom filters":
                return renderDataTable("users.authUser.cf", userCustomFilters, 2);
            default:
                return null;
        }
    }


    const copyToSummary = () => {
        const data = {
            filter: {
                asof: calendar_data.as_of,
                pickup: calendar_data.pick_up,
                roomtype: calendar_data.roomtype,
                segments: calendar_data.segments,
            }
        };

        updateSummary(propertyInView._id, data)
            .then((res) => {
                if (res.type === constants.UPDATE_SUMMARY_SUCCESS) {
                    ToastsStore.success("Successfully copied booking data to summary");
                }
                if (res.type === constants.UPDATE_SUMMARY_FAILURE) {
                    ToastsStore.error(res.error.response?.data?.message
                        ?? "Sorry, we're unable to copy booking data to summary at this time");
                }
            });
    }



    return showDevLogsUI && (
        <div style={{ position: "fixed", zIndex: 9999, left: 32, bottom: 90 }}>

            {/* Copy Booking Data to Summary Button */}
            <Button type="primary" shape="circle" onClick={() => copyToSummary()} size="small"
                style={{
                    width: 32, height: 32, display: "block", backgroundColor: "orange", borderColor: "orange",
                    marginLeft: "auto", marginRight: "auto", marginBottom: 20
                }}>
            </Button>

            {/* Dev Tools Button */}
            <Button type="primary" shape="circle" onClick={() => setShowDevLogs(!showDevLogs)} size="small"
                style={{ width: 32, height: 32, display: "block", marginLeft: "auto", marginRight: "auto" }} >
                {showDevLogs ? "-" : "+"}
            </Button>

            {/* Dev Tools Modal */}
            <Modal title="App State Information" open={showDevLogs} onCancel={() => setShowDevLogs(false)}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                footer={false} centered width={1200} style={{ top: 40 }}
                styles={{
                    body: {
                        height: "80vh", padding: 20, overflowX: "hidden", overflowY: "scroll",
                        fontFamily: "Lato", fontSize: 12
                    }
                }}
            >

                {renderTabs()}
                {renderData()}

            </Modal>
        </div>
    );
}
