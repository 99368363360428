import React from "react";


/**
 * A link button element.
 *
 * @param {String} text Button text.
 * @param {Function} action Action to happen after clicking the button.
 * @return {Component} A link button element.
*/
const LinkButton = ({ text, action = null }) => {

    return (
        <button type="button" onClick={action}
            style={{
                backgroundColor: "#fff", color: "#00c28d", fontSize: 12, width: "max-content",
                fontFamily: "Lato", cursor: "pointer", textDecoration: "underline", border: "none",
            }}>
            {text}
        </button>
    )
};

export default LinkButton;
