import constants from "../../constants";
import { ToastsStore } from "react-toasts";
import { createError } from "../../utils/index";

const initialState = {
    selection: {
        loading: false,
        updating: false,
        data: null
    },
    list: {
        loading: false,
        data: null
    },
    bgLoading: false
}

function propertyGroups(state = initialState, action) {

    const newState = { ...state };

    switch (action.type) {

        case constants.SET_BACKGROUND_LOADING: 
            newState.bgLoading = action.value === true;
            break;

        case constants.NOTIFY_USER:
            switch(action.notifyType) {
                case "SUCCESS": ToastsStore.success(action.message); break;
                case "ERROR": createError({}, action.message); break;
            }

        case constants.GET_PROPERTY_GROUPS_REQUEST:
            newState.list.loading = true;
            break;
        case constants.GET_PROPERTY_GROUPS_SUCCESS:
            newState.list.loading = false;
            newState.list.data = action.response.data.data;
            break;
        case constants.GET_PROPERTY_GROUPS_FAILURE:
            newState.list.loading = false;
            newState.list.data = null;
            newState.error = createError(action.error);
            break;

        case constants.GET_PROPERTY_GROUP_REQUEST:
            newState.selection.loading = true;
            break;
        case constants.GET_PROPERTY_GROUP_SUCCESS:
            newState.selection.loading = false;
            newState.selection.data = action.response.data.data;
            break;
        case constants.GET_PROPERTY_GROUP_FAILURE:
            newState.selection.loading = false;
            newState.selection.data = null;
            newState.error = createError(action.error);
            break;
            
        case constants.UPDATE_PROPERTY_GROUP_REQUEST:
            newState.selection.updating = true;
            break;
        case constants.UPDATE_PROPERTY_GROUP_SUCCESS:
            newState.selection.updating = false;
            newState.selection.data = action.response.data.data;
            break;
        case constants.UPDATE_PROPERTY_GROUP_FAILURE:
            newState.selection.updating = false;
            newState.error = createError(action.error);
            break;
        
        case constants.SET_SELECTED_PROPERTY_GROUP:
            newState.selection.data = action.data;
            break;
        case constants.CLEAR_PROPERTY_GROUP:
            newState.selection = initialState.selection;
        case constants.CLEAR_PROPERTY_GROUPS:
            newState.selection = initialState.list;
        default:
            return state;
    }

    return newState;
}

export default propertyGroups;
