import React, { useState, useEffect, useMemo } from 'react';
// import moment from 'moment';
import dayjs from "dayjs";
import OutlinedIcon from 'elements/Icon/OutlinedIcon';
// NOTE: multiSort = TRUE is not supported or not working yet...
// To Do: Filter, Pagination

/**
 * Custom Hook for creating skeletal table.
 * @param {[Object]} data The data to display.
 * @param {[Object]} headers 
 * Configurations for headers: 
 * key - The name of the field that can be found in data,
 * header - The content of the header. Can be ANY or React Component,
 * sort - Set default field to sort. Available values are "asc" and "desc".
 * @param {Boolean} multiSort Set to TRUE if sorting multiple fields is allowed.
 * @param {Boolean} paginate Not implemented yet.
 * @returns 
 */
const useTable = ({ data, headers, info, multiSort = false, paginate = false }) => {
    const [headersView, setHeadersView] = useState(headers ?? []);

    const populateData = (data) => {
        const sortedHeaders = headersView.filter(h => ['asc', 'desc'].includes(h.sort));
        const sortData = [...data.map((item, index) => {
            const headersViewData = headersView.reduce((row, headerView) => {
                const value = typeof headerView.value === 'function'
                    ? headerView.value(item)
                    : item[headerView.key];
                return { ...row, [headerView.key]: value }
            }, {});
            return { ...item, ...headersViewData }
        })];
        // SORT RULES - Multiple Sort should work here
        // positive = B first
        // negative = A first
        return sortedHeaders.length === 0? sortData: sortData.sort((a, b) => {
            const result = [];
            for(const h of sortedHeaders) {
                const varA = h.sort === 'asc'? a[h.key]: b[h.key];
                const varB = h.sort === 'asc'? b[h.key]: a[h.key];
                let answer = 0;
                if (typeof varA === "number") {
                    answer = varA - varB;
                } else {
                    if (typeof varA === "object" && typeof varB === "object" && dayjs(varA).isValid() && dayjs(varB).isValid()) {
                        answer = new Date(varA) - new Date(varB);
                    } else {
                        if (varA && varB) {
                            answer = varA.localeCompare(varB);
                        }
                    }
                }
                
                result.push(answer);
            }
            // Return the first non-falsy value.
            return result.find(item => !!item) || 0;
        });
    }

    const dataView = useMemo(() => {
        // Get fields required each row/item.
        const populatedData = populateData(data)
        const requiredData = populatedData.map((item, index) => {
            return headersView.reduce((row, headerView) => {
                const value = item[headerView.key];
                const displayValue = typeof headerView.render === 'function'? headerView.render(value, index, item, populatedData, info): value;
                return { ...row, [headerView.key]: displayValue }
            }, {});
        });
        return requiredData;
    }, [data, headersView]);

    const toggleSort = (currentSort) => {
        if (!currentSort) return 'asc';
        if (currentSort === 'asc') return 'desc';
        if (currentSort === 'desc') return null;
    }

    const changeHeaderSort = (headerKey, order, reset = false) => {
        setHeadersView(prev => {
            return prev.map(headerView => {
                if (headerView.key === headerKey) {
                    headerView.sort = order?? toggleSort(headerView.sort);
                    return headerView;
                }
                if (multiSort === true) {
                    if (reset === false) return headerView;
                }
                // RESET others if MultiSort = FALSE || RESET = TRUE
                // RESET others if MultiSort = TRUE and reset = TRUE
                headerView.sort = null;
                return headerView;
            });
        });
    }

    const changeHeaderFilter = () => {}

    const renderHeader = (headerKey) => {
        const headerView = headersView.find(header => header.key === headerKey);
        const classNames = [];
        if (React.isValidElement(headerView?.header)) return headerView?.header;
        if (headerView?.sort) classNames.push('sort-active');
        return <span key={`header-${headerKey}`} className={classNames.join(' ')}>{ headerView?.header }</span>;
    }

    const renderSort = (headerKey) => {
        const headerView = headersView.find(header => header.key === headerKey);
        const currentSort = headerView?.sort;
        const commonStyle = { 
            fontSize: "12px",
            display: "block",
            position: "relative"
        };
        return <span key={`sort-${headerKey}`} style={{ display: 'flex', flexDirection: 'column' }}>
            <OutlinedIcon style={{ ...commonStyle, top: '2.5px', color: currentSort === 'asc'? '#51b68a': '#d2d2d2' }} type="up" />
            <OutlinedIcon style={{ ...commonStyle, bottom: '2.5px', color: currentSort === 'desc'? '#51b68a': '#d2d2d2' }} type="down" />
        </span>
    }

    const renderSortCustom = (headerKey, renderComponent) => {
        const headerView = headersView.find(header => header.key === headerKey);
        return renderComponent(headerView);
    }

    const renderHeaderCustom = (headerKey, renderComponent) => {
        const headerView = headersView.find(header => header.key === headerKey);
        return renderComponent(headerView);
    }

    const renderHeaderKeyBatch = (headerKey, ...batch) => {
        return batch.map(fn => fn(headerKey));
    }

    return {
        renderHeader,
        renderSort,
        changeHeaderSort,
        changeHeaderFilter,
        renderHeaderKeyBatch,
        headersView,
        dataView
    }
}

export default useTable;