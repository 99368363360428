export default function(role) {
  return {
    canCreateProperty: ["ope_admin", "internal_user"].includes(role),
    canEditProperty: [
      "ope_admin",
      "internal_user",
      "hotel_admin",
      "regular_user"
    ].includes(role),
    canMapPropertyToUser: [
      "ope_admin",
      "internal_user",
      "hotel_admin"
    ].includes(role),
    canEditPropertyStatus: ["ope_admin", "internal_user"].includes(role),
    canEditPerformanceWeights: ["ope_admin", "hotel_admin"].includes(role),
    canManageUsers: ["ope_admin", "internal_user", "hotel_admin"].includes(
      role
    ),
    canCreateUsers: ["ope_admin", "internal_user", "hotel_admin"].includes(
      role
    ),
    canChangePermissions: ["ope_admin", "internal_user"].includes(role)
  };
}
