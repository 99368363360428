import React from "react";
import constants from "../../../../constants";
// import moment from "moment";
import dayjs from "dayjs";
import useCalendar from "../../../Calendar/useCalendar";
import { isSameDate } from "./helper";
import OutlinedIcon from "elements/Icon/OutlinedIcon";
import { useSelector, useDispatch } from "react-redux";
import "./StayDateNav.scss";

const NavNext = ({ disabled = false }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.mainReducer);
    const dashboard = useSelector(state => state.dashboard);

    const { dailyData, weeklyData, monthlyData, customDailyData } = dashboard;
    const { sidebarData, activeCalendarView } = state;
    const { toggleCell, handleChangeCheckinStart } = useCalendar();

    const goNext = () => {
        if (disabled) return;
        if (activeCalendarView == "custom") {
            const currentIndex = customDailyData.findIndex(item => dayjs(item.cin).isSame(sidebarData.check_in, 'day'));
            if (customDailyData[currentIndex + 1]) toggleCell(customDailyData[currentIndex + 1], "daily");
        } else if (activeCalendarView == "daily") {
            const lastDayOfCalendar = dayjs(dailyData[dailyData.length -1]?.check_in).utc(true);
            const nextDay = dayjs(sidebarData.check_in).add(1, "day").utc(true);
            if (nextDay.isAfter(lastDayOfCalendar, "day")) {
                // Reload dashboard. Let the Main Component (Daily & Aggregate) handle the toggling of cell.
                dispatch({
                    type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                    value: `${nextDay.format()}_nav`,
                });
                handleChangeCheckinStart(lastDayOfCalendar.add(1, "day").toDate());
                return;
            }

            const nextDayData = dailyData.find(item => isSameDate(item.check_in, nextDay));
            if (nextDayData) toggleCell(nextDayData, "daily");
        } else if (activeCalendarView == "weekly") {
            const lastDayOfCalendar = dayjs(weeklyData[weeklyData.length -1]?.dateRange[1]).utc(true);
            const nextDay = dayjs(sidebarData.dateRange[1]).add(1, "day").utc(true);
            if (nextDay.isAfter(lastDayOfCalendar, "day")) {
                // Reload dashboard. Let the Main Component (Daily & Aggregate) handle the toggling of cell.
                dispatch({
                    type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                    value: `${nextDay.isoWeek()}_${nextDay.year()}_end_nav`
                });
                handleChangeCheckinStart(lastDayOfCalendar.add(1, "day").toDate());
                return;
            }

            const nextWeek = weeklyData.find(item => isSameDate(item.dateRange[0], nextDay));
            if (nextWeek) toggleCell(nextWeek, "weekly");
        } else if (activeCalendarView == "monthly") {
            const lastDayOfCalendar = dayjs(monthlyData[monthlyData.length -1]?.dateRange[1]).utc(true);
            const nextDay = dayjs(sidebarData.dateRange[1]).add(1, "day").utc(true);
            if (nextDay.isAfter(lastDayOfCalendar, "day")) {
                // Reload dashboard. Let the Main Component (Daily & Aggregate) handle the toggling of cell.
                dispatch({
                    type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                    value: `${nextDay.format("MMMM")}_${nextDay.year()}_end_nav`
                });
                handleChangeCheckinStart(lastDayOfCalendar.add(1, "day").toDate());
                return;
            }
            
            const nextMonth = monthlyData.find(item => isSameDate(item.dateRange[0], nextDay));
            if (nextMonth) toggleCell(nextMonth, "monthly");
        }
    }

    return <OutlinedIcon type='right' className="stay-date-navigator-next" disabled={disabled} onClick={goNext}/>
}

export default NavNext;