import React from 'react';
import { DatePicker as AntDatePicker} from 'antd';

const DatePicker = ({
    picker,
    defaultValue,
    value,
    format,
    style,
    disabledDate,
    suffixIcon,
    allowClear,
    cellRender,
    onChange,
}) => {
    return <AntDatePicker
        picker={picker}
        defaultValue={defaultValue}
        value={value}
        format={format}
        style={style}
        disabledDate={disabledDate}
        suffixIcon={suffixIcon}
        allowClear={allowClear}
        cellRender={cellRender}
        onChange={onChange}
    />
}


export default DatePicker;