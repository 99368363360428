import React, { useState, useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";



const DropdownMenu = ({ items, label, closeOnClick = true }) => {
    const [showMenu, toggleShowMenu] = useState(false);
    const node = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClick = (e) => {
        if (node.current.contains(e.target) && closeOnClick) {
            return;
        }
        toggleShowMenu(false);
    };

    return (
        <div
            className="dropdown"
            onClick={() => {
                toggleShowMenu(!showMenu);
            }}
            ref={node}
        >
            {label}
            <CSSTransition
                in={showMenu}
                timeout={300}
                classNames="dropdown__menu"
                unmountOnExit
            >
                {
                    <div className="dropdown__menu">
                        {Array.isArray(items)
                            ? items.map((item) => {
                                return (
                                    <div className="dropdown__item" key={item}>
                                        {item}
                                    </div>
                                );
                            })
                            : items}
                    </div>
                }
            </CSSTransition>
        </div>
    );
};

export default DropdownMenu;
