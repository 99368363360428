import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { Exit } from "Icons";
import FormModalField from "./FormModalField";
import useFormModalController from './useFormModalController';

const FormModal = (props) => {
    const { width, maskClosable, onClose, onSubmit, title, controller } = props;
    const { visible, setValidations } = controller;

    useEffect(() => {
        /**
         * Other FormModalField's configuration will be handled here such as validations
         * This design was implemented to make the component rely on FormModalField only. Not on other list of fields(array). Just add FormModalField and make the configurations complete.

         * Find all FormModalFields in props.children
         * Condition: child.type.name === "FormModalField"
         * Search from: props.children (Nested)
         */
        const findFormModalFields = (props, fields = []) => {
            const fn = (child, fields) => {
                if (!child || typeof child !== 'object') return;

                if (child.type?.name === "FormModalField")
                    fields.push({
                        name: child.props.name,
                        value: child.props.value,
                        validations: child.props.validations
                    });
                if (child.props && child.props.children)
                    findFormModalFields(child.props, fields);
            }
            if (props.children) {
                if (Array.isArray(props.children)) {
                    props.children.forEach(child => fn(child, fields));
                } else {
                    fn(props.children, fields)
                }
            }

            return fields;
        }

        const validations = {};
        findFormModalFields(props).forEach(field => {
            if (field.validations) {
                validations[field.name] = field.validations;
            }
        });

        setValidations(validations);
    }, [visible]);

    return (
        <Modal
            open={visible} width={width} maskClosable={maskClosable} footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={onClose}
        >
            <form onSubmit={onSubmit} className="light-form">
                <div className="rms-modal-content">
                    <div className="rms-modal-header">
                        <h3>{title}</h3>
                    </div>
                    {props.children}
                </div>
            </form>
        </Modal>
    )
}

FormModal.Field = FormModalField;
FormModal.newController = useFormModalController;

export default FormModal;