import React from 'react';
import { NavLink } from "react-router-dom";
import DropdownMenu from "elements/Menu/DropdownMenu";

import { useDownload } from "hooks/useDownload";
import { usePreferences } from 'hooks/usePreferences';
import { useWindow } from 'hooks/useWindow';

import { Account, Download } from "Icons";
import { TutorialSpot } from "../Tutorial/index";




const ProfileNav = ({ history, showSpot, users, eventHandler }) => {

    const { download, isPending } = useDownload();
    const { showDownloadables } = usePreferences();
    const { isLocal } = useWindow();


    const ProfileMenu = () => {

        const accountSettingsLink = (
            <NavLink exact activeClassName="active" to="/account">
                <div className="dropdown__item" key={1}>
                    {users.authUser && users.authUser.name}
                </div>
            </NavLink>
        );

        const adminConsoleLink = (
            <NavLink exact activeClassName="active" to="/admin">
                <div className="dropdown__item" key={2}>
                    Admin Console
                </div>
            </NavLink>
        );

        const manageUsersLink = (
            <NavLink exact activeClassName="active" to="/users">
                <div className="dropdown__item" key={2}>
                    Manage Users
                </div>
            </NavLink>
        );

        const propertyListLink = (
            <NavLink exact activeClassName="active" to="/">
                <div className="dropdown__item" key={3}>
                    Go to Property List
                </div>
            </NavLink>
        );

        const logoutComp = (
            <div className="dropdown__item" key={4} onClick={() => { eventHandler({ action: "logout" }) }}>
                Logout
            </div>
        );

        return <div>
            {accountSettingsLink}
            {(users.authUser && users.authUser.type === "ope_admin") && adminConsoleLink}
            {users.permissions.canManageUsers && manageUsersLink}
            {propertyListLink}
            {logoutComp}
        </div>
    }

    return (<>
        {!history.location.pathname.includes("/dashboard") &&
            showSpot && (
                <TutorialSpot
                    section="dashboard"
                    type="avatar"
                    bottom="10px"
                    left="-20px"
                />)}
        {showDownloadables && isLocal && (
            <Download width="32px" height="32px" className={isPending ? "" : "pointer"}
                style={{ margin: 'auto 8px', filter: isPending ? 'grayscale(100%)' : undefined }}
                onClick={isPending ? null : () => download('app-screen', 'pdf', 'app')}
            />
        )}
        <span className="avatarTutorial">
            <DropdownMenu
                items={<ProfileMenu />}
                label={<Account height="32px" width="32px" className="pointer" style={{ marginTop: '4px' }} />}
            />
        </span>
    </>)
}

export default ProfileNav;