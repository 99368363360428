import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";

import DailyCell from "./DailyCell";
import DailyTable from "./DailyTable";
import Switch from "../../Switch/Switch";
import Tabs from "../../Tabs";
import MonthYearNav from "../MonthYearNav";
import useCalendar from "../useCalendar";
import ViewSettings from "../../ViewSettings/ViewSettings";
import { ExportCalendarButton } from "../../ExportData/";
import { Calendar, List, Download } from "Icons";

import { useDownload } from "hooks/useDownload";
import { usePreferences } from "hooks/usePreferences";
import constants from "../../../constants";
import useHelperFunctions from "../../../store/actions/actions";
import useDashboardActions from "../../../store/actions/dashboardActions";
import usePropertiesActions from "store/actions/propertiesActions";
import { getFirstdayOfWeek, getLastdayOfWeek, isNullOrUndefined } from "../../../utils";
import { defaultViewTabs, defaultDailyTabs, generateFilter } from "../../../utils/calendar";
import NotesModal from "components/Dashboard/components/NotesModal";

const Daily = ({ toggleCell }) => {
    const state = useSelector(state => state.mainReducer);
    const { loading, dailyData, error, source } = useSelector(state => state.dashboard);
    const { notesSummary } = useSelector(state => state.properties);
    const { ADRState, activeCell, calendar_data, calendar_cache, activeHotel,
        hotelCode, hotelId, activeMainData, activeViewType, notesModalState } = state;
    const { as_of, pick_up, pace, pace_string, check_in_start, check_in_end, segments, roomtype } = calendar_data;

    const dispatch = useDispatch();
    const { changeMonth, changeYear, handleChangeCheckinStart } = useCalendar();
    const { getDailyData, getVarianceAvgs } = useDashboardActions();
    const { setADRState, toggleChartModal, setNotesModalState } = useHelperFunctions();
    const { getNotesSummary } = usePropertiesActions();
    const { download, isPending } = useDownload();
    const { user, useCachedDailyData, useSingleAsOfAvgsForVariances, showDownloadables } = usePreferences();
    const [currentData, setCurrentData] = useState([]);

    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const viewTab = defaultViewTabs();
    const dailyTabs = defaultDailyTabs(pace_string);
    const checkin = [getFirstdayOfWeek(check_in_start), getLastdayOfWeek(check_in_end)]
    const group = state.propertyInView.id;
    let filter = generateFilter(hotelId, as_of, checkin, pick_up, pace, segments, roomtype, user?.prefs, group, pace_string);
    filter = { ...filter, cancelToken: source.token };
    const hideDownloadables = true;
    const pdf = {
        property: activeHotel, asof: as_of, pickup: pick_up, pace: pace_string, ss: segments, rts: roomtype,
        cin: `${dayjs(check_in_start).format("MMMM DD, YYYY")} - ${dayjs(check_in_end).format("MMMM DD, YYYY")}`
    };


    let placeHolder = [];
    const placeHolderDays = [1, 2, 3, 4, 5, 6, 0];
    for (let i = 0; i < 5; i++) {
        for (let j = 0; j < placeHolderDays.length; j++) {
            placeHolder.push(
                <DailyCell
                    key={j + "-" + i}
                    selected={activeCell}
                    dayOfWeek={placeHolderDays[j]}
                    status={"disabled"}
                    dataGet={null}
                    data={null}
                />
            );
        }
    }

    useEffect(() => {
        if (calendar_cache.daily_filter !== calendar_data) {
            getDailyData(filter).then((res) => {
                getVarianceAvgs({ ...filter, single: useSingleAsOfAvgsForVariances });
                // Used stay date navigator from dashboard.
                activeCell && (res?.response?.data?.data?? []).forEach(item => {
                    if (dayjs(item.check_in).isSame(dayjs(activeCell.replace("_nav", "")), "day")) {
                        toggleCell(item, "daily");
                        return;
                    }  
                });
            });
        }

        getNotesSummary(filter.property, filter.checkin[0], filter.checkin[1]);
    }, [calendar_data]);

    useEffect(() => {
        const dailyDataWithNotes = dailyData.map(item => {
            if (!(notesSummary.length > 0)) return item;
            return { ...item, note_type: notesSummary.find(note => dayjs(note.date).isSame(item.cin, 'day'))?.type };
        });

        setCurrentData(dailyDataWithNotes);
        // useCachedDailyData's purpose is to prevent reloading/fetching of daily calendar when changing the view then changing back to daily.
        if (useCachedDailyData) {
            dispatch({
                type: constants.UPDATE_CALENDAR_CACHE,
                value: { ...calendar_cache, daily: dailyDataWithNotes, daily_filter: calendar_data }
            });
        }
    }, [dailyData, notesSummary]);

    const activateCell = (data, index) => {
        let newData = currentData.map((item) => {
            return {
                ...item,
                selected: false,
            };
        });
        data.selected
            ? newData[index].selected = false
            : newData[index].selected = true;
        setCurrentData(newData);
        toggleCell(data, "daily");
        dispatch({ type: constants.SHOW_VISUALS, value: false })
        toggleChartModal({ show: false })
    };

    const toggleADR = () => setADRState(!ADRState);
    const setSelectedView = (value) => {
        dispatch({
            type: constants.TOGGLE_CALENDAR_TABLE_VIEW,
            value: value,
        });
    };

    const changeMainData = (value) => {
        dispatch({
            type: constants.CHANGE_SHOWN_MAIN_DATA,
            value: value,
        });
    };

    return (
        <div className="daily-view-container">
            {/* Views */}
            <div className="tabs-container">
                <Tabs
                    tabs={dailyTabs}
                    activeTab={activeMainData}
                    selectTab={changeMainData}
                    callback={() => {}}
                    inputClassName={"daily-tabs"}
                />
                <ViewSettings />
            </div>
            {/* Navigation */}
            <div data-html2canvas-ignore className="calendar-header">
                <MonthYearNav
                    monthValue={dayjs(check_in_start)}
                    yearValue={dayjs(check_in_start).format("YYYY")}
                    handleChange={handleChangeCheckinStart}
                    isLoading={loading}
                    changeMonth={changeMonth}
                    changeYear={changeYear}
                />

                <div
                    className="filter3Tutorial rms-switch font-md"
                    style={{ position: "relative" }}
                >
                    <Calendar width="23px" height="23px" className="pointer" fill={activeViewType === "cells" ? "#67b489" : "#fff"}
                        style={{ margin: 'auto 4px' }} onClick={() => setSelectedView("cells")} />
                    <List width="24px" height="24px" className="pointer" fill={activeViewType === "table" ? "#67b489" : "#fff"}
                        style={{ margin: 'auto 4px' }} onClick={() => setSelectedView("table")} />
                    {showDownloadables && !hideDownloadables && (
                        <Download width="24px" height="24px" className={isPending ? "" : "pointer"}
                            style={{ margin: 'auto 4px', filter: isPending ? 'grayscale(100%)' : undefined }}
                            onClick={() => {
                                const property = activeHotel.toLowerCase().replace(/,/g, '').replace(/ /g, '-');
                                const filename = `${property}-daily-${activeMainData}-${activeViewType}-report`;
                                download(filename, 'pdf', 'calendar', pdf);
                            }}
                        />
                    )}
                    <span style={{ marginLeft: "32px" }} />
                    <span data-html2canvas-ignore>ADR</span>
                    <Switch checked={ADRState} onChange={toggleADR} />
                </div>
            </div>

            {/* Calendar View */}
            {activeViewType === "cells" && (
                <div className="daily-calendar-container">

                    {/* Days of the Week */}
                    <div className="date-of-week">
                        {daysOfWeek.map(item => <div key={item} className="header"><span>{item}</span></div>)}
                    </div>

                    {/* Data Cells */}
                    <div className="date-grid" style={{ display: !loading && currentData?.length === 0 && "block" }}>

                        {/* While Loading */}
                        {loading && placeHolder.map((d) => d)}

                        {/* After Loading */}
                        {!loading &&
                            currentData?.length > 0 &&
                            currentData.map((data, index) => {
                                const dayOfWeek = dayjs(data.check_in).day();
                                const thisMonth = dayjs(data.check_in).format("MMMM") === dayjs(check_in_start).format("MMMM");

                                const pickup = Math.abs(pick_up);
                                const startOfAnalyzingDates = dayjs(as_of).subtract(pickup, "days").toDate();
                                const thisDay = dayjs(data.check_in).toDate();

                                let status = "";
                                if (startOfAnalyzingDates > thisDay) status = "disabled";
                                if (status !== "disabled" && !thisMonth) status = "offwhite";

                                return (
                                    <DailyCell
                                        key={index}
                                        activeCell={activeCell}
                                        selectedTab={activeMainData}
                                        dayOfWeek={dayOfWeek ? dayOfWeek : placeHolderDays[index]}
                                        status={status}
                                        data={data}
                                        onClick={e => activateCell(data, index)}
                                        ADRState={ADRState}
                                    />
                                );
                            })
                        }

                        {/* No Data */}
                        {!loading && !error && currentData?.length === 0 && (
                            <div className="no-data">No data found for this property</div>
                        )}
                        {!loading && error && (
                            <div className="no-data">{error}</div>
                        )}
                    </div>
                </div>
            )}


            {/* Table View */}
            {activeViewType === "table" && (
                <div className="daily-table-container">
                    <DailyTable
                        selectedTab={activeMainData}
                        tabs={dailyTabs}
                        data={currentData}
                        onClick={index => activateCell(currentData[index], index)}
                        isLoading={loading}
                    />
                    <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "24px" }}>
                        <ExportCalendarButton className="pointer" data={dailyData} />
                    </div>
                </div>
            )}

            <NotesModal 
                visible={notesModalState.visible} 
                startDate={notesModalState.startDate}
                endDate={notesModalState.endDate}
                onClose={() => {
                    getNotesSummary(state.propertyInView.id, checkin[0], checkin[1]);
                    setNotesModalState({ visible: false });
                }}
            />
        </div>
    );
};

export default Daily;