import React, { useState, useRef, useEffect } from "react";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";



export default ({
    children,
    itemsSize,
    itemsLength,
    lazyLoad = null,
    loadMore,
    isLoadingatStart,
    isLoadingatEnd,
    isLoading,
    callback,
    lazyloadBackwards,
    height = 300,
    sliderPosition = 0
}) => {
    const containerRef = useRef(null);
    const itemsRef = useRef(null);
    const [arrowVisibility, setArrowVisibility] = useState(false);
    const [numberOfMovementAllowed, setNumberOfMovementAllowed] = useState(0);
    const [itemsWidth, setItemsWidth] = useState(0);

    useEffect(() => {
        setItemsWidth(itemsSize * itemsLength);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsLength]);

    useEffect(() => {
        const handleArrowVisibility = () => {
            if (
                containerRef &&
                containerRef.current &&
                containerRef.current.clientWidth
            ) {

                if (containerRef.current.clientWidth <= itemsWidth) {
                    setArrowVisibility(true);
                    setNumberOfMovementAllowed(
                        Math.ceil(
                            (itemsWidth - containerRef.current.clientWidth) / itemsSize
                        )
                    );
                } else {
                    if (arrowVisibility) {
                        setArrowVisibility(false);
                        setNumberOfMovementAllowed(0);
                    }
                }
            }
        };

        handleArrowVisibility();
        window.addEventListener("resize", () => {
            handleArrowVisibility();
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerWidth, itemsWidth]);

    return (
        <div className="slider-container">
            {/* left arrow */}
            <div
                data-html2canvas-ignore
                className="icon slider-arrow slider-arrow--left"
                onClick={() => {
                    //move left without loading more
                    if (!lazyloadBackwards) {
                        callback(sliderPosition - 1);
                    }
                    //move left to load more
                    if (lazyloadBackwards) {
                        callback(0);
                        loadMore("start");
                    }
                }}
            >
                <div className="icon__wrapper">
                    <i className="fas fa-caret-left fa-2x" />
                </div>
            </div>
            {/* {sliderPosition > 0 || lazyloadBackwards ? (
                <div
                    data-html2canvas-ignore
                    className="icon slider-arrow slider-arrow--left"
                    onClick={() => {
                        //move left without loading more
                        if (!(isLoadingatEnd || isLoadingatStart) && !lazyloadBackwards) {
                            callback(sliderPosition - 1);
                        }
                        //move left to load more
                        if (lazyloadBackwards && !(isLoadingatEnd || isLoadingatStart)) {
                            callback(0);
                            loadMore("start");
                        }
                    }}
                >
                    <div className="icon__wrapper">
                        <i className="fas fa-caret-left fa-2x" />
                    </div>
                </div>
            ) : (
                ""
            )} */}

            {/* right arrow */}
            {(arrowVisibility && sliderPosition < numberOfMovementAllowed) ||
                lazyLoad ? (
                <div
                    data-html2canvas-ignore
                    className="icon slider-arrow slider-arrow--right"
                    ref={itemsRef}
                    onClick={() => {
                        if (
                            sliderPosition <= numberOfMovementAllowed &&
                            !(isLoadingatEnd || isLoadingatStart)
                        ) {
                            callback(sliderPosition + 1);
                        }
                        if (
                            sliderPosition >= numberOfMovementAllowed &&
                            !(isLoadingatEnd || isLoadingatStart)
                        ) {
                            loadMore("end");
                        }
                    }}
                >
                    <div className="icon__wrapper">
                        <i className="fas fa-caret-right fa-2x" />
                    </div>
                </div>
            ) : (
                ""
            )}
            <div className="slider" ref={containerRef}>
                <div
                    className="slider-items-container"
                    style={{
                        transform: `translateX(-${itemsSize * sliderPosition}px)`,
                        width: `${itemsWidth + 1000}px`,
                        minHeight: height + "px"
                    }}
                >
                    {children}
                </div>
                {isLoadingatEnd && (
                    <div className="loader">
                        <LoadingSpinner size={"40px"} />
                    </div>
                )}
            </div>
        </div>
    );
};
