// import moment from "moment";
import dayjs from "dayjs";
import { withCurrency, formatData } from "../../../../utils";

export const getStatus = (status) => {
    if (status === "NEW") return "Confirmed";
    if (status === "MODIFIED") return "Modified";
    if (status === "CANCELLED") return "Cancelled";
    return "N/A";
}

export const checkIfMoment = value => typeof value == "object" && dayjs(value).isValid();

export const transformReservationsData = (property, data, noCurrency = false) => {
    if (!Array.isArray(data)) return [];

    const transformedData = JSON.parse(JSON.stringify(data)).map(reservation => {
        if (reservation == null) return;

        const diff = dayjs(reservation.check_out_date).diff(dayjs(reservation.check_in_date), "days");
        const nights = reservation.nights? reservation.nights: (diff > 0? diff: 1);

        return {
            reservation_id: reservation.reservation_id,
            guest_name: reservation.guest_name,
            status: `${getStatus(reservation.status)}${reservation.is_stay_date_cancelled && reservation.status != "CANCELLED" ? "-Cancelled": ""}`,
            arrival: dayjs(reservation.check_in_date),
            departure: dayjs(reservation.check_out_date),
            segment_code: reservation.segment_code,
            segment_name: reservation.segment_name,
            room_count: reservation.room_count,
            total_occ: reservation.total_occ,
            total_rev: reservation.total_rev,
            total_rate: reservation.total_rate,
            nights: nights,
            adr: withCurrency(formatData(reservation.total_rate / nights), noCurrency? false: property.currency)
        };

    });

    return transformedData;
};

export const populateData = (headers, data) => {
    if (!Array.isArray(data)) return [];

    let result = [...data];
    const keys = [];

    ["reservation_id", "guest_name", "status", "total_occ", "segment_code", "segment_name", "arrival", "departure", "adr"].forEach(key => {
        if (Object.keys(headers).includes(key)) keys.push(key);
    })

    if (keys.some(key => (headers[key]?.filter?? []).length > 0)) {
        result = result.filter(item => {
            let include = true;
            for(let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if ((headers[key].filter?? []).length == 0) continue;
                if (!(headers[key].filter?? []).includes(
                    checkIfMoment(item[key])? item[key].format("MMM DD, YYYY"): item[key]
                )) {
                    include = false;
                }
            }
            return include;
        })
    }
    
    if (keys.some(key => ["asc", "desc"].includes(headers[key]?.sort))) {
        result.sort((a, b) => {
            for(let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const fieldA = a[key], fieldB = b[key];
                const sortValue = headers[key].sort;
                if (sortValue) {
                    if (typeof fieldA === "number") {
                        if (sortValue === "desc") {
                            if ((fieldA - fieldB) < 0) return -1;
                            if ((fieldA - fieldB) > 0) return 1;
                            return 0;
                        }
                        if (sortValue === "asc") {
                            if ((fieldB - fieldA) < 0) return -1;
                            if ((fieldB - fieldA) > 0) return 1;
                            return 0;
                        }
                    } else {
                        if (typeof fieldA === "object" && typeof fieldB === "object" && dayjs(fieldA).isValid() && dayjs(fieldB).isValid()) {
                            if (sortValue === "desc") {
                                if (fieldB.isBefore(fieldA)) return -1;
                                if (fieldB.isAfter(fieldA)) return 1;
                                return 0;
                            }
                            if (sortValue === "asc") {
                                if (fieldA.isBefore(fieldB)) return -1;
                                if (fieldA.isAfter(fieldB)) return 1;
                                return 0;
                            }
                        } else {
                            if (sortValue === "desc") {
                                if (fieldA && fieldB && fieldB.localeCompare(fieldA) < 0) return -1;
                                if (fieldA && fieldB && fieldB.localeCompare(fieldA) > 0) return 1;
                                return 0;
                            }
                            if (sortValue === "asc") {
                                if (fieldA && fieldB && fieldA.localeCompare(fieldB) < 0) return -1;
                                if (fieldA && fieldB && fieldA.localeCompare(fieldB) > 0) return 1;
                                return 0;
                            }
                        }
                    }
                }
            }
        });
    }

    return result;
}