import React from "react";
import PropTypes from "prop-types";

const RmsIcon = ({ type, size, className, position = {}, color = "green" }) => {
  let iconType = "";
  let width = 18;

  switch (size) {
    case "medium":
      width = 26;
      break;
    case "large":
      width = 32;
      break;
    case "x-large":
      width = "40";
      break;
    default:
      width = 18;
  }

  switch (type) {
    case "add-far":
      iconType = "add-far";
      break;
    case "add-fas":
      iconType = "add-fas";
      break;
    case "burger":
      iconType = "burger";
      break;
    case "cross-far":
      iconType = "cross-far";
      break;
    case "dashboard":
      iconType = "dashboard";
      break;
    case "edit":
      iconType = "edit";
      break;
    case "gear":
      iconType = "gear";
      break;
    case "location":
      iconType = "location";
      break;
    case "person":
      iconType = "person";
      break;
    case "question":
      iconType = "question";
      break;
    case "redo":
      iconType = "redo";
      break;
    case "search":
      iconType = "search";
      break;
    default:
      console.log("Type is invalid");
  }

  return (
    <img
      src={`${process.env.PUBLIC_URL}/icons/${iconType}.png`}
      alt={iconType}
      width={width}
      className={`${className} rms-icon`}
      style={{
        position: "relative",
        ...position,
        filter: color === "white" && "brightness(0) invert(1)",
      }}
    />
  );
};

RmsIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.object,
  color: PropTypes.string,
};

export default RmsIcon;
