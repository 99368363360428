import React, { useEffect, useState, useRef } from 'react';
import { AddFilled } from "Icons";
import Button from "../../Button/Button";
import DataGrid from '../../DataGrid';
import adminConsoleActions from "../../../store/actions/adminConsoleActions";
import FormModal from "../../FormModal/FormModal";
import './RoomTypes.scss';

const RoomTypes = () => {
    const _mode = { ADD: 'Add', EDIT: 'Edit', DELETE: 'Delete' };
    const roomTypesGridRef = useRef();
    const { getRoomTypes, getRoomType, addRoomType, updateRoomType, deleteRoomType } = adminConsoleActions();
    const [roomTypesModalState, setRoomtypeModalState] = useState({
        visible: false,
        mode: _mode.ADD,
        selectedRowIdx: null
    });
    const roomTypeModalController = FormModal.newController({}, roomTypesModalState.visible);
    const {
        errors,
        inputValues,
        setValidations,
        onChangeHandler,
        onBlurHandler
    } = roomTypeModalController;
    const showModal = (visible, mode, rowIdx) => {
        setRoomtypeModalState({...roomTypesModalState, 
            visible: visible, 
            selectedRowIdx: rowIdx, 
            mode: mode
        });
    }
    const closeModal = () => {
        setRoomtypeModalState({...roomTypesModalState, visible: false});
    }
    const doneSubmit = (result, type) => {
        if (result.type == type) {
            roomTypeModalController.show(false);
            loadRoomTypesGrid();
        }
    }
    const loadRoomTypesGrid = () => {
        roomTypesGridRef.current.setLoading(true);
        getRoomTypes().then(response => {
            const data = [];
            if (Array.isArray(response.data?.data)) {
                response.data.data.forEach(room_type => {
                    data.push({
                        room_type_code: room_type._id,
                        room_type_name: room_type.name
                    });
                });
            }
            roomTypesGridRef.current.loadData(data);
            roomTypesGridRef.current.setLoading(false);
        });
    }
    const submit = (e) => {
        e.preventDefault();
        if (Object.keys(errors).length > 0) return;

        switch(roomTypesModalState.mode) {
            case _mode.ADD: 
                addRoomType({
                    _id: inputValues.room_type_code,
                    name: inputValues.room_type_name
                }).then(result => doneSubmit(result, "ADD_ROOM_TYPE_SUCCESS"));
                break;
            case _mode.EDIT:
                updateRoomType(roomTypesGridRef.current.getGridData(roomTypesModalState.selectedRowIdx).room_type_code, {
                    name: inputValues.room_type_name
                }).then(result => doneSubmit(result, "UPDATE_ROOM_TYPE_SUCCESS"));
                break;
            case _mode.DELETE:
                deleteRoomType(roomTypesGridRef.current.getGridData(roomTypesModalState.selectedRowIdx).room_type_code).then(result => 
                    doneSubmit(result, "DELETE_ROOM_TYPE_SUCCESS")
                );
                break;
        }
    }
    useEffect(() => {
        if (roomTypesModalState.visible === true) {
            let data = null;
            if ([_mode.EDIT, _mode.DELETE].includes(roomTypesModalState.mode)) {
                data = roomTypesGridRef.current.getGridData(roomTypesModalState.selectedRowIdx);
                getRoomType(data.room_type_code).then(result => {
                    let roomType = result.data.data;
                    if (typeof roomType === 'object') {
                        roomTypeModalController.show(true, { 
                            room_type_name: roomType.name, 
                            room_type_code: roomType._id
                        });
                    }
                });
            } else {
                roomTypeModalController.show(true);
            }
        } else {
            roomTypeModalController.show(false);
        }
    }, [roomTypesModalState]);

    useEffect(() => {
        loadRoomTypesGrid();
    }, []);

    return (
        <div className="admin-room-types">
            <div className="add-room-type" style={{display: 'flex', marginBottom: '10px'}}>
                <h4 style={{color: 'white', marginRight: '10px'}}>Add Room</h4>
                <AddFilled
                    onClick={() => { showModal(true, _mode.ADD); }}
                    width="25px"
                    height="25px"
                    className="pointer"
                />
            </div>
            <DataGrid 
                ref={roomTypesGridRef}
                columns={[{
                    id: 'room_type_name',
                    header: 'ROOM NAME',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'room_type_code',
                    header: 'ROOM CODE',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'actions',
                    header: 'ACTIONS',
                    type: 'action',
                    width: '54px',
                    actionFn: {
                        // NOTE: States are private in functional component. Accessing any state here is useless.
                        onEdit: (rowIdx) => {
                            showModal(true, _mode.EDIT, rowIdx);
                        },
                        onDelete: (rowIdx) => {
                            showModal(true, _mode.DELETE, rowIdx);
                        }
                    }
                }]}
                width="500px"
            />
            <FormModal title={`${roomTypesModalState.mode} Room Type`} onSubmit={submit}
                visible={roomTypesModalState.visible} width={500} maskClosable={false} 
                onClose={() => { closeModal(); }}
                setValidations={setValidations} controller={roomTypeModalController}
            >
            {(roomTypesModalState.mode === _mode.ADD || roomTypesModalState.mode === _mode.EDIT) && (<>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Room Name"
                        name="room_type_name" value={inputValues.room_type_name} error={errors.room_type_name}
                        validations={{ required: true, characters: 'alphanumeric_with_space' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Room Code"
                        name="room_type_code" value={inputValues.room_type_code} error={errors.room_type_code} disabled={roomTypesModalState.mode === _mode.EDIT}
                        validations={{ required: true, characters: 'alphanumeric' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
            </>)}
            {roomTypesModalState.mode === _mode.DELETE && (<>
                <div className="light-form delete-segment">
                    <center>
                        <p>Deleting this room type will remove the item from the predefined list for room type and remove all mappings associated with it.</p>
                        <p>Are you sure you want to delete <b>{roomTypesGridRef.current.getGridData([roomTypesModalState.selectedRowIdx])?.room_type_name}</b>?</p>
                    </center>
                </div>
            </>)}
            <div className="light-form-buttons">
                <Button type="bg-light" style={{ marginRight: '8px' }} onClick={() => { closeModal() }}>Cancel</Button>
                <Button btnType="submit" type="primary">
                    {roomTypesModalState.mode === _mode.DELETE? 'DELETE': 'Submit'}
                </Button>
            </div>
            </FormModal>
        </div>
    )
}

export default React.memo(RoomTypes);