import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";

import { Modal, Popover } from "antd";
import Button from "../Button/Button";
import { TutorialSpot } from "../Tutorial/index";
import { Exit } from "Icons";

import constants from "constants.js";
import useHelperFunctions from "../../store/actions/actions";
import useProperties from "../../store/actions/propertiesActions";
import useUsersActions from "../../store/actions/usersActions";
import useAdminConsoleActions from "../../store/actions/adminConsoleActions";
import usePropertyGroupActions from "store/actions/propertyGroupActions";

import { getTimezones } from '../../utils/timezones';
import { getHotelSegmentsCodeName } from "../../utils/segments";
import { useOutsideClickChecker } from "../../utils/hooks";
import { getPace, isNullOrUndefined } from "../../utils";
import {
    hasRoomTypesEnabled, getMappingsWithEnabledRoomTypes,
    getHotelRoomTypes, getHotelMappedRoomTypes
} from "../../utils/roomtypes";
import {
    getUserCustomFilters, getUserCustomFiltersByProperty, saveUserCustomFiltersToLocalStorage
} from "../../utils/calendar";
import iconSave from "../../assets/images/icon-save.png";
import iconFolder from "../../assets/images/icon-folder.png";
import iconExit from "../../assets/images/icon-exit.png";
import AsOfFilter from "./AsOfFilter";
import PickupFilter from "./PickupFilter";
import PaceFilter from "./PaceFilter";
import RoomTypeFilter from "./RoomTypeFilter";
import SegmentFilter from "./SegmentFilter";
import Select from "elements/Select";

const CalendarFilter = () => {
    const { getValidAsOfs, getProperty, getParsers, getInternalRoomTypes } = useProperties();
    const { toggleSidePanel, toggleChartModal } = useHelperFunctions();
    const { retrieveUserCustomFilters, updateUserCustomFilters } = useUsersActions();
    const { getSegments, getSegmentGroups } = useAdminConsoleActions();
    const { loadPropertyGroupState } = usePropertyGroupActions();

    const state = useSelector((state) => state.mainReducer);
    const properties = useSelector((state) => state.properties);
    const users = useSelector((state) => state.users);
    const { source, loading } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();
    const [temporarySettings, setTemporarySettings] = useState({ ...state.calendar_data });
    const [isPropertyUpdated, setIsPropertyUpdate] = useState(false);

    // Custom Filter State Settings
    const [showSaveCustomFilterButton, setShowSaveCustomFilterButton] = useState(false);
    const [showSaveCustomFilterPopover, setShowSaveCustomFilterPopover] = useState(false);
    const [showRemoveCustomFilterPopover, setShowRemoveCustomFilterPopover] = useState(false);
    const [showCustomFilterError, setShowCustomFilterError] = useState(false);
    const [customFilters, setCustomFilters] = useState(getUserCustomFiltersByProperty(getUserCustomFilters(), state.hotelId));
    const [customFilterName, setCustomFilterName] = useState("");
    const [customFilterIndex, setCustomFilterIndex] = useState(null);
    const [showSavedCustomFiltersPopover, setShowSavedCustomFiltersPopover] = useState(false);

    // Segment Filter State Settings
    const SEGMENTGROUPS = constants.SEGMENTGROUPS;
    const INDIVSEGMENTS = constants.INDIVSEGMENTS;
    const ALLROOMTYPES = constants.ALLROOMTYPES;
    const COMPARISON_NONE = { value: "NONE", name: "NONE" };
    const [hotelMappedRoomTypes, setHotelMappedRoomTypes] = useState([]);
    const [comparisonsEnabled, setComparisonsEnabled] = useState(false);
    const [comparisons, setComparisons] = useState([COMPARISON_NONE]);
    const [expandedSegmentFilterNode, setExpandedSegmentFilterNode] = useState([SEGMENTGROUPS]);
    const [parsers, setParsers] = useState([]);
    const [isPartnerEnabled, setIsPartnerEnabled] = useState(false);
    const segmentFilterElementId = ".ant-select-selection.ant-select-selection--multiple";

    const userPrefs = users.authUser.prefs;
    const hideLatestAsOfModal = !isNullOrUndefined(userPrefs) && userPrefs.hideLatestAsOfModal;
    const hideComparisons = !isNullOrUndefined(userPrefs) && (userPrefs.hideComparisons ?? false);


    useEffect(() => {
        setTemporarySettings(state.calendar_data);
    }, [state.calendar_data]);

    useEffect(() => {

        if (!state.isGroup) {
            if (!loading && state.propertyInView) {
                // console.log(isPropertyUpdated);
                const property = state.propertyInView;
                const latestAsOf = property?.parser_info?.code == "integrationpartner"
                ? property.latest_as_of
                : property.last_file_upload?.reservations?.completed_on;
                // console.log(latestAsOf, state.propertyInView)
                if (latestAsOf == null) {
                    setIsPropertyUpdate(false);
                } else {
                    // propertiesLatestAsOfModal is used know if the user is already notified...
                    setIsPropertyUpdate(
                        ((JSON.parse(localStorage.getItem("propertiesLatestAsOfModal")?? null)?.[state.hotelCode])?? true)
                        && !dayjs(latestAsOf).isSameOrAfter(dayjs(), "day")
                    );
                }
            }

            // Re-retrieve all user custom filters
            // Just in case the saved localStorage user custom filters data gets corrupted for some reason
            // But make sure that the userId data is available
            let userId = localStorage.getItem("userId");
            if (!isNullOrUndefined(userId) && userId.length > 0) {
                retrieveUserCustomFilters({ id: userId }).then((res) => {
                    if (res.type === constants.GET_USER_CUSTOM_FILTERS_SUCCESS) {
                        let userCustomFilters = res.response.data.data;
                        let userPropertyCustomFilters = getUserCustomFiltersByProperty(userCustomFilters, state.hotelId);
                        setCustomFilters(userPropertyCustomFilters);
                        saveUserCustomFiltersToLocalStorage(userCustomFilters);
                    }
                });
            }
        }

        getParsers().then(res => setParsers(res.data.data));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.activeHotel]);

    useEffect(() => {
        let selectedParser = parsers.find(item => item._id === state.propertyInView.parser);
        let isIntegrationPartner = selectedParser?.code === "integrationpartner" ?? false;
        if (isIntegrationPartner) setIsPartnerEnabled(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsers, state.propertyInView]);

    // Happens when User selects another hotel
    useEffect(() => {
        if (state.isGroup) {
            loadPropertyGroupState(state.propertyInView._id).then((result) => {
                const propertyGroup = { ...result.response.data.data, group: true };
                dispatch({
                    type: constants.UPDATE_PROPERTY_DATA,
                    value: propertyGroup
                })
                
                dispatch({
                    type: constants.UPDATE_PROPERTY_IN_VIEW,
                    value: propertyGroup
                })
    
                // This will be used to get default days out for dashboard booking chart.
                dispatch({
                    type: constants.UPDATE_VISUALS_BOOKING_CHART_SELECTED_DAYS_OUT,
                    value: propertyGroup.chart_days_out
                })

                Promise.all([getSegments(), getSegmentGroups()]).then(([resultSegments, resultSegmentGroups]) => {
                    const allSegments = resultSegments.data.data;
                    const allSegmentGroups = resultSegmentGroups.data.data;

                    const mappedSegments = allSegments.filter(segment => {
                        for(const property of propertyGroup.properties) {
                            if (property.segments.find(s => s.global == segment._id)) 
                                return true;
                        }
                    });
                    const mappedSegmentGroups = allSegmentGroups.filter(segmentGroup => {
                        if (mappedSegments.find(s => s.segment_group._id == segmentGroup._id)) 
                            return true;
                    });

                    dispatch({
                        type: constants.UPDATE_SEGMENTS_IN_VIEW,
                        value: {
                            ...getHotelSegmentsCodeName(true, mappedSegments, mappedSegmentGroups),
                            is_global: true
                        }
                    });

                    // If there are no hotel segment groups, set Default Expanded Tree Node to individual segments
                    // And if there are no hotel segments, do not set any Default Expanded Tree Node
                    if (!(Array.isArray(mappedSegments) && mappedSegments.length > 0)) setExpandedSegmentFilterNode([]);
                    if (!(Array.isArray(mappedSegmentGroups) && mappedSegmentGroups.length > 0)) setExpandedSegmentFilterNode([INDIVSEGMENTS]);
                });
    
                // Set Room Type Options
                getInternalRoomTypes().then(res => {
                    let rts = res.data.data;
                    if (Array.isArray(rts)) {
                        let hotelMappedRoomTypes = getHotelRoomTypes(rts, state.isGroup);
                        hotelMappedRoomTypes = hotelMappedRoomTypes.filter(item => {
                            if (item == null) return false;
                            for(const property of propertyGroup.properties) {
                                if (property.room_types.find(rt => rt.mappings.find(rtm => {
                                    return rtm.partner_code == "REVANDYOU" && rtm.room_type_code == item.code
                                }))) return true;
                            }
                        });
                        setHotelMappedRoomTypes(hotelMappedRoomTypes);
                    }
                });
    
                // Set Comparison Options
                setComparisonsEnabled(propertyGroup.comparisons_enabled);
                let groupCmps = propertyGroup.comparisons;
                if (groupCmps && Array.isArray(groupCmps) && groupCmps.length > 0) {
                    setComparisons([COMPARISON_NONE, ...groupCmps.map((comparison) => {
                        if (comparison.id !== propertyGroup.id) return { ...comparison, value: comparison.property }
                    })]);
                }
            });

        } else {
            getProperty(state.hotelId).then((res) => {
                if (res && res.type === constants.GET_PROPERTY_SUCCESS) {
                    const fetchedHotel = res.response.data.data;

                    dispatch({
                        type: constants.UPDATE_PROPERTY_DATA,
                        value: fetchedHotel
                    })
                    dispatch({
                        type: constants.UPDATE_PROPERTY_IN_VIEW,
                        value: fetchedHotel
                    })
                    // This will be used to get default days out for dashboard booking chart.
                    dispatch({
                        type: constants.UPDATE_VISUALS_BOOKING_CHART_SELECTED_DAYS_OUT,
                        value: fetchedHotel.chart_days_out
                    })

                    const propertySegments = getHotelSegmentsCodeName(false, fetchedHotel.segments, fetchedHotel.segment_groups);

                    dispatch({
                        type: constants.UPDATE_SEGMENTS_IN_VIEW,
                        value: {
                            ...propertySegments,
                            is_global: false
                        }
                    });

                    // If there are no hotel segment groups, set Default Expanded Tree Node to individual segments
                    // And if there are no hotel segments, do not set any Default Expanded Tree Node
                    if (propertySegments.segment_groups.length === 0) setExpandedSegmentFilterNode([INDIVSEGMENTS]);
                    if (propertySegments.segments.length === 0) setExpandedSegmentFilterNode([]);

                    // Display or Hide Segments Features according to Hotel Settings
                    dispatch({
                        type: constants.SHOW_SEGMENTS_FEATURES,
                        value: isNullOrUndefined(fetchedHotel.segment_status) ? true : fetchedHotel.segment_status
                    })

                    // Display or Hide Room Type Features according to Hotel Settings
                    let hotelMappings = fetchedHotel.mappings;
                    let hasMappingWithRoomTypesEnabled = hasRoomTypesEnabled(hotelMappings);
                    dispatch({
                        type: constants.SHOW_ROOMTYPE_FEATURES,
                        value: hasMappingWithRoomTypesEnabled
                    })

                    // Set Room Type Options
                    const useMappedRoomTypes = false;
                    let hotelMappedRoomTypes = hasMappingWithRoomTypesEnabled
                        ? useMappedRoomTypes
                            ? getHotelMappedRoomTypes(
                                fetchedHotel.room_types,
                                getMappingsWithEnabledRoomTypes(hotelMappings)[0].partner_code
                            )
                            : getHotelRoomTypes(fetchedHotel.room_types)
                        : getHotelRoomTypes(fetchedHotel.room_types)
                    hotelMappedRoomTypes = hotelMappedRoomTypes.filter(item => item !== undefined);
                    setHotelMappedRoomTypes(hotelMappedRoomTypes);

                    // Set Comparison Options
                    setComparisonsEnabled(fetchedHotel.comparisons_enabled);
                    let cmps = fetchedHotel.comparisons;
                    if (cmps && Array.isArray(cmps) && cmps.length > 0) {
                        setComparisons([COMPARISON_NONE, ...cmps.map((comparison) => {
                            return { ...comparison, value: comparison.property }
                        })]);
                    }
                }
            })

            // Re-retrieve user custom filters from local storage
            // Then filter them according to the selected hotel
            setCustomFilters(getUserCustomFiltersByProperty(getUserCustomFilters(), state.hotelId));

            // Hide 'Apply' and 'Save custom filter' buttons
            setShowSaveCustomFilterButton(false);
            dispatch({
                type: constants.SET_CALENDAR_WITH_CHANGES,
                value: false,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.propertyInView._id])


    const handleChange = (type, value) => {
        let newValue = {};

        if (type === "as_of") {
            newValue = {
                ...temporarySettings,
                [type]: value,
                pace: getPace(temporarySettings.pace, value),
            };
        } else if (type === "pace") {
            newValue = {
                ...temporarySettings,
                [type]: getPace(value, temporarySettings.as_of),
                pace_string: value,
            };
        } else if (type === "segments") {
            // let selectedSegments = getSelectedSegmentOptions(value);
            let selectedSegments = value;
            newValue = {
                ...temporarySettings,
                [type]: selectedSegments,
                segments_string: (selectedSegments.length > 1)
                    ? "COMBINED"
                    : selectedSegments[0].replace(/GROUP-/g, "")
            };
        } else if (type === "roomtype") {
            let roomTypeCode = ALLROOMTYPES;
            if (value !== ALLROOMTYPES) {
                let roomTypeMatch = hotelMappedRoomTypes.filter(rt => rt.code === value)[0];
                roomTypeCode = (!isNullOrUndefined(roomTypeMatch.local))
                    ? roomTypeMatch.local
                    : roomTypeMatch.code;
                if (isNullOrUndefined(roomTypeCode)) roomTypeCode = ALLROOMTYPES;
            }
            newValue = {
                ...temporarySettings,
                [type]: roomTypeCode,
                roomtype_string: value
            };
        } else if (type === "comparison") {
            let comparison = comparisons.find(c => c && c.value === value);
            newValue = {
                ...temporarySettings,
                [type]: comparison?.name ?? "NONE",
                comparison_data: comparison
            };
        } else {
            newValue = {
                ...temporarySettings,
                [type]: value,
            };
        }

        setTemporarySettings(newValue);
        dispatch({
            type: constants.SET_CALENDAR_WITH_CHANGES,
            value: true,
        });
    };

    const applySettings = (newValue) => {

        // Cancel Currently Running Axios Requests
        // And Create a New Axios Source
        source.cancel();
        dispatch({ type: constants.SET_SOURCE })

        // Update Calendar Filter Settings and Data
        dispatch({
            type: constants.UPDATE_CALENDAR_SETTINGS,
            value: newValue,
        });
        dispatch({
            type: constants.SET_CALENDAR_WITH_CHANGES,
            value: false,
        });
        toggleSidePanel(false);

        // Display the 'Save Custom Filter' icon button
        // But only when there are changes in the following filters: pickup, pace, segments
        if ((newValue.pace_string !== state.calendar_data.pace_string)
            || (newValue.pick_up !== state.calendar_data.pick_up)
            || (newValue.segments !== state.calendar_data.segments)
            || (newValue.roomtype !== state.calendar_data.roomtype)
            || (newValue.comparison !== state.calendar_data.comparison)) {
            setShowSaveCustomFilterButton(true);
        } else {
            setShowSaveCustomFilterButton(false);
        }

        dispatch({
            type: constants.UPDATE_SELECTED_CALENDAR_CELL,
            value: null,
        });
        dispatch({
            type: constants.UPDATE_PACE_TYPE,
            value: newValue.pace_string,
        });
    };

    const discardChanges = () => {
        dispatch({
            type: constants.SET_CALENDAR_WITH_CHANGES,
            value: false,
        });
        setTemporarySettings({ ...state.calendar_data });
    };

    const resetVisualsData = () => {
        dispatch({
            type: constants.UPDATE_VISUALS_SEGMENTS_DATA,
            value: { current: null, cache: [] }
        })
        dispatch({
            type: constants.UPDATE_VISUALS_ASOFS_DATA,
            value: { current: null, cache: [] }
        })
        dispatch({ type: constants.SHOW_VISUALS, value: false })
        toggleChartModal({ show: false })
    };


    const [filterPopOverShow, setFilterPopOverShow] = useState(false);

    const popOverContent = (
        <div className="rms-popover">
            <p>Are you sure you want to apply new filters?</p>
            <div>
                <Button
                    type="secondary"
                    onClick={() => {
                        setFilterPopOverShow(false);
                        discardChanges();
                    }}
                >
                    No
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        setFilterPopOverShow(false);
                        applySettings(temporarySettings);
                        resetVisualsData();
                    }}
                >
                    Yes
                </Button>
            </div>
        </div>
    );


    // Custom hook that checks whether a click was made outside the 'Save custom filter' pop-over.
    const saveCFPopoverRef = useRef(null);
    useOutsideClickChecker(saveCFPopoverRef, "popover-save-cfRef", () => setShowSaveCustomFilterPopover(false));


    // Function that checks whether a click was made outside the 'Saved custom filters list' pop-over.
    // Also checks whether a click was made outside the 'Remove custom filter' pop-over.
    // This utilizes React's useRef() hook.
    const useSavedCFPopoverOutsideChecker = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                try {
                    let target = event.target;
                    let targetClass = target.className.toString();

                    if (target.contains(ref.current) || targetClass.includes("popover-saved-cfRef")
                        || targetClass.includes("popover-remove-cfRef")) {
                        setShowSavedCustomFiltersPopover(true);
                        if (targetClass.includes("popover-remove-cfRef")) {
                            // Workaround. Don't close the 'Remove custom filter' pop-over when
                            // an element with the 'popover-remove-cfRef' class is clicked
                            setShowRemoveCustomFilterPopover(true);
                        } else if (targetClass.includes("ant-popover-inner-content")) {
                            // Workaround. Don't close the pop-overs when
                            // an element with the 'ant-popover-inner-content' class is clicked
                            setShowSavedCustomFiltersPopover(true);
                            setShowRemoveCustomFilterPopover(true);
                        } else {
                            setShowRemoveCustomFilterPopover(false);
                            setCustomFilterIndex(null);
                        }
                    } else {
                        if (targetClass.includes("ant-popover-inner-content")
                            || targetClass.includes("ant-popover-arrow")) {
                            // Workaround. Don't close the pop-overs when these classes are clicked
                            // 1. ant-popover-inner-content, 2. ant-popover-arrow
                            setShowSavedCustomFiltersPopover(true);
                            setShowRemoveCustomFilterPopover(true);
                        } else {
                            // Sequence here is important.
                            // The remove custom filter popover cannot be hidden
                            // if the saved user custom filters list popover is not hidden first.
                            setShowRemoveCustomFilterPopover(false);
                            setShowSavedCustomFiltersPopover(false);
                        }
                    }
                } catch (error) { }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const savedCFPopoverRef = useRef(null);
    useSavedCFPopoverOutsideChecker(savedCFPopoverRef);


    // 'Save custom filter' pop-over content. Uses the 'saveCFPopoverRef' ref.
    // Contains 'popover-save-cfRef' class names without any styles for each element inside it.
    const saveCustomFilterPopOverContent = (
        <div ref={saveCFPopoverRef} id="saveCustomFilterPopOverContent" className="rms-popover popover-save-cfRef"
            style={{ width: 200 }}>
            <Exit className="popover-save-cfRef" width="25px" height="25px"
                style={{ position: "absolute", top: 16, right: 12 }}
                onClick={() => {
                    setShowSaveCustomFilterPopover(false);
                    setShowCustomFilterError(false);
                }}
            />

            {/* Pop-over Title and Input */}
            <span className="popover-save-cfRef" style={{ fontFamily: "Titillium Web", fontWeight: 600, color: "#1e2147" }}>
                <strong className="popover-save-cfRef" >CUSTOM FILTER NAME</strong>
            </span>
            <br className="popover-save-cfRef" />
            <input id="custom_filter_name" required autoFocus className="popover-save-cfRef"
                value={customFilterName} onChange={e => setCustomFilterName(e.target.value)}
                style={{ backgroundColor: "#fff", border: "1px solid #1e2147", padding: 4, marginTop: 8 }}
            />

            {/* Custom Filter Saving Errors Content */}
            {showCustomFilterError && <>
                <br className="popover-save-cfRef" />
                <span className="popover-save-cfRef" style={{
                    fontFamily: "Lato", fontSize: 12, lineHeight: 1,
                    color: "red", maxWidth: "25ch", overflow: "hidden"
                }}>
                    {customFilterName.length > 25
                        ? "Please limit the custom filter name to a maximum of 25 characters"
                        : "Please input a custom filter name"
                    }
                </span>
            </>}

            {/* Save Button */}
            <div className="popover-save-cfRef" style={{ display: "grid", placeItems: "center", marginTop: 12 }}>
                <Button type="primary" className="popover-save-cfRef"
                    onClick={() => {
                        if (isNullOrUndefined(customFilterName) || customFilterName.toString().trim() === "") {
                            // Show an error if there is no inputted custom filter name
                            setShowCustomFilterError(true);
                            document.querySelector("#custom_filter_name").focus();
                        } else if (customFilterName.length > 25) {
                            // Also show an error if the inputted custom filter name has more thatn 25 characters
                            setShowCustomFilterError(true);
                            document.querySelector("#custom_filter_name").focus();
                        } else {
                            // Else save the custom filter
                            let customFilter = {
                                name: customFilterName,
                                property: state.hotelId,
                                data: {
                                    pickup: state.calendar_data.pick_up,
                                    pace: state.calendar_data.pace_string,
                                    segments: state.calendar_data.segments,
                                    segments_label: state.calendar_data.segments_string,
                                    roomtype: state.calendar_data.roomtype,
                                    roomtype_label: state.calendar_data.roomtype_string
                                }
                            }
                            let updatedFilters = [...customFilters, customFilter];

                            updateUserCustomFilters({
                                op: "add",
                                filter: customFilter
                            }).then((res) => {
                                if (res.type === constants.UPDATE_USER_CUSTOM_FILTERS_SUCCESS) {
                                    // On successful response, save the custom filter on both state and local storage
                                    setCustomFilters(updatedFilters);
                                    saveUserCustomFiltersToLocalStorage(res.response.data.data);
                                }
                            });

                            // And close the 'Save custom filter' button and pop-over afterwards
                            setShowSaveCustomFilterButton(false);
                            setShowSaveCustomFilterPopover(false);
                            setShowCustomFilterError(false);
                            setCustomFilterName("");
                        }
                    }}
                >
                    <span className="popover-save-cfRef">Save Filter</span>
                </Button>
            </div>
        </div>
    );
    // 'Remove custom filter' pop-over content.
    // Contains 'popover-remove-cfRef' class names without any styles for each element inside it.
    // Which is only used for outside-click checks.
    const removeCustomFilterPopOverContent = () => {
        return (
            <div className="rms-popover popover-remove-cfRef">
                {/* Pop-over message */}
                <span className="popover-remove-cfRef"
                    style={{ display: "block", fontFamily: "Lato", fontSize: 14, lineHeight: 1 }}
                >
                    Are you sure you want to delete this custom filter?
                </span>
                <br className="popover-remove-cfRef" />

                {/* Confirmation Buttons */}
                <div className="popover-remove-cfRef"
                    style={{ display: "grid", placeItems: "center", gridTemplateColumns: "50% 50%" }}
                >
                    <Button type="secondary" className="popover-remove-cfRef"
                        onClick={() => setShowRemoveCustomFilterPopover(false)}
                    >
                        <span className="popover-remove-cfRef">No</span>
                    </Button>
                    <Button type="primary" className="popover-remove-cfRef"
                        onClick={() => {
                            setShowRemoveCustomFilterPopover(false);

                            if (!isNullOrUndefined(customFilterIndex)) {
                                updateUserCustomFilters({
                                    op: "delete",
                                    property: state.hotelId,
                                    filter: { id: customFilters[customFilterIndex]._id }
                                }).then((res) => {
                                    if (res.type === constants.UPDATE_USER_CUSTOM_FILTERS_SUCCESS) {
                                        // On successful response,
                                        // remove the custom filter on both state and local storage
                                        customFilters.splice(customFilterIndex, 1);
                                        setCustomFilters([...customFilters]);
                                        saveUserCustomFiltersToLocalStorage(res.response.data.data);
                                    }
                                });
                            }
                        }}>
                        <span className="popover-remove-cfRef">Yes</span>
                    </Button>
                </div>
            </div>
        )
    }


    // 'Saved custom filters list' pop-over content.
    // Contains 'popover-saved-cfRef' class names without any styles for each element inside it.
    // Which is only used for outside-click checks.
    const savedCustomFiltersPopOverContent = (
        <div ref={savedCFPopoverRef} id="custom_filters" className="rms-popover popover-saved-cfRef">
            <ul className="rev-custom-filters popover-saved-cfRef">
                {(customFilters.length > 0) && customFilters.map((customFilter, index) => {
                    return <div key={index} className="popover-saved-cfRef"
                        style={{ display: "grid", gridTemplateColumns: "80%% 20%", padding: "4px 0" }}
                    >
                        {/* Custom Filter Naame */}
                        <span key={index} className="popover-saved-cfRef"
                            style={{ paddingRight: 40, maxWidth: "25ch", overflow: "hidden" }}
                            onClick={() => {
                                // Re-set the calendar filters based on the custom filter values
                                // And automatically update the calendar data
                                let newValue = {
                                    ...temporarySettings,
                                    pick_up: customFilter.data.pickup,
                                    pace_string: customFilter.data.pace,
                                    segments: customFilter.data.segments,
                                    segments_string: customFilter.data.segments_label,
                                    roomtype: customFilter.data.roomtype || ALLROOMTYPES,
                                    roomtype_string: customFilter.data.roomtype_label || ALLROOMTYPES
                                };

                                setTemporarySettings(newValue);
                                setShowSavedCustomFiltersPopover(false);
                                toggleSidePanel(false);
                                dispatch({
                                    type: constants.UPDATE_CALENDAR_SETTINGS,
                                    value: newValue,
                                });
                                dispatch({
                                    type: constants.UPDATE_PACE_TYPE,
                                    value: newValue.pace_string,
                                });
                                resetVisualsData();
                            }}
                        >
                            {customFilter.name}
                        </span>

                        {/* Delete Button */}
                        {/* Contains 'popover-remove-cfRef' class names without any styles for elements inside it. */}
                        <Popover key={`popover-${index}`} className="popover-remove-cfRef" placement="bottom"
                            content={removeCustomFilterPopOverContent(`popover-remove-${index}`)}
                            open={(index === customFilterIndex) ? showRemoveCustomFilterPopover : false}
                        >
                            <img alt="Exit" src={iconExit} className="popover-saved-cfRef" key={`exit-${index}`}
                                style={{
                                    width: 20, height: 20, position: "absolute", right: 14,
                                    backgroundColor: "#67b489", borderRadius: 10,
                                }}
                                onClick={() => {
                                    setShowRemoveCustomFilterPopover(true);
                                    setCustomFilterIndex(index);
                                }} />
                        </Popover>
                    </div>
                })}
            </ul>
        </div >
    );

    const hasIntegrationPartner = !properties.loadingProperty && properties.property
        && properties.property.mappings && properties.property.mappings.find((mapping) => {
            return mapping.enabled === true
        }) !== undefined ? true : false;

    const AsOfCalendarDateRange = (current) => {
        let pickerDate = current.clone();
        let start_date = null;
        let end_date = dayjs();
        if (properties.property) {
            let hotelTz = getTimezones().find(timezone => timezone.tz == properties.property.timezone);

            if (!isNullOrUndefined(properties.property.first_as_of) && dayjs(properties.property.first_as_of).isValid()) {
                start_date = dayjs(properties.property.first_as_of);
            }
            if (hotelTz !== undefined) {
                // DON'T CONVERT the picker date to hotel's timezone. JUST REPLACE the timezone offset of the picker date.
                pickerDate = dayjs(pickerDate.format('YYYY-MM-DDThh:mm:ss') + hotelTz.offset);
            }
            pickerDate.startOf('day');
            // console.log(pickerDate.format(), start_date.startOf('day').format(), pickerDate.diff(start_date.startOf('day'), 'days'))
            // Check start date
            if (!isNullOrUndefined(start_date) && pickerDate.diff(start_date.startOf('day'), 'days') < 0)
                return true;
            // Check end date
            if (pickerDate.diff(end_date.startOf('day'), 'days') > 0)
                return true;
        }

        return false;
    }
    
    const AsOfCalendarMinMaxDate = (current) => {
        if (!state.propertyInView) return false;
        const firstAsOf = state.propertyInView?.first_as_of;
        const lastReservationUpload = state.propertyInView?.last_file_upload?.reservations?.completed_on;

        if (!firstAsOf || !lastReservationUpload) return false; 
        // currentDate: It is needed to remove the time because it affects the calculation of the dates.
        // The currentDate is the date in calendar that is being checked, if this date should be disabled or not.
        const currentDate = dayjs(current).local(true).startOf('day');
        const startDate = dayjs(firstAsOf);
        const endDate = dayjs(lastReservationUpload);
        
        if (startDate && endDate &&
            dayjs(startDate).isValid() && dayjs(endDate).isValid() && 
            dayjs(currentDate).isBetween(startDate, endDate, undefined, "[]")) {
            return false;
        }

        return true;
    }

    const FilterSeparator = () => <span style={{ marginLeft: 2, marginRight: 8 }}>,</span>;
    
    return (
        <div className="calendar__settings">
            <div className="sentence filterTutorial" style={{ position: "relative" }}>
                {state.tutorial.filter.showSpot && (
                    <TutorialSpot section="overviewCalendar" type="filter" left="-42px" bottom="-6px" />
                )}
                <span>

                    {/* As-of Filter */}
                    <span>As of</span>
                    <AsOfFilter
                        value={dayjs(temporarySettings.as_of)}
                        isLoading={properties.loadingProperty}
                        onDateChange={(e) => handleChange("as_of", dayjs(e))}
                        disabledDate={hasIntegrationPartner ? AsOfCalendarDateRange : AsOfCalendarMinMaxDate}
                        onClick={(e) => {
                            setShowSaveCustomFilterPopover(false);
                            setShowSavedCustomFiltersPopover(false);
                        }}
                    />

                    {/* Pickup Filter */}
                    <FilterSeparator/>
                    <span>pickup</span>
                    <PickupFilter
                        value={temporarySettings.pick_up}
                        handleChange={(value) => handleChange("pick_up", value)}
                    />
                    
                    {/* Pace Filter */}
                    <FilterSeparator/>
                    <span>pace</span>
                    <PaceFilter 
                        value={temporarySettings.pace_string}
                        handleChange={(value) => handleChange("pace", value)}
                    />
                    
                    {/* Segment (Groups and Individual) Filter */}
                    {(state.isSegmentFeaturesEnabled || state.isGroup) && <>
                        <FilterSeparator/>
                        <span>segment</span>
                        <SegmentFilter
                            value={temporarySettings.segments}
                            handleChange={(value) => handleChange("segments", value)}
                            property={state.property}
                            segmentGroups={state.segmentsInView.segment_groups}
                            segments={state.segmentsInView.segments}
                            isPropertyGroup={state.isGroup}
                        />
                    </>}

                    {/* Room Type Filter */}
                    <FilterSeparator/>
                    <span>room type</span>
                    <RoomTypeFilter 
                        value={temporarySettings.roomtype_string}
                        handleChange={(e) => handleChange("roomtype", e)}
                        roomTypes={hotelMappedRoomTypes.sort((a, b) => {
                            if (a.code == "UMP") return 1;
                            if (b.code == "UMP") return -1;
                            return 0;
                        })}
                    />
                    
                    {/* Compare Filter !!! Not sure if this is still important */}
                    {!hideComparisons && comparisonsEnabled && <>
                        <FilterSeparator/>
                        <span>compare to</span>
                        <div className='rms-select-filter'>
                            <Select
                                value={temporarySettings.comparison}
                                onChange={(e) => handleChange("comparison", e)}
                                suffixIcon={null}
                                optionLabelProp='name'
                                options={comparisons.map(option => ({
                                    name: option.name,
                                    label: option.code? `${option.name} (${option.code})`: option.name,
                                    value: option.value
                                }))}
                            />
                        </div>
                    </>}
                </span>
            </div>

            {/* Apply Filters Button */}
            {state.isCalendarSettingsWithChanges && (
                <div className="buttons-container">
                    <div>
                        <Popover
                            placement="bottom"
                            content={popOverContent}
                            open={filterPopOverShow}
                        >
                            <Button type="primary" onClick={() => setFilterPopOverShow(true)}>
                                Apply
                            </Button>
                        </Popover>
                    </div>
                </div>
            )}

            {/* Save New Custom Filter Button (Save Icon) */}
            {/* Shows only after a new set of filters have been applied */}
            {showSaveCustomFilterButton && !state.isCalendarSettingsWithChanges && (
                <div className="buttons-container">
                    <div className="rev-image-icon">
                        <Popover placement="bottom"
                            content={saveCustomFilterPopOverContent}
                            open={showSaveCustomFilterPopover}
                        >
                            <img alt="Save User Custom Filter" src={iconSave} style={{ width: 20, height: 20 }}
                                onClick={() => {
                                    setShowSavedCustomFiltersPopover(false);
                                    setShowSaveCustomFilterPopover(!showSaveCustomFilterPopover);
                                }} />
                            <span className="tooltiptext">Save new custom filter</span>
                        </Popover>
                    </div>  
                </div>
            )}

            {/* Open Saved User Custom Filters Button (Folder Icon) */}
            {/* Shows only if there are user custom filters saved by the user for the selected hotel */}
            {(customFilters.length > 0) && (
                <div className="buttons-container">
                    <div className="rev-image-icon">
                        <Popover placement="bottom"
                            content={savedCustomFiltersPopOverContent}
                            open={showSavedCustomFiltersPopover}
                        >
                            <img alt="User Custom Filters" src={iconFolder} style={{ width: 20, height: 20 }}
                                onClick={() => {
                                    setShowSaveCustomFilterPopover(false);
                                    setShowSavedCustomFiltersPopover(!showSavedCustomFiltersPopover)
                                }} />
                            <span className="tooltiptext">Open saved filters</span>
                        </Popover>
                    </div>
                </div>
            )}

            {/* Latest As-of Modal */}
            {!hideLatestAsOfModal && <Modal
                title={null}
                open={isPropertyUpdated}
                onCancel={() => {
                    let oldPropertiesObject = JSON.parse(
                        localStorage.getItem("propertiesLatestAsOfModal")
                    )?? {};
                    oldPropertiesObject[state.hotelCode] = false;

                    localStorage.setItem(
                        "propertiesLatestAsOfModal",
                        JSON.stringify(oldPropertiesObject)
                    );

                    setIsPropertyUpdate(false);
                }}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                footer={false}
                width={450}
            >
                <div className="rms-modal-content">
                    <p style={{ marginTop: "15px" }}>
                        Your latest uploaded data is for{" "}
                        {dayjs(state.latest_as_of).format("MMM DD YYYY")}. Please upload recent
                        data to ensure your Dashboard is updated.
                    </p>
                </div>
            </Modal>}
        </div>
    );
};

export default CalendarFilter;
