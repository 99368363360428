import { useDispatch } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";
import constants from '../../constants';
import axios from "axios";

const useAdminConsoleActions = () => {
    const dispatch = useDispatch();
    const { getTokenSilently } = useAuth0();

    const getChannel = async (channelId) => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/channels/${channelId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    const getChannels = async () => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/channels`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    const addChannel = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_CHANNEL_REQUEST,
                constants.ADD_CHANNEL_SUCCESS,
                constants.ADD_CHANNEL_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/channels`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const updateChannel = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_CHANNEL_REQUEST,
                constants.UPDATE_CHANNEL_SUCCESS,
                constants.UPDATE_CHANNEL_FAILURE,
            ],
            callAPI: () =>
                axios.patch(`${constants.BASE_URL_2}/channels/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const deleteChannel = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_CHANNEL_REQUEST,
                constants.DELETE_CHANNEL_SUCCESS,
                constants.DELETE_CHANNEL_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/channels/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }
    
    const getSegments = async () => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/segments`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    const getSegment = async (id) => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/segments/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const getSegmentGroups = async () => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/segments/groups`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    const getSegmentGroup = async (id) => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/segments/groups/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const addSegment = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_SEGMENT_REQUEST,
                constants.ADD_SEGMENT_SUCCESS,
                constants.ADD_SEGMENT_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/segments`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const addSegmentGroup = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_SEGMENT_GROUP_REQUEST,
                constants.ADD_SEGMENT_GROUP_SUCCESS,
                constants.ADD_SEGMENT_GROUP_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/segments/groups`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const updateSegment = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_SEGMENT_REQUEST,
                constants.UPDATE_SEGMENT_SUCCESS,
                constants.UPDATE_SEGMENT_FAILURE,
            ],
            callAPI: () =>
                axios.patch(`${constants.BASE_URL_2}/segments/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const updateSegmentGroup = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_SEGMENT_GROUP_REQUEST,
                constants.UPDATE_SEGMENT_GROUP_SUCCESS,
                constants.UPDATE_SEGMENT_GROUP_FAILURE,
            ],
            callAPI: () =>
                axios.patch(`${constants.BASE_URL_2}/segments/groups/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const deleteSegment = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_SEGMENT_REQUEST,
                constants.DELETE_SEGMENT_SUCCESS,
                constants.DELETE_SEGMENT_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/segments/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const deleteSegmentGroup = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_SEGMENT_GROUP_REQUEST,
                constants.DELETE_SEGMENT_GROUP_SUCCESS,
                constants.DELETE_SEGMENT_GROUP_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/segments/group/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const getRoomTypes = async () => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/roomtypes`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const getRoomType = async (id) => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/roomtypes/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const addRoomType = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_ROOM_TYPE_REQUEST,
                constants.ADD_ROOM_TYPE_SUCCESS,
                constants.ADD_ROOM_TYPE_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/roomtypes`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const updateRoomType = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_ROOM_TYPE_REQUEST,
                constants.UPDATE_ROOM_TYPE_SUCCESS,
                constants.UPDATE_ROOM_TYPE_FAILURE,
            ],
            callAPI: () =>
                axios.patch(`${constants.BASE_URL_2}/roomtypes/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const deleteRoomType = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_ROOM_TYPE_REQUEST,
                constants.DELETE_ROOM_TYPE_SUCCESS,
                constants.DELETE_ROOM_TYPE_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/roomtypes/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const getMessageLogs = async (filter) => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/messagelogs`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: filter
        });
    }

    const getMessageLog = async (id) => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/messagelogs/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    const getBookingSource = async (bookingSouceId) => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/bookingsources/${bookingSouceId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    const getBookingSources = async () => {
        const token = await getTokenSilently();
    
        return axios.get(`${constants.BASE_URL_2}/bookingsources`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    }

    const addBookingSource = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_BOOKING_SOURCE_REQUEST,
                constants.ADD_BOOKING_SOURCE_SUCCESS,
                constants.ADD_BOOKING_SOURCE_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/bookingsources`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const updateBookingSource = async (bookingSouceId, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_BOOKING_SOURCE_REQUEST,
                constants.UPDATE_BOOKING_SOURCE_SUCCESS,
                constants.UPDATE_BOOKING_SOURCE_FAILURE,
            ],
            callAPI: () =>
                axios.patch(`${constants.BASE_URL_2}/bookingsources/${bookingSouceId}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const deleteBookingSource = async (bookingSouceId) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_BOOKING_SOURCE_REQUEST,
                constants.DELETE_BOOKING_SOURCE_SUCCESS,
                constants.DELETE_BOOKING_SOURCE_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/bookingsources/${bookingSouceId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const getCompetitors = async () => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/competitors`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const getCompetitor = async (id) => {
        const token = await getTokenSilently();

        return await axios.get(`${constants.BASE_URL_2}/competitors/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    const addCompetitor = async (data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.ADD_COMPETITOR_REQUEST,
                constants.ADD_COMPETITOR_SUCCESS,
                constants.ADD_COMPETITOR_FAILURE,
            ],
            callAPI: () =>
                axios.post(`${constants.BASE_URL_2}/competitors`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const updateCompetitor = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_COMPETITOR_REQUEST,
                constants.UPDATE_COMPETITOR_SUCCESS,
                constants.UPDATE_COMPETITOR_FAILURE,
            ],
            callAPI: () =>
                axios.patch(`${constants.BASE_URL_2}/competitors/${id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    const deleteCompetitor = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_COMPETITOR_REQUEST,
                constants.DELETE_COMPETITOR_SUCCESS,
                constants.DELETE_COMPETITOR_FAILURE,
            ],
            callAPI: () =>
                axios.delete(`${constants.BASE_URL_2}/competitors/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }),
            payload: {},
        });
        return result;
    }

    return {
        getChannels, getChannel, addChannel, updateChannel, deleteChannel,
        getSegment, getSegments, addSegment, updateSegment, deleteSegment, 
        getSegmentGroup, getSegmentGroups, addSegmentGroup, updateSegmentGroup, deleteSegmentGroup,
        getRoomTypes, getRoomType, addRoomType, updateRoomType, deleteRoomType,
        getMessageLogs, getMessageLog, 
        getBookingSource, getBookingSources, addBookingSource, updateBookingSource, deleteBookingSource,
        getCompetitors, getCompetitor, addCompetitor, updateCompetitor, deleteCompetitor
    }
}


export default useAdminConsoleActions;