import { isNullOrUndefined } from '../../utils';
import constants from '../../constants';

const mainTabs = [
    { key: "groupInfo", label: "Group Information", visible: true },
    { key: "adminControls", label: "Admin Controls", visible: true },
    { key: "forecastSettings", label: "Forecast Settings", visible: true }
];

export function getUserMainTabs(userType) {
    let userTabs = [];

    switch (userType) {
        case "ope_admin":
        case "hotel_admin":
        case "internal_user":
            userTabs = ["groupInfo", "adminControls", "forecastSettings"];
            break;
        case "hotel_user":
    }

    return mainTabs.filter(tab => userTabs.includes(tab.key));
}

export function localStorageSub(localStorageKey, value) {
    return localStorage.getItem(localStorageKey) ?? value;
}

export function getChartDaysOutOptions() {
    return constants.DAYS_OUT_OPTIONS.map(opt => {
        return { id: opt.days_out, displayText: `${opt.days_out} days` }
    });
}

export const defaultPickupOptions = [
    { id: 1, displayText: "1 day" },
    { id: 3, displayText: "3 days" },
    { id: 7, displayText: "7 days" },
    { id: 14, displayText: "14 days" },
    { id: 28, displayText: "28 days" }
];
export const defaultPaceOptions = [
    { id: "lastyear", displayText: "Last Year" },
    { id: "samedaylastyear", displayText: "Same day last year" },
    { id: "averageroomsold", displayText: "Average rooms sold" },
    { id: "averagedowroomsold", displayText: "Average DOW rooms sold" }
];
export const defaultViewOptions = [
    { id: "daily", displayText: "Daily" },
    { id: "weekly", displayText: "Weekly" },
    { id: "monthly", displayText: "Monthly" }
];

export function getSelectedPropertyList(event, allProperties, currentSelectedProperties) {
    const newSelectedProperties = [];

    switch (event.action) {
        case "add":
            const { value: selectedPropertyIds } = event;

            const newProperties = allProperties.filter(property =>
                selectedPropertyIds.includes(property.id)
            );

            for (let selectedProperty of [...newProperties, ...currentSelectedProperties]) {
                const index = newSelectedProperties.findIndex(property => property.id == selectedProperty.id);
                if (index > -1) continue;
                newSelectedProperties.push(selectedProperty);
            }

            break;
        case "delete":
            const { id: selectedPropertyId } = event;
            for (let selectedProperty of currentSelectedProperties) {
                if (selectedProperty.id == selectedPropertyId) continue;
                newSelectedProperties.push(selectedProperty);
            }

            break;
    }

    return newSelectedProperties;
}

export function getFormFieldConfigs(name) {
    const fields = [{
        name: "name",
        validations: [{
            type: "required",
            value: true
        }, {
            type: "alphanumeric_with_space"
        }]
    }, {
        name: "code",
        validations: [{
            type: "required",
            value: true
        }, {
            type: "alphanumeric"
        }]
    }, {
        name: "description",
        validations: []
    }, {
        name: "properties",
        validations: []
    }, {
        name: "comparisons",
        defaultValue: [],
        validations: []
    }, {
        name: "status",
        validations: []
    }, {
        name: "average_days",
        defaultValue: 60,
        validations: [{
            type: "required",
            value: true
        }, {
            type: "limit",
            min: 1
        }]
    }, {
        name: "chart_days_out",
        defaultValue: 30,
        validations: [{
            type: "required",
            value: true
        }]
    }, {
        name: "default_pickup",
        defaultValue: 1,
        validations: [{
            type: "required",
            value: true
        }]
    }, {
        name: "default_pace",
        defaultValue: "samedaylastyear",
        validations: [{
            type: "required",
            value: true
        }]
    }, {
        name: "default_view",
        defaultValue: "daily",
        validations: [{
            type: "required",
            value: true
        }]
    }];

    if (name) return fields.find(field => field.name == name);

    return fields;
}

export function validateAllFields(fieldConfigs, values) {
    const errors = [];

    fieldConfigs.forEach(field => {
        const fieldName = field.name;
        const value = values[fieldName];

        validate(field, value).forEach(error => {
            errors.push(error);
        });
    })

    return errors;
}

export function validate(fieldConfig, value) {
    if (fieldConfig === undefined) return [];

    const errors = [];
    const fieldName = fieldConfig.name;
    fieldConfig.validations.forEach(validation => {

        let error = null;
        switch (validation.type) {
            case 'required':
                if (!!validation.value === true && (isNullOrUndefined(value) || value === '' || (Array.isArray(value) && !(value.length > 0)))) {
                    error = 'This field is required';
                }
                break;
            case 'limit':
                if (!isNullOrUndefined(validation.min) && Number(value) < validation.min) {
                    error = 'This field has a minimum value: ' + validation.min;
                }
                if (!isNullOrUndefined(validation.max) && Number(value) > validation.max) {
                    error = 'This field has a maximum value: ' + validation.max;
                }
                break;
            case 'alphanumeric':
                if (!/^[a-zA-Z0-9]*$/.test(value))
                    error = 'Alphanumeric characters only';
                break;
            case 'alphanumeric_with_space':
                if (!/^[a-zA-Z0-9\s]*$/.test(value))
                    error = 'Alphanumeric characters and space only';
                break;
            case 'no_whitespace':
                if (/\s/.test(value))
                    error = 'Alphanumeric and special characters only';
                break;
            case 'url':
                if (value.includes("availability/[PROPERTY_CODE]")) break;
                if (!/^((http|https):\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.?)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/.test(value))
                    error = 'Value is not a valid URL';
                break;
        }

        if (error) errors.push({ field: fieldName, message: error })
    });

    return errors;
}

export function getIdToEdit(match) {
    return match ? match.params.id : null;
}

export function getFormFieldDefaultValues(fieldConfigs) {
    const defaultValues = {}
    fieldConfigs.forEach(field => {
        if (field.defaultValue !== undefined) {
            defaultValues[field.name] = field.defaultValue;
        }
    });
    return defaultValues;
}