import React, { useState, useEffect, useMemo } from 'react';
// import moment from 'moment';
import dayjs from 'dayjs';
import constants from 'constants.js';
import { useSelector, useDispatch } from 'react-redux';
import useHelperFunctions from 'store/actions/actions';
import useDashboardActions from 'store/actions/dashboardActions';
import usePropertiesActions from 'store/actions/propertiesActions';
import Tabs from '../../Tabs';
import CustomCell from './CustomCell';
import ViewSettings from "../../ViewSettings/ViewSettings";
import Switch from "../../Switch/Switch";
import CustomTable from "./CustomTable";
import { defaultDailyTabs, generateFilter } from '../../../utils/calendar';
import { usePreferences } from "hooks/usePreferences";
import { getFirstdayOfWeek, getLastdayOfWeek } from "../../../utils";
import { ExportCalendarButton } from "../../ExportData/";
import { Calendar, List } from "Icons";
import NotesModal from 'components/Dashboard/components/NotesModal';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';
import Select from 'elements/Select';
import { datesToRanges } from 'utils';


const Custom = ({ toggleCell }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const { loading, customDailyData } = useSelector((state) => state.dashboard);
    const { setADRState, toggleChartModal, toggleSidePanel, setNotesModalState } = useHelperFunctions();
    const { getCustomDailyData, getVarianceAvgs } = useDashboardActions();
    const { getNotesSummary } = usePropertiesActions();
    const { user, useSingleAsOfAvgsForVariances } = usePreferences();
    const { ADRState, calendar_data, activeViewType, activeCell, activeMainData, propertyInView, hotelId, notesModalState } = state;
    const { as_of, pick_up, pace, pace_string, segments, roomtype, check_in_start, check_in_end } = calendar_data;
    
    const getViewValue = (viewItem) => {
        return viewItem?.code === constants.CDV_CUSTOM_DATE_SELECTION? viewItem.code: `${viewItem.code}_${viewItem.value}`
    };

    const dailyTabs = defaultDailyTabs(pace_string);
    const [view, setView] = useState(constants.CUSTOM_DASHBOARD_VIEW_OPTIONS.find(item => {
        return getViewValue(propertyInView?.custom_dashboard?.default_view) === getViewValue(item)
    }));
    const [notesSummary, setNotesSummary] = useState([]);
    const currentData = useMemo(() => {
        if (!Array.isArray(customDailyData)) return [];
        const data = {};

        customDailyData.forEach(item => {
            const key = `${dayjs(item.cin).format("MMMM YYYY")}`;
            const noteType = (Array.isArray(notesSummary)? notesSummary: [])
                .find(note => dayjs(note.date).isSame(item.cin, 'day'))?.type;

            if (!data[key]) data[key] = [];
            data[key].push({
                ...item,
                note_type: noteType
            });
        });
        return data;
    }, [customDailyData, notesSummary]);

    const loadNotesSummary = (property) => {
        const dateRanges = view.code === constants.CDV_CUSTOM_DATE_SELECTION
            ? (propertyInView?.custom_dashboard?.custom_dates?? [])
            : [{ start_date: dayjs(as_of), end_date: dayjs(as_of).add(182, 'days') }];
        
        if (!(Array.isArray(dateRanges) && dateRanges.length > 0)) return;
        
        Promise.all(dateRanges.map(range => {
            return getNotesSummary(property.id, range.start_date, range.end_date);
        })).then(results => {
            results.forEach(result => {
                if (result.type === 'GET_NOTES_SUCCESS') {
                    const data = result.response.data.data;
                    const notes = [];
                    data.forEach(item => {
                        if (notes.find(note => dayjs(note.date).isSame(item.date, 'day'))) return;
                        notes.push(item);
                    });
                    setNotesSummary(notes);
                }
            });
        });
    }

    const loadCustomDashboard = () => {
        const checkin = [getFirstdayOfWeek(check_in_start), getLastdayOfWeek(check_in_end)];
        const filter = generateFilter(hotelId, as_of, checkin, pick_up, pace, segments, roomtype, user?.prefs, propertyInView.id, pace_string);

        // Include custom dashboard filter additional filter.
        switch(view.code) {
            case constants.CDV_CUSTOM_DATE_SELECTION:
                filter.custom_dashboard = {
                    view: { code: view.code, value: null },
                    date: propertyInView?.custom_dashboard?.custom_dates?? []
                }
                break;
            case constants.CDV_NOTES_VIEW:
                // Just in case... this range for notes is not final.
                filter.custom_dashboard = {
                    view: { code: view.code, value: view.value },
                    date: [{ start_date: dayjs(as_of), end_date: dayjs(as_of).add(182, 'days') }]
                    // date: [{ start_date: dayjs(as_of), end_date: dayjs(as_of).add(182, 'days').endOf('month').startOf('day') }]
                }
                break;
            default: 
                filter.custom_dashboard = {
                    view: { code: view.code, value: view.value },
                    date: [{ start_date: dayjs(as_of), end_date: dayjs(as_of).add(182, 'days') }]
                }
        }

        toggleSidePanel(false);
        getCustomDailyData(filter).then(result => {
            if (result.type === 'GET_CUSTOM_DAILY_DATA_SUCCESS') {
                const dailyData = result.response.data.data;
                // Only fetch dates in custom dashboard.
                getVarianceAvgs({ 
                    ...filter,
                    custom_dashboard: { 
                        ...filter.custom_dashboard,
                        date: datesToRanges(dailyData.map(item => item.cin))
                    }
                });
            }
        });

        loadNotesSummary(propertyInView);
    }

    useEffect(() => {
        loadCustomDashboard();
    }, [view, calendar_data]);

    const setSelectedView = (value) => {
        dispatch({
            type: constants.TOGGLE_CALENDAR_TABLE_VIEW,
            value: value,
        });
    };

    const activateCell = (data) => {
        toggleCell(data, "daily");
        dispatch({ type: constants.SHOW_VISUALS, value: false })
        toggleChartModal({ show: false })
    };

    return <div className='daily-custom-view-container'>
        <div className='tabs-container'>
            <Tabs
                tabs={dailyTabs}
                activeTab={activeMainData}
                selectTab={(value) => dispatch({ type: constants.CHANGE_SHOWN_MAIN_DATA, value: value })}
                inputClassName={'daily-tabs'}
            />
            <ViewSettings />
        </div>

        <div style={{ display: 'flex', height: '52px', alignItems: 'center', borderBottom: '1px solid #fff', fontWeight: '600' }}>
            <div className='rms-select-filter custom-dashboard-dropdown'>
                <Select
                    value={getViewValue(view)}
                    onChange={(e) => {
                        setView(constants.CUSTOM_DASHBOARD_VIEW_OPTIONS.find(item => {
                            return e === getViewValue(item)
                        }));
                    }}
                    popupMatchSelectWidth={false}
                    suffixIcon={<OutlinedIcon type="right" />}
                    options={constants.CUSTOM_DASHBOARD_VIEW_OPTIONS.map(item => ({ 
                        label: item.label.toUpperCase(),
                        value: getViewValue(item)
                    }) )}
                />
            </div>
            <div
                className="filter3Tutorial rms-switch font-md"
                style={{ position: "relative", marginLeft: 'auto' }}
            >
                <Calendar width="23px" height="23px" className="pointer" fill={activeViewType === "cells" ? "#67b489" : "#fff"}
                    style={{ margin: 'auto 4px' }} onClick={() => setSelectedView("cells")} />
                <List width="24px" height="24px" className="pointer" fill={activeViewType === "table" ? "#67b489" : "#fff"}
                    style={{ margin: 'auto 4px' }} onClick={() => setSelectedView("table")} />
                <span style={{ marginLeft: "32px" }} />
                <span data-html2canvas-ignore>ADR</span>
                <Switch checked={ADRState} onChange={() => setADRState(!ADRState)} />
            </div>
        </div>
        
        {activeViewType === "cells" && (<div className='daily-calendar-container' style={{ display: !loading && currentData?.length === 0 && "block" }}>
            {!loading &&
                Object.keys(currentData).length > 0 &&
                Object.keys(currentData).map((month, index) => {
                    return <div className='daily-custom-month' key={index}>
                        <span className='daily-custom-month-label'>{month.toUpperCase()}</span>
                        <div className='date-grid'>
                            {currentData[month].map((data, index) => {
                                return (
                                    <CustomCell
                                        key={index}
                                        activeCell={activeCell}
                                        selectedTab={activeMainData}
                                        data={data}
                                        onClick={e => activateCell(data, index)}
                                        ADRState={ADRState}
                                    />
                                );
                            })}
                        </div>  
                    </div>
                })
            }
        </div>)}

        {activeViewType === "table" && (
            <div className="daily-table-container">
                <CustomTable
                    selectedTab={activeMainData}
                    tabs={dailyTabs}
                    data={customDailyData}
                    onClick={index => activateCell(customDailyData[index], index)}
                    isLoading={loading}
                />
                <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "24px" }}>
                    <ExportCalendarButton className="pointer" data={customDailyData} />
                </div>
            </div>
        )}

        <NotesModal 
            visible={notesModalState.visible} 
            startDate={notesModalState.startDate}
            endDate={notesModalState.endDate}
            onClose={() => {
                loadNotesSummary(propertyInView);
                setNotesModalState({ visible: false });
            }}
        />
    </div>
};

export default Custom;