import React, { useState, useEffect } from "react";
import SearchBar from 'elements/Select/SearchBar';
import { Select } from "antd";
import { Exit } from 'Icons';

import usePropertyActions from '../../store/actions/propertiesActions';

const { Option } = Select;



const PropertyList = ({ allProperties, selectedProperties, className, eventHandler }) => {
    const { getParsers } = usePropertyActions();

    const removeUnavailableProperties = (propertyList, selectedPropertyList) => {
        const availableProperties = propertyList.filter(property => {
            return !selectedPropertyList.some(selectedProperty => selectedProperty.id == property.id);
        });
        return availableProperties;
    }

    const [searchBarValues, setSearchBarValues] = useState([]);
    const [propertiesData, setPropertiesData] = useState(removeUnavailableProperties(allProperties, selectedProperties));
    const [selectedPropertiesData, setSelectedPropertiesData] = useState(selectedProperties);
    const [parsers, setParsers] = useState([]);
    const [warnings, setWarnings] = useState([]);

    useEffect(() => {
        getParsers().then(res => setParsers(res.data.data));
    }, []);

    useEffect(() => {
        if (!Array.isArray(allProperties)) return;
        setPropertiesData(allProperties);
    }, [allProperties]);

    useEffect(() => {
        if (!Array.isArray(selectedProperties)) return;

        setPropertiesData(removeUnavailableProperties(allProperties, selectedProperties));
        setSelectedPropertiesData(selectedProperties);

        if (selectedProperties.length > 1) {
            let warnings = [];

            let currencies = [...new Set(selectedProperties.map(_ => _.currency?.code ?? null))];
            if (currencies.length > 1) {
                let dataString = currencies.toString().replace(/,/, " | ");
                warnings.push(`Multiple distinct currency values detected: \t${dataString}`);
            }

            let avgDays = [...new Set(selectedProperties.map(_ => _.average_days ?? null))];
            if (avgDays.length > 1) {
                let dataString = avgDays.toString().replace(/,/, " | ");
                warnings.push(`Multiple distinct average day values detected: \t${dataString}`);
            }

            let ps = [...new Set(selectedProperties.map((property) => {
                let parser = property.parser ? parsers.find(_ => _._id === property.parser) : null;
                return parser?.code;
            }))].filter(Boolean);
            if (ps.length > 1) {
                let dataString = ps.toString().replace(/,/, " | ");
                warnings.push(`Multiple distinct parser values detected: \t${dataString}`);
            }

            let mappings = []
            selectedProperties.forEach((property) => {
                property.mappings.forEach((mapping) => {
                    if (mapping.enabled) mappings.push(mapping.partner_code);
                })
                mappings = [...new Set(mappings)];
            })
            if (mappings.length > 1) {
                let dataString = mappings.toString().replace(/,/, " | ");
                warnings.push(`Multiple distinct enabled mapping values detected: \t${dataString}`);
            }

            setWarnings(warnings);
        }
    }, [selectedProperties, parsers]);



    return (
        <div className={`property-list ${className}`}>
            <h3>Properties included</h3>
            <SearchBar
                options={propertiesData.map(item => ({
                    label: item.name,
                    code: item.property_code,
                    value: item.id
                }))}
                optionRender={({ data }) => <div>
                    <span>{data.label}</span>
                    <span>{data.code}</span>
                </div>}
                value={searchBarValues}
                placeholder={"Property ID or Name"}
                notFoundContent={"No available properties to select"}
                handleFilter={(input, option) => {
                    const inputLowerCase = input.toLowerCase();
                    const nameLowerCase = option.label.toLowerCase();
                    const codeLowerCase = option.code.toLowerCase();
                    return (
                        nameLowerCase.includes(inputLowerCase) ||
                        codeLowerCase.includes(inputLowerCase)
                    );
                }}
                onAdd={(values) => {
                    eventHandler({ action: 'add', value: values });
                    setSearchBarValues([])
                }}
                onChange={(values) => {
                    setSearchBarValues(values)
                }}
            />

            <table className="forms-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Property Code</th>
                        <th>Location</th>
                        <th className="table-actions">Actions</th>
                    </tr>
                </thead>
                <tbody style={{ paddingTop: 20 }}>
                    {Array.isArray(selectedPropertiesData) && selectedPropertiesData.length ? (
                        selectedPropertiesData.map((property, index) => {
                            return (
                                <tr key={property._id}>
                                    <td>{property.name}</td>
                                    <td>{property.property_code}</td>
                                    <td>{property.preferred_location}</td>
                                    <td className="table-actions">
                                        <Exit
                                            onClick={() => {
                                                const event = { action: "delete", id: property.id };
                                                typeof eventHandler == "function" && eventHandler(event);
                                            }}
                                            width="16px"
                                            height="16px"
                                            className="pointer"
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td>No Properties Found</td>
                        </tr>
                    )}
                </tbody>
            </table>


            {/* Group Warnings */}
            {warnings.length > 0 && <div style={{ color: 'turquoise', margin: 20 }}>
                <hr />
                <div style={{ margin: 20 }}>
                    <div style={{ marginBottom: 8 }}>
                        <span style={{ color: 'orange', fontWeight: 600 }}>WARNING: </span>
                        <span style={{ color: 'white' }}>Group can still be saved but there could be inconsistencies when comparing group dashboard data to manual computations because of the following:</span>
                    </div>
                    <ul>{warnings.map((warning, index) => {
                        return <li key={index}>{warning}</li>
                    })}</ul>
                </div>
            </div>}
        </div>
    );
}

export default PropertyList;