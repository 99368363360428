import React from "react";
import { Add, AddFilled, Settings, Edit, Exit, Star } from "Icons";


/**
 * A basic button element. Powered by Icons.
 *
 * @param {String} type Button type, either add, edit, view, or delete.
 * @param {Function} action Action to happen after clicking the button.
 * @param {Boolean} hasAccess Whether the user has access to the element.
 * @param {Object} data Element data object. Defaults to {}.
 * @param {String} width Button width in pixels. Defaults to 20px.
 * @return {Component} A basic button element.
*/
const IconButton = ({ type, action = null, hasAccess = false, data = {}, width = "20px" }) => {
    if (!hasAccess) return <div></div>;

    switch (type) {
        case "add":
            return <AddFilled className="pointer" onClick={action} width={width} height={width} />;
        case "view":
            return <Settings className="pointer" onClick={action} width={width} height={width} />;
        case "edit":
            return <Edit className="pointer" onClick={action} width={width} height={width} />;
        case "delete":
            return <Exit className="pointer" onClick={action} width={width} height={width} />;
        case "plus":
            return <Add className="pointer" onClick={action} width={width} height={width} />;
        case "star":
            return <Star className="pointer" onClick={action} fill={data?.isBar ?? false} width={width} height={width} />;
        default:
            return <div></div>;
    }
};

export default IconButton;
