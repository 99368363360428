import * as React from "react";

function SvgExport({ className, onClick, noBackground = false, strokeColor = "#1e2147", width = "32px", height = "32px", style = {} }) {

    return (
        <svg className={className} onClick={onClick} width={width} height={height} viewBox="0 0 24 24" fill="none" style={style}>
            { !noBackground && <circle cx="12" cy="12" r="10" fill="#51b68a" /> }
            <path d="M7 14V16H17V14"    stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 13L12 7"       stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 13L14 11"      stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 13L10 11"      stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default SvgExport;
