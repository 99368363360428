import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";
import { Affix } from "antd";

import RecommendationIcon from "../../Recommendation/RecommendationIcon";
import NotesNotif from "../NotesNotif";
import { TutorialSpot } from "../../Tutorial/index";
import { negativeToBrackets, formatNegativeOne, formatData, withCurrency, getPaceTitle, getPaceRate } from "../../../utils";
import { getAggregatePaceValue, getAggregatePaceAdrValue, isNullOrUndefined } from "../../../utils";
import useHelperFunctions from "store/actions/actions";


export default ({ data, onClick, isLoading, selectedTab, tabs }) => {
    const state = useSelector((state) => state.mainReducer);
    const { calendar_data, currency, ADRState } = state;
    const isGroup = state.isGroup;

    const users = useSelector((state) => state.users);
    const userPrefs = users.authUser.prefs;
    const usePercentsForOccs = isGroup && !isNullOrUndefined(userPrefs) && (userPrefs.usePercentsForOccs ?? true);
    const { setNotesModalState } = useHelperFunctions();

    const dow = () => {
        return (
            <th className="flex-column" key={"dow"}>
                DOW
                <span>(day of week)</span>
            </th>
        );
    };

    const withCurrencyArr = ["pickup_adr", "rate", "pace_adr"];

    const withColor = (item, key, col, data = null) => {
        let pct = (usePercentsForOccs && (col === "pick_up" || col === "pace")) ? '%' : '';
        let value = (key === null) ? data : item[key];
        if (withCurrencyArr.indexOf(key) !== -1) {
            value = withCurrency(formatData(value), currency ?? item.currency);
        } else if (key === "pickup_rooms_sold_computed" || key === "variance_occ") {
            value = (value > 0 ? "+" : "") + value;
        }
        return <div className={`${item.individualColor[col]}`}>{formatNegativeOne(value)}{pct}</div>;
    };

    const renderNoteIcon = (dataItem) => {
        if (!dataItem.note_type) return <></>;
        return <NotesNotif type={ dataItem?.note_type } style={{ marginRight: '-2px' }} onClick={(e) => {
            e.stopPropagation();
            setNotesModalState({ 
                visible: true,
                startDate: dataItem.check_in,
                endDate: dataItem.check_in
            });
        }} />
    }

    const overview_columns = [
        { key: "color", label: "Demand" },
        {
            key: "days_out",
            label: "Days out",
            renderer: (item, key) => {
                return (item[key] >= 0) ? item[key] : ""
            },
        },
        {
            key: "check_in",
            label: "Date",
            renderer: (item, key) => dayjs(item[key]).format("MMMM DD, YYYY"),
        },
        {
            key: "day_of_week",
            label: dow(),
            renderer: (item, key) => dayjs(item[key]).format("dddd"),
        },
        {
            key: "rate",
            label: "Price",
            renderer: (item, key) => withColor(item, key, "rate"),
        },
        {
            key: usePercentsForOccs ? "pct_occ" : "total_occ",
            label: "OTB Rooms sold",
            renderer: (item, key) => usePercentsForOccs ? `${item[key]}%` : item[key]
        },
        {
            key: "adr",
            label: "OTB ADR",
            renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency),
        },
        {
            key: "recommended_action",
            label: "Recommendation",
            renderer: (item, key) => {
                return (
                    <span>
                        <RecommendationIcon data={item} />
                    </span>
                );
            },
        }, {
            key: "note_type",
            label: "Note",
            renderer: (item, key) => renderNoteIcon(item),
        }
    ];

    const pickup_columns = [
        { key: "color", label: "Demand" },
        {
            key: "days_out",
            label: "Days out",
            renderer: (item, key) => {
                return (item[key] >= 0) ? item[key] : ""
            },
        },
        {
            key: "check_in",
            label: "Date",
            renderer: (item, key) => dayjs(item[key]).format("MMMM DD, YYYY"),
        },
        {
            key: "day_of_week",
            label: dow(),
            renderer: (item, key) => dayjs(item[key]).format("dddd"),
        },
        {
            key: usePercentsForOccs ? "pct_pickup" : "pickup",
            label: "Pick up",
            renderer: (item, key) => withColor(item, key, "pick_up"),
        },
        {
            key: "pickup_adr",
            label: "Pick up ADR",
            renderer: (item, key) => withColor(item, key, "pickup_adr"),
        },
        {
            key: usePercentsForOccs ? "pct_occ" : "total_occ",
            label: "OTB Rooms sold",
            renderer: (item, key) => usePercentsForOccs ? `${item[key]}%` : item[key]
        },
        {
            key: "adr",
            label: "OTB ADR",
            renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency),
        }, {
            key: "note_type",
            label: "Note",
            renderer: (item, key) => renderNoteIcon(item),
        }
        
    ];

    const pace_columns = [
        { key: "color", label: "Demand" },
        {
            key: "days_out",
            label: "Days out",
            renderer: (item, key) => {
                return (item[key] >= 0) ? item[key] : ""
            },
        },
        {
            key: "check_in",
            label: "Date",
            renderer: (item, key) => dayjs(item[key]).format("MMMM DD, YYYY"),
        },
        {
            key: "day_of_week",
            label: dow(),
            renderer: (item, key) => dayjs(item[key]).format("dddd"),
        },
        {
            key: usePercentsForOccs ? "pct_pace" : "pace",
            label: "Pace variance",
            renderer: (item, key) => withColor(item, null, "pace", getAggregatePaceValue(calendar_data.pace_string, item, usePercentsForOccs)),
        },
        {
            key: "pace_adr",
            label: "Pace ADR variance",
            renderer: (item, key) => withCurrency(getAggregatePaceAdrValue(calendar_data.pace_string, item), currency ?? item.currency),
        },
        {
            key: usePercentsForOccs ? "pct_occ" : "total_occ",
            label: "OTB Rooms sold",
            renderer: (item, key) => usePercentsForOccs ? `${item[key]}%` : item[key]
        },

        {
            key: "adr",
            label: "OTB ADR",
            renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency),
        }, {
            key: "note_type",
            label: "Note",
            renderer: (item, key) => renderNoteIcon(item),
        }
    ];

    const getPriceColumns = () => {
        const hasCompetitorPricing = (state.propertyInView.competitors_info?? []).length > 0;
        const columns = [];
        columns.push({ key: "color", label: "Demand" });
        columns.push({ key: "days_out", label: "Days out",
            renderer: (item, key) => (item[key] >= 0) ? item[key] : ""
        });
        columns.push({ key: "check_in", label: "Date",
            renderer: (item, key) => dayjs(item[key]).format("MMMM DD, YYYY")
        });
        columns.push({ key: "day_of_week", label: dow(),
            renderer: (item, key) => dayjs(item[key]).format("dddd")
        });
        columns.push({ key: "rate", label: "Price",
            renderer: (item, key) => withColor(item, key, "sell_rate")
        });

        if (hasCompetitorPricing) {
            columns.push({ key: "pace_rate", label: "Price " + getPaceTitle(calendar_data.pace_string),
                renderer: (item, key) => getPaceRate(calendar_data.pace_string, item, currency ?? item.currency)
            });
            const compriceLabel = (userPrefs.enableLowestPriceCompetitor?? false) === true? "Low Comp": "Ave Comp";
            columns.push({ key: "competitors_price", label: compriceLabel,
                renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency)
            });
        } else {
            columns.push({ key: "pickup_rate", label: "Price " + calendar_data.pick_up.substring(1) + " days",
                renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency)
            });
            columns.push({ key: "pace_rate", label: "Price " + getPaceTitle(calendar_data.pace_string),
                renderer: (item, key) => getPaceRate(calendar_data.pace_string, item, currency ?? item.currency)
            });
        }
        
        columns.push({ key: "note_type", label: "Note",
            renderer: (item, key) => renderNoteIcon(item),
        });

        return columns;
    }

    const price_columns = getPriceColumns();

    let columns;
    let headers;

    switch (selectedTab) {
        case "pickup":
            columns = pickup_columns;
            headers = pickup_columns.map((column) => {
                return column.label;
            });
            break;
        case "pace":
            columns = pace_columns;
            headers = pace_columns.map((column) => {
                return column.label;
            });
            break;
        case "average_occ":
            columns = pace_columns;
            headers = pace_columns.map((column) => {
                return column.label;
            });
            break;
        case "average_occ_dow":
            columns = pace_columns;
            headers = pace_columns.map((column) => {
                return column.label;
            });
            break;
        case "rate":
            columns = price_columns;
            headers = price_columns.map((column) => {
                return column.label;
            });
            break;
        default:
        case "overview":
            columns = overview_columns;
            headers = overview_columns.map((column) => {
                return column.label;
            });
            break;
    }

    const ADRarr = ["Pick up ADR", "ADR", "ADR variance", "OTB ADR", "Pace ADR variance"];
    if (!ADRState) {
        headers = headers.filter((item) => {
            return ADRarr.indexOf(item) === -1;
        });
        columns = columns.filter((item) => {
            return ADRarr.indexOf(item.label) === -1;
        });
    }

    const [dataSource, setDataSource] = useState([]);
    useEffect(() => {
        if (data) {
            setDataSource(data);
        }
    }, [data]);

    const renderTableData = () => {
        if (dataSource.length) {
            return dataSource.map((item, index) => {
                const highlight = item.selected ? "active" : "";
                if (
                    dayjs(item.check_in).month() ===
                    dayjs(calendar_data.check_in_start).month()
                ) {
                    return (
                        <tr
                            className={`table-row ${highlight}`}
                            key={index}
                            onClick={() => onClick(index)}
                        >
                            {columns.map((value, i) => {
                                // if day of week
                                if (value.key === "day_of_week") {
                                    return (
                                        <td key={i}>
                                            <span>{value.renderer(item, "check_in")}</span>
                                        </td>
                                    );
                                }
                                // for status
                                if (value.key === "color") {
                                    return (
                                        <td className="status-box" key={i}>
                                            <div className={item[value.key]}></div>
                                        </td>
                                    );
                                }
                                // if with custom renderer
                                if (value.renderer) {
                                    return (
                                        <td key={i}>
                                            {" "}
                                            <span>{value.renderer(item, value.key)}</span>
                                        </td>
                                    );
                                }

                                return (
                                    <td key={i}>
                                        <span>{negativeToBrackets(item[value.key])}</span>
                                    </td>
                                );
                            })}
                        </tr>
                    );
                }
            });
        }
    };



    return (
        <div>
            <Affix offsetTop={70}>
                <table>
                    <thead className="tableTutorial">
                        {state.tutorial.table.showSpot && (
                            <TutorialSpot
                                left="4px"
                                bottom="-6px"
                                section="overviewCalendar"
                                type="table"
                            />
                        )}
                        <tr>
                            {headers.map((header, index) => {
                                if (typeof header === "string") {
                                    return (
                                        <th className={header} key={index}>
                                            {header.toUpperCase()}
                                        </th>
                                    );
                                }
                                return header;
                            })}
                        </tr>
                    </thead>
                </table>
            </Affix>
            <table>
                <tbody>{renderTableData()}</tbody>
            </table>
        </div>
    );
};
