import React, { useState } from "react";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { ToastsStore } from "react-toasts";
// import moment from "moment";
import dayjs from "dayjs";

import Logo from "../assets/images/pdf-logo.png";
import "../assets/pdf-fonts/TitilliumWeb-Bold-bold";
import "../assets/pdf-fonts/Lato-Regular-normal";
import "../assets/pdf-fonts/Lato-Italic-italic";
import { isNullish } from "utils/data";


const today = dayjs(new Date()).format("MMMM DD, YYYY");
const colorHeaderCopy = "#51b68a";
const colorSubheaderCopy = "#000000";
const colorLogoCopy = "#808080";



export const useDownload = () => {

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);


    const download = (fileName, ext, id, info = {}, m = false) => {
        // Initialization
        setError(null);
        setIsPending(true);

        // Timestamp
        const timestamp = (new Date()).getTime();

        try {
            // Guards
            if (isNullish(fileName)) throw new Error('Please provide a file name');
            if (isNullish(ext)) throw new Error('Please provide a file extension type');
            if (isNullish(id)) throw new Error('Please provide a page element to take a screenshot of');

            switch (ext) {
                case 'pdf':
                    // Select Page Element to Take a Screenshot Of. Defaults to element with ID = 'app'
                    const element = document.getElementById(id ?? 'app');

                    // Set HTML2Canvas Options
                    const options = { backgroundColor: '#ffffff' };

                    // Download
                    html2canvas(element, options).then((image) => {
                        // Generate an image screenshot based on provided element ID
                        const imgData = image.toDataURL('image/png', 1.0);
                        setData(imgData);

                        // Set Orientation. Defaults To Landscape if Width > Height
                        const orientation = (image.width > image.height) ? 'l' : 'p';
                        const isLandscape = orientation === 'l';

                        // Set Page Size, Defaults to A4 (297mm x 210mm)
                        // Page Sizes by Default Have Width > Height for Landscape Mode
                        const paperType = 'a4';
                        let page;
                        switch (paperType) {
                            case 'letter':
                                page = { width: isLandscape ? 11 : 8.5, height: isLandscape ? 8.5 : 11, unit: 'in' };
                                break;
                            default:
                                page = { width: isLandscape ? 297 : 210, height: isLandscape ? 210 : 297, unit: 'mm' };
                                break;
                        }

                        // Create a Single Page PDF
                        let pdf = new jsPDF(orientation, page.unit, [page.width, page.height]);

                        // Scale the Image Based On the Page Size and Image Width/Heights
                        let scale = 1;
                        if (image.height <= 900) {
                            if (image.width >= 1920) scale = 1.1;
                            if (image.width <= 1800) scale = 1.0;
                            if (image.width <= 1660) scale = 0.9;
                            if (image.width <= 1440) scale = 0.8;
                            if (image.width <= 1280) scale = 0.7;
                            if (image.width <= 1024) scale = 0.6;
                        }
                        if (image.height <= 700) {
                            if (image.width >= 1920) scale = 1.2;
                            if (image.width <= 1800) scale = 1.1;
                            if (image.width <= 1660) scale = 1.0;
                            if (image.width <= 1440) scale = 0.9;
                            if (image.width <= 1280) scale = 0.8;
                            if (image.width <= 1024) scale = 0.7;
                            if (id.includes("booking-chart")) scale = 0.8;
                        }

                        let marginYScale = 1;
                        if (image.height <= 900) marginYScale = 1.3;
                        if (image.height <= 800) marginYScale = 1.2;
                        if (image.height <= 700) marginYScale = 1.1;
                        if (image.height <= 600) marginYScale = 1.0;

                        const scaleRatio = Math.min(page.width / image.width, page.height / image.height) * scale;
                        const scaledImage = { width: image.width * scaleRatio, height: image.height * scaleRatio };

                        // Set Image Compression, Margin, and Position Options
                        const compression = 'fast';
                        const imageX = scaledImage.width;
                        const imageY = scaledImage.height;
                        const logoX = Logo.naturalWidth;
                        const logoY = Logo.naturalHeight;
                        const marginIX = (page.width - scaledImage.width) / 2;
                        const marginIY = ((page.height - scaledImage.height) / 2) * marginYScale;
                        const marginLIX = page.width * 0.7;
                        const marginLIY = page.height * 0.08;
                        const marginLTX = marginLIX * 0.97;
                        const marginLTY = marginLIY * 1.8;
                        const marginHX = page.width * 0.1;
                        const marginHY = page.height * 0.12;
                        const marginFX = page.width * 0.1;
                        const marginFY = page.height * 0.9;

                        // Add Main and Logo Images to PDF
                        pdf.addImage(Logo, 'png', marginLIX, marginLIY, logoX, logoY, 'logo', compression);
                        pdf.addImage(imgData, 'png', marginIX, marginIY, imageX, imageY, 'pdf', compression);

                        // Set PDF Copies
                        const asof = isNullish(info.asof) ? today : dayjs(info.asof).format("MMMM DD, YYYY");
                        const cin = isNullish(info.cin) ? today : info.cin;
                        const pickup = isNullish(info.pickup) ? 0 : Math.abs(info.pickup);
                        const pace = isNullish(info.pace) ? "same day last year" : info.pace;
                        const segments = isNullish(info.ss) ? "ALL" : info.ss.toString().replace(/ SEGMENTS/g, '');
                        const rts = isNullish(info.rts) ? "ALL" : info.rts.replace(/ ROOM TYPES/g, '');

                        let headerText = info.property ?? "";
                        let subheaderText = `Check-in: ${cin}`;
                        let logoText = "Your partner in unlocking your hotel's revenue potential";
                        let footerText = `Showing data for ${segments} segment(s), as of ${asof}, ` +
                            `${pickup} days pick up, pace from ${pace}, for ${rts} room type(s)`;

                        // Copy Transforms for Specific Scenarios
                        // For Element ID = 'app', Hide All Copies Because Not Necessary
                        if (id === 'app') {
                            headerText = "";
                            logoText = "";
                            subheaderText = "";
                            footerText = ""
                        }

                        // Add Header Copy to PDF
                        pdf.setFont('TitilliumWeb-Bold', 'bold');
                        pdf.setFontSize(14);
                        pdf.setTextColor(colorHeaderCopy);
                        pdf.text(headerText.toUpperCase(), marginHX, marginHY);
                        pdf.setFont('Lato-Regular', 'normal');
                        pdf.setFontSize(10);
                        pdf.setTextColor(colorSubheaderCopy);
                        pdf.text(["", subheaderText], marginHX, marginHY);

                        // Add Logo Copy to PDF
                        pdf.setFontSize(7);
                        pdf.setTextColor(colorLogoCopy)
                        pdf.text(logoText, marginLTX, marginLTY);

                        // Add Footer Copy to PDF
                        pdf.setFont("Lato-Italic", "italic")
                        pdf.setFontSize(9);
                        pdf.text(footerText, marginFX, marginFY);

                        // Download PDF
                        pdf.save(`${fileName}-${timestamp}.${ext}`);
                        ToastsStore.success("PDF Report Downloaded");

                        // Reset
                        setError(null);
                        setIsPending(false);
                    });
                    break;

                default:
                    break;
            }
        } catch (error) {
            // Log
            console.error(error.message);
            ToastsStore.error(error.message);

            // Reset
            setError(error.message);
            setIsPending(false);
        }
    }


    return { download, data, isPending, error };
}