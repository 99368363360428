import React, { cloneElement } from 'react';
import { Button, Dropdown, Menu, Divider } from 'antd';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';

const PickupFilter = ({
    fieldKey,
    onChange,
    value,
    label,
    inputClassName = "",
    errors,
    dropDownList = [],
    disabled = false 
}) => {

    const inputStyle = {
        minWidth: "unset",
        width: "100%",
        background: "#fff",
        color: "#818181",
        border: "1px solid #818181",
        borderWidth: "1px",
        borderRadius: "4px",
        padding: "3px"
    }
    
    // const menu = (
    //     <Menu onClick={onChange}>
    //         {dropDownList.length > 0 && dropDownList.map((item, i) => (
    //             <Menu.Item key={item._id}>
    //                 {item.name}
    //             </Menu.Item>
    //         ))}
    //         {/* <Divider style={{ margin: "10px 0"}}/> */}
    //         <div style={{ padding: "0px 10px 5px 10px" }}> 
    //             {/* <span style={{ marginRight: "10px", alignSelf: "center", fontWeight: "600" }}>
    //                 CUSTOM
    //             </span> */}
    //             <input placeholder="Custom default pickup" type="number" style={inputStyle} onChange={(e) => onChange({ key: e.target.value}) }/>
    //         </div>
    //     </Menu>
    // )

    const getDisplayValue = () => {
        let selectedValue = getValueInDefaultList(value);
        if (selectedValue) return selectedValue.name; 

        return value? value + " days": "";
    }

    const getValueInDefaultList = (val) => {
        return dropDownList.find(opt => opt._id == val);
    }

    return (
        <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
            <p className="field__label">
                {label ? (
                    <span className={errors ? "error" : ""}>{label}</span>
                ) : (
                        ""
                    )}
            </p>
            <Dropdown
                menu={{ 
                    items: dropDownList.map(item => ({
                        key: item._id,
                        label: item.name
                    }))
                }}
                trigger={["click"]}
                overlayClassName="rms-dropdown"
                disabled={disabled}
                dropdownRender={(Menu) => {
                    return (<div style={{ backgroundColor: 'white', borderRadius: '8px' }}>
                        {cloneElement(Menu, { style: { boxShadow: 'none'  } })}
                        <div style={{ padding: "0px 10px 10px 10px" }}>
                            <input placeholder="Custom default pickup" type="number" style={inputStyle} onChange={(e) => onChange({ key: e.target.value}) }/>
                        </div>
                    </div>)
                }}
            >
                <Button style={errors ? { border: '1px solid #ed474b' } : null}>
                    {getDisplayValue()}
                    <OutlinedIcon type='down' />
                </Button>
            </Dropdown>
        </div>
    )
}

export default PickupFilter;