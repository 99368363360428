import React from "react";
import OutlinedIcon from "./OutlinedIcon";


/**
 * An arrow icon with default colors depending on the arrow direction.
 * Powered by the antd Icon component.
 *
 * @param {String} direction Direction of the icon.
 * @return {Object} An arrow icon with default colors.
*/
const ArrowIcon = ({ direction }) => {
    let color = null;
    let type = `caret-${direction}`;

    if (type === "up") { color = "#39b54a" }
    if (type === "down") { color = "#e15f57" }


    return (
        <OutlinedIcon type={type} style={{ color: color }} />
    );
};

export default ArrowIcon;
