import * as React from "react";

function SvgAdd(props) {
    return (
        <svg viewBox="0 0 100 100" width="20px" height="20px" className="" {...props}>
            <path
                d="M12.09 59.2V39.43H40v-27.7h19.73v27.7h28.18V59.2H59.73v28H40v-28z"
                fill="#67b489"
                data-name="Layer 1"
            />
        </svg>
    );
}

export default SvgAdd;
