import React from "react";
import useHelperFunctions from "../../store/actions/actions";

const TutorialSpot = ({ section, type, bottom, left, top }) => {
  const { setToggleTutorial } = useHelperFunctions();

  return (
    <div
      className="tutorial-spot"
      style={{
        bottom:
          bottom &&
          Number(bottom.substring(0, bottom.indexOf("p"))) + 16 + "px",
        left: left && Number(left.substring(0, left.indexOf("p"))) + 16 + "px",
        top,
      }}
      onClick={(e) => {
        e.stopPropagation();
        setToggleTutorial({
          type: type,
          payload: {
            show: true,
            showSpot: false,
            section: section,
          },
        });
      }}
    ></div>
    // <img
    //   src={`${process.env.PUBLIC_URL}/icons/key.png`}
    //   style={{
    //     bottom,
    //     left,
    //     top,
    //   }}
    //   onClick={(e) => {
    //     e.stopPropagation();
    //     setToggleTutorial({
    //       type: type,
    //       payload: {
    //         show: true,
    //         showSpot: false,
    //         section: section,
    //       },
    //     });
    //   }}
    //   className="rms-key-icon"
    //   alt="help"
    // />
  );
};

export default TutorialSpot;
