import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import "./TableChart.scss";

import OutlinedIcon from "elements/Icon/OutlinedIcon";
import { isNullOrUndefined, percent, withCurrency } from "utils/index";
import constants from "constants";


/**
 * Table chart component.
 * Data parameter has the following format: { label : String, value: Number, color: String }
 *
 * @function TableChart
 * @param {[String|Number]} headers Array of Table chart header data, defaults to an empty array.
 * @param {[Object]} data Array of Table chart data, defaults to an empty array.
 * @param {Boolean} hasCurrency Displays currency to table values, or not. Defaults to false.
 * @param {Boolean} isSortable Can sort the table column data by clicking the column header. Defaults to false.
 * @param {Boolean} allowString Allow string values to be displayed on the table. Defaults to false; displays strings as dashes.
 * @param {[Number]} rowIndecesWithCurrency Row data indeces with currency. Defaults to an empty array.
 * @param {Number} rowNameWidth The width in pixels of the first data cell in each table row, defaults to 100.
 * @param {Number} rowDataWidth The width in pixels of the other data cells in each table row besides the first, defaults to 50.
 * @param {Boolean} hasFlexWidth The width of column headers dependes on the number of given text characters, defaults to false.
 * @param {[Number]} rowIndecesWithColor Row data indeces with background color based on their data. Defaults to an empty array.
 * @param {[Number]} rowIndecesWithMultiColor Row data indeces with multiple colors based on their data. Defaults to an empty array.
 * @param {Boolean} showInitialColumn Display the initial column of data. Defaults to true.
 * @return {Component} Table chart component.
*/
const TableChart = ({ headers = [], data = [], hasCurrency = false, isSortable = false, allowString = false,
    rowIndecesWithCurrency = [], rowNameWidth = 100, rowDataWidth = 50, width, hasFlexWidth = false,
    rowIndecesWithColor = [], rowIndecesWithMultiColor = [], showInitialColumn = true, isPercent = false
}) => {
    const state = useSelector((state) => state.mainReducer);
    const { isGroup, sidebarData } = state;

    const dispatch = useDispatch();
    const [sortIndex, setSortIndex] = useState(0);
    const [ascending, setAscending] = useState(false);
    const [sortedData, setSortedData] = useState(data);

    const colorRed = "#ef9a9a";
    const colorYellow = "#fff59d";
    const colorGrey = "#e0e0e0";
    const colorGreen = "#a5d6a7";

    const sortTableData = (dataObj) => {
        if (isSortable && !isNullOrUndefined(sortIndex)) {
            // Convert the single data object to be sorted to an array of data objects first
            let arrayOfData = Object.keys(dataObj).map(key => dataObj[key]);

            // And from that resulting array, remove the data object that is 'UNS' and 'ALL SEGMENTS'
            let unsegmentedSegment = "UNS";
            let uns = [...arrayOfData].filter(item => item.name === unsegmentedSegment);
            arrayOfData = arrayOfData.filter(item => item.name !== unsegmentedSegment);
            let allSegments = [...arrayOfData].filter(item => item.name === constants.ALLSEGMENTS);
            arrayOfData = arrayOfData.filter(item => item.name !== constants.ALLSEGMENTS);
            let sortedArrayOfData = [];

            // If the first table column is clicked, sort the table data alphabetically
            if (sortIndex === 0) {
                sortedArrayOfData = arrayOfData.sort((current, next) => {
                    return ascending
                        ? current.name.localeCompare(next.name)
                        : next.name.localeCompare(current.name);
                })
            } else {
                // As for everything else, sort by value
                const convertToFloat = (value) => isNaN(parseFloat(value)) ? parseFloat(0) : parseFloat(value);
                sortedArrayOfData = arrayOfData.sort((current, next) => {
                    return ascending
                        ? convertToFloat(current.data[sortIndex - 1]) - convertToFloat(next.data[sortIndex - 1])
                        : convertToFloat(next.data[sortIndex - 1]) - convertToFloat(current.data[sortIndex - 1]);
                })
            }

            // Insert back the 'UNS' and 'ALL SEGMENTS' data to the end of the sorted array of data objects
            sortedArrayOfData = [...sortedArrayOfData, ...uns, ...allSegments];


            // Save sorting information to state
            let finalSorting = sortedArrayOfData.map(item => item.name);
            dispatch({ type: constants.UPDATE_VISUALS_WATERFALL_SORTING, value: finalSorting });

            // Convert array of data objects back into a single object of objects
            let finalSortedData = {};
            for (let i = 0; i < sortedArrayOfData.length; i++) {
                finalSortedData[sortedArrayOfData[i].name] = sortedArrayOfData[i];
            }
            return finalSortedData;
        } else {
            return dataObj;
        }
    }

    const getColor = (item) => {
        if (item === "red" || item === "Red") return colorRed;
        if (item === "yellow" || item === "Yellow") return colorYellow;
        if (item === "grey" || item === "Grey") return colorGrey;
        if (item === "green" || item === "Green") return colorGreen;
        return item;
    }

    const getMultiColor = (item) => {
        let colors = item.replace("[", "").replace("]", "").replace(/"/g, "")
            .replace(/red/g, colorRed).replace(/yellow/g, colorYellow)
            .replace(/grey/g, colorGrey).replace(/green/g, colorGreen);

        return `linear-gradient(to right, ${colors})`
    }

    useEffect(() => {
        setSortedData(sortTableData(sortedData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortIndex, ascending])



    return (
        <div className="rev-table-chart">
            <table width={width}>
                <tbody>
                    <tr className={isSortable ? "rev-table-chart-hover" : null}
                        style={{ backgroundColor: "#eaeaec" }}>
                        {headers.map((item, index) =>
                            <th key={item} style={{
                                width: hasFlexWidth ? `${item.length + 2}ch` : null,
                                fontSize: 14, fontFamily: "Titillium Web", padding: "1px 2px",
                                color: (isSortable && sortIndex === index) ? "#f28f3c" : null,
                                userSelect: "none", WebkitUserSelect: "none"
                            }}
                                onClick={() => {
                                    setSortIndex(index);
                                    setAscending(!ascending);
                                }}
                            >
                                {item}
                                {(sortIndex === index) && <div className="sort-icon" style={{ float: 'right' }}>
                                    {isSortable && <OutlinedIcon type={ascending ? "up" : "down"}
                                        style={{ fontSize: 12, color: "#f28f3c", top: 2 }}
                                    />}
                                </div>}
                            </th>
                        )}
                    </tr>
                    {Object.keys(sortedData).map((key) => {
                        return (
                            <tr key={key} style={{
                                backgroundColor: isNullOrUndefined(data[key]) ? null : data[key].color
                            }}>
                                {showInitialColumn && <td style={{ fontSize: 12, width: rowNameWidth, padding: "1px 2px" }}>
                                    {isNullOrUndefined(data[key]) ? null : data[key].name}
                                </td>}
                                {data[key] && data[key].data && data[key].data.map((item, index) => {
                                    if (isNullOrUndefined(item)) item = "-";
                                    if (isNaN(item) && (!item.toString().includes("%") && !allowString)) item = "-";
                                    if (item === "NaN%") item = "-";
                                    if (item === "null%") item = "-";
                                    if (((typeof item === "string" || item instanceof String))
                                        && !item.includes("%") && !allowString) item = "-";

                                    return (
                                        <td key={index} style={{
                                            fontSize: 12, width: rowDataWidth, padding: "1px 2px",
                                            backgroundImage: rowIndecesWithMultiColor.includes(index)
                                                ? getMultiColor(item)
                                                : null,
                                            backgroundColor: rowIndecesWithColor.includes(index)
                                                ? getColor(item)
                                                : null
                                        }}>
                                            {(hasCurrency && rowIndecesWithCurrency.length === 0)
                                                ? (item !== "-")
                                                    ? isGroup
                                                        ? withCurrency(item, sidebarData?.currency)
                                                        : withCurrency(item)
                                                    : item
                                                : rowIndecesWithCurrency.includes(index)
                                                    ? isGroup
                                                        ? withCurrency(item, sidebarData?.currency)
                                                        : withCurrency(item)
                                                    : isPercent
                                                        ? percent(item)
                                                        : item
                                            }
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div >
    );
}

TableChart.propType = {
    headers: PropTypes.array,
    data: PropTypes.array,
    withCurrency: PropTypes.bool,
};

export default TableChart;
