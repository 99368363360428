import React from "react";


/**
 * Integration partners custom information list component.
 *
 * @param {[Object]} customInfo List of integration partners custom info data object.
 * @return {Component} Integration partners list component.
*/
const PartnersCustomInfoList = ({ customInfo }) => {

    let hasCustomInfo = customInfo && Array.isArray(customInfo) && customInfo.length > 0;


    return (
        <div style={{ padding: 20 }}>
            <div className="light-input-container">
                <div className="light-label">
                    <span>Custom Information</span>
                </div>
            </div>

            <table style={{ width: "100%" }}>
                <thead style={{ borderTop: "1px solid grey", borderBottom: "1px solid grey" }}>
                    <tr style={{ display: "grid", gridTemplateColumns: "50% 50%", margin: "8px 0" }}>
                        <th>DATA LABEL</th>
                        <th>VALUE</th>
                    </tr>
                </thead>
                <tbody>
                    {hasCustomInfo && customInfo.map((info, index) =>
                        <tr key={`${index}-${info.label}-${info.value}`}
                            style={{ display: "grid", gridTemplateColumns: "50% 50%", marginTop: 4 }}
                        >
                            <td>{info.label}</td>
                            <td>{info.value}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
};

export default PartnersCustomInfoList;
