import * as React from "react";

function SvgDownload(props) {
    return (
        <svg viewBox="0 0 100 100" data-html2canvas-ignore {...props}>
            <defs>
                <style>
                    {".Download_svg__cls-1{fill:#67b489}.Download_svg__cls-2{fill:#fff}"}
                </style>
            </defs>
            <g id="Download_svg__Layer_1" data-name="Layer 1">
                <circle className="Download_svg__cls-1" cx={50} cy={50.26} r={47.5} />
                <path
                    className="Download_svg__cls-2"
                    d="M80,50c0-16.5-13.5-30-30-30S20,33.5,20,50s13.5,30,30,30S80,66.5,80,50z M50,65.3L34,51.7l3.9-4.6l9,7.8V34.6h6v20.3   l9-7.8l3.9,4.6L50,65.3z"
                />
            </g>
        </svg>
    );
}

export default SvgDownload;
