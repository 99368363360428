import * as React from "react";

function SvgFlag(props) {
    return (
        <svg viewBox="0 0 24 24" { ...props } stroke={props.stroke?? '#000000'} fill='none'>
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g> 
                <path d="M5 21V3.90002C5 3.90002 5.875 3 8.5 3C11.125 3 12.875 4.8 15.5 4.8C18.125 4.8 19 3.9 19 3.9V14.7C19 14.7 18.125 15.6 15.5 15.6C12.875 15.6 11.125 13.8 8.5 13.8C5.875 13.8 5 14.7 5 14.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path> 
            </g>
        </svg>
    );
}

export default SvgFlag;