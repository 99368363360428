import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Dropdown, Menu } from "antd";
import Icon from '@ant-design/icons';
import OutlinedIcon from "elements/Icon/OutlinedIcon";
import Accordion from "elements/Accordion/Accordion";
import WithDateRequiredContainer from "../WithDataRequiredContainer/WithDataRequiredContainer";
import RmsIcon from "../RmsIcon/RmsIcon";
import { TutorialSpot } from "../Tutorial/index";



const Table = forwardRef(
    (   
        {
            heads = [],
            data = [],
            dataId,
            rowAction,
            pagination,
            filterFunction,
            isLoading,
            accordion,
            layout = "initial",
            initialSortKey,
        },
        ref
    ) => {
        const [sort, setSort] = useState(
            heads
                .filter((h) => h.sort)
                .map((he) => {
                    return { [he.key]: he.sort.default };
                })
                .reduce(
                    (result, item) => {
                        const key = Object.keys(item)[0];
                        result[key] = item[key];
                        return result;
                    },
                    { clicked: initialSortKey }
                )
        );

        const other = useSelector((state) => state.mainReducer.tutorial.other);

        const [tableLoading, setTableLoading] = useState("false");

        const [page, setPage] = useState(1);

        const [sortedColumn, setSortedColumn] = useState("");

        const getMenu = (actions, item) => {
            return (
                <Menu>
                    {actions.map((a) => (
                        <Menu.Item
                            onClick={() => a.function(item)}
                            key={`${a.label}${item[dataId]}`}
                        >
                            <span>{a.label}</span>
                        </Menu.Item>
                    ))}
                </Menu>
            );
        };

        useEffect(() => {
            setSortedColumn(initialSortKey);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (accordion) {
                if (accordion?.collapse === accordion?.activeKey) {
                    (async function asyncFilter() {
                        await filterFunction(page, sort);
                        setTableLoading(false);
                    })();
                }
            } else {
                (async function asyncFilter() {
                    await filterFunction(page, sort);
                    setTableLoading(false);
                })();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [page, sort, accordion?.collapse]);

        useImperativeHandle(ref, () => ({
            refreshFilter() {
                filterFunction(page, sort);
            },
        }));

        const handleSort = (key) => {
            const newSort = { ...sort };

            newSort[key] = sort[key] === "ascend" ? "descend" : "ascend";
            newSort.clicked = key;
            setSort(newSort);
            setTableLoading(true);
            setSortedColumn(key);
        };

        const handlePaginate = (direction) => {
            if (
                page + direction > 0 &&
                (direction === -1 || pagination.totalCount !== pagination.length + (page - 1) * 5)
            ) {
                setPage(page + direction);
                setTableLoading(true);
            }
        };

        const getTable = () => (
            <div className="rms-table-height">
                <table className="rms-table" style={{ tableLayout: layout }}>
                    <thead>
                        <tr>
                            {heads.map((h, i) => (
                                <th key={i} className={h.sort && "clickable-head"} width={h.width}>
                                    <div onClick={() => h.sort && handleSort(h.key)}>
                                        {h.label}{" "}
                                        {h.sort && h.key === sortedColumn && (
                                            <div className="sort-icon">
                                                <OutlinedIcon
                                                    type="up"
                                                    style={{
                                                        fontSize: "12px",
                                                        color:
                                                            sort[h.key] === "ascend"
                                                                ? "#51b68a"
                                                                : "#fff",
                                                        display: "block",
                                                        position: "relative",
                                                        top: "2.5px",
                                                    }}
                                                />

                                                <OutlinedIcon
                                                    type="down"
                                                    style={{
                                                        fontSize: "12px",
                                                        color:
                                                            sort[h.key] === "descend"
                                                                ? "#51b68a"
                                                                : "#fff",
                                                        display: "block",
                                                        position: "relative",
                                                        bottom: "2.5px",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {!tableLoading && (
                        <tbody>
                            {data.map((d, index) => (
                                <tr key={d[dataId]}>
                                    {heads.map((h) =>
                                        h.key !== "action" ? (
                                            <td key={`${h.key}${d[dataId]}`}>
                                                {h.date
                                                    ? d[h.key]
                                                        ? dayjs(d[h.key]).format("DD MMM YYYY")
                                                        : ""
                                                    : h.conditions
                                                        ? h.conditions[d[h.key]]
                                                        : d[h.key]}
                                            </td>
                                        ) : (
                                                <td key={`actionrow${d[dataId]}`}>
                                                    <span
                                                        className={index === 0 ? "otherTutorial" : ""}
                                                        style={{ position: "relative" }}
                                                    >
                                                        {index === 0 && other.showSpot && (
                                                            <TutorialSpot
                                                                type="other"
                                                                section="dashboard"
                                                                left="-39px"
                                                                bottom="-7px"
                                                            />
                                                        )}
                                                        {h.actions.map((a, i) => {
                                                            if (a.key === "upload_property_data" && d.mappings[0]?.enabled === true) {
                                                                return (<span key={i}>{a.disabledEl}</span>)
                                                            }
                                                            return (
                                                                <span
                                                                    onClick={() => a.function(d)}
                                                                    key={`action${i}${d[dataId]}`}
                                                                >
                                                                    {a.element}
                                                                </span>
                                                            )
                                                        })}
                                                        {h.burgerActions && (
                                                            <Dropdown
                                                                menu={getMenu(h.burgerActions, d)}
                                                                trigger={["click"]}
                                                                placement="bottomRight"
                                                            >
                                                                <span>
                                                                    <RmsIcon
                                                                        type="burger"
                                                                        className="rms-table-icon"
                                                                    />
                                                                </span>
                                                            </Dropdown>
                                                        )}
                                                    </span>
                                                </td>
                                            )
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
                <WithDateRequiredContainer doneFetching={!tableLoading} height="270px" />
            </div>
        );

        const accordionItems = [
            {
                key: accordion?.activeKey,
                label: accordion?.header,
                options: {
                    arrow: false,
                },
                content: getTable,
            },
        ];
        //END of accordion settings

        return (
            <div className="rms-table-container">
                {accordion ? (
                    <Accordion
                        accordion={true}
                        items={accordionItems}
                        defaultActiveKey={accordion?.activeKey}
                        onChange={accordion.onChange}
                        headerClassname={accordion.headerClassname}
                        collapse={accordion?.collapse ? true : false}
                    />
                ) : (
                        getTable()
                    )}
                <div className="table-footer">
                    <span>Viewing</span>
                    <Icon
                        type="caret-left"
                        style={{
                            fontSize: "12px",
                            color: "#fff",
                        }}
                        theme="outlined"
                        onClick={() =>
                            !tableLoading &&
                            accordion?.collapse === accordion?.activeKey &&
                            handlePaginate(-1)
                        }
                    />

                    {accordion?.collapse === accordion?.activeKey ? (
                        !isLoading && !tableLoading ? (
                            <span>
                                {(page - 1) * 5 + 1} - {pagination.length + (page - 1) * 5}
                            </span>
                        ) : (
                                <span>
                                    <Icon
                                        style={{
                                            fontSize: "14px",
                                            color: "#808285",
                                            marginRight: "4px",
                                        }}
                                        type="loading"
                                    />
                                </span>
                            )
                    ) : (
                            <span>0</span>
                        )}
                    <Icon
                        type="caret-right"
                        style={{
                            fontSize: "12px",
                            color: "#fff",
                        }}
                        theme="outlined"
                        onClick={() =>
                            !tableLoading &&
                            accordion?.collapse === accordion?.activeKey &&
                            handlePaginate(1)
                        }
                    />
                    <span>of {pagination.totalCount}</span>
                </div>
            </div>
        );
    }
);

Table.propType = {
    heads: PropTypes.array.isRequired,
    data: PropTypes.array,
    dataId: PropTypes.string,
    rowAction: PropTypes.func,
    pagination: PropTypes.obj,
    filterFunction: PropTypes.func,
    isLoading: PropTypes.bool,
    accordion: PropTypes.obj,
    layout: PropTypes.string,
    initialSortKey: PropTypes.string,
};

export default Table;