import React from 'react';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';
/**
 * Columns
 */
export const gridDefaultsColumns = [
    {
        id: 'variable',
        header: "VARIABLES",
        width: '200px'
    }, {
        id: 'average_days',
        header: 'AVG DAYS',
        type: 'number',
        defaultValue: 365,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'limit',
            min: 0
        }],
        width: '140px'
    }, {
        id: 'instances',
        header: 'INSTANCES',
        type: 'number',
        defaultValue: 4,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'limit',
            min: 0
        }],
        width: '140px'
    }, {
        type: 'empty',
        width: 'auto'
    }
    
];

export const gridPickupColumns = [
    {
        id: 'variable',
        header: "VARIABLES",
        width: 'auto'
    }, {
        id: 'average_days',
        header: 'AVG DAYS',
        type: 'number',
        defaultValue: null,
        validations: [{
            type: 'required',
            value: false
        }, {
            type: 'limit',
            min: 0
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_good',
        header: <div className="indicator good" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 20,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'avg_days_threshold_avg',
            error: 'The threshold for GOOD indicator should be greater than the threshold of AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_avg',
        header: <div className="indicator avg" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 10,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'avg_days_threshold_good',
            error: 'The threshold for AVERAGE indicator should be less than the threshold of GOOD indicator.'
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'avg_days_threshold_below_avg',
            error: 'The threshold for AVERAGE indicator should be greater than the threshold of BELOW AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_below_avg',
        header: <div className="indicator below-avg" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 0,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'avg_days_threshold_avg',
            error: 'The threshold for BELOW AVERAGE indicator should be less than the threshold of AVERAGE indicator.'
        }, {
            type: 'compare',
            operator: 'equal_greater_than_field',
            field: 'avg_days_threshold_bad',
            error: 'The threshold for BELOW AVERAGE indicator should be greater than the threshold of BAD indicator.'
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_bad',
        header: <div className="indicator bad" />,
        type: 'number',
        formatValue: '%val%%',
        defaultValue: -10,
        disabled: false,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'avg_days_threshold_below_avg',
            error: 'The threshold for BAD indicator should be less than the threshold of BELOW AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        type: 'empty',
        width: '15px'
    }, {
        id: 'instances',
        header: 'INSTANCES',
        type: 'number',
        defaultValue: null,
        validations: [{
            type: 'required',
            value: false
        }, {
            type: 'limit',
            min: 0
        }],
        width: '140px'
    }
];

export const gridPaceColumns = [
    {
        id: 'variable',
        header: "VARIABLES",
        width: 'auto'
    }, {
        id: 'average_days',
        header: 'AVG DAYS',
        type: 'number',
        defaultValue: null,
        validations: [{
            type: 'required',
            value: false
        }, {
            type: 'limit',
            min: 0
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_good',
        header: <div className="indicator good" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 20,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'avg_days_threshold_avg',
            error: 'The threshold for GOOD indicator should be greater than the threshold of AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_avg',
        header: <div className="indicator avg" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 10,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'avg_days_threshold_good',
            error: 'The threshold for AVERAGE indicator should be less than the threshold of GOOD indicator.'
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'avg_days_threshold_below_avg',
            error: 'The threshold for AVERAGE indicator should be greater than the threshold of BELOW AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_below_avg',
        header: <div className="indicator below-avg" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 0,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'avg_days_threshold_avg',
            error: 'The threshold for BELOW AVERAGE indicator should be less than the threshold of AVERAGE indicator.'
        }, {
            type: 'compare',
            operator: 'equal_greater_than_field',
            field: 'avg_days_threshold_bad',
            error: 'The threshold for BELOW AVERAGE indicator should be greater than the threshold of BAD indicator.'
        }],
        width: '140px'
    }, {
        id: 'avg_days_threshold_bad',
        header: <div className="indicator bad" />,
        type: 'number',
        formatValue: '%val%%',
        defaultValue: -10,
        disabled: false,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'avg_days_threshold_below_avg',
            error: 'The threshold for BAD indicator should be less than the threshold of BELOW AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        type: 'empty',
        width: '15px'
    }, {
        id: 'instances',
        header: 'INSTANCES',
        type: 'number',
        defaultValue: null,
        validations: [{
            type: 'required',
            value: false
        }, {
            type: 'limit',
            min: 0
        }],
        width: '140px'
    }
];

export const gridFinalsColumns = [
    {
        id: 'variable',
        header: "VARIABLES",
        width: 'auto'
    }, {
        id: 'finals_average_days',
        header: 'AVG DAYS',
        type: 'number',
        defaultValue: null,
        validations: [{
            type: 'required',
            value: false
        }, {
            type: 'limit',
            min: 0
        }],
        width: '140px'
    }, {
        id: 'finals_threshold_good',
        header: <div className="indicator good" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 90,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'finals_threshold_avg',
            error: 'The threshold for GOOD indicator should be greater than the threshold of AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        id: 'finals_threshold_avg',
        header: <div className="indicator avg" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 80,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'finals_threshold_good',
            error: 'The threshold for AVERAGE indicator should be less than the threshold of GOOD indicator.'
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'finals_threshold_below_avg',
            error: 'The threshold for AVERAGE indicator should be greater than the threshold of BELOW AVERAGE indicator.'
        }],
        width: '140px'
    }, {
        id: 'finals_threshold_below_avg',
        header: <div className="indicator below-avg" />,
        type: 'number',
        formatValue: '=> %val%%',
        defaultValue: 70,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'finals_threshold_avg',
            error: 'The threshold for BELOW AVERAGE indicator should be less than the threshold of AVERAGE indicator.'
        }, {
            type: 'compare',
            operator: 'equal_greater_than_field',
            field: 'finals_threshold_bad',
            error: 'The threshold for BELOW AVERAGE indicator should be greater than the threshold of BAD indicator.'
        }],
        width: '140px'
    }, {
        id: 'finals_threshold_bad',
        header: <div className="indicator bad" />,
        type: 'number',
        formatValue: '%val%%',
        defaultValue: 60,
        disabled: false,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'finals_threshold_below_avg',
            error: 'The threshold for BAD indicator should be less than the threshold of BELOW AVERAGE indicator.'
        }],
        width: '140px'
    }
];

export const gridPriceThresholdColumns = [
    {
        id: 'variable',
        header: '',
        width: 'auto'
    }, {
        id: 'price_threshold_good',
        header: <div className="indicator good" />,
        type: 'number',
        formatValue: '>= %val%%',
        defaultValue: 10,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'price_threshold_avg',
            error: 'The threshold for GOOD indicator should be greater than the threshold of AVERAGE indicator.'
        }],
        width: '160px'
    }, {
        id: 'price_threshold_avg',
        header: <div className="indicator avg" />,
        type: 'number',
        formatValue: '>= %val%%',
        defaultValue: 0,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'price_threshold_below_avg',
            error: 'The threshold for AVERAGE indicator should be greater than the threshold of BELOW AVERAGE indicator.'
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'price_threshold_good',
            error: 'The threshold for AVERAGE indicator should be less than the threshold of GOOD indicator.'
        }],
        width: '160px'
    }, {
        id: 'price_threshold_below_avg',
        header: <div className="indicator below-avg" />,
        type: 'number',
        formatValue: '>= %val%%',
        defaultValue: -10,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'price_threshold_bad',
            error: 'The threshold for BELOW AVERAGE indicator should be greater than the threshold of BAD indicator.'
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'price_threshold_avg',
            error: 'The threshold for BELOW AVERAGE indicator should be less than the threshold of AVERAGE indicator.'
        }],
        width: '160px'
    }, {
        id: 'price_threshold_bad',
        header: <div className="indicator bad" />,
        type: 'number',
        formatValue: '%val%%',
        defaultValue: -15,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'price_threshold_below_avg',
            error: 'The threshold for BAD indicator should be less than the threshold of BELOW AVERAGE indicator.'
        }],
        width: '160px'
    }
];

export const gridPriceAverageDaysColumns = [
    {
        id: 'variable',
        header: 'VARIABLES',
        width: 'auto'
    }, {
        id: 'average_days',
        header: 'AVERAGE DAYS',
        type: 'number',
        defaultValue: null,
        disabled: false,
        validations: [{
            type: 'required',
            value: false
        }, {
            type: 'limit',
            min: 0
        }],
        width: '160px'
    }
];

export const gridPricingRecommendationColumns = [
    {
        id: 'increase_pricing_threshold_3',
        header: <span>
            <OutlinedIcon type="up" style={{ color: '#39b54a', width: '24px', height: '24px' }} />
            <OutlinedIcon type="up" style={{ color: '#39b54a', width: '24px', height: '24px' }} />
            <OutlinedIcon type="up" style={{ color: '#39b54a', width: '24px', height: '24px' }} />
        </span>,
        type: 'number',
        formatValue: '> %val%%',
        defaultValue: 20,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'increase_pricing_threshold_2',
            error: 'The threshold for 3 up arrow indicator should be greater than the threshold of 2 up arrow indicator.'
        }],
        width: '150px'
    }, {
        id: 'increase_pricing_threshold_2',
        header: <span>
            <OutlinedIcon type="up" style={{ color: '#39b54a', width: '24px', height: '24px' }} />
            <OutlinedIcon type="up" style={{ color: '#39b54a', width: '24px', height: '24px' }} />
        </span>,
        type: 'number',
        formatValue: '> %val%%',
        defaultValue: 10,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'increase_pricing_threshold_3',
            error: 'The threshold for 2 up arrow indicator should be less than the threshold of 3 up arrow indicator.'
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'increase_pricing_threshold_1',
            error: 'The threshold for 2 up arrow indicator should be greater than the threshold of 1 up arrow indicator.'
        }],
        width: '150px'
    }, {
        id: 'increase_pricing_threshold_1',
        header: <span>
            <OutlinedIcon type="up" style={{ color: '#39b54a', width: '24px', height: '24px' }} />
        </span>,
        type: 'number',
        formatValue: '> %val%%',
        defaultValue: 5,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'increase_pricing_threshold_2',
            error: 'The threshold for 1 up arrow indicator should be less than the threshold of 2 up arrow indicator.'
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'decrease_pricing_threshold_1',
            error: 'The threshold for 1 up arrow indicator should be greater than the threshold of 1 down arrow indicator.'
        }],
        width: '150px'
    }, {
        id: 'flat_pricing_threshold',
        header: <span style={{ fontWeight: 600 }}>FLAT</span>,
        type: 'number',
        formatValue: '--',
        defaultValue: null,
        disabled: true,
        width: 'auto'
    }, {
        id: 'decrease_pricing_threshold_1',
        header: <span>
            <OutlinedIcon type="down" style={{ color: '#e15f57', width: '24px', height: '24px' }} />
        </span>,
        type: 'number',
        formatValue: '< %val%%',
        defaultValue: -5,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'decrease_pricing_threshold_2',
            error: 'The threshold for 1 down arrow indicator should be greater than the threshold of 2 down arrow indicator.'
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'increase_pricing_threshold_1',
            error: 'The threshold for 1 down arrow indicator should be less than the threshold of 1 up arrow indicator.'
        }],
        width: '150px'
    }, {
        id: 'decrease_pricing_threshold_2',
        header: <span>
            <OutlinedIcon type="down" style={{ color: '#e15f57', width: '24px', height: '24px' }} />
            <OutlinedIcon type="down" style={{ color: '#e15f57', width: '24px', height: '24px' }} />
        </span>,
        type: 'number',
        formatValue: '< %val%%',
        defaultValue: -10,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'decrease_pricing_threshold_1',
            error: 'The threshold for 2 down arrow indicator should be less than the threshold of 1 down arrow indicator.'
        }, {
            type: 'compare',
            operator: 'greater_than_field',
            field: 'decrease_pricing_threshold_3',
            error: 'The threshold for 2 down arrow indicator should be greater than the threshold of 3 down arrow indicator.'
        }],
        width: '150px'
    }, {
        id: 'decrease_pricing_threshold_3',
        header: <span>
            <OutlinedIcon type="down" style={{ color: '#e15f57', width: '24px', height: '24px' }} />
            <OutlinedIcon type="down" style={{ color: '#e15f57', width: '24px', height: '24px' }} />
            <OutlinedIcon type="down" style={{ color: '#e15f57', width: '24px', height: '24px' }} />
        </span>,
        type: 'number',
        formatValue: '< %val%%',
        defaultValue: -20,
        validations: [{
            type: 'required',
            value: true
        }, {
            type: 'compare',
            operator: 'less_than_field',
            field: 'decrease_pricing_threshold_2',
            error: 'The threshold for 3 down arrow indicator should be less than the threshold of 2 down arrow indicator.'
        }],
        width: '150px'
    }
];
/**
 * Cells
 */

export const gridDefaultsCellConfig = [
    {
        positionX: 1,
        positionY: 0,
        disabled: true
    }
];

export const gridPickupCellConfig = [
    {
        positionX: 1,
        positionY: 0,
        disabled: true
    }
];

export const gridPaceCellConfig = [
    {
        positionX: 1,
        positionY: 0,
        disabled: true
    }
];

export const gridPriceAvgdaysCellConfig = [
    {
        positionX: 1,
        positionY: 0,
        disabled: true
    },
    {
        positionX: 1,
        positionY: 3,
        defaultValue: null,
        disabled: true
    },
    // {
    //     positionX: 1,
    //     positionY: 6,
    //     defaultValue: null,
    //     disabled: true
    // },
    // {
    //     positionX: 1,
    //     positionY: 7,
    //     defaultValue: null,
    //     disabled: true
    // },
    // {
    //     positionX: 1,
    //     positionY: 8,
    //     defaultValue: null,
    //     disabled: true
    // },
    // {
    //     positionX: 1,
    //     positionY: 9,
    //     defaultValue: null,
    //     disabled: true
    // }
];

export const gridFinalsCellConfig = [
    {
        positionX: 1,
        positionY: 0,
        disabled: true
    },
    {
        positionX: 1,
        positionY: 3,
        disabled: true
    }
];
