import { useSelector } from "react-redux";
import { getPaceTitle } from "../../utils";
import { isNullish } from "../../utils/data";
import { transformReservationsData } from "../DashboardModal/components/ReservationsList/helper";
// import moment from "moment";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

export const useExport = () => {
    
    const state = useSelector(state => state);
    const { users } = state;
    const { propertyInView, calendar_data, activeMainData, activeCalendarView, sidebarData } = state.mainReducer;
    const { weeklyData, monthlyData } = state.dashboard;
    const { as_of } = calendar_data;
    const hasCompetitorPricing = (propertyInView?.competitors_info?? []).length > 0;
    const userPrefs = users.authUser.prefs;
    const usePercentsForOccs = propertyInView?.isGroup && (userPrefs?.usePercentsForOccs ?? false);

    const formatDate  = (date, format = "YYYYMMDD") => dayjs(date).format(format);
    const exportFile = async (type, data, filename) => {
        const getConfig = (type) => {
            if (type === "daily_overview") return { 
                    name: "Daily - Overview",
                    columnWidth: [80, 70, 90, 90, 80, 125, 80, 175],
                    subject: `Daily Overview Data [${formatDate(as_of)}] of ${dayjs(calendar_data.check_in_start).format("MMMM YYYY")}`
                }; 
            if (type === "daily_pickup") return {
                    name: "Daily - Pick up",
                    columnWidth: [80, 70, 90, 90, 80, 125, 80, 175],
                    subject: `Daily Pick Up Data [${formatDate(as_of)}] of ${dayjs(calendar_data.check_in_start).format("MMMM YYYY")}`
                }; 
            if (type === "daily_pace") return {
                    name: "Daily - Pace",
                    columnWidth: [80, 70, 90, 90, 80, 125, 80, 175],
                    subject: `Daily Pace Data [${formatDate(as_of)}] of ${dayjs(calendar_data.check_in_start).format("MMMM YYYY")}`
                }; 
            if (type === "daily_price") return {
                    name: "Daily - Price",
                    columnWidth: [80, 70, 90, 90, 80, 125, 80, 175],
                    subject: `Daily Price Data [${formatDate(as_of)}] of ${dayjs(calendar_data.check_in_start).format("MMMM YYYY")}`
                };
            if (type === "weekly") return {
                    name: "Weekly",
                    columnWidth: [80, 90, 90, 70, 90, 110, 140, 80, 100, 150, 90, 90, 110],
                    subject: `Weekly Data [${formatDate(as_of)}] from ${dayjs(weeklyData[0].dateRange[0]).format("MMM DD, YYYY")} to ${dayjs(weeklyData[weeklyData.length -1].dateRange[1]).format("MMM DD, YYYY")}`
                }
            if (type === "monthly") return {
                    name: "Monthly",
                    columnWidth: [80, 90, 70, 90, 110, 140, 80, 100, 170, 90, 90, 110],
                    subject: `Monthly Data [${formatDate(as_of)}] from ${dayjs(monthlyData[0].dateRange[0]).format("MMM DD, YYYY")} to ${dayjs(monthlyData[monthlyData.length -1].dateRange[1]).format("MMM DD, YYYY")}`
                }
            if (type === "reservations_daily") return { 
                    name: `Reservations ${formatDate(sidebarData.cin)}`,
                    columnWidth: [120, 360, 145, 60, 180, 90, 90, 80],
                    subject: `Reservations Data [${formatDate(as_of)}] of ${formatDate(sidebarData.cin, "MMM DD, YYYY")}`
                }
            if (type === "reservations_weekly") return { 
                    name: `Reservations ${formatDate(sidebarData.dateRange[0])}-${formatDate(sidebarData.dateRange[1])}`,
                    columnWidth: [120, 360, 145, 60, 180, 90, 90, 80],
                    subject: `Reservations Data [${formatDate(as_of)}] from ${formatDate(sidebarData.dateRange[0], "MMM DD, YYYY")} tp ${formatDate(sidebarData.dateRange[1], "MMM DD, YYYY")}`
                }
            if (type === "reservations_monthly") return { 
                    name: `Reservations ${formatDate(sidebarData.dateRange[0])}-${formatDate(sidebarData.dateRange[1])}`,
                    columnWidth: [120, 360, 145, 60, 180, 90, 90, 80],
                    subject: `Reservations Data [${formatDate(as_of)}] from ${formatDate(sidebarData.dateRange[0], "MMM DD, YYYY")} to ${formatDate(sidebarData.dateRange[1], "MMM DD, YYYY")}`
                }

            throw new Error(`Unrecognized export data type: ${type}`);
        }

        const config = getConfig(type);
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Set columns' width (by pixels - wpx)
        worksheet["!cols"] = (config?.columnWidth ?? []).map(width => ({ wpx: width }));
        
        XLSX.utils.book_append_sheet(workbook, worksheet, config.name);

        return XLSX.writeFile(workbook, `${filename}.xlsx`, { Props: {
            Title: "RevAndYou Export Data",
            Author: "RevAndYou",
            Subject: config.subject
        } });
    }

    const exportCalendarData = async(data) => {
        if (activeCalendarView == "daily")
            return await exportDailyData(data);
        if (activeCalendarView == "custom")
            return await exportDailyData(data);
        if (activeCalendarView == "weekly")
            return await exportWeeklyData(data)
        if (activeCalendarView == "monthly")
            return await exportMonthlyData(data);

        throw new Error(`Unrecognized calendar view: ${activeCalendarView}`);
    }

    const exportDailyData = async (data) => {
        if (activeMainData === "overview") 
            return await exportFile("daily_overview", transformDailyData(data), `RevAndYouExport_${formatDate(as_of)}_Daily(Overview)`);
        if (activeMainData === "pickup") 
            return await exportFile("daily_pickup", transformDailyData(data), `RevAndYouExport_${formatDate(as_of)}_Daily(Pickup)`);
        if (["pace", "average_occ", "average_occ_dow"].includes(activeMainData)) 
            return await exportFile("daily_pace", transformDailyData(data), `RevAndYouExport_${formatDate(as_of)}_Daily(Pace)`);
        if (activeMainData === "rate") 
            return await exportFile("daily_price", transformDailyData(data), `RevAndYouExport_${formatDate(as_of)}_Daily(Price)`);
    }

    const exportWeeklyData = async (data) => {
        return await exportFile("weekly", transformWeeklyData(data), `RevAndYouExport_${formatDate(as_of)}_Weekly`);
    }

    const exportMonthlyData = async (data) => {
        return await exportFile("monthly", transformMonthlyData(data), `RevAndYouExport_${formatDate(as_of)}_Monthly`);
    }

    const exportReservationsData = async (data) => {
        if (activeCalendarView == "daily")
            return await exportFile(`reservations_daily`, transformResData(data), `RevAndYouExport_${formatDate(as_of)}_Resvn(Daily)`);
        if (activeCalendarView == "weekly")
            return await exportFile(`reservations_weekly`, transformResData(data), `RevAndYouExport_${formatDate(as_of)}_Resvn(Weekly)`);
        if (activeCalendarView == "monthly")
            return await exportFile(`reservations_monthly`, transformResData(data), `RevAndYouExport_${formatDate(as_of)}_Resvn(Monthly)`);

        throw new Error(`Unrecognized calendar view: ${activeCalendarView}`);
    }

    const getPaceRate = (paceType, data) => {
        if (paceType === "averageroomsold") 
            return isNullish(data.average_rate) ? null : Math.round(data.average_rate);
        if (paceType === "averagedowroomsold") 
            return isNullish(data.average_rate_dow) ? null : Math.round(data.average_rate_dow);
        return isNullish(data.pace_rate) ? null : Math.round(data.pace_rate);
    }

    const getAggregatePaceAdrValue = (paceType, data) => {
        if (paceType === "averageroomsold") return data.average_adr;
        if (paceType === "averagedowroomsold") return data.average_adr_dow;
        if (paceType === "samedaylastyear") return data.pace_adr;
        if (paceType === "lastyear") return data.pace_adr;
        return null;
    }

    const getAggregatePaceValue = (paceType, data, isGroup = false) => {
        if (isGroup) {
            if (paceType === "averageroomsold") return Math.round(data.pct_avgdo);
            if (paceType === "averagedowroomsold") return Math.round(data.pct_avgdow);
            if (paceType === "samedaylastyear") return Math.round(data.pct_pace);
            if (paceType === "lastyear") return Math.round(data.pct_pace);
            return null;
        } else {
            if (paceType === "averageroomsold") return data.average_occ;
            if (paceType === "averagedowroomsold") return data.average_occ_dow;
            if (paceType === "samedaylastyear") return data.pace;
            if (paceType === "lastyear") return data.pace;
            return null;
        }
    }
    
    const transformDailyData = (dailyData) => {

        const getColumns = () => {

            const defaultColumns = [
                { key: "color", label: "Demand" },
                { key: "days_out", label: "Days out" },
                { key: "check_in", label: "Date" },
                { key: "day_of_week", label: "DOW" },
            ]

            if (activeMainData == "overview") {
                return [
                    ...defaultColumns,
                    { key: "rate", label: "Price" },
                    { key: usePercentsForOccs? "pct_occ" : "total_occ", label: "OTB Rooms sold" },
                    { key: "adr", label: "OTB ADR" },
                    { key: "recommended_action", label: "Recommendation" },
                ];
            }

            if (activeMainData == "pickup") {
                return [
                    ...defaultColumns,
                    { key: usePercentsForOccs ? "pct_pickup" : "pickup", label: "Pick up" },
                    { key: "pickup_adr", label: "Pick up ADR" },
                    { key: usePercentsForOccs ? "pct_occ" : "total_occ", label: "OTB Rooms sold" },
                    { key: "adr", label: "OTB ADR" },
                ];
            }
            
            if (["pace", "average_occ", "average_occ_dow"].includes(activeMainData)) {
                return [
                    ...defaultColumns,
                    { key: usePercentsForOccs ? "pct_pace" : "pace", label: "Pace variance" },
                    { key: "pace_adr", label: "Pace ADR variance" },
                    { key: usePercentsForOccs ? "pct_occ" : "total_occ", label: "OTB Rooms sold" },
                    { key: "adr", label: "OTB ADR" },
                ]
            }

            if (activeMainData == "rate") {
                if (hasCompetitorPricing) {
                    return [
                        ...defaultColumns,
                        { key: "rate", label: "Price" },
                        { key: "pace_rate", label: `Price ${getPaceTitle(calendar_data.pace_string)}` },
                        { key: "competitors_price", label: (userPrefs.enableLowestPriceCompetitor?? false) === true? "Low Comp": "Ave Comp" }
                    ]
                }

                return [
                    ...defaultColumns,
                    { key: "rate", label: "Price" },
                    { key: "pickup_rate", label: "Price " + calendar_data.pick_up.substring(1) + " days" },
                    { key: "pace_rate", label: "Price " + getPaceTitle(calendar_data.pace_string) }
                ]
            }

            return [];
        }

        const columns = getColumns();
        
        const data = (activeCalendarView === 'daily'
            ? dailyData.filter(daily => dayjs(daily.check_in).isSame(calendar_data.check_in_start, "month"))
            : dailyData
        ).map(daily => {
            const row = {};
            columns.forEach(item => {
                switch(item.key) {
                    case "color": 
                        row[item.label] = daily[item.key]? daily[item.key].toUpperCase(): null; break;
                    case "check_in": 
                        row[item.label] = dayjs(daily[item.key]).format("YYYY-MM-DD"); break;
                    case "pct_pace": case "pace": 
                        row[item.label] = getAggregatePaceValue(calendar_data.pace_string, daily, usePercentsForOccs); break;
                    case "pace_adr": 
                        row[item.label] = getAggregatePaceAdrValue(calendar_data.pace_string, daily); break;
                    case "day_of_week":
                        row[item.label] = dayjs(daily.check_in).format("dddd"); break;
                    default: 
                        row[item.label] = daily[item.key]?? null;
                }
            });

            return row;
        });
        
        return data;
    }

    const getPercentOTB = (totalOcc, totalInventory, noOfItemsAggregated) => {
        return Math.round(
            (totalOcc / (totalInventory * (noOfItemsAggregated || 1))) * 100
        );
    }

    const transformWeeklyData = (weeklyData) => {
        const columns = [
            { key: "color", label: "Demand" },
            { key: "start_date", label: "Start Date" },
            { key: "end_date", label: "End Date" },
            { key: usePercentsForOccs ? "pct_pickup" : "pickup", label: "Pick up" },
            { key: "pickup_adr", label: "Pick up ADR" },
            { key: usePercentsForOccs ? "pct_pace" : "pace", label: "Pace Variance" },
            { key: "pace_adr", label: "Pace ADR Variance" },
            { key: "rate", label: "Price" },
            { key: "pickup_rate", label: "Price " + calendar_data.pick_up.substring(1) + " days" },
            { key: "pace_rate", label: "Price " + getPaceTitle(calendar_data.pace_string) },
            { key: "total_occ", label: "OTB OCC%" },
            { key: "adr", label: "OTB ADR" },
            { key: "total_rev", label: "OTB revenue" },
        ];

        const data = weeklyData.map(week => {
            const row = {};
            columns.forEach(item => {
                switch(item.key) {
                    case "color": 
                        row[item.label] = week[item.key]? week[item.key].toUpperCase(): null; break;
                    case "start_date": 
                        row[item.label] = week.dateRange[0]; break;
                    case "end_date":
                        row[item.label] = week.dateRange[1]; break;
                    case "pct_pace": case "pace":
                        row[item.label] = getAggregatePaceValue(calendar_data.pace_string, week, usePercentsForOccs); break;
                    case "pace_adr": 
                        row[item.label] = getAggregatePaceAdrValue(calendar_data.pace_string, week); break;
                    case "pace_rate":
                        row[item.label] = getPaceRate(calendar_data.pace_string, week, false); break;
                    case "total_occ":
                        row[item.label] = getPercentOTB(week[item.key], week.total_inventory, week.noOfItemsAggregated); break;
                    default: 
                        row[item.label] = week[item.key]?? null;
                }
            });

            Object.keys(row).forEach(key => row[key] = row[key] == "-"? null: row[key]);

            return row;
        });

        return data;
    }

    const transformMonthlyData = (monthlyData) => {
        const columns = [
            { key: "color", label: "Demand" },
            { key: "month", label: "Month" },
            { key: usePercentsForOccs ? "pct_pickup" : "pickup", label: "Pick up" },
            { key: "pickup_adr", label: "Pick up ADR" },
            { key: usePercentsForOccs ? "pct_pace" : "pace", label: "Pace Variance" },
            { key: "pace_adr", label: "Pace ADR Variance" },
            { key: "rate", label: "Price" },
            { key: "pickup_rate", label: "Price " + calendar_data.pick_up.substring(1) + " days" },
            { key: "pace_rate", label: "Price " + getPaceTitle(calendar_data.pace_string) },
            { key: "total_occ", label: "OTB OCC%" },
            { key: "adr", label: "OTB ADR" },
            { key: "total_rev", label: "OTB revenue" },
        ];

        const data = monthlyData.map(month => {
            const row = {};
            columns.forEach(item => {
                switch(item.key) {
                    case "color": 
                        row[item.label] = month[item.key]? month[item.key].toUpperCase(): null; break;
                    case "pct_pace": case "pace":
                        row[item.label] = getAggregatePaceValue(calendar_data.pace_string, month, usePercentsForOccs); break;
                    case "pace_adr": 
                        row[item.label] = getAggregatePaceAdrValue(calendar_data.pace_string, month); break;
                    case "pace_rate":
                        row[item.label] = getPaceRate(calendar_data.pace_string, month, false); break;
                    case "total_occ":
                        row[item.label] = getPercentOTB(month[item.key], month.total_inventory, month.noOfItemsAggregated); break;
                    default: 
                        row[item.label] = month[item.key]?? null;
                }
            });

            Object.keys(row).forEach(key => row[key] = row[key] == "-"? null: row[key]);
            
            return row;
        });

        return data;
    }

    const transformResData = (reservationsData) => {
        const userSelectedSegments = calendar_data.segments;
        const segmentHeader = userSelectedSegments.includes("ALL SEGMENTS") || userSelectedSegments.some(s => s.includes("GROUP"))
            ? "Segment Group"
            : "Segment";

        const columns = [
            { key: "reservation_id", label: "Reservation ID" },
            { key: "guest_name", label: "Guest Name" },
            { key: "status", label: "Status" },
            { key: "total_occ", label: "Rooms" },
            { key: "segment_name", label: segmentHeader },
            { key: "arrival", label: "Arrival" },
            { key: "departure", label: "Departure" },
            { key: "adr", label: "ADR" }
        ];

        const data = transformReservationsData(propertyInView, reservationsData, true).map(reservation => {
            const row = {};
            columns.forEach(item => {
                switch(item.key) {
                    case "arrival": 
                        row[item.label] = dayjs(reservation[item.key]).format("YYYY-MM-DD"); break;
                    case "departure":
                        row[item.label] = dayjs(reservation[item.key]).format("YYYY-MM-DD"); break;
                    case "adr":
                        row[item.label] = reservation.total_rate / reservation.nights; break;
                    default: 
                        row[item.label] = reservation[item.key]?? null;
                }
            });

            Object.keys(row).forEach(key => row[key] = row[key] == "-"? null: row[key]);

            return row;
        });

        return data;
    }

    return {
        exportCalendarData, exportDailyData, exportWeeklyData, exportMonthlyData, exportReservationsData
    }
}