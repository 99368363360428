import { useState, useEffect } from "react";
// import moment from "moment";
import dayjs from "dayjs";


const useForm = (callback, validate, fields, initialState, isEdit) => {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState([]);
    const [editedValues, setEditedValues] = useState({});

    const handleChange = (key, value) => {
        setValues({
            ...values,
            [key]: value
        });
        if (!touched.includes(key)) {
            setTouched([...touched, key]);
        }
        if (isEdit) {
            setEditedValues({
                ...editedValues,
                [key]: value
            });
        }
    };


    const handleBlur = key => {
        if (!touched.includes(key)) {
            setTouched([...touched, key]);
        }
    };


    useEffect(() => {
        const validationErrors = validate(values, fields);
        const touchedErrors = Object.keys(validationErrors)
            .filter(key => touched.includes(key))
            .reduce((acc, key) => {
                if (!acc[key]) {
                    acc[key] = validationErrors[key];
                }
                return acc;
            }, {});
        setErrors(touchedErrors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, touched]);


    const handleSubmit = () => {
        const errors = validate(values, fields);

        const noErrors = Object.keys(errors).length === 0;

        if (noErrors) {
            callback();
            setEditedValues({});
        } else {
            setTouched(Object.keys(errors));
            setErrors(errors);
        }
    };


    const prePopulateForm = async data => {
        const _values = { ...values };
        Object.keys(_values).map(key => {
            switch (key) {
                case "status":
                    // Field values are also being passed in param(data) that's why there are times the status key contains boolean type value (it came from status field).
                    _values[key] = data[key] === "active" || data[key] == true;
                    break;
                case "brand":
                    _values[key] = (data[key]._id === undefined) ? data[key] : data[key]._id;
                    break;
                case "avg_rate":
                case "avg_dow_rate":
                    _values[key] = (data[key] === undefined) ? 0 : data[key];
                    break;
                case "reservation_cut_off_time":
                    _values[key] = dayjs(data[key], "HH:mm:ss");
                    break;
                default:
                    _values[key] = data[key];
            }
        });
        setValues(_values);
        return _values;
    };


    return {
        handleChange,
        values,
        editedValues,
        handleSubmit,
        handleBlur,
        errors,
        prePopulateForm
    };
};

export default useForm;
