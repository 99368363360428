/** @file Helper functions for segments */


import { isNullOrUndefined } from "./index";
import constants from "../constants";

const ALLSEGMENTS = constants.ALLSEGMENTS;
const SEGMENTGROUPS = constants.SEGMENTGROUPS;
const INDIVSEGMENTS = constants.INDIVSEGMENTS;



/**
 * Default Unsegmented Segment Data
 *
 * @return {Object} Default Unsegmented segment data
*/
export const defaultUnsegmentedSegment = {
    global: "UNS",
    local: {
        code: "UNS",
        name: "Unsegmented",
        description: "Unsegmented",
        gc: "UNS"
    }
}


/**
 * Default Unsegmented Segment Group Data
 *
 * @return {Object} Default Unsegmented segment group data
*/
export const defaultUnsegmentedSegmentGroup = {
    g: "Unsegmented",
    gc: "UNS",
    scs: ["UNS"]
}


/**
 * Determines if a selected segment item is a parent node or not.
 *
 * @function isAParentSegmentNode
 * @param {String} segmentItem Segment item
 * @return {Boolean} Is a parent node, or not.
*/
export function isAParentSegmentNode(value) {
    return value === SEGMENTGROUPS || value === INDIVSEGMENTS || value === `${ALLSEGMENTS}Z`;
}


/**
 * Retrieves the segment option text, given a list of mapped segments and the segment code
 *
 * @function getSegmentOptionText
 * @param {[Object]} hotelSegments List of segments mapped to newly selected property
 * @param {String} segmentCode Segment code
 * @return {String} Segment option text
*/
export function getSegmentOptionText(hotelSegments, segmentCode, isGroup) {
    if (isGroup) {
        if (isNullOrUndefined(segmentCode)) return "";
        if (isNullOrUndefined(hotelSegments)) return segmentCode;
        if (Array.isArray(hotelSegments) && (hotelSegments.length > 0)) {
            let segment = hotelSegments.find(item => item.code === segmentCode);
            return (isNullOrUndefined(segment) || isNullOrUndefined(segment.name))
                ? segmentCode
                : `${segment.name.toString().toUpperCase()} (${segmentCode})`;
        }
    }

    if (isNullOrUndefined(segmentCode)) return "";
    if (isNullOrUndefined(hotelSegments)) return segmentCode;
    if (Array.isArray(hotelSegments) && (hotelSegments.length > 0)) {
        let segment = hotelSegments.filter(item => item.local && (item.local.code === segmentCode))[0];
        return (isNullOrUndefined(segment) || isNullOrUndefined(segment.local) || isNullOrUndefined(segment.local.name))
            ? segmentCode
            : `${segment.local.name.toString().toUpperCase()} (${segmentCode})`;
    }
}


/**
 * Retrieves the segment group option text, given a list of mapped segment groups and the segment group code
 *
 * @function getSegmentGroupOptionText
 * @param {[Object]} hotelSegmentGroups List of segment groups mapped to newly selected property
 * @param {String} segmentGroupCode Segment group code
 * @return {String} Segment group option text
*/
export function getSegmentGroupOptionText(hotelSegmentGroups, segmentGroupCode, isGroup) {
    if (isGroup) {
        if (isNullOrUndefined(segmentGroupCode)) return "";
        if (isNullOrUndefined(hotelSegmentGroups)) return segmentGroupCode;
        if (Array.isArray(hotelSegmentGroups) && (hotelSegmentGroups.length > 0)) {
            let segmentGroup = hotelSegmentGroups.find(item => item.code === segmentGroupCode);
            return (isNullOrUndefined(segmentGroup) || isNullOrUndefined(segmentGroup.name))
                ? segmentGroupCode
                : `${segmentGroup.name.toString().toUpperCase()} (${segmentGroupCode})`;
        }
    }

    if (isNullOrUndefined(segmentGroupCode)) return "";
    if (isNullOrUndefined(hotelSegmentGroups)) return segmentGroupCode;
    if (Array.isArray(hotelSegmentGroups) && (hotelSegmentGroups.length > 0)) {
        let segmentGroup = hotelSegmentGroups.filter(item => item.gc === segmentGroupCode)[0];
        return (isNullOrUndefined(segmentGroup) || isNullOrUndefined(segmentGroup.g))
            ? segmentGroupCode
            : `${segmentGroup.g.toString().toUpperCase()} (${segmentGroupCode})`;
    }
}


/**
 * Checks if the current selected segments from the dashboard segments filter
 * are also in the list of segments mapped to the newly selected property
 *
 * @function areSelectedSegmentsInActiveProperty
 * @param {[String]} selectedSegments List of current selected segments from dashboard segments filter
 * @param {Object} activeProperty Property object data currently in view
 * @return {Boolean} Current selected segments also exists in newly selected property
*/
export function areSelectedSegmentsInActiveProperty(selectedSegments, activeProperty) {
    const hotelSegments = activeProperty.segments;
    const hotelSegmentGroups = activeProperty.segment_groups;

    if (isNullOrUndefined(selectedSegments)) return false;
    if (selectedSegments[0] === ALLSEGMENTS) return false;

    if (isSegmentGroupOnly(selectedSegments)) {
        if (isNullOrUndefined(hotelSegmentGroups)) return false;

        // Check if all currently selected segments from filter are also in the list of segment group codes
        for (let i = 0; i < selectedSegments.length; i++) {
            let hasSegmentGroupMatchInActiveProperty = hotelSegmentGroups.filter((item) => {
                return item.gc === selectedSegments[i].replace(/GROUP-/g, "")
            }).length > 0;
            if (!hasSegmentGroupMatchInActiveProperty) return false;
        }
        return true;
    } else {
        // Get list of segment codes from list of segments  mapped to property
        if (isNullOrUndefined(hotelSegments)) return false;
        let segmentsList = getHotelSegmentCodes(hotelSegments);

        // Check if all currently selected segments from filter are also in the list of segment codes
        let areSegmentsInActiveProperty = selectedSegments.map(item => segmentsList.includes(item));
        let areSelectedSegmentsInActiveProperty = !areSegmentsInActiveProperty.includes(false);
        return areSelectedSegmentsInActiveProperty;
    }
}


/**
 * Handles logic for displaying selected segments filter options.
 * Selected segment options can either be ALL SEGMENTS or a list of individual segments.
 *
 * @function getSelectedSegmentOptions
 * @param {[String]} selectedSegments List of selected segments filter options, sorted from first to last selected
 * @return {[String]} List selected segments filter options, either ALL or list of individual segments
*/
export function getSelectedSegmentOptions(selectedSegments) {
    let selected = selectedSegments;
    let allSegments = "ALL SEGMENTS";

    // If all previous selected individual options are unselected, then revert to "ALL SEGMENTS"
    selected = (selected.length === 0) ? [allSegments] : selected;

    // If the last selected option is "ALL SEGMENTS", then unselect everything else
    let lastSelected = selected[selected.length - 1].toUpperCase();
    selected = (selected.length > 1 && lastSelected === allSegments)
        ? [allSegments]
        : selected;

    // If the last selected option is an individual segment
    // and the previous selected option is "ALL SEGMENTS", then unselect "ALL SEGMENTS"
    selected = (selected.length > 1 && selected.includes(allSegments) && lastSelected !== allSegments)
        ? selected.filter((item, index, arr) => { return item !== allSegments })
        : selected;

    // If the last selected option is a segment group, then unselect individual segments
    // Otherwise if the last selected option is an individual segment, then unselect segment groups
    lastSelected = selected[selected.length - 1].toUpperCase();
    selected = lastSelected.includes("GROUP-")
        ? selected.filter(item => item.includes("GROUP-"))
        : selected.filter(item => !item.includes("GROUP-"))

    return selected;
}


/**
 * Retrieves the list of segments codes from the list of segments mapped to a property
 *
 * @function getHotelSegmentCodes
 * @param {[Object]} hotelSegments List of segments mapped to newly selected property
 * @return {[String]} List of segments codes
*/
export function getHotelSegmentCodes(hotelSegments) {
    let segmentsList = [];
    if (isNullOrUndefined(hotelSegments)) return [];
    if (Array.isArray(hotelSegments) && (hotelSegments.length > 0)) {
        segmentsList = hotelSegments.map((item) => {
            if (item.local) return item.local.code;
        });
        segmentsList = segmentsList.filter(item => !isNullOrUndefined(item));
        return (segmentsList || []);
    }
    return [];
}


/**
 * Retrieves the list of segments group codes from the list of segment groups mapped to a property
 *
 * @function getHotelSegmentGroupCodes
 * @param {[Object]} hotelSegmentGroups List of segment groups mapped to newly selected property
 * @return {[String]} List of segment group codes
*/
export function getHotelSegmentGroupCodes(hotelSegmentGroups) {
    let segmentGroupsList = [];
    if (isNullOrUndefined(hotelSegmentGroups)) return [];
    if (Array.isArray(hotelSegmentGroups) && (hotelSegmentGroups.length > 0)) {
        segmentGroupsList = hotelSegmentGroups.map((item) => {
            if (!isNullOrUndefined(item) && !isNullOrUndefined(item.gc) && !isNullOrUndefined(item.scs)
                && Array.isArray(item.scs) && item.scs.length > 0) {
                return item.gc;
            }
        });
        segmentGroupsList = segmentGroupsList.filter(item => !isNullOrUndefined(item));
        return (segmentGroupsList || []);
    }
    return [];
}


/**
 * Checks if the selected segments in calendar filter is specifically a group only.
 *
 * @function isSegmentGroupOnly
 * @param {[String]} selectedSegments User-selected segments.
 */
export function isSegmentGroupOnly(selectedSegments) {
    return selectedSegments[0].includes("GROUP-");
}


/**
 * Checks if the selected segments in calendar filter is either a group or ALL SEGMENTS.
 * 
 * @function isSegmentGroupSelected
 * @param {[Object]} calendarData The calendar_data from state.
 */
export function isSegmentGroupSelected(calendarData, includeAllSegments = true) {
    if (includeAllSegments && calendarData.segments_string === constants.ALLSEGMENTS) return true;

    return isSegmentGroupOnly(calendarData.segments);
}

/**
 * Gets hotel segments (either individual property or group of properties)
 * @function getHotelSegments
 * @param {Object} property 
 * 
 * @returns {Object} List of segments and segment groups
 */
export function getHotelSegments(property) {
    const segments = [];
    const segmentGroups = [];

    if (property?._id === null) return { segments, segmentGroups };

    const gatherSegments = (property) => {
        property.segments.forEach(segment => {
            segments.push({
                revandyou: segment.global,
                property: segment.local.code,
                pid: property.id
            });
        });

        property.segment_groups.forEach(segment => {
            segmentGroups.push({
                revandyou: segment.global ?? null,
                property: segment.gc,
                pid: property.id
            });
        });
    }

    if (property?.group === true) {
        property.properties.forEach(property => {
            gatherSegments(property);
        });
    } else {
        gatherSegments(property);
    }

    return { segments, segmentGroups }
}

/**
 * Get segments and segment groups' codes and names only
 * @fucntion getHotelSegmentsCodeName
 * @param {[Object]} hotelMappedSegments 
 * @param {[Object]} hotelMappedSegmentGroups 
 * 
 * @returns {Object} List of segment and segment groups
 */
export function getHotelSegmentsCodeName(isGroup, hotelMappedSegments, hotelMappedSegmentGroups) {
    const segments = [], segmentGroups = [];

    if (Array.isArray(hotelMappedSegments)) {
        if (isGroup) {
            // Follow admin/global segments' model
            hotelMappedSegments.forEach(s => {
                segments.push({ 
                    code: s._id, name: s.name, 
                    segment_group: { 
                        code: s?.segment_group?._id?? null, 
                        name: s?.segment_group?.name?? null 
                    }
                });
            });
        } else {
            // Follow property segments' model
            hotelMappedSegments.forEach(s => {
                const segmentGroup = hotelMappedSegmentGroups.find(sg => sg.gc == s.local.gc)?? {};
                segments.push({ 
                    code: s.local.code, name: s.local.name, 
                    segment_group: { 
                        code: segmentGroup?.gc?? null, 
                        name: segmentGroup?.g?? null 
                    }
                });
            });
        }
    }
    
    if (Array.isArray(hotelMappedSegmentGroups)) {
        if (isGroup) {
            // Follow admin/global segments' model
            hotelMappedSegmentGroups.forEach(sg => {
                segmentGroups.push({ 
                    code: sg._id, name: sg.name,
                    segments: Array.isArray(sg.segments)
                        ? sg.segments.map(s => {
                            return { code: s._id, name: s.name }
                        })
                        : []
                });
            });
        } else {
            // Follow property segments' model
            hotelMappedSegmentGroups.forEach(sg => {
                segmentGroups.push({ 
                    code: sg.gc, name: sg.g,
                    segments: Array.isArray(sg.scs)
                        ? sg.scs.map(sc => {
                            const segment = hotelMappedSegments.find(s => s.local.code == sc);
                            return { code: segment?.local?.code, name: segment?.local?.name }
                        })
                        : []
                });
            });
        }
        
    }

    return { segments: segments, segment_groups: segmentGroups };
}
