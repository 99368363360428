import React from 'react';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';

const DataGridPagination = ({ isLoading = false, viewFromNumber, viewToNumber, totalCount, eventHandler}) => {

    const navigatorStyle = { fontSize: "12px", color: "#fff" };

    const disableNext = isLoading || viewToNumber >= totalCount;
    const disablePrev = isLoading || viewFromNumber <= 1;

    return(
        <div className="data-grid-pagination">
            <span>Viewing</span>
            <OutlinedIcon type='left' style={disablePrev? {...navigatorStyle, color: '#868686'}: navigatorStyle} onClick={() => {
                if (disablePrev) return;
                eventHandler({ action: 'paginate', value: 'prev' });
            }} />
        {isLoading === true? (
            <span>
                <OutlinedIcon type='loading' style={{ fontSize: "14px", color: "#808285", marginRight: "4px" }}/>
            </span>
        ): ((viewFromNumber > 0)? 
            <span>{viewFromNumber} - {viewToNumber}</span>:
            <span>0</span>
        )}
            <OutlinedIcon type='right' style={disableNext? {...navigatorStyle, color: '#868686'}: navigatorStyle} theme="outlined"
                onClick={() => {
                    if (disableNext) return;
                    eventHandler({ action: 'paginate', value: 'next' });
                }}
            />
            <span>of</span>
            <span>{totalCount}</span>
        </div>
    )
}

export default DataGridPagination;