export const user_information = [
  {
    key: "name",
    label: "Name of User",
    placeholder: "",
    validation: ["required"],
    type: "text",
    initialValue: "",
  },
  {
    key: "email",
    label: "Email",
    placeholder: "",
    validation: ["required"],
    type: "text",
    initialValue: "",
  },
];

export const user_permissions = {
  key: "type",
  label: "",
  placeholder: "",
  validation: ["required"],
  type: "radio",
  initialValue: "hotel_user",
  options: [
    { value: "ope_admin", label: "OPE Admin" },
    { value: "internal_user", label: "Internal User" },
    { value: "hotel_admin", label: "Property Admin" },
    { value: "hotel_user", label: "Property User" },
  ],
};
