import React from "react";

import TableChart from "elements/Chart/TableChart";
import { arrayToObject } from "../../../utils/data";


/**
 * Matrix View of the Algorithm Modal.
 *
 * @return {Component}
*/
const AlgorithmModalMatrix = () => {

    let numberOfItems = 16;
    let headers = [
        "Demand",
        "Price",
        "Finals",
        "Action to Get Flat",
    ];


    // Green Matrix Data
    let greenForecast = [];
    let greenPrice = [];
    let greenFinals = [];
    let greenAction = [];
    let greenData = [];

    for (let i = 0; i < numberOfItems; i++) {
        greenForecast.push("Green");

        if (i < 4) greenPrice.push("Green");
        if (i >= 4 && i < 8) greenPrice.push("Grey");
        if (i >= 8 && i < 12) greenPrice.push("Yellow");
        if (i >= 12 && i < 16) greenPrice.push("Red");

        if (i % 4 === 0) greenFinals.push("Green");
        if (i % 4 === 1) greenFinals.push("Grey");
        if (i % 4 === 2) greenFinals.push("Yellow");
        if (i % 4 === 3) greenFinals.push("Red");

        if (i === 0 || i === 1 || i === 6 || i === 11) greenAction.push("Flat");
        if (i === 2) greenAction.push("Decrease to Grey");
        if (i === 3 || i === 7) greenAction.push("Decrease to Yellow");
        if (i === 4 || i === 5 || i === 8 || i === 9 || i === 12 || i === 13) greenAction.push("Increase to Green");
        if (i === 10 || i === 14) greenAction.push("Increase to Grey");
        if (i === 15) greenAction.push("Increase to Yellow");

        greenData.push({
            id: i,
            name: null,
            data: [
                greenForecast[i],
                greenPrice[i],
                greenFinals[i],
                greenAction[i],
            ],
            color: null
        })
    }

    let green_data = arrayToObject(greenData);


    // Grey Matrix Data
    let greyForecast = [];
    let greyPrice = [];
    let greyFinals = [];
    let greyAction = [];
    let greyData = [];

    for (let i = 0; i < numberOfItems; i++) {
        greyForecast.push("Grey");

        if (i < 4) greyPrice.push("Green");
        if (i >= 4 && i < 8) greyPrice.push("Grey");
        if (i >= 8 && i < 12) greyPrice.push("Yellow");
        if (i >= 12 && i < 16) greyPrice.push("Red");

        if (i % 4 === 0) greyFinals.push("Green");
        if (i % 4 === 1) greyFinals.push("Grey");
        if (i % 4 === 2) greyFinals.push("Yellow");
        if (i % 4 === 3) greyFinals.push("Red");

        if (i === 0 || i === 5 || i === 6 || i === 11) greyAction.push("Flat");
        if (i === 1 || i === 2) greyAction.push("Decrease to Grey");
        if (i === 3 || i === 7) greyAction.push("Decrease to Yellow");
        if (i === 4 || i === 8 || i === 12) greyAction.push("Increase to Green");
        if (i === 9 || i === 10 || i === 13 || i === 14) greyAction.push("Increase to Grey");
        if (i === 15) greyAction.push("Increase to Yellow");

        greyData.push({
            id: i,
            name: null,
            data: [
                greyForecast[i],
                greyPrice[i],
                greyFinals[i],
                greyAction[i],
            ],
            color: null
        })
    }

    let grey_data = arrayToObject(greyData);


    // Yellow Matrix Data
    let yellowForecast = [];
    let yellowPrice = [];
    let yellowFinals = [];
    let yellowAction = [];
    let yellowData = [];

    for (let i = 0; i < numberOfItems; i++) {
        yellowForecast.push("Yellow");

        if (i < 4) yellowPrice.push("Green");
        if (i >= 4 && i < 8) yellowPrice.push("Grey");
        if (i >= 8 && i < 12) yellowPrice.push("Yellow");
        if (i >= 12 && i < 16) yellowPrice.push("Red");

        if (i % 4 === 0) yellowFinals.push("Green");
        if (i % 4 === 1) yellowFinals.push("Grey");
        if (i % 4 === 2) yellowFinals.push("Yellow");
        if (i % 4 === 3) yellowFinals.push("Red");

        if (i === 4 || i === 9 || i === 10 || i === 15) yellowAction.push("Flat");
        if (i === 0) yellowAction.push("Decrease to Grey");
        if (i === 1 || i === 2 || i === 5 || i === 6) yellowAction.push("Decrease to Yellow");
        if (i === 3 || i === 7 || i === 11) yellowAction.push("Decrease to Red");
        if (i === 8 || i === 12) yellowAction.push("Increase to Grey");
        if (i === 13 || i === 14) yellowAction.push("Increase to Yellow");

        yellowData.push({
            id: i,
            name: null,
            data: [
                yellowForecast[i],
                yellowPrice[i],
                yellowFinals[i],
                yellowAction[i],
            ],
            color: null
        })
    }

    let yellow_data = arrayToObject(yellowData);


    // Red Matrix Data
    let redForecast = [];
    let redPrice = [];
    let redFinals = [];
    let redAction = [];
    let redData = [];

    for (let i = 0; i < numberOfItems; i++) {
        redForecast.push("Red");

        if (i < 4) redPrice.push("Green");
        if (i >= 4 && i < 8) redPrice.push("Grey");
        if (i >= 8 && i < 12) redPrice.push("Yellow");
        if (i >= 12 && i < 16) redPrice.push("Red");

        if (i % 4 === 0) redFinals.push("Green");
        if (i % 4 === 1) redFinals.push("Grey");
        if (i % 4 === 2) redFinals.push("Yellow");
        if (i % 4 === 3) redFinals.push("Red");

        if (i === 4 || i === 9 || i === 14 || i === 15) redAction.push("Flat");
        if (i === 0) redAction.push("Decrease to Grey");
        if (i === 1 || i === 5) redAction.push("Decrease to Yellow");
        if (i === 2 || i === 3 || i === 6 || i === 7 || i === 10 || i === 11) redAction.push("Decrease to Red");
        if (i === 8 || i === 12) redAction.push("Increase to Grey");
        if (i === 13) redAction.push("Increase to Yellow");

        redData.push({
            id: i,
            name: null,
            data: [
                redForecast[i],
                redPrice[i],
                redFinals[i],
                redAction[i],
            ],
            color: null
        })
    }

    let red_data = arrayToObject(redData);



    return (
        <div>
            <div style={{
                display: "grid", gridAutoFlow: "column", width: "100%",
                justifyItems: "center", gridTemplateColumns: "50% 50%", marginBottom: 20
            }}>
                {/* Green Table */}
                <div style={{ marginRight: 10 }}>
                    <p><strong>GREEN</strong></p>
                    <TableChart headers={headers} data={green_data} rowIndecesWithColor={[0, 1, 2]}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} showInitialColumn={false} />
                </div>

                {/* Grey Table */}
                <div style={{ marginLeft: 10 }}>
                    <p><strong>GREY</strong></p>
                    <TableChart headers={headers} data={grey_data} rowIndecesWithColor={[0, 1, 2]}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} showInitialColumn={false} />
                </div>
            </div>

            <div style={{
                display: "grid", gridAutoFlow: "column", width: "100%",
                justifyItems: "center", gridTemplateColumns: "50% 50%", marginBottom: 20
            }}>
                {/* Yellow Table */}
                <div style={{ marginRight: 10 }}>
                    <p><strong>YELLOW</strong></p>
                    <TableChart headers={headers} data={yellow_data} rowIndecesWithColor={[0, 1, 2]}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} showInitialColumn={false} />
                </div>

                {/* Red Table */}
                <div style={{ marginLeft: 10 }}>
                    <p><strong>RED</strong></p>
                    <TableChart headers={headers} data={red_data} rowIndecesWithColor={[0, 1, 2]}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} showInitialColumn={false} />
                </div>
            </div>
        </div>
    )
}


export default AlgorithmModalMatrix;
