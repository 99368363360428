import React, { useEffect, useState } from "react";
// import moment from "moment";
import dayjs from "dayjs";
import ListHeader from "elements/Text/ListHeader";
import IconButton from "elements/Button/Icon";
import Pagination from "elements/Pagination/Basic";
/**
 * Room types list component.
 *
 * @param {[Object]} roomInventories List of room inventory/availability data object.
 * @param {Object} user Rev&You user data object.
 * @param {Function} onAdd Event action after clicking the RoomInventory's Add button.
 * @param {Function} onView Event action after clicking the RoomInventory's View button.
 * @param {Function} onEdit Event action after clicking the RoomInventory's Edit button.
 * @param {Function} onDelete Event action after clicking the RoomInventory's Delete button.
 * @return {Component} Room Inventory list component.
*/
const RoomInventoriesList = ({ roomInventories = [], user, onAdd, onEdit, onDelete }) => {
    const isAdmin = user.type === "ope_admin";
    const isHotelUser = user.type === "hotel_user";
    const hasUpdateAccess = (isHotelUser || isAdmin);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);

    const roomInventoriesToTable = (roomInventories) => {
        return (roomInventories?? []).map(item => {
            return {
                id: item._id,
                stay_dates: `${dayjs(item.start_date).format("MMM DD YYYY").toUpperCase()} - ${dayjs(item.end_date).format("MMM DD YYYY").toUpperCase()}`,
                start_date: item.start_date,
                end_date: item.end_date,
                max_capacity: item.max_capacity
            }
        });
    }

    useEffect(() => {
        setData(roomInventories.sort((a, b) => {
            if (dayjs(a.start_date).isBefore(b.start_date)) return 1;
            if (dayjs(a.start_date).isAfter(b.start_date)) return -1;
            return 0
        }).slice(0, 10)); // 10 is limit
    }, [roomInventories]);
    
    return (
        <div className="room-inventories-list">
            <ListHeader headerCopy="Room Availability" component={
                <IconButton type="add" hasAccess={hasUpdateAccess} action={() => onAdd()} width="25px" />
            } />

            <table className="forms-table">
                <thead>
                    <tr style={{ marginBottom: "8px" }}>
                        <th style={{ flex: "unset", width: "300px" }}>Stay Dates</th>
                        <th style={{ textAlign: "center" }}>Max Capacity</th>
                        <th style={{ textAlign: "right" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody style={{ height: "365px" }}>
                    {!(Array.isArray(data) && data.length > 0)? <tr>
                        <td>No Max Capacity restrictions set</td>
                    </tr>
                    : 
                    roomInventoriesToTable(data).map((item, index) => 
                        <tr key={`${item.id}-${index}`}>
                            <td style={{ flex: "unset", width: "300px" }}>{item.stay_dates}</td>
                            <td style={{ textAlign: "center" }}>{item.max_capacity}</td>
                            <td style={{ textAlign: "right" }}>
                                <IconButton type="edit" hasAccess={true} action={() => onEdit(roomInventories.find(datum => datum._id === item.id))}/>
                                <IconButton type="delete" hasAccess={true} action={() => onDelete(roomInventories.find(datum => datum._id === item.id))}/>
                            </td>
                        </tr>
                     )}
                </tbody>
            </table>
            <div style={{ float: 'right' }}>
                <Pagination 
                    page={currentPage} 
                    limit={10}
                    totalCount={roomInventories.length} 
                    eventHandler={({ action, start, end }) => {
                        if (action === "paginate_next") {
                            setCurrentPage(currentPage + 1);
                        }
                        if (action === "paginate_prev") {
                            setCurrentPage(currentPage - 1);
                        }
                        setData(roomInventories.slice(start - 1, end));
                    }}
                />
            </div>
        </div>
    )
};

export default RoomInventoriesList;
