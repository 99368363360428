import React, { useEffect, useState, useRef } from "react";


export const useWindow = () => {

    const windowRef = useRef(window).current;
    const [isLocal, setIsLocal] = useState(true);

    useEffect(() => {
        if (windowRef) {
            const host = (window?.location?.host ?? "").toString().toLowerCase();
            setIsLocal(host.includes("localhost"));
        }
    }, [windowRef])


    return { isLocal };
}