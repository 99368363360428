import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import mainReducer from "./reducer";
// import chartReducer from "./chartReducer";
import properties from "./propertiesReducer";
import users from "./usersReducer";
import dashboard from "./dashboardReducer";
import adminConsole from "./adminConsoleReducer";
import propertyGroups from "./propertyGroupsReducer";
import thunk from "redux-thunk";
import api from "../../middleware/api";

const appReducer = combineReducers({
  mainReducer,
  users,
  properties,
  propertyGroups,
  dashboard,
  adminConsole
});

const MyAssetsApp = (state, action) => {
  if (action.type === "LOGOUT_REQUEST") {
    state = undefined;
  }

  return appReducer(state, action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, api));

const store = createStore(MyAssetsApp, enhancer);

export default store;
