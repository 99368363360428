import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import { withCurrency } from "utils/index";

const OTB_VS_SDLY = 'OTB vs. SDLY';
const OTB_VS_AVG = 'OTB vs. AVG';
const OTB_VS_AVG_DOW = 'OTB vs. AVG DOW';
const OTB_VS_CMP = 'OTB vs. CMP';


/**
 * Line chart component.
 * Data parameter has the following format: ["Days Out", "SDLY", "Average", "AverageDOw"]
 *
 * @function LineChart
 * @param {String} chartName Identifier for component customizations.
 * @param {[Number]} data Array of line chart data, defaults to an empty array.
 * @param {[String]} lines Names of the items in the line chart data array, defaults to "["vs. SDLY", "vs. AVG", "vs. AVG DOW"]".
 * @param {String} title Chart title, defaults to an empty string.
 * @param {String} xTitle Chart horizontal axis title, defaults to "Days Out".
 * @param {String} yTitle Chart vertical axis title, defaults to empty string.
 * @param {Number} width Line chart width, defaults to "600px".
 * @param {Number} height Line chart height, defaults to "400px".
 * @param {Boolean} hasCurrency Displays currency to vertical axis values, or not. Defaults to false.
 * @param {Number} direction Direction in which the values along the horizontal axis grow. Defaults to -1.
 * @return {Component} Line chart component.
*/
const LineChart = ({
    chartName = '', width = 600, height = 400, title = "", xTitle = "Days Out", yTitle = "", hasCurrency = false,
    data = [], lines = [OTB_VS_SDLY, OTB_VS_AVG, OTB_VS_AVG_DOW], direction = 1, isPercent = false, colors = []
}) => {
    const state = useSelector((state) => state.mainReducer);
    const { sidebarData } = state;
    const c = sidebarData?.currency;

    let chartData = transformData(data, lines, chartName, isPercent, c, colors);
    return (
        <div className="rms-line-chart">
            <span className="title">{title}</span>
            <span className="yTitle">{yTitle}</span>
            <div className="rms-line-chart-lines">
                {lines.map((line, i) =>
                    <span key={line} className="legend">
                        <span
                            style={{
                                backgroundColor: chartData.lineColors[i]
                            }}
                            className="colored-line">
                        </span>
                        {line}
                    </span>
                )}
            </div>
            <Chart
                width={width}
                height={height}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={chartData.data}
                options={{
                    // title: title,
                    backgroundColor: 'transparent',
                    titleTextStyle: {
                        bold: true,
                        fontName: "Titillium Web",
                        italic: false,
                        color: "#282F5B"
                    },
                    colors: chartData.lineColors,
                    legend: 'none',
                    interpolateNulls: true,
                    tooltip: { isHtml: true },
                    hAxis: {
                        direction: direction,
                        textStyle: { fontName: "Lato" },
                        ticks: data.map(item => item[0]),
                        title: xTitle,
                        titleTextStyle: {
                            bold: true,
                            fontName: "Titillium Web",
                            italic: false,
                            color: "#282F5B",
                            fontSize: 14
                        },
                        gridlines: { count: 0 },
                    },
                    vAxis: {
                        // title: yTitle,
                        textStyle: { fontName: "Lato" },
                        titleTextStyle: {
                            bold: true,
                            fontName: "Titillium Web",
                            italic: false,
                            color: "#282F5B"
                        },
                        baselineColor: "#efefef",
                        // format: hasCurrency ? `${getActiveHotelCurrency()} #` : "#",
                    },
                    chartArea: {
                        width: width - 80,
                        height: height - 140
                    }
                }}
            />
        </div>
    )
}

/**
 * Creates basic tooltip content. Composing multiple label and value pair.
 * @param {[Object]} data a
 */
const generateTooltip = (data = []) => {
    let tooltip = '';
    data.forEach((item) => {
        tooltip += `<p>${item.label}: <b>${item.value}</b></p>`
    });
    return tooltip;
}


LineChart.propType = {
    width: PropTypes.number,
    height: PropTypes.number,
    xTitle: PropTypes.string,
    yTitle: PropTypes.string,
    withCurrency: PropTypes.bool,
    data: PropTypes.array,
    lines: PropTypes.array,
    colors: PropTypes.array,
};

    // ORDER OF COLORS
    // Green - Property
    // Yellow 
    // Gray
    // Red 
    // Blue
    // Violet


/**
 * Reconstruct data to match requirements for adding custom tooltip.
 * @param {[Object]} data Array of line chart data, defaults to an empty array.
 * @param {[String]} lines Array line descriptions.
 * @param {[String]} chartName Name of pie chart. Used for tooltip customization.
 * @returns {Object} { data: [], colors: [] }
 */
const transformData = (data = [], lines, chartName, isPercent, c, colors = []) => {
    const chartHeader = [];
    const chartData = [];

    const chartDefaultLineColors = ["#EE9421", "#4CBC90", "#282F5B", "#808080", "#ed474b"];
    const chartsWithCustomTooltip = {
        dashboard_pickup_occ: { addCurrency: false, appendLabel: ' Pickup', appendValue: isPercent ? '%' : ' rooms' },
        dashboard_pace_occ: { addCurrency: false, appendLabel: ' Rooms Sold', appendValue: isPercent ? '%' : ' rooms' },
        dashboard_pickup_adr: { addCurrency: true, appendLabel: '', appendValue: '' },
        dashboard_pace_adr: { addCurrency: true, appendLabel: '', appendValue: '' },
        dashboard_price: { addCurrency: true, appendLabel: '', appendValue: '' },
        dashboard_compricing: { addCurrency: true, appendLabel: '', appendValue: '' }
    }

    const dataClone = data.map(d => [...d]);
    // Determine if data is empty.
    const isDataEmpty = data.every((row, rowIdx) => {
        return row.every((val, valIdx) => {
            if (valIdx === 0) return true;
            return val == null
        })
    });
    // Setting colors for all lines. If data is empty or not.
    // If Data is empty, all line colors should be TRANSPARENT.
    // If Data is not empty, use default line colors or custom colors.
    const lineColors = isDataEmpty === true
        ? (Array(lines.length)).fill("transparent")
        : !(Array.isArray(colors) && colors.length > 0)
            ? lines.map((line, index) => chartDefaultLineColors[index])
            : [...colors];
    // Modify data's value.
    // First item of each row should be coverted to String.
    // The rest should be NaN if only if null. To prevent plotting of null data.
    dataClone.forEach((row, rowIdx) => {
        row.forEach((col, colIdx) => {
            if (colIdx === 0) {
                dataClone[rowIdx][colIdx] = col != null
                    ? col.toString(): col
                return;
            }
            dataClone[rowIdx][colIdx] = col?? NaN;
        });
    });
    
    if (Object.keys(chartsWithCustomTooltip).includes(chartName)) {
        const chartInfo = chartsWithCustomTooltip[chartName];
        // Create headers.
        chartHeader.push("Days Out");
        lines.forEach(line => {
            // Add combination of value and tooltip config per line.
            chartHeader.push(line);
            chartHeader.push({ role: 'tooltip', p: { html: true } });
        });

        if (isDataEmpty) {
            dataClone.forEach(row => {
                const values = [];
                lines.forEach(() => {
                    // Add combination of value and tooltip config per line.
                    values.push(0);
                    values.push("");
                });
                // Number of items should much chartHeader's
                chartData.push([ row[0], ...values ])
            });
        } else {
            dataClone.forEach((item, itemIdx) => {
                // Number of items should much chartHeader's
                const chartDataItem = [item[0]];
                item.forEach((value, valIdx) => {
                    if (valIdx === 0) return;
                    // Add combination of value and tooltip config per line.
                    chartDataItem.push(value);
                    chartDataItem.push(generateTooltip([{
                        label: 'Days out to check-in',
                        value: item[0]
                    }, {
                        label: lines[valIdx - 1] + chartInfo.appendLabel,
                        value: (chartInfo.addCurrency ? withCurrency(value, c) : value) + chartInfo.appendValue
                    }]))
                });
                chartData.push(chartDataItem);
            });
        }
    }

    return { data: [chartHeader, ...chartData], lineColors: lineColors, isEmpty: isDataEmpty };

}

export default LineChart;