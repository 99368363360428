import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Visuals from './_Visuals';
import { Exit } from "Icons";
import constants from "../../constants";
import useHelperFunctions from '../../store/actions/actions';



const DashboardModal = ({ visible }) => {
    const dispatch = useDispatch();
    const { toggleChartModal } = useHelperFunctions();
    const { visualsAreDisplayed } = useSelector(state => state.mainReducer);


    return (
        <div className={`rms-dashboard-modal ${visible ? 'active' : ''}`}>
            <div className="rms-dashboard-modal-container">
                <div className="rms-dashboard-modal-close">
                    <Exit width="25px" height="25px" className="pointer"
                        onClick={() => {
                            toggleChartModal({ show: false })
                            dispatch({ type: constants.SHOW_VISUALS, value: false })
                        }} />
                </div>
                <div className="rms-dashboard-modal-container-body">
                    {visualsAreDisplayed && <Visuals />}
                </div>
            </div>
        </div>
    );
}


DashboardModal.propTypes = {
    visible: PropTypes.bool
}

export default DashboardModal;
