import * as React from "react";

function SvgQuestion(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <g data-name="Layer 1">
        <circle cx={50} cy={49.48} r={47.5} fill="#67b489" />
        <path
          d="M51.06 30.48q5.28 0 8 2.15t2.67 6.45a16.74 16.74 0 01-.86 6.25 11 11 0 01-3.6 4 15.57 15.57 0 00-3.47 3.16 4.38 4.38 0 00-.8 2.45v1.71h-5.85a7.16 7.16 0 01-1.71-5c0-1.32 1.16-2.92 3.5-4.79A29.8 29.8 0 0053.23 43a4.29 4.29 0 00.8-2.62c0-2.16-1.56-3.25-4.68-3.25a36.17 36.17 0 00-6.77.66l-1.16.21-.42-5.59a26.46 26.46 0 0110.06-1.93zM45.94 68.6v-8.87h7.93v8.87z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgQuestion;
