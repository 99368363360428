import React from "react";
import OutlinedIcon from "elements/Icon/OutlinedIcon";

const LoadingSpinner = ({
    size = "20px",
    color = "#808285",
    mask = false,
    offSetLeft = 0,
    offSetTop = 0,
    text = null
}) => {
    return (
        <div className={`loadingSpinner ${mask && "with-mask"}`}>
            <div
                className="icon-container"
                style={{
                    left: `calc(50% - ${offSetLeft})`,
                    top: `calc(50% + ${offSetTop})`
                }}
            >
                {(text === null)
                    ? <OutlinedIcon style={{ fontSize: size, color: color }} type="loading" />
                    : (<div style={{ width: 'max-content', display: 'grid', placeItems: 'center' }}>
                        <OutlinedIcon style={{ fontSize: size, color: color }} type="loading" />
                        <br />
                        <span style={{ fontFamily: "Lato", fontSize: 12 }}>
                            {text}
                        </span>
                    </div>)}
            </div>
        </div>
    );
};

export default LoadingSpinner;
