import * as React from "react";

function SvgDashboard(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <defs>
        <style>
          {
            ".Dashboard_svg__cls-1{fill:#67b489}.Dashboard_svg__cls-2{fill:none}"
          }
        </style>
      </defs>
      <g id="Dashboard_svg__Layer_1" data-name="Layer 1">
        <g id="Dashboard_svg__nx3Hsx">
          <path
            className="Dashboard_svg__cls-1"
            d="M54.38 19.49c3.78 1.1 7.32 2 10.73 3.18 2.61.93 5 1.39 6.31-1.6 1.43-3.28-.8-4.8-3.44-5.86a48.51 48.51 0 00-27.33-2.6C8.5 18.57-8.14 57.26 10 84.3c.94 1.41 3.56 2.89 5 2.54a24.75 24.75 0 008.72-4.25c1.07-.8 1.53-3.64 1-5-.43-1-3-1.62-4.55-1.55S17 77.23 15.32 78c-5.47-10.68-6.27-21.27-2.45-32.27 3.72.69 7.52 5.17 10 .21 2.68-5.29-3.62-5.42-6.21-8 6.94-10.39 16.54-16.35 29-18.08 1.17 3.34-1.34 9.07 4.42 9 5.43 0 3.35-5.39 4.3-9.37zm30.27 58.59c-1.64-.74-2.94-1.28-4.21-1.9C78 75 75.66 75.58 75 78c-.38 1.49.66 4 1.91 5.07 1.94 1.62 4.62 2.32 7 3.49 2.61 1.32 4.71.55 6-1.81A72 72 0 0095 73.85a46.33 46.33 0 00-.87-32.17c-1.06-2.78-2.46-5.31-5.94-3.92-3.28 1.31-2.66 4-1.51 6.61a16.82 16.82 0 01.67 1.84 39.66 39.66 0 01-2.7 31.87z"
          />
          <path
            className="Dashboard_svg__cls-1"
            d="M54.38 19.49c-1 4 1.13 9.37-4.3 9.4-5.76 0-3.25-5.7-4.42-9-12.42 1.73-22 7.69-29 18.08 2.59 2.59 8.89 2.72 6.21 8-2.52 5-6.32.48-10-.21-3.82 11-3 21.59 2.45 32.24 1.68-.77 3.18-1.9 4.68-2s4.12.53 4.55 1.55c.57 1.36.11 4.2-1 5a24.75 24.75 0 01-8.72 4.25c-1.4.35-4-1.13-5-2.54-18.09-27-1.45-65.73 30.7-71.69A48.51 48.51 0 0168 15.21c2.64 1.06 4.87 2.58 3.44 5.86-1.31 3-3.7 2.53-6.31 1.6-3.43-1.21-6.97-2.08-10.75-3.18z"
          />
          <path
            className="Dashboard_svg__cls-2"
            d="M54.38 19.49c-1 4 1.13 9.37-4.3 9.4-5.76 0-3.25-5.7-4.42-9-12.42 1.73-22 7.69-29 18.08 2.59 2.59 8.89 2.72 6.21 8-2.52 5-6.32.48-10-.21-3.82 11-3 21.59 2.45 32.24 1.68-.77 3.18-1.9 4.68-2s4.12.53 4.55 1.55c.57 1.36.11 4.2-1 5a24.75 24.75 0 01-8.72 4.25c-1.4.35-4-1.13-5-2.54-18.09-27-1.45-65.73 30.7-71.69A48.51 48.51 0 0168 15.21c2.64 1.06 4.87 2.58 3.44 5.86-1.31 3-3.7 2.53-6.31 1.6-3.43-1.21-6.97-2.08-10.75-3.18z"
          />
          <path
            className="Dashboard_svg__cls-1"
            d="M45 69.29c-5.75-2.76-7.68-9.94-4.65-15.55a9.06 9.06 0 012.4-3c11.5-8.59 23.31-16.77 35.17-24.84 1.18-.81 4.17-.59 5.12.36s1.09 3.92.28 5.11Q71.17 49.11 58.51 66.53c-2.81 3.86-8.36 4.85-12.81 3.06-.24-.09-.47-.19-.7-.3zM84.65 78.08a39.66 39.66 0 002.73-31.87 16.82 16.82 0 00-.67-1.84c-1.15-2.6-1.77-5.3 1.51-6.61 3.48-1.39 4.88 1.14 5.94 3.92A46.33 46.33 0 0195 73.85a72 72 0 01-5.16 10.92c-1.27 2.36-3.37 3.13-6 1.81-2.33-1.17-5-1.87-7-3.49-1.25-1-2.29-3.58-1.91-5.07.63-2.44 3-3 5.41-1.84 1.37.62 2.66 1.16 4.31 1.9z"
          />
          <path
            className="Dashboard_svg__cls-2"
            d="M84.65 78.08a39.66 39.66 0 002.73-31.87 16.82 16.82 0 00-.67-1.84c-1.15-2.6-1.77-5.3 1.51-6.61 3.48-1.39 4.88 1.14 5.94 3.92A46.33 46.33 0 0195 73.85a72 72 0 01-5.16 10.92c-1.27 2.36-3.37 3.13-6 1.81-2.33-1.17-5-1.87-7-3.49-1.25-1-2.29-3.58-1.91-5.07.63-2.44 3-3 5.41-1.84 1.37.62 2.66 1.16 4.31 1.9z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDashboard;
