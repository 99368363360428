import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../Button/Button';
import DataGrid from '../../../DataGrid';
import { gridPricingRecommendationColumns } from './gridConfig';
import usePropertiesActions from '../../../../store/actions/propertiesActions';

const PricingRecommendationSection = (props) => {
    const isGroup = props?.isGroup ?? false;
    const group = props.values;
    const { property, loadingProperty } = useSelector(state => state.properties);
    const item = isGroup ? group : property;
    const forecastSettingsPrice = item?.forecast_settings_v2.pricing_recommendation;

    const gridPricingRecoRef = useRef();
    const { updateForecastSettingsV2 } = usePropertiesActions();

    useEffect(() => {
        if (loadingProperty) return;
        gridPricingRecoRef.current.loadData(getColumnData(forecastSettingsPrice, false));
    }, []);

    const getColumnData = (pricingReco, loadDefaults) => {
        if (loadDefaults) {
            return [{}];
        }
        return [{
            increase_pricing_threshold_3: pricingReco.threshold.good.level_3,
            increase_pricing_threshold_2: pricingReco.threshold.good.level_2,
            increase_pricing_threshold_1: pricingReco.threshold.good.level_1,
            decrease_pricing_threshold_1: pricingReco.threshold.bad.level_1,
            decrease_pricing_threshold_2: pricingReco.threshold.bad.level_2,
            decrease_pricing_threshold_3: pricingReco.threshold.bad.level_3
        }]
    }

    const handleSubmit = () => {
        if (gridPricingRecoRef.current.internalState.hasError) return;

        const newForecastSettingsPricingReco = { ...forecastSettingsPrice };

        newForecastSettingsPricingReco.threshold.good.level_3 = gridPricingRecoRef.current.getData('increase_pricing_threshold_3');
        newForecastSettingsPricingReco.threshold.good.level_2 = gridPricingRecoRef.current.getData('increase_pricing_threshold_2');
        newForecastSettingsPricingReco.threshold.good.level_1 = gridPricingRecoRef.current.getData('increase_pricing_threshold_1');
        newForecastSettingsPricingReco.threshold.bad.level_1 = gridPricingRecoRef.current.getData('decrease_pricing_threshold_1');
        newForecastSettingsPricingReco.threshold.bad.level_2 = gridPricingRecoRef.current.getData('decrease_pricing_threshold_2');
        newForecastSettingsPricingReco.threshold.bad.level_3 = gridPricingRecoRef.current.getData('decrease_pricing_threshold_3');

        if (gridPricingRecoRef.current.errors.length > 0) return;

        updateForecastSettingsV2(isGroup ? group.id : property._id, newForecastSettingsPricingReco, 'pricing_recommendation', isGroup)
            .then(result => {
                if (result.type === "UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS") {
                    const updatedProperty = result.response.data.data;
                    gridPricingRecoRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.pricing_recommendation));
                }
            });
    };

    const handleCancel = () => {
        gridPricingRecoRef.current.reload();
    };

    return (
        <div className="pricing-recommendation-section">
            <DataGrid ref={gridPricingRecoRef} columns={gridPricingRecommendationColumns} defaultCellHeight="40px" footerHeight="0px" />
            <div style={{ display: "flex", flexDirection: 'row-reverse', paddingTop: "25px" }}>
                <Button type="primary" className="submit" onClick={handleSubmit} style={{ marginLeft: "35px" }}>Submit</Button>
                <Button type="bg-dark" onClick={handleCancel}>Cancel</Button>
            </div>
        </div>
    )
}

export default PricingRecommendationSection;