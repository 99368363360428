import React from "react";

import { isNullOrUndefined } from "utils";


/**
 * A list header element. Requires some header text.
 * Can have a component displayed next to it.
 *
 * @param {String} headerCopy The header copy to display.
 * @param {Component} component Component to display next to the header copy. Defaults to undefined.
 * @param {Boolean} spaceBetween Whether header content is spaced-between or not. Defaults to false.
 * @return {Component} A list header element.
*/
const ListHeader = ({ headerCopy, component = null, spaceBetween = false }) => {
    let justifyContent = `${spaceBetween ? "space-between" : ""}`;

    return (
        <div className="custom-header" style={{ display: "flex", justifyContent: justifyContent }}>
            <h3>{headerCopy}</h3>

            {!isNullOrUndefined(component) && component}
        </div>
    );
};

export default ListHeader;
