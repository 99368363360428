/* eslint-disable react-hooks/exhaustive-deps */
/** @file Helper custom hook functions */


import { useCallback, useState, useEffect, useReducer } from "react";



/**
 * A custom hook for representing a toggle functionality using useState.
 * It's a more succint way of expressing the state than just using the simple useState.
 * From: https://daveceddia.com/custom-hooks/
 * Usage:
 *   const [isVisible, toggleVisible, reset] = useToggle(false)
 *   <button onClick={toggleVisible}>
 *
 * @exports useToggle
 * @param {Boolean} initialValue Initial toggle value.
 * @return {[Boolean, Function]} An array of the toggle value and a toggle function.
*/
export function useToggle(initialValue) {
    const [value, setValue] = useState(initialValue);

    const toggleValue = () => setValue(!value);
    const resetValue = () => setValue(initialValue);

    return [value, toggleValue, resetValue];
}


/**
 * A custom hook for representing boolean and setting boolean values using useState.
 * Simple use case is in hiding/showing modals, sidebars, or tooltips.
 * From: https://daveceddia.com/custom-hooks/
 * Usage:
 *   const [isVisible, showModal, hideModal] = useBoolean(initialValue)
 *
 * @exports useBoolean
 * @param {Boolean} initialValue Initial boolean value.
 * @return {[Boolean, Function, Function]} An array of the boolean value and two boolean functions.
*/
export function useBoolean(initialValue) {
    const [value, setValue] = useState(initialValue);

    const setTrue = () => setValue(true);
    const setFalse = () => setValue(false);

    return [value, setTrue, setFalse];
}


/**
 * A custom hook for carrying out array-related tasks.
 * From: https://blog.bitsrc.io/writing-your-own-custom-hooks-4fbcf77e112e
 * Usage:
 *   const todos = useArray(["hi", "there", "sup", "world"])
 *   <button onClick={() => todos.add(Math.random())}>
 *
 * @exports useArray
 * @param {Boolean} initialValue Initial boolean value.
 * @return {[Boolean, Function, Function]} An array of the boolean value and two boolean functions.
*/
export function useArray(initialValue) {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        setValue,
        add: useCallback(a => setValue(v => [...v, a])),
        clear: useCallback(() => setValue(() => [])),
        removeById: useCallback(id => setValue(arr => arr.filter(v => v && v.id !== id))),
        removeIndex: useCallback(index => setValue(v => {
            v.splice(index, 1)
            return v
        }))
    }
}


/**
 * A custom hook for persisting a desired state via localStorage.
 * From: https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
 * Usage:
 *   const [user, setUser] = usePersistedState("me", null)
 *
 * @exports usePersistedState
 * @param {String} key Key for the persisted state stored in localStorage.
 * @param {Object} defaultValue Default value for given key.
 * @return {Object} A tuple of the state and an updater function.
*/
export function usePersistedState(key, defaultValue) {
    const [state, setState] = useState(
        () => JSON.parse(localStorage.getItem(key)) || defaultValue
    );

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state))
    }, [key, state])

    return [state, setState];
}


/**
 * A custom hook for checking if a click event has happened outside a desired element.
 * Uses a classname for better validation instead of just relying on whether the target contains the ref.
 *
 * @exports useOutsideClickChecker
 * @param {Object} ref An element reference. Defined by a useRef() on the component with the element.
 * @param {String} classNameToCheck A class name value.
 * @param {Function} action An action that happens when a click was made outside the element.
 * @return {Function} Outside click checker.
*/
export function useOutsideClickChecker(ref, classNameToCheck, action) {
    useEffect(() => {
        function handleClickOutside(event) {
            try {
                let target = event.target
                let targetClass = target.className.toString()
                if (!(target.contains(ref.current) || targetClass.includes(classNameToCheck))) {
                    action()
                }
            } catch (error) { }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref])
}


/**
 * A custom hook for loading images progressively, using a fallback image until the full image is available.
 * From: https://dev.to/selbekk/how-to-write-a-progressive-image-loading-hook-1jj2
 *
 * @exports useProgressiveImage
 * @param {Object} src Full image source.
 * @param {String} fallbackSrc Fallback image source.
 * @return {Function} Progressive image loader.
*/
export function useProgressiveImage({ src, fallbackSrc }) {
    const reducer = (currentSrc, action) => {
        if (action.type === "GET_FULL") return action.src
        if (!currentSrc) return action.src
        return currentSrc
    }
    const [currentSrc, dispatch] = useReducer(reducer, null);

    useEffect(() => {
        const mainImage = new Image()
        const fallbackImage = new Image()

        mainImage.onload = () => dispatch({ type: "GET_FULL", src })
        fallbackImage.onload = () => dispatch({ type: "GET_FALLBACK", src: fallbackSrc })

        mainImage.src = src
        fallbackImage.src = fallbackSrc
    }, [src, fallbackSrc])

    return currentSrc;
}
