import React from "react";
import { Provider } from "react-redux";
// import ReactDOM from "react-dom";
import ReactDOM from 'react-dom/client';
import { Router } from "react-router-dom";
import { Auth0Provider } from "./react-auth0-spa";
import HttpsRedirect from "react-https-redirect";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";
import store from "./store/reducer";


const {
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_APP_CLIENT_ID,
    REACT_APP_AUTH0_AUDIENCE
} = process.env;

const config = {
    "domain": REACT_APP_AUTH0_DOMAIN,
    "clientId": REACT_APP_AUTH0_APP_CLIENT_ID,
    "audience": REACT_APP_AUTH0_AUDIENCE
}


// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin}
        audience={config.audience}
        onRedirectCallback={onRedirectCallback}
    >
        <Provider store={store}>
            <HttpsRedirect>
                <Router history={history}>
                    <App />
                </Router>
            </HttpsRedirect>
        </Provider>
    </Auth0Provider>
);

serviceWorker.unregister();
