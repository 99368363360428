// import moment from "moment";
import dayjs from "dayjs";
import { ToastsStore } from "react-toasts";
import constants from "../constants";
import { isNullish } from "./data";


/**
 * Checks if a value is null or not.
 *
 * @function isNull
 * @param {?number} value Value to be checked
 * @return {boolean} Value is null or not
*/
const isNull = (value) => {
    return value === null;
}


/**
 * Checks if a value is undefined or not.
 *
 * @function isUndefined
 * @param {?number} value Value to be checked
 * @return {boolean} Value is undefined or not
*/
const isUndefined = (value) => {
    return value === undefined;
}


/**
 * Checks if a value is equal to -1.
 *
 * @function isNegativeOne
 * @param {?number} value Value to be checked
 * @return {boolean} Value is undefined or not
*/
const isNegativeOne = (value) => {
    return value === -1;
}


/**
 * Checks if a value is a string.
 *
 * @function isString
 * @param {?number|string} value Value to be checked
 * @return {boolean} Value is string or not
*/
const isString = (value) => {
    return (typeof value === 'string' || value instanceof String);
}



export function isNullOrUndefined(x) {
    return x === null || x === undefined;
}

export function negativeToDash(value) {
    if (value < 0) return "-" + Math.abs(value);
    return value;
}

export function negativeToBrackets(value) {
    if (value < 0) return "(" + Math.abs(value) + ")";
    return value;
}

export function abbreviateNumber(value, lowestValue) {
    var newValue = value;
    if (value >= lowestValue) {
        var suffixes = ["", "k", "m", "b", "t"];
        var suffixNum = Math.floor(("" + value).length / 3);

        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat(
                (suffixNum !== 0
                    ? value / Math.pow(1000, suffixNum)
                    : value
                ).toPrecision(precision)
            );

            var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
            if (dotLessShortValue.length <= 2) {
                break;
            }
        }
        if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
}

export function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function toInt(value, useMath = false) {
    if (isString(value)) return "-";
    if (isNull(value) || isUndefined(value) || isNaN(value)) return "-";
    return useMath
        ? Math.round(value)
        : parseInt(value);
}


export function formatNegativeOne(value) {
    if (isNegativeOne(value)) return "-";
    return value;
}

export function formatData(value, lowestValue = 10000, useShorten = false, rounded = true) {
    if (isString(value)) return "-";
    if (isNull(value) || isUndefined(value) || isNaN(value)) return "-";

    let valueAsInteger = Math.round(value);
    let valueAsNegative = negativeToDash(rounded ? valueAsInteger : value);
    let valueAsAbbreviated = useShorten
        ? shortenNumber(valueAsNegative)
        : abbreviateNumber(valueAsNegative, lowestValue);
    let valueWithCommas = numberWithCommas(valueAsAbbreviated);
    return valueWithCommas;
}

export function formatDataWithPlusSign(value, rounded = true) {
    if (isString(value)) return "-";
    if (rounded) return (value > 0 ? "+" : "") + formatData(value);
    return (value > 0 ? "+" : "") + formatData(value, 10000, false, false);
}

export function withCurrency(value, currency) {
    if (currency === false) return `${value}`;
    let storedCurrency = JSON.parse(localStorage.getItem("activeHotel"));
    let hasStoredCurrency = !isNullOrUndefined(storedCurrency.currency);
    let activeHotelCurrency = hasStoredCurrency ? storedCurrency.currency.symbol : null;

    if (isNullOrUndefined(currency) || isNullOrUndefined(currency.symbol)) {
        return isNullOrUndefined(activeHotelCurrency)
            ? `${value}`
            : `${activeHotelCurrency} ${value}`;
    }
    return `${currency.symbol} ${value}`;
}

export function percent(value) {
    if (isString(value)) return "-";
    if (isNull(value) || isUndefined(value) || isNaN(value)) return "-";

    return `${value}%`;
}



export function getPaceTitle(value) {
    switch (value) {
        case "averageroomsold":
            return "AVG Rooms Sold";
        case "averagedowroomsold":
            return "AVG DOW Rooms Sold";
        case "samedaylastweek":
            return "Same Day Last Week";
        case "samedaylastmonth":
            return "Same Day Last Month";
        case "lastyear":
            return "Last Year";
        default:
            return "SDLY";
    }
}

export function getPaceRate(paceType, data, currency) {
    if (paceType === "averageroomsold") return withCurrency(formatData(
        isNullish(data.average_rate) ? null : Math.round(data.average_rate)), currency);
    if (paceType === "averagedowroomsold") return withCurrency(formatData(
        isNullish(data.average_rate_dow) ? null : Math.round(data.average_rate_dow)), currency);
    return withCurrency(formatData(isNullish(data.pace_rate) ? null : Math.round(data.pace_rate)), currency);
};

export function getAggregatePaceValue(paceType, data, isGroup = false) {
    if (isGroup) {
        if (paceType === "averageroomsold") return formatDataWithPlusSign(Math.round(data.pct_avgdo));
        if (paceType === "averagedowroomsold") return formatDataWithPlusSign(Math.round(data.pct_avgdow));
        if (paceType === "samedaylastyear") return formatDataWithPlusSign(Math.round(data.pct_pace));
        if (paceType === "lastyear") return formatDataWithPlusSign(Math.round(data.pct_pace));
        return null;
    } else {
        if (paceType === "averageroomsold") return formatDataWithPlusSign(data.average_occ);
        if (paceType === "averagedowroomsold") return formatDataWithPlusSign(data.average_occ_dow);
        if (paceType === "samedaylastyear") return formatDataWithPlusSign(data.pace);
        if (paceType === "lastyear") return formatDataWithPlusSign(data.pace);
        return null;
    }
}

export function getAggregatePaceAdrValue(paceType, data) {
    if (paceType === "averageroomsold") return formatData(data.average_adr);
    if (paceType === "averagedowroomsold") return formatData(data.average_adr_dow);
    if (paceType === "samedaylastyear") return formatData(data.pace_adr);
    if (paceType === "lastyear") return formatData(data.pace_adr);
    return null;
}

export function getDailyCellColor(color) {
    if (color === "offwhite") return "off-white";
    if (color === "disabled") return "disabled";
    return "";
}

export function getDailyCellStatus(status, data) {
    if (data) {
        if (status === "disabled") return "--none";
        if (data.color === "red") return "--red";
        if (data.color === "yellow") return "--warning";
        if (data.color === "green") return "--green";
        if (data.color === "gray" || data.color === "grey") return "--neutral";
    }
    return "";
}

export function getAggregateCellStatus(color) {
    if (color === "red") return "bad";
    if (color === "yellow") return "warning";
    if (color === "green") return "good";
    if (color === "gray" || color === "grey") return "neutral";
    return null;
}

// For reference only. This is supposedly a new version. But it seems the old one is working properly.
// export function shortenNumber(value) {
//     const suffixes = ["k", "m", "b", "t", "q"];
//     for (let idx = suffixes.length -1; idx >= 0; idx--) {
//         // Start from Quadrillion to K
//         const num = [...Array(idx + 1)].reduce((total) => total * 1000, 1);
//         const result = value / num;
//         if (Math.floor(result) !== 0) {
//             // The result contains a whole number greater than 0.
//             // Include 1 decimal if the decimal is not 0.
//             return `${(result.toFixed(1) * 10) / 10}${suffixes[idx]}`;
//         }
//     }
//     return value;
// }

export function shortenNumber(value) {
    let suffixNum = 0;
    let num = value + "";
    let absNum = (Math.abs(value)).toString();
    let suffixes = ["", "k", "m", "b", "t", "q"];
    let base = "";
    if (absNum.length >= 5) {
        let temp;
        do {
            if ((Math.round(parseFloat(num) / 1000) + "").length > 3) {
                temp = Math.round(parseFloat(num) / 1000) + "";
            } else {
                temp = Math.round((parseFloat(num) / 1000) * 10) / 10 + "";
            }

            suffixNum++;

            num = temp;
            base = temp.split(".")[0];
        } while (base.length > 3);
    }

    return num + suffixes[suffixNum];
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function strTranslation(key) {
    const translations = {
        average_occ: "Occupancy",
        pickup: "Pick-up",
        average_rate: "Price",
        average_daily_rate: "ADR",
        average_rev: "Revenue",
    };

    return translations[key];
}

export const colors = [
    "#ffd54f",
    "#1e2147",
    "#39b1dc",
    "#4fc3f7",
    "#7986cb",

    "#fff9c4",
    "#51b68a",
    "#80cbad",
    "#c5cae9",
    "#b3e5fc",

    "#283747",
    "#148f77",
    "#76448a",
    "#1f618d",
    "#5d6d7e",

    "#a9dfbf",
    "#d98880",
    "#e67e22",
    "#515a5a",
    "#283747",
];

export const defaultStyle = {
    transition: `opacity 200ms ease-in-out`,
    opacity: 0,
};

export const transitionStyles = {
    entering: { opacity: 1, height: "100%" },
    entered: { opacity: 1, height: "100%" },
    exiting: { opacity: 0, height: "100%" },
    exited: { opacity: 0, height: "1px" },
};

export const getFirstdayOfWeek = (day) => {
    return dayjs(day).startOf('isoweek');
};

export const getLastdayOfWeek = (day) => {
    return dayjs(day).endOf('isoweek').startOf('day');
}

// export const getFirstdayOfWeek = (day) => {
//     const firstDay = dayjs(day);
//     const noOfdaysFromPastMonth = firstDay.day() !== 0 
//         ? firstDay.day() - 1 : 6 - firstDay.day();
//     return firstDay.subtract(noOfdaysFromPastMonth, "days");
// };

// export const getLastdayOfWeek = (day) => {
//     const lastDay = dayjs(day);
//     const noOfdaysOfNextMonth = lastDay.day() !== 0 
//         ? 7 - lastDay.day() : 0;
//     return lastDay.add(noOfdaysOfNextMonth, "days");
// };

export const withAdjacentMonthsDays = (data) => {
    if (data) {
        const _data = Array.from(data.response);
        const firstDay = dayjs(_data[0].otb_check_in);
        const lastDay = dayjs(_data[_data.length - 1].otb_check_in);

        const noOfdaysFromPastMonth = firstDay.day() - 1;
        const noOfdaysNextMonth = lastDay.day() !== 0 ? 7 - lastDay.day() : 0;

        // add past month's days included in the 1st week of current month
        for (let i = 1; i <= noOfdaysFromPastMonth; i++) {
            const day = dayjs(firstDay).subtract(i, "days").format("YYYY-MM-DD");
            const pastMonthDays = { otb_check_in: day, status: "disabled" };
            _data.unshift(pastMonthDays);
        }
        // add next month's days included in the last week of current month
        for (let i = 1; i <= noOfdaysNextMonth; i++) {
            const day = dayjs(lastDay).add(i, "days").format("YYYY-MM-DD");
            const pastMonthDays = { otb_check_in: day, status: "disabled" };
            _data.push(pastMonthDays);
        }

        return _data;
    } else {
        return {};
    }
};

export const composeData = (data, getSegmentData) => {
    let dataFiltered = [];
    if (getSegmentData) {
        dataFiltered = data
            ? data.map((d) => ({
                ...getSegmentData(d),
            }))
            : [];
    } else {
        dataFiltered = data;
    }
    const dataComposed = dataFiltered
        ? dataFiltered.map((d) => ({
            ...d,
            dow: dayjs(d.otb_check_in).format("ddd"),

            pickup_revenue_computed: Math.round(d.otb_revenue - d.pickup_revenue),
            pickup_rooms_sold_computed: Math.round(
                d.otb_rooms_sold - d.pickup_rooms_sold
            ),
            pickup_adr:
                isNaN(
                    (d.otb_revenue - d.pickup_revenue) /
                    (d.otb_rooms_sold - d.pickup_rooms_sold)
                ) ||
                    !isFinite(
                        (d.otb_revenue - d.pickup_revenue) /
                        (d.otb_rooms_sold - d.pickup_rooms_sold)
                    )
                    ? 0
                    : Math.round(
                        (d.otb_revenue - d.pickup_revenue) /
                        (d.otb_rooms_sold - d.pickup_rooms_sold)
                    ),
            otb_revenue: Math.round(d.otb_revenue),
            otb_rooms_sold: Math.round(d.otb_rooms_sold),
            otb_adr: isNaN(d.otb_revenue / d.otb_rooms_sold)
                ? 0
                : Math.round(d.otb_revenue / d.otb_rooms_sold),
            variance_occ: Math.round(d.otb_rooms_sold - d.pace_rooms_sold),
            variance_revenue: Math.round(d.otb_revenue - d.pace_revenue),
            variance_adr: Math.round(
                d.otb_revenue / Math.round(d.otb_rooms_sold) -
                Math.round(d.pace_revenue) / Math.round(d.pace_rooms_sold)
            ),
            pace_otb_occ: Math.round(d.pace_finals_rooms_sold - d.pace_rooms_sold),
            pace_otb_revenue: Math.round(d.pace_finals_revenue - d.pace_revenue),
            pace_otb_adr: isNaN(
                (d.pace_finals_revenue - d.pace_revenue) /
                (d.pace_finals_rooms_sold - d.pace_rooms_sold)
            )
                ? "-"
                : Math.round(
                    (d.pace_finals_revenue - d.pace_revenue) /
                    (d.pace_finals_rooms_sold - d.pace_rooms_sold)
                ),
            finals_adr: Math.round(
                d.pace_finals_revenue / d.pace_finals_rooms_sold
            ),
            otb_pickup: Math.round(d.otb_sell_rate - d.pickup_sell_rate),
            otb_pace: Math.round(d.otb_sell_rate - d.pace_sell_rate),
            pace_finals_revenue: Math.round(d.pace_finals_revenue),
            pace_finals_rooms_sold: Math.round(d.pace_finals_rooms_sold),
            otb_sell_rate: Math.round(d.otb_sell_rate),
            for_100:
                d.days_out < 0
                    ? "-"
                    : d.days_out === 0
                        ? Math.round(d.total_inventory - d.otb_rooms_sold)
                        : Math.round((d.total_inventory - d.otb_rooms_sold) / d.days_out),
            total_inventory: d.total_inventory,
            year: dayjs(d.series).year().toString(),
            month: dayjs(d.series).format("MMMM"),
        }))
        : [];

    return dataComposed;
};

export const getTotals = (dataComposed) => {
    let total = {
        otb_rooms_sold: 0,
        otb_revenue: 0,
        otb_adr: 0,
        for_100: 0,
        pickup_rooms_sold_computed: 0,
        pickup_revenue_computed: 0,
        pickup_adr: 0,
        variance_occ: 0,
        variance_revenue: 0,
        variance_adr: 0,
        pace_revenue: 0,
        pace_rooms_sold: 0,
        pace_otb_occ: 0,
        pace_otb_revenue: 0,
        pace_otb_adr: 0,
        pace_finals_rooms_sold: 0,
        pace_finals_revenue: 0,
        finals_adr: 0,
        otb_sell_rate: 0,
        otb_pickup: 0,
        otb_pace: 0,
        otb_count: 0,
        total_inventory: 0,
        average_occ: 0,
        average_rev: 0,
        average_dow_rooms_sold: 0,
        average_dow_revenue: 0,
        year: "",
        month: "",
        forecast: 0,

        average_pickup: 0,
        average_dow_pickup: 0,

        variance_otb_rooms_sold_to_average_pace: 0,
        variance_otb_rooms_sold_to_average_dow_pace: 0,
        forecast_pickup: 0,
        forecast_pace: 0,
        forecast_sell_rate: 0,
        recommended_value: 0,
    };

    dataComposed.forEach((item, index) => {
        total.otb_rooms_sold += item.otb_rooms_sold;
        total.otb_revenue += item.otb_revenue;
        total.pickup_revenue_computed += item.pickup_revenue_computed;
        total.pickup_rooms_sold_computed += item.pickup_rooms_sold_computed;
        total.for_100 += isNaN(item.for_100) ? 0 : item.for_100;
        total.total_inventory += item.total_inventory;

        total.variance_occ += item.variance_occ;
        total.variance_revenue += item.variance_revenue;
        total.pace_revenue += parseFloat(item.pace_revenue);
        total.pace_rooms_sold += item.pace_rooms_sold;
        total.pace_otb_occ += item.pace_otb_occ;
        total.pace_otb_revenue += item.pace_otb_revenue;
        total.pace_finals_rooms_sold += item.pace_finals_rooms_sold;
        total.pace_finals_revenue += item.pace_finals_revenue;

        total.average_pickup += item.average_pickup;
        total.average_dow_pickup += item.average_dow_pickup;
        total.variance_otb_rooms_sold_to_average_pace +=
            item.variance_otb_rooms_sold_to_average_pace;
        total.variance_otb_rooms_sold_to_average_dow_pace +=
            item.variance_otb_rooms_sold_to_average_dow_pace;

        total.otb_sell_rate += item.otb_sell_rate;
        total.otb_pickup += item.otb_pickup;
        total.otb_pace += item.otb_pace;
        total.otb_count += item.otb_rooms_sold ? 1 : 0;

        total.year = dataComposed[0].year;
        total.month = item.month;
        total.forecast += parseFloat(item.forecast);

        total.forecast_pickup += item.forecast_pickup;
        total.forecast_pace += item.forecast_pace;
        total.forecast_sell_rate += item.forecast_sell_rate;

        total.average_rev += item.average_rev;
        total.average_occ += item.average_occ;

        total.average_dow_revenue += item.average_dow_revenue;
        total.average_dow_rooms_sold += item.average_dow_rooms_sold;
        total.recommended_value += item.recommended_value;
    });

    let forecast = total.forecast / dataComposed.length;
    total.forecast = forecast.toFixed(2);

    total.individualColor = {
        pick_up: "",
        pace: "",
        sell_rate: "",
    };
    total.individualColor.pick_up = getColor(
        total.forecast_pickup / dataComposed.length
    );
    total.individualColor.pace = getColor(
        total.forecast_pace / dataComposed.length
    );
    total.individualColor.sell_rate = getColor(
        total.forecast_sell_rate / dataComposed.length
    );

    total.color = getColor(forecast);

    const pickup_adr =
        total.pickup_revenue_computed / total.pickup_rooms_sold_computed;

    total.pickup_adr = Math.round(
        isNaN(pickup_adr) || !isFinite(pickup_adr) ? 0 : pickup_adr
    );

    const otb_adr = total.otb_revenue / total.otb_rooms_sold;

    total.otb_adr = Math.round(
        isNaN(otb_adr) || !isFinite(otb_adr) ? 0 : otb_adr
    );

    const variance_adr =
        total.otb_revenue / total.otb_rooms_sold -
        total.pace_revenue / total.pace_rooms_sold;

    total.variance_adr = Math.round(
        isNaN(variance_adr) || !isFinite(variance_adr) ? 0 : variance_adr
    );

    const variance_average_adr =
        total.otb_revenue / total.otb_rooms_sold -
        total.average_revenue / total.average_rooms_sold;

    total.variance_average_adr = Math.round(
        isNaN(variance_average_adr) || !isFinite(variance_average_adr)
            ? 0
            : variance_average_adr
    );

    const variance_average_dow_adr =
        total.otb_revenue / total.otb_rooms_sold -
        total.average_dow_revenue / total.average_dow_rooms_sold;

    total.variance_average_dow_adr = Math.round(
        isNaN(variance_average_dow_adr) || !isFinite(variance_average_dow_adr)
            ? 0
            : variance_average_dow_adr
    );

    const pace_otb_adr =
        (total.pace_finals_revenue - total.pace_revenue) /
        (total.pace_finals_rooms_sold - total.pace_rooms_sold);
    total.pace_otb_adr = Math.round(
        isNaN(pace_otb_adr) || !isFinite(pace_otb_adr) ? 0 : pace_otb_adr
    );

    const finals_adr = total.pace_finals_revenue / total.pace_finals_rooms_sold;
    total.finals_adr = Math.round(
        isNaN(finals_adr) || !isFinite(finals_adr) ? 0 : finals_adr
    );

    const average_sell_rate = total.otb_sell_rate / total.otb_count;
    total.average_sell_rate = Math.round(
        isNaN(average_sell_rate) || !isFinite(average_sell_rate)
            ? 0
            : average_sell_rate
    );

    const recommended_value = total.recommended_value / total.otb_count;
    total.recommended_value = Math.round(
        isNaN(recommended_value) || !isFinite(recommended_value)
            ? 0
            : recommended_value
    );

    const otb_pace = total.otb_pace / total.otb_count;
    total.otb_pace = Math.round(
        isNaN(otb_pace) || !isFinite(otb_pace) ? 0 : otb_pace
    );

    const otb_pickup = total.otb_pickup / total.otb_count;
    total.otb_pickup = Math.round(
        isNaN(otb_pickup) || !isFinite(otb_pickup) ? 0 : otb_pickup
    );

    return total;
};

export function getSameDayOfYear(date, yearsToAdd) {
    date = typeof date === "string" ? new Date(date) : date;
    let retDate = new Date(+date);
    retDate.setYear(retDate.getFullYear() + yearsToAdd);
    let diff = date.getDay() - retDate.getDay();
    retDate.setDate(retDate.getDate() + diff);
    if (yearsToAdd === -1) {
        return dayjs(date).subtract(52, "weeks").toDate();
    }
    return retDate;
}

export function getPrevYear(date, years = 1, isSDLY = false) {
    const newDate = dayjs(date);
    // dayjs default format = YYYY-MM-DDTHH:mm:ssZ
    // const toFormat = dayjs.defaultFormat.replace('Z', "") + newDate.format('Z');
    const toFormat = `YYYY-MM-DDTHH:mm:ss${newDate.format('Z')}`;

    if (isSDLY) return newDate.subtract(52 * years, 'weeks');
    // Reconstruct date units. This way we can check if the date created here really exists in calendar.
    // This is also the solution for leap year.
    // const units = ['Y', 'M', 'D', 'h', 'm', 's', 'ms'];
    const units = ['y', 'M', 'D', 'h', 'm', 's', 'ms'];
    const dateObj = {};
    units.forEach(unit => dateObj[unit] = newDate.get(unit));
    dateObj['y']-= years;

    const prevYear = dayjs(dayjs(dateObj).format(toFormat));
    return newDate.isUTC()? prevYear.utc(): prevYear;
}

export function getColor(value) {
    let weights = JSON.parse(localStorage.getItem("rms_weights_3")) || {
        red: [-1, -0.25],
        yellow: [-0.25, 0.5],
        gray: [0.5, 1.25],
        green: [1.25, 2],
    };

    if (value > weights.green[0] && value <= weights.green[1]) {
        return "green";
    } else if (value > weights.gray[0] && value <= weights.gray[1]) {
        return "gray";
    } else if (value > weights.yellow[0] && value <= weights.yellow[1]) {
        return "yellow";
    } else if (value >= weights.red[0] && value <= weights.red[1]) {
        return "red";
    }
}

export function getPace(paceType, date) {
    switch (paceType) {
        case "samedaylastweek":
            const lastWeek = dayjs(date).subtract(1, "weeks").toDate();
            return lastWeek;
        case "samedaylastmonth":
            const thisMonth = dayjs(date).toDate();
            let lastMonth = dayjs(thisMonth).subtract(4, "weeks").toDate();
            if (dayjs(lastMonth).month() === dayjs(thisMonth).month()) {
                lastMonth = dayjs(lastMonth).subtract(1, "weeks").toDate();
            }
            return lastMonth;
        case "lastyear":
            return getPrevYear(date);
        default:
            return getPrevYear(date, 1, true);
    }
}

export function getPaceForAggregatedWeekly(paceType, date) {
    let paceWeeklyStart;
    let paceWeeklyEnd;
    switch (paceType) {
        case "samedaylastweek":
            const sameDayLastWeek = dayjs(date).subtract(1, "weeks").toDate();
            paceWeeklyStart = dayjs(sameDayLastWeek);
            paceWeeklyEnd = dayjs(date).subtract(1, "days");
            return { start: paceWeeklyStart, end: paceWeeklyEnd };
        case "samedaylastmonth":
            const thisMonth = dayjs(date).toDate();
            let lastMonth = dayjs(thisMonth).subtract(4, "weeks").toDate();
            if (dayjs(lastMonth).month() === dayjs(thisMonth).month()) {
                lastMonth = dayjs(lastMonth).subtract(1, "weeks").toDate();
            }
            paceWeeklyStart = dayjs(lastMonth);
            paceWeeklyEnd = dayjs(paceWeeklyStart)
                .add(6, "days");
            return { start: paceWeeklyStart, end: paceWeeklyEnd };
        case "averageroomsold":
        case "averagedowroomsold":
            const _sameDayLastYear = getPrevYear(date, 1, true);
            paceWeeklyStart = dayjs(_sameDayLastYear);
            paceWeeklyEnd = dayjs(date).subtract(1, "days");
            return { start: paceWeeklyStart, end: paceWeeklyEnd };
        case "lastyear":
            const lastYear = getPrevYear(date);
            paceWeeklyStart = dayjs(lastYear);
            paceWeeklyEnd = dayjs(lastYear).add(6, "days");
            return { start: paceWeeklyStart, end: paceWeeklyEnd };
        default:
            const sameDayLastYear = getPrevYear(date, 1, true);
            paceWeeklyStart = dayjs(sameDayLastYear);
            paceWeeklyEnd = dayjs(sameDayLastYear)
                .add(6, "days");
            return { start: paceWeeklyStart, end: paceWeeklyEnd };
    }
}

export function getPaceForAggregatedMonthly(paceType, date) {
    let paceMonthlyStart;
    let paceMonthlyEnd;
    switch (paceType) {
        case "samedaylastweek":
            const sameDayLastWeek = dayjs(date).subtract(1, "weeks").toDate();
            paceMonthlyStart = dayjs(sameDayLastWeek).format("YYYY-MM-DD");
            paceMonthlyEnd = dayjs(date).subtract(1, "days").format("YYYY-MM-DD");
            return { start: paceMonthlyStart, end: paceMonthlyEnd };
        case "samedaylastmonth":
            const thisMonth = dayjs(date).toDate();
            let lastMonth = dayjs(thisMonth).subtract(4, "weeks").toDate();
            if (dayjs(lastMonth).month() === dayjs(thisMonth).month()) {
                lastMonth = dayjs(lastMonth).subtract(1, "weeks").toDate();
            }
            paceMonthlyStart = dayjs(lastMonth).format("YYYY-MM-DD");
            paceMonthlyEnd = dayjs(paceMonthlyStart)
                .add(6, "days")
                .format("YYYY-MM-DD");
            return { start: paceMonthlyStart, end: paceMonthlyEnd };
        case "averageroomsold":
        case "averagedowroomsold":
            const _sameDayLastYear = getPrevYear(date, 1, true);
            paceMonthlyStart = dayjs(_sameDayLastYear).format("YYYY-MM-DD");
            paceMonthlyEnd = dayjs(date).subtract(1, "days").format("YYYY-MM-DD");
            return { start: paceMonthlyStart, end: paceMonthlyEnd };
        case "lastyear":
            const lastYear = dayjs(date).subtract(1, "year");
            paceMonthlyStart = dayjs(lastYear).format("YYYY-MM-DD");
            paceMonthlyEnd = dayjs(lastYear).add(1, "month").format("YYYY-MM-DD");
            return { start: paceMonthlyStart, end: paceMonthlyEnd };
        default:
            const sameDayLastYear = getPrevYear(date, 1, true);
            paceMonthlyStart = dayjs(sameDayLastYear).format("YYYY-MM-DD");
            paceMonthlyEnd = dayjs(sameDayLastYear)
                .add(6, "days")
                .format("YYYY-MM-DD");
            return { start: paceMonthlyStart, end: paceMonthlyEnd };
    }
}

export function composeSortParams(order, field) {
    if (field == null) return "";
    let prefix;
    switch (order) {
        case "descend":
            prefix = "-";
            break;
        case "ascend":
            prefix = "";
            break;
        case false:
            return "";
        default:
            break;
    }
    return prefix + field;
}

export function createError(err, errorMessage) {
    let error = "Something went wrong!";
    if (err.response) error = err.response.data.message;

    ToastsStore.error(errorMessage || error);
    return error;
}


/**
 * Removes timezone from a date String
 *
 * @function noTimezone
 * @param {String} dateString A date string
 * @return {String} Date string without a timezone
*/
export function noTimezone(dateString) {
    return dateString.toString().split("T")[0];
}


/**
 * Converts a momentJS date day-of-week value to ISO 8601 standard day-of-week value
 * Note: https://www.timeanddate.com/calendar/days/
 *
 * @function getISOStandardDOW
 * @param {Date} date A date or a date string
 * @param {String} type If provided date value is not a date but already a DOW value
 * @return {Number} The date's ISO Standard 8601 day-of-week value
*/
export function getISOStandardDOW(date, type = 'date') {
    let momentDOW = (type === 'date') ? dayjs(date).day() : date;
    let isoStandardDOW = (momentDOW === 0) ? 7 : momentDOW;
    return isoStandardDOW;
}

/**
 * 
 * @param {[dayjs]} dates 
 * @returns {[Object]} An array of date ranges (object) composed of start_date and end_date fields.
 */
export function datesToRanges(dates) {
    if (!(Array.isArray(dates) && dates.length > 0)) return [];
    const arrDates = [...dates].sort((a, b) => dayjs.utc(a).diff(b, 'days'));
    const ranges = [{ start_date: arrDates[0], end_date: arrDates[0] }];
    // Skip first item, it is already added in ranges
    for(let index = 1; index < arrDates.length; index++) {
        const prevEndDate = dayjs(ranges[ranges.length -1].end_date).add(1, 'day');
        const currentDate = arrDates[index];
        if (prevEndDate.isSame(currentDate, 'day')) {
            ranges[ranges.length - 1].end_date = currentDate;
            continue;
        }
        ranges.push({ start_date: currentDate, end_date: currentDate });
    }

    return ranges;
}
/**
 * 
 * @param {[Object]} dateRanges An array of date ranges which are composed of start_date and end_date fields.
 * @returns {[dayjs]} An array of single dates.
 */
export function rangesToDates (dateRanges = []) {
    if (!Array.isArray(dateRanges)) return [];
    const dates = [];
    dateRanges.forEach(dateRange => {
        for(let currentDate = dayjs.utc(dateRange.start_date); currentDate.isSameOrBefore(dateRange.end_date, 'day'); currentDate.add(1, 'day')) {
            dates.push(dayjs(currentDate));
        }
    });
    return [...new Set(dates)];
}