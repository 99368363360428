import * as React from "react";

function SvgEdit(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path
        d="M35.74 82.8l-20 2.33a3.59 3.59 0 01-4-4l2.33-20L61.3 14a13.06 13.06 0 0118.47 0l3.16 3.16a13.08 13.08 0 010 18.47z"
        fill={props.fill? props.fill: "#67b489"}
        data-name="Layer 1"
      />
    </svg>
  );
}

export default SvgEdit;
