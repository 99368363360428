import React from "react";

export default function ErrorPage({ errorMessage }) {
  return (
    <div className="error-page container">
      <div className="row">
        <h2>{errorMessage}</h2>
      </div>
    </div>
  );
}
