/** @file Helper functions for the summaryController. */

import { color, colorScore, colorAvg, weightScore, percentDiff, percentAdjustment, percentValue } from "./algorithm";
import { priceRecoDefn, arrowQty, arrowDirection } from "./algorithm";
import { isNullish, arraySum, byDo, byDoDow, round } from "../data";



/**
 * Applies forecasting and recommendations to dailies
 *
 * @function withForecast
 * @param {Object} filter The filter to use.
 * @param {Object} dailies Daily dashboard data.
 * @return {Object} Daily dashboard data with forecasting and recommendations.
*/
export function withForecast(filter, dailies) {
    const weights = filter.forecast.weights;

    let dailiesQty = dailies.length;
    for (let index = 0; index < dailiesQty; index++) {
        const daily = dailies[index];
        const computed = daily._computed;
        const vs = computed.vs;
        const icolors = computed.icolors;
        const dout = daily.dout;
        const dowIndex = daily.dow - 1;
        let _forecast = {};


        // Compute Pickup Variable Scores
        let pick_ado = computed?.avgs?.pickupByDo ?? null;
        let pick_adow = computed?.avgs?.pickupByDoDow ?? null;

        let pick_vs_sdly_cscore = colorScore(vs.colors.pick_vs_sdly);
        let pick_vs_ado_cscore = colorScore(vs.colors.pick_vs_ado);
        let pick_vs_adow_cscore = colorScore(vs.colors.pick_vs_adow);

        let pick_vs_sdly_weight = weights.forecast.pickup.sdly;
        let pick_vs_ado_weight = weights.forecast.pickup.avg;
        let pick_vs_adow_weight = weights.forecast.pickup.avg_dow;

        let pick_vs_sdly_wscore = weightScore(pick_vs_sdly_weight, pick_vs_sdly_cscore);
        let pick_vs_ado_wscore = weightScore(pick_vs_ado_weight, pick_vs_ado_cscore);
        let pick_vs_adow_wscore = weightScore(pick_vs_adow_weight, pick_vs_adow_cscore);


        // Compute Pickup Instance Scores
        let pick_vs_sdly_i = weights.variables.instances.pickup.sdly;
        let pick_vs_ado_i = weights.variables.instances.pickup.avg;
        let pick_vs_adow_i = weights.variables.instances.pickup.avg_dow;

        let pick_vs_sdly_iavg = colorAvg(icolors.pick_vs_sdly, pick_vs_sdly_i);
        let pick_vs_ado_iavg = colorAvg(icolors.pick_vs_ado, pick_vs_ado_i);
        let pick_vs_adow_iavg = colorAvg(icolors.pick_vs_adow, pick_vs_adow_i);

        let pick_vs_sdly_icolor = color(pick_vs_sdly_iavg, weights.forecast.color);
        let pick_vs_ado_icolor = color(pick_vs_ado_iavg, weights.forecast.color);
        let pick_vs_adow_icolor = color(pick_vs_adow_iavg, weights.forecast.color);

        let pick_vs_sdly_icscore = colorScore(pick_vs_sdly_icolor);
        let pick_vs_ado_icscore = colorScore(pick_vs_ado_icolor);
        let pick_vs_adow_icscore = colorScore(pick_vs_adow_icolor);

        let pick_vs_sdly_iweight = weights.forecast.pickup.trend_sdly;
        let pick_vs_ado_iweight = weights.forecast.pickup.trend_avg;
        let pick_vs_adow_iweight = weights.forecast.pickup.trend_avg_dow;

        let pick_vs_sdly_iwscore = weightScore(pick_vs_sdly_iweight, pick_vs_sdly_icscore);
        let pick_vs_ado_iwscore = weightScore(pick_vs_ado_iweight, pick_vs_ado_icscore);
        let pick_vs_adow_iwscore = weightScore(pick_vs_adow_iweight, pick_vs_adow_icscore);


        // Compute Pickup Forecast
        let pickup_forecast_sum = round(arraySum([
            pick_vs_sdly_wscore,
            pick_vs_ado_wscore,
            pick_vs_adow_wscore,
            pick_vs_sdly_iwscore,
            pick_vs_ado_iwscore,
            pick_vs_adow_iwscore
        ]));
        let pickup_forecast_color = color(pickup_forecast_sum, weights.forecast.color);


        // Save Pickup Forecast Breakdown
        _forecast.pickup = {
            current: computed.pickup.occ_diff ?? null,
            forecast: {
                sum: pickup_forecast_sum,
                color: pickup_forecast_color
            },
            variables: {
                vs_sdly: {
                    value: vs.values.pick_vs_sdly,
                    value_diff: vs.diffs.pick_vs_sdly,
                    color: vs.colors.pick_vs_sdly,
                    color_score: pick_vs_sdly_cscore,
                    weight: vs.weights.pick_vs_sdly,
                    weight_score: pick_vs_sdly_wscore
                },
                vs_avg: {
                    value: round(pick_ado?.pickup),
                    value_diff: vs.diffs.pick_vs_ado,
                    color: vs.colors.pick_vs_ado,
                    color_score: pick_vs_ado_cscore,
                    weight: vs.weights.pick_vs_ado,
                    weight_score: pick_vs_ado_wscore
                },
                vs_avg_dow: {
                    value: round(pick_adow?.pickup),
                    value_diff: vs.diffs.pick_vs_adow,
                    color: vs.colors.pick_vs_adow,
                    color_score: pick_vs_adow_cscore,
                    weight: vs.weights.pick_vs_adow,
                    weight_score: pick_vs_adow_wscore
                },
            },
            instance: {
                vs_sdly: {
                    value: icolors.pick_vs_sdly,
                    no_instance: pick_vs_sdly_i,
                    average: round(pick_vs_sdly_iavg),
                    color: pick_vs_sdly_icolor,
                    color_weight: weights.forecast.color,
                    color_score: pick_vs_sdly_icscore,
                    weight: pick_vs_sdly_iweight,
                    weight_score: pick_vs_sdly_iwscore
                },
                vs_avg: {
                    value: icolors.pick_vs_ado,
                    no_instance: pick_vs_ado_i,
                    average: round(pick_vs_ado_iavg),
                    color: pick_vs_ado_icolor,
                    color_weight: weights.forecast.color,
                    color_score: pick_vs_ado_icscore,
                    weight: pick_vs_ado_iweight,
                    weight_score: pick_vs_ado_iwscore
                },
                vs_avg_dow: {
                    value: icolors.pick_vs_adow,
                    no_instance: pick_vs_adow_i,
                    average: round(pick_vs_adow_iavg),
                    color: pick_vs_adow_icolor,
                    color_weight: weights.forecast.color,
                    color_score: pick_vs_adow_icscore,
                    weight: pick_vs_adow_iweight,
                    weight_score: pick_vs_adow_iwscore
                },
            }
        }


        // Compute Pace Variable Scores
        let pace_vs_sdly_cscore = colorScore(vs.colors.pace_vs_sdly);
        let pace_vs_ado_cscore = colorScore(vs.colors.pace_vs_ado);
        let pace_vs_adow_cscore = colorScore(vs.colors.pace_vs_adow);

        let pace_vs_sdly_weight = weights.forecast.pace.sdly;
        let pace_vs_ado_weight = weights.forecast.pace.avg;
        let pace_vs_adow_weight = weights.forecast.pace.avg_dow;

        let pace_vs_sdly_wscore = weightScore(pace_vs_sdly_weight, pace_vs_sdly_cscore);
        let pace_vs_ado_wscore = weightScore(pace_vs_ado_weight, pace_vs_ado_cscore);
        let pace_vs_adow_wscore = weightScore(pace_vs_adow_weight, pace_vs_adow_cscore);


        // Compute Pace Instance Scores
        let pace_vs_sdly_ins = weights.variables.instances.pace.sdly;
        let pace_vs_ado_ins = weights.variables.instances.pace.avg;
        let pace_vs_adow_ins = weights.variables.instances.pace.avg_dow;

        let pace_vs_sdly_iavg = colorAvg(icolors.pace_vs_sdly, pace_vs_sdly_ins);
        let pace_vs_ado_iavg = colorAvg(icolors.pace_vs_ado, pace_vs_ado_ins);
        let pace_vs_adow_iavg = colorAvg(icolors.pace_vs_adow, pace_vs_adow_ins);

        let pace_vs_sdly_icolor = color(pace_vs_sdly_iavg, weights.forecast.color);
        let pace_vs_ado_icolor = color(pace_vs_ado_iavg, weights.forecast.color);
        let pace_vs_adow_icolor = color(pace_vs_adow_iavg, weights.forecast.color);

        let pace_vs_sdly_icscore = colorScore(pace_vs_sdly_icolor);
        let pace_vs_ado_icscore = colorScore(pace_vs_ado_icolor);
        let pace_vs_adow_icscore = colorScore(pace_vs_adow_icolor);

        let pace_vs_sdly_iweight = weights.forecast.pace.trend_sdly;
        let pace_vs_ado_iweight = weights.forecast.pace.trend_avg;
        let pace_vs_adow_iweight = weights.forecast.pace.trend_avg_dow;

        let pace_vs_sdly_iwscore = weightScore(pace_vs_sdly_iweight, pace_vs_sdly_icscore);
        let pace_vs_ado_iwscore = weightScore(pace_vs_ado_iweight, pace_vs_ado_icscore);
        let pace_vs_adow_iwscore = weightScore(pace_vs_adow_iweight, pace_vs_adow_icscore);


        // Compute Pace Forecast
        let pace_forecast_sum = round(arraySum([
            pace_vs_sdly_wscore,
            pace_vs_ado_wscore,
            pace_vs_adow_wscore,
            pace_vs_sdly_iwscore,
            pace_vs_ado_iwscore,
            pace_vs_adow_iwscore
        ]));
        let pace_forecast_color = color(pace_forecast_sum, weights.forecast.color);


        // Save Pace Forecast Breakdown
        _forecast.pace = {
            current: computed.data.occ,
            forecast: {
                sum: pace_forecast_sum,
                color: pace_forecast_color
            },
            variables: {
                vs_sdly: {
                    value: isNullish(vs.values.pace_vs_sdly) ? null : round(vs.values.pace_vs_sdly),
                    value_diff: vs.diffs.pace_vs_sdly,
                    color: vs.colors.pace_vs_sdly,
                    color_score: pace_vs_sdly_cscore,
                    weight: vs.weights.pace_vs_sdly,
                    weight_score: pace_vs_sdly_wscore
                },
                vs_avg: {
                    value: isNullish(vs.values.pace_vs_ado) ? null : round(vs.values.pace_vs_ado),
                    value_diff: vs.diffs.pace_vs_ado,
                    color: vs.colors.pace_vs_ado,
                    color_score: pace_vs_ado_cscore,
                    weight: vs.weights.pace_vs_ado,
                    weight_score: pace_vs_ado_wscore
                },
                vs_avg_dow: {
                    value: isNullish(vs.values.pace_vs_adow) ? null : round(vs.values.pace_vs_adow),
                    value_diff: vs.diffs.pace_vs_adow,
                    color: vs.colors.pace_vs_adow,
                    color_score: pace_vs_adow_cscore,
                    weight: vs.weights.pace_vs_adow,
                    weight_score: pace_vs_adow_wscore
                },
            },
            instance: {
                vs_sdly: {
                    value: icolors.pace_vs_sdly,
                    no_instance: pace_vs_sdly_ins,
                    average: round(pace_vs_sdly_iavg),
                    color: pace_vs_sdly_icolor,
                    color_weight: weights.forecast.color,
                    color_score: pace_vs_sdly_icscore,
                    weight: pace_vs_sdly_iweight,
                    weight_score: pace_vs_sdly_iwscore
                },
                vs_avg: {
                    value: icolors.pace_vs_ado,
                    no_instance: pace_vs_ado_ins,
                    average: round(pace_vs_ado_iavg),
                    color: pace_vs_ado_icolor,
                    color_weight: weights.forecast.color,
                    color_score: pace_vs_ado_icscore,
                    weight: pace_vs_ado_iweight,
                    weight_score: pace_vs_ado_iwscore
                },
                vs_avg_dow: {
                    value: icolors.pace_vs_adow,
                    no_instance: pace_vs_adow_ins,
                    average: round(pace_vs_adow_iavg),
                    color: pace_vs_adow_icolor,
                    color_weight: weights.forecast.color,
                    color_score: pace_vs_adow_icscore,
                    weight: pace_vs_adow_iweight,
                    weight_score: pace_vs_adow_iwscore
                },
            }
        }


        // Compute Demand Forecast (Based on Pickup Forecast and Pace Forecast)
        let pickup_forecast_weight = weights.forecast.breakdown.pickup;
        let pace_forecast_weight = weights.forecast.breakdown.pace;

        let pickup_forecast_wscore = weightScore(pickup_forecast_weight, pickup_forecast_sum);
        let pace_forecast_wscore = weightScore(pace_forecast_weight, pace_forecast_sum);

        let demand_forecast_sum = round(arraySum([pickup_forecast_wscore, pace_forecast_wscore]));
        let demand_forecast_color = color(demand_forecast_sum, weights.forecast.color);


        // Save Demand Forecast Breakdown
        _forecast.demand = {
            forecast: {
                sum: demand_forecast_sum,
                color: demand_forecast_color
            },
            breakdown: {
                pickup: {
                    score: pickup_forecast_sum,
                    color: pickup_forecast_color,
                    weight: pickup_forecast_weight,
                    weight_score: pickup_forecast_wscore
                },
                pace: {
                    score: pace_forecast_sum,
                    color: pace_forecast_color,
                    weight: pace_forecast_weight,
                    weight_score: pace_forecast_wscore
                }
            }
        }


        // Compute Price Variable Scores
        let price_weight_sdly = weights.forecast.price.sdly;
        let price_weight_ado = weights.forecast.price.avg;
        let price_weight_adow = weights.forecast.price.avg_dow;

        let price_ado = computed?.avgs?.priceByDo ?? null;
        let price_adow = computed?.avgs?.priceByDoDow ?? null;

        let price_wscore_sdly = weightScore(price_weight_sdly, computed.pace.rate);
        let price_wscore_ado = weightScore(price_weight_ado, round(price_ado?.avg_rate));
        let price_wscore_adow = weightScore(price_weight_adow, round(price_adow?.avg_rate));

        let price_weight_rsdly = weights.forecast.price.remaindays_sdly;
        let price_weight_ravg = weights.forecast.price.remaindays_avg;
        let price_weight_ravg_dow = weights.forecast.price.remaindays_avg_dow;

        let price_rsdly = computed?.remaindays?.price?.sdly ?? null;
        let price_ravg = computed?.remaindays?.price?.avg ?? null;
        let price_ravg_dow = computed?.remaindays?.price?.avg_dow ?? null;

        let price_wscore_rsdly = weightScore(price_weight_rsdly, price_rsdly);
        let price_wscore_rado = weightScore(price_weight_ravg, price_ravg);
        let price_wscore_radow = weightScore(price_weight_ravg_dow, price_ravg_dow);


        // Compute Price Forecast
        let price_forecast_sum = round(arraySum([
            price_wscore_sdly,
            price_wscore_ado,
            price_wscore_adow,
            price_wscore_rsdly,
            price_wscore_rado,
            price_wscore_radow,
        ]));
        let price_forecast_diff = round(percentDiff(computed.data.bar, price_forecast_sum), true);
        let price_forecast_color = color(price_forecast_diff, weights.variables.colors.price);
        let price_forecast_score = colorScore(price_forecast_color);


        // Save Price Forecast Breakdown
        _forecast.price = {
            current: computed.data.bar,
            currency: filter.currency,
            forecast: {
                sum: price_forecast_sum,
                score: price_forecast_score,
                percent_diff: price_forecast_diff,
                color: price_forecast_color
            },
            breakdown: {
                sdly: {
                    price: computed.pace.rate,
                    weight: price_weight_sdly,
                    weight_score: price_wscore_sdly
                },
                avg: {
                    price: round(price_ado?.avg_rate),
                    weight: price_weight_ado,
                    weight_score: price_wscore_ado
                },
                avg_dow: {
                    price: round(price_adow?.avg_rate),
                    weight: price_weight_adow,
                    weight_score: price_wscore_adow
                },
                remaindays_sdly: {
                    price: round(price_rsdly),
                    weight: price_weight_rsdly,
                    weight_score: price_wscore_rsdly
                },
                remaindays_avg: {
                    price: round(price_ravg),
                    weight: price_weight_ravg,
                    weight_score: price_wscore_rado
                },
                remaindays_avg_dow: {
                    price: round(price_ravg_dow),
                    weight: price_weight_ravg_dow,
                    weight_score: price_wscore_radow
                },
            }
        }


        // Compute Finals Variable Scores
        let finals_ado = byDo((computed?._breakdown?.avgFinalsByDo ?? [])[0] ?? [], -1);
        let finals_adow = byDoDow(computed?._breakdown?.avgFinalsByDoDow ?? [], dowIndex, -1);
        let finals_avg = round(finals_ado?.avg_occ);
        let finals_avg_dow = round(finals_adow?.avg_occ);

        let finals_vs_sdly = percentValue(computed?.sdly?.occFinals, computed.data.inv);
        let finals_vs_ado = percentValue(finals_avg, computed.data.inv);
        let finals_vs_adow = percentValue(finals_avg_dow, computed.data.inv);

        let finals_weight_sdly = weights.variables.colors.finals.sdly;
        let finals_weight_ado = weights.variables.colors.finals.avg;
        let finals_weight_adow = weights.variables.colors.finals.avg_dow;

        let finals_vs_sdly_color = color(finals_vs_sdly, finals_weight_sdly);
        let finals_vs_ado_color = color(finals_vs_ado, finals_weight_ado);
        let finals_vs_adow_color = color(finals_vs_adow, finals_weight_adow);

        let finals_vs_sdly_cscore = colorScore(finals_vs_sdly_color);
        let finals_vs_ado_cscore = colorScore(finals_vs_ado_color);
        let finals_vs_adow_cscore = colorScore(finals_vs_adow_color);

        let finals_vs_sdly_weight = weights.forecast.finals.sdly;
        let finals_vs_ado_weight = weights.forecast.finals.avg;
        let finals_vs_adow_weight = weights.forecast.finals.avg_dow;

        let finals_vs_sdly_wscore = weightScore(finals_vs_sdly_weight, finals_vs_sdly_cscore);
        let finals_vs_ado_wscore = weightScore(finals_vs_ado_weight, finals_vs_ado_cscore);
        let finals_vs_adow_wscore = weightScore(finals_vs_adow_weight, finals_vs_adow_cscore);


        // Compute Finals Forecast
        let finals_forecast_sum = round(arraySum([
            finals_vs_sdly_wscore,
            finals_vs_ado_wscore,
            finals_vs_adow_wscore
        ]));
        let finals_forecast_color = color(finals_forecast_sum, weights.forecast.color);


        // Save Finals Forecast Breakdown
        _forecast.finals = {
            inventory: computed.data.inv,
            forecast: {
                sum: finals_forecast_sum,
                color: finals_forecast_color
            },
            variables: {
                vs_sdly: {
                    value: computed?.sdly?.occFinals,
                    value_diff: finals_vs_sdly,
                    color: finals_vs_sdly_color,
                    color_score: finals_vs_sdly_cscore,
                    weight: finals_vs_sdly_weight,
                    weight_score: finals_vs_sdly_wscore
                },
                vs_avg: {
                    value: finals_avg,
                    value_diff: finals_vs_ado,
                    color: finals_vs_ado_color,
                    color_score: finals_vs_ado_cscore,
                    weight: finals_vs_ado_weight,
                    weight_score: finals_vs_ado_wscore
                },
                vs_avg_dow: {
                    value: finals_avg_dow,
                    value_diff: finals_vs_adow,
                    color: finals_vs_adow_color,
                    color_score: finals_vs_adow_cscore,
                    weight: finals_vs_adow_weight,
                    weight_score: finals_vs_adow_wscore
                },
            },
        }


        // Compute Price Recommendation
        let price_reco_definition = priceRecoDefn(demand_forecast_color, price_forecast_color, finals_forecast_color);
        let color_comparison = weights.variables.colors.price;
        let percent_adjustment = percentAdjustment(price_reco_definition, color_comparison);

        let compare_price = price_forecast_sum;
        let compare_price_adjusted = (isNullish(compare_price) || isNullish(percent_adjustment))
            ? null
            : compare_price * (1 + percent_adjustment);
        let price_vs_comparison = (isNullish(compare_price_adjusted) || isNullish(computed.data.bar))
            ? null
            : ((compare_price_adjusted - computed.data.bar) / computed.data.bar) * 100;
        let price_reco_percent = round(price_vs_comparison);

        let arrow_weights = weights.variables.price_reco_arrows;
        let arrow_quantity = arrowQty(price_reco_percent, arrow_weights, price_reco_definition);
        let arrow_direction = arrowDirection(price_reco_percent, arrow_weights, price_reco_definition);


        // Save Price Recommendation Breakdown
        _forecast.price_recommendation = {
            current: computed.data.bar,
            currency: filter.currency,
            recommendation: {
                percent: price_reco_percent,
                definition: price_reco_definition,
                arrow_quantity: arrow_quantity,
                arrow_direction: arrow_direction
            },
            breakdown: {
                adjustment: percent_adjustment,
                comparison: compare_price,
                comparison_diff: price_vs_comparison,
                colors: {
                    demand: demand_forecast_color,
                    price: price_forecast_color,
                    finals: finals_forecast_color,
                }
            },
        }


        // Save Item Colors For Dashboard/Sidebar Display
        _forecast.colors = {
            pickup: pickup_forecast_color,
            pace: pace_forecast_color,
            demand: demand_forecast_color,
            price: price_forecast_color,
        }


        daily._forecast = _forecast;


        // Convert to V1
        const forecast = daily._forecast;
        daily.algorithm_breakdown = forecast;
        daily.forecast_pickup = forecast?.pickup?.forecast?.sum;
        daily.forecast_pace = forecast?.pace?.forecast?.sum;
        daily.forecast = forecast?.demand?.forecast?.sum;
        daily.forecast_sell_rate = forecast?.price?.forecast?.sum;
        daily.color = forecast?.colors?.demand;
        daily.sell_rate_color = forecast?.colors?.price;
        daily.individualColor = {
            pick_up: forecast?.colors?.pickup,
            pace: forecast?.colors?.pace,
            sell_rate: forecast?.colors?.price
        };
        daily.recommended_value = forecast?.price_recommendation?.recommendation?.arrow_quantity;
        daily.recommended_action = forecast?.price_recommendation?.recommendation?.definition;
    }

    return dailies;
};



/**
 * Compute VS pickup and VS pace information for a specific check-in date
 *
 * @function vs
 * @param {Object} filter The filter to use.
 * @param {Object} pickups Pickups data object.
 * @param {Object} variants Variants data object.
 * @param {Object} avgs Averages data object.
 * @param {Object} pace Pace info for the specific check-in date.
 * @param {Object} sdly SDLY info for the specific check-in date.
 * @param {Date} cin Check-in date.
 * @param {Date} dout Days-out.
 * @param {Date} dow Days-of-week.
 * @param {Number} occ OTB Occupancy.
 * @return {Object} VS pickup and VS pace info for a specific check-in date.
*/
export function vs(filter, { pickups, variants, avgs, pace, sdly, cin, dout, dow, occ }) {
    // Variant Pickup Data
    const vPickup = (isNullish(variants?.pickups) ? pickups : variants.pickups).find(p => p.cin === cin);
    const vPickupOccDiff = vPickup ? occ - vPickup.o : null;

    // VS Pickup
    const weights = filter.forecast.weights;
    const iAvgs = filter.iAvgs;
    const iAvgPickupByDo = isNullish(iAvgs)
        ? byDo((avgs.avgPickupByDo ?? [])[0], dout)
        : byDo(iAvgs.pickupByDo, dout);
    const iAvgPickupByDoDow = isNullish(iAvgs)
        ? byDoDow(avgs.avgPickupByDoDow, dow - 1, dout)
        : byDoDow(iAvgs.pickupByDoDow, dow, dout);
    const iAvgPickupByDoPickup = isNullish(iAvgPickupByDo) ? null : round(iAvgPickupByDo.pickup);
    const iAvgPickupByDoDowPickup = isNullish(iAvgPickupByDoDow) ? null : round(iAvgPickupByDoDow.pickup);

    let pick_vs_sdly = round(percentDiff(vPickupOccDiff, sdly.pickup), true);
    let pick_vs_ado = round(percentDiff(vPickupOccDiff, iAvgPickupByDoPickup), true);
    let pick_vs_adow = round(percentDiff(vPickupOccDiff, iAvgPickupByDoDowPickup), true);

    let pick_sdly_days = weights.variables.colors.pickup.sdly.days;
    let pick_ado_days = weights.variables.colors.pickup.avg.days;
    let pick_adow_days = weights.variables.colors.pickup.avg_dow.days;

    let pick_vs_sdly_color = color(pick_vs_sdly, pick_sdly_days);
    let pick_vs_ado_color = color(pick_vs_ado, pick_ado_days);
    let pick_vs_adow_color = color(pick_vs_adow, pick_adow_days);

    // VS Pace
    const iAvgPaceByDo = isNullish(iAvgs)
        ? byDo((avgs.avgPaceByDo ?? [])[0], dout)
        : byDo(iAvgs.paceByDo, dout);
    const iAvgPaceByDoDow = isNullish(iAvgs)
        ? byDoDow(avgs.avgPaceByDoDow, dow - 1, dout)
        : byDoDow(iAvgs.paceByDoDow, dow, dout);
    const iAvgPaceByDoAvgOcc = isNullish(iAvgPaceByDo) ? null : round(iAvgPaceByDo.avg_occ);
    const iAvgPaceByDoDowAvgOcc = isNullish(iAvgPaceByDoDow) ? null : round(iAvgPaceByDoDow.avg_occ);

    let pace_vs_sdly = round(percentDiff(occ, pace.occ), true);
    let pace_vs_ado = round(percentDiff(occ, iAvgPaceByDoAvgOcc), true);
    let pace_vs_adow = round(percentDiff(occ, iAvgPaceByDoDowAvgOcc), true);

    let pace_sdly_days = weights.variables.colors.pace.sdly.days;
    let pace_ado_days = weights.variables.colors.pace.avg.days;
    let pace_adow_days = weights.variables.colors.pace.avg_dow.days;

    let pace_vs_sdly_color = color(pace_vs_sdly, pace_sdly_days);
    let pace_vs_ado_color = color(pace_vs_ado, pace_ado_days);
    let pace_vs_adow_color = color(pace_vs_adow, pace_adow_days);


    return {
        values: {
            pick_vs_sdly: sdly.pickup,
            pick_vs_ado: iAvgPickupByDoPickup,
            pick_vs_adow: iAvgPickupByDoDowPickup,
            pace_vs_sdly: pace.occ,
            pace_vs_ado: iAvgPaceByDoAvgOcc,
            pace_vs_adow: iAvgPaceByDoDowAvgOcc,
        },
        diffs: {
            pick_vs_sdly: pick_vs_sdly,
            pick_vs_ado: pick_vs_ado,
            pick_vs_adow: pick_vs_adow,
            pace_vs_sdly: pace_vs_sdly,
            pace_vs_ado: pace_vs_ado,
            pace_vs_adow: pace_vs_adow,
        },
        weights: {
            pick_vs_sdly: pick_sdly_days,
            pick_vs_ado: pick_ado_days,
            pick_vs_adow: pick_adow_days,
            pace_vs_sdly: pace_sdly_days,
            pace_vs_ado: pace_ado_days,
            pace_vs_adow: pace_adow_days,
        },
        colors: {
            pick_vs_sdly: pick_vs_sdly_color,
            pick_vs_ado: pick_vs_ado_color,
            pick_vs_adow: pick_vs_adow_color,
            pace_vs_sdly: pace_vs_sdly_color,
            pace_vs_ado: pace_vs_ado_color,
            pace_vs_adow: pace_vs_adow_color,
        }
    }
}



/**
 * Compute the average price from a given data set with days out that is less or equal than the given
 *
 * @function avgPriceByRemainingDo
 * @param {Object} type Data set type. Either sdly, ado, or adow.
 * @param {Object} data Data set.
 * @param {Object} dout Days out.
 * @return {Number} Average price.
*/
export function avgPriceByRemainingDo(type, data, dout) {
    const key = (type === "ado") ? "_id" : (type === "adow") ? "do" : "dout";

    let prices = [];
    Array.isArray(data)
        ? data.filter(_ => _[key] <= dout).forEach(_ => prices.push((type === "sdly") ? _.bar : _.avg_rate))
        : prices.push((type === "sdly") ? data.bar : data.avg_rate);

    let sumPrices = arraySum(prices);
    return (!isNullish(sumPrices) && prices.length > 0) ? sumPrices / prices.length : null;
}