import React, { useState } from 'react';
import ListHeader from 'elements/Text/ListHeader';
import IconButton from 'elements/Button/Icon';
import Switch from 'elements/Switch/Basic';
import CustomDateRangesModal from 'components/Modal/CustomDateRangesModal';
import constants from 'constants.js';

const CustomDashboardViewList = ({ defaultViewOption = {}, customDates = [], user, onDefaultViewEdit, onCustomDatesEdit }) => {
    const isAdmin = user.type === 'ope_admin';
    const isHotelUser = user.type === 'hotel_user';
    const hasUpdateAccess = (isHotelUser || isAdmin);

    const { showDateRangesModal, hideDateRangesModal, isDateRangesModalVisible, dateRanges } = useDateRangesModal();
    const [selectedViewOption, setSelectedViewOption] = useState(defaultViewOption.code && defaultViewOption.value
        ? defaultViewOption: constants.CUSTOM_DASHBOARD_VIEW_OPTIONS[0]
    );

    return (
        <div>
            <ListHeader headerCopy='CUSTOM' />

            <table className='forms-table'>
                <thead>
                    <tr style={{ marginBottom: '8px' }}>
                        <th style={{ maxWidth: '300px' }}>Label</th>
                        <th colSpan={2} style={{ maxWidth: '80px', textAlign: 'right' }}>
                            Actions
                        </th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {constants.CUSTOM_DASHBOARD_VIEW_OPTIONS.map((option, index) => {
                        const isSelected = (() => {
                            if (option.code === constants.CDV_CUSTOM_DATE_SELECTION && selectedViewOption.code === constants.CDV_CUSTOM_DATE_SELECTION) 
                                return true;
                            if (option.code === selectedViewOption.code && option.value === selectedViewOption.value) 
                                return true;
                            
                            return false;
                        })();
                        return ( 
                            <tr key={`view-option-${index}`}>
                                <td style={{ maxWidth: '300px' }}>{option.label}</td>
                                <td style={{ display: 'flex', maxWidth: '50px', justifyContent: 'center' }}>
                                    <Switch checked={isSelected} type={'100%'} action={() => {
                                        if (option.code !== selectedViewOption.code || option.value !== selectedViewOption.value) {
                                            setSelectedViewOption(option);
                                            onDefaultViewEdit(option.code, option.value);
                                        }
                                    }}/>
                                </td>
                                <td style={{ display: 'flex', maxWidth: '30px' }}>{option.code === constants.CDV_CUSTOM_DATE_SELECTION && 
                                    <IconButton type='edit' hasAccess={hasUpdateAccess} action={() => showDateRangesModal() }/>
                                }</td>
                                <td />
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <CustomDateRangesModal 
                headerCopy={'Custom Selected Dates'}
                isVisible={isDateRangesModalVisible} 
                items={customDates}
                onCancel={() => hideDateRangesModal() } 
                width={484}
                onSubmit={(dates) => {
                    onCustomDatesEdit(dates).then(() => {
                        hideDateRangesModal();
                    });
                }}
            />
        </div>
    )
};

const useDateRangesModal = (items = []) => {
    const [dateRangesModal, setDateRangesModal] = useState({ visible: false, items: items });

    const showDateRangesModal = () => setDateRangesModal(prev => ({ ...prev, visible: true }));
    const hideDateRangesModal = () => setDateRangesModal(prev => ({ ...prev, visible: false }));
    const setDateRanges = (newItems) => setDateRangesModal(prev => ({ ...prev, items: newItems }));
    
    return {
        setDateRanges,
        showDateRangesModal,
        hideDateRangesModal,
        dateRanges: dateRangesModal.items,
        isDateRangesModalVisible: dateRangesModal.visible,
    }
}

export default CustomDashboardViewList;
