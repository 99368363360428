import constants from "../constants";
// import moment from "moment";
import dayjs from "dayjs";
import { isNullOrUndefined, toInt, withCurrency } from "./index";
import { round } from "./data";

/**
 * Default Visual Asofs Data
 *
 * @return {Object} Default visual asofs data
*/
export function defaultVisualsAsofData() {
    return {
        days_out: [],
        pickup_rev: {
            days_out: [],
            line: [],
            table: {
                otb_rev: {
                    name: '{date}',
                    color: null,
                    data: []
                },
                ly_rev: {
                    name: 'LY',
                    color: null,
                    data: []
                },
                otb_vs_ly_rev: {
                    name: 'vs LY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                sdly_rev: {
                    name: 'SDLY',
                    color: null,
                    data: []
                },
                otb_vs_sdly_rev: {
                    name: 'vs SDLY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                ave_rev: {
                    name: 'AVG',
                    color: null,
                    data: []
                },
                otb_vs_ave_rev: {
                    name: 'vs AVG',
                    color: 'rgba(40, 47, 91, 0.3)',
                    data: []
                },
                ave_dow_rev: {
                    name: 'AVG DOW',
                    color: null,
                    data: []
                },
                otb_vs_ave_dow_rev: {
                    name: 'vs AVG DOW',
                    color: 'rgba(128, 128, 128, 0.3)',
                    data: []
                },
                cmp_rev: {
                    name: '{comparison}',
                    color: null,
                    data: []
                },
                otb_vs_cmp_rev: {
                    name: 'vs {comparison}',
                    color: 'rgba(237, 71, 75, 0.3)',
                    data: []
                },
            }
        },
        pickup_occ: {
            days_out: [],
            line: [],
            table: {
                otb_occ: {
                    name: '{date}',
                    color: null,
                    data: []
                },
                ly_occ: {
                    name: 'LY',
                    color: null,
                    data: []
                },
                otb_vs_ly_occ: {
                    name: 'vs LY',
                    color: 'rgba(238, 148, 33, 0.3)',
                    data: []
                },
                sdly_occ: {
                    name: 'SDLY',
                    color: null,
                    data: []
                },
                otb_vs_sdly_occ: {
                    name: 'vs SDLY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                ave_occ: {
                    name: 'AVG',
                    color: null,
                    data: []
                },
                otb_vs_ave_occ: {
                    name: 'vs AVG',
                    color: 'rgba(40, 47, 91, 0.3)',
                    data: []
                },
                ave_dow_occ: {
                    name: 'AVG DOW',
                    color: null,
                    data: []
                },
                otb_vs_ave_dow_occ: {
                    name: 'vs AVG DOW',
                    color: 'rgba(128, 128, 128, 0.3)',
                    data: []
                },
                cmp_occ: {
                    name: '{comparison}',
                    color: null,
                    data: []
                },
                otb_vs_cmp_occ: {
                    name: 'vs {comparison}',
                    color: 'rgba(237, 71, 75, 0.3)',
                    data: []
                },
            }
        },
        pickup_adr: {
            days_out: [],
            line: [],
            table: {
                otb_adr: {
                    name: '{date}',
                    color: null,
                    data: []
                },
                ly_adr: {
                    name: 'LY',
                    color: null,
                    data: []
                },
                otb_vs_ly_adr: {
                    name: 'vs LY',
                    color: 'rgba(238, 148, 33, 0.3)',
                    data: []
                },
                sdly_adr: {
                    name: 'SDLY',
                    color: null,
                    data: []
                },
                otb_vs_sdly_adr: {
                    name: 'vs SDLY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                ave_adr: {
                    name: 'AVG',
                    color: null,
                    data: []
                },
                otb_vs_ave_adr: {
                    name: 'vs AVG',
                    color: 'rgba(40, 47, 91, 0.3)',
                    data: []
                },
                ave_dow_adr: {
                    name: 'AVG DOW',
                    color: null,
                    data: []
                },
                otb_vs_ave_dow_adr: {
                    name: 'vs AVG DOW',
                    color: 'rgba(128, 128, 128, 0.3)',
                    data: []
                },
                cmp_adr: {
                    name: '{comparison}',
                    color: null,
                    data: []
                },
                otb_vs_cmp_adr: {
                    name: 'vs {comparison}',
                    color: 'rgba(237, 71, 75, 0.3)',
                    data: []
                },
            }
        },
        pace_rev: {
            days_out: [],
            line: [],
            table: {
                otb_rev: {
                    name: '{date}',
                    color: null,
                    data: []
                },
                ly_rev: {
                    name: 'LY',
                    color: null,
                    data: []
                },
                otb_vs_ly_rev: {
                    name: 'vs LY',
                    color: 'rgba(238, 148, 33, 0.3)',
                    data: []
                },
                sdly_rev: {
                    name: 'SDLY',
                    color: null,
                    data: []
                },
                otb_vs_sdly_rev: {
                    name: 'vs SDLY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                ave_rev: {
                    name: 'AVG',
                    color: null,
                    data: []
                },
                otb_vs_ave_rev: {
                    name: 'vs AVG',
                    color: 'rgba(40, 47, 91, 0.3)',
                    data: []
                },
                ave_dow_rev: {
                    name: 'AVG DOW',
                    color: null,
                    data: []
                },
                otb_vs_ave_dow_rev: {
                    name: 'vs AVG DOW',
                    color: 'rgba(128, 128, 128, 0.3)',
                    data: []
                },
                cmp_rev: {
                    name: '{comparison}',
                    color: null,
                    data: []
                },
                otb_vs_cmp_rev: {
                    name: 'vs {comparison}',
                    color: 'rgba(237, 71, 75, 0.3)',
                    data: []
                },
            }
        },
        pace_occ: {
            days_out: [],
            line: [],
            table: {
                otb_occ: {
                    name: '{date}',
                    color: null,
                    data: []
                },
                ly_occ: {
                    name: 'LY',
                    color: null,
                    data: []
                },
                otb_vs_ly_occ: {
                    name: 'vs LY',
                    color: 'rgba(238, 148, 33, 0.3)',
                    data: []
                },
                sdly_occ: {
                    name: 'SDLY',
                    color: null,
                    data: []
                },
                otb_vs_sdly_occ: {
                    name: 'vs SDLY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                ave_occ: {
                    name: 'AVG',
                    color: null,
                    data: []
                },
                otb_vs_ave_occ: {
                    name: 'vs AVG',
                    color: 'rgba(40, 47, 91, 0.3)',
                    data: []
                },
                ave_dow_occ: {
                    name: 'AVG DOW',
                    color: null,
                    data: []
                },
                otb_vs_ave_dow_occ: {
                    name: 'vs AVG DOW',
                    color: 'rgba(128, 128, 128, 0.3)',
                    data: []
                },
                cmp_occ: {
                    name: '{comparison}',
                    color: null,
                    data: []
                },
                otb_vs_cmp_occ: {
                    name: 'vs {comparison}',
                    color: 'rgba(237, 71, 75, 0.3)',
                    data: []
                },
            }
        },
        pace_adr: {
            days_out: [],
            line: [],
            table: {
                otb_adr: {
                    name: '{date}',
                    color: null,
                    data: []
                },
                ly_adr: {
                    name: 'LY',
                    color: null,
                    data: []
                },
                otb_vs_ly_adr: {
                    name: 'vs LY',
                    color: 'rgba(238, 148, 33, 0.3)',
                    data: []
                },
                sdly_adr: {
                    name: 'SDLY',
                    color: null,
                    data: []
                },
                otb_vs_sdly_adr: {
                    name: 'vs SDLY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                ave_adr: {
                    name: 'AVG',
                    color: null,
                    data: []
                },
                otb_vs_ave_adr: {
                    name: 'vs AVG',
                    color: 'rgba(40, 47, 91, 0.3)',
                    data: []
                },
                ave_dow_adr: {
                    name: 'AVG DOW',
                    color: null,
                    data: []
                },
                otb_vs_ave_dow_adr: {
                    name: 'vs AVG DOW',
                    color: 'rgba(128, 128, 128, 0.3)',
                    data: []
                },
                cmp_adr: {
                    name: '{comparison}',
                    color: null,
                    data: []
                },
                otb_vs_cmp_adr: {
                    name: 'vs {comparison}',
                    color: 'rgba(237, 71, 75, 0.3)',
                    data: []
                },
            }
        },
        rate: {
            days_out: [],
            line: [],
            table: {
                otb_rate: {
                    name: '{date}',
                    color: null,
                    data: []
                },
                ly_rate: {
                    name: 'LY',
                    color: null,
                    data: []
                },
                otb_vs_ly_rate: {
                    name: 'vs LY',
                    color: 'rgba(238, 148, 33, 0.3)',
                    data: []
                },
                sdly_rate: {
                    name: 'SDLY',
                    color: null,
                    data: []
                },
                otb_vs_sdly_rate: {
                    name: 'vs SDLY',
                    color: 'rgba(76, 188, 144, 0.3)',
                    data: []
                },
                ave_rate: {
                    name: 'AVG',
                    color: null,
                    data: []
                },
                otb_vs_ave_rate: {
                    name: 'vs AVG',
                    color: 'rgba(40, 47, 91, 0.3)',
                    data: []
                },
                ave_dow_rate: {
                    name: 'AVG DOW',
                    color: null,
                    data: []
                },
                otb_vs_ave_dow_rate: {
                    name: 'vs AVG DOW',
                    color: 'rgba(128, 128, 128, 0.3)',
                    data: []
                },
                cmp_rate: {
                    name: '{comparison}',
                    color: null,
                    data: []
                },
                otb_vs_cmp_rate: {
                    name: 'vs {comparison}',
                    color: 'rgba(237, 71, 75, 0.3)',
                    data: []
                },
            }
        },
        compricing: {
            days_out: [],
            line_columns: [],
            line_colors: [
                "#4CBC90", 
                "#EE9421", 
                "#808080", 
                "#f23c3c",
                "#282F5B",
                '#8810ff',
            ],
            line: [],
            table_colors: [
                'rgba(76, 188, 144, 0.3)',
                'rgba(238, 148, 33, 0.3)',
                'rgba(40, 47, 91, 0.3)',
                'rgba(188, 47, 40, 0.3)',
                'rgba(0, 0, 255, 0.3)',
                'rgba(100, 43, 255, 0.3)',
            ],
            table: [],
        },
        otb: {
            pie: [],
            table: []
        },

    }
}



/**
 * Default Visual Segments Data
 *
 * @return {Object} Default visual segments data
*/
export function defaultVisualSegmentsData() {
    return {
        pickup: [],
        pace: [],
        total_occ: [],
        total_rev: [],
        adr: [],
        segmentsColor: {
            // selected: '#f99200',
            // unselected: '#00c28d',
            // total: '#282b5d',
            unselected: '#d1d3d4',
            selected: {
                negative: "#ee9421", // orange
                default: '#00c28d' // green
            },
            total: '#282b5d' // blue
        },
        table: {
            headers: [
                'Segments',
                'Rooms',
                '% Rooms',
                'Revenue',
                '% Revenue',
                'ADR'
            ],
            data: []
        },
        pickup_table: {
            headers: [
                'Segments',
                'PU Rooms',
                'PU Revenue',
                'PU ADR'
            ],
            data: []
        },
        pace_table: {
            headers: [
                'Segments',
                'Pace Rooms',
                'Pace Revenue',
                'Pace ADR Variance'
            ],
            data: []
        },
        lead_time_table: {
            headers: [
                'Segments',
                '0',
                '1-3',
                '4-7',
                '8-14',
                '15-31',
                '>31'
            ],
            data: []
        },
        otb_day_of_week: {
            headers: [
                'Segments',
                'MON',
                'TUE',
                'WED',
                'THU',
                'FRI',
                'SAT',
                'SUN'
            ],
            data: []
        }
    }
}

export const visualsSegmentsTableHeaders = {
    otb: ['Segments', 'Rooms', '% Rooms', 'Revenue', '% Revenue', 'ADR'],
    pickup: ['Segments', 'PU Rooms', 'PU Revenue', 'PU ADR'],
    pace: ['Segments', 'Pace Rooms', 'Pace Revenue', 'Pace ADR Variance'],
    otb_lead_time: ['Segments', '0', '1-3', '4-7', '8-14', '15-31', '>31'],
    otb_day_of_week: ['Segments', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
}

/**
 * Transforms segments data into a format usable by the pie chart.
 *
 * @exports getSegmentsPie
 * @param {[Object]} data Segments data.
 * @return {Object} PieChart-usable segments data.
*/
export function getSegmentsPie(data) {
    const getPieDataLabel = item => `${item.label} ${Math.round((item.value / item.total) * 100)}%`;

    let pieData = [["Category", "Value"]];
    let pieDataColors = {};
    data.filter(item => item.label !== constants.ALLSEGMENTS).forEach((item, index) => {
        if (data.length > 1) {
            if (item.label !== constants.ALLSEGMENTS) {
                pieData.push([getPieDataLabel(item), item.value]);
                pieDataColors[index] = { color: item.color };
            };
        } else {
            pieData.push([getPieDataLabel(item), item.value]);
            pieDataColors[index] = { color: item.color };
        }
    })

    return {
        data: pieData,
        colors: pieDataColors
    }
}



/**
 * Transforms segments data into a format usable by the Pickup table chart.
 *
 * @exports getSegmentsPickupTable
 * @param {[Object]} segments_occ Segments occupancy data.
 * @return {Object} TableChart-usable segments pickup data.
*/
export function getSegmentsPickupTable(segments_occ) {
    const getValue = value => (isNullOrUndefined(value) || isNaN(value)) ? "-" : value;

    let pickupTable = {};
    for (let i = 0; i < segments_occ.length; i++) {
        pickupTable[segments_occ[i].label] = {
            name: segments_occ[i].label,
            color: segments_occ[i].selected ? "rgba(238, 148, 33, 0.3)" : null,
            data: [
                getValue(segments_occ[i].pickup.occ),
                getValue(segments_occ[i].pickup.rev),
                getValue(segments_occ[i].pickup.adr)
            ]
        }
    }

    return pickupTable;
}



/**
 * Transforms segments data into a format usable by the Pace table chart.
 *
 * @exports getSegmentsPaceTable
 * @param {[Object]} segments_occ Segments occupancy data.
 * @return {Object} TableChart-usable segments pace data.
*/
export function getSegmentsPaceTable(segments_occ) {
    const getValue = value => (isNullOrUndefined(value) || isNaN(value)) ? "-" : value;

    let paceTable = {};
    for (let i = 0; i < segments_occ.length; i++) {
        paceTable[segments_occ[i].label] = {
            name: segments_occ[i].label,
            color: segments_occ[i].selected ? "rgba(238, 148, 33, 0.3)" : null,
            data: [
                getValue(segments_occ[i].pace.occ),
                getValue(segments_occ[i].pace.rev),
                getValue(segments_occ[i].pace.adr)
            ]
        }
    }

    return paceTable;
}



/**
 * Subtracts two numbers unless one is null or undefined
 *
 * @exports diffUnlessNullOrUndefined
 * @param {Number} minuend Minued.
 * @param {Number} subtrahend Subtrahend.
 * @return {Number} Difference.
*/
export function diffUnlessNullOrUndefined(minuend, subtrahend) {
    return (isNullOrUndefined(minuend) || isNullOrUndefined(subtrahend))
        ? null
        : minuend - subtrahend;
}



/**
 * Subtracts two object values using their similar key unless one is null or undefined
 *
 * @exports diffByKeyUnlessNullOrUndefined
 * @param {Object} minuend Minued.
 * @param {Object} subtrahend Subtrahend.
 * @param {String} key Object key.
 * @param {String} subtrahendKey Subtrahend key, used when the minuend and subtrahend does not use similar keys.
 * @return {Number} Difference.
*/
export function diffByKeyUnlessNullOrUndefined(minuend, subtrahend, key, subtrahendKey = null) {
    if (isNullOrUndefined) subtrahendKey = key;
    let difference = (isNullOrUndefined(minuend) || isNullOrUndefined(subtrahend))
        ? null
        : minuend[key] - subtrahend[subtrahendKey];
    if (isNullOrUndefined(difference)) return null;
    return difference.toString().includes(".99") ? Math.ceil(difference) : difference;
}



/**
 * Handles sums of two items assumed to be numbers.
 * Returns a null if either item is null or undefined, else sum the numbers.
 *
 * @function sumUnlessNullOrUndefined
 * @param {?number} initial Initial value or previous sum
 * @param {?number} addend Number to be added
 * @return {?number} Sum of the items or null
*/
export function sumUnlessNullOrUndefined(initial, addend) {
    if (isNullOrUndefined(initial) || isNullOrUndefined(addend)) return null;
    return initial + addend;
}



/**
 * Handles sums of two items assumed to be numbers.
 * Returns a null if either item is null or undefined or a dash, else sum the numbers.
 *
 * @function sumUnlessNullOrUndefinedOrDash
 * @param {?number} initial Initial value or previous sum
 * @param {?number} addend Number to be added
 * @return {?number} Sum of the items or null
*/
export function sumUnlessNullOrUndefinedOrDash(initial, addend) {
    if (isNullOrUndefined(initial) || isNullOrUndefined(addend)) return null;
    if ((initial === "-") || (addend === "-")) return null;
    return initial + addend;
}



/**
 * Handles division of two items.
 * First parameter is the dividend, second parameter is the divisor.
 * Returns a null if either item is null or undefined. Also returns a null if the divisor is zero.
 *
 * @function divideUnlessZeroOrNull
 * @param {?number} dividend Dividend
 * @param {?number} divisor Divisor
 * @return {?number} Quotient or null
*/
export function divideUnlessZeroOrNull(dividend, divisor) {
    if (isNullOrUndefined(divisor)) return null;
    if (divisor === 0) return null;
    if (isNullOrUndefined(dividend)) return null;
    return dividend / divisor;
}



export function getBookingChartDataToDisplay(bookingChartData, maxDaysOut, currentDout = null) {
    const newchartData = { datasets: [], labels: [] };
    const indeces = [];
    const interval = constants.DAYS_OUT_OPTIONS.find(item => item.days_out === maxDaysOut).chart_interval;
    // Get the indeces to include in data. This way, we will know which data to exclude when days out tool is changed.
    bookingChartData.labels.forEach((label, index) => {
        if (label <= maxDaysOut && (label % interval === 0 || label <= 0)) {
            indeces.push(index);
            newchartData.labels.push(label);
        }
    });
    
    // Process the dataset. Remove all data that doesn't have the same index in indeces array.
    bookingChartData.datasets.forEach((dataset) => {
        let newData = [];
        if (Array.isArray(dataset.data)) {
            for (let [index, item] of dataset.data.entries()) {
                if (indeces.includes(index)) {
                    newData.push(item == 0? null: item);
                }
            }

        }
        newchartData.datasets.push({ ...dataset, data: newData });
    });

    return newchartData;
}

export function getVisualSegmentsTableHeaders(headers, isSelectedSegmentGroup = false) {
    if (!Array.isArray(headers)) return [];
    return headers.map((header, index) => {
        if (index === 0 && isSelectedSegmentGroup) return 'Segment Groups';

        return header;
    });
}

export function getPickupWaterfallData(statisticsSegmentData, selectedSegments) {
    const segmentsColor = defaultVisualSegmentsData().segmentsColors;
    const getSegmentColor = (value, isSelected) => {
        if (selectedSegments.includes("ALL SEGMENTS") || isSelected)
            return segmentsColor.selected[!(value >= 0)? "negative": "default"];    
        return segmentsColor.unselected;
    }
    return statisticsSegmentData.map(segment => {
        const isSelected = selectedSegments.includes(segment.segment);
        return {
            label: segment.segment,
            color: getSegmentColor(segment.pickup.o, isSelected),
            selected: isSelected,
            pickup: {
                occ: segment.pickup.o,
                rev: segment.pickup.r,
                adr: segment.pickup.adr
            },

        }
    });
}

/**
 * Get charts needed data to present to modal.
 * @param {Object} statisticsSegmentData The data fetched from statistics segments. An object of segments with 
 * @param {[String]} selectedSegments The list of segment codes selected from dashboard filter.
 * @returns Formatted segment data seggregated by charts and tables' requirements.
 */
export function getVisualSegmentsData(statisticsSegmentData, selectedSegments) {
    // if (!["pickup", "pace", "otb_occ", "otb_rev"].includes(type)) return [];
    if (!Array.isArray(statisticsSegmentData)) return null;
    // Clone statisticsSegmentData to prevent modification of original data.
    const newStatisticsSegmentdata = JSON.parse(JSON.stringify(statisticsSegmentData));
    const segmentsColor = defaultVisualSegmentsData().segmentsColor;
    const allSegments = newStatisticsSegmentdata.find(segment => segment.segment === "ALL SEGMENTS");
    const isSegmented = !selectedSegments.includes("ALL SEGMENTS");
    const getSegmentColor = (value, isSelected, isTotal) => {
        if (isTotal) return segmentsColor.total;
        if (selectedSegments.includes("ALL SEGMENTS") || isSelected)
            return segmentsColor.selected[!(value >= 0)? "negative": "default"];
        return segmentsColor.unselected;
    }

    const sortKeys = (segments, selectedSegments, order = "asc") => {
        const sortFn = (a, b) => order === "asc"? a.localeCompare(b): b.localeCompare(a);
        const leastSegments = ["UNS", "ALL SEGMENTS"];
        const newSelectedSegments = [...selectedSegments];
        const unselectedSegments = segments.filter(segment => ![...leastSegments, ...newSelectedSegments].includes(segment));
        newSelectedSegments.sort(sortFn);
        unselectedSegments.sort(sortFn);
        return [...newSelectedSegments, ...unselectedSegments, ...leastSegments];
    }
    // Collect ALL SEGMENTS DATA from API.
    newStatisticsSegmentdata.forEach(segment => {
        if (segment.segment === "ALL SEGMENTS") return;
        segment.otb.res.forEach(res => allSegments.otb.res.push(res));
        segment.pickup.res.forEach(res => allSegments.pickup.res.push(res));
    });

    const sortedSegmentCodes = sortKeys(newStatisticsSegmentdata.map(s => s.segment), selectedSegments);
    const segments = { pickup: [], pace: [], otb_occ: [], otb_rev: [], otb_lead_time: [], otb_day_of_week: [] };

    sortedSegmentCodes.forEach(segmentCode => {
        const segment = newStatisticsSegmentdata.find(segment => segment.segment === segmentCode);

        if (!segment) return;

        const isSelected = selectedSegments.includes(segment.segment);
        const isAllSegments = segment.segment === "ALL SEGMENTS";
        const initial = { label: segment.segment, selected: isSelected };
        // Pickup Waterfall
        segments.pickup.push({
            ...initial, 
            occ: Math.round(segment.pickup.o),
            rev: Math.round(segment.pickup.r),
            adr: toInt(Math.round(segment.pickup.adr?? 0), true),
            value: Math.round(segment.pickup.o),
            color: getSegmentColor(segment.pickup.o, isSelected, isAllSegments)
        });
        // Pace Waterfall
        segments.pace.push({
            ...initial, 
            occ: toInt(Math.round(segment.pace.o), true),
            rev: Math.round(segment.pace.r),
            adr: toInt(Math.round(segment.pace.adr?? 0), true),
            value: toInt(Math.round(segment.pace.o), true),
            color: getSegmentColor(segment.pace.o, isSelected, isAllSegments)
        });
        // On The Books Occupancy
        segments.otb_occ.push({
            ...initial, 
            value: Math.round(segment.otb.o),
            occ: Math.round(segment.otb.o),
            total: Math.round(allSegments.otb.o),
            adr: toInt(Math.round(segment.otb.adr?? 0), true),
            color: getSegmentColor(segment.otb.o, isSelected, isAllSegments)
        });
        // On The Books Revenue
        segments.otb_rev.push({
            ...initial, 
            value: Math.round(segment.otb.r),
            total: Math.round(allSegments.otb.r),
            adr: toInt(Math.round(segment.otb.adr?? 0), true),
            color: getSegmentColor(segment.otb.r, isSelected, isAllSegments)
        });
        // Getting Lead time ranges group. 0, 1-3, 4-7, 8-14, 15-31, > 31
        const doutsGroup = [0, 1, 4, 8, 15, 32];
        const resLeadTime = doutsGroup.reduce((obj, num) => ({ ...obj, [num]: { total_occ: 0, total_rev: 0, res: [] } }), {});
        const rnsLeadTime = doutsGroup.reduce((obj, num) => ({ ...obj, [num]: { total_occ: 0, total_rev: 0, res: [] } }), {});
        segment.otb.res.forEach(res => {
            // Use room nights per days out
            // res.summary.dates.forEach(item => {
            //     const resRawDout = moment(item.date).diff(moment(res.details.created_date).startOf("day"), "days");
            //     const resDout = resRawDout < 0? 0: resRawDout;
            //     const rangeDout = doutsGroup.slice().reverse().find(num => resDout >= num);
            //     rnsLeadTime[rangeDout].res.push({ 
            //         id: res.id, 
            //         cdate: res.details.created_date, 
            //         mdate: res.details.modified_date, 
            //         total_occ: res.summary.total_occ, 
            //         total_rev: res.summary.total_rev, 
            //         dout: resDout, date: item.date 
            //     });
            //     rnsLeadTime[rangeDout].total_occ += parseFloat(item.o);
            //     rnsLeadTime[rangeDout].total_rev += parseFloat(item.r);
            // });
            const firstCheckIn = res.summary.dates.sort((a, b) => new Date(a.date) - new Date(b.date))?.[0];
            const resRawDout = dayjs(firstCheckIn?.date).diff(dayjs(res.details.created_date).startOf("day"), "days");
            const resDout = resRawDout < 0? 0: resRawDout; // There are reservations with created date beyond check in date
            const rangeDout = doutsGroup.slice().reverse().find(num => resDout >= num);
            resLeadTime[rangeDout].res.push({ 
                id: res.id, 
                cdate: res.details.created_date,
                mdate: res.details.modified_date,
                total_occ: res.summary.total_occ,
                total_rev: res.summary.total_rev,
                dout: resDout, date: firstCheckIn
            });
            resLeadTime[rangeDout].total_occ += parseFloat(res.summary.total_occ);
            resLeadTime[rangeDout].total_rev += parseFloat(res.summary.total_rev);
        });

        segments.otb_lead_time.push({
            ...initial,
            res: Object.keys(resLeadTime).map(key => resLeadTime[key].res.length),
            adr: Object.keys(resLeadTime).map(key => Math.round((round(resLeadTime[key].total_rev / resLeadTime[key].total_occ)))),
            total_occ: Object.keys(resLeadTime).map(key => Math.round(resLeadTime[key].total_occ)),
            total_rev: Object.keys(resLeadTime).map(key => Math.round(resLeadTime[key].total_rev)),
            color: isSegmented
                ? isSelected? segmentsColor.selected.default: segmentsColor.unselected
                : segmentsColor.selected.default
        });
        const daysOfWeek = [1, 2, 3, 4, 5, 6, 7].reduce((obj, num) => ({ ...obj, [num]: { total_occ: 0, total_rev: 0, res: 0 } }), {});
        // 1 - 7 = Monday - Sunday
        segment.otb.res.forEach(res => {
            res.summary.dates.forEach(stayDate => {
                const dateISOWeek = dayjs(stayDate.date).isoWeekday();
                daysOfWeek[dateISOWeek].total_occ += stayDate.o;
                daysOfWeek[dateISOWeek].total_rev += stayDate.r;
            });
        });

        segments.otb_day_of_week.push({
            ...initial,
            adr: Object.keys(daysOfWeek).map(key => Math.round(round(daysOfWeek[key].total_rev / daysOfWeek[key].total_occ))),
            total_occ: Object.keys(daysOfWeek).map(key => Math.round(daysOfWeek[key].total_occ)),
            total_rev: Object.keys(daysOfWeek).map(key => Math.round(daysOfWeek[key].total_rev)),
            color: isSegmented
                ? isSelected? segmentsColor.selected.default: segmentsColor.unselected
                : segmentsColor.selected.default
        });
    });
    return segments;
}

function generateTooltip(data = []) {
    let tooltip = '';
    data.forEach((item) => {
        tooltip += `<p>${item.label}: <b>${item.value}</b></p>`
    });
    return tooltip;
}

export function transformLeadTimeStackChartData(leadTimeData, type) {
    const data = [];
    const labels = ["DaysOut"];
    const xHeaders = ["0", "1-3", "4-7", "8-14", "15-31", ">31"];
    const allSegments = leadTimeData.find(segment => segment.label === "ALL SEGMENTS");
    const isSegmented = leadTimeData.some(segment => segment.label !== "ALL SEGMENTS" && segment.selected === true);

    for (let rowIdx = 0; rowIdx < xHeaders.length; rowIdx++) {
        const rowVal = [xHeaders[rowIdx]];
        for (let segmentIdx = 0; segmentIdx < leadTimeData.length; segmentIdx++) {
            const segment = leadTimeData[segmentIdx];
            if (segment.label === "ALL SEGMENTS") continue;
            if (rowIdx === 0) {
                labels.push(segment.label);
                labels.push({ role: "tooltip", p: { html: true } });
                isSegmented && labels.push({ role: "style" });
            }
            rowVal.push(segment.res[rowIdx]);
            rowVal.push(generateTooltip([
                { label: "Segment", value: segment.label },
                { label: "RN", value: segment.total_occ[rowIdx] },
                { label: "%RN", value: Math.round((segment.total_occ[rowIdx] / allSegments.total_occ[rowIdx]) * 100) },
                { label: "ADR", value: withCurrency(round(segment.adr[rowIdx]?? 0)) },
                { label: "Revenue", value: withCurrency(round(segment.total_rev[rowIdx])) }
            ]));
            isSegmented && rowVal.push(`color: ${segment.color}`);
        }
        if (rowIdx === 0) data.push(labels);
        data.push(rowVal);
    }
    return data;
}

export function transformDayOfWeekStackChartData(dayOfWeekData) {
    const data = [];
    const labels = ["DayOfWeek"];
    const xHeaders = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
    const allSegments = dayOfWeekData.find(segment => segment.label === "ALL SEGMENTS");
    const isSegmented = dayOfWeekData.some(segment => segment.label !== "ALL SEGMENTS" && segment.selected === true);
    
    for (let rowIdx = 0; rowIdx < xHeaders.length; rowIdx++) {
        const rowVal = [xHeaders[rowIdx]];
        for (let segmentIdx = 0; segmentIdx < dayOfWeekData.length; segmentIdx++) {
            const segment = dayOfWeekData[segmentIdx];
            if (segment.label === "ALL SEGMENTS") continue;
            if (rowIdx === 0) {
                labels.push(segment.label);
                labels.push({ role: "tooltip", p: { html: true } });
                isSegmented && labels.push({ role: "style" });
            }
            rowVal.push(segment.total_occ[rowIdx]);
            rowVal.push(generateTooltip([
                { label: "Segment", value: segment.label },
                { label: "RN", value: segment.total_occ[rowIdx] },
                { label: "%RN", value: Math.round((segment.total_occ[rowIdx] / allSegments.total_occ[rowIdx]) * 100) },
                { label: "ADR", value: withCurrency(round(segment.adr[rowIdx]?? 0)) },
                { label: "Revenue", value: withCurrency(round(segment.total_rev[rowIdx])) }
            ]));
            isSegmented && rowVal.push(`color: ${segment.color}`);
        }
        if (rowIdx === 0) data.push(labels);
        data.push(rowVal);
    }
    return data;
}

/**
 * Transforms segments data into a format usable by the OTB table chart.
 *
 * @exports getSegmentsOtbTable
 * @param {[Object]} segments_occ Segments occupancy data.
 * @param {[Object]} segments_rev Segments revenue data.
 * @return {Object} TableChart-usable segments data.
*/
export function getSegmentsOtbTable(segments_occ, segments_rev) {
    const computePercentage = item => `${((item.value / item.total) * 100).toFixed(2)}%`;
    const otbTable = {};
    for (let i = 0; i < segments_occ.length; i++) {
        otbTable[segments_occ[i].label] = {
            name: segments_occ[i].label,
            color: segments_occ[i].selected ? "rgba(238, 148, 33, 0.3)" : null,
            data: [
                (segments_occ[i].value >= 0) ? segments_occ[i].value : "--",
                computePercentage(segments_occ[i]),
                (segments_rev[i].value >= 0) ? segments_rev[i].value : "--",
                computePercentage(segments_rev[i]),
                (segments_rev[i].adr !== null) ? segments_rev[i].adr : "--"
            ]
        }
    }
    return otbTable;
}

/**
 * Transforms segments data into a format usable by the segments table chart.
 *
 * @exports getSegmentsPickupTable
 * @param {[Object]} segments_occ Segments occupancy data.
 * @return {Object} TableChart-usable segments pickup data.
*/
export function getSegmentsTableData(statisticsSegments) {
    const getValue = value => (isNullOrUndefined(value) || isNaN(value)) ? "-" : value;
    const data = {};
    for (const segment of statisticsSegments) {
        data[segment.label] = {
            name: segment.label,
            color: segment.selected ? "rgba(238, 148, 33, 0.3)" : null,
            data: [
                getValue(segment.occ),
                getValue(segment.rev),
                getValue(segment.adr)
            ]
        }
    }

    return data;
}

export function transformLeadTimeTableData(leadTimeData, field = "res", usePercent = true) {
    const tableData = {};
    const allSegments = leadTimeData.find(segment => segment.label === "ALL SEGMENTS");

    for (let i = 0; i < leadTimeData.length; i++) {
        const segment = leadTimeData[i];
        const getValue = (key, usePercent = true) => {
            const values = segment[key];
            if (segment.label === "ALL SEGMENTS") {
                const total = values.reduce((total, val) => total += val, 0);
                return values.map(val => {
                    if (usePercent === true) {
                        const value = Math.round((val / total) * 100);
                        return `${isNaN(value)? 0: value}%`;
                    }
                    return val?? 0;
                });
            }
            return values.map((val, index) => {
                if (usePercent === true) {
                    const value = Math.round((val / allSegments[key][index]) * 100);
                    return `${isNaN(value)? 0: value}%`
                }
                return val?? 0;
            });
        }

        tableData[segment.label] = {
            name: segment.label,
            color: segment.selected ? "rgba(238, 148, 33, 0.3)" : null,
            data: getValue(field, usePercent)
        }
    }

    return tableData;
}

export function transformDayOfWeekTableData(dayOfWeekData, field = "res", usePercent = true) {
    const tableData = {};
    const allSegments = dayOfWeekData.find(segment => segment.label === "ALL SEGMENTS");

    for (let i = 0; i < dayOfWeekData.length; i++) {
        const segment = dayOfWeekData[i];
        const getValue = (key, usePercent) => {
            const values = segment[key];
            if (segment.label === "ALL SEGMENTS") {
                const total = values.reduce((total, val) => total + val, 0);
                return values.map(val => { 
                    if (usePercent === true) {
                        const value = Math.round((val / total) * 100);
                        return `${isNaN(value)? 0: value}%`;
                    }
                    return val?? 0;
                });
            }
            return values.map((val, index) => {
                if (usePercent === true) {
                    const value = Math.round((val / allSegments[key][index]) * 100);
                    return `${isNaN(value)? 0: value}%`;
                }
                return val?? 0;
            });
        }
        tableData[segment.label] = {
            name: segment.label,
            color: segment.selected ? "rgba(238, 148, 33, 0.3)" : null,
            data: getValue(field, usePercent)
        }
    }

    return tableData;
}

export function getReservationsList(statisticsSegmentData, selectedSegments, isSegmentGroup = true) {
    const reservations = {
        list: { otb: [], pickup: [] },
        total: { otb_occ: 0, pickup_occ: 0 }
    };

    const getReservationDetails = (res) => {
        const details = res.details;

        return {
            reservation_id: details.reservation_id,
            is_stay_date_cancelled: res.is_date_cancelled,
            property_id: res.pid,
            guest_name: details.guest_name,
            check_in_date: details.check_in_date,
            check_out_date: details.check_out_date,
            created_date: details.created_date,
            modified_date: details.modified_date,
            status: details.status,
            segment_code: isSegmentGroup? details.segment_group_code: details.segment_code,
            segment_name: isSegmentGroup? details.segment_group_name: details.segment_name,
            room_type_code: details.room_type_code,
            room_count: details.room_count,
            total_occ: res.summary.total_occ,
            total_rev: res.summary.total_rev,
            total_rate: details.total_rate,
        }
    }

    if (!Array.isArray(statisticsSegmentData)) return reservations;
    const uniqueRes = { otb: {}, pickup: {} };
    // A reservation's segment can be changed. When this happen, that reservation can be store to different segment. 
    // So we need to get the latest reservation version regardless of its segment.
    statisticsSegmentData.forEach(segment => {
        if (segment.segment === "ALL SEGMENTS") return;
        if (!selectedSegments.includes("ALL SEGMENTS") && !selectedSegments.includes(segment.segment)) return;
        
        segment.otb.res.forEach(res => {
            if (!uniqueRes.otb[res.sref_id]) uniqueRes.otb[res.sref_id] = [];
            uniqueRes.otb[res.sref_id].push(res);
        });

        segment.pickup.res.forEach(res => {
            if (!uniqueRes.pickup[res.sref_id]) uniqueRes.pickup[res.sref_id] = [];
            uniqueRes.pickup[res.sref_id].push(res);
        });
    });
    // This part is complicated. To get the latest reservation, get the latest reservation details then get its id.
    // That id holds the latest summary item.
    // Sometimes latest reservation details id could be not found in the list - Cancelled reservations
    Object.keys(uniqueRes.otb).forEach(srefId => {
        const latestVersion = uniqueRes.otb[srefId].sort((a, b) => b.version - a.version)?.[0];
        const res = uniqueRes.otb[srefId].find(res => res.id == latestVersion?.details.id);
        reservations.list.otb.push(getReservationDetails(res?? latestVersion));
    });

    Object.keys(uniqueRes.pickup).forEach(srefId => {
        const res = uniqueRes.pickup[srefId].sort((a, b) => b.version - a.version);
        if (uniqueRes.pickup[srefId].reduce((total, res) => total + res.summary.total_occ, 0) === 0) return;
        reservations.list.pickup.push(getReservationDetails(res[0]));
    });

    reservations.total.otb_occ = reservations.list.otb.reduce((occ, res) => occ + res.total_occ, 0);
    reservations.total.pickup_occ = reservations.list.pickup.reduce((occ, res) => occ + res.total_occ, 0);

    return reservations;
}

export function getVisualAsOfsData(variances, contentTitles, comparison, hideComparisons, hasCompetitor) {
    let visualAsOfs = defaultVisualsAsofData();

    // Function to Generate Line Data from the Table Data
    const lineData = (data) => {
        let line = [];
        const douts = variances.douts;
        for (let i = 0; i < 8; i++) {
            let d = hideComparisons
                ? [douts[i], data.r3_vs_l[i], data.r5_vs_s[i], data.r7_vs_a[i], data.r9_vs_w[i]]
                : [douts[i], data.r3_vs_l[i], data.r5_vs_s[i], data.r7_vs_a[i], data.r9_vs_w[i], data.r11_vs_c[i]];
            line.push(d);
        }
        return line;
    }

    // Set Pickup Occ Data
    visualAsOfs.pickup_occ.table.otb_occ.name = contentTitles.table;
    visualAsOfs.pickup_occ.table.otb_occ.data = variances.pickOccs.r1_otbs;
    visualAsOfs.pickup_occ.table.ly_occ.data = variances.pickOccs.r4_sdlys;
    visualAsOfs.pickup_occ.table.sdly_occ.data = variances.pickOccs.r4_sdlys;
    visualAsOfs.pickup_occ.table.otb_vs_ly_occ.data = variances.pickOccs.r5_vs_s;
    visualAsOfs.pickup_occ.table.otb_vs_sdly_occ.data = variances.pickOccs.r5_vs_s;
    visualAsOfs.pickup_occ.table.ave_occ.data = variances.pickOccs.r6_ados;
    visualAsOfs.pickup_occ.table.otb_vs_ave_occ.data = variances.pickOccs.r7_vs_a;
    visualAsOfs.pickup_occ.table.ave_dow_occ.data = variances.pickOccs.r8_adows;
    visualAsOfs.pickup_occ.table.otb_vs_ave_dow_occ.data = variances.pickOccs.r9_vs_w;
    if (hideComparisons) {
        delete visualAsOfs.pickup_occ.table.cmp_occ;
        delete visualAsOfs.pickup_occ.table.otb_vs_cmp_occ;
    } else {
        visualAsOfs.pickup_occ.table.cmp_occ.name = comparison;
        visualAsOfs.pickup_occ.table.otb_vs_cmp_occ.name = `vs ${comparison}`;
        visualAsOfs.pickup_occ.table.cmp_occ.data = variances.pickOccs.r8_cmps;
        visualAsOfs.pickup_occ.table.otb_vs_cmp_occ.data = variances.pickOccs.r11_vs_c;
    }
    visualAsOfs.pickup_occ.line = lineData(variances.pickOccs);

    // Set Pickup ADR Data
    visualAsOfs.pickup_adr.table.otb_adr.name = contentTitles.table;
    visualAsOfs.pickup_adr.table.otb_adr.data = variances.pickAdrs.r1_otbs;
    visualAsOfs.pickup_adr.table.ly_adr.data = variances.pickAdrs.r2_lys;
    visualAsOfs.pickup_adr.table.otb_vs_ly_adr.data = variances.pickAdrs.r3_vs_l;
    visualAsOfs.pickup_adr.table.sdly_adr.data = variances.pickAdrs.r4_sdlys;
    visualAsOfs.pickup_adr.table.otb_vs_sdly_adr.data = variances.pickAdrs.r5_vs_s;
    visualAsOfs.pickup_adr.table.ave_adr.data = variances.pickAdrs.r6_ados;
    visualAsOfs.pickup_adr.table.otb_vs_ave_adr.data = variances.pickAdrs.r7_vs_a;
    visualAsOfs.pickup_adr.table.ave_dow_adr.data = variances.pickAdrs.r8_adows;
    visualAsOfs.pickup_adr.table.otb_vs_ave_dow_adr.data = variances.pickAdrs.r9_vs_w;
    if (hideComparisons) {
        delete visualAsOfs.pickup_adr.table.cmp_adr;
        delete visualAsOfs.pickup_adr.table.otb_vs_cmp_adr;
    } else {
        visualAsOfs.pickup_adr.table.cmp_adr.name = comparison;
        visualAsOfs.pickup_adr.table.otb_vs_cmp_adr.name = `vs ${comparison}`;
        visualAsOfs.pickup_adr.table.cmp_adr.data = variances.pickAdrs.r8_cmps;
        visualAsOfs.pickup_adr.table.otb_vs_cmp_adr.data = variances.pickAdrs.r11_vs_c;
    }
    visualAsOfs.pickup_adr.line = lineData(variances.pickAdrs);


    // Set Pace Occ Data
    visualAsOfs.pace_occ.table.otb_occ.name = contentTitles.table;
    visualAsOfs.pace_occ.table.otb_occ.data = variances.paceOccs.r1_otbs;
    visualAsOfs.pace_occ.table.ly_occ.data = variances.paceOccs.r2_lys;
    visualAsOfs.pace_occ.table.otb_vs_ly_occ.data = variances.paceOccs.r3_vs_l;
    visualAsOfs.pace_occ.table.sdly_occ.data = variances.paceOccs.r4_sdlys;
    visualAsOfs.pace_occ.table.otb_vs_sdly_occ.data = variances.paceOccs.r5_vs_s;
    visualAsOfs.pace_occ.table.ave_occ.data = variances.paceOccs.r6_ados;
    visualAsOfs.pace_occ.table.otb_vs_ave_occ.data = variances.paceOccs.r7_vs_a;
    visualAsOfs.pace_occ.table.ave_dow_occ.data = variances.paceOccs.r8_adows;
    visualAsOfs.pace_occ.table.otb_vs_ave_dow_occ.data = variances.paceOccs.r9_vs_w;
    if (hideComparisons) {
        delete visualAsOfs.pace_occ.table.cmp_occ;
        delete visualAsOfs.pace_occ.table.otb_vs_cmp_occ;
    } else {
        visualAsOfs.pace_occ.table.cmp_occ.name = comparison;
        visualAsOfs.pace_occ.table.otb_vs_cmp_occ.name = `vs ${comparison}`;
        visualAsOfs.pace_occ.table.cmp_occ.data = variances.paceOccs.r8_cmps;
        visualAsOfs.pace_occ.table.otb_vs_cmp_occ.data = variances.paceOccs.r11_vs_c;
    }
    visualAsOfs.pace_occ.line = lineData(variances.paceOccs);

    // Set Pace ADR Data
    visualAsOfs.pace_adr.table.otb_adr.name = contentTitles.table;
    visualAsOfs.pace_adr.table.otb_adr.data = variances.paceAdrs.r1_otbs;
    visualAsOfs.pace_adr.table.ly_adr.data = variances.paceAdrs.r2_lys;
    visualAsOfs.pace_adr.table.otb_vs_ly_adr.data = variances.paceAdrs.r3_vs_l;
    visualAsOfs.pace_adr.table.sdly_adr.data = variances.paceAdrs.r4_sdlys;
    visualAsOfs.pace_adr.table.otb_vs_sdly_adr.data = variances.paceAdrs.r5_vs_s;
    visualAsOfs.pace_adr.table.ave_adr.data = variances.paceAdrs.r6_ados;
    visualAsOfs.pace_adr.table.otb_vs_ave_adr.data = variances.paceAdrs.r7_vs_a;
    visualAsOfs.pace_adr.table.ave_dow_adr.data = variances.paceAdrs.r8_adows;
    visualAsOfs.pace_adr.table.otb_vs_ave_dow_adr.data = variances.paceAdrs.r9_vs_w;
    if (hideComparisons) {
        delete visualAsOfs.pace_adr.table.cmp_adr;
        delete visualAsOfs.pace_adr.table.otb_vs_cmp_adr;
    } else {
        visualAsOfs.pace_adr.table.cmp_adr.name = comparison;
        visualAsOfs.pace_adr.table.otb_vs_cmp_adr.name = `vs ${comparison}`;
        visualAsOfs.pace_adr.table.cmp_adr.data = variances.paceAdrs.r8_cmps;
        visualAsOfs.pace_adr.table.otb_vs_cmp_adr.data = variances.paceAdrs.r11_vs_c;
    }
    visualAsOfs.pace_adr.line = lineData(variances.paceAdrs);


    // Set BAR Data
    visualAsOfs.rate.table.otb_rate.name = contentTitles.table;
    visualAsOfs.rate.table.otb_rate.data = variances.bars.r1_otbs;
    visualAsOfs.rate.table.ly_rate.data = variances.bars.r2_lys;
    visualAsOfs.rate.table.otb_vs_ly_rate.data = variances.bars.r3_vs_l;
    visualAsOfs.rate.table.sdly_rate.data = variances.bars.r4_sdlys;
    visualAsOfs.rate.table.otb_vs_sdly_rate.data = variances.bars.r5_vs_s;
    visualAsOfs.rate.table.ave_rate.data = variances.bars.r6_ados;
    visualAsOfs.rate.table.otb_vs_ave_rate.data = variances.bars.r7_vs_a;
    visualAsOfs.rate.table.ave_dow_rate.data = variances.bars.r8_adows;
    visualAsOfs.rate.table.otb_vs_ave_dow_rate.data = variances.bars.r9_vs_w;
    if (hideComparisons) {
        delete visualAsOfs.rate.table.cmp_rate;
        delete visualAsOfs.rate.table.otb_vs_cmp_rate;
    } else {
        visualAsOfs.rate.table.cmp_rate.name = comparison;
        visualAsOfs.rate.table.otb_vs_cmp_rate.name = `vs ${comparison}`;
        visualAsOfs.rate.table.cmp_rate.data = variances.bars.r8_cmps;
        visualAsOfs.rate.table.otb_vs_cmp_rate.data = variances.bars.r11_vs_c;
    }
    visualAsOfs.rate.line = lineData(variances.bars);

    
    // Set Competitors' Price Data
    // Include property's rates in competitors' table and graph
    if (hasCompetitor) {
        visualAsOfs.compricing.table['r1'] = {
            name: "Property",
            color: visualAsOfs.compricing.table_colors[0],
            data: variances.bars.r1_otbs
        };
        variances.compricing.forEach((item, index) => {
            visualAsOfs.compricing.table[`r${index + 2}`] = {
                name: item.competitor,
                color: visualAsOfs.compricing.table_colors[index + 1],
                data: item.pricing
            }
        });
        
        visualAsOfs.compricing.line = variances.douts.map((dout, index) => {
            const values = variances.compricing.map((item, comIdx) => {
                return variances.compricing[comIdx].pricing[index];
            });
            values.unshift(variances.bars.r1_otbs[index]);
            return [dout, ...values];
        });

        visualAsOfs.compricing.line_columns = variances.compricing.map(item => item.competitor);
        visualAsOfs.compricing.line_columns.unshift("Property");
    }

    visualAsOfs.days_out = variances.douts;
    return visualAsOfs;
}