import React, { useEffect, useState, useRef } from 'react';
import { AddFilled } from "Icons";
import Button from "../../Button/Button";
import DataGrid from '../../DataGrid';
import adminConsoleActions from "../../../store/actions/adminConsoleActions";
import FormModal from "../../FormModal/FormModal";
import './BookingSources.scss';

const BookingSources = () => {
    const _mode = { ADD: 'Add', EDIT: 'Edit', DELETE: 'Delete' };
    const bookingSourceGridRef = useRef();
    const { getBookingSources, getBookingSource, addBookingSource, updateBookingSource, deleteBookingSource } = adminConsoleActions();
    const [bookingSourceModalState, setBookingSourceModalState] = useState({
        visible: false,
        mode: _mode.ADD,
        selectedRowIdx: null
    });
    const bookingSourceModalController = FormModal.newController({}, bookingSourceModalState.visible);
    const {
        errors,
        inputValues,
        setValidations,
        onChangeHandler,
        onBlurHandler
    } = bookingSourceModalController;
    const showModal = (visible, mode, rowIdx) => {
        setBookingSourceModalState({...bookingSourceModalState, 
            visible: visible, 
            selectedRowIdx: rowIdx, 
            mode: mode
        });
    }
    const closeModal = () => {
        setBookingSourceModalState({...bookingSourceModalState, visible: false});
    }
    const doneSubmit = (result, type) => {
        if (result.type == type) {
            bookingSourceModalController.show(false);
            loadBookingSourcesGrid();
        }
    }
    const loadBookingSourcesGrid = () => {
        bookingSourceGridRef.current.setLoading(true);
        getBookingSources().then(response => {
            const data = [];
            if (Array.isArray(response.data?.data)) {
                response.data.data.forEach(booking_source => {
                    data.push({
                        booking_source_code: booking_source._id,
                        booking_source_name: booking_source.source
                    });
                });
            }
            bookingSourceGridRef.current.loadData(data);
            bookingSourceGridRef.current.setLoading(false);
        });
    }
    const submit = (e) => {
        e.preventDefault();
        if (Object.keys(errors).length > 0) return;

        switch(bookingSourceModalState.mode) {
            case _mode.ADD: 
                addBookingSource({
                    _id: inputValues.booking_source_code,
                    source: inputValues.booking_source_name
                }).then(result => doneSubmit(result, "ADD_BOOKING_SOURCE_SUCCESS"));
                break;
            case _mode.EDIT:
                updateBookingSource(bookingSourceGridRef.current.getGridData(bookingSourceModalState.selectedRowIdx).booking_source_code, {
                    _id: inputValues.booking_source_code,
                    source: inputValues.booking_source_name
                }).then(result => doneSubmit(result, "UPDATE_BOOKING_SOURCE_SUCCESS"));
                break;
            case _mode.DELETE:
                deleteBookingSource(bookingSourceGridRef.current.getGridData(bookingSourceModalState.selectedRowIdx).booking_source_code).then(result => 
                    doneSubmit(result, "DELETE_BOOKING_SOURCE_SUCCESS")
                );
                break;
        }
    }
    useEffect(() => {
        if (bookingSourceModalState.visible === true) {
            let data = null;
            if ([_mode.EDIT, _mode.DELETE].includes(bookingSourceModalState.mode)) {
                data = bookingSourceGridRef.current.getGridData(bookingSourceModalState.selectedRowIdx);
                getBookingSource(data.booking_source_code).then(result => {
                    let bookingSource = result.data.data;
                    if (typeof bookingSource === 'object') {
                        bookingSourceModalController.show(true, { 
                            booking_source_name: bookingSource.source, 
                            booking_source_code: bookingSource._id
                        });
                    }
                });
            } else {
                bookingSourceModalController.show(true);
            }
        } else {
            bookingSourceModalController.show(false);
        }
    }, [bookingSourceModalState]);

    useEffect(() => {
        loadBookingSourcesGrid();
    }, []);

    return (
        <div className="admin-booking-sources">
            <div className="add-booking-source" style={{display: 'flex', marginBottom: '10px'}}>
                <h4 style={{color: 'white', marginRight: '10px'}}>Add Booking Source</h4>
                <AddFilled
                    onClick={() => { showModal(true, _mode.ADD); }}
                    width="25px"
                    height="25px"
                    className="pointer"
                />
            </div>
            <DataGrid
                ref={bookingSourceGridRef}
                controller={bookingSourceGridRef.current} 
                columns={[{
                    id: 'booking_source_name',
                    header: 'BOOKING SOURCE',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'booking_source_code',
                    header: 'BOOKING SOURCE CODE',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'actions',
                    header: 'ACTIONS',
                    type: 'action',
                    width: '54px',
                    actionFn: {
                        // NOTE: States are private in functional component. Accessing any state here is useless.
                        onEdit: (rowIdx) => {
                            showModal(true, _mode.EDIT, rowIdx);
                        },
                        onDelete: (rowIdx) => {
                            showModal(true, _mode.DELETE, rowIdx);
                        }
                    }
                }]}
                width="500px"
            />
            <FormModal title={`${bookingSourceModalState.mode} Booking Source`} onSubmit={submit}
                visible={bookingSourceModalState.visible} width={500} maskClosable={false} 
                onClose={() => { closeModal(); }}
                setValidations={setValidations} controller={bookingSourceModalController}
            >
            {(bookingSourceModalState.mode === _mode.ADD || bookingSourceModalState.mode === _mode.EDIT) && (<>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Booking Source"
                        name="booking_source_name" value={inputValues.booking_source_name} error={errors.booking_source_name}
                        validations={{ required: true, characters: 'alphanumeric_with_space' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Booking Source Code"
                        name="booking_source_code" value={inputValues.booking_source_code} error={errors.booking_source_code} disabled={bookingSourceModalState.mode === _mode.EDIT}
                        validations={{ required: true, characters: 'alphanumeric' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
            </>)}
            {bookingSourceModalState.mode === _mode.DELETE && (<>
                <div className="light-form delete-segment">
                    <center>
                        <p>Deleting this booking source will remove all mappings associated with this booking source. All occupancy and revenue data will be reallocated to the Unsegmented cluster.</p>
                        <p>Are you sure you want to delete the <b>{bookingSourceGridRef.current.getGridData([bookingSourceModalState.selectedRowIdx])?.booking_source_name}</b> booking source?</p>
                    </center>
                </div>
            </>)}
            <div className="light-form-buttons">
                <Button type="bg-light" style={{ marginRight: '8px' }} onClick={() => { closeModal() }}>Cancel</Button>
                <Button btnType="submit" type="primary">
                    {bookingSourceModalState.mode === _mode.DELETE? 'DELETE': 'Submit'}
                </Button>
            </div>
            </FormModal>
        </div>
    )

}

export default React.memo(BookingSources);