import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Spotlight from "react-spotlight";
import { Modal } from "antd";
import { Exit } from "Icons";

import Button from "../Button/Button";
import TutorialBox from "./TutorialBox";
import useTutorial from "hooks/useTutorial";

import useHelperFunctions from "../../store/actions/actions";



const Tutorial = ({ isNewUser, updateUserTutorial }) => {
    const loading = useSelector((state) => state.properties.loading);
    const { tutorial } = useSelector((state) => state.mainReducer);
    const { toggleTutorialSection } = useHelperFunctions();
    const { getTutorialObject } = useTutorial();
    const [activeTutorial, setActiveTutorial] = useState({});

    const [run, setRun] = useState(false);

    const [pos, setPos] = useState({ x: 0, y: -100 });

    const tutorialObject = getTutorialObject();

    const orientation = {
        bottom: {
            top: "calc(200% + 4px)",
            left: false,
        },
        left: {
            left: -58,
            top: "-3px",
        },
        right: {
            right: "-54px",
            top: "8px",
        },
    };

    useEffect(() => {
        if (isNewUser) {
            toggleTutorialSection({
                section: "dashboard",
                payload: true,
            });
            toggleTutorialSection({
                section: "overviewCalendar",
                payload: true,
            });
            toggleTutorialSection({
                section: "sidepanel",
                payload: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewUser]);

    useEffect(() => {
        if (run) {
            updateUserTutorial({
                tutorial: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [run]);

    useEffect(() => {
        Object.keys(tutorial).map((item) => {
            if (tutorial[item].show) {
                if (
                    item === "currentDate" ||
                    item === "sell_rate" ||
                    item === "pickupDate" ||
                    item === "paceDate" ||
                    item === "rateDate"
                )
                    document.querySelector("." + item + "Tutorial").scrollIntoView();
                const position = document
                    .querySelector("." + item + "Tutorial")
                    .getBoundingClientRect();
                setPos({
                    x: position.left + position.width / 2,
                    y: position.top + position.height / 2,
                });

                setActiveTutorial({
                    tutorial: item,
                    section: tutorial[item].section,
                });
            }
        });

        return function cleanup() {
            setPos({ x: 0, y: -100 });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        tutorial.avatar,
        tutorial.search,
        tutorial.other,
        tutorial.filter,
        tutorial.dataTypeView,
        tutorial.filter2,
        tutorial.filter1,
        tutorial.filter3,
        tutorial.hotelMenu,
        tutorial.currentDate,
        tutorial.currentDate2,
        tutorial.currentDate3,
        tutorial.pickupDate,
        tutorial.paceDate,
        tutorial.rateDate,
        tutorial.weeklyCard,
        tutorial.monthlyCard,
        tutorial.table,
        tutorial.uploadModal,
        tutorial.downloadTemplate,
        tutorial.chart,
        tutorial.chart2,
        tutorial.chart3,
        tutorial.chart4,
        tutorial.pickup,
        tutorial.pickup2,
        tutorial.pickup3,
        tutorial.pace,
        tutorial.pace2,
        tutorial.pace3,
        tutorial.sell_rate,
        tutorial.sell_rate2,
        tutorial.sell_rate3,
        tutorial.otb,
        tutorial.sidepanel,
    ]);

    const spotlightBackgroundColor = "rgba(0,0,0,.8)";

    const {
        avatar,
        search,
        other,
        dataTypeView,
        filter1,
        filter,
        filter2,
        filter3,
        hotelMenu,
        currentDate,
        currentDate2,
        currentDate3,
        pickupDate,
        paceDate,
        rateDate,
        weeklyCard,
        monthlyCard,
        table,
        uploadModal,
        downloadTemplate,
        chart,
        chart2,
        chart3,
        chart4,
        pickup,
        pickup2,
        pickup3,
        pace,
        pace2,
        pace3,
        sell_rate,
        sell_rate2,
        sell_rate3,
        otb,
        sidepanel,
    } = tutorial;
    return (
        <>
            {(avatar.show ||
                search.show ||
                other.show ||
                dataTypeView.show ||
                filter2.show ||
                filter3.show ||
                hotelMenu.show ||
                currentDate.show ||
                currentDate2.show ||
                currentDate3.show ||
                pickupDate.show ||
                paceDate.show ||
                rateDate.show ||
                weeklyCard.show ||
                monthlyCard.show ||
                table.show ||
                filter.show ||
                uploadModal.show ||
                downloadTemplate.show ||
                sidepanel.show ||
                chart.show ||
                chart2.show ||
                chart3.show ||
                chart4.show ||
                pickup.show ||
                pickup2.show ||
                pickup3.show ||
                pace.show ||
                pace2.show ||
                pace3.show ||
                sell_rate.show ||
                sell_rate2.show ||
                sell_rate3.show ||
                otb.show ||
                filter1.show) && (
                    <Spotlight
                        x={pos.x}
                        y={pos.y}
                        animSpeed={0}
                        color={spotlightBackgroundColor}
                        radius={100}
                        responsive
                        borderColor="transparent"
                        innerClass="outer-box-tutorial"
                        outerStyles={{
                            zIndex: 1004,
                            width: "200px",
                        }}
                        innerStyles={{
                            borderRadius: 0,
                            height: tutorialObject[activeTutorial?.tutorial]?.height,
                            width: tutorialObject[activeTutorial?.tutorial]?.width,
                            opacity: 1,
                            transition: "none",
                            transform: tutorialObject[activeTutorial?.tutorial]?.transform,
                        }}
                        borderWidth={5}
                    >
                        <div
                            style={{
                                position: "absolute",
                                left: orientation[
                                    tutorialObject[activeTutorial?.tutorial]?.orientation
                                ]?.left
                                    ? orientation[
                                        tutorialObject[activeTutorial?.tutorial]?.orientation
                                    ]?.left !== 0
                                        ? orientation[
                                            tutorialObject[activeTutorial?.tutorial]?.orientation
                                        ]?.left -
                                        tutorialObject[activeTutorial?.tutorial]?.boxWidth +
                                        "px"
                                        : orientation[
                                            tutorialObject[activeTutorial?.tutorial]?.orientation
                                        ]?.left
                                    : "initial",
                                top:
                                    orientation[
                                        tutorialObject[activeTutorial?.tutorial]?.orientation
                                    ]?.top,
                                right:
                                    orientation[
                                        tutorialObject[activeTutorial?.tutorial]?.orientation
                                    ]?.right,
                                whiteSpace: "nowrap",
                            }}
                        >
                            <TutorialBox
                                width={tutorialObject[activeTutorial?.tutorial]?.boxWidth}
                                type={activeTutorial.tutorial}
                                section={activeTutorial.section}
                                orientation={
                                    tutorialObject[activeTutorial?.tutorial]?.orientation
                                }
                                content={tutorialObject[activeTutorial?.tutorial]?.content}
                                next={tutorialObject[activeTutorial?.tutorial]?.next}
                                calloutStyles={
                                    tutorialObject[activeTutorial?.tutorial]?.calloutStyles
                                }
                            />
                        </div>
                    </Spotlight>
                )}
            {!loading && isNewUser && (
                <Modal
                    width={600}
                    open={!run}
                    footer={false}
                    closeIcon={
                        <Exit
                            width="25px"
                            height="25px"
                            onClick={() => setRun(true)}
                            className="pointer"
                        />
                    }
                >
                    <div className="rms-modal-content">
                        <div style={{ margin: "15px", marginBottom: 0, display: "flex" }}>
                            <div style={{ position: "relative", bottom: "20px" }}>
                                <h2
                                    style={{
                                        textAlign: "center",
                                        position: "relative",
                                        top: "30px",
                                        left: "6px",
                                    }}
                                >
                                    Welcome to
                </h2>

                                <img
                                    src={`${process.env.PUBLIC_URL}/images/revlogo.png`}
                                    alt="revandyoulogo"
                                    style={{
                                        width: "120px",
                                        position: "relative",
                                        left: "21px",
                                        top: "10px",
                                    }}
                                />
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/dog.png`}
                                    alt="dog"
                                    style={{
                                        width: "150px",
                                        position: "relative",
                                        bottom: "30px",
                                    }}
                                />
                            </div>
                            <div style={{ paddingLeft: "40px" }}>
                                <p>
                                    Hi! I'm Rev! Welcome to Rev&You, your partner in unlocking
                                    your hotel's revenue potential.
                </p>
                                <p>
                                    Rev&You helps you find the best dynamic price to maximize your
                                    hotel's revenue potential. We analyze data to make an accurate
                                    demand forecast per day and recommend a pricing action in a
                                    friendly and intuitive way.
                </p>
                                <p>
                                    Click the hotspots throughout the application for explanations
                                    on data displayed, and what actions you can perform within.
                </p>
                                <p>
                                    Switch the tutorials on or off at will. Just click on the help
                                    icon found in the bottom left corner of every section of
                                    Rev&You.
                </p>
                                <div className="modal-buttons">
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setRun(true);
                                        }}
                                    >
                                        GOT IT
                  </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default Tutorial;
