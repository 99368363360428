export const messageTypeList = [{
    id: 'OCC',
    displayText: 'Availability'
}, {
    id: 'BAR',
    displayText: 'BAR'
}, {
    id: 'RATE',
    displayText: 'Rates'
}, {
    id: 'RES',
    displayText: 'Reservations'
}, {
    id: 'RESH',
    displayText: 'Reservation History'
}, {
    id: 'RESOB',
    displayText: 'Onboarding'
}];

export const messageStatusList = [{
    id: 'SUCCESS',
    displayText: 'SUCCESS'
}, {
    id: 'WARNING',
    displayText: 'WARNING'
}, {
    id: 'UNPROCESSED',
    displayText: 'UNPROCESSED'
}];