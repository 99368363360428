import React from 'react';
import Switch from '../../Switch/Switch';

export default ({ name, inputClass, value, disabled, switchOnly = false, onChange }) => {
    value = (value === true ? true : false);
    return (
    <div className="form-field-input-div">
        <div className={`form-field-input form-field-switch ${inputClass}`} style={{ display: "inline-flex", marginTop: "auto", marginBottom: "auto" }}>
            {switchOnly !== true && <span style={{ marginRight: 8, fontSize: 14 }}>{value === true ? "Active" : "Inactive"}</span>}
            <Switch checked={value} disabled={disabled} onChange={() => onChange(name, !value)}></Switch>
        </div>
    </div>);
}