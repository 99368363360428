import React from "react";
import { useExport } from "./useExport";
import Export from "../../Icons/Export";

const ExportCalendarButton = ({ data, style }) => {
    const { exportCalendarData } = useExport();
    
    return <Export className="pointer" onClick={() => exportCalendarData(data)} style={style} />
}

export default ExportCalendarButton;