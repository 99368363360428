import React from 'react';
import { Select as AntSelect } from 'antd';

export const { Option } = AntSelect;

const Select = ({
    mode,
    value,
    visible,
    children,
    popupMatchSelectWidth = false,
    optionLabelProp,
    labelRender,
    suffixIcon,
    dropdownRender,
    options,
    optionRender,
    onDropdownVisibleChange,
    onChange,
    maxTagCount,
    style,
    showSearch = false,
    filterOption = true
}) => {
    return <AntSelect
        mode={mode}
        value={value}
        open={visible}
        popupMatchSelectWidth={popupMatchSelectWidth}
        optionLabelProp={optionLabelProp}
        labelRender={labelRender}
        suffixIcon={suffixIcon}
        dropdownRender={dropdownRender}
        onChange={onChange}
        onDropdownVisibleChange={onDropdownVisibleChange}
        options={options}
        optionRender={optionRender}
        showSearch={showSearch}
        maxTagCount={maxTagCount}
        style={style}
        filterOption={filterOption}
    >{children}</AntSelect>
}

export default Select;