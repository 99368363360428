import { useDispatch } from "react-redux";
import constants from "../../constants";



const useHelperFunctions = () => {
    const dispatch = useDispatch();

    const emptyDataStore = () => {
        dispatch({
            type: constants.STORE_DAILY_DATA,
            value: null,
        });
        dispatch({
            type: constants.STORE_WEEKLY_DATA,
            value: null,
        });
        dispatch({
            type: constants.STORE_MONTHLY_DATA,
            value: null,
        });
    };

    const setADRState = (status) => {
        dispatch({
            type: constants.TOGGLE_ADR_STATE,
            value: status,
        });
    };

    const toggleSidePanel = (value) => {
        if (value === false) {
            dispatch({ type: constants.UPDATE_SIDEBAR_CONTENT, value: null });
            dispatch({ type: constants.UPDATE_SELECTED_CALENDAR_CELL, value: null });
            dispatch({
                type: constants.TOGGLE_CHART_MODAL_SECTION,
                value: { show: false },
            });
        }

        dispatch({
            type: constants.TOGGLE_SIDEBAR,
            value: value,
        });
    };

    const setIsLoading = (value, loading) => {
        dispatch({
            type: constants.SET_ISLOADING,
            value: value,
            loading: loading,
        });
    };
    
    const setNotesModalState = (value) => {
        dispatch({
            type: constants.SET_NOTES_MODAL,
            value: value,
        });
    }

    const setShowUploadModal = (value) => {
        dispatch({
            type: constants.SET_UPLOAD_MODAL,
            value,
        });
    };

    const setToggleTutorial = (value) => {
        dispatch({
            type: constants.TOGGLE_TUTORIAL,
            value,
        });
    };

    const toggleTutorialSection = (value) => {
        dispatch({
            type: constants.TOGGLE_TUTORIAL_SECTION,
            value,
        });
    };

    const toggleChartModal = (value) => {
        dispatch({
            type: constants.TOGGLE_CHART_MODAL_SECTION,
            value,
        });
        dispatch({
            type: constants.UPDATE_VISUALS_SELECTED_TAB,
            value: value.tab,
        });
    }

    return {
        emptyDataStore,
        setADRState,
        toggleSidePanel,
        setIsLoading,
        setNotesModalState,
        setShowUploadModal,
        setToggleTutorial,
        toggleTutorialSection,
        toggleChartModal
    };
};

export default useHelperFunctions;
