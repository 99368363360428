import React from "react";
import { useSelector } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";
import RecommendationIcon from "../../Recommendation/RecommendationIcon";
import NotesNotif from '../NotesNotif';
import { TutorialSpot } from "../../Tutorial/index";
import {
    formatData, formatDataWithPlusSign, formatNegativeOne, isNullOrUndefined,
    withCurrency, getPaceTitle, getPaceRate, noTimezone, getDailyCellColor, getDailyCellStatus
} from "../../../utils";
import useHelperFunctions from "store/actions/actions";


const DailyCell = ({ dayOfWeek, activeCell, status, data, onClick, selectedTab, ADRState }) => {
    const tutorial = useSelector((state) => state.mainReducer.tutorial);
    const state = useSelector((state) => state.mainReducer);
    const { calendar_data, currency } = state;
    const isGroup = state.isGroup;
    const hasCompetitorPricing = (state.propertyInView.competitors_info?? []).length > 0;

    const { setNotesModalState } = useHelperFunctions();

    const users = useSelector((state) => state.users);
    const userPrefs = users.authUser.prefs;
    const usePercentsForOccs = isGroup && !isNullOrUndefined(userPrefs) && (userPrefs.usePercentsForOccs ?? true);

    const date = data && data.check_in ? dayjs(noTimezone(data.check_in)).format("DD") : " ";
    const cellKey = data?.check_in;

    let forecastColor = getDailyCellStatus(status, data);
    let cellColor = getDailyCellColor(status);


    const displayPrice = (price, c) => withCurrency(formatData(price), currency ?? c);
    const displayPickup = (pickup) => formatData(pickup);
    const displayPickupWithPlusSign = (pickup) => {
        return usePercentsForOccs ? `${formatDataWithPlusSign(pickup)}%` : formatDataWithPlusSign(pickup);
    }

    const displayPace = (data) => {
        if (usePercentsForOccs) {
            let primaryDataKey = "pct_pace";
            if (calendar_data.pace_string === "averageroomsold") { primaryDataKey = "pct_avgdo" };
            if (calendar_data.pace_string === "averagedowroomsold") { primaryDataKey = "pct_avgdow" };
            return `${formatDataWithPlusSign(Math.round(data[primaryDataKey]))}%`;
        } else {
            let primaryDataKey = "pace";
            if (calendar_data.pace_string === "averageroomsold") { primaryDataKey = "average_occ" };
            if (calendar_data.pace_string === "averagedowroomsold") { primaryDataKey = "average_occ_dow" };
            return formatDataWithPlusSign(data[primaryDataKey]);
        }
    }

    const displayPaceAdr = (data) => {
        let secondaryDataKey = "pace_adr";
        if (calendar_data.pace_string === "averageroomsold") { secondaryDataKey = "average_adr" };
        if (calendar_data.pace_string === "averagedowroomsold") { secondaryDataKey = "average_adr_dow" };
        return ADRState ? withCurrency(formatData(data[secondaryDataKey]), currency ?? data.currency) : "";
    }

    const displayAdr = (adr, c) => ADRState ? withCurrency(formatData(adr), currency ?? c) : "";
    const displayAdrSlash = () => ADRState ? " / " : "";

    const displayTotalOccAndAdr = (total_occ, adr, c) => {
        return [
            {
                data: `${formatData(total_occ)}${usePercentsForOccs ? '%' : ''}${displayAdrSlash()}${displayAdr(adr, c)}`,
                label: "otb",
            },
        ]
    }

    const displayPickupAndPaceRates = (data) => {
        return [
            {
                data: withCurrency(formatData(data.pickup_rate), currency ?? data.currency),
                label: calendar_data.pick_up.substring(1) + " days",
            },
            {
                data: getPaceRate(calendar_data.pace_string, data, currency ?? data.currency),
                label: getPaceTitle(calendar_data.pace_string).replace("Rooms Sold", ""),
            },
        ]
    }

    const displayCompetitorPricing = (data) => {
        const items = [];
        items.push({
            data: getPaceRate(calendar_data.pace_string, data, currency ?? data.currency),
            label: getPaceTitle(calendar_data.pace_string).replace("Rooms Sold", ""),
        })

        if (userPrefs.enableLowestPriceCompetitor === true) {
            items.push({
                data: withCurrency(formatData(data._computed?.competitors?.lowest_price), currency ?? data.currency),
                label: "Low Comp"
            });
        } else {
            items.push({
                data: withCurrency(formatData(data._computed?.competitors?.average_price), currency ?? data.currency),
                label: "Ave Comp"
            });
        }
        
        return items;
    }


    let primary_label = "";
    let primary_data = "";
    let secondary_label = ADRState ? "adr" : "";
    let secondary_data = "";
    let tertiary_data = [];
    let individualColor = "--neutral";
    if (data) {
        switch (selectedTab) {
            case "overview":
                primary_label = "price";
                primary_data = displayPrice(data["rate"], data.currency);
                secondary_data = displayPickup(data["pickup"]);
                tertiary_data = displayTotalOccAndAdr(
                    usePercentsForOccs ? Math.round(data.pct_occ) : data.total_occ, data.adr, data.currency
                );
                break;
            case "rate":
                primary_label = "price";
                primary_data = displayPrice(data["rate"], data.currency);
                secondary_label = "pickup";
                tertiary_data = hasCompetitorPricing
                    ? displayCompetitorPricing(data) :displayPickupAndPaceRates(data);
                individualColor = data.individualColor.sell_rate;
                break;
            case "average_occ": // fallthrough to case pace
            case "average_occ_dow": // fallthrough to case pace
            case "pace":
                primary_label = "pace";
                primary_data = displayPace(data);
                secondary_data = displayPaceAdr(data);
                tertiary_data = displayTotalOccAndAdr(
                    usePercentsForOccs ? Math.round(data.pct_occ) : data.total_occ, data.adr, data.currency
                );
                individualColor = data.individualColor.pace;
                break;
            default:
                primary_label = "pickup";
                primary_data = displayPickupWithPlusSign(usePercentsForOccs ? Math.round(data.pct_pickup) : data["pickup"]);
                secondary_data = displayAdr(data["pickup_adr"], data.currency);
                tertiary_data = displayTotalOccAndAdr(
                    usePercentsForOccs ? Math.round(data.pct_occ) : data.total_occ, data.adr, data.currency
                );
                individualColor = data.individualColor.pick_up;
                break;
        }
        if (status === "disabled") { individualColor = "--neutral" }
    }

    const showTutorialSpot = (tab, spot, section, type) => {
        if (dayjs().format("DD") === date && selectedTab === tab && spot) {
            return <TutorialSpot section={section} type={type} left="-6px" bottom="66px" />
        }
    }

    const dataDiv = (style, label, data) => {
        return <>
            <div className={style}>
                <div>
                    <span>{label}</span>
                </div>
            </div>
            <span>{data}</span>
        </>
    }


    return (
        <div className={`relative-position-container`}>
            <div
                className={`cell__days ${cellColor} ${activeCell && cellKey == activeCell ? "selectedCell" : ""
                    } ${dayjs().format("DD") === date &&
                    `${selectedTab === "overview"
                        ? "currentDateTutorial currentDate2Tutorial currentDate3Tutorial"
                        : ""
                    } ${selectedTab === "pickup" ? "pickupDateTutorial" : ""} ${selectedTab === "pace" ? "paceDateTutorial" : ""
                    } ${selectedTab === "rate" ? "rateDateTutorial" : ""}`
                    }`}
                style={{
                    gridColumn: dayOfWeek,
                }}
                onClick={onClick}
            >

                {/* Tutorial Spots */}
                {showTutorialSpot("overview", tutorial.currentDate.showSpot, "overviewCalendar", "currentDate")}
                {showTutorialSpot("pickup", tutorial.pickupDate.showSpot, "overviewCalendar", "pickupDate")}
                {showTutorialSpot("pace", tutorial.paceDate.showSpot, "paceCalendar", "paceDate")}
                {showTutorialSpot("rate", tutorial.rateDate.showSpot, "paceCalendar", "rateDate")}

                {/* Date */}
                <div className="cell__date">{date} { 
                    data?.note_type && <NotesNotif type={ data.note_type } style={{ marginRight: '-2px' }} onClick={(e) => {
                        e.stopPropagation();
                        setNotesModalState({ 
                            visible: true,
                            startDate: data.check_in,
                            endDate: data.check_in
                        });
                    }} />
                }</div>

                {/* Cell Data */}
                <div className="cell__data-container">

                    {/* Overview */}
                    {data && selectedTab === "overview" && (
                        <div className="data">
                            <div className={`recommended-data`}>
                                <RecommendationIcon data={data} />
                            </div>

                            <div className="main-data" style={{ height: 33 }}>
                                {dataDiv("hidden-label", primary_label, primary_data)}
                            </div>

                            {tertiary_data &&
                                tertiary_data.map((item) => {
                                    const { data, label } = item;
                                    return (
                                        <div key={item.label} className="small-data">
                                            {dataDiv("hidden-label", label, data)}
                                        </div>
                                    );
                                })}
                        </div>
                    )}

                    {/* Pickup | Pace | Price */}
                    {data && selectedTab !== "overview" && (
                        <div className="data">
                            <div className={`main-data main-data--${individualColor}`}>
                                {dataDiv("hidden-label", primary_label, primary_data)}
                            </div>

                            {secondary_data && (
                                <div className="sub-data">
                                    {dataDiv("hidden-label", secondary_label, secondary_data)}
                                </div>
                            )}

                            {tertiary_data &&
                                tertiary_data.map((item, index) => {
                                    const { data, label } = item;
                                    return (
                                        <div key={index} className="small-data">
                                            {dataDiv("hidden-label", label, data)}
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>

                {/* Forecast Color */}
                <div className={`cell__status ${forecastColor}`} />
            </div>
        </div>
    );
};

export default DailyCell;
