/** @file Helper functions for room types */


import { isNullOrUndefined } from "./index";
import constants from "../constants";

const ALLROOMTYPES = constants.ALLROOMTYPES;



/**
 * Retrieves integration mappings with enabled room types status
 *
 * @function getMappingsWithEnabledRoomTypes
 * @param {[Object]} mapping List of all property integration mappings
 * @return {[Object]} List of integration mappings with enabled room types status
*/
export function getMappingsWithEnabledRoomTypes(mappings) {
    if (isNullOrUndefined(mappings)) return [];
    return mappings.filter((mapping) => {
        return !isNullOrUndefined(mapping.room_types_enabled) && (mapping.room_types_enabled === true)
    })
}


/**
 * Checks if there are any property integration mapping that has room types enabled
 *
 * @function hasRoomTypesEnabled
 * @param {[Object]} mapping List of all property integration mappings
 * @return {Boolean} Either there is an integration mapping that has room types enabled, or not.
*/
export function hasRoomTypesEnabled(mappings) {
    return Array.isArray(mappings) && (mappings.length > 0) && (getMappingsWithEnabledRoomTypes(mappings).length > 0);
}


/**
 * Retrieves list of property room types.
 * Does not matter whether room type has mapping to a specific partner code or not.
 *
 * @function getHotelRoomTypes
 * @param {[Object]} roomtypes List of all property room types
 * @return {[Object]} List of property room types mapped to a specific partner code
*/
export function getHotelRoomTypes(roomtypes, isGroup) {
    if (isNullOrUndefined(roomtypes)) return [];
    let hotelMappedRoomTypes = roomtypes.map((rt) => {
        return {
            local: rt.local,
            code: isGroup ? rt._id : rt.room_type_code,
            name: isGroup ? rt.name : rt.room_type,
            value: isGroup ? rt._id : rt.room_type_code
        }
    })
    hotelMappedRoomTypes = hotelMappedRoomTypes.filter(item => item !== undefined)

    let hasUMP = (hotelMappedRoomTypes.filter(rt => rt.code === "UMP")).length > 0
    if (!hasUMP) hotelMappedRoomTypes.push({ local: undefined, code: "UMP", name: "Unmapped Room", value: "UMP" });
    return hotelMappedRoomTypes;
}


/**
 * Retrieves list of property room types mapped to a specific partner code
 *
 * @function getHotelMappedRoomTypes
 * @param {[Object]} roomtypes List of all property room types
 * @param {String} partnerCode Partner code
 * @return {[Object]} List of property room types mapped to a specific partner code
*/
export function getHotelMappedRoomTypes(roomtypes, partnerCode) {
    if (isNullOrUndefined(roomtypes) || isNullOrUndefined(partnerCode)) return [];
    let hotelMappedRoomTypes = roomtypes.map((rt) => {
        const getPartnerRoomTypeMapping = (mappings) => {
            return mappings.filter((mapping) => {
                return !isNullOrUndefined(mapping.partner_code)
                    && mapping.partner_code.toLowerCase() === partnerCode.toLowerCase();
            })
        }
        let partnerRoomTypeMapping = getPartnerRoomTypeMapping(rt.mappings);
        let hasPartnerRoomTypeMapping = partnerRoomTypeMapping.length > 0;

        if (hasPartnerRoomTypeMapping) {
            return {
                local: rt.local,
                code: partnerRoomTypeMapping[0].room_type_code,
                name: rt.room_type,
                value: partnerRoomTypeMapping[0].room_type_code
            }
        }
    })
    hotelMappedRoomTypes = hotelMappedRoomTypes.filter(item => item !== undefined)

    let hasUMP = (hotelMappedRoomTypes.filter(rt => rt.code === "UMP")).length > 0
    if (!hasUMP) hotelMappedRoomTypes.push({ local: undefined, code: "UMP", name: "Unmapped Room", value: "UMP" });
    return hotelMappedRoomTypes;
}


/**
 * Checks if the current selected room type from the dashboard room type filter
 * is also in the list of room types mapped to the newly selected property
 *
 * @function isSelectedRoomTypeInActiveProperty
 * @param {String} selectedRoomType Current selected room type from dashboard segments filter
 * @param {Object} activeProperty Property object data currently in view
 * @return {Boolean} Current selected room type also exists in newly selected property
*/
export function isSelectedRoomTypeInActiveProperty(selectedRoomType, activeProperty) {
    if (isNullOrUndefined(selectedRoomType)) return false;
    if (selectedRoomType === ALLROOMTYPES) return false;

    let hotelMappings = activeProperty.mappings;
    if (hotelMappings.length === 0) return false;

    let hotelMappingsWithEnabledRoomTypes = getMappingsWithEnabledRoomTypes(hotelMappings);
    if (hotelMappingsWithEnabledRoomTypes.length === 0) return false;
    if (isNullOrUndefined(hotelMappingsWithEnabledRoomTypes[0].partner_code)) return false;

    let hasMappingWithRoomTypesEnabled = hasRoomTypesEnabled(hotelMappings);
    if (!hasMappingWithRoomTypesEnabled) return false;

    let hotelMappedRoomTypes = getHotelMappedRoomTypes(
        activeProperty.room_types,
        hotelMappingsWithEnabledRoomTypes[0].partner_code
    );
    let roomTypeMatch = hotelMappedRoomTypes.filter(item => item.local === selectedRoomType);
    return roomTypeMatch.length > 0;
}

/**
 * Version 2 of isSelectedRoomTypeInActiveProperty
 * Checks if the current selected room types from the dashboard room types filter
 * are also in the list of room types mapped to the newly selected property
 *
 * @function isRoomTypeInActiveProperty
 * @param {[String]} selectedRoomType selected room type from dashboard room types filter
 * @param {[Object]} hotelRoomTypes List of room types mapped to newly selected property
 * @param {[Object]} integrationPartners List of integration partners of newly selected property
 * @return {Boolean} Current selected room types also exists in newly selected property
*/
export function isRoomTypeInActiveProperty(selectedRoomType, activeProperty) {
    if (isNullOrUndefined(selectedRoomType) || isNullOrUndefined(activeProperty)) return false;

    try {
        const integrationPartners = activeProperty?.mappings;

        if (!(Array.isArray(integrationPartners) && integrationPartners.length > 0)) throw new Error("No integration partner found!");
        // The selected property integration partner is the first element of property.mappings[].
        const integrationPartner = integrationPartners[0];

        if (integrationPartner.room_types_enabled !== true) throw new Error(`Room types in Integration Partner: ${integrationPartner.partner_code} is not enabled!`);

        const propertyRoomTypes = activeProperty?.room_types;

        if (!(Array.isArray(propertyRoomTypes) && propertyRoomTypes.length > 0)) throw new Error(`No mapped room types found for Integration Partner: ${integrationPartner.partner_code}!`);

        // Check if the selected room type exists in the newly selected hotel
        for (let propertyRoomType of propertyRoomTypes) {
            if (propertyRoomType.mappings.some(mapping =>
                mapping.partner_code == integrationPartner.partner_code &&
                mapping.room_type_code == selectedRoomType
            )) return true;
        }

        return false;

    } catch (error) {
        // console.error(error);
        return false;
    }
}
