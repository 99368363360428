import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
// import moment from 'moment';
import dayjs from "dayjs";

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import EnlargedSimpleChart from '../SimpleChart/EnlargedSimpleChart';
import Waterfall from "elements/Chart/WaterFallChart";
import LineChart from "elements/Chart/LineChart";
import TableChart from "elements/Chart/TableChart";
import PieChart from "elements/Chart/PieChart";
import StackedChart from "elements/Chart/StackedChart";
import Switch from "../Switch/Switch";
import ReservationList from  "./components/ReservationsList/";
import { ExportReservationsButton } from "../ExportData";
import { NavWithIndicator } from "./components/StayDateNav";
import { Download } from "Icons";

import { useDownload } from "hooks/useDownload";
import { usePreferences } from 'hooks/usePreferences';
import useCalendar from "../Calendar/useCalendar";
import constants from "../../constants";
import { isNullOrUndefined, noTimezone, getPaceTitle, numberWithCommas } from "../../utils/index";
import { isSegmentGroupSelected } from "../../utils/segments";
import { getBookingChartDataToDisplay, getVisualSegmentsTableHeaders, 
    getVisualSegmentsData, getReservationsList, getVisualAsOfsData, 
    transformLeadTimeStackChartData, transformDayOfWeekStackChartData, 
    transformLeadTimeTableData, transformDayOfWeekTableData,
    visualsSegmentsTableHeaders } from "../../utils/visuals";
import { getSegmentsOtbTable, getSegmentsTableData } from "../../utils/visuals";
import { isNullish } from "utils/data";

/**
 * Visuals component. Displays a group of visual charts for segments and as-ofs.
 *
 * @function Visuals
 * @return {Component} Visuals component.
*/
const Visuals = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const dashboard = useSelector((state) => state.dashboard);
    const { variances, dailyData, weeklyData, monthlyData, loading } = dashboard;
    const { download, isPending } = useDownload();
    const { user, useCacheDashboardModal, usePercentsForOccs, showComparisons, showDownloadables } = usePreferences();
    const global = useSelector(state => state);
    const { property, calendar_data, sidebarData, sidebarChartData, sidebarChartDataIsLoading, 
        segmentsTotal, activeCalendarView, activeHotel, isGroup, isSegmentFeaturesEnabled, visualsAreDisplayed, 
        visualsTablesAreDisplayed, visualsSelectedTab, visualsSegmentsData, statisticsSegmentsData,
        visualsBookingChartSelectedDaysOut, 
        segmentsInView, propertyInView
    } = state;
    const { as_of, pick_up, pace_string, check_in_start, check_in_end, segments, roomtype } = calendar_data;
    const comparison = calendar_data.comparison
    const hideComparisons = (!showComparisons) || comparison === "NONE";
    const hasCompetitor = (propertyInView?.competitors_info?? []).length > 0;
    const hideDownloadables = true;
    const isSelectedSegmentGroup = isSegmentGroupSelected(calendar_data);

    const viewReservationsEnabled = isGroup
        ? (property?.properties?? []).every(_ => _?.reservations_translation_enabled === true)
        : (property?.reservations_translation_enabled === true?? false);


    // Defaults
    const getSegmentName = (calendar_data) => {

        if (calendar_data.segments_string === constants.ALLSEGMENTS) return constants.ALLSEGMENTS;
        if (calendar_data.segments_string === constants.UNSEGMENTED) return constants.UNSEGMENTED;
        if (calendar_data.segments_string === constants.COMBINED) return constants.COMBINED;

        let selectedSegment;

        if (calendar_data.segments[0].includes("GROUP-")) {
            selectedSegment = segmentsInView.segment_groups.find((item) => {
                return item.code === calendar_data.segments_string
            });
            return selectedSegment? selectedSegment.name: "";
        } else {
            selectedSegment = segmentsInView.segments.find((item) => {
                return item.code === calendar_data.segments_string 
            });
            return selectedSegment? selectedSegment.name: "";
        }
    }

    const getVisualDate = () => {
        return !isNullOrUndefined(sidebarData)
            ? !isNullOrUndefined(sidebarData.check_in)
                ? noTimezone(sidebarData.check_in)
                : sidebarData.week_range.toString().replace(/,/g, " to ")
            : new Date();
    }

    const getCacheId = () => {
        const isDaily = activeCalendarView === "daily" || activeCalendarView === "custom";
        const formatDate = (date) => dayjs(date).format("YYYYMMDD");
        const startDate = isDaily? sidebarData?.check_in: sidebarData?.dateRange[0];
        const endDate = isDaily? sidebarData?.check_in: sidebarData?.dateRange[1];
        return `${propertyInView.id}-` + 
            `${formatDate(as_of)}-${formatDate(startDate)}-${formatDate(endDate)}-` + 
            `${Math.abs(pick_up)}-${pace_string}-` + 
            `${segments.join(",")}-` +
            `${roomtype}`;
    }

    const getVisualTitle = (isTitle = true) => {
        return !isNullOrUndefined(sidebarData)
            ? sidebarData.type === "daily"
                ? isTitle
                    ? dayjs(visualDate).format("MMMM DD, YYYY")
                    : dayjs(visualDate).format("MMM DD, YYYY")
                : (sidebarData.type === "weekly")
                    ? `${dayjs(sidebarData.week_range[0]).format("MMM DD")}
                        to ${dayjs(sidebarData.week_range[1]).format("MMM DD")} ${sidebarData.year}`
                    : `${sidebarData.month} ${sidebarData.year}`
            : dayjs(new Date()).format("MMMM DD, YYYY");
    }

    const getVisualTabs = () => {
        if (isSegmentFeaturesEnabled || isGroup) {
            return viewReservationsEnabled
                ? ["booking-chart", "pickup", "pace", "price", "on-the-books", "others"]
                : ["booking-chart", "pickup", "pace", "price", "on-the-books"]
        }
        return ["booking-chart", "pickup", "pace", "price"];
    }

    const [subView, setSubView] = useState("occ");
    let visualTabs = getVisualTabs();
    let visualDate = getVisualDate();
    let visualPickup = Math.abs(calendar_data.pick_up);
    let visualPace = getPaceTitle(calendar_data.pace_string);
    let visualSegment = getSegmentName(calendar_data);
    let visualSubview = subView === "occ" ? "Occupancy" : "ADR";
    let visualTitle = getVisualTitle();
    let visualTableDate = getVisualTitle(false);
    let contentTitles = {
        pickup: `${visualTitle}, ${visualPickup} Days Pickup`,
        pace: `${visualTitle} vs. ${visualPace}`,
        price: `${visualTitle} Price vs. Pace Prices`,
        otb: `${visualTitle} Segmentation`,
        others: `${visualTitle} Reports`,
        table: visualTableDate,
        lines: {
            pickup: `${visualSegment} ${visualSubview} Variance ${visualPickup} Days Pickup`,
            pace: `${visualSegment} Pace ${visualSubview} Variance`
        },
        waterfall: {
            pickup: `Segmentation ${visualPickup} Days Pickup`,
            pace: `Segmentation vs. ${visualPace}`
        }
    }
    
    useEffect(() => {
        setVisualAsofsData(null);
        if (!isNullish(variances)) {
            setVisualAsofsData(getVisualAsOfsData(variances, contentTitles, comparison, hideComparisons, hasCompetitor));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variances, hideComparisons]);

    // Request for Visuals Data if User Selects a Check-in Date to View and Save that to a State-Cache
    // But if Data Has Been Previously Fetched then Use that State-Cached Data to Save User/Server Bandwidth
    const { getFilterSegments, getStatisticsSegments } = useCalendar();
    const [reservationsData, setReservationsData] = useState({ list: { otb: [], pickup: [] } , total: { } });
    // const [reservationsDataCache, setReservationsDataCache] = useState([]);
    const [reservationsLoading, setReservationsLoading] = useState(false);
    // const [visualSegmentsData, setVisualSegmentsData] = useState(null);
    // const [visualSegmentsLoading, setVisualSegmentsLoading] = useState(true);
    
    const [segmentsChartDataLoading, setSegmentsChartsDataLoading] = useState(true);
    const [segmentsChartData, setSegmentsChartsData] = useState(null);
    
    useEffect(() => {
        const cacheId = getCacheId();
        if (visualsAreDisplayed && sidebarData) {
            const inCacheItem = data => useCacheDashboardModal ? data.cache.find(item => item.id === cacheId) : null;

            // const inSegmentsCacheItem = inCacheItem(visualsSegmentsData);
            // if (!inSegmentsCacheItem) setVisualSegmentsLoading(true);
            // else setVisualSegmentsLoading(false);

            setSegmentsChartsDataLoading(true);
            setReservationsLoading(true);
            const segmentsList = getFilterSegments();
            const selectedSegments = isSelectedSegmentGroup? segments.map(s => s.replace(/^GROUP-/g, "")): segments;

            if (!inCacheItem(statisticsSegmentsData)) {
                // Load new data. Happens when date changed.
                getStatisticsSegments(viewReservationsEnabled, segmentsList.map(_ => _.id), cacheId).then(result => {
                    setSegmentsChartsData(getVisualSegmentsData(result, selectedSegments));
                    setReservationsData(getReservationsList(result, selectedSegments, isSelectedSegmentGroup));
                    setSegmentsChartsDataLoading(false);
                    setReservationsLoading(false);
                });
            } else {
                // Load data from cache
                const statisticsSegments = statisticsSegmentsData.cache.find(item => item.id === cacheId)?.value;
                setSegmentsChartsData(getVisualSegmentsData(statisticsSegments, selectedSegments));
                setReservationsData(getReservationsList(statisticsSegments, selectedSegments, isSelectedSegmentGroup));
                setSegmentsChartsDataLoading(false);
                setReservationsLoading(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualsAreDisplayed, sidebarData]);

    // useEffect(() => {
    //     if (!isSegmentFeaturesEnabled && !isGroup) return;
        
    //     const statisticsSegments = statisticsSegmentsData.cache.find(item => item.id === visualDate)?.value;
        

    //     setSegmentsChartsData(getVisualSegmentsData(statisticsSegments, selectedSegments));
    //     setReservationsData(getReservationsList(statisticsSegments, selectedSegments, isSelectedSegmentGroup));
    //     setSegmentsChartsDataLoading(false);
    // }, [statisticsSegmentsData]);

    // useEffect(() => {
    //     if (segmentsChartDataLoading === false) return;
    //     setSegmentsChartsData(null);
    // }, [segmentsChartDataLoading])

    // useEffect(() => {
    //     if (isSegmentFeaturesEnabled || isGroup) {
    //         let inCacheItem = visualsSegmentsData.cache.filter(item => item.id === visualDate);
    //         let data = inCacheItem.length > 0 ? inCacheItem[0].value : null;

    //         setVisualSegmentsData(data);
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [visualsSegmentsData]);

    const [visualAsofsData, setVisualAsofsData] = useState(null);
    // Booking Chart
    const [range1 = "", range2 = ""] = useMemo(() => {
        let range1 = "";
        let range2 = "";
        sidebarChartData.datasets.forEach((item) => {
            let dateRange = ` ${dayjs(item.rangeStart).format("MMM DD, YYYY")} ${item.rangeStart !== item.rangeEnd
                ? "to " + dayjs(item.rangeEnd).format("MMM DD, YYYY")
                : ""    
                }`;
            if (item.label.indexOf("Range 1") !== -1 && !range1) {
                range1 = dateRange;
            }
            if (activeCalendarView === "weekly") {
                dateRange = ` ${dayjs(item.rangeStart).format("MMM DD")} ${item.rangeStart !== item.rangeEnd
                    ? "to " + dayjs(item.rangeEnd).format("MMM DD, YYYY") + " "
                    : ""
                    }`;
            }
            if (activeCalendarView === "monthly") {
                range1 = dayjs(sidebarChartData.datasets[0].rangeStart).format("MMMM YYYY");
                dateRange = ` ${dayjs(item.rangeEnd).format("MMMM YYYY")} `;
            }
            if (item.label.indexOf("Range 2") !== -1 && !range2) {

                switch (calendar_data.pace_string) {
                    case "samedaylastweek":
                        range2 = "Same day last week ";
                        range2 += `( ${dateRange})`;
                        break;
                    case "samedaylastmonth":
                        range2 = "Same day last month ";
                        range2 += `( ${dateRange})`;
                        break;
                    case "averageroomsold":
                        range2 = "Average rooms sold ";
                        break;
                    case "averagedowroomsold":
                        range2 = "Average DOW rooms sold ";
                        break;
                    case "lastyear":
                        range2 = "LY ";
                        range2 += `(${dateRange})`;
                        break;
                    default:
                        range2 = "SDLY ";
                        range2 += `(${dateRange})`;
                        break;
                }
            }
        }); 
        return [range1, range2];
    }, [sidebarChartData]);

    const renderLoadingSpinner = () => {
        let copy = "Preparing your visuals. This will only take several seconds. Please wait ...";
        return <LoadingSpinner size="40px" text={copy} />
    }

    const isReadyToRender = () => {
        if (loading) return false;
        if (isNullOrUndefined(visualAsofsData)) return false;
        if (isSegmentFeaturesEnabled || isGroup) {
            // if (visualSegmentsLoading || isNullOrUndefined(visualSegmentsData)) return false;
            if (segmentsChartDataLoading || isNullOrUndefined(segmentsChartData)) return false;
        }
        return true;
    }

    useEffect(() => {
        if (readyToRender !== isReadyToRender()) setReadyToRender(isReadyToRender());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualAsofsData, segmentsChartDataLoading, loading])

    useEffect(() => {
        if (isReadyToRender()) {
            setLoadingSpinner(<></>);
            setReadyToRender(false);
            setTimeout(() => {
                setReadyToRender(true);
                setLoadingSpinner(renderLoadingSpinner());
            }, 0.001);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualsTablesAreDisplayed, subView, variances])

    const [readyToRender, setReadyToRender] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(renderLoadingSpinner());

    const renderTabs = () => {
        return (<div className="rms-modal-content-header" style={{ marginLeft: 20, paddingRight: 38 }}>
            {visualTabs.map((item, index) => {
                return (
                    <button key={index} onClick={() => dispatch({
                        type: constants.UPDATE_VISUALS_SELECTED_TAB, value: item
                    })} style={(visualsSelectedTab === item)
                        ? {
                            fontFamily: "Titillium Web", backgroundColor: "#fff", color: "#00c28d",
                            cursor: "pointer", borderRadius: 0, borderLeft: 0, borderRight: 0,
                            borderTop: 0, borderBottom: "2px solid #00c28d", padding: "0 0 4px 0",
                            margin: "4px 24px 4px 0", width: "max-content"
                        }
                        : {
                            fontFamily: "Titillium Web", backgroundColor: "#fff",
                            cursor: "pointer", border: "none", margin: "4px 24px 4px 0",
                            width: "max-content"
                        }
                    }>
                        {item.replace(/-/g, " ").toUpperCase()}
                    </button>
                )
            })}

            {(visualsSelectedTab !== "booking-chart" && 
                !(visualsSelectedTab === "pickup" && currentPUView === "reservations") &&
                !(visualsSelectedTab === "on-the-books" && currentOTBView === "reservations")
            ) &&
                <div style={{
                    display: "grid", placeItems: "center", gridTemplateColumns: "50% 50%",
                    fontFamily: "Titillium Web", backgroundColor: "#fff",
                    cursor: "pointer", border: "none", margin: "4px 0", padding: 0,
                    width: 90, float: "right"
                }}>
                    <span style={{
                        width: "max-content", fontSize: 13,
                        fontWeight: 600, textAlign: "right"
                    }}
                        onClick={() => {
                            dispatch({
                                type: constants.SHOW_VISUALS_TABLES,
                                value: !visualsTablesAreDisplayed
                            });
                        }}
                    >
                        TABLE
                    </span>
                    <Switch checked={visualsTablesAreDisplayed}
                        onChange={() => {
                            dispatch({
                                type: constants.SHOW_VISUALS_TABLES,
                                value: !visualsTablesAreDisplayed
                            });
                        }} />
                </div>}

            <NavWithIndicator disabled={!readyToRender}/>
            
            {(visualsSelectedTab === "booking-chart") &&
                <div style={{ float: "right" }}>
                    <span style={{
                        fontFamily: "Titillium Web", border: "none", margin: "4px 10px 4px 0",
                        width: "max-content", fontWeight: 600, fontSize: "13px"
                    }}>
                        DAYS OUT
                    </span>
                    {constants.DAYS_OUT_OPTIONS.map((option, index) => (
                        <button key={index}
                            onClick={() => {
                                dispatch({
                                    type: constants.UPDATE_VISUALS_BOOKING_CHART_SELECTED_DAYS_OUT,
                                    value: option.days_out
                                });
                            }}
                            style={(visualsBookingChartSelectedDaysOut === option.days_out)
                                ? {
                                    fontFamily: "Titillium Web", backgroundColor: "#fff", color: "#00c28d",
                                    cursor: "pointer", borderRadius: 0, borderLeft: 0, borderRight: 0,
                                    borderTop: 0, borderBottom: "2px solid #00c28d", padding: "0 0 4px 0",
                                    margin: "4px 6px 4px 0", width: "max-content"
                                }
                                : {
                                    fontFamily: "Titillium Web", backgroundColor: "#fff",
                                    cursor: "pointer", border: "none", margin: "4px 6px 4px 0",
                                    width: "max-content"
                                }
                            }>{option.days_out}
                        </button>
                    ))}
                </div>}

            {showDownloadables && !hideDownloadables && (
                <div style={{ float: "right", marginTop: 4, marginRight: 12 }}>
                    <Download width="24px" height="24px" className={isPending ? "" : "pointer"}
                        style={{ margin: 'auto 20px', filter: isPending ? 'grayscale(100%)' : undefined }}
                        onClick={() => {
                            const cin = sidebarData.type === "daily"
                                ? dayjs(sidebarData.check_in).format("MMMM DD, YYYY")
                                : (sidebarData.type === "weekly")
                                    ? `${dayjs(sidebarData.week_range[0]).format("MMM DD")} to `
                                    + `${dayjs(sidebarData.week_range[1]).format("MMM DD, YYYY")}`
                                    : `${sidebarData.month} ${sidebarData.year}`;
                            const pdf = {
                                property: activeHotel, asof: as_of, pickup: pick_up, pace: pace_string,
                                ss: segments, rts: roomtype, cin: cin
                            };

                            const property = activeHotel.toLowerCase().replace(/,/g, '').replace(/ /g, '-');
                            const title = cin.toLowerCase().replace(/,/g, '').replace(/ /g, '-');
                            const filename = `${property}-${visualsSelectedTab}-report-${title}`;
                            download(filename, 'pdf', `visual-${visualsSelectedTab}`, pdf);
                        }}
                    />
                </div>
            )}
        </div >)
    }


    const renderBookingChart = () => {
        if (visualsSelectedTab !== "booking-chart") return;
        const titleOtb = sidebarData?.week_range && activeCalendarView === "weekly"
            ? `${(
                sidebarData.week_range &&
                `${dayjs(sidebarData.week_range[0]).format("MMM DD")}
                                to ${dayjs(sidebarData.week_range[1]).format("MMM DD")}`
            ) || sidebarData.month} ${sidebarData.year}`
            : range1;
        const titlePace = ` vs ${range2}`;
        const titleCmp = hideComparisons ? '' : ` vs ${comparison} (${titleOtb})`;
        const title = `${titleOtb}${titlePace}${titleCmp}`;

        return (
            <div id="visual-booking-chart">
                <div className="rms-dashboard-modal-header">
                    <h3 style={{ marginTop: '16px', color: "#282F5B" }}>{title}</h3>
                </div>
                <EnlargedSimpleChart
                    data={getBookingChartDataToDisplay(sidebarChartData, visualsBookingChartSelectedDaysOut, sidebarData?.days_out)}
                    filter={segmentsTotal}
                    type={activeCalendarView}
                />
            </div>
        )
    }


    const renderSubViewLink = () => {
        return (
            <div data-html2canvas-ignore
                style={{ display: "grid", placeItems: "flex-end", marginTop: visualsTablesAreDisplayed ? 8 : 20 }}
            >
                <button onClick={() => {
                    setSubView(subView === "occ" ? "adr" : "occ");
                    setVisualAsofsData(visualAsofsData);
                }}
                    style={{
                        backgroundColor: "#fff", color: "#00c28d", fontSize: 12, width: "max-content",
                        fontFamily: "Lato", cursor: "pointer", textDecoration: "underline", border: "none",
                        marginRight: (isSegmentFeaturesEnabled || isGroup) ? null : 130
                    }}>
                    {subView === "occ" ? "View ADR" : "View Occupancy"}
                </button>
            </div>
        )
    }

    const [leadTimeTableView, setLeadTimeTableView] = useState({ 
        RN: { visible: true }, 
        revenue: { visible: true },
        ADR: { visible: false }, 
    });
    const [dayOfWeekTableView, setDayOfWeekTableView] = useState({
        RN: { visible: true }, 
        revenue: { visible: true },
        ADR: { visible: false }, 
    });

    const [currentPUView, setCurrentPUView] = useState("statistics");
    const [currentOTBView, setCurrentOTBView] = useState("statistics");

    const renderViewReservationsLink = (type) => {
        const isDailyBeyondPastDate = sidebarData?.type === "daily" && 
            dayjs(sidebarData?.check_in).startOf("day").diff(dayjs(as_of).startOf("day"), "days") < pick_up;
        const noReservationsData = type == "otb"
            ? (reservationsData.list?.otb?? []).length === 0
            : (reservationsData.list?.pickup?? []).length === 0;
        const isDisabled = noReservationsData || reservationsLoading || (type == "pickup" && isDailyBeyondPastDate);
        const onClick = () => {
            if (noReservationsData === 0) return;
            if (type === "pickup") setCurrentPUView("reservations");
            if (type === "otb") setCurrentOTBView("reservations")
        }
        return (
            <div data-html2canvas-ignore
                style={{ display: "grid", placeItems: "flex-end", marginTop: visualsTablesAreDisplayed ? 8 : 20 }}
            >
                <button disabled={isDisabled} onClick={onClick} style={{
                    backgroundColor: "#fff", color: isDisabled? "#d2d2d2": "#00c28d", fontSize: 12, width: "max-content",
                    fontFamily: "Lato", cursor: isDisabled? "not-allowed": "pointer", textDecoration: "underline", border: "none",
                    marginRight: (isSegmentFeaturesEnabled || isGroup) ? "3px" : 130,
                }}>{ reservationsLoading ? "Loading Reservations..." : "View Reservations" }</button>
            </div>
        )
    }

    const renderViewStatisticsLink = (type) => {
        const onClick = (e) => {
            if (type === "pickup") setCurrentPUView("statistics");
            if (type === "otb") setCurrentOTBView("statistics");
        }
        return <button onClick={onClick} style={{
            backgroundColor: "#fff", color: "#00c28d", fontSize: 14, width: "max-content",
            fontFamily: "Lato", cursor: "pointer", textDecoration: "underline", border: "none", 
            // justifySelf: "flex-end"
        }}>Back</button>
    }

    const renderPickup = () => {
        if (visualsSelectedTab !== "pickup") return;
        if (!readyToRender) return loadingSpinner;
        return <div id="visual-pickup" style={{ width: "min-content", placeSelf: "center" }}>
            <div className="rms-dashboard-modal-header">
                <h3 style={{ marginTop: '16px', color: "#282F5B" }}>
                    {contentTitles.pickup}
                </h3>
            </div>
            {/* Statistics View */}
            {currentPUView === "statistics" && <>
                <div style={{
                    display: "grid", gridAutoFlow: "column", justifyItems: "center", gap: "50px"
                }}>
                    {(isSegmentFeaturesEnabled || isGroup) && <div>
                        <Waterfall chartName="dashboard_pickup" data={segmentsChartData?.pickup}
                            width={500} height={visualsTablesAreDisplayed ? 280 : 470}
                            xTitle={isSelectedSegmentGroup ? 'Segment Groups' : 'Segment'}
                            title={contentTitles.waterfall.pickup.toUpperCase()} yTitle="Pickup" />
                        {visualsTablesAreDisplayed && <div style={{ marginTop: 32 }}>
                            <TableChart isSortable={true}
                                headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.pickup, isSelectedSegmentGroup)}
                                data={getSegmentsTableData(segmentsChartData?.pickup?? [])}
                                rowIndecesWithCurrency={[1, 2]} rowNameWidth={150} rowDataWidth={115}
                                isPercent={isGroup && usePercentsForOccs}
                            />
                        </div>}
                        {viewReservationsEnabled && renderViewReservationsLink("pickup")}
                    </div>}
                    {subView === "occ"
                        ? <div>
                            {visualAsofsData && <LineChart chartName="dashboard_pickup_occ"
                                data={visualAsofsData.pickup_occ.line} yTitle="Pickup Occupancy Variance"
                                lines={hideComparisons
                                    ? ["OTB vs. LY", "OTB vs. SDLY", "OTB vs. AVG Pickup", "OTB vs. AVG DOW Pickup"]
                                    : ["OTB vs. LY", "OTB vs. SDLY", "OTB vs. AVG Pickup", "OTB vs. AVG DOW Pickup", `OTB vs. ${comparison}`]
                                }
                                width={(isSegmentFeaturesEnabled || isGroup) ? 500 : 800}
                                height={visualsTablesAreDisplayed ? 280 : 440}
                                title={contentTitles.lines.pickup.toUpperCase()}
                                isPercent={variances?.usePercents ?? false} />}
                            {visualsTablesAreDisplayed && visualAsofsData && <div style={{ marginTop: 32 }}>
                                <TableChart headers={["Days Out", ...variances?.douts ?? ""]}
                                    data={visualAsofsData.pickup_occ.table}
                                    width={(isSegmentFeaturesEnabled || isGroup) ? null : 800}
                                    isPercent={variances?.usePercents ?? false}
                                />
                            </div>}
                            {(isSegmentFeaturesEnabled || isGroup) && renderSubViewLink()}
                        </div>
                        : <div>
                            {visualAsofsData && <LineChart chartName="dashboard_pickup_adr"
                                data={visualAsofsData.pickup_adr.line} yTitle="Pickup ADR Variance"
                                lines={hideComparisons
                                    ? ["OTB vs. LY ADR", "OTB vs. SDLY ADR", "OTB vs. AVG ADR", "OTB vs. AVG DOW ADR"]
                                    : ["OTB vs. LY ADR", "OTB vs. SDLY ADR", "OTB vs. AVG ADR", "OTB vs. AVG DOW ADR", `OTB vs. ${comparison} ADR`]}
                                hasCurrency={true}
                                width={(isSegmentFeaturesEnabled || isGroup) ? 500 : 800}
                                height={visualsTablesAreDisplayed ? 280 : 440}
                                title={contentTitles.lines.pickup.toUpperCase()} />}
                            {visualsTablesAreDisplayed && visualAsofsData && <div style={{ marginTop: 32 }}>
                                <TableChart headers={["Days Out", ...variances?.douts ?? ""]}
                                    data={visualAsofsData.pickup_adr.table} hasCurrency={true}
                                    width={(isSegmentFeaturesEnabled || isGroup) ? null : 800} />
                            </div>}
                            {(isSegmentFeaturesEnabled || isGroup) && renderSubViewLink()}
                        </div>
                    }
                </div>
                {!(isSegmentFeaturesEnabled || isGroup) && <div>
                    {renderSubViewLink()}
                </div>}
            </>}
            {/* Reservations View */}
            {viewReservationsEnabled && <>
                {currentPUView === "reservations" && 
                <div style={{ display: "grid", justifyContent: "center", marginTop: "10px", width: "max-content" }}>
                    {!reservationsLoading && <>
                        <div style={{ display: "block", overflow: "auto", maxHeight: "calc(100vh - 320px)" }}>
                            <ReservationList data={reservationsData.list?.pickup?? []}/>
                        </div>
                        <span style={{ justifySelf: "flex-end", marginTop: "10px", display: "flex", alignItems: "center" }}>
                            <span>Total Rooms: </span>
                            <span style={{ fontWeight: "600", marginRight: "10px" }}>{numberWithCommas(reservationsData.total?.pickup_occ)}</span>
                            {renderViewStatisticsLink("pickup")}
                            <ExportReservationsButton className="pointer" data={reservationsData.list?.pickup?? []} />
                        </span>
                    </>}
                    
                    {reservationsLoading && <>
                        <LoadingSpinner size="40px" text="Loading reservations. Please wait ..." />
                        {renderViewStatisticsLink("pickup")}
                    </>}
                </div>}
            </>}
        </div>
    }


    const renderPace = () => {
        if (visualsSelectedTab !== "pace") return;
        if (!readyToRender) return loadingSpinner;

        return <div id="visual-pace" style={{ width: "min-content", placeSelf: "center" }}>
            <div className="rms-dashboard-modal-header">
                <h3 style={{ marginTop: '16px', color: "#282F5B" }}>
                    {contentTitles.pace}
                </h3>
            </div>
            <div style={{
                display: "grid", gridAutoFlow: "column", justifyItems: "center", gap: "50px"
            }}>
                {(isSegmentFeaturesEnabled || isGroup) && <div>
                    <Waterfall chartName="dashboard_pace" data={segmentsChartData?.pace}
                        width={500} height={visualsTablesAreDisplayed ? 280 : 470}
                        xTitle={isSelectedSegmentGroup ? 'Segment Groups' : 'Segment'}
                        title={contentTitles.waterfall.pace.toUpperCase()} yTitle="Pace" />
                    {visualsTablesAreDisplayed && visualAsofsData && <div style={{ marginTop: 32 }}>
                        <TableChart isSortable={true}
                            headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.pace, isSelectedSegmentGroup)}
                            data={getSegmentsTableData(segmentsChartData?.pace)}
                            rowIndecesWithCurrency={[1, 2]} rowNameWidth={140} hasFlexWidth={true}
                            isPercent={isGroup && usePercentsForOccs}
                        />
                    </div>}
                </div>}
                {subView === "occ"
                    ? <div>
                        {visualAsofsData && <LineChart chartName="dashboard_pace_occ"
                            data={visualAsofsData.pace_occ.line} yTitle="Pace Occupancy Variance"
                            lines={hideComparisons
                                ? ["OTB vs. LY", "OTB vs. SDLY", "OTB vs. AVG Rooms Sold", "OTB vs. AVG DOW Rooms Sold"]
                                : ["OTB vs. LY", "OTB vs. SDLY", "OTB vs. AVG Rooms Sold", "OTB vs. AVG DOW Rooms Sold", `OTB vs. ${comparison}`]
                            }
                            width={(isSegmentFeaturesEnabled || isGroup) ? 500 : 800}
                            height={visualsTablesAreDisplayed ? 280 : 440}
                            title={contentTitles.lines.pace.toUpperCase()}
                            isPercent={variances?.usePercents ?? false} />}
                        {visualsTablesAreDisplayed && visualAsofsData && <div style={{ marginTop: 32 }}>
                            <TableChart headers={["Days Out", ...variances?.douts ?? ""]}
                                data={visualAsofsData.pace_occ.table}
                                width={(isSegmentFeaturesEnabled || isGroup) ? null : 800}
                                isPercent={variances?.usePercents ?? false}
                            />
                        </div>}
                        {(isSegmentFeaturesEnabled || isGroup) && renderSubViewLink()}
                    </div>
                    : <div>
                        {visualAsofsData && <LineChart chartName="dashboard_pace_adr"
                            data={visualAsofsData.pace_adr.line} yTitle="Pace ADR Variance"
                            lines={hideComparisons
                                ? ["OTB vs. LY ADR", "OTB vs. SDLY ADR", "OTB vs. AVG ADR", "OTB vs. AVG DOW ADR"]
                                : ["OTB vs. LY ADR", "OTB vs. SDLY ADR", "OTB vs. AVG ADR", "OTB vs. AVG DOW ADR", `OTB vs. ${comparison} ADR`]
                            }
                            hasCurrency={true}
                            width={(isSegmentFeaturesEnabled || isGroup) ? 500 : 800}
                            height={visualsTablesAreDisplayed ? 280 : 440}
                            title={contentTitles.lines.pace.toUpperCase()} />}
                        {visualsTablesAreDisplayed && <div style={{ marginTop: 32 }}>
                            <TableChart headers={["Days Out", ...variances?.douts ?? ""]}
                                data={visualAsofsData.pace_adr.table} hasCurrency={true}
                                width={(isSegmentFeaturesEnabled || isGroup) ? null : 800} />
                        </div>}
                        {(isSegmentFeaturesEnabled || isGroup) && renderSubViewLink()}
                    </div>
                }
            </div>
            {!(isSegmentFeaturesEnabled || isGroup) && <div>
                {renderSubViewLink()}
            </div>}
        </div>
    }


    const renderPrice = () => {
        if (visualsSelectedTab !== "price") return;
        if (!readyToRender) return loadingSpinner;
        
        const defaultWidth = hasCompetitor? 550: 700;
        
        const renderPropertyPricing = () => {
            return <div id="visual-property-price">
                <div className="rms-dashboard-modal-header" style={{ zIndex: 1 }}>
                    <h3 style={{ marginTop: '16px', color: "#282F5B" }}>
                        {contentTitles.price}
                    </h3>
                </div>
                <div style={{ display: "grid", placeItems: "center" }}>
                    <div style={{ marginLeft: 20 }}>
                        {visualAsofsData && <LineChart chartName="dashboard_price" data={visualAsofsData.rate.line}
                            yTitle="Price Variance" width={defaultWidth} height={visualsTablesAreDisplayed ? 280 : 440}
                            lines={hideComparisons
                                ? ["OTB vs. LY Price", "OTB vs. SDLY Price", "OTB vs. AVG Price", "OTB vs. AVG DOW Price"]
                                : ["OTB vs. LY Price", "OTB vs. SDLY Price", "OTB vs. AVG Price", "OTB vs. AVG DOW Price", `OTB vs. ${comparison} Price`]
                            }
                            hasCurrency={true} />}
                    </div>
                    {visualsTablesAreDisplayed && <br />}
                    {visualsTablesAreDisplayed && visualAsofsData && <div style={{ marginTop: 12 }}>
                        <TableChart width={defaultWidth} headers={["Days Out", ...variances?.douts ?? ""]}
                            data={visualAsofsData.rate.table} hasCurrency={true} />
                    </div>}
                </div>
            </div>
        }

        const renderCompetitorPricing = () => {
            return <div id="visual-competitor-price">
                <div className="rms-dashboard-modal-header" style={{ zIndex: 1 }}>
                    <h3 style={{ marginTop: '16px', color: "#282F5B" }}>
                        {contentTitles.price}
                    </h3>
                </div>
                <div style={{ display: "grid", placeItems: "center" }}>
                    <div style={{ marginLeft: 20 }}>
                        {visualAsofsData && <LineChart chartName="dashboard_compricing" data={visualAsofsData.compricing.line}
                            yTitle="Price Variance" width={defaultWidth} height={visualsTablesAreDisplayed ? 280 : 440}
                            lines={visualAsofsData.compricing.line_columns} colors={visualAsofsData.compricing.line_colors}
                            hasCurrency={true} />}
                    </div>
                    {visualsTablesAreDisplayed && <br />}
                    {visualsTablesAreDisplayed && visualAsofsData && <div style={{ marginTop: 12 }}>
                        <TableChart width={defaultWidth} headers={["Days Out", ...variances?.douts ?? ""]}
                            data={visualAsofsData.compricing.table} hasCurrency={true} />
                    </div>}
                </div>
            </div>
        }

        const renderPriceVisuals = () => {
            if (hasCompetitor) {
                return (
                <div style={{display: "grid", gridAutoFlow: "column", justifyItems: "center", gap: "50px" }}>
                    {renderPropertyPricing()}
                    {renderCompetitorPricing()}
                </div>)
            }
            return renderPropertyPricing()
        }

        return <div id="visual-price" style={{ width: "min-content", placeSelf: "center" }}>
            {renderPriceVisuals()}
        </div>
    }

    const renderOnTheBooks = () => {
        let pieRadius = visualsTablesAreDisplayed ? "340px" : "480px";
        if (visualsSelectedTab !== "on-the-books") return;
        if (!isSegmentFeaturesEnabled && !isGroup) return;
        if (!readyToRender) { return loadingSpinner; }

        return <div id="visual-on-the-books" style={{ width: "min-content", placeSelf: "center" }}>
            <div className="rms-dashboard-modal-header">
                <h3 style={{ marginTop: '16px', color: "#282F5B" }}>
                    {contentTitles.otb}
                </h3>
            </div>
            {currentOTBView === "statistics" && <>
                <div style={{ display: "grid", placeItems: "center" }}>
                    <div>
                        <div style={{ display: "grid", placeItems: "center", gridTemplateColumns: "50% 50%" }}>
                            <PieChart chartName="dashboard_otb_occ"
                                data={segmentsChartData?.otb_occ}
                                radius={pieRadius} title="Rooms" />
                            <PieChart chartName="dashboard_otb_rev"
                                data={segmentsChartData?.otb_rev}
                                radius={pieRadius} title="Revenue" />
                        </div>
                        {visualsTablesAreDisplayed && <div style={{ zIndex: 11, marginTop: -30 }}>
                            <TableChart 
                                headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.otb, isSelectedSegmentGroup)}
                                data={getSegmentsOtbTable(segmentsChartData?.otb_occ, segmentsChartData?.otb_rev)}
                                isSortable={true} rowIndecesWithCurrency={[2, 4]} rowNameWidth={180} rowDataWidth={100}
                            />
                        </div>}
                        {viewReservationsEnabled && renderViewReservationsLink("otb")}
                    </div>
                </div>
            </>}
            {/* Reservations View */}
            {viewReservationsEnabled && <>
                {currentOTBView === "reservations" && 
                <div style={{ display: "grid", justifyContent: "center", marginTop: "10px", width: "max-content" }}>
                    {!reservationsLoading && <>
                        <div style={{ display: "block", overflow: "auto", maxHeight: "calc(100vh - 320px)" }}>
                            <ReservationList data={reservationsData.list?.otb?? []}/>
                        </div>
                        <span style={{ justifySelf: "flex-end", marginTop: "10px", display: "flex", alignItems: "center" }}>
                            <span>Total Rooms: </span>
                            <span style={{ fontWeight: "600", marginRight: "10px" }}>{numberWithCommas(reservationsData.total?.otb_occ)}</span>
                            {renderViewStatisticsLink("otb")}
                            <ExportReservationsButton data={reservationsData.list?.otb?? []} />
                        </span>
                    </>}
                    
                    {reservationsLoading && <>
                        <LoadingSpinner size="40px" text="Loading reservations. Please wait ..." />
                        {renderViewStatisticsLink("otb")}
                    </>}
                </div>}
            </>}
            
        </div>
    }

    

    const renderOthers = () => {
        if (!isSegmentFeaturesEnabled && !isGroup) return;
        if (visualsSelectedTab !== "others") return;
        if (!readyToRender) { return loadingSpinner; }
        
        return <div className="visual-others" style={{ width: "min-content", placeSelf: "center" }}>
            <div className="rms-dashboard-modal-header">
                <h3 style={{ marginTop: '16px', color: "#282F5B" }}>
                    {contentTitles.others}
                </h3>
            </div>
            <div style={{ display: "grid", gridAutoFlow: "column", justifyItems: "center", gap: "50px" }}>
                <div style={{ display: "grid", placeItems: "center", gridTemplateRows: "320px 1fr" }}>
                    <StackedChart
                        mainTitle="Lead Time"
                        data={transformLeadTimeStackChartData(segmentsChartData?.otb_lead_time)}
                        colors={["#00c28d"]}
                        width={500}
                        height={250}
                    />
                    {visualsTablesAreDisplayed && segmentsChartData !== null && <div style={{ zIndex: 11 }}>
                        {leadTimeTableView.RN.visible === true && <TableChart 
                            headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.otb_lead_time, isSelectedSegmentGroup)}
                            data={transformLeadTimeTableData(segmentsChartData?.otb_lead_time, "res")}
                            isSortable={true} rowNameWidth={140} rowDataWidth={60}
                        />}
                        {leadTimeTableView.ADR.visible === true && <TableChart 
                            headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.otb_lead_time, isSelectedSegmentGroup)}
                            data={transformLeadTimeTableData(segmentsChartData?.otb_lead_time, "adr", false)}
                            isSortable={true} rowNameWidth={140} rowDataWidth={60} rowIndecesWithCurrency={[0, 1, 2, 3, 4, 5]}
                        />}
                        {leadTimeTableView.revenue.visible === true && <TableChart 
                            headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.otb_lead_time, isSelectedSegmentGroup)}
                            data={transformLeadTimeTableData(segmentsChartData?.otb_lead_time, "total_rev")}
                            isSortable={true} rowNameWidth={140} rowDataWidth={60}
                        />}
                        <button 
                            onClick={() => {
                                const view = { ...leadTimeTableView };
                                view.ADR.visible = !view.ADR.visible;
                                view.revenue.visible = !view.revenue.visible;
                                setLeadTimeTableView({ ...view });
                            }}
                            style={{
                                backgroundColor: "#fff", color: "#00c28d", fontSize: 12, width: "max-content",
                                fontFamily: "Lato", cursor: "pointer", textDecoration: "underline", border: "none",
                                marginRight: (isSegmentFeaturesEnabled || isGroup) ? null : 130, float: "right"
                            }}>
                            {leadTimeTableView.ADR.visible? "View Revenue" : "View ADR"}
                        </button>
                    </div>}
                </div>
                
                {activeCalendarView === "monthly" && <div style={{ display: "grid", placeItems: "center", gridTemplateRows: "320px 1fr" }}>
                    <StackedChart
                        mainTitle="Day-of-Week"
                        data={transformDayOfWeekStackChartData(segmentsChartData?.otb_day_of_week)}
                        colors={["#00c28d"]}
                        width={500}
                        height={250}
                    />
                    {visualsTablesAreDisplayed && segmentsChartData !== null && <div style={{ zIndex: 11 }}>
                        {dayOfWeekTableView.RN.visible && <TableChart 
                            headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.otb_day_of_week, isSelectedSegmentGroup)}
                            data={transformDayOfWeekTableData(segmentsChartData?.otb_day_of_week, "total_occ")}
                            isSortable={true} rowNameWidth={140} rowDataWidth={60}
                        />}
                        {dayOfWeekTableView.ADR.visible && <TableChart 
                            headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.otb_day_of_week, isSelectedSegmentGroup)}
                            data={transformDayOfWeekTableData(segmentsChartData?.otb_day_of_week, "adr", false)}
                            isSortable={true} rowNameWidth={140} rowDataWidth={60} rowIndecesWithCurrency={[0, 1, 2, 3, 4, 5, 6]}
                        />}
                        {dayOfWeekTableView.revenue.visible && <TableChart 
                            headers={getVisualSegmentsTableHeaders(visualsSegmentsTableHeaders.otb_day_of_week, isSelectedSegmentGroup)}
                            data={transformDayOfWeekTableData(segmentsChartData?.otb_day_of_week, "total_rev")}
                            isSortable={true} rowNameWidth={140} rowDataWidth={60}
                        />}
                        <button 
                            onClick={() => {
                                const view = { ...dayOfWeekTableView };
                                view.ADR.visible = !view.ADR.visible;
                                view.revenue.visible = !view.revenue.visible;
                                setDayOfWeekTableView({ ...view });
                            }}
                            style={{
                                backgroundColor: "#fff", color: "#00c28d", fontSize: 12, width: "max-content",
                                fontFamily: "Lato", cursor: "pointer", textDecoration: "underline", border: "none",
                                marginRight: (isSegmentFeaturesEnabled || isGroup) ? null : 130, float: "right"
                            }}>
                            {dayOfWeekTableView.ADR.visible? "View Revenue" : "View ADR"}
                        </button>
                    </div>}
                </div>}
            </div>
        </div>
    }

    return (
        <div className="rms-modal-content">
            {renderTabs()}
            {renderBookingChart()}
            {renderPickup()}
            {renderPace()}
            {renderPrice()}
            {renderOnTheBooks()}
            {renderOthers()}
        </div>
    )

}

export default Visuals;
