import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Checkbox } from "antd";
import { ToastsStore } from "react-toasts";
import constants from "../../constants";
import { sortArray } from "../../utils/data";
import usePropertyActions from "../../store/actions/propertiesActions";
import useAdminConsoleActions from "../../store/actions/adminConsoleActions";
import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";


const BookingSourcesModal = ({ visible, bookingSourcesMasterList, partnerMappings, onCancel = null, onSubmit = null }) => {
    const state = useSelector((state) => state.mainReducer);
    const { listItemInView, propertyInView } = state;
    const { retrievePartnerMappings, updatePartnerMappings } = usePropertyActions();
    const { getChannel } = useAdminConsoleActions();
    const [integrationPartner, setIntegrationPartner] = useState();
    const [bookingSources, setBookingSources] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (visible !== true ) {
            setError("");
            return;
        }

        setLoading(true);

        getChannel(listItemInView?.partner_code).then((channelResult) => {
            const channel = channelResult.data.data;

            setIntegrationPartner(channel);
            retrievePartnerMappings(propertyInView?._id).then((res) => {
                try {
                    if (res.type == "GET_PROPERTY_PARTNER_MAPPINGS_SUCCESS") {
                        if (Array.isArray(res.response.data?.data)) {
                            const mappings = res.response.data?.data;
                            const selectedPartner = mappings.find(mapping => mapping.partner_code == listItemInView?.partner_code);
    
                            if (!selectedPartner) throw new Error(`Channel:${listItemInView?.partner_code} is not mapped in this property.`);
                            // Combination of Channel and Property's Integration Partner booking sources.
                            // We need the booking sources master list(predefined list) data to get the names assigned in the channel's booking sources.
                            setBookingSources(sortArray(channel.booking_sources.map(channelBookingSource => {
                                let bookingSourceMaster = bookingSourcesMasterList.find(bookingSourceMaster => 
                                    bookingSourceMaster._id == channelBookingSource.booking_source_id
                                );
                                return {
                                    master_id: bookingSourceMaster?._id,
                                    master_name: bookingSourceMaster?.source,
                                    channel_source_id: channelBookingSource?.channel_source_id,
                                    isSelected: selectedPartner.booking_sources.includes(bookingSourceMaster?._id)
                                }
                            }), "master_name"));
                        } else {
                            throw new Error("Property mappings has malformed data.");
                        }
                    } else {
                        throw new Error("Failed to retrieve property mappings.");
                    }
                } catch(error) {
                    setError("Failed to retrieve property mappings");
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });
        });
        
    }, [visible]);

    useEffect(() => {

    }, []);

    const handleBSOnChange = (key, checked) => {
        setBookingSources(bookingSources.map(bookingSource => {
            return bookingSource.master_id == key
                ? {...bookingSource, isSelected: checked}
                : bookingSource;
        }));
    }

    const handleModalCancel = () => {
        onCancel();
        // resetModal();
    }

    const handleModalSubmit = () => {
        try {
            if (error) throw new Error(error);
            setSaving(true);
            retrievePartnerMappings(propertyInView?._id).then((res) => {
                if (res.type === constants.GET_PROPERTY_PARTNER_MAPPINGS_SUCCESS) {
                    const partnerMappings = res.response.data?.data;
                    if (!Array.isArray(res.response.data?.data)) throw new Error("Property mappings has malformed data.");
                    const partnerMappingIndex = partnerMappings.findIndex((mapping) => mapping.partner_code == integrationPartner._id);
                    if (partnerMappingIndex > -1) {
                        partnerMappings[partnerMappingIndex].booking_sources = bookingSources
                            .filter((bookingSource) => bookingSource.isSelected === true)
                            .map((bookingSource) => bookingSource.master_id);

                        updatePartnerMappings(propertyInView._id, {
                            op: "edit",
                            filter: {
                                before: listItemInView,
                                after: partnerMappings[partnerMappingIndex],
                            }
                        }).then((res) => {
                            if (res.type === constants.UPDATE_PROPERTY_PARTNER_MAPPINGS_SUCCESS) {
                                onSubmit();
                                ToastsStore.success('Booking sources successfuly updated');
                            } else {
                                ToastsStore.error('Failed to update booking sources');
                            }
                            setSaving(false);
                        });
                    } else {
                        throw new Error(`Integration Partner: ${integrationPartner._id} is not mapped in this property.`);
                    }
                }
            });
        } catch(error) {
            ToastsStore.error('Failed to update booking sources');
            console.error(error);
        }
    }

    const modalWidth = 350 + ((Array.isArray(bookingSources)? bookingSources.length: 0) * 21) - (window.innerHeight - 180);

    return (
        <Modal open={visible} onCancel={() => handleModalCancel()} footer={null} 
            closeIcon={<IconButton type="delete" hasAccess={true} width="25px" />}
            width={"min-content"}
        >
            {integrationPartner && (
                <div className="rms-modal-content">
                    {/* Header */}
                    <div className="rms-modal-header">
                        <h3>{`${integrationPartner.name} Booking Sources`}</h3>
                    </div>
                    <form className="light-form">
                        {/* <div style={{minWidth: "400px", minHeight: "100px", height: "max-content", width: "800px"}}> */}
                        <div style={{height: "max-content", width: modalWidth < 350? "350px": modalWidth, minWidth: "350px", maxWidth: window.innerWidth - 100}}>
                        {loading && (
                            <div>Please wait...</div>
                        )}
                        {!loading && !error && bookingSources.length === 0 && (
                            <div>There is no booking sources found for this integration partner</div>
                        )}
                        {!loading && error && (
                            <div>{error}</div>
                        )}
                        {!loading && !error && bookingSources.length > 0 && (
                            <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(50px, 300px))"}}>
                                {bookingSources.map(({master_id, master_name, isSelected}) => 
                                    <div>
                                        <Checkbox key={master_id} checked={isSelected} onChange={(e) => handleBSOnChange(master_id, e.target.checked)}>{master_name}</Checkbox>
                                    </div>
                                )}
                            </div>
                        )}
                        </div>

                        {/* Cancel, Submit, Delete Buttons */}
                        <div className="light-form-buttons" style={{marginTop: "10px"}}>
                            <ActionButton type={saving? "disabled" : "cancel"} action={() => handleModalCancel()} text="Cancel" />
                            <span style={{ width: 20 }} />
                            <ActionButton type={loading || saving || error? "disabled" : "submit"} 
                                text="Submit" action={() => handleModalSubmit()}
                            />
                        </div>
                    </form>
                </div>
            )}
        </Modal>
    );
}

export default BookingSourcesModal;