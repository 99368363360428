import React, { useEffect, useState, useRef } from 'react';
import { AddFilled } from "Icons";
import Button from "../../Button/Button";
import DataGrid from '../../DataGrid';
import adminConsoleActions from "../../../store/actions/adminConsoleActions";
import FormModal from "../../FormModal/FormModal";
import './Competitors.scss';

const Competitors = () => {
    const _mode = { ADD: 'Add', EDIT: 'Edit', DELETE: 'Delete' };
    const competitorsGridRef = useRef();
    const { getCompetitors, getCompetitor, addCompetitor, updateCompetitor, deleteCompetitor } = adminConsoleActions();
    const [competitorModalState, setCompetitorModalState] = useState({
        visible: false,
        mode: _mode.ADD,
        selectedRowIdx: null
    });
    const competitorModalController = FormModal.newController({}, competitorModalState.visible);
    const {
        errors,
        inputValues,
        setValidations,
        onChangeHandler,
        onBlurHandler
    } = competitorModalController;
    const showModal = (visible, mode, rowIdx) => {
        setCompetitorModalState({...competitorModalState, 
            visible: visible, 
            selectedRowIdx: rowIdx, 
            mode: mode
        });
    }
    const closeModal = () => {
        setCompetitorModalState({...competitorModalState, visible: false});
    }
    const doneSubmit = (result, type) => {
        if (result.type == type) {
            competitorModalController.show(false);
            loadCompetitorsGrid();
        }
    }
    const loadCompetitorsGrid = () => {
        competitorsGridRef.current.setLoading(true);
        getCompetitors().then(response => {
            const data = [];
            if (Array.isArray(response.data?.data)) {
                response.data.data.forEach(competitor => {
                    data.push({
                        competitor_id: competitor._id,
                        competitor_code: competitor.code,
                        competitor_name: competitor.name
                    });
                });
            }
            competitorsGridRef.current.loadData(data);
            competitorsGridRef.current.setLoading(false);
        });
    }
    const submit = (e) => {
        e.preventDefault();
        if (Object.keys(errors).length > 0) return;

        switch(competitorModalState.mode) {
            case _mode.ADD: 
                addCompetitor({
                    code: inputValues.competitor_code,
                    name: inputValues.competitor_name
                }).then(result => doneSubmit(result, "ADD_COMPETITOR_SUCCESS"));
                break;
            case _mode.EDIT:
                updateCompetitor(competitorsGridRef.current.getRawData(competitorModalState.selectedRowIdx).competitor_id, {
                    code: inputValues.competitor_code,
                    name: inputValues.competitor_name
                }).then(result => doneSubmit(result, "UPDATE_COMPETITOR_SUCCESS"));
                break;
            case _mode.DELETE:
                deleteCompetitor(competitorsGridRef.current.getRawData(competitorModalState.selectedRowIdx).competitor_id).then(result => 
                    doneSubmit(result, "DELETE_COMPETITOR_SUCCESS")
                );
                break;
        }
    }
    useEffect(() => {
        if (competitorModalState.visible === true) {
            let data = null;
            if ([_mode.EDIT, _mode.DELETE].includes(competitorModalState.mode)) {
                data = competitorsGridRef.current.getRawData(competitorModalState.selectedRowIdx);

                getCompetitor(data.competitor_id).then(result => {
                    let roomType = result.data.data;
                    if (typeof roomType === 'object') {
                        competitorModalController.show(true, { 
                            competitor_name: roomType.name, 
                            competitor_code: roomType.code
                        });
                    }
                });
            } else {
                competitorModalController.show(true);
            }
        } else {
            competitorModalController.show(false);
        }
    }, [competitorModalState]);

    useEffect(() => {
        loadCompetitorsGrid();
    }, []);

    return (
        <div className="admin-competitors">
            <div className="add-competitor" style={{display: 'flex', marginBottom: '10px'}}>
                <h4 style={{color: 'white', marginRight: '10px'}}>Add Competitor</h4>
                <AddFilled
                    onClick={() => { showModal(true, _mode.ADD); }}
                    width="25px"
                    height="25px"
                    className="pointer"
                />
            </div>
            <DataGrid 
                ref={competitorsGridRef}
                columns={[{
                    id: 'competitor_name',
                    header: 'PROPERTY NAME',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'competitor_code',
                    header: 'MAPPING CODE',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'actions',
                    header: 'ACTIONS',
                    type: 'action',
                    width: '54px',
                    actionFn: {
                        // NOTE: States are private in functional component. Accessing any state here is useless.
                        onEdit: (rowIdx) => {
                            showModal(true, _mode.EDIT, rowIdx);
                        },
                        onDelete: (rowIdx) => {
                            showModal(true, _mode.DELETE, rowIdx);
                        }
                    }
                }]}
                width="800px"
            />
            <FormModal title={`${competitorModalState.mode} Competitor`} onSubmit={submit}
                visible={competitorModalState.visible} width={500} maskClosable={false} 
                onClose={() => { closeModal(); }}
                setValidations={setValidations} controller={competitorModalController}
            >
            {(competitorModalState.mode === _mode.ADD || competitorModalState.mode === _mode.EDIT) && (<>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Property Name"
                        name="competitor_name" value={inputValues.competitor_name} error={errors.competitor_name}
                        validations={{ required: true }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Mapping Code"
                        name="competitor_code" value={inputValues.competitor_code} error={errors.competitor_code}
                        validations={{ required: true }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
            </>)}
            {competitorModalState.mode === _mode.DELETE && (<>
                <div className="light-form delete-competitor">
                    <center>
                        <p>Deleting this competitor will remove the item from the predefined list for competitor and remove all mappings associated with it.</p>
                        <p>Are you sure you want to delete <b>{competitorsGridRef.current.getGridData([competitorModalState.selectedRowIdx])?.competitor_name}</b>?</p>
                    </center>
                </div>
            </>)}
            <div className="light-form-buttons">
                <Button type="bg-light" style={{ marginRight: '8px' }} onClick={() => { closeModal() }}>Cancel</Button>
                <Button btnType="submit" type="primary">
                    {competitorModalState.mode === _mode.DELETE? 'DELETE': 'Submit'}
                </Button>
            </div>
            </FormModal>
        </div>
    )
}

export default React.memo(Competitors);