import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";
import dayjs from "dayjs";

import AggregateCell from "./AggregateCell";
import AggregateTable from "./AggregateTable";
import Switch from "../../Switch/Switch";
import Slider from "elements/Slider/Slider";
import Tabs from "../../Tabs";
import MonthYearNav from "../MonthYearNav";
import useCalendar from "../useCalendar";
import ViewSettings from "../../ViewSettings/ViewSettings";
import { Calendar, List, Download } from "Icons";

import { useDownload } from "hooks/useDownload";
import { usePreferences } from "hooks/usePreferences";
import constants from "../../../constants";
import useHelperFunctions from "../../../store/actions/actions";
import useDashboardActions from "../../../store/actions/dashboardActions";
import usePropertiesActions from "store/actions/propertiesActions";
import { defaultViewTabs, generateFilter } from "../../../utils/calendar";
import NotesModal from "components/Dashboard/components/NotesModal";

const Aggregate = ({ type, toggleCell }) => {
    const state = useSelector((state) => state.mainReducer);
    const users = useSelector((state) => state.users);
    const { loading, weeklyData, monthlyData, source } = useSelector((state) => state.dashboard);
    const { ADRState, calendar_data, calendar_cache, activeHotel,
        hotelCode, hotelId, showSidebar, activeViewType, activeCell, notesModalState } = state;
    const { as_of, pick_up, pace, pace_string, check_in_start, segments, roomtype } = calendar_data;

    const dispatch = useDispatch();
    const { getNotesSummary } = usePropertiesActions();
    const { handleChangeCheckinStart } = useCalendar();
    const { getWeeklyData, getMonthlyData, getVarianceAvgs } = useDashboardActions();
    const { setADRState, toggleChartModal, setNotesModalState } = useHelperFunctions();
    const { download, isPending } = useDownload();
    const { user, useCachedDailyData, useSingleAsOfAvgsForVariances, showDownloadables } = usePreferences();
    
    const [currentData, setCurrentData] = useState([]);

    const isWeek = type === "week";
    const isMonth = type === "month";
    const viewTab = defaultViewTabs("aggregate");
    const checkin = [
        isWeek
            ? dayjs.utc(check_in_start).startOf("isoWeek")
            : dayjs.utc(check_in_start).startOf("month"),
        isWeek
            ? dayjs.utc(check_in_start).endOf("isoWeek").add(5, "weeks").startOf("day")
            : dayjs.utc(check_in_start).add(2, "months").endOf("month").startOf("day")
    ]
    
    const group = state.propertyInView.id;
    let filter = generateFilter(hotelId, as_of, checkin, pick_up, pace, segments, roomtype, user?.prefs, group, pace_string);
    filter.cancelToken = source.token;

    const hideDownloadables = true;
    const pdf = {
        property: activeHotel, asof: as_of, pickup: pick_up, pace: pace_string, ss: segments, rts: roomtype,
        cin: `${dayjs(checkin[0]).format("MMMM DD, YYYY")} - ${dayjs(checkin[1]).format("MMMM DD, YYYY")}`
    };

    useEffect(() => {
        if (isWeek) {
            if (calendar_cache.weekly_filter !== calendar_data) {
                getWeeklyData(filter).then((res) => {
                    getVarianceAvgs({ ...filter, single: useSingleAsOfAvgsForVariances });
                    // Used stay date navigator from dashboard.
                    activeCell && (res?.response?.data?.data?? []).forEach(item => {
                        const [activeWeek, activeYear, direction = null] = activeCell.split("_");
                        if (item.week == activeWeek && item.year == activeYear) {
                            toggleCell(item, "weekly");
                            return;
                        }
                    });
                });
            }
        }

        if (isMonth) {
            if (calendar_cache.monthly_filter !== calendar_data) {
                getMonthlyData(filter).then((res) => {
                    getVarianceAvgs({ ...filter, single: useSingleAsOfAvgsForVariances });
                    // Used stay date navigator from dashboard.
                    activeCell && (res?.response?.data?.data?? []).forEach((item, index) => {
                        const [activeMonth, activeYear, direction = null] = activeCell.split("_");
                        if (item.month == activeMonth && item.year == activeYear) {
                            toggleCell(item, "monthly");
                        }
                    });
                });
            }
        }
    }, [calendar_data]);

    useEffect(() => {
        
        if (notesModalState.visible === true) return;

        const data = (isWeek? [ ...weeklyData ]: [ ...monthlyData ])
            .sort((a, b) => new Date(a.dateRange[0]) - new Date(b.dateRange[0]));
        
        if (data.length === 0) return;

        const checkInStart = data[0].dateRange[0];
        const checkInEnd = data[data.length - 1].dateRange[1];
        
        getNotesSummary(state.propertyInView.id, checkInStart, checkInEnd).then(result => {
            if (result.type === constants.GET_NOTES_SUCCESS) {
                const notes = result.response.data.data;
                if (notes.length > 0) {
                    setCurrentData(data.map(item => {
                        
                        const sortOrder = { ALERT: 1, EVENT: 2, MEMO: 3 };
                        const noteSummary = notes
                            .filter(note => dayjs(note.date).isBetween(item.dateRange[0], item.dateRange[1], 'day', '[]'))
                            .sort((a, b) => sortOrder[a.type] - sortOrder[b.type]);

                        return { ...item, note_type: noteSummary.length > 0? noteSummary[0].type: null };
                    }))
                } else {
                    setCurrentData(data);
                }
            }

            if (useCachedDailyData) {
                if (isWeek) {
                    dispatch({
                        type: constants.UPDATE_CALENDAR_CACHE,
                        value: { ...calendar_cache, weekly: data, weekly_filter: calendar_data }
                    });
                }
                if (isMonth) {
                    dispatch({ 
                        type: constants.UPDATE_CALENDAR_CACHE,
                        value: { ...calendar_cache, monthly: data, monthly_filter: calendar_data }
                    });
                }
            }
        });

    }, [weeklyData, monthlyData, notesModalState]);

    const [sliderPosition, setSliderPosition] = useState(0);
    const [activeCellIndex, setActiveCellIndex] = useState(null);
    const handleSliderChange = (value) => setSliderPosition(value);

    useEffect(() => {
        if (showSidebar && window.innerWidth <= 1900) {
            if (activeCellIndex > sliderPosition) {
                setSliderPosition(sliderPosition + 1);
            }
        } else {
            if (sliderPosition) {
                setSliderPosition(sliderPosition - 1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSidebar]);


    const modifyFilterCheckin = (filter, dateRange) => {
        return generateFilter(hotelId, as_of, dateRange, pick_up, pace, segments, roomtype, users.authUser.prefs, group, pace_string);
    }

    const loadAdditional = (direction) => {
        if (!loading) {
            if (direction === "end") {
                if (isWeek) {
                    const lastWeek = weeklyData[weeklyData.length - 1];
                    const nthWeek = dayjs.utc(lastWeek.dateRange[0]).week();
                    const dateRange = [
                        dayjs.utc().week(nthWeek + 1).startOf('week'),
                        dayjs.utc().week(nthWeek + 3).endOf('week').startOf('day')
                    ];
                    const newFilter = modifyFilterCheckin(filter, dateRange);
                    getWeeklyData(newFilter, "end");
                }
                if (isMonth) {
                    const lastMonth = currentData[currentData.length - 1];
                    // const nextMonth = dayjs.utc().month(lastMonth.month).year(lastMonth.year).date(1)
                    const nextMonth = dayjs.utc(lastMonth.dateRange[0]).add(1, 'month');
                    const dateRange = [
                        dayjs(nextMonth).startOf('day'), 
                        dayjs(nextMonth).endOf('month').startOf('day')
                    ];
                    const newFilter = modifyFilterCheckin(filter, dateRange);
                    getMonthlyData(newFilter, "end");
                }
            } else if (direction === "start") {
                if (isWeek) {
                    const firstWeek = weeklyData[0];
                    const nthWeek = dayjs.utc(firstWeek.dateRange[0]).week();
                    const dateRange = [
                        dayjs.utc().week(nthWeek - 3).endOf('week').startOf('day'),
                        dayjs.utc().week(nthWeek - 1).startOf('week')
                    ];
                    const newFilter = modifyFilterCheckin(filter, dateRange);
                    getWeeklyData(newFilter, "beginning");
                }
                if (isMonth) {
                    const firstMonth = currentData[0];
                    // const prevMonth = dayjs.utc().month(firstMonth.month).year(firstMonth.year).date(1)
                    const prevMonth = dayjs.utc(firstMonth.dateRange[0]).subtract(1, 'month');
                    const dateRange = [
                        dayjs(prevMonth).startOf('day'), 
                        dayjs(prevMonth).endOf('month').startOf('day')
                    ];
                    const newFilter = modifyFilterCheckin(filter, dateRange);
                    getMonthlyData(newFilter, "beginning");
                }
            }
        }
    };


    const showLoadPrevious = () => {
        // Change Request: ticket #1584 - always show back button at monthly/weekly view
        let firstItemDate = "";
        if (isWeek && weeklyData.length) firstItemDate = dayjs(weeklyData[0].dateRange[0]);
        if (isMonth && monthlyData.length) firstItemDate = dayjs(monthlyData[0].dateRange[0]);
        let asOfDateAndPickup = dayjs(as_of)
            .add(pick_up, "days")
            ;

        return firstItemDate > asOfDateAndPickup && sliderPosition === 0;
    };


    const getMonth = (sliderPosition) => {
        let month = dayjs(check_in_start);
        let index = (sliderPosition > 0) ? sliderPosition : 0;

        let data = currentData;
        if (data.length && data[index].dateRange[1]) month = dayjs(data[index].dateRange[1]);
        if (isWeek) month = month.add(7, "days");

        return month;
    }

    const changeMonth = (direction) => {
        const newCheckinStart = dayjs(check_in_start).startOf("month");
        setSliderPosition(0);
        handleChangeCheckinStart(
            dayjs(newCheckinStart).add(direction, "months")
        );
    };

    const getYear = (sliderPosition) => {
        let year = dayjs(check_in_start).format("YYYY");
        let index = (sliderPosition > 0) ? sliderPosition : 0;

        let data = currentData;
        if (data.length && data[index].year) year = data[index].year;

        return year;
    }

    const changeYear = (direction) => {
        const newCheckinStart = dayjs(check_in_start).startOf("month");
        setSliderPosition(0);
        handleChangeCheckinStart(
            dayjs(newCheckinStart).add(direction, "years")
        );
        if (isWeek) dispatch({ type: constants.STORE_WEEKLY_DATA, value: null });
    };


    const toggleADR = () => setADRState(!ADRState);
    const setSelectedView = (value) => {
        dispatch({
            type: constants.TOGGLE_CALENDAR_TABLE_VIEW,
            value: value,
        });
    };

    const activateCell = (data, index) => {
        let aggregateData = isWeek ? weeklyData : monthlyData;
        let newData = aggregateData.map((item) => {
            return {
                ...item,
                selected: false,
            };
        });
        data.selected
            ? newData[index].selected = false
            : newData[index].selected = true;

        if (isWeek) dispatch({ type: constants.STORE_WEEKLY_DATA, value: newData });
        if (isMonth) dispatch({ type: constants.STORE_MONTHLY_DATA, value: newData });

        setActiveCellIndex(index);
        toggleCell(data, isWeek ? "weekly" : "monthly");
        dispatch({ type: constants.SHOW_VISUALS, value: false })
        toggleChartModal({ show: false })
    };


    const getAggregateItemsLength = () => {
        let data = currentData;
        return data ? data.length : 0;
    }



    return (
        <div className={`aggregate-view-container ${isWeek ? "weekly-view" : "monthly-view"}`}>

            {/* Views */}
            <div className="tabs-container">
                <ViewSettings />
            </div>

            {/* Navigation */}
            <div data-html2canvas-ignore className="calendar-header">
                <MonthYearNav
                    monthValue={getMonth(sliderPosition)}
                    yearValue={getYear(sliderPosition)}
                    handleChange={handleChangeCheckinStart}
                    isLoading={loading}
                    changeMonth={changeMonth}
                    changeYear={changeYear}
                />
                <div className="rms-switch font-md filter3Tutorial">
                    <Calendar width="24px" height="24px" className="pointer" fill={activeViewType === "cells" ? "#67b489" : "#fff"}
                        style={{ margin: 'auto 4px' }} onClick={() => setSelectedView("cells")} />
                    <List width="24px" height="24px" className="pointer" fill={activeViewType === "table" ? "#67b489" : "#fff"}
                        style={{ margin: 'auto 4px' }} onClick={() => setSelectedView("table")} />
                    {showDownloadables && !hideDownloadables && (
                        <Download width="24px" height="24px" className={isPending ? "" : "pointer"}
                            style={{ margin: 'auto 4px', filter: isPending ? 'grayscale(100%)' : undefined }}
                            onClick={() => {
                                const property = activeHotel.toLowerCase().replace(/,/g, '').replace(/ /g, '-');
                                const filename = `${property}-${type}ly-${activeViewType}-report`;
                                download(filename, 'pdf', 'calendar', pdf);
                            }}
                        />
                    )}
                    <span style={{ marginLeft: "32px" }} />
                    <span data-html2canvas-ignore>ADR</span>
                    <Switch checked={ADRState} onChange={toggleADR} />
                </div>
            </div>


            {/* Card View with Slider */}
            {activeViewType === "cells" && (
                <Slider
                    itemsSize={(isWeek ? 1160 : 1200) / 6}
                    itemsLength={getAggregateItemsLength()}
                    itemsToShow={isWeek ? 1 : 6}
                    lazyLoad={true}
                    lazyloadBackwards={sliderPosition == 0}
                    loadMore={loadAdditional}
                    isLoading={loading}
                    isLoadingatEnd={false}
                    isLoadingatStart={false}
                    callback={handleSliderChange}
                    sliderPosition={sliderPosition}
                >
                    {isWeek && currentData &&
                        currentData.map((weekData, index) => {
                            let weekFrom = dayjs(weekData.week_range[0]).format("MMM D");
                            let weekTo = dayjs(weekData.week_range[1]).format("MMM D");
                            weekData.key = `${weekData.week}_${weekData.year}`;
                            return (
                                <AggregateCell
                                    type={type}
                                    title={`${weekFrom} - ${weekTo}`}
                                    data={weekData}
                                    key={weekData.key}
                                    index={index}
                                    ADRState={ADRState}
                                    onClick={(e) => {
                                        activateCell(weekData, index);
                                    }}
                                    activeCell={activeCell}
                                />
                            );
                        })}
                    {isMonth && currentData &&
                        currentData.map((monthData, index) => {
                            monthData.key = `${monthData.month}_${monthData.year}`;
                            return (
                                <AggregateCell
                                    type={type}
                                    title={`${monthData.month} ${monthData.year}`}
                                    data={monthData}
                                    key={monthData.key}
                                    index={index}
                                    ADRState={ADRState}
                                    onClick={(e) => {
                                        activateCell(monthData, index);
                                    }}
                                    activeCell={activeCell}
                                />
                            );
                        })}
                </Slider>
            )}


            {/* Table View */}
            {activeViewType !== "cells" && (
                <AggregateTable
                    type={type}
                    data={currentData}
                    onClick={(index) => {
                        let data = currentData;
                        activateCell(data[index], index);
                    }}
                    isLoading={loading}
                    loadMore={loadAdditional}
                />
            )}

            <NotesModal 
                visible={notesModalState.visible} 
                startDate={notesModalState.startDate}
                endDate={notesModalState.endDate}
                onClose={() => {
                    setNotesModalState({ visible: false });
                }}
            />
        </div>
    );
};

export default Aggregate;
