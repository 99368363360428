import * as React from "react";

function SvgSettings(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path
        d="M87.55 45.79l-7.46-1.26a2.37 2.37 0 01-1.92-1.76 28 28 0 00-3.09-7.44 2.4 2.4 0 01.11-2.61l4.38-6.17a.47.47 0 000-.6l-5.29-5.29a.48.48 0 00-.34-.15.58.58 0 00-.27.09L67.5 25a2.42 2.42 0 01-2.62.1 28.65 28.65 0 00-7.62-3.1 2.41 2.41 0 01-1.77-1.94l-1.25-7.41a.45.45 0 00-.46-.39H46.3a.46.46 0 00-.46.39l-1.28 7.52a2.44 2.44 0 01-1.75 1.92 28.09 28.09 0 00-7.43 3.12 2.43 2.43 0 01-2.63-.1l-6.13-4.37a.39.39 0 00-.26-.09.46.46 0 00-.34.14l-5.29 5.29a.5.5 0 00-.06.61l4.43 6.21a2.38 2.38 0 01.12 2.6 28.35 28.35 0 00-3 7.46 2.39 2.39 0 01-1.93 1.77L12.84 46a.45.45 0 00-.39.46v7.48a.46.46 0 00.39.46l7.51 1.28a2.42 2.42 0 011.92 1.76 28.19 28.19 0 003.13 7.42 2.43 2.43 0 01-.11 2.63l-4.37 6.13a.48.48 0 000 .6l5.3 5.3a.48.48 0 00.34.14.58.58 0 00.26-.09l6.22-4.42a2.28 2.28 0 011.38-.45 2.49 2.49 0 011.21.32A29.47 29.47 0 0043 78a2.37 2.37 0 011.75 1.91L46 87.36a.45.45 0 00.46.39h7.48a.46.46 0 00.46-.39l1.26-7.47A2.34 2.34 0 0157.39 78a28.84 28.84 0 007.44-3.09 2.4 2.4 0 012.61.1l6.17 4.39a.45.45 0 00.26.09.46.46 0 00.34-.14L79.5 74a.48.48 0 00.06-.6l-4.39-6.18a2.4 2.4 0 01-.11-2.61 28.08 28.08 0 003.09-7.45 2.39 2.39 0 011.92-1.75l7.46-1.25a.45.45 0 00.39-.46v-7.45a.46.46 0 00-.37-.46zM50.18 68.33A18.35 18.35 0 1168.53 50a18.36 18.36 0 01-18.35 18.33z"
        fill="#67b489"
        data-name="Layer 1"
      />
    </svg>
  );
}

export default SvgSettings;
