/**
 * @author Jarbee Bejar
 */
import React, {useState, useRef, useEffect } from 'react';
import { AddFilled, Exit, Edit, Settings } from 'Icons';
import { DatePicker, Radio, Select, Input, Rate, Menu, Dropdown, Button } from 'antd';

const Cell = ({ 
    type = 'label', 
    items = [],
    customActions = [],
    actionFn = {},
    linkFn = {},
    coordinates = {},
    error = '', 
    initialValue = null, formatValue = null,
    useDisplayField = true,
    disabled = false,
    render,
    saveValueToGridData = () => {}
}) => {
    
    const [value, setValue] = useState(initialValue);
    const [isSelected, setIsSelected] = useState(false);
    const inputRef = useRef(null);
    const isInputField = ['number', 'text', 'dropdown', 'datepicker'].includes(type);

    useEffect(() => {
        if (isSelected && inputRef.current) inputRef.current.focus();
    }, [isSelected]);

    useEffect(() => {
        // This is used for copyFieldValue feature. Make sure that the state:value is same with the initialValue. This will cause to trigger the useEffect of grid twice.
        if (value != initialValue) {
            setValue(initialValue);
            saveValueToGridData(coordinates.y, coordinates.x, initialValue);
        }
    }, [initialValue]);

    const handleOnBlur = (e) => {
        setIsSelected(false);
    }
    const handleOnChange = (e) => {
        if (typeof e === 'object' && e?.target?.value !== undefined) {
            saveValueToGridData(coordinates.y, coordinates.x, e.target.value);
        } else {
            saveValueToGridData(coordinates.y, coordinates.x, e);
        }
    }
    const handleKeyDown = (e) => {
        if (!inputRef.current) return;
        switch(e.key) {
            case 'Enter':
            case 'Escape': inputRef.current.blur(); break;
            // case 'Tab': 
        }
    }
    // Determine cell input type
    const getInputField = () => {
        switch(type) {
            case 'text': return (<>
                <input type="text"
                    className="data-grid-text"
                    ref={inputRef} 
                    value={value || ''} 
                    disabled={disabled === true} 
                    onChange={handleOnChange} 
                    onBlur={handleOnBlur}
                    onKeyDown={handleKeyDown}>
                </input>
            </>)
            case 'number': return (<>
                <input type="number"
                    className="data-grid-number"
                    ref={inputRef} 
                    value={value || ''} 
                    disabled={disabled === true} 
                    onChange={handleOnChange} 
                    onBlur={handleOnBlur}
                    onKeyDown={handleKeyDown}>
                </input>
            </>)
            case 'dropdown': return (<>
                <Select 
                    className="data-grid-dropdown" 
                    style={{width: '100%'}} 
                    disabled={disabled} 
                    value={value || ''} 
                    onChange={handleOnChange}>
                {items.length > 0 && items.map(item =>
                    <Select.Option key={item.id} value={item.id}>
                        {item.displayText}
                    </Select.Option>
                )}
                </Select>
            </>)
        }
    }
    const getDisplayValue = (value) => {
        let displayValue = value;
        if (typeof render === 'function') displayValue = render(displayValue);
        if (formatValue) displayValue = formatValue.replace('%val%', displayValue);
        return displayValue;
    }
    switch (type) {
        case 'empty': return <div className="data-grid-cell empty"></div>;
        case 'custom_actions': return (<div className="data-grid-cell">
                {customActions.map((action, index) => 
                    <div key={`cac_${coordinates.y}_${coordinates.x}_${index}`} className="data-grid-custom-action">{action.render(coordinates.y)}</div>
                )}
            </div>);
        case 'action': case 'action_delete': case 'action_edit': 
            return (<div className="data-grid-cell">
                {type != "action_delete" && <Edit  width="20px" height="20px" className="pointer" onClick={(e) => { actionFn.onEdit(coordinates.y)}} style={{ marginRight: '7px', verticalAlign: 'middle' }} />}
                {type != "action_edit" && <Exit width="20px" height="20px" className="pointer" onClick={(e) => { actionFn.onDelete(coordinates.y)}} style={{ verticalAlign: 'middle' }}/>}
            </div>);
        case 'link': return (<div className="data-grid-cell">
                <a type="text"
                    className="data-grid-link"
                    disabled={disabled === true} 
                    onClick={(e) => { if (typeof linkFn.onClick === 'function') linkFn.onClick(coordinates.x, coordinates.y) }}>
                    {value || ''}
                </a>
            </div>)
        default: return (
            <div className={`data-grid-cell ${error? 'error' : ''}`}
                onClick={(e) => { if (disabled === true || !isInputField) return; setIsSelected(true); }}
                onFocus={(e) => { if (disabled === true || !isInputField) return; setIsSelected(true); }}
                title={error? error: ''}
            >
                {(isSelected || !useDisplayField) && getInputField()}
                {!isSelected && useDisplayField &&
                    <div className={`${isInputField? 'display-field': ''}`}>
                        {getDisplayValue(value)}
                    </div>
                }
            </div>
        )
    }    
}

export default Cell;