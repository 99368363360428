import React from 'react';
import { 
    CaretUpOutlined, 
    CaretDownOutlined, 
    CaretLeftOutlined, 
    CaretRightOutlined, 
    LoadingOutlined,
    CalendarOutlined,
    WarningOutlined,
    SearchOutlined
} from '@ant-design/icons';

const OutlinedIcon = ({ type, ...props }) => {
    switch(type) {
        case 'up': return <CaretUpOutlined {...props} />
        case 'down': return <CaretDownOutlined {...props} />
        case 'left': return <CaretLeftOutlined {...props} />
        case 'right': return <CaretRightOutlined {...props} />
        case 'loading': return <LoadingOutlined spin={true} {...props}  />
        case 'calendar': return <CalendarOutlined {...props} />
        case 'search': return <SearchOutlined {...props} />
        case 'warning': return <WarningOutlined {...props} />
        default: <></>
    }
}

export default OutlinedIcon;