import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Exit } from "Icons";
import MultipleSelectSearch from "../MultipleSelectSearch/MultipleSelectSearch";

const PropertyMapping = ({ mappedProperties, handleChange }) => {
    const propertiesState = useSelector((state) => state.properties);
    const [filteredProperties, setFilteredProperties] = useState(
        propertiesState.properties
    );
    const [searchable, setSearchable] = useState([]);
    const [defaultProperties, setDefaultProperties] = useState([]);


    useEffect(() => {
        const mappedToUser = mappedProperties.map((item) => item._id);
        const removedMappedToUser = propertiesState.properties.filter(
            (property) => {
                return !mappedToUser.includes(property._id);
            }
        );
        setDefaultProperties(removedMappedToUser);
        setSearchable(removedMappedToUser);
        setFilteredProperties(removedMappedToUser);
    }, [propertiesState.properties, mappedProperties]);

    const handleSearch = (searchTerm) => {
        const newFiltered = searchable.filter((item) => {
            const searchTermLowerCase = searchTerm.toLowerCase();
            const nameLowerCase = item.name.toLowerCase();
            const propCodeLowerCase = item.property_code.toLowerCase();
            return (
                nameLowerCase.startsWith(searchTermLowerCase) ||
                propCodeLowerCase.startsWith(searchTermLowerCase)
            );
        });

        setFilteredProperties(newFiltered);
    };

    const handleSubmit = (properties) => {
        const updatedMappedProperties = [...mappedProperties, ...properties];
        handleChange("properties", updatedMappedProperties);
    };

    const handleCallback = (properties) => {
        const propertyIds = properties.map((item) => item._id);
        setSearchable(
            defaultProperties.filter((item) => !propertyIds.includes(item._id))
        );
    };

    return (
        <div className="forms-section property-mapping">
            <h3>Map Properties to User</h3>
            <MultipleSelectSearch
                handleSearch={handleSearch}
                searchResults={filteredProperties}
                handleSubmit={handleSubmit}
                callback={handleCallback}
            />
            <div className="property-mapping__table"></div>

            <table className="forms-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Property Code</th>
                        <th>Location</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {mappedProperties.length ? (
                        mappedProperties.map((property, index) => {
                            return (
                                <tr key={property._id}>
                                    <td>{property.name}</td>
                                    <td>{property.property_code}</td>
                                    <td>{property.preferred_location}</td>
                                    <td className="table-actions">
                                        <Exit
                                            onClick={() => {
                                                const updatedMappedProperties = mappedProperties.filter(
                                                    (item) => item._id !== property._id
                                                );

                                                handleChange("properties", updatedMappedProperties);
                                            }}
                                            width="16px"
                                            height="16px"
                                            className="pointer"
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                            <tr>
                                <td>No Properties Found</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    );
};

export default PropertyMapping;
