import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../Button/Button';
import DataGrid from '../../../DataGrid';
import CustomAvgDatesModal from "../Modals/CustomAvgDatesModal";
import { gridFinalsColumns, gridFinalsCellConfig } from './gridConfig';
import usePropertiesActions from '../../../../store/actions/propertiesActions';

const FinalsSection = (props) => {
    const isGroup = props?.isGroup ?? false;
    const group = props.values;
    const { property, loadingProperty } = useSelector(state => state.properties);
    const item = isGroup ? group : property;
    const forecastSettingsFinals = item?.forecast_settings_v2.finals;

    const gridFinalsRef = useRef();
    const { updateForecastSettingsV2 } = usePropertiesActions();
    const [customModalState, setCustomModalState] = useState({ for: null, header: null, items: [], visible: false });

    useEffect(() => {
        if (loadingProperty) return;
        gridFinalsRef.current.loadData(getColumnData(forecastSettingsFinals, false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getColumnData = (finals, loadDefaults = true) => {
        if (loadDefaults) {
            return [{
                'variable': 'Same day last year'
            }, {
                'variable': 'Rolling Average DOW'
            }, {
                'variable': 'Rolling Average'
            }, {
                'variable': 'Custom Average DOW'
            }, {
                'variable': 'Custom Average'
            }, {
                'variable': 'On The Books occupancy'
            }];
        }
        return [{
            'variable': 'Same day last year',
            'finals_average_days': 'N/A',
            'finals_threshold_good': finals.threshold.sdly.good,
            'finals_threshold_avg': finals.threshold.sdly.avg,
            'finals_threshold_below_avg': finals.threshold.sdly.below_avg,
            'finals_threshold_bad': finals.threshold.sdly.bad
        }, {
            'variable': 'Rolling Average DOW',
            'finals_average_days': finals.avg_days.avg_dow,
            'finals_threshold_good': finals.threshold.avg_dow.good,
            'finals_threshold_avg': finals.threshold.avg_dow.avg,
            'finals_threshold_below_avg': finals.threshold.avg_dow.below_avg,
            'finals_threshold_bad': finals.threshold.avg_dow.bad
        }, {
            'variable': 'Rolling Average',
            'finals_average_days': finals.avg_days.avg,
            'finals_threshold_good': finals.threshold.avg.good,
            'finals_threshold_avg': finals.threshold.avg.avg,
            'finals_threshold_below_avg': finals.threshold.avg.below_avg,
            'finals_threshold_bad': finals.threshold.avg.bad
        }, {
            'variable': 'On The Books occupancy',
            'finals_average_days': 'N/A',
            'finals_threshold_good': finals.threshold.otb.good,
            'finals_threshold_avg': finals.threshold.otb.avg,
            'finals_threshold_below_avg': finals.threshold.otb.below_avg,
            'finals_threshold_bad': finals.threshold.otb.bad
        }, {
            'variable': 'Custom Average DOW',
            'finals_average_days': finals.avg_days.custom_avg_dow,
            'finals_threshold_good': finals.threshold.custom_avg_dow.good,
            'finals_threshold_avg': finals.threshold.custom_avg_dow.avg,
            'finals_threshold_below_avg': finals.threshold.custom_avg_dow.below_avg,
            'finals_threshold_bad': finals.threshold.custom_avg_dow.bad
        }, {
            'variable': 'Custom Average',
            'finals_average_days': finals.avg_days.custom_avg,
            'finals_threshold_good': finals.threshold.custom_avg.good,
            'finals_threshold_avg': finals.threshold.custom_avg.avg,
            'finals_threshold_below_avg': finals.threshold.custom_avg.below_avg,
            'finals_threshold_bad': finals.threshold.custom_avg.bad
        }];
    }

    const cellConfigs = [...gridFinalsCellConfig, { 
        positionX: 1, 
        positionY: 4, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg_dow", 
                header: "Manage Custom Average DOW",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }, { 
        positionX: 1, 
        positionY: 5, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg", 
                header: "Manage Custom Average",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }];

    const handleSubmit = () => {
        if (gridFinalsRef.current.internalState.hasError) return;

        const newForecastSettingsFinals = { ...forecastSettingsFinals };
        ['sdly', 'avg_dow', 'avg', 'otb', 'custom_avg_dow', 'custom_avg'].forEach((key, idx) => {
            console.log(key, newForecastSettingsFinals.threshold[key])
            newForecastSettingsFinals.avg_days[key] = [0, 3].includes(idx) ? null : gridFinalsRef.current.getData('finals_average_days', idx);
            newForecastSettingsFinals.threshold[key].good = gridFinalsRef.current.getData('finals_threshold_good', idx);
            newForecastSettingsFinals.threshold[key].avg = gridFinalsRef.current.getData('finals_threshold_avg', idx);
            newForecastSettingsFinals.threshold[key].below_avg = gridFinalsRef.current.getData('finals_threshold_below_avg', idx);
            newForecastSettingsFinals.threshold[key].bad = gridFinalsRef.current.getData('finals_threshold_bad', idx);
        });

        if (gridFinalsRef.current.errors.length > 0) return;

        updateForecastSettingsV2(isGroup ? group.id : property._id, newForecastSettingsFinals, 'finals', isGroup)
            .then(result => {
                if (result.type === "UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS") {
                    const updateProperty = result.response.data.data;
                    gridFinalsRef.current.loadData(getColumnData(updateProperty.forecast_settings_v2.finals, false))
                }
            });
    }

    const handleCancel = () => {
        gridFinalsRef.current.reload();
    }

    return (<div className='finals-section'>
        <DataGrid ref={gridFinalsRef} columns={gridFinalsColumns} cellConfigs={cellConfigs} defaultCellHeight="40px" footerHeight="0px" />
        <div style={{ display: "flex", flexDirection: 'row-reverse', paddingTop: "25px" }}>
            <Button type="primary" className="submit" onClick={handleSubmit} style={{ marginLeft: "35px" }}>Submit</Button>
            <Button type="bg-dark" onClick={handleCancel}>Cancel</Button>
        </div>

        <CustomAvgDatesModal width={484}
            headerCopy={ customModalState.header }
            isVisible={ customModalState.visible } 
            items={ customModalState.items }
            onCancel={() => setCustomModalState({ ...customModalState, visible: false })} 
            onSubmit={(items) => {
                const params = { avg_days: null };

                if (customModalState.for === 'avg_dow') {
                    params.avg_days = { custom_avg_dow: items };
                }

                if (customModalState.for === 'avg') {
                    params.avg_days = { custom_avg: items }
                }

                updateForecastSettingsV2(isGroup ? group.id : property._id, params, 'finals', isGroup).then(result => {
                    setCustomModalState({ ...customModalState, visible: false });
                    const updatedProperty = result.response.data.data;
                    gridFinalsRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.finals, false));
                });
            }}
        />
    </div>)
}

export default FinalsSection;