import React from "react";
import constants from "../../../../constants";
// import moment from "moment";
import dayjs from "dayjs";
import useCalendar from "../../../Calendar/useCalendar";
import { isSameDate } from "./helper";
import OutlinedIcon from "elements/Icon/OutlinedIcon";
import { useSelector, useDispatch } from "react-redux";
import "./StayDateNav.scss";

const NavPrev = ({ disabled = false }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.mainReducer);
    const dashboard = useSelector(state => state.dashboard);

    const { dailyData, weeklyData, monthlyData, customDailyData } = dashboard;
    const { sidebarData, activeCalendarView, activeMainData } = state;
    const { toggleCell, handleChangeCheckinStart } = useCalendar();
    
    const goPrev = () => {
        if (disabled) return;
        if (activeCalendarView == "custom") {
            const currentIndex = customDailyData.findIndex(item => dayjs(item.cin).isSame(sidebarData.check_in, 'day'));
            if (customDailyData[currentIndex - 1]) toggleCell(customDailyData[currentIndex - 1], "daily");
        } else if (activeCalendarView == "daily") {
            const firstDayOfCalendar = dayjs(dailyData[0]?.check_in).utc();
            const prevDay = dayjs(sidebarData.check_in).utc().add(-1, "day");

            if (prevDay.isBefore(firstDayOfCalendar, "day")) {
                // Reload dashboard. Let the Main Component (Daily & Aggregate) handle the toggling of cell.
                dispatch({
                    type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                    value: `${prevDay.format()}_nav`
                });
                handleChangeCheckinStart(firstDayOfCalendar.add(-1, "day").toDate())
                return;
            }

            const prevDayData = dailyData.find(item => isSameDate(item.check_in, prevDay));
            if (prevDayData) toggleCell(prevDayData, "daily");
        } else if (activeCalendarView == "weekly") {
            const firstDayOfCalendar = dayjs(weeklyData[0]?.dateRange[0]).utc();
            const prevDay = dayjs(sidebarData.dateRange[0]).utc().add(-1, "day");
            
            if (prevDay.isBefore(firstDayOfCalendar, "day")) {
                // Reload dashboard. Let the Main Component (Daily & Aggregate) handle the toggling of cell.
                dispatch({
                    type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                    value: `${prevDay.isoWeek()}_${prevDay.year()}_start_nav`
                });
                handleChangeCheckinStart(firstDayOfCalendar.add(-1, "day").toDate());
                return;
            }

            const prevWeek = weeklyData.find(item => isSameDate(item.dateRange[1], prevDay));
            if (prevWeek) toggleCell(prevWeek, "weekly");
        } else if (activeCalendarView == "monthly") {
            const firstDayOfCalendar = dayjs(monthlyData[0]?.dateRange[0]).utc();
            const prevDay = dayjs(sidebarData.dateRange[0]).add(-1, "day");
            if (prevDay.isBefore(firstDayOfCalendar, "day")) {
                // Reload dashboard. Let the Main Component (Daily & Aggregate) handle the toggling of cell.
                dispatch({
                    type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                    value: `${prevDay.format("MMMM")}_${prevDay.year()}_start_nav`
                });
                handleChangeCheckinStart(firstDayOfCalendar.add(-1, "day").toDate());
                return;
            }

            const prevMonth = monthlyData.find(item => isSameDate(item.dateRange[1], prevDay));
            if (prevMonth) toggleCell(prevMonth, "monthly");
        }
    }
    return <OutlinedIcon className="stay-date-navigator-prev" type="left" disabled={disabled} onClick={goPrev}/>
}

export default NavPrev;