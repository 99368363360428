import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

import './WaterfallChart.scss'

import constants from "constants";
import { withCurrency, isNullOrUndefined } from "utils/index";
import { isSegmentGroupSelected } from "utils/segments";


/**
 * Waterfall chart component.
 * Data parameter has the following format: { label : String, value: Number, color: String }
 *
 * @function WaterFall
 * @param {String} chartName Identifier for component customizations.
 * @param {[Object]} data Array of waterfall chart data, defaults to an empty array.
 * @param {String} title Waterfall chart title, defaults to an empty string.
 * @param {String} xTitle Waterfall chart horizontal axis title, defaults to 'Segments'.
 * @param {String} yTitle Waterfall chart vertical axis title, defaults to empty string.
 * @param {Number} width Waterfall chart width, defaults to 400.
 * @param {Number} height Waterfall chart height, defaults to 500.
 * @return {Component} Waterfall chart component.
*/
const WaterFall = ({ chartName = '', data = [], title = "", xTitle = 'Segments', yTitle = '', width = 525, height = 600 }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const { visualsWaterfallSorting, visualsAreDisplayed, calendar_data } = state;


    // Reset Waterfall Chart Data to Original Sorting Everytime the Dashboard Modal is Closed
    useEffect(() => {
        dispatch({ type: constants.UPDATE_VISUALS_WATERFALL_SORTING, value: [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualsAreDisplayed]);

    // Sort Waterfall Chart Data Everytime the Table Data Sorting is Updated
    useEffect(() => {
        if (!isNullOrUndefined(visualsWaterfallSorting) && visualsWaterfallSorting.length > 0) {
            let reSortedData = [];
            for (let i = 0; i < visualsWaterfallSorting.length; i++) {
                reSortedData.push(data.filter(item => item.label === visualsWaterfallSorting[i])[0])
            }
            setSortedData(transFormData(reSortedData, chartName, isSegmentGroupSelected(calendar_data)));
        } else {
            setSortedData(transFormData(data, chartName, isSegmentGroupSelected(calendar_data)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualsWaterfallSorting]);
    const [sortedData, setSortedData] = useState(transFormData(data, chartName, isSegmentGroupSelected(calendar_data)));


    return (
        <div className="rms-waterfall-chart" >
            <span className="title">{title}</span>
            <span className="yTitle">{yTitle}</span>
            <Chart
                chartType="CandlestickChart"
                height={height}
                width={width}
                loader={<div>Loading Chart</div>}
                data={sortedData}
                options={{
                    width_units: '%',
                    height_units: '%',
                    backgroundColor: 'transparent',
                    tooltip: { isHtml: true },
                    titleTextStyle: {
                        bold: true,
                        fontName: "Titillium Web",
                        italic: false,
                        color: "#282F5B"
                    },
                    legend: 'none',
                    bar: { groupWidth: '80%' }, // Removes space between bars
                    hAxis: {
                        title: xTitle,
                        textStyle: { fontName: 'Lato' },
                        gridlines: { color: 'transparent' },
                        titleTextStyle: {
                            bold: true,
                            fontName: "Titillium Web",
                            italic: false,
                            color: "#282F5B",
                            fontSize: 14
                        }
                    },
                    vAxis: {
                        gridlines: { count: 0 },
                        textStyle: { fontName: 'Lato' },
                        titleTextStyle: {
                            bold: true,
                            fontName: "Titillium Web",
                            italic: false,
                            color: "#282F5B"
                        },
                        baselineColor: '#efefef'
                    },
                    gridlines: { color: 'transparent' },
                    chartArea: {
                        backgroundColor: {
                            stroke: '#efefef',
                            strokeWidth: 1
                        },
                        width: width - 80,
                        height: height - 140
                    },
                    candlestick: {
                        fallingColor: { strokeWidth: 0, fill: '#a52714' },
                        risingColor: { strokeWidth: 0, fill: '#0f9d58' },
                    },
                }}
            />
        </div>
    )
}

WaterFall.propType = {
    chartName: PropTypes.string,
    data: PropTypes.array,
    xTitle: PropTypes.string,
    yTitle: PropTypes.string,
    height: PropTypes.number
};

/**
 * Creates basic tooltip content. Composing multiple label and value pair.
 * @param {[Object]} data label and value pair
 * @returns {String}
 */
const generateTooltip = (data = []) => {
    let tooltip = '';
    data.forEach((item) => {
        tooltip += `<p>${item.label}: <b>${item.value}</b></p>`
    });
    return tooltip;
}

const transFormData = (data, chartName = '', isSegmentGroupSelected = false) => {
    const chartHeader = [];
    const chartData = [];
    const chartsWithCustomTooltip = {
        dashboard_pickup: { label: 'Pick up' },
        dashboard_pace: { label: 'Pace' }
    }

    if (data.length > 0) {
        if (Object.keys(chartsWithCustomTooltip).includes(chartName)) {
            // Add headers
            chartHeader.push('SEGMENTS');
            chartHeader.push('Occ');
            chartHeader.push('b');
            chartHeader.push('c');
            chartHeader.push('d');
            chartHeader.push({ role: 'style' });
            chartHeader.push({ role: 'tooltip', p: { html: true } });
            // Add data
            const chartInfo = chartsWithCustomTooltip[chartName];
            const createtooltipData = (segment, value, adr) => [
                { label: isSegmentGroupSelected ? 'Segment Group' : 'Segment', value: segment },
                { label: chartInfo.label, value: value },
                { label: `${chartInfo.label} ADR`, value: withCurrency(isNullOrUndefined(adr) ? "-" : adr) }
            ];

            data.forEach((item, index) => {
                if (!isNullOrUndefined(item)) {
                    let tooltipData = [];
                    let startValue = 0;
                    let endValue = item ? item.value : 0;
                    // For first and last bars on the chart, always start the bar at 0 value
                    if (index === 0 || index === data.length - 1) {
                        tooltipData = createtooltipData(item.label, item.value, item.adr);
                        // return [item.label, startValue, startValue, item.value, item.value, item.color, `${item.label}: ${item.value}`];
                    } else {
                        // But for the other bars on the chart, start the bar at the value of the previous bar
                        // And end the bar on the sum of the previous bars and the current bar
                        for (let j = 1; j <= index; j++) {
                            let addend = isNullOrUndefined(data[j - 1]) ? 0 : data[j - 1].value;
                            if (isNaN(addend)) addend = 0;
                            startValue += addend;
                            endValue += addend;
                        }
                        tooltipData = createtooltipData(item.label, endValue - startValue, item.adr);
                    }

                    chartData.push([item.label, startValue, startValue, endValue, endValue, item.color, generateTooltip(tooltipData)]);
                }
            })
        }
    }

    return [chartHeader, ...chartData]
}

export default WaterFall;
