import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";

import constants from "../../constants";
import { colors, strTranslation, getFirstdayOfWeek, getLastdayOfWeek } from "../../utils";
import { generateFilter, defaultPaceFilterOptions } from "../../utils/calendar";
import { isNullish } from "utils/data";



const useChartView = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.mainReducer);
    const { source } = useSelector((state) => state.dashboard);
    const {
        hotelId, activeCalendarView, plotType, segments, segmentType, dateRanges,
        currency, calendar_data, sidebarData, chartData
    } = state;
    const yaxesID = {
        average_occ: "y",
        total_occ: "y",
        average_rate: "y1",
        average_daily_rate: "y1",
        total_rev: "y",
        average_rev: "y",
        pickup: "y",
        cpm_occ: "y",
        cpm_rate: "y1",
    };

    const users = useSelector((state) => state.users);
    const userPrefs = users.authUser.prefs;

    const { as_of, pick_up, pace, pace_string, check_in_start, check_in_end,
        roomtype, comparison, comparison_data } = calendar_data;
    const hideComparisons = (!isNullish(userPrefs) && (userPrefs.hideComparisons ?? false)) || comparison === "NONE";
    const checkin = [getFirstdayOfWeek(check_in_start), getLastdayOfWeek(check_in_end)]
    const group = state.propertyInView.id;

    let filter = generateFilter(hotelId, as_of, checkin, pick_up, pace, calendar_data.segments, roomtype, userPrefs, group, pace_string);
    filter = { ...filter, cancelToken: source.token }



    const getOccTypeToPlot = () => {
        let isSDLY = calendar_data.pace_string === "samedaylastyear";
        let isLY = calendar_data.pace_string === "lastyear";
        let isDaily = sidebarData && sidebarData.type === "daily";
        return ((isSDLY || isLY) && isDaily) ? "total_occ" : "average_occ";
    }

    const getRevTypeToPlot = () => {
        let isSDLY = calendar_data.pace_string === "samedaylastyear";
        let isLY = calendar_data.pace_string === "lastyear";
        let isDaily = sidebarData && sidebarData.type === "daily";
        return ((isSDLY || isLY) && isDaily) ? "total_rev" : "average_rev";
    }

    const itemsToPlot = [
        "average_daily_rate",
        getOccTypeToPlot(),
        "average_rate",
        getRevTypeToPlot(),
        "pickup",
    ];



    const generateGraph = (rawData, dateRanges, itemsToPlot, plotType, colors) => {
        // Defaults
        const overallData = { labels: [], datasets: [] };
        let values = [];
        let count = 0;
        // Based on Maximum Booking Chart Days Out Value
        // Generate an Array of Days Out Values
        const maxDaysOut = 90;
        const xAxisDaysOutValues = [-1, ...Array(maxDaysOut + 1).keys()].sort((a, b) => b - a);
        let currencyCode = (currency?.symbol) ? currency.symbol : currency?.code ?? "";


        // Loop Over the Raw Data Received from Network Response
        Object.keys(rawData).map((itemNumber) => {
            // Update the Default Overall Booking Chart Data
            // But Only If the Raw Data Exists for that Days Out
            let dateRangeInstance = rawData[itemNumber];
            let instanceData = [];
            for (let daysOut = -1; daysOut < maxDaysOut + 1; daysOut++) {
                // Generate a Default Overall Booking Chart Data
                let instanceDataItem = {
                    adr: null,
                    average_daily_rate: null,
                    average_occ: null,
                    average_rate: null,
                    average_rev: null,
                    currency_code: null,
                    days_out: daysOut,
                    rate: null,
                    total_inventory: null,
                    total_occ: null,
                    total_rev: null,
                    _id: daysOut
                }
                let dateRangeInstanceIdx = dateRangeInstance.findIndex((idx) => idx.days_out === daysOut);
                if (dateRangeInstanceIdx > -1) {
                    instanceDataItem = { ...instanceDataItem, ...dateRangeInstance[dateRangeInstanceIdx] }
                    // If there is no currency code found from state then use the currency code from the rawData.
                    if (!currencyCode) currencyCode = dateRangeInstance[dateRangeInstanceIdx].currency_code;
                }
                instanceData.push(instanceDataItem);
            }
            // And Then Sort The Overall Booking Chat Data By Decreasing Days Out Value
            // From Max Days Out (90) To Finals (-1)
            instanceData.sort((a, b) => { return b.days_out - a.days_out; });

            let rangeLabel = `Range ${itemNumber}: `;
            const isRange1 = rangeLabel.includes('Range 1');
            const isRange2 = rangeLabel.includes('Range 2');
            const isRange3 = rangeLabel.includes('Range 3');
            const isADR = rangeLabel.includes('ADR');
            const paceSelected = (defaultPaceFilterOptions.find(_ => _.value === pace_string))?.code;
            const lightOrange = "rgba(242, 143, 60, 0.5)";

            itemsToPlot.map((plot) => {
                let hasCurrency = ["average_rate", "average_rev", "total_rev", "average_daily_rate", "cmp_rate"].includes(plot);
                let hasPercentage = ["average_occ", "total_occ", "cmp_occ"].includes(plot);
                let dataType = strTranslation(plot);
                if (isRange2 && paceSelected) dataType = `${paceSelected} ${dataType}`;
                if (isRange3) dataType = `${comparison} ${dataType}`;

                // Default Booking Chart Information for Each Data Item
                let datasets = {
                    plot: plot,
                    label: `${rangeLabel}${dataType}`,
                    dataType: dataType,
                    data: [],
                    rangeStart: isRange3 ? dateRanges[0]?.startDate : dateRanges[itemNumber - 1]?.startDate,
                    rangeEnd: isRange3 ? dateRanges[0]?.endDate : dateRanges[itemNumber - 1]?.endDate,
                    fill: false,
                    type: plotType[plot],
                    borderColor: isRange3 ? lightOrange : colors[count],
                    yAxisID: yaxesID[plot],
                    backgroundColor: isRange3 ? (isADR ? lightOrange : "#f28f3c") : colors[count],
                    formatAsCurrency: hasCurrency,
                    currencyCode: currencyCode ? currencyCode : "",
                    segment: "ALLSEGMENTS",
                    showPercentage: hasPercentage
                };

                // Update Segment Fields
                datasets.segment = calendar_data.segments_string.replace(/ /g, "");
                // Loop Over The Overall Booking Chart Data Again
                // And Use That Information to Update Each Booking Chart Data Item
                instanceData.map((data, i) => {
                    // Update Data Array Field
                    let isAPickupDataItem = datasets.label === `${rangeLabel}${strTranslation("pickup")}`;
                    if (isAPickupDataItem) {
                        if (data.days_out >= 0 && dateRangeInstance[i + 1]) {
                            let pickup = dateRangeInstance[i + 1].average_rooms_sold - data.average_rooms_sold;
                            datasets.data.push(pickup);
                        }
                    } else {
                        datasets.data.push(data[plot] || 0)
                    }
                    return data;
                });

                values.push(datasets);
                count++;
                return plot;
            });

            // Generate the Final Overall Data for the Booking Chart
            overallData.labels = xAxisDaysOutValues;
            overallData.datasets = values;

            // Don't display item data in the chart, starting from the index of the item
            // which has a days out value that is larger than the days out value of the first item
            const getStartItemToHideIndex = (data) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i] > data[0]) return i;
                }
            }
            let startItemToHideIndex = getStartItemToHideIndex(overallData.labels);
            overallData.labels = overallData.labels.slice(0, startItemToHideIndex);

            return itemNumber;
        });
        return overallData;
    };



    const { getTokenSilently } = useAuth0();

    const handleSubmitForm = async (bcFilter, dateRanges, type) => {
        const token = await getTokenSilently();
        // bcFilter.pace_string = calendar_data.pace_string;
        // bcFilter.isDOW = calendar_data.pace_string === 'averagedowroomsold';
        // bcFilter.isAggregated = activeCalendarView !== 'daily';
        // bcFilter.as_of = calendar_data.as_of;
        // bcFilter.segments = calendar_data.segments;
        // bcFilter.roomtype = calendar_data.roomtype;

        if (!hideComparisons) {
            filter.comparison = {
                name: comparison_data.name,
                group: comparison_data.isGroup,
                property: comparison_data.property,
                properties: comparison_data.properties,
            }
        }

        const data = await fetch(`${constants.BASE_URL_2}/summary/bookingchart`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(
                { filter: { ...filter, checkin: bcFilter.check_in[0].range, type: calendar_data.pace_string } }
            ),
        });

        const dataJSON = await data.json();
        const response = dataJSON?.data ?? {};
        const graphData = generateGraph(response, dateRanges, itemsToPlot, plotType, colors);
        graphData.datasets = [...graphData.datasets];

        dispatch({ type: type, value: graphData });
        dispatch({
            type: constants.SET_ISLOADING,
            value: false,
            loading: "sidebarChartDataIsLoading",
        });
    };


    const updateGraphData = (item, value) => {
        const newData = chartData;
        newData.datasets.map((d) => {
            // to get main label without suffix of index
            const split = d.label.split(": ");
            if (split[1] === strTranslation(item)) {
                d.type = value;
            }

            if (item === "average_rooms_sold" && value !== "unplotted" && d.type !== "unplotted") {
                dispatch({ type: constants.UPDATE_SEGMENT_TYPE, value: value });
                Object.keys(segments).forEach((segment) => {
                    if (segment === split[1]) {
                        d.type = value;
                    }
                });
            }
            return d;
        });

        dispatch({ type: constants.SET_CHART_DATA, value: newData });
        dispatch({
            type: constants.UPDATE_PLOT_TYPES,
            value: { ...plotType, [item]: value },
        });
    };

    const addNewDateRange = (start, end, currentEndDate) => {
        const _startDate = start.format("YYYY-MM-DD");
        const _endDate = end.format("YYYY-MM-DD");
        if (currentEndDate !== _endDate) {
            const newDateRange = { startDate: _startDate, endDate: _endDate };
            dispatch({ type: constants.ADD_NEW_DATE_RANGE, value: newDateRange });
        }
    };

    const removeDateRange = (value) => {
        const newState = dateRanges.filter((item) => item !== value);
        dispatch({ type: constants.REMOVE_DATE_RANGE, value: newState });
    };

    const updateSegmentsTotal = (value) => {
        dispatch({ type: constants.UPDATE_SEGMENTS_TOTALS, value });
    };

    const updateSegmentsObject = (value) => {
        const newState = { ...segments, [value]: !segments[value] };
        dispatch({ type: constants.UPDATE_SEGMENTS, value: newState });
        let newChartData = Object.assign({}, chartData);
        newChartData = {
            ...newChartData,
            datasets: newChartData.datasets.map((element) => {
                const split = element.label.split(": ");

                if (value === split[1]) {
                    return {
                        ...element,
                        type: !segments[value] ? segmentType : "unplotted",
                    };
                } else {
                    return element;
                }
            }),
        };
        dispatch({ type: constants.SET_CHART_DATA, value: newChartData });
    };

    const resetChartForms = () => {
        dispatch({ type: constants.RESET_CHART_FORM, value: "" });
    };

    return {
        addNewDateRange,
        removeDateRange,
        handleSubmitForm,
        updateGraphData,
        updateSegmentsObject,
        updateSegmentsTotal,
        resetChartForms,
    };
};

export default useChartView;
