import React from 'react';
import { Modal } from 'antd';
import { Exit } from "Icons";
import Button from "../Button/Button";

const ConfirmationModal = ({ visible, text, confirmText = "Submit", cancelText = "Cancel", width = 500, eventHandler }) => {

    const closeIcon = <Exit width="25px" height="25px" className="pointer" />;
    
    return (
        <Modal
            open={visible}
            closeIcon={closeIcon}
            onCancel={() => eventHandler({ action: "cancel" })}
            width={width}
            footer={null}
        >
            <div className="rms-modal-content">
                {text}
                <div className="modal-buttons">
                    <Button type="bg-light" onClick={() => eventHandler({ action: "cancel" })}>
                        {cancelText}
                    </Button>
                    <Button type="primary" onClick={() => eventHandler({ action: "submit" })}>
                        {confirmText}
                    </Button>
                </div>
            </div>
        </Modal>);
    
}

export default ConfirmationModal;