import React from "react";
import NavNext from "./NavNext";
import NavPrev from "./NavPrev";
// import moment from "moment";
import dayjs from "dayjs";
import OutlinedIcon from "elements/Icon/OutlinedIcon";
import { useSelector } from "react-redux";


const NavWithIndicator = ({ disabled = false }) => {
    const state = useSelector(state => state.mainReducer);
    const dashboard = useSelector(state => state.dashboard);
    const { customDailyData, dailyData } = dashboard;
    const { sidebarData, activeCalendarView } = state;
    
    const isCustomDailyActive = activeCalendarView === 'custom';
    const isFirstDate = () => {
        if (isCustomDailyActive) return dayjs(sidebarData?.check_in).isSameOrBefore(customDailyData[0]?.cin);
        return dayjs(sidebarData?.check_in).isSameOrBefore(dailyData[0]?.cin);
    }
    const isLastDate = () => {
        if (isCustomDailyActive) return dayjs(sidebarData?.check_in).isSameOrAfter(customDailyData[customDailyData.length - 1]?.cin);
        return dayjs(sidebarData?.check_in).isSameOrAfter(dailyData[dailyData.length - 1]?.cin);
    }

    return <div className="stay-date-navigator" style={{ float: "right", margin: "5px 8px 4px 4px", display: "flex", width: "65px", justifyContent: "space-between", height: "22px" }}>
        <NavPrev disabled={!disabled && isCustomDailyActive && isFirstDate()? true: disabled} />
        { !disabled && <OutlinedIcon className="stay-date-navigator-icon" type="calendar" /> }
        { disabled && <OutlinedIcon className="stay-date-navigator-loading" type="loading" />}
        <NavNext disabled={!disabled && isCustomDailyActive && isLastDate()? true: disabled} />  
    </div>
}

export default NavWithIndicator;