import { useState, useEffect } from 'react';
import { isNullOrUndefined } from '../../utils/index';

const useFormModalController = () => {
    const [visible, setVisible] = useState(false);
    const [rawData, setRawData] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [editedValues, setEditedValues] = useState({});
    const [validations, setValidations] = useState({});
    const [errors, setErrors] = useState({});
    
    useEffect(() => {
        setInputValues({ ...rawData });
    }, [rawData]);

    const show = (isVisible = true, data = null, cb) => {
        if (isVisible === true) resetForm();
        if (!isNullOrUndefined(data)) loadData(data);
        setVisible(isVisible);
        if (typeof cb === 'function') cb();
    }    
    const loadData = (data) => {
        setRawData(data);
    }
    const onChangeHandler = (key, value) => {
        setInputValues({ ...inputValues, [key]: value });
    }
    const onBlurHandler = (key, value) => {
        if (rawData[key] !== value) {
            setEditedValues({ ...editedValues, [key]: value });
            // Validate field
            if (validations[key]) {
                let errorsTmp = { ...errors, [key]: validateField(value, validations[key]) };
                if (!errorsTmp[key]) delete errorsTmp[key];
                setErrors(errorsTmp);
            }
        } else {
            let editedValuesTmp = { ...editedValues };
            delete editedValuesTmp[key];
            setEditedValues(editedValuesTmp)
        }
    }

    const resetForm = () => {
        setInputValues({});
        setEditedValues({});
        setErrors({});
    }

    const validateField = (value, validation) => {
        if (!validation) return;

        if (typeof validation === 'function') {
            return validation();
        }
        /* Required */
        if (validation.required && validation.required === true) {
            if (!value)
                return 'This field is required';
        }
        /* Characters */
        if (validation.characters && value) {
            switch (validation.characters) {
                case 'alphanumeric':
                    if (!/^[a-zA-Z0-9]*$/.test(value))
                        return 'Alphanumeric characters only';
                    break;
                case 'alphanumeric_with_space':
                    if (!/^[a-zA-Z0-9\s]*$/.test(value))
                        return 'Alphanumeric characters and space only';
                    break;
                case 'integer':
                    if (!/^\d+$/.test(value))
                        return 'Integers only';
                    break;
                default:
                    break;
            }
        }
        /* Maximum Characters */
        if (validation.maxChars && value) {
            if (value.length > validation.maxChars)
                return `Maximum characters: ${validation.maxChars}`;
        }
    };

    const validateFields = () => {
        const errorsFound = {};

        Object.keys(validations).forEach(field_name => {
            let validation = validations[field_name];
            let value = inputValues[field_name];
            let error = validateField(value, validation);

            if (error) errorsFound[field_name] = error;
        });

        setErrors(errorsFound);
        return errorsFound;
    }

    return {
        rawData,
        inputValues, setInputValues,
        editedValues, setEditedValues,
        validations, setValidations,
        visible,
        errors, setErrors,
        resetForm,
        loadData,
        show,
        validateFields,
        onChangeHandler,
        onBlurHandler,
    }
}

export default useFormModalController;