import React from 'react';
import { 
    CloseCircleFilled, 
} from '@ant-design/icons';

const FilledIcon = ({ type, ...props }) => {
    switch(type) {
        case 'close-circle': return <CloseCircleFilled {...props} />
        default: <></>
    }
}

export default FilledIcon;