import * as React from "react";

function SvgMap(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path
        d="M50 12.25a30.69 30.69 0 00-30.69 30.69c0 13.46 19.36 35.06 27.33 43.37a4.64 4.64 0 006.72 0c8-8.32 27.33-29.91 27.33-43.37A30.69 30.69 0 0050 12.25zm0 46.49a16.41 16.41 0 1116.4-16.41A16.41 16.41 0 0150 58.74z"
        fill="#67b489"
        data-name="Layer 1"
      />
    </svg>
  );
}

export default SvgMap;
