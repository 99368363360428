import React from "react";
import { useSelector } from "react-redux";

import TableChart from "elements/Chart/TableChart";

import { isNullOrUndefined, rangesToDates } from "utils";


/**
 * Settings View of the Algorithm Modal.
 *
 * @return {Component}
*/
const AlgorithmModalSettings = () => {
    const { sidebarData, propertyInView } = useSelector(state => state.mainReducer);
    const isPerDaysOut = true; // Use dynamic forecast settings (forecast settings per days out)

    const settings = propertyInView.forecast_settings_v2;

    // General Algorithm Settings Data from Current Active Property In View
    let pickupSettings = null;
    let paceSettings = null;
    let priceSettings = null;
    let finalsSettings = null;
    let priceRecoSettings = null;
    let weightSettings = null;

    if (!isPerDaysOut && !isNullOrUndefined(propertyInView) && !isNullOrUndefined(settings)) {
        // Fixed Property's Forecast Settings
        pickupSettings = settings.pickup;
        paceSettings = settings.pace;
        priceSettings = settings.price;
        finalsSettings = settings.finals;
        priceRecoSettings = settings.pricing_recommendation;
        weightSettings = settings.weights;
    }

    if (isPerDaysOut) {
        if (sidebarData?._forecast?.settings) {
            // Dynamic Forecast Settings
            const usedVariables = sidebarData._forecast.settings.variables;
            const usedForecast =  sidebarData._forecast.settings.forecast;

            pickupSettings = { avg_days: {}, instances: {}, threshold: {}, days: null };

            pickupSettings.avg_days.sdly = usedVariables.avg_days.pickup.sdly;
            pickupSettings.avg_days.avg_dow = usedVariables.avg_days.pickup.avg_dow;
            pickupSettings.avg_days.avg = usedVariables.avg_days.pickup.avg;
            pickupSettings.avg_days.custom_avg_dow = rangesToDates(usedVariables.avg_days.pickup.custom_avg_dow).length;
            pickupSettings.avg_days.custom_avg = rangesToDates(usedVariables.avg_days.pickup.custom_avg).length;
            
            pickupSettings.instances.sdly = usedVariables.instances.pickup.sdly;
            pickupSettings.instances.avg_dow = usedVariables.instances.pickup.avg_dow;
            pickupSettings.instances.avg = usedVariables.instances.pickup.avg;
            pickupSettings.instances.custom_avg_dow = usedVariables.instances.pickup.custom_avg_dow;
            pickupSettings.instances.custom_avg = usedVariables.instances.pickup.custom_avg;

            pickupSettings.threshold.sdly = { avg_days: {} };
            pickupSettings.threshold.sdly.avg_days.good = usedVariables.colors.pickup.sdly.days.green;
            pickupSettings.threshold.sdly.avg_days.avg = usedVariables.colors.pickup.sdly.days.grey;
            pickupSettings.threshold.sdly.avg_days.below_avg = usedVariables.colors.pickup.sdly.days.yellow;
            pickupSettings.threshold.sdly.avg_days.bad = usedVariables.colors.pickup.sdly.days.red;
            
            pickupSettings.threshold.avg_dow = { avg_days: {} };
            pickupSettings.threshold.avg_dow.avg_days.good = usedVariables.colors.pickup.avg_dow.days.green;
            pickupSettings.threshold.avg_dow.avg_days.avg = usedVariables.colors.pickup.avg_dow.days.grey;
            pickupSettings.threshold.avg_dow.avg_days.below_avg = usedVariables.colors.pickup.avg_dow.days.yellow;
            pickupSettings.threshold.avg_dow.avg_days.bad = usedVariables.colors.pickup.avg_dow.days.red;
            
            pickupSettings.threshold.avg = { avg_days: {} };
            pickupSettings.threshold.avg.avg_days.good = usedVariables.colors.pickup.avg.days.green;
            pickupSettings.threshold.avg.avg_days.avg = usedVariables.colors.pickup.avg.days.grey;
            pickupSettings.threshold.avg.avg_days.below_avg = usedVariables.colors.pickup.avg.days.yellow;
            pickupSettings.threshold.avg.avg_days.bad = usedVariables.colors.pickup.avg.days.red;

            pickupSettings.threshold.custom_avg_dow = { avg_days: {} };
            pickupSettings.threshold.custom_avg_dow.avg_days.good = usedVariables.colors.pickup.custom_avg_dow.days.green;
            pickupSettings.threshold.custom_avg_dow.avg_days.avg = usedVariables.colors.pickup.custom_avg_dow.days.grey;
            pickupSettings.threshold.custom_avg_dow.avg_days.below_avg = usedVariables.colors.pickup.custom_avg_dow.days.yellow;
            pickupSettings.threshold.custom_avg_dow.avg_days.bad = usedVariables.colors.pickup.custom_avg_dow.days.red;
            
            pickupSettings.threshold.custom_avg = { avg_days: {} };
            pickupSettings.threshold.custom_avg.avg_days.good = usedVariables.colors.pickup.custom_avg.days.green;
            pickupSettings.threshold.custom_avg.avg_days.avg = usedVariables.colors.pickup.custom_avg.days.grey;
            pickupSettings.threshold.custom_avg.avg_days.below_avg = usedVariables.colors.pickup.custom_avg.days.yellow;
            pickupSettings.threshold.custom_avg.avg_days.bad = usedVariables.colors.pickup.custom_avg.days.red;
            
            pickupSettings.days = usedVariables.instances.pickup.days;

            paceSettings = { avg_days: {}, instances: {}, threshold: {}, days: null };
            
            paceSettings.avg_days.sdly = usedVariables.avg_days.pace.sdly;
            paceSettings.avg_days.avg_dow = usedVariables.avg_days.pace.avg_dow;
            paceSettings.avg_days.avg = usedVariables.avg_days.pace.avg;
            paceSettings.avg_days.custom_avg_dow = rangesToDates(usedVariables.avg_days.pace.custom_avg_dow).length;
            paceSettings.avg_days.custom_avg = rangesToDates(usedVariables.avg_days.pace.custom_avg).length;
            
            paceSettings.instances.sdly = usedVariables.instances.pace.sdly;
            paceSettings.instances.avg_dow = usedVariables.instances.pace.avg_dow;
            paceSettings.instances.avg = usedVariables.instances.pace.avg;
            paceSettings.instances.custom_avg_dow = usedVariables.instances.pace.custom_avg_dow;
            paceSettings.instances.custom_avg = usedVariables.instances.pace.custom_avg;

            paceSettings.threshold.sdly = { avg_days: {} };
            paceSettings.threshold.sdly.avg_days.good = usedVariables.colors.pace.sdly.days.green;
            paceSettings.threshold.sdly.avg_days.avg = usedVariables.colors.pace.sdly.days.grey;
            paceSettings.threshold.sdly.avg_days.below_avg = usedVariables.colors.pace.sdly.days.yellow;
            paceSettings.threshold.sdly.avg_days.bad = usedVariables.colors.pace.sdly.days.red;
            
            paceSettings.threshold.avg_dow = { avg_days: {} };
            paceSettings.threshold.avg_dow.avg_days.good = usedVariables.colors.pace.avg_dow.days.green;
            paceSettings.threshold.avg_dow.avg_days.avg = usedVariables.colors.pace.avg_dow.days.grey;
            paceSettings.threshold.avg_dow.avg_days.below_avg = usedVariables.colors.pace.avg_dow.days.yellow;
            paceSettings.threshold.avg_dow.avg_days.bad = usedVariables.colors.pace.avg_dow.days.red;
            
            paceSettings.threshold.avg = { avg_days: {} };
            paceSettings.threshold.avg.avg_days.good = usedVariables.colors.pace.avg.days.green;
            paceSettings.threshold.avg.avg_days.avg = usedVariables.colors.pace.avg.days.grey;
            paceSettings.threshold.avg.avg_days.below_avg = usedVariables.colors.pace.avg.days.yellow;
            paceSettings.threshold.avg.avg_days.bad = usedVariables.colors.pace.avg.days.red;

            paceSettings.threshold.custom_avg_dow = { avg_days: {} };
            paceSettings.threshold.custom_avg_dow.avg_days.good = usedVariables.colors.pace.custom_avg_dow.days.green;
            paceSettings.threshold.custom_avg_dow.avg_days.avg = usedVariables.colors.pace.custom_avg_dow.days.grey;
            paceSettings.threshold.custom_avg_dow.avg_days.below_avg = usedVariables.colors.pace.custom_avg_dow.days.yellow;
            paceSettings.threshold.custom_avg_dow.avg_days.bad = usedVariables.colors.pace.custom_avg_dow.days.red;
            
            paceSettings.threshold.custom_avg = { avg_days: {} };
            paceSettings.threshold.custom_avg.avg_days.good = usedVariables.colors.pace.custom_avg.days.green;
            paceSettings.threshold.custom_avg.avg_days.avg = usedVariables.colors.pace.custom_avg.days.grey;
            paceSettings.threshold.custom_avg.avg_days.below_avg = usedVariables.colors.pace.custom_avg.days.yellow;
            paceSettings.threshold.custom_avg.avg_days.bad = usedVariables.colors.pace.custom_avg.days.red;
            
            paceSettings.days = usedVariables.instances.pickup.days;

            priceSettings = { avg_days: {}, threshold: {} };
            
            priceSettings.avg_days.sdly = usedVariables.avg_days.price.sdly;
            priceSettings.avg_days.avg_dow = usedVariables.avg_days.price.avg_dow;
            priceSettings.avg_days.avg = usedVariables.avg_days.price.avg;
            priceSettings.avg_days.remaindays_sdly = usedVariables.avg_days.price.remaindays_sdly;
            priceSettings.avg_days.remaindays_avg_dow = usedVariables.avg_days.price.remaindays_avg_dow;
            priceSettings.avg_days.remaindays_avg = usedVariables.avg_days.price.remaindays_avg;
            priceSettings.avg_days.custom_avg_dow = rangesToDates(usedVariables.avg_days.price.custom_avg_dow).length;
            priceSettings.avg_days.custom_avg = rangesToDates(usedVariables.avg_days.price.custom_avg).length;
            priceSettings.avg_days.remaindays_custom_avg_dow = rangesToDates(usedVariables.avg_days.price.remaindays_custom_avg_dow).length;
            priceSettings.avg_days.remaindays_custom_avg = rangesToDates(usedVariables.avg_days.price.remaindays_custom_avg).length;
            

            priceSettings.threshold = {};
            priceSettings.threshold.good = usedVariables.colors.price.property.green;
            priceSettings.threshold.avg = usedVariables.colors.price.property.grey;
            priceSettings.threshold.below_avg = usedVariables.colors.price.property.yellow;
            priceSettings.threshold.bad = usedVariables.colors.price.property.red;
            priceSettings.competitor_threshold = {};
            priceSettings.competitor_threshold.good = usedVariables.colors.price.competitor.green;
            priceSettings.competitor_threshold.avg = usedVariables.colors.price.competitor.grey;
            priceSettings.competitor_threshold.below_avg = usedVariables.colors.price.competitor.yellow;
            priceSettings.competitor_threshold.bad = usedVariables.colors.price.competitor.red;

            finalsSettings = { avg_days: {}, threshold: {} };

            finalsSettings.avg_days.sdly = usedVariables.avg_days.finals.sdly;
            finalsSettings.avg_days.avg_dow = usedVariables.avg_days.finals.avg_dow;
            finalsSettings.avg_days.avg = usedVariables.avg_days.finals.avg;
            finalsSettings.avg_days.custom_avg_dow = rangesToDates(usedVariables.avg_days.finals.custom_avg_dow).length;
            finalsSettings.avg_days.custom_avg = rangesToDates(usedVariables.avg_days.finals.custom_avg).length;

            finalsSettings.threshold.sdly = {};
            finalsSettings.threshold.sdly.good = usedVariables.colors.finals.sdly.green;
            finalsSettings.threshold.sdly.avg = usedVariables.colors.finals.sdly.grey;
            finalsSettings.threshold.sdly.below_avg = usedVariables.colors.finals.sdly.yellow;
            finalsSettings.threshold.sdly.bad = usedVariables.colors.finals.sdly.red;
            
            finalsSettings.threshold.avg_dow = {};
            finalsSettings.threshold.avg_dow.good = usedVariables.colors.finals.avg_dow.green;
            finalsSettings.threshold.avg_dow.avg = usedVariables.colors.finals.avg_dow.grey;
            finalsSettings.threshold.avg_dow.below_avg = usedVariables.colors.finals.avg_dow.yellow;
            finalsSettings.threshold.avg_dow.bad = usedVariables.colors.finals.avg_dow.red;
            
            finalsSettings.threshold.avg = {};
            finalsSettings.threshold.avg.good = usedVariables.colors.finals.avg.green;
            finalsSettings.threshold.avg.avg = usedVariables.colors.finals.avg.grey;
            finalsSettings.threshold.avg.below_avg = usedVariables.colors.finals.avg.yellow;
            finalsSettings.threshold.avg.bad = usedVariables.colors.finals.avg.red;

            finalsSettings.threshold.otb = {};
            finalsSettings.threshold.otb.good = usedVariables.colors.finals.otb.green;
            finalsSettings.threshold.otb.avg = usedVariables.colors.finals.otb.grey;
            finalsSettings.threshold.otb.below_avg = usedVariables.colors.finals.otb.yellow;
            finalsSettings.threshold.otb.bad = usedVariables.colors.finals.otb.red;
            
            finalsSettings.threshold.custom_avg_dow = {};
            finalsSettings.threshold.custom_avg_dow.good = usedVariables.colors.finals.custom_avg_dow.green;
            finalsSettings.threshold.custom_avg_dow.avg = usedVariables.colors.finals.custom_avg_dow.grey;
            finalsSettings.threshold.custom_avg_dow.below_avg = usedVariables.colors.finals.custom_avg_dow.yellow;
            finalsSettings.threshold.custom_avg_dow.bad = usedVariables.colors.finals.custom_avg_dow.red;
            
            finalsSettings.threshold.custom_avg = {};
            finalsSettings.threshold.custom_avg.good = usedVariables.colors.finals.custom_avg.green;
            finalsSettings.threshold.custom_avg.avg = usedVariables.colors.finals.custom_avg.grey;
            finalsSettings.threshold.custom_avg.below_avg = usedVariables.colors.finals.custom_avg.yellow;
            finalsSettings.threshold.custom_avg.bad = usedVariables.colors.finals.custom_avg.red;

            priceRecoSettings = { threshold: { good: {}, bad: {} } };

            priceRecoSettings.threshold.good.level_1 = usedVariables.price_reco_arrows.good.level_1;
            priceRecoSettings.threshold.good.level_2 = usedVariables.price_reco_arrows.good.level_2;
            priceRecoSettings.threshold.good.level_3 = usedVariables.price_reco_arrows.good.level_3;

            priceRecoSettings.threshold.bad.level_1 = usedVariables.price_reco_arrows.bad.level_1;
            priceRecoSettings.threshold.bad.level_2 = usedVariables.price_reco_arrows.bad.level_2;
            priceRecoSettings.threshold.bad.level_3 = usedVariables.price_reco_arrows.bad.level_3;

            weightSettings = { pickup: {}, pace: {}, price: {}, finals: {}, forecast: {} }

            weightSettings.pickup.sdly = usedForecast.pickup.sdly;
            weightSettings.pickup.avg_dow = usedForecast.pickup.avg_dow;
            weightSettings.pickup.avg = usedForecast.pickup.avg;
            weightSettings.pickup.trend_sdly = usedForecast.pickup.trend_sdly;
            weightSettings.pickup.trend_avg_dow = usedForecast.pickup.trend_avg_dow;
            weightSettings.pickup.trend_avg = usedForecast.pickup.trend_avg;
            weightSettings.pickup.custom_avg_dow = usedForecast.pickup.custom_avg_dow;
            weightSettings.pickup.custom_avg = usedForecast.pickup.custom_avg;
            weightSettings.pickup.trend_custom_avg_dow = usedForecast.pickup.trend_custom_avg_dow;
            weightSettings.pickup.trend_custom_avg = usedForecast.pickup.trend_custom_avg;

            weightSettings.pace.sdly = usedForecast.pace.sdly;
            weightSettings.pace.avg_dow = usedForecast.pace.avg_dow;
            weightSettings.pace.avg = usedForecast.pace.avg;
            weightSettings.pace.trend_sdly = usedForecast.pace.trend_sdly;
            weightSettings.pace.trend_avg_dow = usedForecast.pace.trend_avg_dow;
            weightSettings.pace.trend_avg = usedForecast.pace.trend_avg;
            weightSettings.pace.custom_avg_dow = usedForecast.pace.custom_avg_dow;
            weightSettings.pace.custom_avg = usedForecast.pace.custom_avg;
            weightSettings.pace.trend_custom_avg_dow = usedForecast.pace.trend_custom_avg_dow;
            weightSettings.pace.trend_custom_avg = usedForecast.pace.trend_custom_avg;
            
            weightSettings.price.sdly = usedForecast.price.sdly;
            weightSettings.price.avg_dow = usedForecast.price.avg_dow;
            weightSettings.price.avg = usedForecast.price.avg;
            weightSettings.price.remaindays_sdly = usedForecast.price.remaindays_sdly;
            weightSettings.price.remaindays_avg_dow = usedForecast.price.remaindays_avg_dow;
            weightSettings.price.remaindays_avg = usedForecast.price.remaindays_avg;
            weightSettings.price.competitor_pricing = usedForecast.price.competitor_pricing;
            weightSettings.price.custom_avg_dow = usedForecast.price.custom_avg_dow;
            weightSettings.price.custom_avg = usedForecast.price.custom_avg;
            weightSettings.price.remaindays_custom_avg_dow = usedForecast.price.remaindays_custom_avg_dow;
            weightSettings.price.remaindays_custom_avg = usedForecast.price.remaindays_custom_avg;

            weightSettings.finals.sdly = usedForecast.finals.sdly;
            weightSettings.finals.avg_dow = usedForecast.finals.avg_dow;
            weightSettings.finals.avg = usedForecast.finals.avg;
            weightSettings.finals.otb = usedForecast.finals.otb;
            weightSettings.finals.custom_avg_dow = usedForecast.finals.custom_avg_dow;
            weightSettings.finals.custom_avg = usedForecast.finals.custom_avg;

            weightSettings.forecast.pickup = usedForecast.breakdown.pickup;
            weightSettings.forecast.pace = usedForecast.breakdown.pace;
            weightSettings.forecast.finals = usedForecast.breakdown.finals;
        } else {
            return <></>
        }
    }

    // Pickup Settings Data
    let pickup_headers = [
        "Variables",
        "Average Days",
        "Green",
        "Grey",
        "Yellow",
        "Red",
        "Instances",
    ];
    let pickup_data = {
        sdly: {
            name: "SDLY",
            data: [
                pickupSettings.avg_days.sdly,
                `${pickupSettings.threshold.sdly.avg_days.good}%`,
                `${pickupSettings.threshold.sdly.avg_days.avg}%`,
                `${pickupSettings.threshold.sdly.avg_days.below_avg}%`,
                `${pickupSettings.threshold.sdly.avg_days.bad}%`,
                pickupSettings.instances.sdly,
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                pickupSettings.avg_days.avg,
                `${pickupSettings.threshold.avg.avg_days.good}%`,
                `${pickupSettings.threshold.avg.avg_days.avg}%`,
                `${pickupSettings.threshold.avg.avg_days.below_avg}%`,
                `${pickupSettings.threshold.avg.avg_days.bad}%`,
                pickupSettings.instances.avg,
            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                pickupSettings.avg_days.avg_dow,
                `${pickupSettings.threshold.avg_dow.avg_days.good}%`,
                `${pickupSettings.threshold.avg_dow.avg_days.avg}%`,
                `${pickupSettings.threshold.avg_dow.avg_days.below_avg}%`,
                `${pickupSettings.threshold.avg_dow.avg_days.bad}%`,
                pickupSettings.instances.avg_dow,
            ],
            color: null
        },
        custom_avg: {
            name: "Custom AVG",
            data: [
                pickupSettings.avg_days.custom_avg,
                `${pickupSettings.threshold.custom_avg.avg_days.good}%`,
                `${pickupSettings.threshold.custom_avg.avg_days.avg}%`,
                `${pickupSettings.threshold.custom_avg.avg_days.below_avg}%`,
                `${pickupSettings.threshold.custom_avg.avg_days.bad}%`,
                pickupSettings.instances.custom_avg,
            ],
            color: null
        },
        custom_avg_dow: {
            name: "Custom AVG DOW",
            data: [
                pickupSettings.avg_days.custom_avg_dow,
                `${pickupSettings.threshold.custom_avg_dow.avg_days.good}%`,
                `${pickupSettings.threshold.custom_avg_dow.avg_days.avg}%`,
                `${pickupSettings.threshold.custom_avg_dow.avg_days.below_avg}%`,
                `${pickupSettings.threshold.custom_avg_dow.avg_days.bad}%`,
                pickupSettings.instances.custom_avg_dow,
            ],
            color: null
        },
    };


    // Pace Settings Data
    let pace_headers = [
        "Variables",
        "Average Days",
        "Green",
        "Grey",
        "Yellow",
        "Red",
        "Instances",
    ];
    let pace_data = {
        sdly: {
            name: "SDLY",
            data: [
                paceSettings.avg_days.sdly,
                `${paceSettings.threshold.sdly.avg_days.good}%`,
                `${paceSettings.threshold.sdly.avg_days.avg}%`,
                `${paceSettings.threshold.sdly.avg_days.below_avg}%`,
                `${paceSettings.threshold.sdly.avg_days.bad}%`,
                paceSettings.instances.sdly,
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                paceSettings.avg_days.avg,
                `${paceSettings.threshold.avg.avg_days.good}%`,
                `${paceSettings.threshold.avg.avg_days.avg}%`,
                `${paceSettings.threshold.avg.avg_days.below_avg}%`,
                `${paceSettings.threshold.avg.avg_days.bad}%`,
                paceSettings.instances.avg,
            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                paceSettings.avg_days.avg_dow,
                `${paceSettings.threshold.avg_dow.avg_days.good}%`,
                `${paceSettings.threshold.avg_dow.avg_days.avg}%`,
                `${paceSettings.threshold.avg_dow.avg_days.below_avg}%`,
                `${paceSettings.threshold.avg_dow.avg_days.bad}%`,
                paceSettings.instances.avg_dow,
            ],
            color: null
        },
        custom_avg: {
            name: "Custom AVG",
            data: [
                paceSettings.avg_days.custom_avg,
                `${paceSettings.threshold.avg.avg_days.good}%`,
                `${paceSettings.threshold.avg.avg_days.avg}%`,
                `${paceSettings.threshold.avg.avg_days.below_avg}%`,
                `${paceSettings.threshold.avg.avg_days.bad}%`,
                paceSettings.instances.custom_avg,
            ],
            color: null
        },
        custom_avg_dow: {
            name: "Custom AVG DOW",
            data: [
                paceSettings.avg_days.custom_avg_dow,
                `${paceSettings.threshold.avg_dow.avg_days.good}%`,
                `${paceSettings.threshold.avg_dow.avg_days.avg}%`,
                `${paceSettings.threshold.avg_dow.avg_days.below_avg}%`,
                `${paceSettings.threshold.avg_dow.avg_days.bad}%`,
                paceSettings.instances.custom_avg_dow,
            ],
            color: null
        },
    };


    // Price Average Days Settings Data
    let price_avgdays_headers = [
        "",
        "SDLY",
        "AVG",
        "AVG DOW",
        "Remain Days SDLY",
        "Remain Days AVG",
        "Remain Days AVG DOW",
        "Custom AVG",
        "Custom AVG DOW",
        "Custom RemDays AVG",
        "Custom RemDays AVG DOW"
    ];
    let price_avgdays_data = {
        avg_days: {
            name: "Average Days",
            data: [
                priceSettings.avg_days.sdly,
                priceSettings.avg_days.avg,
                priceSettings.avg_days.avg_dow,
                priceSettings.avg_days.remaindays_sdly,
                priceSettings.avg_days.remaindays_avg,
                priceSettings.avg_days.remaindays_avg_dow,
                priceSettings.avg_days.custom_avg,
                priceSettings.avg_days.custom_avg_dow,
                priceSettings.avg_days.remaindays_custom_avg,
                priceSettings.avg_days.remaindays_custom_avg_dow,
            ],
            color: null
        },
    };


    // Price Threshold Settings Data
    let price_threshold_headers = [
        "",
        "Green",
        "Grey",
        "Yellow",
        "Red",
    ];
    let price_threshold_data = {
        threshold: {
            name: "Property",
            data: [
                `${priceSettings.threshold.good}%`,
                `${priceSettings.threshold.avg}%`,
                `${priceSettings.threshold.below_avg}%`,
                `${priceSettings.threshold.bad}%`,
            ],
            color: null
        },
        competitor_threshold: {
            name: "Competitor",
            data: [
                `${priceSettings.competitor_threshold.good}%`,
                `${priceSettings.competitor_threshold.avg}%`,
                `${priceSettings.competitor_threshold.below_avg}%`,
                `${priceSettings.competitor_threshold.bad}%`,
            ],
            color: null
        },
    };


    // Finals Settings Data
    let finals_headers = [
        "Variables",
        "Average Days",
        "Green",
        "Grey",
        "Yellow",
        "Red",
    ];
    let finals_data = {
        sdly: {
            name: "SDLY",
            data: [
                finalsSettings.avg_days.sdly,
                `${finalsSettings.threshold.sdly.good}%`,
                `${finalsSettings.threshold.sdly.avg}%`,
                `${finalsSettings.threshold.sdly.below_avg}%`,
                `${finalsSettings.threshold.sdly.bad}%`,
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                finalsSettings.avg_days.avg,
                `${finalsSettings.threshold.avg.good}%`,
                `${finalsSettings.threshold.avg.avg}%`,
                `${finalsSettings.threshold.avg.below_avg}%`,
                `${finalsSettings.threshold.avg.bad}%`,
            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                finalsSettings.avg_days.avg_dow,
                `${finalsSettings.threshold.avg_dow.good}%`,
                `${finalsSettings.threshold.avg_dow.avg}%`,
                `${finalsSettings.threshold.avg_dow.below_avg}%`,
                `${finalsSettings.threshold.avg_dow.bad}%`,
            ],
            color: null
        },
        custom_avg: {
            name: "Custom AVG",
            data: [
                finalsSettings.avg_days.custom_avg,
                `${finalsSettings.threshold.custom_avg.good}%`,
                `${finalsSettings.threshold.custom_avg.avg}%`,
                `${finalsSettings.threshold.custom_avg.below_avg}%`,
                `${finalsSettings.threshold.custom_avg.bad}%`,
            ],
            color: null
        },
        custom_avg_dow: {
            name: "Custom AVG DOW",
            data: [
                finalsSettings.avg_days.custom_avg_dow,
                `${finalsSettings.threshold.custom_avg_dow.good}%`,
                `${finalsSettings.threshold.custom_avg_dow.avg}%`,
                `${finalsSettings.threshold.custom_avg_dow.below_avg}%`,
                `${finalsSettings.threshold.custom_avg_dow.bad}%`,
            ],
            color: null
        },
        otb: {
            name: "OTB",
            data: [
                finalsSettings.avg_days.otb,
                `${finalsSettings.threshold.otb.good}%`,
                `${finalsSettings.threshold.otb.avg}%`,
                `${finalsSettings.threshold.otb.below_avg}%`,
                `${finalsSettings.threshold.otb.bad}%`,
            ],
            color: null
        }
    };


    // Price Recommendation Settings Data
    let price_reco_headers = [
        "",
        "Level 1",
        "Level 2",
        "Level 3",
    ];
    let price_reco_data = {
        good: {
            name: "Good",
            data: [
                `${priceRecoSettings.threshold.good.level_1}%`,
                `${priceRecoSettings.threshold.good.level_2}%`,
                `${priceRecoSettings.threshold.good.level_3}%`,
            ],
            color: null
        },
        bad: {
            name: "Bad",
            data: [
                `${priceRecoSettings.threshold.bad.level_1}%`,
                `${priceRecoSettings.threshold.bad.level_2}%`,
                `${priceRecoSettings.threshold.bad.level_3}%`,
            ],
            color: null
        },
    };


    // Forecast Weights Settings Data
    let weights_headers = [
        "",
        "SDLY",
        "AVG",
        "AVG DOW",
        "Custom AVG",
        "Custom AVG DOW",
        "SDLY Trend / RemainDays",
        "AVG Trend / RemainDays",
        "AVG DOW Trend / RemainDays",
        "Custom AVG Trend / RemainDays",
        "Custom AVG DOW Trend / RemainDays",
        "Competitor Pricing",
        "OTB",
    ];
    let weights_data = {
        pickup: {
            name: "Pickup",
            data: [
                `${weightSettings.pickup.sdly}%`,
                `${weightSettings.pickup.avg}%`,
                `${weightSettings.pickup.avg_dow}%`,
                `${weightSettings.pickup.custom_avg}%`,
                `${weightSettings.pickup.custom_avg_dow}%`,
                `${weightSettings.pickup.trend_sdly}%`,
                `${weightSettings.pickup.trend_avg}%`,
                `${weightSettings.pickup.trend_avg_dow}%`,
                `${weightSettings.pickup.trend_custom_avg}%`,
                `${weightSettings.pickup.trend_custom_avg_dow}%`,
                "N/A",
                "N/A",
            ],
            color: null
        },
        pace: {
            name: "Pace",
            data: [
                `${weightSettings.pace.sdly}%`,
                `${weightSettings.pace.avg}%`,
                `${weightSettings.pace.avg_dow}%`,
                `${weightSettings.pace.custom_avg}%`,
                `${weightSettings.pace.custom_avg_dow}%`,
                `${weightSettings.pace.trend_sdly}%`,
                `${weightSettings.pace.trend_avg}%`,
                `${weightSettings.pace.trend_avg_dow}%`,
                `${weightSettings.pace.trend_custom_avg}%`,
                `${weightSettings.pace.trend_custom_avg_dow}%`,
                "N/A",
                "N/A",
            ],
            color: null
        },
        price: {
            name: "Price",
            data: [
                `${weightSettings.price.sdly}%`,
                `${weightSettings.price.avg}%`,
                `${weightSettings.price.avg_dow}%`,
                `${weightSettings.price.custom_avg}%`,
                `${weightSettings.price.custom_avg_dow}%`,
                `${weightSettings.price.remaindays_sdly}%`,
                `${weightSettings.price.remaindays_avg}%`,
                `${weightSettings.price.remaindays_avg_dow}%`,
                `${weightSettings.price.remaindays_custom_avg}%`,
                `${weightSettings.price.remaindays_custom_avg_dow}%`,
                `${weightSettings.price.competitor_pricing}%`,
                "N/A"
            ],
            color: null
        },
        finals: {
            name: "finals",
            data: [
                `${weightSettings.finals.sdly}%`,
                `${weightSettings.finals.avg}%`,
                `${weightSettings.finals.avg_dow}%`,
                `${weightSettings.finals.custom_avg}%`,
                `${weightSettings.finals.custom_avg_dow}%`,
                "N/A",
                "N/A",
                "N/A",
                "N/A",
                "N/A",
                "N/A",
                `${weightSettings.finals.otb}%`
            ],
            color: null
        },
    };



    return (
        <div>
            <div style={{
                display: "grid", gridAutoFlow: "column", width: "100%",
                justifyItems: "center", gridTemplateColumns: "50% 50%", marginBottom: 20
            }}>
                {/* Pickup Settings Table */}
                <div >
                    <p>
                        <strong>PICKUP FORECAST</strong>
                        <br />
                        <strong>{`Instance Days: ${pickupSettings.days}`}</strong>
                    </p>
                    <TableChart headers={pickup_headers} data={pickup_data}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} />
                </div>

                {/* Pace Settings Table */}
                <div style={{ marginLeft: 20 }}>
                    <p>
                        <strong>PACE FORECAST</strong>
                        <br />
                        <strong>{`Instance Days: ${paceSettings.days}`}</strong>
                    </p>
                    <TableChart headers={pace_headers} data={pace_data}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} />
                </div>
            </div>

            <div style={{
                display: "grid", gridAutoFlow: "column", width: "100%",
                justifyItems: "center", gridTemplateColumns: "50% 50%",
                marginBottom: "20px"
            }}>

                {/* Price Settings Table */}
                <div>
                    <p><strong>PRICE FORECAST</strong></p>
                    <TableChart headers={price_avgdays_headers} data={price_avgdays_data}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} />
                    <TableChart headers={price_threshold_headers} data={price_threshold_data}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} />

                    {/* Price Recommendation Settings Table */}
                    <br />
                    <p><strong>PRICE RECOMMENDATION</strong></p>
                    <TableChart headers={price_reco_headers} data={price_reco_data}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} />
                </div>

                <div style={{ marginLeft: 20 }}>
                    {/* Finals Settings Table */}
                    <p><strong>FINALS FORECAST</strong></p>
                    <TableChart headers={finals_headers} data={finals_data}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} />

                    {/* Forecast Weights Distribution Table */}
                    <br />
                </div>
                
            </div>
            <div>
                <p><strong>FORECAST WEIGHTS</strong></p>
                <TableChart headers={weights_headers} data={weights_data}
                    allowString={true} rowNameWidth={150} rowDataWidth={115} />
            </div>
        </div>
    )
}


export default AlgorithmModalSettings;
