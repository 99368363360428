import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Tabs from "../Tabs";

import constants from "../../constants";
import useHelperFunctions from "../../store/actions/actions";



export default () => {
    const state = useSelector((state) => state.mainReducer);
    const { source } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    const { toggleSidePanel } = useHelperFunctions();
    const tabs = [
        {
            key: "custom",
            label: "Custom",
            visible: true
        },
        {
            key: "daily",
            label: "Daily",
            visible: true,
        },
        {
            key: "weekly",
            label: "Weekly",
            visible: true
        },
        {
            key: "monthly",
            label: "Monthly",
            visible: true
        }
    ];
    const handleChange = (value) => {
        source.cancel();
        dispatch({ type: constants.SET_SOURCE })

        dispatch({ type: constants.SET_CALENDAR_VIEW, value: value });
        dispatch({ type: constants.SHOW_ALGORITHM, value: false })
        toggleSidePanel(false);
        if (value === "weekly" || value === "monthly") {
            dispatch({
                type: constants.UPDATE_SELECTED_CALENDAR_CELL,
                value: null,
            });
        }
    };
    return (
        <div className="view-tabs-container">
            <Tabs
                tabs={tabs}
                activeTab={state.activeCalendarView}
                selectTab={handleChange}
                callback={() => {}}
                inputClassName={"view-tabs"}
            />
        </div>
    );
};
