import constants from "../../constants";
import { ToastsStore } from "react-toasts";
import { createError } from "../../utils/index";

const defaultState = {
    bgLoading: false
}

function adminConsole(state = defaultState, action) {
    switch (action.type) {
        // ADD INTEGRATION PARTNER
        case constants.ADD_CHANNEL_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.ADD_CHANNEL_SUCCESS:
            let newChannel = action.response.data.data;
            ToastsStore.success(`Integration Partner: ${newChannel._id} was successfully added`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.ADD_CHANNEL_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // UPDATE INTEGRATION PARTNER
        case constants.UPDATE_CHANNEL_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.UPDATE_CHANNEL_SUCCESS:
            let updatedChannel = action.response.data.data;
            ToastsStore.success(`Integration Partner: ${updatedChannel._id} was successfully updated`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_CHANNEL_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // DELETE INTEGRATION PARTNER
        case constants.DELETE_CHANNEL_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.DELETE_CHANNEL_SUCCESS:
            let deletedChannel = action.response.data.data;
            ToastsStore.success(`Integration Partner: ${deletedChannel._id} was successfully deleted`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.DELETE_CHANNEL_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // ADD SEGMENT
        case constants.ADD_SEGMENT_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.ADD_SEGMENT_SUCCESS:
            let newSegment = action.response.data.data;
            ToastsStore.success(`Segment: ${newSegment._id} was successfully added`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.ADD_SEGMENT_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // UPDATE SEGMENT
        case constants.UPDATE_SEGMENT_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.UPDATE_SEGMENT_SUCCESS:
            let updatedSegment = action.response.data.data;
            ToastsStore.success(`Segment: ${updatedSegment._id} was successfully updated`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_SEGMENT_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // DELETE SEGMENT
        case constants.DELETE_SEGMENT_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.DELETE_SEGMENT_SUCCESS:
            let deletedSegment = action.response.data.data;
            ToastsStore.success(`Segment: ${deletedSegment._id} was successfully deleted`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.DELETE_SEGMENT_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // ADD SEGMENT GROUP
        case constants.ADD_SEGMENT_GROUP_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.ADD_SEGMENT_GROUP_SUCCESS:
            let newSegmentGroup = action.response.data.data;
            ToastsStore.success(`Segment Group: ${newSegmentGroup._id} was successfully added`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.ADD_SEGMENT_GROUP_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // UPDATE SEGMENT GROUP
        case constants.UPDATE_SEGMENT_GROUP_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.UPDATE_SEGMENT_GROUP_SUCCESS:
            let updatedSegmentGroup = action.response.data.data;
            ToastsStore.success(`Segment Group: ${updatedSegmentGroup._id} was successfully updated`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_SEGMENT_GROUP_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // DELETE SEGMENT GROUP
        case constants.DELETE_SEGMENT_GROUP_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.DELETE_SEGMENT_GROUP_SUCCESS:
            let deletedSegmentGroup = action.response.data.data;
            ToastsStore.success(`Segment Group: ${deletedSegmentGroup._id} was successfully deleted`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.DELETE_SEGMENT_GROUP_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // ADD ROOM TYPE
        case constants.ADD_ROOM_TYPE_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.ADD_ROOM_TYPE_SUCCESS:
            let newRoomType = action.response.data.data;
            ToastsStore.success(`Room Type: ${newRoomType._id} was successfully added`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.ADD_ROOM_TYPE_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // UPDATE ROOM TYPE
        case constants.UPDATE_ROOM_TYPE_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.UPDATE_ROOM_TYPE_SUCCESS:
            let updatedRoomType = action.response.data.data;
            ToastsStore.success(`Room Type: ${updatedRoomType._id} was successfully updated`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_ROOM_TYPE_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // DELETE ROOM TYPE
        case constants.DELETE_ROOM_TYPE_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.DELETE_ROOM_TYPE_SUCCESS:
            let deleteRoomType = action.response.data.data;
            ToastsStore.success(`Room Type: ${deleteRoomType._id} was successfully deleted`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.DELETE_ROOM_TYPE_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // ADD BOOKING SOURCE
        case constants.ADD_BOOKING_SOURCE_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.ADD_BOOKING_SOURCE_SUCCESS:
            let newBookingSource = action.response.data.data;
            ToastsStore.success(`Booking Source: ${newBookingSource._id} was successfully added`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.ADD_BOOKING_SOURCE_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // UPDATE BOOKING SOURCE
        case constants.UPDATE_BOOKING_SOURCE_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.UPDATE_BOOKING_SOURCE_SUCCESS:
            let updateBookingSource = action.response.data.data;
            ToastsStore.success(`Booking Source: ${updateBookingSource._id} was successfully updated`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_BOOKING_SOURCE_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // DELETE BOOKING SOURCE
        case constants.DELETE_BOOKING_SOURCE_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.DELETE_BOOKING_SOURCE_SUCCESS:
            let deletedBookingSource = action.response.data.data;
            ToastsStore.success(`Booking Source: ${deletedBookingSource._id} was successfully deleted`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.DELETE_BOOKING_SOURCE_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // ADD COMPETITOR
        case constants.ADD_COMPETITOR_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.ADD_COMPETITOR_SUCCESS:
            let newCompetitor = action.response.data.data;
            ToastsStore.success(`Competitor: ${newCompetitor.name} was successfully added`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.ADD_COMPETITOR_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // UPDATE COMPETITOR
        case constants.UPDATE_COMPETITOR_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.UPDATE_COMPETITOR_SUCCESS:
            let updatedCompetitor = action.response.data.data;
            ToastsStore.success(`Competitor: ${updatedCompetitor.name} was successfully updated`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_COMPETITOR_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };
        // DELETE COMPETITOR
        case constants.DELETE_COMPETITOR_REQUEST:
            return {
                ...state,
                bgLoading: true
            };
        case constants.DELETE_COMPETITOR_SUCCESS:
            let deletedCompetitor = action.response.data.data;
            ToastsStore.success(`Competitor: ${deletedCompetitor.name} was successfully deleted`);
            return {
                ...state,
                bgLoading: false
            };
        case constants.DELETE_COMPETITOR_FAILURE:
            createError(action.error);
            return {
                ...state,
                bgLoading: false
            };

        default:
            return state;
    }
}

export default adminConsole;
