import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { ToastsStore } from "react-toasts";

import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";
import BasicInput from "elements/Input/Basic";
import ModalSelect from "elements/Select/Modal";
import Error from "elements/Text/Error";

import useProperties from "../../store/actions/propertiesActions";
import { isNullOrUndefined } from "utils";
import { isAlphaNumeric, sortArray } from "utils/data";
import constants from "../../constants";



const SegmentMappingsModal = ({ visible, type, channels = [], ss = [], onCancel = null, onSubmit = null }) => {
    const state = useSelector((state) => state.mainReducer);
    const { listItemInView, propertyInView } = state;
    const { updateSegmentMappings } = useProperties();

    const partners = sortArray(channels, "name")
        .map((item) => { return { name: item.name, value: item._id } });
    const segments = sortArray(propertyInView?.segments ?? [], "global")
        .map((item) => { if (item.local) return { name: `${item.local.name} (${item.local.code})`, value: item.local.code } });

    const [partner, setPartner] = useState("");
    const [segment, setSegment] = useState("");
    const [sourcename, setSourceName] = useState("");
    const [sourcecode, setSourceCode] = useState("");
    const [sourcedesc, setSourceDesc] = useState("");

    const [hasPartner, setHasPartner] = useState(false);
    const [beforeEdit, setBeforeEdit] = useState(null);
    const [modalErrors, setModalErrors] = useState([]);

    const isViewModal = type === "view";
    const isAddModal = type === "add";
    const isEditModal = type === "edit";
    const isDeleteModal = type === "delete";


    // Dynamic Header Copy & Element
    let headerCopy = "";
    if (isAddModal) headerCopy = "Add Segment Mapping";
    if (isViewModal) headerCopy = "Segment Mapping";
    if (isEditModal) headerCopy = "Edit Segment Mapping";
    if (isDeleteModal) headerCopy = "Delete Segment Mapping";

    // Dynamic Success/Fail Copy
    let successCopy = "";
    if (isAddModal) successCopy = "Segment mapping successfully added";
    if (isEditModal) successCopy = "Segment mapping successfully updated";
    if (isDeleteModal) successCopy = "Segment mapping successfully removed";

    let failureCopy = "";
    if (isAddModal) failureCopy = "Sorry, we're unable to add the segment mapping at this time";
    if (isEditModal) failureCopy = "Sorry, we're unable to update the segment mapping at this time";
    if (isDeleteModal) failureCopy = "Sorry, we're unable to removed the segment mapping at this time";


    // Happens in Edit Mode
    // Pre-Fill Modal with Generic listItemInView State Data if a Partner Item is Selected
    useEffect(() => {
        resetModal();
        if (isEditModal && !isNullOrUndefined(listItemInView)) {

            const mapping = listItemInView.mapping;
            setSegment(listItemInView.segment.local.code);
            if (mapping) {
                setSourceName(mapping?.source ?? "");
                setSourceCode(mapping?.source_code ?? "");
                setSourceDesc(mapping?.description ?? "");
                setPartner(mapping?.partner_code ?? "");
                setHasPartner(!isNullOrUndefined(mapping));
                setBeforeEdit({
                    name: mapping ? mapping.source : "",
                    code: mapping ? mapping.source_code : "",
                })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);



    // Modal Actions
    const handleModalErrors = (data) => {
        let errors = [];

        const mapping = data.sources && data.sources.filter((m) => {
            return m.partner_code === partner && m.source_code === sourcecode && m.source === sourcename;
        })[0]

        if (mapping) {
            if (mapping.partner_code === "") errors.push({ id: "partner", message: "Please select a channel" });
            if (segment === "") errors.push({ id: "type", message: "Please select a segment" });
            if (mapping.source === "") errors.push({ id: "s_name", message: "Please provide a source name" });
            if (mapping.source_code === "") errors.push({ id: "s_code", message: "Please provide a source code" });

            // if (mapping.source !== "" && !isAlphaNumeric(mapping.source, true, true)) {
            //     errors.push({ id: "s_name", message: "Name contains non-alphanumeric characters" });
            // }
            // if (mapping.source_code !== "" && !mapping.source_code.trim().match(/^[a-z\d\-_\s.\\\/\&]+$/i)) {
            //     errors.push({ id: "s_code", message: "Code contains invalid characters" });
            // }
        }

        return errors;
    }

    const resetModal = () => {
        setPartner("");
        setSegment("");
        setSourceName("");
        setSourceCode("");
        setSourceDesc("");
        setModalErrors([]);
    }

    const handleModalCancel = () => {
        onCancel();
        resetModal();
    }

    const handleModalSubmit = () => {
        let selectedSegment = isDeleteModal ? listItemInView.segment.local.code : segment;
        let matchSegment = propertyInView.segments.find(ss => ss.local && ss.local.code === selectedSegment);
        let matchMappings = matchSegment ? matchSegment.sources : [];

        if (isEditModal && beforeEdit) {
            matchMappings = matchMappings.filter(m => !(m.partner_code === partner
                && m.source_code === beforeEdit.code && m.source === beforeEdit.name));
        } else if (isDeleteModal) {
            matchMappings = matchMappings.filter(m => !(m.partner_code === listItemInView.mapping.partner_code
                && m.source_code === listItemInView.mapping.source_code
                && m.source === listItemInView.mapping.source));
        }

        let dataSource = isDeleteModal ? listItemInView.segment : matchSegment;
        let data = {
            global: dataSource?.global ?? "",
            local: dataSource?.local ?? "",
            sources: isDeleteModal
                ? matchMappings
                : [
                    ...matchMappings,
                    {
                        partner_code: partner,
                        source_code: sourcecode,
                        source: sourcename,
                        description: sourcedesc
                    }
                ],
        };

        let errors = handleModalErrors(data);
        if (errors.length === 0) {
            updateSegmentMappings(propertyInView._id, {
                op: isAddModal ? "add" : isEditModal ? "edit" : "delete",
                type: "segmentmapping",
                filter: data
            }).then((res) => {
                if (res && res.type === constants.UPDATE_PROPERTY_SEGMENT_MAPPINGS_SUCCESS) {
                    onSubmit();
                    resetModal();
                    ToastsStore.success(successCopy);
                } else {
                    ToastsStore.error(failureCopy);
                }
            });
        } else {
            setModalErrors(errors);
        }
    }



    return (
        <Modal open={visible} onCancel={() => handleModalCancel()} footer={null} closeIcon={
            <IconButton type="delete" hasAccess={true} width="25px" />
        }>
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>

                <form onSubmit={(e) => null} className="light-form">
                    {!isDeleteModal && <>

                        {/* Select Channel */}
                        <ModalSelect label="Select Channel" value={partner} options={partners}
                            disabled={isEditModal && hasPartner && partner.trim() !== ""}
                            action={(value) => setPartner(value)} />
                        {modalErrors.filter(err => err.id === "partner").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        {/* Select Segment */}
                        <ModalSelect label="Select Segment" value={segment} options={segments}
                            disabled={isEditModal} action={(value) => setSegment(value)} />
                        {modalErrors.filter(err => err.id === "type").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        <div style={{ display: "grid", gridTemplateColumns: "48% 4% 48%" }}>
                            <div>
                                {/* Source Name */}
                                <BasicInput id="sourceName" label="Source Name" value={sourcename}
                                    disabled={false} onBlur={(e) => setSourceName(e.target.value)}
                                    onChange={(e) => setSourceName(e.target.value)} />
                                {modalErrors.filter(err => err.id === "s_name").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>

                            <span />

                            <div>
                                {/* Source Code */}
                                <BasicInput id="sourcecode" label="Source Code" value={sourcecode}
                                    disabled={false} onBlur={(e) => setSourceCode(e.target.value)}
                                    onChange={(e) => setSourceCode(e.target.value)} />
                                {modalErrors.filter(err => err.id === "s_code").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>
                        </div>

                        {/* Source Description */}
                        <div>
                            <BasicInput id="sourcedesc" label="Source Description" value={sourcedesc}
                                disabled={false} onBlur={(e) => setSourceDesc(e.target.value)}
                                onChange={(e) => setSourceDesc(e.target.value)} />
                        </div>

                    </>}


                    {/* Delete Segment Copy */}
                    {isDeleteModal && <div style={{ padding: 20 }}>
                        <p>
                            <strong>Segment Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.segment.local.name}</span>
                            <br />
                            <strong>Segment Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.segment.local.code}</span>
                            <br />
                            <strong>Channel: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.partner_code}</span>
                            <br />
                            <strong>Source Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.source}</span>
                            <br />
                            <strong>Source Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.source_code}</span>
                        </p>
                        <p>Are you sure you want to delete this mapping?</p>
                    </div>}


                    {/* Cancel, Submit, Delete Buttons */}
                    <div className="light-form-buttons" style={{ marginTop: isDeleteModal ? 20 : 40 }}>
                        <ActionButton type="cancel" action={() => handleModalCancel()} text="Cancel" />
                        <span style={{ width: 20 }} />
                        <ActionButton type="submit" action={() => handleModalSubmit()}
                            text={isDeleteModal ? "Delete" : "Submit"} />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default SegmentMappingsModal;
