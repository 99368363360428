import React from "react";
import { useSelector, useDispatch } from "react-redux";
import 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import _ from "lodash";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { TutorialSpot } from "../Tutorial/index";
import useHelperFunctions from '../../store/actions/actions';
import constants from '../../constants';


const SimpleChart = ({ data, filter, isLoading, onClick, showModal }) => {
    const dispatch = useDispatch();
    const tutorial = useSelector((state) => state.mainReducer.tutorial);
    const { toggleChartModal } = useHelperFunctions();
    const filteredData = {
        ...data,
        datasets: data.datasets.filter((item) => {
            const split = item.label.split(": ");
            if (split[1] === "Occupancy") {
                return item.type !== "unplotted" && filter;
            }

            return item.type !== "unplotted";
        }),
    };
    const arrangedData = {
        ...filteredData,
        datasets: _.orderBy(
            filteredData.datasets,
            ["type", "label", "segment"],
            ["desc", "asc", "asc"]
        ),
    };

    return (
        <div
            id="chartTutorial"
            className="chartTutorial chart2Tutorial chart3Tutorial chart4Tutorial chart-container"
            onClick={() => { onClick(!showModal); dispatch({ type: constants.SHOW_VISUALS, value: true }); toggleChartModal({ show: true, tab: 'booking-chart' }) }}
        >
            {tutorial.chart.showSpot && (
                <TutorialSpot
                    section="sidepanel"
                    type="chart"
                    left="-33px"
                    bottom="104px"
                />
            )}
            {isLoading && <LoadingSpinner size={"40px"} color={"#fff"} />}

            <Bar
                data={arrangedData}
                width={100}
                height={50}
                options={{
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                    scales: {
                        y: {
                            display: false,
                            position: "right",
                            gridLines: { display: false },
                            ticks: {
                                beginAtZero: true,
                                color: "#2e2e2e",
                                font: {
                                    size: 10
                                }
                            }
                        },
                        y1: {
                            display: false,
                            position: "left",
                            gridLines: { display: false },
                            ticks: {
                                beginAtZero: true,
                                color: "#2e2e2e",
                                font: {
                                    size: 10
                                }
                            }
                        },
                        x: {
                            display: false,
                            categoryPercentage: 0.75,
                            barPercentage: 1.0,
                            gridLines: { display: false },
                            ticks: {
                                color: "#2e2e2e",
                                font: {
                                    size: 10
                                }
                            }
                        }
                    },
                    events: [],
                }}
            />
        </div>
    );
};

export default SimpleChart;
