import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { ToastsStore } from "react-toasts";

import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";
import BasicInput from "elements/Input/Basic";
import ModalSelect from "elements/Select/Modal";
import Error from "elements/Text/Error";

import useProperties from "../../store/actions/propertiesActions";
import { isNullOrUndefined } from "utils";
import { isAlphaNumeric, sortArray } from "utils/data";
import constants from "../../constants";



const RoomMappingsModal = ({ visible, type, channels = [], rts = [], onCancel = null, onSubmit = null }) => {
    const state = useSelector((state) => state.mainReducer);
    const { listItemInView, propertyInView } = state;
    const { updateRoomMappings } = useProperties();

    const partners = sortArray(channels, "name")
        .map((item) => { return { name: item.name, value: item._id } });
    const roomtypes = sortArray(propertyInView?.room_types ?? [], "room_type")
        .map((item) => { return { name: `${item.room_type} (${item.room_type_code})`, value: item.room_type_code } });

    const [partner, setPartner] = useState("");
    const [roomtype, setRoomType] = useState("");
    const [roomname, setRoomName] = useState("");
    const [roomcode, setRoomCode] = useState("");
    const [rateplancode, setRatePlanCode] = useState("");
    const [rateplanname, setRatePlanName] = useState("");
    const [defaultoccupancy, setDefaultOccupancy] = useState(2);

    const [hasPartner, setHasPartner] = useState(false);
    const [beforeEdit, setBeforeEdit] = useState(null);
    const [modalErrors, setModalErrors] = useState([]);

    const isViewModal = type === "view";
    const isAddModal = type === "add";
    const isEditModal = type === "edit";
    const isDeleteModal = type === "delete";


    // Dynamic Header Copy & Element
    let headerCopy = "";
    if (isAddModal) headerCopy = "Add Room Mapping";
    if (isViewModal) headerCopy = "Room Mapping";
    if (isEditModal) headerCopy = "Edit Room Mapping";
    if (isDeleteModal) headerCopy = "Delete Room Mapping";

    // Dynamic Success/Fail Copy
    let successCopy = "";
    if (isAddModal) successCopy = "Room mapping successfully added";
    if (isEditModal) successCopy = "Room mapping successfully updated";
    if (isDeleteModal) successCopy = "Room mapping successfully removed";

    let failureCopy = "";
    if (isAddModal) failureCopy = "Sorry, we're unable to add the room mapping at this time";
    if (isEditModal) failureCopy = "Sorry, we're unable to update the room mapping at this time";
    if (isDeleteModal) failureCopy = "Sorry, we're unable to removed the room mapping at this time";


    // Happens in Edit Mode
    // Pre-Fill Modal with Generic listItemInView State Data if a Partner Item is Selected
    useEffect(() => {
        resetModal();
        if (isEditModal && !isNullOrUndefined(listItemInView)) {
            const mapping = listItemInView.mapping;
            setRoomType(listItemInView.roomtype.room_type_code);
            if (mapping) {
                setRoomName(mapping?.room_type_name ?? "");
                setRoomCode(mapping?.room_type_code ?? "");
                setRatePlanName(mapping?.rate_plan_name ?? "");
                setRatePlanCode(mapping?.rate_plan_code ?? "");
                setDefaultOccupancy(mapping?.default_occupancy ?? "");
                setPartner(mapping?.partner_code ?? "");
                setHasPartner(!isNullOrUndefined(mapping));
                setBeforeEdit({
                    name: mapping ? mapping.room_type_name : "",
                    code: mapping ? mapping.room_type_code : "",
                })
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);



    // Modal Actions
    const handleModalErrors = (data) => {
        let errors = [];

        const mapping = data.mappings.filter((m) => {
            return m.partner_code === partner && m.room_type_code === roomcode && m.room_type_name === roomname;
        })[0]

        if (mapping) {
            if (mapping.partner_code === "") errors.push({ id: "partner", message: "Please select a channel" });
            if (data.room_type_code === "") errors.push({ id: "type", message: "Please select a room type" });
            if (mapping.room_type_name === "") errors.push({ id: "rm_name", message: "Please provide a channel room name" });
            if (mapping.room_type_code === "") errors.push({ id: "rm_code", message: "Please provide a channel room code" });

            if (mapping.room_type_name !== "" && !isAlphaNumeric(mapping.room_type_name)) {
                errors.push({ id: "rm_name", message: "Name contains non-alphanumeric characters" });
            }
            if (mapping.room_type_code !== "" && !isAlphaNumeric(mapping.room_type_code)) {
                errors.push({ id: "rm_code", message: "Code contains non-alphanumeric characters" });
            }
            if (mapping.rate_plan_name !== "" && !isAlphaNumeric(mapping.rate_plan_name)) {
                errors.push({ id: "rp_name", message: "Name contains non-alphanumeric characters" });
            }
            if (mapping.rate_plan_code !== "" && !isAlphaNumeric(mapping.rate_plan_code, false)) {
                errors.push({ id: "rp_code", message: "ID contains non-alphanumeric characters" });
            }
        }

        return errors;
    }

    const resetModal = () => {
        setPartner("");
        setRoomType("");
        setRoomName("");
        setRoomCode("");
        setRatePlanName("");
        setRatePlanCode("");
        setDefaultOccupancy(2);
        setModalErrors([]);
    }

    const handleModalCancel = () => {
        onCancel();
        resetModal();
    }

    const handleModalSubmit = () => {
        let selectedRoomType = isDeleteModal ? listItemInView.roomtype.room_type_code : roomtype;
        let matchRoomType = propertyInView.room_types.find(rts => rts.room_type_code === selectedRoomType);
        let matchMappings = matchRoomType ? matchRoomType.mappings : [];
        if (isEditModal && beforeEdit) {
            matchMappings = matchMappings.filter(m => !(m.partner_code === partner
                && m.room_type_code === beforeEdit.code && m.room_type_name === beforeEdit.name));
        } else if (isDeleteModal) {
            matchMappings = matchMappings.filter(m => !(m.partner_code === listItemInView.mapping.partner_code
                && m.room_type_code === listItemInView.mapping.room_type_code
                && m.room_type_name === listItemInView.mapping.room_type_name));
        }

        let dataSource = isDeleteModal ? listItemInView.roomtype : matchRoomType;
        let data = {
            room_type: dataSource?.room_type ?? "",
            room_type_code: dataSource?.room_type_code ?? "",
            mappings: isDeleteModal
                ? matchMappings
                : [
                    ...matchMappings,
                    {
                        partner_code: partner,
                        room_type_code: roomcode,
                        room_type_name: roomname,
                        rate_plan_code: rateplancode,
                        rate_plan_name: rateplanname,
                        default_occupancy: defaultoccupancy,
                        bar: (matchMappings.length < 2) ? true : (listItemInView?.mapping?.bar ?? false)
                    }
                ],
            total_inventory: dataSource?.total_inventory ?? "",
        };


        let errors = handleModalErrors(data);
        if (errors.length === 0) {
            updateRoomMappings(propertyInView._id, {
                op: isAddModal ? "add" : isEditModal ? "edit" : "delete",
                type: "roommapping",
                filter: data
            }).then((res) => {
                if (res.type === constants.UPDATE_PROPERTY_ROOM_MAPPINGS_SUCCESS) {
                    onSubmit();
                    resetModal();
                    ToastsStore.success(successCopy);
                } else {
                    ToastsStore.error(failureCopy);
                }
            });
        } else {
            setModalErrors(errors);
        }
    }



    return (
        <Modal open={visible} onCancel={() => handleModalCancel()} footer={null} closeIcon={
            <IconButton type="delete" hasAccess={true} width="25px" />
        }>
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>

                <form onSubmit={(e) => null} className="light-form">
                    {!isDeleteModal && <>

                        {/* Select Channel */}
                        <ModalSelect label="Select Channel" value={partner} options={partners}
                            disabled={isEditModal && hasPartner && partner.trim() !== ""}
                            action={(value) => setPartner(value)} />
                        {modalErrors.filter(err => err.id === "partner").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        {/* Select Room Type */}
                        <ModalSelect label="Select Room Type" value={roomtype} options={roomtypes}
                            disabled={isEditModal} action={(value) => setRoomType(value)} />
                        {modalErrors.filter(err => err.id === "type").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        <div style={{ display: "grid", gridTemplateColumns: "48% 4% 48%" }}>
                            <div>
                                {/* Channel Room Name */}
                                <BasicInput id="roomName" label="Channel Room Name" value={roomname}
                                    disabled={false} onBlur={(e) => setRoomName(e.target.value)}
                                    onChange={(e) => setRoomName(e.target.value)} />
                                {modalErrors.filter(err => err.id === "rm_name").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>

                            <span />

                            <div>
                                {/* Channel Room Code */}
                                <BasicInput id="roomCode" label="Channel Room Code" value={roomcode}
                                    disabled={false} onBlur={(e) => setRoomCode(e.target.value)}
                                    onChange={(e) => setRoomCode(e.target.value)} />
                                {modalErrors.filter(err => err.id === "rm_code").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "48% 4% 48%" }}>
                            <div>
                                {/* Channel Rate Plan ID */}
                                <BasicInput id="rateplanname" label="Channel Rate Plan Name" value={rateplanname}
                                    disabled={false} onBlur={(e) => setRatePlanName(e.target.value)}
                                    onChange={(e) => setRatePlanName(e.target.value)} />
                                {modalErrors.filter(err => err.id === "rp_name").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>

                            <span />

                            <div>
                                {/* Channel Rate Plan Name */}
                                <BasicInput id="rateplancode" label="Channel Rate Plan ID" value={rateplancode}
                                    disabled={false} onBlur={(e) => setRatePlanCode(e.target.value)}
                                    onChange={(e) => setRatePlanCode(e.target.value)} />
                                {modalErrors.filter(err => err.id === "rp_code").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>
                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "48%" }}>
                            <div>
                                {/* Default Occupancy */}
                                <BasicInput id="defaultoccupancy" label="Default Occupancy" value={defaultoccupancy} type="number"
                                    disabled={false} onBlur={(e) => setDefaultOccupancy(e.target.value)}
                                    onChange={(e) => setDefaultOccupancy(e.target.value)} />
                                {modalErrors.filter(err => err.id === "defaultoccupancy").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>
                        </div>
                    </>}


                    {/* Delete Room Type Copy */}
                    {isDeleteModal && <div style={{ padding: 20 }}>
                        <p>
                            <strong>Room Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.roomtype.room_type}</span>
                            <br />
                            <strong>Room Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.roomtype.room_type_code}</span>
                            <br />
                            <strong>Channel: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.partner_code}</span>
                            <br />
                            <strong>Channel Room Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.room_type_name}</span>
                            <br />
                            <strong>Channel Room Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.room_type_code}</span>
                            <br />
                            <strong>Channel Rate Plan Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.rate_plan_name}</span>
                            <br />
                            <strong>Channel Rate Plan ID: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.rate_plan_code}</span>
                        </p>
                        <p>Are you sure you want to delete this mapping?</p>
                    </div>}


                    {/* Cancel, Submit, Delete Buttons */}
                    <div className="light-form-buttons" style={{ marginTop: isDeleteModal ? 20 : 40 }}>
                        <ActionButton type="cancel" action={() => handleModalCancel()} text="Cancel" />
                        <span style={{ width: 20 }} />
                        <ActionButton type="submit" action={() => handleModalSubmit()}
                            text={isDeleteModal ? "Delete" : "Submit"} />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default RoomMappingsModal;
