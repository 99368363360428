import React from 'react';
import { isNullOrUndefined } from '../../utils/index';
import { Select } from 'antd';
import Switch from '../Switch/Switch';

const { Option } = Select;
const fieldDefaultConfig = {
    allowClear: false,
    bordered: true
};

const FormModalField = ({ label, name, value, type,  error, disabled = false, inputClass="",
    items = [], config = fieldDefaultConfig,
    onChange = () => {}, onBlur = () => {},
    validations = []
}) => {
    
    const Error = ({ error }) => <>
        {error && 
        <div className="light-input-error">
            {error}
        </div>}
    </>;

    switch(type) {
        case "textbox": 
            return <>
                {!isNullOrUndefined(label) && (<div className="light-label">{label}</div>)}
                <input key={name} className={`light-input ${inputClass}`} name={name} type="text" value={value || ''} 
                    disabled={disabled}
                    onChange={(e)=> { onChange(name, e.target.value) }}
                    onBlur={(e) => { onBlur(name, e.target.value) }} />
                <Error error={error}></Error>
            </>;
        case "dropdown":
            return <>
                {!isNullOrUndefined(label) && (<div className="light-label">{label}</div>)}
                <Select key={name} className={`light-select ${inputClass}`} disabled={disabled} value={value || ''} onChange={(value) => { onChange(name, value) }} 
                    allowClear={config.allowClear} bordered={config.bordered}>
                {items.length > 0 && items.map(item =>
                    <Option key={item.id} className={`light-option ${item.class? item.class: ''}`} value={item.id}>
                        {item.displayText}
                    </Option>
                )}
                </Select>
                <Error error={error}></Error>
            </>
        case "switch":
            value = (value === true ? true : false);
            return <>
                {!isNullOrUndefined(label) && (<div className="light-label">{label}</div>)}
                <div style={{ display: "inline-flex", marginTop: "auto", marginBottom: "auto" }}>
                    <span style={{ marginRight: 8, fontSize: 14 }}>{value === true ? "Active" : "Inactive"}</span>
                    <Switch checked={value} disabled={disabled} onChange={() => onChange(name, !value)}></Switch>
                </div>
            </>
        case "switchonly":
            value = (value === true ? true : false);
            return <>
                {!isNullOrUndefined(label) && (<div className="light-label">{label}</div>)}
                <div style={{ display: "inline-flex", marginTop: "auto", marginBottom: "auto" }}>
                    <Switch checked={value} disabled={disabled} onChange={() => onChange(name, !value)}></Switch>
                </div>
            </>
        case "staticfield":
            return <>
                {label && (<div className="light-label">{label}</div>)}
                <div key={name} style={{fontWeight: '800'}}>{value}</div>
            </>
        default: 
            return <></>
    }
    
}

export default FormModalField;