import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const FormModalField = ({ label, name, value, type, items = [], error, disabled = false,
    onChange = () => {}, onBlur = () => {},
    validations = []
}) => {
    
    const Error = ({ error }) => <>
        {error && 
        <div className="light-input-error">
            {error}
        </div>}
    </>;

    switch(type) {
        case "textbox": 
            return <>
                {label && (<div className="light-label">{label}</div>)}
                <input key={name} className="light-input" name={name} type="text" value={value || ''} 
                    disabled={disabled}
                    onChange={(e)=> { onChange(name, e.target.value) }}
                    onBlur={(e) => { onBlur(name, e.target.value) }} />
                <Error error={error}></Error>
            </>;
        case "dropdown":
            return <>
                {label && (<div className="light-label">{label}</div>)}
                <Select key={name} className="light-select" disabled={disabled} value={value || ''} onChange={(value) => { onChange(name, value) }}>
                {items.length > 0 && items.map(item =>
                    <Option key={item.id} className="light-option" value={item.id}>
                        {item.displayText}
                    </Option>
                )}
                </Select>
                <Error error={error}></Error>
            </>
        default: 
            return <></>
    }
    
}

FormModalField.displayName = 'FormModalField';

export default FormModalField;