import React from 'react';
import { Select } from 'antd';

const fieldDefaultConfig = {
    allowClear: false,
    bordered: false
};

export default ({ name, inputClass, value, disabled, items = [], config = fieldDefaultConfig, onChange }) => {
    const { Option } = Select;
    return (
    <div className="form-field-input-div">
        <Select key={name} className={`form-field-input form-field-dropdown ${inputClass}`} disabled={disabled} value={value || ''} 
            allowClear={config.allowClear} bordered={config.bordered}
            onChange={(value) => { typeof onChange == "function" && onChange(name, value) }} 
        >
        {items.length > 0 && items.map(item =>
            <Option key={item.id} className={`form-field-dropdown-option ${item.class? item.class: ''}`} value={item.id}>
                {item.displayText}
            </Option>
        )}
        </Select>
    </div>);
}