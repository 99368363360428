import React from "react";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";



export default function WithDataRequiredContainer({
    doneFetching,
    height,
    children,
    style
}) {
    return (
        <div className="fetching-container" style={style}>
            {!doneFetching ? (
                <div style={{ height }}>
                    <LoadingSpinner size="40px" />
                </div>
            ) : (
                    children
                )}
        </div>
    );
}
